import axios from "axios";
import { APIOverrides } from "../../ApiOverrides";
import type { AdPlacementProps, AdTypeProps } from "../../types";

let SOCIAL_SVC_API = ''

const AdServerApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        SOCIAL_SVC_API = endpoints['SOCIAL_SVC_API'] as string;
    },
    getAdTypes: async():Promise<AdTypeProps[]> => {
        const resp = await axios.get(`${SOCIAL_SVC_API}/v2/ads/types`);
        return resp.data.ad_types
    },
    findAds : async():Promise<AdPlacementProps[]> => {
        const resp = await axios.get(`${SOCIAL_SVC_API}/v2/ads/find`)
        return resp.data.ad_placements
    }
}

const AdServerHelpers = {
    generateUrl: (landing_url:string, distinct_id?:string):string => {
        if(!distinct_id){return landing_url }
        let split_url = landing_url.split('$')
        let leading_char = '?'
        if(split_url.length > 1){
            leading_char = '&'
        }
        return `${landing_url}${leading_char}id=${distinct_id}`
    }
}

export { AdServerApi, AdServerHelpers }