import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import moment from 'moment-mini';
import { v4 } from 'uuid';
import type { AuthStateProps } from './types';

const ENVIRONMENT_ENDPOINTS = {
    DEVELOPMENT: {
        AUTH_SVC_API: 'http://localhost:5000',
        CLIENT_API_KEY: '2c4b3d06-6306-4376-9964-89b69a18b96f',
        VOUCH_ID: 'adqEZph8t*_NBFy6u_l4!t~Hin6kpK',
        VOUCHED_CALLBACK_URL: 'https://1f9b-64-159-204-178.ngrok-free.app/v1/players/vouch/hook',
        VOUCHED_SANDBOX: true,
        EVENT_SVC_API: 'http://localhost:3002',
        MK_SVC_API:'http://localhost:3001',
        TP_SVC_API: 'http://localhost:3006',
        SOCIAL_SVC_API: 'http://localhost:3011',
        WALLET_SVC_API: 'http://localhost:3012',
        LOCATION_API: 'AIzaSyAZwcca9MedBHEedDdQmQG3AL9FMUwTvP0',
        PAYPAL_CLIENT_ID: 'AWSlToJzIYpEfvKMPB6vSyi54Sc9LEv6R0axuLUsAiJTo42vhHMpdUkLKv3zeGuayhL-05dKgC09iaS6',
        WS_SVC_API: 'ws://localhost:3000',
        ANALYTICS_SVC_API: 'https://be-analytics-svc.herokuapp.com'
    },
    STAGING: {
        AUTH_SVC_API: 'https://stage-be-auth-svc.herokuapp.com',
        CLIENT_API_KEY: 'mlOfTJPp4TdmppP9ajBmMVo2bO50O2G9',
        VOUCH_ID: 'adqEZph8t*_NBFy6u_l4!t~Hin6kpK',
        VOUCHED_CALLBACK_URL: 'https://stage-be-auth-svc.herokuapp.com/v1/players/vouch/hook',
        VOUCHED_SANDBOX: true,
        EVENT_SVC_API: 'https://stage-be-sr-svc.herokuapp.com',
        MK_SVC_API:'https://stage-be-mk-svc.herokuapp.com',
        TP_SVC_API: 'https://stage-be-tp-svc.herokuapp.com',
        SOCIAL_SVC_API: 'https://stage-be-soc-svc.herokuapp.com',
        WALLET_SVC_API: 'https://stage-be-pay-svc.herokuapp.com',
        LOCATION_API: 'AIzaSyAZwcca9MedBHEedDdQmQG3AL9FMUwTvP0',
        PAYPAL_CLIENT_ID: 'AWSlToJzIYpEfvKMPB6vSyi54Sc9LEv6R0axuLUsAiJTo42vhHMpdUkLKv3zeGuayhL-05dKgC09iaS6',
        WS_SVC_API: 'wss://stage-be-ws.herokuapp.com',
        ANALYTICS_SVC_API: 'https://be-analytics-svc.herokuapp.com'
    },
    PRODUCTION: {
        AUTH_SVC_API: 'https://api.players.bettoredge.com',
        CLIENT_API_KEY: 'mlOfTJPp4TdmppP9ajBmMVo2bO50O2G9',
        VOUCH_ID: 'Q3tmJ3r9FnkN*n-MqS-!j0NS*k!mdo',
        VOUCHED_CALLBACK_URL: 'https://be-auth-svc.herokuapp.com/v1/players/vouch/hook',
        VOUCHED_SANDBOX: false,
        EVENT_SVC_API: 'https://api.events.bettoredge.com',
        MK_SVC_API: 'https://api.markets.bettoredge.com',
        TP_SVC_API: 'https://be-tp-svc.herokuapp.com',
        SOCIAL_SVC_API: 'https://api.social.bettoredge.com',
        WALLET_SVC_API: 'https://be-pay-svc.herokuapp.com',
        LOCATION_API: 'AIzaSyAZwcca9MedBHEedDdQmQG3AL9FMUwTvP0',
        PAYPAL_CLIENT_ID: 'AUZbVk4xEx5hlDu52ogVyUr4LhPF7eIYWrvxqEh_ebiAlbdg8HUvCZHnNgnW9_gmkI2lx4nX095STlAr',
        WS_SVC_API: 'wss://be-ws.herokuapp.com',
        ANALYTICS_SVC_API: 'https://be-analytics-svc.herokuapp.com'
    }
}

let ENDPOINTS:{ [key:string]: string|boolean } = {}

let auth_state:AuthStateProps = {
    authenticated:false,
    distinct_id: '',
    session_id:''
}

export { APIOverrides }

const APIOverrides = {
    start: async({ env, no_cache }: { env:'PRODUCTION'|'STAGING'|'DEVELOPMENT', no_cache:boolean }):Promise<AuthStateProps> => {
        
        APIOverrides.setEnvironment(env);
        APIOverrides.setAPIKey();
        let distinct_id = await APIOverrides.getDistinctID();
        APIOverrides.setDistinctToken(distinct_id);
        const session_id = v4();
        APIOverrides.setSessionToken(session_id)
        auth_state = { authenticated: false, distinct_id, session_id }
        if(no_cache){ return auth_state }
        let cached_token = await AsyncStorage.getItem('access_token');
        if(!cached_token){ return { authenticated: false, distinct_id, session_id } }
        let cached_refresh_token = await AsyncStorage.getItem('refresh_token');
        if(!cached_refresh_token){ return { authenticated: false, distinct_id, session_id } }
        let expire_datetime = await AsyncStorage.getItem('auth_expire_time');
        if(!expire_datetime){ return { authenticated:false, distinct_id, session_id } }
        if(moment().isAfter(moment(expire_datetime))){
            //Try to get a new one
            const refresh_resp = await APIOverrides.refreshToken(cached_refresh_token, distinct_id);
            if(!refresh_resp){ 
                //This failed - so lets clear out our stored tokens
                await AsyncStorage.removeItem('access_token');
                await AsyncStorage.removeItem('refresh_token');
                await AsyncStorage.removeItem('auth_expire_time');
                return { authenticated: false, distinct_id, session_id }
            }
            cached_token = refresh_resp.access_token
            cached_refresh_token = refresh_resp.refresh_token
            expire_datetime = refresh_resp.expire_datetime
            await AsyncStorage.setItem('access_token', cached_token);
            await AsyncStorage.setItem('refresh_token', cached_refresh_token);
            await AsyncStorage.setItem('auth_expire_time', expire_datetime);
        }
        const player_id = await AsyncStorage.getItem('player_id');
        APIOverrides.setBearerToken(cached_token);
        APIOverrides.setRefreshTokenInterceptor(cached_refresh_token, distinct_id)

        auth_state = {
            authenticated: true,
            distinct_id,
            player_id,
            session_id,
            access_token: cached_token,
            refresh_token: cached_refresh_token,
            expire_datetime
        }
        return auth_state
    },
    authenticateApp: async(auth_response:AuthStateProps, options: { cache?:boolean }):Promise<{ result: 'success'|'fail' }> => {
        //First set the bearertokena and refresh interceptor
        const { access_token, player_id, refresh_token, expire_datetime, distinct_id } = auth_response
        if(!access_token || !refresh_token || !player_id){ return { result: 'fail' } }
        APIOverrides.setBearerToken(access_token);
        APIOverrides.setRefreshTokenInterceptor(access_token, distinct_id)
        if(options.cache){
            await APIOverrides.cacheTokens(access_token, player_id, refresh_token, expire_datetime)
        }
        return { result: 'success' }
    },
    logoutApp: async():Promise<{result: 'success'|'fail'}> => {
        //First remove bearertoken
        APIOverrides.setBearerToken(undefined);
        //Then clear tokens from cache
        await APIOverrides.clearTokens();
        return { result: 'success' }
    },
    setEnvironment: (env:'PRODUCTION'|'STAGING'|'DEVELOPMENT') => {
        ENDPOINTS = ENVIRONMENT_ENDPOINTS[env]
    },
    getEndpoints: () => {
        return ENDPOINTS
    },
    cacheTokens: async(access_token:string, player_id?:string, refresh_token?:string, expire_datetime?:any) => {
        await AsyncStorage.setItem('access_token', access_token);
        if(player_id){ await AsyncStorage.setItem('player_id', player_id) };
        if(refresh_token){ await AsyncStorage.setItem('refresh_token', refresh_token) }
        if(expire_datetime){ await AsyncStorage.setItem('auth_expire_time', expire_datetime) }
    },
    clearTokens: async() => {
        await AsyncStorage.removeItem('access_token');
        await AsyncStorage.removeItem('refresh_token')
        await AsyncStorage.removeItem('player_id');
        await AsyncStorage.removeItem('auth_expire_time');
    },
    getDistinctID: async() => {
        let distinct_id = await AsyncStorage.getItem('distinct_id');
        if(!distinct_id){
            distinct_id = v4();
            AsyncStorage.setItem('distinct_id', distinct_id);
        }
        return distinct_id
    },
    setBearerToken: (token?:string) => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    },
    setAPIKey: () => {
        let CLIENT_API_KEY = ENDPOINTS['CLIENT_API_KEY']

        if(CLIENT_API_KEY){
            axios.defaults.headers.common['x-api-key'] = CLIENT_API_KEY
        } else {
            delete axios.defaults.headers.common['x-api-key'];
        }
    },
    setDistinctToken: (distinct_id?:string) => {
        if (distinct_id) {
            axios.defaults.headers.common['distinctid'] = distinct_id;
        } else {
            delete axios.defaults.headers.common['distinctid'];
        }
    },
    setSessionToken: (session_id?:string) => {
        if (session_id) {
            axios.defaults.headers.common['sessionid'] = session_id;
        } else {
            delete axios.defaults.headers.common['sessionid'];
        }
    },
    refreshToken: async(token:string, distinct_id:string):Promise<{refresh_token:string, access_token:string, expire_datetime:string, player_id:string } | undefined> => {
        try {
            let AUTH_SVC_API = ENDPOINTS['AUTH_SVC_API']
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/tokens/token/refresh`, { refresh_token:token, device_id:distinct_id })
            return resp.data
        } catch (error) {
            return undefined
        }
    },
    setRefreshTokenInterceptor: async(token:string, distinct_id:string) => {
        //Set the endpoint based on the environment that is past        
        axios.interceptors.response.use((response) => {
            return response
        }, async (error) => {
            const originalRequest = error.config;
            //If the user gets a 403 - sign them out if they haven't been signed out yet
            if(error?.response?.status === 403) {
                //if(store.getState().auth.isAuthenticated){ store.dispatch(auth_logout()); throw new Error(error);  } 
                return Promise.reject({ message: 'Authenication session has expired.' })
            }
            //If the user gets a 401 - check for a refresh_token and attempt to get another access token to use the request
            if(error?.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                let refresh_resp = await APIOverrides.refreshToken(token, distinct_id);
                if(!refresh_resp){
                    return Promise.reject({ message: 'Unable to authenticate request' })
                }
                const { player_id, access_token, refresh_token, expire_datetime } = refresh_resp;
                APIOverrides.setBearerToken(access_token)
                await APIOverrides.cacheTokens(access_token, player_id, refresh_token, expire_datetime);
                return Promise.resolve(axios({ ...originalRequest, headers: { ...originalRequest.headers, Authorization: `Bearer ${access_token}` }}));
            }
            return Promise.reject(error)
        })
    }
}