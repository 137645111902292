import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, Image, ScrollView, TouchableOpacity, FlatList } from "react-native"
import type { MyPlayerProps, PlayerPodcastEpisodeProps, PodcastEpisodesProps, PodcastProps, PublicPlayerProps } from '../../types';
import { PodcastApi } from './api';
import Colors from '../../constants/colors';
import { SocialPodcastHelpers } from '../api';
import { view_styles } from '../../constants/styles';
import { Button, Icons, Spring, Text } from '../../Components';
import PodcastEpisodeCard from '../PodcastEpisodeCard';
import moment from 'moment-mini';
import PlayerCard from '../PlayerCard';
import ClaimPodcastModal from './components/ClaimPodcastModal';

type PodcastModuleProps = {
    player_id?:string,
    podcast_id:string,
    padding_insets?:{ top:number, bottom:number },
    onBack?:() => void,
    onShareEpisode?:(pd:PodcastEpisodesProps) => void,
    onSharePodcast?:(p:PodcastProps) => void,
    onSelectEpisode:(pe:PodcastEpisodesProps) => void
    onSelectPlayer?:(p:PublicPlayerProps) => void
}

const PodcastModule = ({ podcast_id, player_id, padding_insets, onSelectEpisode, onBack, onShareEpisode, onSharePodcast, onSelectPlayer }:PodcastModuleProps) => {
    const [ module_size, setModuleSize ] = useState({ height:700, width:330 });
    const [ show_claim, setShowClaim ] = useState(false);
    const [ pod_data, setPodData ] = useState<{
        loading:boolean,
        player?:MyPlayerProps,
        episodes_loading:boolean,
        podcast?:PodcastProps,
        podcast_episodes:PodcastEpisodesProps[],
        player_podcast_episodes:PlayerPodcastEpisodeProps[],
        players:PublicPlayerProps[],
        episodes_offset:number
    }>({
        loading:false,
        episodes_loading: false,
        player_podcast_episodes:[],
        podcast_episodes:[],
        players:[],
        episodes_offset:0
    });
    const { loading, episodes_loading, podcast, podcast_episodes, episodes_offset, players, player_podcast_episodes, player } = pod_data;
    const claimed_player = pod_data.players.find(p => p.player_id == podcast?.claimed_player_id);
    useEffect(() => {
        PodcastApi.setEnvironment();
        getPodDataFromServer(podcast_id, 0);
    },[podcast_id]);

    const getPodDataFromServer = async(id:string, offset:number) => {
        setPodData({ ...pod_data, loading:true });
        const pod = await PodcastApi.getPodcastById(id);
        const pod_ep = await PodcastApi.getPodcastEpisodesByPodcast(id, offset);
        let me:MyPlayerProps | undefined = undefined;
        if(player_id){
            me = await PodcastApi.getMyDetails();
        }
        let player_ids = pod?.player_ids ?? []
        if(pod?.claimed_player_id){ player_ids.push(pod.claimed_player_id) }
        const ps = await PodcastApi.getPlayersByPlayerIds(pod?.player_ids);
        setPodData({
            ...pod_data,
            loading: false,
            episodes_loading: false,
            podcast: pod,
            player: me,
            players:ps,
            podcast_episodes: pod_ep.podcast_episodes,
            player_podcast_episodes: pod_ep.player_podcast_episodes,
            episodes_offset:offset
        })
    }

    const getEpisdoesFromServer = async(id:string, offset:number) => {
        setPodData({ ...pod_data, episodes_loading: true });
        const pod_eps = await PodcastApi.getPodcastEpisodesByPodcast(id, offset);
        setPodData({
            ...pod_data,
            episodes_loading: false,
            podcast_episodes: pod_eps.podcast_episodes,
            player_podcast_episodes: pod_eps.player_podcast_episodes,
            episodes_offset: offset
        })
    }

    const approveClaim = async() => {
        if(!player || player.role != 'admin'){ return alert('Not eligible to approve this request') }
        const pod = await PodcastApi.approveClaim(podcast_id);
        setPodData({
            ...pod_data,
            podcast: pod
        })
    }

    const renderPlayers = (data:{item:PublicPlayerProps, index:number}) => {
        return (
            <View style={{ padding:4 }}>
                <PlayerCard
                    player={data.item}
                    width={135}
                    show_follow
                    border
                    alignment='vertical'
                    onSelectPlayer={(p) => onSelectPlayer ? onSelectPlayer(p) : console.log('')}
                    onFollowingUpdate={(pf) => console.log(pf)}
                />
            </View>
        )
    }


    const renderEpsidoes = (data: { item:PodcastEpisodesProps, index:number }) => {
        const ep_width = (module_size.width - 60) / 2
        const ppe = player_podcast_episodes.find(pp => pp.podcast_episode_id == data.item.podcast_episode_id);
        return (
            <View>
                <PodcastEpisodeCard 
                    podcast_episode={data.item}
                    player_podcast_episode={ppe}
                    width={ep_width}
                    onShare={onShareEpisode}
                    onSelectEpisode={onSelectEpisode}
                />
            </View>
        )
    }

    if(!podcast || loading){
        return (
            <View style={{ flex:1 }}>
                <ActivityIndicator style={{ padding:20, alignSelf: 'center' }} size='large' color={Colors.brand.midnight} />
            </View>
        )
    }

    let height = module_size.height * 0.5
    let width = module_size.width

    let description = `${podcast.description.slice(0,200)}...`
    if(podcast.description_override){ description = `${podcast.description.slice(0,200)}...` }

    return (
        <View style={{ flex:1 }}>
            <View style={{ flex:1, backgroundColor:Colors.shades.white }} onLayout={(ev) => {
            const { height, width } = ev.nativeEvent.layout
            setModuleSize({ width, height });
        }}>
                <ScrollView style={{ flex:1 }}>
                    <View nativeID='podcast_header'>
                        <Image
                            source={{ uri: SocialPodcastHelpers.getPodcastImage(podcast) }}
                            style={{ width, height }}
                            resizeMode='cover'
                        />
                        <View style={{ padding:20,  position:'absolute', bottom:0, left:0, right:0, ...style.blur }}>
                            <TouchableOpacity 
                                onPress={() => {
                                    let episode = podcast_episodes.sort((a,b) => moment(b.publish_date).unix() - moment(a.publish_date).unix())[0]
                                    if(!episode){ return alert('Unable to process') }
                                    onSelectEpisode(episode)
                                }}
                                disabled={podcast_episodes.length == 0 ? true : false}
                                style={{ padding:10, borderRadius:8, width:module_size.width*0.7, flexDirection:'row', alignItems:'center', justifyContent:'center', alignSelf:'center', backgroundColor:Colors.shades.white, ...view_styles.float }}>
                                <Icons.PlayIcon size={20} color={Colors.brand.electric}/>
                                <Text style={{ marginLeft:15 }} weight='bold' color={Colors.brand.electric}>Play Episode</Text>
                            </TouchableOpacity>
                            {!podcast.claim_status ?
                            <TouchableOpacity 
                                style={{ padding:10, marginTop:5, borderRadius:8, width:module_size.width*0.7, flexDirection:'row', alignItems:'center', justifyContent:'center', alignSelf:'center', backgroundColor:Colors.utility.success, ...view_styles.float }}
                                onPress={() => setShowClaim(true)}>
                                <Icons.HeadphoneIcon size={20} color={Colors.shades.white}/>
                                <Text style={{ marginLeft:15 }} weight='bold' color={Colors.shades.white}>Claim This Podcast!</Text>
                            </TouchableOpacity>
                            :podcast.claim_status == 'requested' && player?.role != 'admin' ?
                            <View 
                                style={{ padding:10, marginTop:5, borderRadius:8, width:module_size.width*0.7, flexDirection:'row', alignItems:'center', justifyContent:'center', alignSelf:'center', backgroundColor:Colors.brand.midnight, ...view_styles.float }}>
                                <Icons.HeadphoneIcon size={20} color={Colors.shades.white}/>
                                <Text style={{ marginLeft:15 }} weight='bold' color={Colors.shades.white}>Claim Requested!</Text>
                            </View>
                            :podcast.claim_status == 'requested' && player?.role == 'admin' ?
                            <TouchableOpacity 
                                style={{ padding:10, marginTop:5, borderRadius:8, width:module_size.width*0.7, flexDirection:'row', alignItems:'center', justifyContent:'center', alignSelf:'center', backgroundColor:Colors.utility.success, ...view_styles.float }}
                                onPress={() => approveClaim()}>
                                <Icons.HeadphoneIcon size={20} color={Colors.shades.white}/>
                                <Text style={{ marginLeft:15 }} weight='bold' color={Colors.shades.white}>Approve Claim Request!</Text>
                            </TouchableOpacity>
                            :<></>}
                            <View style={{ marginTop:10 }}>
                                <Text size={24} weight='bold' color={Colors.shades.white}>{podcast.title}</Text>
                                <Text style={{ marginTop:4 }} color={Colors.shades.white} weight='semibold'>{description}</Text>
                            </View>
                            {podcast.claim_status == 'claimed' ?
                            <View style={{ position:'absolute', top:20, left:5, height:40, width:40, borderRadius:100, justifyContent:'center', alignItems:'center' }}>
                                <Icons.CheckCirlceIcon size={30} color={Colors.utility.success} />
                            </View>
                            :<></>}
                            {podcast.claim_status == 'claimed' && claimed_player ?
                            <TouchableOpacity style={{ position:'absolute', top:20, right:5, justifyContent:'center', alignItems:'center' }}
                            onPress={() => onSelectPlayer ? onSelectPlayer(claimed_player) : console.log('')}>
                                  <Image
                                    source={{ uri: claimed_player.profile_pic && claimed_player.profile_pic != '' ? claimed_player.profile_pic:'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                                    style={{ height:40, width:40, borderRadius:100 }}
                                    resizeMode='cover'
                                />
                            </TouchableOpacity>
                            :<></>}
                        </View>
                    </View>
                    {players.length > 0 ?
                    <View style={{ ...view_styles.section }}>
                        <View style={{ ...view_styles.section_header }}>
                            <View style={{ flex:1 }}>
                                <Text theme='header'>Podcasters</Text>
                                <Text style={{ marginTop:3 }} theme='body_2'>Below are the players that put this podcast on! Check out their profiles</Text>
                            </View>
                        </View>
                        <View style={{ ...view_styles.section_body, padding:10 }}>
                            <FlatList
                                key='podcastors'
                                data={players}
                                horizontal
                                showsHorizontalScrollIndicator={false}
                                renderItem={renderPlayers}
                                keyExtractor={(item) => item.player_id.toString()}
                            />
                        </View>
                    </View>
                    :<></>}
                    <View style={{ ...view_styles.section }}>
                        <View style={{ ...view_styles.section_header }}>
                            <View style={{ flex:1 }}>
                                <Text theme='header'>Episodes</Text>
                                <Text style={{ marginTop:3 }} theme='body_2'>Select an episode below to listed to it!</Text>
                            </View>
                        </View>
                        <View style={{ ...view_styles.section_body, padding:0 }}>
                            <View style={{ ...view_styles.body_row }}>
                                {episodes_offset > 0 ?
                                <Button
                                    title='PREV'
                                    title_color={Colors.brand.electric}
                                    backgroundColor='transparent'
                                    onPress={() => getEpisdoesFromServer(podcast_id, episodes_offset - 1)}
                                />
                                :<View/>}
                                <View style={{ flex:1 }} />
                                <Button
                                    title='NEXT'
                                    title_color={Colors.brand.electric}
                                    backgroundColor='transparent'
                                    onPress={() => getEpisdoesFromServer(podcast_id, episodes_offset + 1)}
                                />
                            </View>
                            {episodes_loading ?
                            <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
                            :<></>}
                            <View style={{ flexDirection:'row', flexWrap: 'wrap' }}>
                                {podcast_episodes.sort((a,b) => moment(b.publish_date).unix() - moment(a.publish_date).unix()).map((e,i) => {
                                    return renderEpsidoes({ item:e, index:i })
                                })}
                            </View>
                        </View>
                    </View>
                </ScrollView>
                <View style={{ position:'absolute', top:padding_insets?.top ?? 0, left:0, right:0, flexDirection:'row', alignItems:'center' }}>
                    {onBack ?
                    <TouchableOpacity 
                        style={{ ...view_styles.section, backgroundColor:Colors.shades.shade100, height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center' }}
                        onPress={() => onBack()}>
                        <Icons.ChevronIcon direction='left' color={Colors.brand.midnight} size={14} />
                    </TouchableOpacity> 
                    :<></>}
                    <View style={{ flex:1 }} />
                    {onSharePodcast ?
                    <TouchableOpacity 
                        style={{ ...view_styles.section, backgroundColor:Colors.shades.shade100, height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center' }}
                        onPress={() => onSharePodcast(podcast)}>
                        <Icons.ShareIcon color={Colors.brand.electric} size={14} />
                    </TouchableOpacity> 
                    :<></>}
                </View>
                
            </View>
            {show_claim ?
            <View style={{ position:'absolute', flex:1, backgroundColor:Colors.shades.black_faded, top:0, left:0, right:0, bottom:0, justifyContent:'flex-end' }}>
                <Spring
                    slide='vertical'
                    to={0}
                    from={500}
                    
                >
                <ClaimPodcastModal
                    podcast={podcast}
                    width={module_size.width - 20}
                    onClose={() => setShowClaim(false)}
                    onPodcastUpdate={(pod) => {
                        setShowClaim(false);
                        setPodData({
                            ...pod_data,
                            podcast: pod
                        })
                    }}
                />
            </Spring>
            </View>
            :<></>}
        </View>
    )
}

export default PodcastModule

const style:any = {
    blur: {
        backdropFilter: 'blur(75px)',
        backgroundColor:Colors.shades.black_faded_heavy
    }
}