import React from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import { view_styles } from "../../constants/styles"
import type { MarketProps } from '../../types';
import { Checkbox, Text } from '../../Components';
import Colors from '../../constants/colors';


type MarketSelectorProps = {
    maxHeight?:number,
    markets:MarketProps[],
    selected_markets:string[],
    onSelectMarket:(m:MarketProps) => void,
    onDeselectMarket:(m:MarketProps) => void
}
const MarketSelector = ({ maxHeight, markets, selected_markets, onSelectMarket, onDeselectMarket }:MarketSelectorProps) => {

    const renderMarkets = (data:{item:MarketProps, index:number}) => {
        const selected = selected_markets.find(se => se == data.item.market_id) ? true : false;
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} onPress={() => selected ? onDeselectMarket(data.item) : onSelectMarket(data.item)}>
                <Checkbox checked={selected} disabled onSelect={() => console.log('')}/>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text size={12} weight='bold' color={Colors.brand.midnight}>{data.item.type == 'Stat' ? data.item.stat_label : data.item.type}</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>{data.item.description}</Text>
                </View>
            </TouchableOpacity>
        )
    }
    return (
        <View style={{ ...view_styles.section, maxHeight }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Select Market</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>Manage the markets that are shown on the dashboard</Text>
                </View>
            </View>
            <View style={{ ...view_styles.section_body }}>
                <FlatList
                    key={'market_selector'}
                    data={markets}
                    renderItem={renderMarkets}
                    keyExtractor={(item) => item.market_id.toString()}
                />
            </View>
        </View>
    )
}

export default MarketSelector