import axios from "axios";
import { APIOverrides } from "../../../ApiOverrides";
import type { MyPlayerProps, PlayerPodcastEpisodeProps, PodcastEpisodesProps, PodcastProps, PublicPlayerProps } from "../../../types";

let SOCIAL_SVC_API = '';
let AUTH_SVC_API = '';

export { PodcastApi }

const PodcastApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        SOCIAL_SVC_API = endpoints['SOCIAL_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getPodcastById: async(podcast_id:string):Promise<PodcastProps | undefined> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/podcasts/podcast/${podcast_id}`)
            return resp.data.podcast
        } catch (e) {
            return undefined
        }
    },
    getMyDetails: async():Promise<MyPlayerProps | undefined> => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
            return resp.data.player
        } catch (e) {
            return undefined
        }
    },
    claimPodcast: async(podcast_id:string):Promise<PodcastProps | undefined> => {
        try {
            const resp = await axios.post(`${SOCIAL_SVC_API}/v1/podcasts/podcast/claim`, { podcast_id });
            return resp.data.podcast
        } catch (e) {
            return undefined
        }
    },
    approveClaim: async(podcast_id:string):Promise<PodcastProps | undefined> => {
        try {
            const resp = await axios.post(`${SOCIAL_SVC_API}/v1/podcasts/podcast/approve`, { podcast_id });
            return resp.data.podcast
        } catch (e) {
            return undefined
        }
    },
    getPodcastEpisodesByPodcast: async(podcast_id:string, offset:number):Promise<{podcast_episodes:PodcastEpisodesProps[], player_podcast_episodes:PlayerPodcastEpisodeProps[]}> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/podcasts/episodes/podcast/${podcast_id}?offset=${offset}`);
            return resp.data
        } catch (e) {
            console.log(e);
            return { podcast_episodes: [], player_podcast_episodes: [] }
        }
    },
    getPlayersByPlayerIds: async (player_ids?: string[]): Promise<PublicPlayerProps[]> => {
        if(!player_ids){ return [] }
        if (player_ids.length == 0) { return [] }
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
            return resp.data.players
        } catch (e) {
            console.log(e);
            return []
        }
    },
}
