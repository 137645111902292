import { Image, View, TouchableOpacity } from "react-native"
import { view_styles } from "../../constants/styles"
import { Icons, Text } from "../../Components"
import type { EventProps, LeagueProps } from "../../types"
import moment from "moment-mini"
import Colors from "../../constants/colors"
import React, { useState } from "react"

type EventCardProps = {
    league?:LeagueProps,
    event:EventProps
}
const EventCard = ({ event, league }:EventCardProps) => {
    const [ expanded, setExpanded ] = useState(true);
    const overtime_occurred = event.event_scoring && event.event_scoring.length > 4 ? true : false;

    return (
        <View style={{ ...view_styles.section, minWidth:350 }}>
                <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                    <View style={{ flexDirection:'row', alignItems:'center', paddingRight:5, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                        {league ?
                        <Image
                            source={{ uri: league.league_image }}
                            style={{ height:30, width:30, borderRadius:4, marginRight:5 }}
                            resizeMode="cover"
                        />
                        :<></>}
                    </View>
                    <View style={{ flex:1, marginLeft:5 }}>
                        <Text theme="header">{event.away?.market_name} {event.away?.name} vs {event.home?.market_name} {event.home?.name}</Text>
                        <Text style={{ marginTop:3 }} theme="body_2">{event.time_detail == 'scheduled' ? moment(event.scheduled_datetime).format('MMM D - hh:mm a') : event.time_detail}</Text>
                    </View>
                    <Icons.ChevronIcon direction={expanded?'up':'down'} color={Colors.brand.midnight} size={8} />
                </TouchableOpacity>
                {event.event_scoring && expanded ?
                <View style={view_styles.section_body}>
                    <View style={view_styles.body_row}>
                        <Image
                            source={{ uri: event.away?.image?.url }}
                            style={{ height:20, width:20, borderRadius:4, marginRight:5 }}
                            resizeMode="cover"
                        />
                        <Text style={{ flex:1 }} theme="body">{event.away?.abbr}</Text>
                        <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                            {event.event_scoring.sort((a,b) => a.period - b.period).map(s => {
                                return (
                                    <View style={{ flex:1 }}>
                                        <Text theme={overtime_occurred && s.period == 4 ? 'error_header_2': 'header_2'}>{s.away_cumulative_score}</Text>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                    <View style={view_styles.body_row}>
                        <Image
                            source={{ uri: event.home?.image?.url }}
                            style={{ height:20, width:20, borderRadius:4, marginRight:5 }}
                            resizeMode="cover"
                        />
                        <Text style={{ flex:1 }} theme="body">{event.home?.abbr}</Text>
                        <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                            {event.event_scoring.map(s => {
                                return (
                                    <View style={{ flex:1 }}>
                                        <Text theme={overtime_occurred && s.period == 4 ? 'error_header_2': 'header_2'}>{s.home_cumulative_score}</Text>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                </View>
                :<></>}
            </View>
    )
}

export default EventCard