import axios from "axios";
import { APIOverrides } from "../../ApiOverrides";
import type { AthleteProps, EventProps, LeagueProps, MatchProps, OrderProps, PlayerFollowerProps, PlayerPodcastEpisodeProps, PlayerPodcastProps, PodcastEpisodesProps, PodcastProps, PostReactionProps, TeamProps, TournamentProps } from "../../types";
import Colors from "../../constants/colors";
import moment from "moment-mini";

let SOCIAL_SVC_API = '';
let EVENT_SVC_API = '';
let MK_SVC_API = '';

export { SocialComponentApi, SocialOrderHelpers, SocialPodcastHelpers }

const SocialComponentApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        SOCIAL_SVC_API = endpoints['SOCIAL_SVC_API'] as string;
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        MK_SVC_API = endpoints['MK_SVC_API'] as string;

    },
    getLeagues: async():Promise<LeagueProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues`);
            return resp.data.leagues   
        } catch (e) {
            return []
        }
    },
    reverseOrder: async(order:OrderProps):Promise<OrderProps | undefined> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/order/reverse`, { order })
            return resp.data.order
        } catch (e) {
            return undefined
        }
    },
    getBulkPlayerfollowers: async(player_ids:string[]) => {
        const resp = await axios.post(`${SOCIAL_SVC_API}/v1/followers/bulk/get`, { player_ids })
        return resp.data.player_followers
    },
    savePlayerFollower: async(player_follower:PlayerFollowerProps):Promise<PlayerFollowerProps> => {
        const resp = await axios.post(`${SOCIAL_SVC_API}/v1/followers/player/create`, { player_follower })
        return resp.data.player_follower
    },
    getEventsByEventIds: async(event_ids:string[]):Promise<EventProps[]> => {
        if(event_ids.length == 0){ return [] }
        try {
            const resp = await axios.post(`${EVENT_SVC_API}/v1/events/bulk/get`, { attribute:'event_id', values: event_ids })
            return resp.data.events
        } catch (e) {
            return []
        }
    },
    getMatchesByMatchIds: async(match_ids:string[]):Promise<MatchProps[]> => {
        if(match_ids.length == 0){ return [] }
        try {
            const resp = await axios.post(`${EVENT_SVC_API}/v1/matches/bulk/get`, { attribute: 'match_id', values: match_ids })
            return resp.data.matches
        } catch (e) {
            return []
        }
    },
    getAthletesByIds : async(athlete_ids:string[]):Promise<AthleteProps[]> => {
        if(athlete_ids.length == 0){ return [] }
        try {
            const resp = await axios.post(`${EVENT_SVC_API}/v1/athletes/bulk/get`, { attribute: 'athlete_id', values: athlete_ids })
            return resp.data.athletes
        } catch (e) {
            return []
        }
    },
    getTournamentsByTournamentIds: async(tournament_ids:string[]):Promise<TournamentProps[]> => {
        if(tournament_ids.length == 0){ return [] }
        try {
            const resp = await axios.post(`${EVENT_SVC_API}/v1/tournaments/bulk/get`, { attribute: 'event_id', values: tournament_ids })
            return resp.data.tournaments
        } catch (e) {
            return []
        }
    },
    getTeamsByIds: async(team_ids:string[]) => {
        if(team_ids.length == 0){ return [] }
        try {
            const resp = await axios.post(`${EVENT_SVC_API}/v1/teams/bulk/get`, { attribute:'team_id', values: team_ids })
            return resp.data.teams
        } catch (e) {
            return []
        }
    },
    createPostReaction: async(post_reaction:PostReactionProps):Promise<PostReactionProps|undefined> => {
        try {
            const resp = await axios.post(`${SOCIAL_SVC_API}/v1/posts/reaction/create`, { post_reaction });
            return resp.data.post_reaction
        } catch (e) {
            console.log(e);
            return undefined
        }
    },
    updatePostReaction : async(post_reaction:PostReactionProps):Promise<PostReactionProps|undefined> => {
        try {
            const resp = await axios.post(`${SOCIAL_SVC_API}/v1/posts/reaction/update`, { post_reaction })
            return resp.data.post_reaction
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    getPodcastById: async(podcast_id:string):Promise<PodcastProps | undefined> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/podcasts/podcast/${podcast_id}`);
            return resp.data.podcast
        } catch (e) {
            return undefined
        }
    },
    getPodcastEpisodeById: async(podcast_episode_id:string):Promise<PodcastEpisodesProps | undefined> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/podcasts/episodes/episode/${podcast_episode_id}`);
            return resp.data.podcast_episode
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    getPlayerPodcastEpisode: async(podcast_episode_id:string):Promise<undefined | { player_podcast?:PlayerPodcastProps, player_podcast_episode?:PlayerPodcastEpisodeProps }> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/podcasts/episode/player/${podcast_episode_id}`);
            return resp.data
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    startPodcastEpisode: async(podcast_episode_id:string):Promise<undefined | { player_podcast:PlayerPodcastProps, player_podcast_episode:PlayerPodcastEpisodeProps }> => {
        try {
            const resp = await axios.post(`${SOCIAL_SVC_API}/v1/podcasts/episode/start`, { podcast_episode_id });
            return resp.data
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    pausePodcastEpisode:async(podcast_episode_id:string, pause_timestamp:number):Promise<undefined | PlayerPodcastEpisodeProps> => {
        try {
            const resp = await axios.post(`${SOCIAL_SVC_API}/v1/podcasts/episode/pause`, { podcast_episode_id, pause_timestamp });
            return resp.data.player_podcast_episode
        } catch (e) {
            console.log(e)
            return undefined
        }
    }
}


const SocialOrderHelpers = {
    getIconColor: (order:OrderProps) => {
        switch(order.status){
            case 'approved':
                return Colors.brand.electric
            case 'closed':
                return Colors.brand.midnight
            case 'cancelled':
                return Colors.highlights.highlight200
            default:
                return Colors.shades.black
        }
    },
    getResultDetails: (order:OrderProps) => {
        if(order.resolution_status == 'inprogress'){ return { result_label: '', color:Colors.brand.midnight } }
        if(order.resolution_status == 'closed' && !order.positions[0]){ return { result_label: 'CANCELLED', color:Colors.brand.slate } }
        let position = order.positions[0]
        if(!position || position.status == 'inprogress'){ return { result_label: '', color:Colors.brand.midnight } }
        switch(position.result_ind){
            case 'win': return { result_label: 'WIN', color:Colors.utility.success }
            case 'lose': return { result_label: 'LOSE', color:Colors.utility.error }
            case 'draw': return { result_label: 'DRAW', color:Colors.brand.midnight }
            default: return { result_label: '', color:Colors.brand.midnight }
        }
    },
    calcPotentialWinnings :(odds:number, amt:number) => {
        let potential_winnings;
        if (odds > 0) {
            var earningsMultiplyer = (odds/100) + 1;
            potential_winnings = Math.round(earningsMultiplyer * amt * 100) / 100;
        } else {
            var earningsMultiplyer = (100/(Math.abs(odds))) + 1;
            potential_winnings = Math.round(earningsMultiplyer * amt * 100) / 100;
        }
        return potential_winnings
    },
    getIdsFromOrders: (orders:OrderProps[]) => {
        let event_ids:string[] = [], tournament_ids:string[] = [], match_ids:string[] = [], athlete_ids:string[] = [], team_ids:string[] = []
        orders.map(o => {
            if(o.event_type == 'team'){ event_ids.push(o.event_id) }
            if(o.event_type == 'tournament'){ tournament_ids.push(o.event_id) }
            if(o.event_type == 'match'){ match_ids.push(o.event_id) }
            if(o.side_type == 'athlete'){ athlete_ids.push(o.side_id) }
            if(o.side_type == 'team'){ team_ids.push(o.side_id) }
        })
        return { event_ids, tournament_ids, match_ids, athlete_ids, team_ids }
    },
    getContestDetails: (order:OrderProps, events:EventProps[], tournaments:TournamentProps[], matches:MatchProps[], athletes:AthleteProps[], teams:TeamProps[], leagues:LeagueProps[]):{ title:string, time:string, league?:LeagueProps, athlete?:AthleteProps, team?:TeamProps } => {
        let athlete = order.side_type == 'athlete' ? athletes.find(a => a.athlete_id == order.side_id) : undefined
        let team = order.side_type == 'team' ? teams.find(t => t.team_id == order.side_id) : undefined;
        
        switch(order.event_type){
            case 'team': 
                let event = events.find(e => e.event_id == order.event_id);
                if(!event){ return { team, athlete, title: '', time: '' } }
                let league = leagues.find(l => l.league_id == event.league_id);
                return { team, athlete, league, title: event.event_title, time: event.status == 'scheduled' ? moment(event.scheduled_datetime).format('MM/DD @ hh:mm a') : event.time_detail ?? '' }
            case 'match':
                let match = matches.find(m => m.match_id == order.event_id);
                if(!match){ return { team, athlete, title: '', time: '' } }
                let tourney = tournaments.find(t => t.tournament_id == match.tournament_id);
                let m_league = leagues.find(l => l.league_id == tourney?.league_id)
                return { team, athlete, league: m_league, title: match.match_title, time: moment(match.scheduled_datetime).format('MM/DD @ hh:mm a') }
            case 'tournament':
                let tournament = tournaments.find(t => t.tournament_id == order.event_id);
                if(!tournament){ return { title: '', time: '' } }
                const t_league = leagues.find(l => l.league_id == tournament.league_id)
                return { team, athlete, league: t_league, title: tournament.tournament_name, time: moment(tournament.scheduled_datetime).format('MM/DD @ hh:mm a') }
            default : return { title: '', time: '' }
        }
    },
    getOddsLabel: (odds:number, decimals?:number) => {
        let new_odds = odds.toFixed()
        if(decimals){ new_odds = odds.toFixed(decimals) }
        if(odds < 0){ return `${new_odds}` }
        return `+${new_odds}`
    }
}

const SocialPodcastHelpers = {
    getPodcastImage: (podcast:PodcastProps):string => {
        let image_source = podcast.image_override?.url
        if(!image_source){ image_source = podcast.image?.url }
        if(image_source && image_source !== ''){ return image_source }
        return 'https://res.cloudinary.com/hoabts6mc/image/upload/v1728494579/default_pod_nji5sk.webp'
    },
    getEpisodeImage: (podcast_episode?:PodcastEpisodesProps):string => {
        if(!podcast_episode){ return 'https://res.cloudinary.com/hoabts6mc/image/upload/v1728494579/default_pod_nji5sk.webp' }
        let image_source = podcast_episode.image_override?.url
        if(!image_source){ image_source = podcast_episode.image?.url }
        if(image_source && image_source != ''){ return image_source }
        return 'https://res.cloudinary.com/hoabts6mc/image/upload/v1728494579/default_pod_nji5sk.webp'
    },
    formatTime: (time:number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time - minutes * 60);
        const final_time = SocialPodcastHelpers.str_pad_left(minutes, '0', 2) + ':' + SocialPodcastHelpers.str_pad_left(seconds, '0', 2);
        return final_time
    },
    str_pad_left: (string:number, pad:string, length:number) => {
        return (new Array(length + 1).join(pad) + string).slice(-length);
    }
}