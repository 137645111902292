import React, { useEffect, useState } from 'react';
import { View, ScrollView, TouchableOpacity, Image, FlatList } from "react-native";
import { view_styles } from '../../constants/styles';
import { Button, Icons, Text, TextInput } from '../../Components';
import type { CompanyProps, CompetitionSeasonProps } from '../../types';
import { ManageSeasonApi, ManageSeasonHelpers } from '../api';
import Colors from '../../constants/colors';

type CreateSeasonFormProps = {
    width:number,
    max_height?:number,
    onCreateSeason:(cs:CompetitionSeasonProps) => void,
    onClose:() => void
}

const CreateSeasonForm = ({ width, max_height, onCreateSeason, onClose }:CreateSeasonFormProps) => {
    const [ action_loading, setActionLoading ] = useState(false);
    const [ draft_season, setDraftSeason ] = useState<CompetitionSeasonProps>(ManageSeasonHelpers.getPendingSeason());
    const [ show_companies, setShowCompanies ] = useState(false);
    const [ show_templates, setShowTemplates ] = useState(false);

    const [ form_data, setFormData ] = useState<{
        templates:CompetitionSeasonProps[],
        companies:CompanyProps[]
    }>({
        templates: [],
        companies: []
    });
    const { templates, companies } = form_data;

    const selected_company = companies.find(c => c.company_id == draft_season.company_id);
    const selected_template = templates.find(cs => cs.competition_season_id == draft_season.following_season_id)

    useEffect(() => {
        ManageSeasonApi.setEnvironment();
        getFormData()
    },[])

    const getFormData = async() => {
        const temps = await ManageSeasonApi.getSeasonTemplates();
        const company_resp = await ManageSeasonApi.getMyCompanies();
        setFormData({
            ...form_data,
            companies: company_resp.companies,
            templates:temps
        })
    }

    const handleCreate = async(season:CompetitionSeasonProps) => {
        setActionLoading(true);
        const new_season = await ManageSeasonApi.createSeason(season);
        console.log(new_season)
        onCreateSeason(new_season);
        setActionLoading(false);
    }

    const renderCompanies = (data: { item:CompanyProps, index:number }) => {
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10 }} onPress={() => {
                setDraftSeason({ ...draft_season, company_id: data.item.company_id })
                setShowCompanies(false)
            }}>
                <Image
                    source={{ uri: data.item.company_image?.url }}
                    style={{ height:50, width:50, borderRadius:4 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header_2">{data.item.company_name}</Text>
                    <Text style={{ marginTop:3 }} theme="body">{data.item.company_description}</Text>
                </View>
            </TouchableOpacity>
        )
    }


    const renderTemplates = (data: { item:CompetitionSeasonProps, index:number }) => {
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10 }} onPress={() => {
                setDraftSeason({ ...draft_season, following_season_id: data.item.competition_season_id })
                setShowTemplates(false)
            }}>
                <Image
                    source={{ uri: data.item.image?.url }}
                    style={{ height:50, width:50, borderRadius:4 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header_2">{data.item.season_name}</Text>
                    <Text style={{ marginTop:3 }} theme="body">{data.item.season_description}</Text>
                </View>
            </TouchableOpacity>
        )
    }



    if(!draft_season){ return <></> }

    const errors = ManageSeasonHelpers.isSeasonValid(draft_season);

    return (
        <View style={{ ...view_styles.section, width,  maxHeight:max_height ?? 700, }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Create New Season</Text>
                </View>
            </View>
            <ScrollView style={{ ...view_styles.section_body, flex:1 }}>
                <View style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width }}>
                    <View style={{ margin:5 }}>
                        <Text theme='header_2'>Season Name</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Give your season a memorable name.</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, flex:1, margin:5 }}
                        value={draft_season?.season_name}
                        placeholder='Season Name'
                        placeholderTextColor={Colors.brand.slate}
                        onChangeText={(text) => setDraftSeason({ ...draft_season, season_name: text })}
                    />
                </View>
                {companies.length > 0 ?
                <View style={{ padding:10 }}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme="header">Company</Text>
                        <Text style={{ marginTop:3 }} theme="body">Is this competition associated with one of your companies / brands?</Text>
                    </View>
                    <View style={{ padding:10, borderRadius:4, borderWidth:1, borderColor:Colors.shades.shade600, backgroundColor:Colors.shades.white }}>
                        <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }} onPress={() => setShowCompanies(!show_companies)}>
                            {selected_company ?
                            <View style={{ flex:1, flexDirection:'row' }}>
                                <Image
                                    source={{ uri: selected_company.company_image?.url }}
                                    style={{ height:50, width:50, borderRadius:4 }}
                                    resizeMode="cover"
                                />
                                <View style={{ flex:1, marginLeft:10 }}>
                                    <Text theme="header_2">{selected_company.company_name}</Text>
                                    <Text style={{ marginTop:3 }} theme="body">{selected_company.company_description}</Text>
                                </View>
                            </View>
                            :
                            <View style={{ flex:1 }}>
                                <Text theme="header_2">View Companies</Text>
                            </View>
                            }
                            <Icons.ChevronIcon direction={show_companies ? 'up' : 'down'} size={8} color={Colors.brand.midnight}/>
                        </TouchableOpacity>
                        {show_companies ?
                        <View>
                            <TouchableOpacity style={{ padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => {
                                setDraftSeason({ ...draft_season, company_id:undefined })
                                setShowCompanies(false);
                            }}>
                                <Text theme="header_2">NO COMPANY</Text>
                            </TouchableOpacity>
                            <FlatList
                                data={companies}
                                renderItem={renderCompanies}
                                keyExtractor={(item) => item.company_id.toString()}
                            />
                        </View>
                        :<></>}
                    </View>
                </View>
                :<></>}
                {templates.length > 0 ?
                <View style={{ padding:10 }}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme="header">Create From Template</Text>
                        <Text style={{ marginTop:3 }} theme="body">Optionally you can select a template to auto create this competition from</Text>
                    </View>
                    <View style={{ padding:10, borderRadius:4, borderWidth:1, borderColor:Colors.shades.shade600, backgroundColor:Colors.shades.white }}>
                        <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }} onPress={() => setShowTemplates(!show_templates)}>
                            {selected_template ?
                            <View style={{ flex:1, flexDirection:'row' }}>
                                <Image
                                    source={{ uri: selected_template.image?.url }}
                                    style={{ height:50, width:50, borderRadius:4 }}
                                    resizeMode="cover"
                                />
                                <View style={{ flex:1, marginLeft:10 }}>
                                    <Text theme="header_2">{selected_template.season_name}</Text>
                                    <Text style={{ marginTop:3 }} theme="body">{selected_template.season_description}</Text>
                                </View>
                            </View>
                            :
                            <View style={{ flex:1 }}>
                                <Text theme="header_2">View Templates</Text>
                            </View>
                            }
                            <Icons.ChevronIcon direction={show_templates ? 'up' : 'down'} size={8} color={Colors.brand.midnight}/>
                        </TouchableOpacity>
                        {show_templates ?
                        <View>
                            <TouchableOpacity style={{ padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => {
                                setDraftSeason({ ...draft_season, following_season_id:undefined })
                                setShowTemplates(false);
                            }}>
                                <Text theme="header_2">NO TEMPLATE</Text>
                            </TouchableOpacity>
                            <FlatList
                                data={templates}
                                renderItem={renderTemplates}
                                keyExtractor={(item) => item.competition_season_id.toString()}
                            />
                        </View>
                        :<></>}
                    </View>
            </View>
            :<></>}
            </ScrollView>
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title="CLOSE"
                    title_color={Colors.shades.white}
                    style={{ flex:1, marginRight:8 }}
                    padding={15}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
                <Button
                    title="CREATE SEASON"
                    title_color={Colors.shades.white}
                    loading={action_loading}
                    backgroundColor={Colors.utility.success}
                    padding={15}
                    style={{ flex:2, opacity: !action_loading && errors.length == 0 ? 1 : 0.5 }}
                    disabled={errors.length > 0 || action_loading ? true : false}
                    onPress={() => handleCreate(draft_season)}
                />
            </View>
        </View>
    )
}

export default CreateSeasonForm