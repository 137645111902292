import React, { useEffect, useState } from 'react';
import { View, FlatList } from 'react-native';
import type { AthleteProps, EventProps, LeagueProps, MatchProps, OrderProps, PublicPlayerProps, TeamProps, TournamentProps } from '../types';
import SocialOrderCard from './SocialOrderCard';
import { SocialComponentApi, SocialOrderHelpers } from './api';

type SocialOrdersListProps = {
    players:PublicPlayerProps[],
    show_player?:boolean,
    orders:OrderProps[],
    maxWidth?:number,
    direction: 'vertical'|'horizontal',
    onCopyOrder?:(order:OrderProps) => void,
    onFadeOrder?:(order:OrderProps) => void
}
const SocialOrdersList = ({ maxWidth, players, show_player, orders, direction, onCopyOrder, onFadeOrder }:SocialOrdersListProps) => {
    const [list_data, setListData] = useState<{
        loading:boolean,
        leagues:LeagueProps[],
        events:EventProps[],
        tournaments:TournamentProps[],
        matches:MatchProps[],
        athletes:AthleteProps[],
        teams:TeamProps[]
    }>({
        loading:false,
        leagues:[],
        events:[],
        tournaments:[],
        matches:[],
        athletes:[],
        teams:[]
    });
    const { leagues, events, tournaments, matches, athletes, teams } = list_data;

    useEffect(() => {
        SocialComponentApi.setEnvironment()
        getDataFromServer();
    },[orders.length])


    const getDataFromServer = async() => {
        setListData({ ...list_data, loading:true });
        //Get event_ids
        const { event_ids, tournament_ids, match_ids, athlete_ids, team_ids } = SocialOrderHelpers.getIdsFromOrders(orders);
        let l = await SocialComponentApi.getLeagues();
        let e = await SocialComponentApi.getEventsByEventIds(event_ids);
        let t = await SocialComponentApi.getTournamentsByTournamentIds(tournament_ids);
        let m = await SocialComponentApi.getMatchesByMatchIds(match_ids);
        let a = await SocialComponentApi.getAthletesByIds(athlete_ids);
        let tm = await SocialComponentApi.getTeamsByIds(team_ids);
        //Now lets grab the data!
        setListData({
            ...list_data,
            loading:false,
            leagues: l,
            events: e,
            tournaments: t,
            matches: m,
            athletes: a,
            teams: tm
        })

    }


    const renderOrders = (data:{item:OrderProps, index:number}) => {
        let { title, time, league, team, athlete } = SocialOrderHelpers.getContestDetails(data.item, events, tournaments, matches, athletes, teams, leagues);
        let player = players.find(p => p.player_id == data.item.player_id)
        return (
            <View>
                <SocialOrderCard
                    show_player={show_player}
                    player={player}
                    league={league}
                    team={team}
                    maxWidth={maxWidth}
                    athlete={athlete}
                    contest_title={title}
                    contest_time={time}
                    order={data.item}
                    onFadeOrder={onFadeOrder}
                    onCopyOrder={onCopyOrder}
                />
            </View>
        )
    }

    return (
        <View style={{ justifyContent:'center' }}>
            <FlatList
                data={orders}
                renderItem={renderOrders}
                horizontal={direction == 'horizontal' ? true : false}
                keyExtractor={(item) => item.order_id.toString()}
            />
        </View>
    )
}

export default SocialOrdersList