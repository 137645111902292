import type { CompanyProps, CompetitionProps, CompetitionSeasonProps } from "../../types"
import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Image, FlatList, ScrollView } from 'react-native';
import { view_styles } from "../../constants/styles";
import { Button, Icons, Text, TextInput } from "../../Components";
import { ManageCompetitionApi, ManageCompetitionHelpers } from "../api";
import Colors from "../../constants/colors";

type CreateCompetitionFormProps = {
    width:number,
    competition_season?:CompetitionSeasonProps,
    max_height?:number,
    onCreate: (competition:CompetitionProps) => void,
    onClose: () => void
}

const CreateCompetitionForm = ({ competition_season, width, max_height, onCreate, onClose }:CreateCompetitionFormProps) => {
    const [ action_loading, setActionLoading ] = useState(false);
    const [ draft_competition, setDraftCompetition ] = useState(ManageCompetitionHelpers.getEmptyCompetition())
    const [ form_data ,setFormData ] = useState<{
        template_competitions:CompetitionProps[],
        companies:CompanyProps[]
    }>({
        template_competitions:[],
        companies: []
    });
    const { template_competitions, companies } = form_data;
    const [ show_templates, setShowTemplates ] = useState(false);
    const [ show_companies, setShowCompanies ] = useState(false);

    const selected_template = template_competitions.find(c => c.competition_id == draft_competition.template_competition_id)
    const selected_company = companies.find(c => c.company_id == draft_competition.company_id);


    useEffect(() => {
        ManageCompetitionApi.setEnvironment();
        getDataFromServer()
    },[])

    const getDataFromServer = async() => {
        const templates = await ManageCompetitionApi.getTemplateCompetitions();
        const company_response = await ManageCompetitionApi.getMyCompanies();
        setFormData({
            ...form_data,
            template_competitions: templates,
            companies: company_response.companies
        })
    }

    const handleCreate = async(comp:CompetitionProps) => {
        setActionLoading(true);
        let company_id = comp.company_id
        if(competition_season){ company_id = competition_season.company_id }
        const created_competition = await ManageCompetitionApi.createCompetition(company_id, selected_company?.legacy_id, comp.competition_name, comp.template_competition_id, competition_season?.competition_season_id);
        onCreate(created_competition)
        setActionLoading(false)
    }

    const renderCompanies = (data: { item:CompanyProps, index:number }) => {
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10 }} onPress={() => {
                setDraftCompetition({ ...draft_competition, company_id: data.item.company_id })
                setShowCompanies(false)
            }}>
                <Image
                    source={{ uri: data.item.company_image?.url }}
                    style={{ height:50, width:50, borderRadius:4 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header_2">{data.item.company_name}</Text>
                    <Text style={{ marginTop:3 }} theme="body">{data.item.company_description}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    const renderTemplates = (data: { item:CompetitionProps, index:number }) => {
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10 }} onPress={() => {
                setDraftCompetition({ ...draft_competition, template_competition_id: data.item.competition_id })
                setShowTemplates(false)
            }}>
                <Image
                    source={{ uri: data.item.image?.url }}
                    style={{ height:50, width:50, borderRadius:4 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header_2">{data.item.competition_name}</Text>
                    <Text style={{ marginTop:3 }} theme="body">{data.item.competition_description}</Text>
                </View>
            </TouchableOpacity>
        )
    }


    return (
        <View style={{ maxWidth:width, minWidth:300, maxHeight:max_height ?? 700, backgroundColor:Colors.shades.white }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>CREATE COMPETITION</Text>
                    <Text style={{ marginTop:3 }} theme="body">Give the competition a name!</Text>
                </View>
            </View>
            {competition_season ?
            <View style={{ backgroundColor:Colors.incentive.gold_faded, flexDirection:'row', padding:10, alignItems:'center' }}>
                <Image
                    source={{ uri: competition_season.image?.url }}
                    style={{ height:30, width:30, borderRadius:4 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header_2">Season:</Text>
                    <Text style={{ marginTop:3 }} theme="header">{competition_season.season_name}</Text>
                </View>
            </View>
            :<></>}
            <ScrollView style={{ ...view_styles.section_body, maxWidth:width, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth: width }}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme="header">Competition Name</Text>
                        <Text style={{ marginTop:3 }} theme="body">Give the competition a memorable name</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, margin:5, flexGrow:1 }}
                        value={draft_competition.competition_name}
                        placeholder="Name this competition"
                        placeholderTextColor={Colors.brand.slate}
                        onChangeText={(text) => setDraftCompetition({ ...draft_competition, competition_name:text })}
                    />
                </View>
                {!competition_season && companies.length > 0 ?
                <View style={{ padding:10 }}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme="header">Company</Text>
                        <Text style={{ marginTop:3 }} theme="body">Is this competition associated with one of your companies / brands?</Text>
                    </View>
                    <View style={{ padding:10, borderRadius:4, borderWidth:1, borderColor:Colors.shades.shade600, backgroundColor:Colors.shades.white }}>
                        <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }} onPress={() => setShowCompanies(!show_companies)}>
                            {selected_company ?
                            <View style={{ flex:1, flexDirection:'row' }}>
                                <Image
                                    source={{ uri: selected_company.company_image?.url }}
                                    style={{ height:50, width:50, borderRadius:4 }}
                                    resizeMode="cover"
                                />
                                <View style={{ flex:1, marginLeft:10 }}>
                                    <Text theme="header_2">{selected_company.company_name}</Text>
                                    <Text style={{ marginTop:3 }} theme="body">{selected_company.company_description}</Text>
                                </View>
                            </View>
                            :
                            <View style={{ flex:1 }}>
                                <Text theme="header_2">View Companies</Text>
                            </View>
                            }
                            <Icons.ChevronIcon direction={show_companies ? 'up' : 'down'} size={8} color={Colors.brand.midnight}/>
                        </TouchableOpacity>
                        {show_companies ?
                        <View>
                            <TouchableOpacity style={{ padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => {
                                setDraftCompetition({ ...draft_competition, company_id:undefined })
                                setShowCompanies(false);
                            }}>
                                <Text theme="header_2">NO COMPANY</Text>
                            </TouchableOpacity>
                            <FlatList
                                data={companies}
                                renderItem={renderCompanies}
                                keyExtractor={(item) => item.company_id.toString()}
                            />
                        </View>
                        :<></>}
                    </View>
                </View>
                :<></>}
                {template_competitions.length > 0 ?
                <View style={{ padding:10 }}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme="header">Create From Template</Text>
                        <Text style={{ marginTop:3 }} theme="body">Optionally you can select a template to auto create this competition from</Text>
                    </View>
                    <View style={{ padding:10, borderRadius:4, borderWidth:1, borderColor:Colors.shades.shade600, backgroundColor:Colors.shades.white }}>
                        <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }} onPress={() => setShowTemplates(!show_templates)}>
                            {selected_template ?
                            <View style={{ flex:1, flexDirection:'row' }}>
                                <Image
                                    source={{ uri: selected_template.image?.url }}
                                    style={{ height:50, width:50, borderRadius:4 }}
                                    resizeMode="cover"
                                />
                                <View style={{ flex:1, marginLeft:10 }}>
                                    <Text theme="header_2">{selected_template.competition_name}</Text>
                                    <Text style={{ marginTop:3 }} theme="body">{selected_template.competition_description}</Text>
                                </View>
                            </View>
                            :
                            <View style={{ flex:1 }}>
                                <Text theme="header_2">View Templates</Text>
                            </View>
                            }
                            <Icons.ChevronIcon direction={show_templates ? 'up' : 'down'} size={8} color={Colors.brand.midnight}/>
                        </TouchableOpacity>
                        {show_templates ?
                        <View>
                            <TouchableOpacity style={{ padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => {
                                setDraftCompetition({ ...draft_competition, template_competition_id:undefined })
                                setShowTemplates(false);
                            }}>
                                <Text theme="header_2">NO TEMPLATE</Text>
                            </TouchableOpacity>
                            <FlatList
                                data={template_competitions}
                                renderItem={renderTemplates}
                                keyExtractor={(item) => item.competition_id.toString()}
                            />
                        </View>
                        :<></>}
                    </View>
            </View>
            :<></>}
            </ScrollView>
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title="CLOSE"
                    title_color={Colors.shades.white}
                    style={{ flex:1, marginRight:8 }}
                    padding={15}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
                <Button
                    title="CREATE COMPETITION"
                    title_color={Colors.shades.white}
                    loading={action_loading}
                    backgroundColor={Colors.utility.success}
                    padding={15}
                    style={{ flex:2, opacity: !action_loading && draft_competition.competition_name ? 1 : 0.5 }}
                    disabled={!draft_competition.competition_name || action_loading ? true : false}
                    onPress={() => handleCreate(draft_competition)}
                />
            </View>
        </View>
    )
}

export default CreateCompetitionForm