import React, { useState } from 'react';
import { View } from "react-native"
import type { BracketCompetitionProps, CompetitionProps, CompetitionSeasonProps, SquaresCompetitionProps } from '../../types';
import { view_styles } from '../../constants/styles';
import { Button, Icons, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import { EngageApi } from '../api';

type PrivateCodePromptProps = {
    width:number,
    onFoundCompetition:(c:CompetitionProps) => void,
    onFoundSquaresCompetition:(sc:SquaresCompetitionProps) => void,
    onFoundSeason:(cs:CompetitionSeasonProps) => void,
    onFoundBracket:(b:BracketCompetitionProps) => void,
    onClose:() => void
}

const PrivateCodePrompt = ({ width, onFoundCompetition, onFoundSquaresCompetition, onFoundSeason, onFoundBracket, onClose }:PrivateCodePromptProps) => {
    const [ search, setSearch ] = useState<{
        loading:boolean,
        value: string,
    }>({
        loading:false,
        value: ''
    });
    const [ error, setError ] = useState<string|undefined>();
    const { loading, value } = search;

    const handleSearch = async() => {
        setSearch({ ...search, loading:true });
        const result = await EngageApi.getPrivateEngage(value);
        setSearch({ ...search, loading:false });
        if(result.competition){ return onFoundCompetition(result.competition) }
        if(result.competition_season){ return onFoundSeason(result.competition_season) }
        if(result.bracket_competition){ return onFoundBracket(result.bracket_competition) }
        if(result.squares_competition){ return onFoundSquaresCompetition(result.squares_competition) }
        setError('Sorry! We could not find any competitions, squares, brackets with that code.')
    }

    const search_valid = value ? true : false
    return (
        <View style={{ ...view_styles.section, width }}>
            <View style={{ ...view_styles.section_header, padding:15 }}>
                <Icons.SearchIcon color={Colors.brand.midnight} size={20}/>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>Find Private Competition!</Text>
                </View>
            </View>
            <View style={{ ...view_styles.section_body }}>
                <View style={{ borderRadius:8, backgroundColor:Colors.shades.shade100, padding:10 }}>
                    <Text size={18} color={Colors.brand.midnight} textAlign='center' weight='bold'>Did you receive a private code?</Text>
                    <Text style={{ marginTop:10 }} size={14} color={Colors.brand.midnight} textAlign='center' weight='regular'>Enter the code in the input section below and press FIND.  If we find the competition / squares / bracket, we will send you to it!</Text>

                    <TextInput
                        style={{ ...view_styles.input, marginTop:20, textAlign:'center', borderRadius:22, padding:15 }}
                        placeholder='Enter Private Code'
                        placeholderTextColor={Colors.brand.slate}
                        onChangeText={(text) => {
                            if(error){ setError(undefined) }
                            setSearch({ ...search, value:text })
                        }}
                    />
                    {error?
                    <View style={{ marginTop:10, padding:10 }}>
                        <Text size={14} color={Colors.utility.error} weight='bold' textAlign='center'>{error}</Text>
                    </View>
                    :<></>}
                </View>
            </View>
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title='CANCEL'
                    backgroundColor={Colors.utility.error}
                    title_color={Colors.shades.white}
                    style={{ marginRight:5 }}
                    padding={15}
                    onPress={() => onClose()}
                />
                <Button
                    style={{ flex:1, opacity:search_valid&&!loading?1:0.5 }}
                    padding={15}
                    disabled={loading || !search_valid}
                    loading={loading}
                    backgroundColor={Colors.utility.success}
                    title_color={Colors.shades.white}
                    title='FIND'
                    onPress={async() => handleSearch()}
                />
            </View>
        </View>
    )
}

export default PrivateCodePrompt