import React, { useEffect, useRef, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import DraftTextViewer from './components/DraftTextViewer';
import type { OrderProps, PostCommentStatProps, PostProps, PostReactionProps, PostReactionStatsProps, PublicPlayerProps } from '../../types';
import PostHeader from './components/PostHeader';
import { view_styles } from '../../constants/styles';
import { useIsInViewport } from '../../Components/ViewportObserver';
import { SocialProfileApi } from '../PlayerProfile/api';

import PostReactionBar from './components/PostReactionBar';
import Colors from '../../constants/colors';
import { Icons, Text } from '../../Components';
import SocialOrdersList from '../SocialOrdersList';
import ImageList from './components/ImageList';
import { SocialComponentApi } from '../api';

type PostCardProps = {
    post: PostProps,
    player:PublicPlayerProps,
    orders:OrderProps[],
    player_id?:string,
    onRequestAuthenticate:() => void,
    onCopyOrder?:(order:OrderProps) => void,
    onFadeOrder?:(order:OrderProps) => void,
    onViewComments:(post_id:string) => void
}
const PostCard = ({ player_id, post, player, orders, onRequestAuthenticate, onCopyOrder, onFadeOrder, onViewComments }:PostCardProps) => {
    const [ post_data, setPostData ] = useState<{
        loading:boolean,
        reactions_loaded:boolean,
        post_comment_stat:PostCommentStatProps,
        post_reaction_stats:PostReactionStatsProps[],
    }>({
        loading:false,
        reactions_loaded: false,
        post_comment_stat: { activity_post_id:post.memo_post_id, comment_count: 0 },
        post_reaction_stats: []
    })
    const { reactions_loaded, post_reaction_stats, post_comment_stat } = post_data;
    
    const [ my_data, setMyData ] = useState<{
        my_data_loading:boolean,
        my_reactions:PostReactionProps[]
    }>({
        my_data_loading:false,
        my_reactions:[]
    })
    const { my_reactions, my_data_loading } = my_data;
    const post_ref = useRef();
    const is_in_viewport = useIsInViewport(post_ref);

    useEffect(() => {
        if(!is_in_viewport || reactions_loaded){ return }
        getPostReactionData();
        if(player_id){ getMyData(player_id) }
    },[is_in_viewport]);

    const getMyData = async(player_id:string) => {
        setMyData({ ...my_data, my_data_loading: true });
        const mr = await SocialProfileApi.getReactionsByPostAndPlayer(player_id, post.memo_post_id, 'memo');
        setMyData({
            ...my_data,
            my_data_loading: false,
            my_reactions: mr
        })
    }

    const getPostReactionData = async() => {
        setPostData({ ...post_data, loading: true });
        const pr_stats = await SocialProfileApi.getReactionStatsByPostId(post.memo_post_id, 'memo');
        const pc_stat = await SocialProfileApi.getCommentStatsByPostId(post.memo_post_id, 'memo');
        setPostData({
            ...post_data,
            reactions_loaded:true,
            loading: false,
            post_reaction_stats: pr_stats,
            post_comment_stat: pc_stat ? pc_stat: post_comment_stat
        })
    }

    const handleReaction = async(reaction:PostReactionProps) => {
        if(!player_id){ return onRequestAuthenticate() }
        setMyData({ ...my_data, my_data_loading: true });
        if(reaction.status == 'visible'){
            let new_reaction = await SocialComponentApi.createPostReaction(reaction);
            if(!new_reaction){ return setMyData({ ...my_data, my_data_loading: false }) }
            setMyData({ 
                ...my_data, 
                my_data_loading:false,
                my_reactions: my_reactions.concat(new_reaction)
            }) 
            let reaction_stats = post_reaction_stats.find(s => s.reaction == reaction.reaction)
            if(!reaction_stats){ reaction_stats = { activity_post_id: post.memo_post_id, reaction:reaction.reaction, reaction_count:1 } }
            else { reaction_stats = { ...reaction_stats, reaction_count: reaction_stats.reaction_count + 1 } }
            //IUpdate reaction stats
            setPostData({
                ...post_data,
                post_reaction_stats: post_reaction_stats.filter(ps => ps.reaction != reaction.reaction).concat(reaction_stats)
            })
        } else {
            let updated_reaction = await SocialComponentApi.updatePostReaction(reaction);
            if(!updated_reaction){ return setMyData({ ...my_data, my_data_loading: false }) }
            
            setMyData({
                ...my_data,
                my_data_loading: false,
                my_reactions: my_reactions.filter(r => r.post_reaction_id != updated_reaction.post_reaction_id)
            })

            let reaction_stats = post_reaction_stats.find(s => s.reaction == reaction.reaction)
            if(!reaction_stats){ reaction_stats = { activity_post_id: post.memo_post_id, reaction:reaction.reaction, reaction_count:0 } }
            else { reaction_stats = { ...reaction_stats, reaction_count: reaction_stats.reaction_count - 1 } }
            setPostData({
                ...post_data,
                post_reaction_stats: post_reaction_stats.filter(ps => ps.reaction != reaction.reaction).concat(reaction_stats)
            })
        }
    }

    return (
        <View ref={post_ref as any} style={{ ...view_styles.section, padding:0, borderWidth:1, borderColor:Colors.shades.shade600 }}>
            <PostHeader 
                player={player}
                post={post}
            />
            <View style={{  padding:10 }}>
                <Text theme='header_2'>{post.title}</Text>
            </View>
            <View style={{ padding:10, paddingTop:0, paddingBottom:0 }}>
                <DraftTextViewer 
                    post_id={post.memo_post_id}
                    formatted_body={post.formatted_body}
                    view_type='memo'
                    text_fallback={post.body}
                />
            </View>
            {post.memo_orders && post.memo_orders.length > 0 ?
            <SocialOrdersList
                players={[player]}
                orders={orders}
                maxWidth={275}
                direction='horizontal'
                onCopyOrder={onCopyOrder}
                onFadeOrder={onFadeOrder}
            />
            :<></>}
            {post.memo_images && post.memo_images.length > 0 ?
            <ImageList
                post_id={post.memo_post_id}
                images={post.memo_images}
                width={300}
            />
            :<></>}
            <View style={{ ...view_styles.section_footer, padding:5, backgroundColor:Colors.shades.white }}>
                <View style={{}}>
                    <PostReactionBar
                        loading={my_data_loading}
                        post_id={post.memo_post_id}
                        post_reaction_stats={post_reaction_stats}
                        my_post_reactions={my_reactions}
                        onReactionSelect={(r) => handleReaction(r)}
                    />
                </View>
                <TouchableOpacity
                    style={{ flex:1, flexDirection:'row', alignItems:'center', justifyContent:'flex-end' }}
                    onPress={() => onViewComments(post.memo_post_id)}
                >
                    <Text style={{ marginRight:4 }} theme='header_2'>{post_comment_stat.comment_count}</Text>
                    <Icons.ChatIcon size={18} color={Colors.brand.midnight}/>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default PostCard
