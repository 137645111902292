import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, TouchableOpacity, ScrollView } from 'react-native';
import { Button, Icons, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import { ProfileApi } from '../api';
import { button_styles } from '../../constants/styles';
import type { MyPlayerProps } from '../../types';

type BasicInfoManagerProps = {
    player:MyPlayerProps,
    walkthrough?:boolean,
    onNext:() => void,
    onClose:() => void,
    onPlayerUpdate:(player:MyPlayerProps) => void 
}

const BasicInfoManager = ({ player, walkthrough, onPlayerUpdate, onNext, onClose }:BasicInfoManagerProps) => {
    const [ draft_player, setDraftPlayer ] = useState<MyPlayerProps>(player)
    const [ loading, setLoading ] = useState(false);
    const [ username_valid, setUsernameValid ] = useState<{
        loading:boolean,
        valid:boolean
    }>({
        loading:false,
        valid:true
    });

    useEffect(() => {
        setDraftPlayer(player)
    },[player])


    const is_changed = JSON.stringify(player) != JSON.stringify(draft_player) ? true : false

    const updatePlayer = async() => {
        if(loading){ return }
        setLoading(true)
        let new_player:MyPlayerProps = { ...draft_player };
        if(`${draft_player.first_name.toLowerCase()}${draft_player.last_name.toLowerCase()}` != `${player.first_name.toLowerCase()}${player.last_name.toLowerCase()}`){
            new_player = await ProfileApi.updatePlayerName(draft_player.first_name, draft_player.last_name)
        }
        if(player.username.toLowerCase() != draft_player.username.toLowerCase()){
            if(!username_valid.valid){ return }
            new_player = await ProfileApi.updatePlayerUsername(draft_player.username)
        }
        onPlayerUpdate(new_player)
        setLoading(false);
        return
    }

    const handleNext = async() => {
        if(!is_changed){ return onNext() }
        await updatePlayer()
        return onNext()
    }

    const allow_next = username_valid.valid && !username_valid.loading && draft_player.first_name && draft_player.last_name ? true : false

    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1 }}>
                {walkthrough ?
                <View style={{ padding:20 }}>
                    <Text size={30} color={Colors.brand.midnight} weight='bold'>Let's start with the basics</Text>
                    <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>You can change this later, but will be required to verify your account.</Text>
                </View>
                :<></>}
                <View style={{ padding:30 }}>
                    <Text weight='bold' size={18} color={Colors.brand.midnight}>USERNAME</Text>
                    <Text style={{ marginTop:5 }} color={Colors.brand.midnight} size={14} textAlign='left'>To start, we randomly generate a username for you!  Change it if you'd like here.</Text>
                    <View style={{ marginTop:20, backgroundColor:Colors.shades.white, borderRadius:22, flexDirection:'row', alignItems:'center' }}>
                        <View style={{ paddingLeft:15, paddingRight:15 }}>
                            <Text size={20} color={Colors.brand.midnight} weight='bold'>@</Text>
                        </View>
                        <TextInput
                            value={draft_player.username}
                            style={{ flex:1, borderRadius:0 }}
                            onBlur={async() => {
                                if(draft_player.username.toLowerCase() == player.username.toLowerCase()){ return }//USERNAME HAS NOT CHANGED
                                setUsernameValid({ ...username_valid, loading:true })
                                const is_valid = await ProfileApi.isAttributeValid('username', draft_player.username)
                                setTimeout(() => {
                                    setUsernameValid({ loading: false, valid: is_valid });
                                }, 500);
                            }}
                            onChangeText={(text) => {
                                setDraftPlayer({ ...draft_player, username: text })
                                if(text.toLowerCase() != player.username.toLowerCase()){
                                    setUsernameValid({ loading:false, valid:false })
                                } else {
                                    setUsernameValid({ loading: false, valid:true });
                                }
                            }}
                        />
                        <View style={{ paddingLeft:15, paddingRight:15 }}>
                        {username_valid.loading ?
                        <ActivityIndicator size='small' color={Colors.brand.midnight}/>
                        :
                        <View>
                            {username_valid.valid ?
                            <Icons.CheckIcon size={16} color={Colors.utility.success} />
                            :
                            <Icons.AlertIcon size={16} color={Colors.utility.warning} />
                            }
                        </View>
                        }
                        </View>
                    </View>
                </View>

                <View style={{ padding:30 }}>
                    <Text weight='bold' size={18} textAlign='left' color={Colors.brand.midnight}>NAME</Text>
                    <Text style={{ marginTop:5 }} color={Colors.brand.midnight} size={14} textAlign='left'>Add / Update your name below.  This is required to verify your account</Text>
                    <View style={{ marginTop:20, backgroundColor:Colors.shades.white, borderRadius:22, flexDirection:'row', alignItems:'center' }}>
                        <View style={{ paddingLeft:15, paddingRight:15 }}>
                            <Icons.UserIcon
                                color={Colors.brand.midnight}
                                size={18}
                            />
                        </View>
                        <TextInput
                            value={draft_player.first_name}
                            placeholder='First Name'
                            placeholderTextColor={Colors.brand.slate}
                            editable={player.vouched_status != 'verified' ? true : false}
                            style={{ flex:1, borderTopLeftRadius:0, borderBottomLeftRadius:0 }}
                            onChangeText={(text) => setDraftPlayer({ ...draft_player, first_name: text })}
                        />
                    </View>
                    <View style={{ marginTop:10, backgroundColor:Colors.shades.white, borderRadius:22, flexDirection:'row', alignItems:'center' }}>
                        <View style={{ paddingLeft:15, paddingRight:15 }}>
                            <Icons.UserIcon
                                color={Colors.brand.midnight}
                                size={18}
                            />
                        </View>
                        <TextInput
                            value={draft_player.last_name}
                            placeholder='Last Name'
                            placeholderTextColor={Colors.brand.slate}
                            editable={player.vouched_status != 'verified' ? true : false}
                            style={{ flex:1, borderTopLeftRadius:0, borderBottomLeftRadius:0 }}
                            onChangeText={(text) => setDraftPlayer({ ...draft_player, last_name: text })}
                        />
                    </View>
                </View> 
            </ScrollView>
            {walkthrough ?
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    title='EXIT SETUP'
                    title_color={Colors.utility.error}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
                <TouchableOpacity 
                    disabled={!allow_next}
                    style={allow_next ? button_styles.wizard_valid : button_styles.wizard_invalid}
                    onPress={() => handleNext()}
                    >
                    <Icons.ChevronIcon size={20} direction='right' color={Colors.brand.midnight}/>
                </TouchableOpacity>
            </View>
            :
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    style={{ flex:1, marginRight:5 }}
                    title='BACK'
                    padding={15}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onClose()}
                />
                <Button
                    style={{ flex:3, opacity:allow_next && is_changed?1:0.5 }}
                    disabled={!is_changed || !allow_next}
                    title='UPDATE'
                    padding={15}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={async() => {
                        await updatePlayer()
                        onClose()
                    }}
                />
            </View>
            }
        </View>
    )
}

export default BasicInfoManager