import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, Image } from 'react-native';
import { Button, Icons, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import { ProfileApi, ProfileHelpers } from '../api';
import type { CodeRequestProps, MyPlayerProps, PlayerReferralProps, PromoProps, PublicPlayerProps, RewardOptionProps } from '../../types';

type CodeRedeemProps = {
    player:MyPlayerProps,
    walkthrough?:boolean,
    onVerify:() => void,
    onRedeemCode:(code_details:{
        code_request?:CodeRequestProps,
        promo?:PromoProps,
        player_referral?:PlayerReferralProps,
        reward_option?:RewardOptionProps,
        referrer?:PublicPlayerProps
    }) => void,
    onClose:() => void
}

const CodeRedeem = ({ walkthrough, onVerify, onClose, onRedeemCode }:CodeRedeemProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ show_reward, setShowReward ] = useState(false);
    const [ action_loading, setActionLoading ] = useState(false);
    const [ data, setData ] = useState<{
        code_request?:CodeRequestProps,
        promo?:PromoProps,
        player_referral?:PlayerReferralProps,
        reward_option?:RewardOptionProps,
        referrer?:PublicPlayerProps
    }>({})
    const [ code_error, setCodeError ] = useState('');
    const [ draft_code, setDraftCode ] = useState<string>('') 

    const { code_request, promo, reward_option, referrer } = data;

    useEffect(() => {
        getDataFromServer()
    },[])

    const getDataFromServer = async() => {
        setLoading(true);
        const request_response = await ProfileApi.getMyCodeDetails();
        setData({ ...request_response })
        if(request_response.code_request){ setDraftCode(request_response.code_request.referral_code) }
        setLoading(false);
    }

    const handleTryCodeRequest = async() => {
        if(!draft_code){ setCodeError('Please enter a code') }
        const request_response = await ProfileApi.tryCodeRequest(draft_code);
        if(request_response.result == 'failed'){
            return setCodeError(request_response.error_message ?? 'Unable to use this code')
        }
        setCodeError('');
        return setData({ ...request_response })
    }

    const handleRedeemCode = async() => {
        if(!code_request || action_loading){ return }
        setActionLoading(true)
        const request_response = await ProfileApi.redeemCode(code_request.code_request_id)
        setShowReward(true);
        setTimeout(() => {
            setData({ ...request_response })
            onRedeemCode(request_response)
            setActionLoading(false);
        }, 2000);
    }



    if(loading){
        return (
            <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ padding:40, alignSelf:'center' }} />
        )
    }

    const is_changed = draft_code && draft_code != code_request?.referral_code ? true : false
    const try_allowed = draft_code ? true : false
    const change_allowed = !loading && ProfileHelpers.isChangeCodeAllowed(code_request) ? true : false
    return (
        <View style={{ flex:1 }}>
            <View style={{ flex:1, padding:20 }}>
            <View style={{ padding:20 }}>
                    <Text size={30} color={Colors.brand.midnight} weight='bold'>How Did You Find Us?</Text>
                    <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>All new players are eligible for a one-time sign up bonus. Below is the code / referrer used during sign up.</Text>
                </View>
                <View style={{ padding:10, marginTop:20 }}>
                    <Text size={20} weight='bold' textAlign='center' color={Colors.brand.midnight}>CODE USED</Text>
                    <View style={{ marginTop:20, backgroundColor:Colors.shades.white, borderRadius:22, flexDirection:'row', alignItems:'center' }}>
                        <View style={{ paddingLeft:15, paddingRight:15 }}>
                            <Icons.USDIcon
                                color={Colors.brand.midnight}
                                size={18}
                            />
                        </View>
                        <TextInput
                            value={draft_code}
                            style={{ flex:1, borderRadius:0, backroundColor:change_allowed?Colors.shades.white:Colors.shades.shade100 }}
                            placeholder='Add Code Here'
                            editable={change_allowed}
                            placeholderTextColor={Colors.brand.slate}
                            onChangeText={(text) => setDraftCode(text)}
                        />
                        <View style={{ paddingLeft:15, paddingRight:15 }}>
                            {loading ?
                            <ActivityIndicator size='small' color={Colors.brand.midnight}/>
                            :
                            <View>
                                { !is_changed && code_request ?
                                <Icons.CheckIcon size={16} color={Colors.utility.success} />
                                : !code_error ?
                                <Icons.AlertIcon size={16} color={Colors.utility.warning} />
                                :
                                <Icons.CloseIcon size={14} color={Colors.utility.error} />
                                }
                            </View>
                            }
                        </View>
                    </View>
                    {code_error ?
                    <Text style={{ padding:5 }} size={14} color={Colors.utility.error} textAlign='center'>{code_error}</Text>
                    :<></>}
                    {is_changed ?
                        <Button
                            title='Apply Code'
                            style={{ marginTop:20, opacity:try_allowed?1:0.5 }}
                            disabled={!try_allowed}
                            padding={15}
                            onPress={() => handleTryCodeRequest()}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.brand.electric}
                        />
                        :<></>}
                    {false ?
                    <Text size={14} color={Colors.utility.warning}>{'ERROR GOES HERE'}</Text>
                    :<></>}
                </View>
                {!reward_option ?
                <View style={{ padding:10, marginTop:20 }}>
                    <Text size={20} weight='bold' textAlign='center' color={Colors.brand.midnight}>CODE OFFER</Text>
                    {promo ?
                    <View style={{ marginTop:20, backgroundColor:Colors.shades.white, borderRadius:8, padding:10 }}>
                        <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign='center'>{ProfileHelpers.getReferralDescription(promo)}</Text>
                    </View>
                    :<></>}
                </View>
                :
                <View style={{ padding:10, marginTop:20 }}>
                    <Text size={20} weight='bold' textAlign='center' color={Colors.brand.midnight}>CODE REDEEMED</Text>
                    {reward_option ?
                    <View style={{ marginTop:10, backgroundColor:Colors.highlights.highlight400Faded, padding:10, borderRadius:8 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='semibold'>AWARDED: {reward_option.reward_name}</Text>
                        {code_request && promo && ['referral_sweepstakes','referral_code'].includes(promo.type) ?
                        <Text style={{ marginTop:10 }} size={14} color={Colors.brand.midnight} weight='semibold'>KICKBACK STATUS: {code_request.status == 'fulfilled' ? 'Referrer will get the same reward amount after you make your first deposit': 'Kickback received'}</Text>
                        :<></>}
                    </View>
                    :<></>}
                </View>
                }
            </View>
            {referrer ?
            <View style={{ margin:10, marginRight:30, marginLeft:30, padding:5, backgroundColor:Colors.shades.white, borderRadius:8 }}>
                    <View nativeID='referrer_details' style={{ flexDirection:'row', padding:5}}>
                        <Image
                            source={{ uri:referrer.profile_pic }}
                            style={{ height:50, width:50, borderRadius:8 }}
                            resizeMode='cover'
                        />
                        <View style={{ flex:1, marginLeft:20 }}>
                            <Text size={16} weight='bold'>Referred By: {referrer.first_name} {referrer.last_name}</Text>
                            <Text style={{ marginTop:5 }} size={14} weight='regular'>@{referrer.username}</Text>
                        </View>
                    </View>
                </View>
            :<></>}
            <View style={{ flexDirection:'row', padding:10 }}>
                <Button
                    title={walkthrough ? 'SKIP' : 'CLOSE'}
                    style={{ flex:1, marginRight:5 }}
                    borderColor={Colors.brand.electric}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    padding={15}
                    onPress={() =>  onClose()}
                />
                {code_request?.status == 'requested' ?
                    <Button
                        title='VERIFY MY ACCOUNT'
                        style={{ flex:3 }}
                        backgroundColor={Colors.utility.warning}
                        title_color={Colors.shades.white}
                        padding={15}
                        onPress={() => onVerify()}
                    />
                    :code_request?.status == 'verified' ?
                    <Button
                        title='REDEEM CODE'
                        style={{ flex:3 }}
                        backgroundColor={Colors.utility.success}
                        title_color={Colors.shades.white}
                        padding={15}
                        onPress={() => handleRedeemCode()}
                    />
                    :
                    <Button
                        title='CODE REDEEMED'
                        style={{ flex:3 }}
                        disabled
                        backgroundColor={Colors.brand.slate}
                        title_color={Colors.shades.white}
                        padding={15}
                        onPress={() => console.log('REDEEMED!!!')}
                    />
                    }
                </View>
                {show_reward ?
                <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                    <View style={{ borderRadius:8, backgroundColor:Colors.shades.white, padding:20, minWidth:250, minHeight:200 }}>
                        {reward_option ?
                        <View>
                            <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign='center'>CONGRATULATIONS!</Text>
                            <Text style={{ marginTop:10 }} size={30} color={Colors.utility.success} weight='bold' textAlign='center'>{reward_option.reward_name}</Text>
                            <Text style={{ marginTop:10 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign='center'>AWARD RECEIVED</Text>
                            <Button
                                title='CLOSE'
                                title_color={Colors.shades.white}
                                style={{ marginTop:20 }}
                                padding={15}
                                backgroundColor={Colors.brand.electric}
                                onPress={() => setShowReward(false)}
                            />
                        </View>
                        :
                        <View>
                            <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign='center'>GETTING YOUR REWARD...</Text>
                            <ActivityIndicator size='large' color={Colors.brand.midnight} style={{alignSelf:'center', marginTop:20 }}/>
                        </View>
                        }
                    </View>
                    :<></>
                </View>
                :<></>}
        </View>
    )
}

export default CodeRedeem