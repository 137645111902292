import React from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import type { BracketCompetitionProps, BracketProps, CompanyProps, CompetitionResultTypeProps, LeagueProps, PublicPlayerProps } from '../../types';
import { view_styles } from '../../constants/styles';
import Colors from '../../constants/colors';
import { Icons, Text } from '../../Components';
import moment from 'moment-mini';

type BracketCompetitionCardProps = {
    bracket_competition:BracketCompetitionProps,
    competition_result_type:CompetitionResultTypeProps,
    company?:CompanyProps,
    admin?:PublicPlayerProps,
    pacer?:PublicPlayerProps,
    league?:LeagueProps,
    bracket?:BracketProps,
    onCompetitionSelect:(c:BracketCompetitionProps) => void
}
const BracketCompetitionCard = ({ bracket_competition, bracket, league, company, admin, onCompetitionSelect }:BracketCompetitionCardProps) => {
    const cl = bracket_competition.market_type == 'FOR_MONEY' ? '$' : 'E'
    let current_payout = bracket_competition.ticket_revenue
    if(bracket_competition.promo_payout){ current_payout += bracket_competition.promo_payout }
    if(bracket_competition.guaranteed_payout && bracket_competition.guaranteed_payout > current_payout){ current_payout = bracket_competition.guaranteed_payout }

    const tickets_available = bracket_competition.max_brackets - bracket_competition.tickets_sold
    const is_live = moment().isAfter(moment(bracket_competition.scheduled_datetime)) ? true : false
    
    return (
        <TouchableOpacity style={{ ...view_styles.section, padding:0, borderWidth:1, borderColor:Colors.shades.shade600  }} onPress={() => onCompetitionSelect(bracket_competition)}>
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <Image
                    source={{ uri: bracket_competition.image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:45, width:45, borderRadius:4}}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:10, marginRight:10, borderRightWidth:1, borderRightColor:Colors.shades.shade600 }}>
                    <Text theme='header_2'>{bracket_competition.competition_name}</Text>
                    <Text style={{ marginTop:3 }} theme='body'>{bracket_competition.competition_description}</Text>
                </View>
                <Text size={20} color={Colors.brand.electric} weight='bold'>{cl}{bracket_competition.buy_in}</Text>
            </View>
            <View style={{ ...view_styles.section_body }}>
                <View style={{ ...view_styles.body_row }}>
                    {bracket && league ?
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', marginRight:10 }}>
                        <Image
                            source={{ uri: league.league_image }}
                            style={{ height:30, width:30, borderRadius:4 }}
                            resizeMode='cover'
                        />
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text theme='header_2'>{bracket.bracket_name}</Text>
                        </View>
                    </View>
                    :<></>}
                    {tickets_available > 0 ? 
                    <View style={{ justifyContent:'center', alignItems:'center' }}>
                        <Text size={18} color={Colors.brand.midnight} weight='bold'>{tickets_available}</Text>
                        <Text style={{ marginTop:3 }} theme='body'>TICKETS</Text>
                    </View>
                    :
                    <View style={{ justifyContent:'center', alignItems:'center' }}>
                        <Text size={18} color={Colors.utility.error} weight='bold'>FULL</Text>
                    </View>
                    }
                </View>
            </View>

            <View style={{ ...view_styles.section_footer, backgroundColor:company?Colors.incentive.gold_faded:Colors.shades.shade100 }}>
                {company ?
                <Image
                    source={{ uri: company.company_image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:30, width:30, borderRadius:4}}
                    resizeMode='cover'
                />
                :admin ?
                <Image
                    source={{ uri: admin.profile_pic && admin.profile_pic != '' ? admin.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:30, width:30, borderRadius:4}}
                    resizeMode='cover'
                />
                :<></>}
                {bracket_competition.prize_override ?
                <View style={{ flex:1, flexDirection:'row', alignItems:'center', marginLeft:10 }}>
                    <View style={{ flex:1, marginRight:10}}>
                        <Text size={12} weight='bold' color={Colors.brand.midnight}>ADDITIONAL PRIZE</Text>
                        <Text style={{ marginTop:3 }} size={10} color={Colors.incentive.gold} weight='bold'>{bracket_competition.prize_override}</Text>
                    </View>
                    {bracket_competition.prize_image?.url ?
                    <Image
                        source={{ uri: bracket_competition.prize_image.url }}
                        style={{ height:30, width:30, borderRadius:4}}
                        resizeMode='cover'
                    />
                    :
                    <Icons.AwardRibbonIcon size={24} color={Colors.incentive.gold} />
                    }
                </View>
                :
                <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                    <View style={{ flex:1, marginLeft:20, marginRight:20 }}>
                        <Text theme='header_2'>CURRENT PAYOUT</Text>
                        {is_live ? 
                        <Text style={{ marginTop:3 }} color={Colors.utility.error} weight='bold' size={12}>IN PROGRESS</Text>
                        :
                        <Text style={{ marginTop:3 }} theme='body_2'>Join Until {moment(bracket_competition.scheduled_datetime).format('ddd hh:mm a')}</Text>
                        }
                    </View>
                    <Text color={Colors.utility.success} size={20} weight='bold'>{cl}{current_payout.toFixed(2)}</Text>
                </View>
                }
            </View>
        </TouchableOpacity>
    )
}

export default BracketCompetitionCard