import React from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import { view_styles } from "../../constants/styles"
import type { FundProps } from '../../types';
import { Button, Checkbox, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { MarketMakerApi } from '../api';


type FundSelectorProps = {
    funds:FundProps[],
    selected_fund_id?:string,
    maxHeight:number,
    onSelectFund:(f:FundProps) => void,
    onCreateFund:(f:FundProps) => void,
    onEditFund:(f:FundProps) => void
}
const FundSelector = ({ selected_fund_id, funds, maxHeight, onSelectFund, onCreateFund, onEditFund }:FundSelectorProps) => {

    const renderFunds = (data:{item:FundProps, index:number}) => {
        const selected = selected_fund_id == data.item.fund_id ? true : false
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} onPress={() => onSelectFund(data.item)}>
                <Checkbox checked={selected} disabled onSelect={() => console.log('')}/>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text size={12} weight='bold' color={Colors.brand.midnight}>{data.item.name}</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>This is a cool fund!</Text>
                </View>
                <TouchableOpacity onPress={() => onEditFund(data.item)}>
                    <Icons.EditIcon size={18} color={Colors.brand.electric} />
                </TouchableOpacity>
            </TouchableOpacity>
        )
    }
    return (
        <View style={{ ...view_styles.section, maxHeight }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Select Fund</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>Select a fund to populate the dashboard with the funds orders and data</Text>
                </View>
                <Button
                    title='NEW'
                    backgroundColor={Colors.utility.success}
                    title_color={Colors.shades.white}
                    onPress={async() => {
                       const new_fund = await MarketMakerApi.createFund('Pending Fund', 100, 'FOR_MONEY');
                       if(!new_fund){ return alert('Unable to process') }
                       onCreateFund(new_fund)
                    }}
                />
            </View>
            <View style={{ ...view_styles.section_body }}>
                <FlatList
                    key={'market_selector'}
                    data={funds}
                    renderItem={renderFunds}
                    keyExtractor={(item) => item.fund_id.toString()}
                />
            </View>
        </View>
    )
}

export default FundSelector