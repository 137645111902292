import React, { useState } from "react";
import { TextInput, TouchableOpacity, View } from "react-native"
import type { PollOptionProps, PollResponseProps, PollSummaryProps } from "../../types";
import Colors from "../../constants/colors";
import { Button, Icons, Text } from "../../Components";

type PollOptionCardProps = {
    poll_option:PollOptionProps,
    draft_selected?:boolean,
    show_result?:boolean,
    poll_response?:PollResponseProps,
    poll_summary?:PollSummaryProps,
    show_summary:boolean,
    onSelect: (PollOptionProps: PollOptionProps) => void
}
const PollOptionCard = ({ draft_selected, show_result, poll_option, poll_summary, show_summary, onSelect, poll_response } : PollOptionCardProps) => {
    const [ option_width, setOptionWidth ] = useState(0);
    const [ input_locked, setInputLocked ] = useState(false);
    const [ input_value, setInputValue ] = useState('');

    const getResultColor = (result_ind?:'win'|'lose'|'draw') => {
        switch(result_ind){
            case 'win': return Colors.utility.success
            case 'lose': return Colors.utility.error
            case 'draw': return Colors.utility.warning
            default: return Colors.brand.slate
        }
    }

    const getResultIcon = (result_ind:'win'|'lose'|'draw') => {
        let color = getResultColor(result_ind)
        switch(result_ind){
            case 'win':
                return <Icons.CheckIcon color={color} size={12} />
            case 'lose':
                return <Icons.CloseIcon color={color} size={12} />
            default: return <></>
        }
    }
   
    return (
        <View>
            {poll_option.option_type == 'select' ?
            <TouchableOpacity 
                style={{ flex:1, flexDirection:'row', alignItems:'center', padding:10, borderRadius:22, borderWidth:1, borderColor:show_result?getResultColor(poll_option.result_ind):Colors.shades.shade600, backgroundColor:draft_selected?Colors.highlights.highlight500Faded:undefined }}
                onPress={() => onSelect(poll_option)}
                onLayout={(ev) => {
                    const { width } = ev.nativeEvent.layout
                    setOptionWidth(width)
                }}
                >
                <Text style={{ flex:1 }} textAlign="center" size={14} color={show_result?getResultColor(poll_option.result_ind):Colors.brand.midnight} weight='bold'>{poll_option.option_name}</Text>
                {show_result && poll_option.result_ind ?
                <View>
                    {getResultIcon(poll_option.result_ind)}
                </View>
                :<></>}
                {show_summary && poll_summary?
                <View style={{ position: 'absolute', left:0, top:0, bottom:0, width:poll_summary.pct*option_width, borderRadius:22, backgroundColor:Colors.highlights.highlight400Faded }} />
                :<></>}
                {show_summary && poll_summary ? 
                <View style={{ position:'absolute', right:5, top:0, bottom:0, justifyContent:'center' }}>
                    <Text weight='regular' size={10} color={Colors.brand.midnight}>{(poll_summary.pct * 100).toFixed(2)}%</Text>
                </View>
                :<></>}
            </TouchableOpacity>  
            :
            <View style={{ flexDirection:'row' }}>
                <View style={{ flex:1, flexDirection:'row', alignItems:'center', paddingRight:10, borderRadius:22, borderWidth: 1, borderColor: show_result?getResultColor(poll_response?.result_ind):Colors.shades.shade600 }}>
                <TextInput
                    style={{ flex:1, textAlign:'center', fontSize:14, padding:10, color:getResultColor(poll_response?.result_ind) }}
                    keyboardType={poll_option.input_type == 'number' ? 'decimal-pad' : 'default'}
                    editable={show_result?false:true}
                    placeholder="Input Answer"
                    value={poll_response ? poll_response.input_value : input_value}
                    placeholderTextColor={Colors.shades.shade600}
                    onChangeText={(text) => {
                        if(draft_selected){ onSelect(poll_option) }
                        setInputValue(text)
                        setInputLocked(false);
                    }}
                />
                    {show_result && poll_response?.result_ind ?
                    <View>
                        {getResultIcon(poll_response.result_ind)}
                    </View>
                    :<></>}
                </View>
                {!show_result ?
                <Button
                    style={{ marginLeft:5 }}
                    borderRadius={22}
                    title={input_locked?'Locked In':'Lock It In'}
                    backgroundColor={input_locked?Colors.brand.slate:Colors.brand.electric}
                    title_color={Colors.shades.white}
                    onPress={() => {
                        if(!input_value || input_value == ''){ return alert('Please enter valid value') }
                        setInputLocked(true)
                        onSelect({ ...poll_option, option_value: input_value })
                    }}
                />
                :<></>}
            </View>

                } 
        </View>
    )
}

export default PollOptionCard