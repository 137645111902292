import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, Image, ScrollView } from "react-native";
import type { AccountProps, AccountSnoozeProps, CodeRequestProps, ItemOrderProps, MyPlayerProps, PlayerDepositLimitProps, PlayerReferralProps, PromoProps, PublicPlayerProps, RewardOptionProps, WalletSettingsProps } from '../../types';
import { ItemOrderApi, ItemOrderHelpers, WalletApi } from '../api';
import Colors from '../../constants/colors';
import { Button, Icons, Text, TextInput } from '../../Components';
import { view_styles } from '../../constants/styles';

type DepositCardProps = {
    player:MyPlayerProps,
    wallet_settings:WalletSettingsProps,
    account:AccountProps,
    code_details?: {
        code_request?:CodeRequestProps,
        referrer?:PublicPlayerProps,
        promo?:PromoProps,
        player_referral?:PlayerReferralProps,
        reward_option?:RewardOptionProps
    },
    deposit_limit:PlayerDepositLimitProps,
    onTransact:(draft_order:ItemOrderProps, account_id?:string) => void,
    onCancel:() => void
}

const DepositCard = ({ wallet_settings, code_details, account, deposit_limit, onTransact, onCancel }:DepositCardProps) => {
    const [ draft_order, setDraftOrder ] = useState<ItemOrderProps>();
    const [ account_snooze, setAccountSnooze ] = useState<AccountSnoozeProps>();

    useEffect(() => {
        getItemFromServer()
    },[])

    const getItemFromServer = async() => {
        const item = await ItemOrderApi.getItemByIdentifier('deposit')
        const as = await WalletApi.getSnoozeByAccountId(account.account_id);
        setAccountSnooze(as)
        setDraftOrder(ItemOrderHelpers.genDepositOrder(item, account))
    }

    const handleTransact = () => {
        if(!draft_order){ return }
        onTransact({ ...draft_order, total_amount: draft_order.amount as number }, account.account_id)
    }

    let errors = ItemOrderHelpers.isDepositOrdervalid(account, deposit_limit, wallet_settings, draft_order)
    if(account_snooze?.status == 'active'){ errors.push('This account is currently snoozing and cannot be used to deposit') }

    if(!draft_order){
        return (
            <View style={{ padding:20 }}>
                <ActivityIndicator style={{ alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
            </View>
        )
    }
    
    let order_amount = parseFloat(draft_order.amount as string);

    return (
        <View style={{ flex:1 }}>
             <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>MAKE A DEPOSIT</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>In order to participate in real-money activities, you will need to fund your BettorEdge Wallet.  Please enter the amount you wish to deposit below.</Text>
            </View>
            <ScrollView style={{ flex:1 }}>
                <View nativeID='deposit_amount' style={{ justifyContent:'center', alignItems:'center', padding:20 }}>
                    <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.midnight} weight='regular'>Minumum $25 / Maximum ${ItemOrderHelpers.getDepositAvailable(deposit_limit, account).toFixed(2)}</Text>
                    <TextInput 
                        style={{ flex:1 }}
                        placeholder='25'
                        placeholderTextColor={Colors.brand.slate}
                        value={draft_order.amount as string}
                        onChangeText={(text) => setDraftOrder({ ...draft_order, amount:text })}
                    />
                    <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign='center'>*All deposits are held for {deposit_limit.hold_days} days before they are eligible for withdraw</Text>

                </View>
                {errors.length == 0 && order_amount > deposit_limit.instant_available ?
                <View nativeID='instant_available' style={{ backgroundColor:Colors.shades.shade100, padding:5, borderRadius:8, margin:10 }}>
                    <View style={{ padding:10, borderBottomWidth:1, borderColor:Colors.highlights.highlight200Faded }}>
                        <View style={{flexDirection:'row', marginBottom:5 }}>
                            <Icons.AlertIcon color={Colors.utility.warning} size={16}/>
                            <Text style={{ marginLeft:10 }} size={14} color={Colors.utility.warning} weight='bold'>INSTANT DEPOSIT NOTICE</Text>
                        </View>
                        <Text size={14} color={Colors.brand.midnight} weight='regular'>Transactions take time to leave your account and settle in ours. In order to get you started before they arrive, we allow for some to be instantly available. The remainder is available after settlement.</Text>
                    </View>
                    <View style={{ padding:5 }}>
                        <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>Available Instantly</Text>
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>${deposit_limit.instant_available.toFixed(2)}</Text>
                        </View>
                        <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>Available after settlement ({deposit_limit.hold_days} days)</Text>
                            <Text size={14} color={Colors.brand.midnight}>${(order_amount - deposit_limit.instant_available).toFixed(2)}</Text>
                        </View>
                        <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>Total Deposit</Text>
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>${order_amount.toFixed(2)}</Text>
                        </View>
                    </View>
                </View>
                :<></>}
                {errors.length > 0 ?
                <View style={{ margin:20, borderRadius:8, padding:20, backgroundColor:Colors.shades.white, ...view_styles.float }}>
                    <Text style={{ marginBottom:10 }} size={14} color={Colors.brand.midnight}>Please fix the following before continuing</Text>
                    {errors.map(e => {
                        return (
                            <Text size={14} color={Colors.utility.warning}>{e}</Text>
                        )
                    })}
                </View>
                :<></>}
            </ScrollView>
            {code_details && code_details.code_request?.pending_deposit && code_details.promo && code_details.referrer && ['referral_code','referral_sweepstakes'].includes(code_details.promo.type) ?
            <View style={{ margin:15, flexDirection:'row', backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float }}>
                <View style={{ justifyContent:'center' }}>
                    {code_details.referrer?.profile_pic?
                    <Image
                        source={{ uri: code_details.referrer.profile_pic }}
                        style={{ height: 60, width:60, borderRadius:4 }}
                        resizeMode='cover'
                    />
                    :
                    <Icons.GiftIcon size={40} color={Colors.incentive.gold} />
                    }
                </View>
                <View style={{flex:1, backgroundColor:Colors.incentive.gold_faded, padding:10 }}>
                    <Text style={{ paddingBottom:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} size={14} color={Colors.brand.midnight} weight='bold'>Give back to {code_details.referrer?.username}</Text>
                    <Text style={{ paddingTop:5 }} size={12} color={Colors.brand.midnight} weight='semibold'>Make a qualifying deposit and {code_details.referrer.username} will receive a reward!</Text>
                </View>
            </View>
            :<></>}
            <View nativeID='action_row' style={{ flexDirection:'row', padding:20 }}>
                <Button
                    style={{ flex:1, marginRight:5, opacity: 1 }}
                    title={'CANCEL'}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => onCancel()}
                />
                <Button
                    style={{ flex:3, opacity: errors.length > 0 ? 0.5: 1 }}
                    disabled={errors.length > 0 ? true : false}
                    title={'REVIEW DEPOSIT'}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => handleTransact()}
                />
            </View>
        </View>
    )
}

export default DepositCard