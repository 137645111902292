import React, { useState } from 'react';
import { FlatList, View, TouchableOpacity, ActivityIndicator } from "react-native"
import type { PostReactionProps, PostReactionStatsProps } from '../../../types';
import { Button, Text } from '../../../Components';
import Colors from '../../../constants/colors';
import { view_styles } from '../../../constants/styles';

type PostReactionBarProps = {
    post_id:string,
    post_reaction_stats: PostReactionStatsProps[],
    my_post_reactions: PostReactionProps[],
    featured?:boolean,
    loading?:boolean,
    onReactionSelect: (post_reaction:PostReactionProps) => void
}

const available_reactions = [
    { reaction: 'like', label: '❤️' },
    { reaction: 'celebrate', label: '🎉' },
    { reaction: 'fire', label: '🔥' },
    { reaction: 'clap', label: '👏' },
    { reaction: 'trophy', label: '🏆' },
    { reaction: 'strong', label: '💪' },
    { reaction: 'money', label: '💰' },
    { reaction: 'eyes', label: '👀' },
]

const PostReactionBar = ({ post_id, loading, post_reaction_stats, my_post_reactions, onReactionSelect }:PostReactionBarProps) => {
    const [ more_selected, setMoreSelected ] = useState(false);

    const handleReaction = async(reaction:string) => {
        if(!onReactionSelect){ return }
        let existing_reaction = my_post_reactions.find(pr => pr.reaction == reaction)
        onReactionSelect({
            post_reaction_id:existing_reaction?.post_reaction_id ?? '',
            post_type:'memo',
            activity_post_id:post_id,
            reaction,
            status: existing_reaction ? 'hidden' : 'visible',
            player_id: '',
            create_datetime: '',
            last_update_datetime:''
        })
        setMoreSelected(false)
    }

    const renderAvailableReactions = (data: {item:any, index:number}) => {
        const reacted = post_reaction_stats.length > 0 && !more_selected
        const reaction_stat = post_reaction_stats.find(p => p.reaction === data.item.reaction)
        const my_reaction = my_post_reactions.find(pr => pr.reaction === data.item.reaction)
        const selected = my_reaction?.status === 'visible' ? true : false
        return (
            <TouchableOpacity style={ 
                reacted ? 
                {  ...view_styles.float, marginBottom:5, marginLeft:data.index===0?0:-5, paddingRight:10, paddingLeft:5, flexDirection:'row', alignItems:'center', padding:2, borderRadius:100, backgroundColor:selected?Colors.highlights.highlight200:Colors.shades.white }
                :
                {}} onPress={() => handleReaction(data.item.reaction)}>
                
                <Text style={{fontSize:12, padding:6}}>{data.item.label}</Text>
                {reaction_stat && !more_selected && post_reaction_stats.length < 5 ?
                <Text size={12} color={selected ? Colors.shades.white : Colors.brand.midnight} weight='regular' style={{ padding:3 }}>{reaction_stat.reaction_count}</Text>
                :<></>}
            </TouchableOpacity>
        )
    }

    let visible_reactions = available_reactions
    if(post_reaction_stats.length > 0 && !more_selected){
        visible_reactions = visible_reactions.filter(r => post_reaction_stats.find(pr => pr.reaction === r.reaction && pr.reaction_count > 0))
    }
    return (
        <View style={{ flexDirection:'row', alignItems:'center' }}>
            <FlatList key='reactions' data={visible_reactions} renderItem={renderAvailableReactions} keyExtractor={(item) => item.reaction} horizontal/>
            {post_reaction_stats.length > 0 && !more_selected ?
            <Button
                title='MORE'
                title_color={Colors.brand.electric}
                title_size={10}
                style={{ marginLeft:4 }}
                padding={0}
                onPress={() => setMoreSelected(true)}
            />
            :<></>}
            {loading ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'center', backgroundColor:Colors.shades.black_faded, borderRadius:22 }}>
                <ActivityIndicator size='small' color={Colors.brand.midnight} />
            </View>
            :<></>}
        </View>
    )
}

export default PostReactionBar