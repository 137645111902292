import axios from 'axios';
import type { BEEventProps, GeneratedLinkProps } from '../../types';
export { BEEventApi, BELinkApi }

const ANALYTICS_SVC_API = 'https://be-analytics-svc.herokuapp.com';

let my_device:any
let my_source:string
let my_player_id:string
let my_session_id:string
let my_distinct_id:string

const delayed_events:BEEventProps[] = [];

const BEEventApi = {
    setDevice: (device:any) => {
        const is_crawler = BEEventApi.detectCrawler(device.userAgent)
        my_device = { ...device, is_crawler }
    },
    detectCrawler: (user_agent:string) => {
        var botPattern = "(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|prerender|prerenderer|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
        var re = new RegExp(botPattern, 'i');
        if (re.test(user_agent)) {
            return true
        }
        return false
    },
    setSource: (source:string) => {
        my_source = source
    },
    setDistinctId:(distinct_id:string) =>  {
        my_distinct_id = distinct_id
        BEEventHelpers.addDistinctHeader(distinct_id);
    },
    setSessionId: (session_id:string) => {
        my_session_id = session_id
        BEEventHelpers.addSessionHeader(session_id);
        if(delayed_events.length > 0){ 
            delayed_events.map(e => BEEventApi.saveEvent(e))
        }
    },
    saveEvent: async(be_event:BEEventProps):Promise<string|undefined> => {
        try {
            if(my_device && my_device.is_crawler){ return }//Dont send if this is just a crawler
            be_event.source_type = 'client';
            be_event.source = my_source
            be_event.device_data = my_device;
            be_event.player_id = my_player_id;
            //let pos = await LocationApi.getMyPosition();
            be_event.coords = { }
            be_event.distinct_id = my_distinct_id;
            be_event.session_id = my_session_id;
            const resp = await axios.post(`${ANALYTICS_SVC_API}/v1/events/event/create`, { be_event });
            return resp.data.be_event_id
        } catch (e) {
            return
        }
    },
    pageHit: async(page:string, url?:string):Promise<string|undefined> => {
        try {
            if(my_device && my_device.is_crawler){ return }//Dont send if this is just a crawler
            let be_event:BEEventProps = {
                event_name: 'page_hit',
                source_type: 'client',
                source: my_source,
                device_data: my_device,
                distinct_id: my_distinct_id,
                session_id: my_session_id,
                player_id: my_player_id,
                event_data: {
                    screen: page,
                    url
                },
                level:2
            }
            const resp = await axios.post(`${ANALYTICS_SVC_API}/v1/events/event/create`, { be_event });
            return resp.data.be_event_id
        } catch (e) {
            return
        }
    },
    identify: async(player_id:string) => {
        try {
            if(!player_id){ return }
            my_player_id = player_id
            await axios.post(`${ANALYTICS_SVC_API}/v1/events/identify`, { player_id, distinct_id:my_distinct_id });
            return
        } catch (e) {
            console.log(e)
            return
        }
    }
}

const BEEventHelpers = {
    addSessionHeader: (session_id:string) => {
        axios.defaults.headers.common['SessionId'] = session_id;
    },
    addDistinctHeader: (distinct_id:string) => {
        axios.defaults.headers.common['DistinctId'] = distinct_id;
    }
}

const BELinkApi = {
    generateShortLink: async(generated_link:GeneratedLinkProps):Promise<{ generated_link:GeneratedLinkProps, url:string }> => {
        try {
            const resp = await axios.post(`${ANALYTICS_SVC_API}/v1/links/redirect/generate`, { generated_link })
            return { generated_link:resp.data.generated_link, url: `https://share.mybe.app/id/${resp.data.generated_link.visible_id}` }
        } catch (e) {
            console.log(e)
            return { generated_link, url: generated_link.redirect_url }
        }
    },
    share: async(title:string, text?:string, link?:string, generated_link_id?:string):Promise<void> => {            
        if(navigator.share){
            try {
                await navigator.share({
                    title,
                    text:text,
                    url: link 
                })
            } catch (e) {
                return
            }
        } else {
            alert(`Your browser does not support native sharing.  Copy the following link and share: ${link}`)
        }
        BEEventApi.saveEvent({
            event_name: 'generated_link',
            event_data: {
                generated_link_id
            },
            level:1
        })
    }
}