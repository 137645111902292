import type { BestAvailableOrderProps, BestAvailableResponseProps, EventOrderStatProps, EventProps, TradeProps } from "../../../../types";

export { TeamEventListHelpers }

const TeamEventListHelpers = {
    getEventMarketDataFromBestAvailable: (ba:BestAvailableResponseProps, events:EventProps[]):{ updated:boolean, latest_trades:TradeProps[], event_order_stats:EventOrderStatProps[], available_orders:BestAvailableOrderProps[] } => {
        let updated = false;
        let event_order_stats:EventOrderStatProps[] = []
        let available_orders:BestAvailableOrderProps[] = []
        let latest_trades:TradeProps[] = []
        ba.events.map(ba_event => {
            if(!ba_event.supported_markets){ return }
            if(!events.find(e => e.event_id == ba_event.event_id)){ return }
            updated = true
            ba_event.supported_markets.map(sm => {
                if(sm.available_orders){ available_orders = available_orders.concat(sm.available_orders) }
                if(sm.order_stats){ event_order_stats = event_order_stats.concat(sm.order_stats) }
                if(sm.latest_trades){ latest_trades = latest_trades.concat(sm.latest_trades) }
            })
        })
        return { updated, event_order_stats, available_orders, latest_trades }
    }
}