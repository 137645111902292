import axios from "axios";
import { APIOverrides } from "../../ApiOverrides";
import type { CompanyProps, CompetitionPayoutTypeProps, CompetitionProps, CompetitionResultTypeProps, CompetitionSeasonPlayerProps, CompetitionSeasonProps, CompetitionSeasonResultProps, CompetitionTypeProps, MyPlayerProps, PlayerBalanceProps, PublicPlayerProps } from "../../types";
import moment from "moment-mini";

let AUTH_SVC_API = ''
let TP_SVC_API = ''
//let EVENT_SVC_API = ''
//let MK_SVC_API = ''


export { SeasonApi, SeasonHelpers }

const SeasonApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        TP_SVC_API = endpoints['TP_SVC_API'] as string;
        //EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        //MK_SVC_API = endpoints['MK_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getPlayersByPlayerIds : async(player_ids:string[]):Promise<PublicPlayerProps[]> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
        return resp.data.players
    },
    getCompaniesByIds: async(company_ids:string[]):Promise<CompanyProps[]> => {
        try {
            if(company_ids.length == 0){ return [] }
            const resp = await axios.post(`${AUTH_SVC_API}/v1/companies/bulk/get`, { company_ids })
            return resp.data.companies
        } catch (e) {
            return []
        }
    },
    getMyDetails: async():Promise<MyPlayerProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
        return resp.data.player
    },
    getMyBalance: async():Promise<PlayerBalanceProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/balance/me`)
        return resp.data.player_balance
    },
    getSeasonById: async(competition_season_id:string):Promise<CompetitionSeasonProps> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/seasons/season/${competition_season_id}`);
        return resp.data.competition_season
    },
    getCompetitionsBySeasonId: async(competition_season_id:string):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/season/${competition_season_id}`);
        return resp.data.competitions
    },
    getCompetitionOptions: async():Promise<{competition_types:CompetitionTypeProps[], competition_result_types:CompetitionResultTypeProps[], competition_payout_types:CompetitionPayoutTypeProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/options`)
        return resp.data
    },
    getSeasonPlayersBySeasonId: async(competition_season_id:string, offset:number):Promise<{ competition_season_players: CompetitionSeasonPlayerProps[], competition_season_results:CompetitionSeasonResultProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/seasons/players/${competition_season_id}?offset=${offset}`);
        return resp.data
    },
    joinSeason: async(competition_season_id:string):Promise<{ competition_season:CompetitionSeasonProps, competition_season_player:CompetitionSeasonPlayerProps, competition_season_result:CompetitionSeasonResultProps, competitions:CompetitionProps[] }> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/season/join`, { competition_season_id });
        return resp.data
    },
    leaveSeason: async(competition_season_player_id:string):Promise<{ competition_season:CompetitionSeasonProps, competition_season_player:CompetitionSeasonPlayerProps, competition_season_result:CompetitionSeasonResultProps, competitions:CompetitionProps[] }> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/season/leave`, { competition_season_player_id });
        return resp.data
    }
}

const SeasonHelpers = {
    canJoin: (competition_season:CompetitionSeasonProps, competitions:CompetitionProps[]):boolean => {
        if(competitions.length == 0){ console.log('non') }
        if(competition_season.status != 'active'){ return false }
        let started = moment().isAfter(moment(competition_season.scheduled_datetime)) ? true : false
        if(started && !competition_season.allow_late_buy_in){ return false }
        return true
    },
    getPayoutAmounts: (competition_season:CompetitionSeasonProps) => {
        let total_payout = competition_season.ticket_revenue
        if(competition_season.promo_amt){ total_payout = total_payout + parseFloat(competition_season.promo_amt as string) }
        if(competition_season.guaranteed_payout && competition_season.guaranteed_payout > total_payout){ total_payout = competition_season.guaranteed_payout }

        let season_payout = parseFloat(competition_season.payout_allocation as string) * total_payout
        let competition_payout = total_payout - season_payout
        return { season_payout, competition_payout }
    },
    formatPlace :(place:number) => {
        switch(place){
            case 1:
                return '1st'
            case 2:
                return '2nd'
            case 3:
                return '3rd'
            case 4:
                return '4th'
            case 5:
                return '5th'
            case 6:
                return '6th'
            case 7:
                return '7th'
            case 8:
                return '8th'
            case 9:
                return '9th'
            default:
                return `${place}`
    
        }
    },
}