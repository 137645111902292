import axios from "axios";
import { APIOverrides } from "../../../ApiOverrides";
import type { ArticleFeedProps, ArticleProps, BracketCompetitionProps, BracketProps, CompanyProps, CompetitionPayoutTypeProps, CompetitionProps, CompetitionResultTypeProps, CompetitionTypeProps, EventProps, LeagueProps, PodcastProps, PublicPlayerProps, SquaresCompetitionProps } from "../../../types";
import type { CompanyMemberProps } from "../../../Authenticator/api/types";
import moment from "moment-mini";

let AUTH_SVC_API = '';
let SOCIAL_SVC_API = '';
let TP_SVC_API = '';
let EVENT_SVC_API = '';

export { CompanyProfileApi, CompanyProfileHelpers }

const CompanyProfileApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        SOCIAL_SVC_API = endpoints['SOCIAL_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
        TP_SVC_API = endpoints['TP_SVC_API'] as string;
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
    },
    getCompanyById: async (company_id: string): Promise<{ company: CompanyProps, company_members: CompanyMemberProps[] } | undefined> => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/companies/company/id/${company_id}`)
            return resp.data
        } catch (e) {
            return undefined
        }
    },
    getPlayersByPlayerIds: async (player_ids: string[]): Promise<PublicPlayerProps[]> => {
        if (player_ids.length == 0) { return [] }
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
            return resp.data.players
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getLeagues: async():Promise<LeagueProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues?status=active`)
            return resp.data.leagues
        } catch (e) {
            return []
        }
    },
    getEventsByEventIds: async(event_ids:string[]):Promise<EventProps[]> => {
        try {
            if(event_ids.length == 0){ return [] }
            const resp = await axios.post(`${EVENT_SVC_API}/v1/events/bulk/get`, { attribute:'event_id', values: event_ids })
            return resp.data.events
        } catch (e) {
            return []
        }
    },
    getBracketsByIds: async(bracket_ids:string[]):Promise<BracketProps[]> => {
        try {
            if(bracket_ids.length == 0){ return [] }
            const resp = await axios.post(`${EVENT_SVC_API}/v1/brackets/bulk/get`, { bracket_ids })
            return resp.data.brackets
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getCompetitionOptions: async():Promise<undefined | {competition_types:CompetitionTypeProps[], competition_result_types:CompetitionResultTypeProps[], competition_payout_types:CompetitionPayoutTypeProps[]}> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/options`)
            return resp.data
        } catch (e) {
            console.log(e)
            return undefined
        }

    },
    getCompetitionsByCompanyId: async (company_id: string, offset: number, status: 'active' | 'closed'): Promise<CompetitionProps[]> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/company/${company_id}?offset=${offset}&status=${status}`)
            return resp.data.competitions
        } catch (e) {
            console.log(e);
            return []
        }
    },
    
    getSquaresByCompanyId: async (company_id: string, offset: number, status: 'active' | 'closed'): Promise<CompetitionProps[]> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/company/${company_id}?offset=${offset}&status=${status}`)
            return resp.data.competitions
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getSquaresCompsByCompanyId: async (company_id: string, status: string): Promise<SquaresCompetitionProps[]> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/tp/squares/company/${company_id}?status=${status}`);
            return resp.data.squares_competitions
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getBracketCompetitionsByCompanyId: async (company_id: string, offset: number, status?: 'active' | 'closed'): Promise<BracketCompetitionProps[]> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/brackets/competitions/company/${company_id}?offset=${offset}&status=${status}`)
            return resp.data.bracket_competitions
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getPodcastsByCompanyId:async(company_id:string, offset:number):Promise<PodcastProps[]> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/podcasts/company/${company_id}?offset=${offset}`);
            return resp.data.podcasts
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getArticlesByCompanyId: async(company_id:string, offset:number):Promise<ArticleProps[]> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/articles/company/${company_id}?offset=${offset}`);
            console.log(resp.data)
            return resp.data.articles
        } catch (e) {
            return []
        }
    },
    getArticleFeedsByCompanyId: async(company_id:string):Promise<ArticleFeedProps[]> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/articles/feeds/company/${company_id}`)
            console.log(resp.data)
            return resp.data.article_feeds
        } catch (e) {
            return []
        }
    }
}

const CompanyProfileHelpers = {
    sortComps: (comps:CompetitionProps[], bcs:BracketCompetitionProps[], scs:SquaresCompetitionProps[]):{ type: 'competition'|'squares_competition'|'bracket_competition', id:string, start_time:any }[] => {
        let sorted_comps:{ type:'competition'|'squares_competition'|'bracket_competition', id:string, start_time:any }[] = [];
        comps.map(c => sorted_comps.push({ type: 'competition', id: c.competition_id, start_time: moment(c.scheduled_datetime) }));
        bcs.map(bc => sorted_comps.push({ type: 'bracket_competition', id:bc.bracket_competition_id, start_time: moment(bc.scheduled_datetime) }));
        scs.map(s => sorted_comps.push({ type: 'squares_competition', id:s.sq_comp_id, start_time: moment(s.begin_datetime) }));
        return sorted_comps
    }
}