import React, { useCallback, useEffect, useState } from 'react';
import Text from '../Components/Text';
import { View, TouchableOpacity, FlatList, ActivityIndicator, Clipboard, ScrollView } from 'react-native';
import { view_styles } from '../constants/styles';
import { Button, Icons, TextInput } from '../Components';
import Colors from '../constants/colors';
import { ShareApi, ShareHelpers } from './api';
import type { BEEventProps, GeneratedLinkProps } from '../types';
import EmbedPreview from './components/EmbedPreview';

const native_share_options = [
    { name: 'twitter', getIcon: () => <Icons.TwitterXIcon size={18}/> },
    { name: 'instagram', getIcon: () => <Icons.InstagramIcon size={18}/> },
    { name: 'facebook', getIcon:() => <Icons.FacebookIcon size={18} /> },
    { name: 'moare', getIcon:() => <Icons.MoreIcon size={18} color={Colors.brand.midnight} /> }
]

type ShareWidgetProps = {
    redirect_url?:string,
    generated_link_id?:string,
    title:string,
    body?:string,
    player_id?:string,
    generator: 'player'|'company'|'tool',
    link_type: GeneratedLinkProps['link_type'],
    link_sub_type: GeneratedLinkProps['link_sub_type'],
    type_id?:string,
    embed?:{
        embed_code:string,
        script_source?:string,
        widget_element?:any,
        div_id?:string
    },
    onCancel:() => void,
    onShare?:(be_event:BEEventProps) => void,
    allow_edit?:boolean,
    maxWidth?:number
    maxHeight?:number
}

const ShareWidget = ({ generated_link_id, title, body, redirect_url, maxWidth, maxHeight, generator, allow_edit, player_id, link_type, link_sub_type, type_id, embed, onCancel, onShare }:ShareWidgetProps) => {
    const [ preview_embed, setPreviewEmbed ] = useState(false);
    const [ link_height, setLinkHeight ] = useState(0);
    const [ active_tab, setActiveTab ] = useState('link');
    const [ link_status, setLinkStatus ] = useState<{
        loading: boolean,
        update_loading:boolean,
        editing:boolean,
        generated_link?:GeneratedLinkProps,
        draft_generated_link?:GeneratedLinkProps,
        visible_id_valid:Boolean,
        query_params:{ key:string, value:string }[]
        url?:string
    }>({
        loading: false,
        update_loading: false,
        editing:false,
        visible_id_valid: true,
        query_params: []
    });
    const { loading, editing, generated_link, draft_generated_link, url, visible_id_valid, update_loading } = link_status;


    const native_share_allowed = useCallback(() => {
        return ShareHelpers.nativeShareAvailable()
    },[])

    useEffect(() => {
        if(!redirect_url && !generated_link_id){ return }//Unable to get redirect link
        ShareApi.setEnvironment();
        if(generated_link_id){ getGeneratedLink(generated_link_id) }
        else if(redirect_url){ generateLink(redirect_url) }
    },[redirect_url, generated_link_id]);


    const getGeneratedLink = async(generated_link_id:string) => {
        setLinkStatus({ ...link_status, loading:true });
        const retrieved_link = await ShareApi.getGeneratedLinkById(generated_link_id);
        setLinkStatus({
            ...link_status,
            loading:false,
            generated_link:retrieved_link?.generated_link,
            draft_generated_link: retrieved_link?.generated_link,
            query_params: ShareHelpers.extractQueryParams(retrieved_link?.generated_link.redirect_url),
            url: retrieved_link?.url
        });
    }


    const generateLink = async(redirect_url:string) => {
        setLinkStatus({ ...link_status, loading: true });
        //If there is a player id - lets append the referral code if one is not supplied!
        if(player_id){ redirect_url = await ShareHelpers.appendPlayerReferral(redirect_url) }
        const { generated_link, url } = await ShareApi.generateShortLink({
            generator: generator,
            generated_link_id:'',
            generator_id: player_id,
            type_id,
            redirect_url,
            link_type,
            link_sub_type
        });

        setLinkStatus({
            ...link_status,
            loading: false,
            generated_link,
            draft_generated_link: generated_link,
            url
        });
    }



    const renderNativeOptions = (data:{ item:any, index:number }) => {
        return (
            <View style={{ padding:5 }}>
                {data.item.getIcon()}
            </View>
        )
    }

    const link_is_changed = JSON.stringify(draft_generated_link) != JSON.stringify(generated_link)
    const needs_verification = link_is_changed && !visible_id_valid

    return (
        <View style={{ ...view_styles.section, minWidth:250, maxWidth, maxHeight }}>
            <View style={{ ...view_styles.section_header, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Share Modal</Text>
                    <Text style={{ marginTop:3 }} theme='body'>{title}</Text>
                </View>
                <Button
                    title='X'
                    title_weight='regular'
                    borderRadius={100}
                    backgroundColor={Colors.shades.shade100}
                    padding={5}
                    onPress={() => onCancel()}
                />
            </View>
            <ScrollView style={{ flex:1 }}>
                {loading || !generated_link ?
                <View>
                    <ActivityIndicator style={{ padding:20, alignSelf:'center' }} color={Colors.brand.midnight} size={'large'} />
                </View>
                : active_tab == 'link' ?
                <View>
                    {editing && draft_generated_link ?
                    <View style={{ ...view_styles.section_body }}>
                        <View style={{ ...view_styles.body_row }}>
                            <View style={{ flex:1 }}>
                                <Text size={16} color={Colors.brand.midnight} weight='regular'>Edit This Link</Text>
                                <Text style={{ marginTop:3 }} theme='body'>Edit the visible Id and query parameters below</Text>
                            </View>
                        </View>
                        <View style={{ backgroundColor:Colors.shades.shade100, padding:10 }}>
                            <View style={{ ...view_styles.body_row }}>
                                <View style={{ flex:1 }}>
                                    <Text theme='header_2'>Short Link</Text>
                                    <View style={{ flexDirection:'row', alignItems:'center' }}>
                                        <Text style={{ marginTop:3 }} theme='body'>https://share.mybe.app/id/</Text>
                                        <TextInput
                                            value={draft_generated_link.visible_id}
                                            style={{ ...view_styles.input, flex:1, maxWidth:150 }}
                                            onChangeText={(text) => {
                                                setLinkStatus({
                                                    ...link_status,
                                                    draft_generated_link: { ...draft_generated_link, visible_id: text },
                                                    visible_id_valid: false
                                                })
                                            }}
                                        />
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...view_styles.body_row }}>
                                <View style={{ flex:1 }}>
                                    <Text theme='header_2'>Link Destination</Text>
                                    <TextInput
                                        value={draft_generated_link.redirect_url}
                                        multiline={true}
                                        style={{ 
                                            ...view_styles.input,
                                            marginTop:4,
                                            flex:1, 
                                            height: Math.max(link_height, 35) }}
                                        onContentSizeChange={(ev) => {
                                            setLinkHeight(ev.nativeEvent.contentSize.height)
                                        }}
                                        onChangeText={(text) => {
                                            let new_params = ShareHelpers.extractQueryParams(text);
                                            setLinkStatus({
                                                ...link_status,
                                                draft_generated_link: { ...draft_generated_link, redirect_url: text },
                                                query_params: new_params
                                            })
                                        }}
                                    />
                                </View>
                            </View>
                        </View>
                        <View style={{ ...view_styles.section_footer }}>
                            <Button
                                title='Cancel'
                                title_color={Colors.shades.white}
                                borderRadius={4}
                                backgroundColor={Colors.utility.error}
                                onPress={() => setLinkStatus({ ...link_status, editing: false, draft_generated_link: generated_link })}
                            />
                            <View style={{ flex:1 }}/>
                            {needs_verification ?
                            <Button
                                title='Check Link Availability'
                                title_color={Colors.shades.white}
                                style={{ opacity:update_loading?0.5:1 }}
                                borderRadius={4}
                                loading={update_loading}
                                backgroundColor={Colors.brand.electric}
                                onPress={async() => {
                                    setLinkStatus({ ...link_status, update_loading: true })
                                    const is_available = await ShareApi.isGeneratedLinkAvailable(draft_generated_link);
                                    if(!is_available){ 
                                        setLinkStatus({ ...link_status, update_loading: false })
                                        return alert('Sorry! This link is not available.  Please change the visible id portion of the link and try again') 
                                    }
                                    setLinkStatus({
                                        ...link_status,
                                        update_loading:false,
                                        visible_id_valid: true
                                    })
                                }}
                            />
                            :
                            <Button
                                title='Save'
                                style={{ opacity:link_is_changed && !update_loading?1:0.5 }}
                                disabled={!link_is_changed}
                                loading={update_loading}
                                title_color={Colors.shades.white}
                                borderRadius={4}
                                backgroundColor={Colors.utility.success}
                                onPress={async() => {
                                    setLinkStatus({ ...link_status, update_loading: true });
                                    const new_link = await ShareApi.updateGeneratedLink(draft_generated_link);
                                    if(!new_link?.generated_link){ return alert('Unable to update link') }
                                    setLinkStatus({
                                        ...link_status,
                                        update_loading: false,
                                        editing: false,
                                        generated_link: new_link?.generated_link,
                                        draft_generated_link: new_link?.generated_link,
                                        url: new_link?.url
                                    });
                                }}
                            />
                            }
                        </View>
                    </View>
                    :
                    <View style={{ ...view_styles.section_body }}>
                        <View style={{ paddingBottom:0 }}>
                            <View style={{ ...view_styles.body_row }}>
                                <Text size={16} color={Colors.brand.midnight} weight='regular'>Share this link via</Text>
                            </View>
                            {native_share_allowed() ? 
                            <TouchableOpacity style={{ ...view_styles.body_row }} onPress={async() => {
                                const share_event = await ShareApi.share(title, body, link_status.url, generated_link.generated_link_id);
                                if(onShare && share_event){ onShare(share_event) }
                            }}>
                                <View style={{ flexDirection:'row', alignItems:'center', padding:3, paddingRight:10, borderWidth:1, borderRadius:22, borderColor:Colors.highlights.highlight400Faded, backgroundColor: Colors.shades.white, ...view_styles.float }}>
                                    <View style={{ height:36, width:36, marginRight:10, backgroundColor:Colors.brand.electric, borderRadius:100, justifyContent:'center', alignItems:'center' }}>
                                        <Icons.ShareIcon size={14} color={Colors.shades.white} />
                                    </View>
                                    <FlatList
                                        horizontal
                                        data={native_share_options}
                                        keyExtractor={(item) => item.name}
                                        renderItem={renderNativeOptions}
                                    />
                                </View>
                            </TouchableOpacity>
                            :<></>}
                        </View>
                        <View style={{ paddingTop:0, }}>
                            <View style={{ ...view_styles.body_row }}>
                                <Text selectable size={16} color={Colors.brand.midnight} weight='regular'>Copy link</Text>
                            </View>
                            <View style={{ ...view_styles.body_row, padding:10, borderRadius:4, borderWidth:1, borderColor:Colors.shades.shade600 }}>
                                {url ?
                                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', backgroundColor:Colors.brand.electric, padding:10, borderRadius:4 }} onPress={() =>  Clipboard.setString(url)}>
                                    <Icons.LinkIcon size={18} color={Colors.shades.white} />
                                    <Text style={{ marginLeft:4 }} size={12} color={Colors.shades.white} textAlign='left'>Copy</Text>
                                </TouchableOpacity>
                                :<></>}
                                <Text style={{ marginLeft:5, flex:1 }} theme='body'>{url}</Text>
                                {allow_edit ?
                                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', backgroundColor:Colors.brand.electric, padding:10, borderRadius:4 }} onPress={() => setLinkStatus({ ...link_status, editing: true })}>
                                    <Icons.EditIcon size={18} color={Colors.shades.white} />
                                </TouchableOpacity>
                                :<></>}
                            </View>
                        </View>
                    </View>
                    }
                </View>
                : active_tab == 'embed' && embed?.embed_code ?
                <View style={{ ...view_styles.section_body, paddingTop:0 }}>
                    <View style={{ ...view_styles.body_row }}>
                        <View style={{ flex:1 }}>
                            <Text size={16} color={Colors.brand.midnight} weight='regular'>Embed this in your website</Text>
                            <Text style={{ marginTop:3 }} theme='body_2'>Copy and past the code snippet below into the 'body' tag</Text>
                        </View>
                    </View>
                    <View style={{ backgroundColor:Colors.shades.shade100, padding:10 }}>
                        <Text selectable size={14} color={Colors.brand.midnight}>{embed.embed_code}</Text>
                        <View style={{ ...view_styles.body_row }}>
                            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', backgroundColor:Colors.brand.electric, padding:10, borderRadius:4 }}
                            onPress={() => Clipboard.setString(embed.embed_code)}>
                                <Icons.LinkIcon size={18} color={Colors.shades.white} />
                                <Text style={{ marginLeft:4 }} size={12} color={Colors.shades.white} textAlign='left'>Copy</Text>
                            </TouchableOpacity>
                            <View style={{ flex:1 }} />
                            {embed.widget_element && embed.script_source && embed.div_id ?
                            <Button
                                title={preview_embed ? 'Hide Preview' : 'Show Preview'}
                                title_color={Colors.shades.white}
                                borderRadius={4}
                                padding={10}
                                backgroundColor={preview_embed ? Colors.utility.error : Colors.brand.cyan}
                                onPress={() => setPreviewEmbed(!preview_embed)}
                            />
                            :<></>}
                        </View>
                    </View>
                    
                    {preview_embed && embed.widget_element && embed.script_source && embed.div_id ?
                    <View style={{ borderWidth:1, borderRadius:4, borderColor:Colors.brand.slate }}>
                        <EmbedPreview
                            script_source={embed.script_source}
                            widget_element={embed.widget_element}
                            div_id={embed.div_id}
                        />
                    </View>

                    :<></>}
                </View>
                :<></>}
                {active_tab == 'embed' ?
                <TouchableOpacity style={{ ...view_styles.section_footer }} onPress={() => setActiveTab('link')}>
                    <View style={{ flex:1, marginRight:10 }}>
                        <Text theme='header'>Share Link Options</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Customize the link and share</Text>
                    </View>
                    <View style={{ height:30, width:30, borderRadius:100, backgroundColor:Colors.shades.white, justifyContent:'center', alignItems:'center' }}>
                        <Icons.LinkIcon color={Colors.brand.midnight} size={18}/>
                    </View>
                </TouchableOpacity>
                : active_tab == 'link' && embed?.embed_code ?
                <TouchableOpacity style={{ ...view_styles.section_footer }} onPress={() => setActiveTab('embed')}>
                    <View style={{ flex:1, marginRight:10 }}>
                        <Text theme='header'>Available For Embed</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Copy code snippet and paste into the html of your website</Text>
                    </View>
                    <View style={{ height:30, width:30, borderRadius:100, backgroundColor:Colors.shades.white, justifyContent:'center', alignItems:'center' }}>
                        <Icons.EmbedIcon color={Colors.brand.midnight} size={18}/>
                    </View>
                </TouchableOpacity>
                :<></>}
            </ScrollView>
        </View>
    )
}

export default ShareWidget