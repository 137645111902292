import React, { useEffect, useState } from 'react';
import { View, Image, FlatList, TouchableOpacity, ActivityIndicator } from "react-native";
import type { BEEventProps, CompanyProps } from '../../types';
import LinearGradient from 'react-native-linear-gradient';
import Colors from '../../constants/colors';
import { Button, Icons, Text } from '../../Components';
import { view_styles } from '../../constants/styles';
import CompanyCard from './CompanyCard';
import SearchBox from '../../Components/SearchBox';
import { EngageApi } from '../api';

type EngageHeaderProps = {
    companies:CompanyProps[],
    width:number,
    onEvent?:(be_event:BEEventProps) => void,
    onSelectCompany?:(company:CompanyProps) => void,
    onManage?:() => void
}

const EngageHeader = ({ companies, width, onManage, onEvent, onSelectCompany }:EngageHeaderProps) => {
    const [ active_company, setActiveComany ] = useState<number>(0);
    const [ search, setSearch ] = useState<{
        loading:boolean,
        active: boolean,
        value: string,
        offset: number
    }>({
        loading:false,
        active: false,
        value: 'a',
        offset:0
    });
    const [ found_companies, setFoundCompanies ] = useState<CompanyProps[]>([]);
    const { active, loading, value } = search;

    let filtered_companies = [ ...found_companies ]
    filtered_companies = filtered_companies.filter(c => c.company_name.toLowerCase().includes(value.toLowerCase()))

    useEffect(() => {
        getCompaniesFromServer(0);
    },[])

    useEffect(() => {
        if(companies.length == 0){ return }
        setActiveComany(0)
    },[companies.length])

    useEffect(() => {
        if(companies.length == 0){ return }
        let next_company_index = active_company + 1
        let next_company = companies[next_company_index]
        if(!next_company){ next_company_index = 0 }
        setTimeout(() => {
            setActiveComany(next_company_index)
        }, 3000);
    },[active_company])

    const getCompaniesFromServer = async(offset:number) => {
        const cpnys = await EngageApi.searchCompanies(value, offset);
        setSearch({ ...search, loading: false, offset })
        setFoundCompanies(cpnys);
    }

    const renderCompanies = (data:{ item:CompanyProps, index:number }) => {
        return (
            <View>
                <CompanyCard
                    company={data.item}
                    width={width / 2.5}
                    onEvent={onEvent}
                    onSelectCompany={onSelectCompany}
                />
            </View>
        )
    }

    const company = companies[active_company]

    return (
        <View>
            <LinearGradient colors={[Colors.brand.midnightTopGradient, Colors.brand.midnight ]} style={{ padding:20, flexDirection:'row', alignItems:'center' }}>
                <View style={{ borderRadius:100, height:75, width:75, backgroundColor:Colors.shades.white, justifyContent:'center', alignItems:'center', ...view_styles.float }}>
                    {company ?
                    <Image
                        source={{ uri: company.company_image?.url }}
                        style={{ height:50, width:50 }}
                        resizeMode='cover'
                    />
                    :<></>}
                </View>
                <View style={{ flex:1, marginLeft:20 }}>
                    <Text size={20} weight='bold' color={Colors.shades.white}>Engage with our partners</Text>
                    <Text style={{ marginTop:10 }} size={14} weight='regular' color={Colors.shades.white}>Competitions, squares, brackets are all brought to you without fees thanks to our amazing partners.</Text>
                </View>
                {onManage ?
                <View style={{ position:'absolute', bottom:5, right:10 }}>
                    <Button
                        title='MANAGE'
                        title_color={Colors.shades.white}
                        padding={10}
                        backgroundColor={Colors.utility.success}
                        onPress={() => onManage()}
                    />
                </View>
                    :<></>}
            </LinearGradient>
            <View nativeID='brands'>
                <TouchableOpacity style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100, borderRadius:0, marginBottom:10 }} onPress={() => setSearch({ ...search, active: !active })}>
                    <View style={{ flex:1, marginRight:10 }}>
                        <Text theme='header'>{active?'Search':'Support'} Our Partners!</Text>
                        {active ?
                        <Text style={{ marginTop:3 }} theme='body'>Use the search box below to find a brand that you're interested in!</Text>
                        :
                        <Text style={{ marginTop:3 }} theme='body'>Below are brands that currently have availble competitions!</Text>
                        }
                    </View>
                    {active ?
                    <Icons.CloseIcon color={Colors.utility.error} size={18} />
                    :
                    <Icons.SearchIcon color={Colors.brand.midnight} size={18} />
                    }
                </TouchableOpacity>
                {active ?
                <View>
                    <View style={{ marginBottom:10 }}>
                        <SearchBox
                            placeholder='Search our brands'
                            onChange={(text) => setSearch({ ...search, value: text })}
                            onSearch={() => getCompaniesFromServer(0)}
                        />
                    </View>
                    {loading ?
                    <ActivityIndicator size='large' color={Colors.brand.midnight} />
                    :<></>}
                    <FlatList
                        data={filtered_companies}
                        renderItem={renderCompanies}
                        horizontal
                        keyExtractor={(item) => item.company_id.toString()}
                    />
                </View>
                :
                <FlatList
                    data={companies}
                    renderItem={renderCompanies}
                    horizontal
                    keyExtractor={(item) => item.company_id}
                />
                }
            </View>
        </View>
    )
}

export default EngageHeader