import React, { useState } from 'react';
import { FlatList, View , TouchableOpacity} from 'react-native';
import type { PlayerSquareProps, SquareOfferProps, SquareProps } from "../../types"
import { view_styles } from '../../constants/styles';
import { Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import SquareOfferCard from './SquareOfferCard';

type SquareOffersCardProps = {
    player_id?:string,
    squares:SquareProps[],
    player_squares:PlayerSquareProps[],
    home_abbr?:string,
    away_abbr?:string,
    square_offers:SquareOfferProps[],
    onRequestAuthenticate:() => void
}
const SquareOffersCard = ({ square_offers, squares, player_squares, player_id, away_abbr, home_abbr, onRequestAuthenticate }:SquareOffersCardProps) => {
    const [ expanded, setExpanded ] = useState(true);

    const renderOffers = (data: { item:SquareOfferProps, index:number }) => {
        const player_square = player_squares.find(ps => ps.sq_player_square_id == data.item.sq_player_square_id);
        if(!player_square){ return <></> }
        const square = squares.find(s => s.sq_square_id == player_square.sq_square_id);
        if(!square){ return <></> }
        return (
            <View>
                <SquareOfferCard
                    square_offer={data.item}
                    square={square}
                    home_abbr={home_abbr}
                    away_abbr={away_abbr}
                    player_square={player_square}
                    player_id={player_id}
                    onRequestAuthenticate={onRequestAuthenticate}
                />
            </View>
        )
    }

    return (
        <View style={{ ...view_styles.section, minWidth:350 }}>
            <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                <View style={{flex:1, marginRight:10}}>
                    <Text theme='header'>SQUARE OFFERS</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>These are offers from other users to buy or sell squares during the game</Text>
                </View>
                <Icons.ChevronIcon direction={expanded?'up':'down'} size={8} color={Colors.brand.midnight} />
            </TouchableOpacity>
            {expanded ?
            <View style={view_styles.section_body}>
                <FlatList
                    data={square_offers}
                    renderItem={renderOffers}
                    horizontal
                    keyExtractor={(item) => item.sq_offer_id.toString()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default SquareOffersCard