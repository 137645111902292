import React from 'react';
import { Image, View } from 'react-native';
import type { CompetitionPlayerProps, CompetitionSeasonProps } from "../../types"
import { Button, Text } from '../../Components';
import Colors from '../../constants/colors';
import moment from 'moment-mini';

type SeasonCardProps = {
    competition_season:CompetitionSeasonProps,
    authenticated_competition_player?:CompetitionPlayerProps,
    onViewSeason: (competition_season:CompetitionSeasonProps) => void
}

const SeasonCard = ({ competition_season, authenticated_competition_player, onViewSeason }:SeasonCardProps) => {
    const can_join = moment().isBefore(moment(competition_season.scheduled_datetime)) ? true : false
    return (
        <View style={{ flexDirection:'row', alignItems:'center', padding:10, backgroundColor:Colors.brand.mint }}>
            <Image
                source={{ uri:competition_season.image?.url }}
                style={{ height:50, width:50, borderRadius:4 }}
                resizeMode='cover'
            />
            <View style={{ flex:1, marginLeft:10 }}>
                <Text theme='header'>Season</Text>
                <Text style={{ marginTop:3 }} theme='header_2'>{competition_season.season_name}</Text>
            </View>
            {!authenticated_competition_player && can_join ?
            <Button
                title='JOIN'
                backgroundColor={Colors.utility.success}
                title_color={Colors.shades.white}
                onPress={() => onViewSeason(competition_season)}
            />
            :
            <Button
                title='VIEW'
                backgroundColor={Colors.brand.electric}
                title_color={Colors.shades.white}
                onPress={() => onViewSeason(competition_season)}
            />
            }
        </View>
    )
}

export default SeasonCard