
import { TextInput, View, FlatList, TouchableOpacity } from "react-native"
import React, { useEffect, useState } from "react";

import type { CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionProps, CompetitionTypeProps, EventProps } from "../../types";
import { ManageCompetitionHelpers } from "../api";
import Colors from "../../constants/colors";
import { Button, Icons, Text } from "../../Components";
import moment from "moment-mini";
import { view_styles } from "../../constants/styles";

type ContestSettingsFormProps = {
    width:number,
    is_valid?:boolean,
    competition:CompetitionProps,
    competition_matches:CompetitionMatchProps[],
    competition_match_markets:CompetitionMatchMarketProps[],
    competition_types:CompetitionTypeProps[],
    events:EventProps[],
    onCompetitionUpdate: (c:CompetitionProps) => void
}

const length_types:CompetitionProps['length_type'][] = ['event']

const ContestSettingsForm = ({ is_valid, width, competition, competition_matches, competition_match_markets, competition_types, events, onCompetitionUpdate }:ContestSettingsFormProps) => {
    const [ expanded, setExpanded ] = useState(false);
    const [ draft_competition, setDraftCompetition ] = useState<CompetitionProps | undefined>(undefined);

    const competition_type = competition_types.find(ct => ct.competition_type_id == competition?.competition_type_id);

    const start_time_options = ManageCompetitionHelpers.getStartTimeOptions(competition, competition_matches, competition_match_markets, events);

    useEffect(() => {
        setDraftCompetition(competition);
    },[competition])

    const renderCompetitionTypes = (data:{ item:CompetitionTypeProps, index:number }) => {
        if(!draft_competition) { return <></> }
        const selected = data.item.competition_type_id == competition.competition_type_id ? true : false
        return (
            <TouchableOpacity 
                disabled={competition.competition_season_id ? true : false}
                style={{ minWidth:75, margin:1, borderRadius:8, padding:10, backgroundColor:selected?Colors.brand.midnight:undefined }} onPress={() => onCompetitionUpdate({ ...draft_competition, competition_type_id: data.item.competition_type_id })}>
                <Text textAlign="center"  color={selected?Colors.shades.white:Colors.brand.midnight} weight={selected?'bold':'regular'} size={14}>{data.item.type_label}</Text>
            </TouchableOpacity>
        )
    }

    const renderLengthTypes = (data:{ item:'event' | 'time', index:number }) => {
        if(!draft_competition) { return <></> }
        const selected = data.item == competition?.length_type ? true : false
        return (
            <TouchableOpacity 
            disabled={competition.competition_season_id ? true : false}
            style={{ minWidth:75, margin:1, borderRadius:8, padding:10, backgroundColor:selected?Colors.brand.midnight:undefined }} onPress={() => onCompetitionUpdate({ ...draft_competition, length_type: data.item })}>
                <Text textAlign="center"  color={selected?Colors.shades.white:Colors.brand.midnight} weight={selected?'bold':'regular'} size={14}>{data.item.toUpperCase()}</Text>
            </TouchableOpacity>
        )
    }    

    const renderStartTimeOptions = (data: { item: { start_time:string, label:string }, index:number }) => {
        if(!draft_competition){ return <></> }
        const selected = competition?.scheduled_datetime == data.item.start_time ? true : false
        return (
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:10 }} onPress={() => onCompetitionUpdate({ ...draft_competition, scheduled_datetime: data.item.start_time })}>
                <View style={{ height:25, width:25, borderRadius:100, borderWidth:1, borderColor:Colors.brand.midnight, justifyContent:'center', alignItems:'center' }}>
                    {selected ?
                    <View style={{ height:15, width:15, borderRadius:100, backgroundColor:Colors.brand.midnight }} />
                    :<></>}
                </View>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text weight='bold' size={14} color={Colors.brand.midnight}>{data.item.label}</Text>
                    <Text weight='semibold' style={{ marginTop:4 }} size={14} color={Colors.brand.slate}>{moment(data.item.start_time).format('MMM DD YYYY hh:mm a')}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    if(!competition || !draft_competition){ return <></> }
    const is_changed = JSON.stringify({ max_pick_count:competition.max_pick_count, initial_balance:competition.initial_balance }) != JSON.stringify({ max_pick_count:draft_competition.max_pick_count, initial_balance:draft_competition.initial_balance }) ? true : false
    return (
        <View style={{ ...view_styles.section, minWidth:350, maxWidth: width, flexGrow:1 }}>
            <TouchableOpacity style={{ ...view_styles.section_header, maxWidth:width }} onPress={() => setExpanded(!expanded)}>
                {is_valid ?
                <Icons.CheckCirlceIcon size={16} color={Colors.utility.success} />
                :
                <Icons.AlertIcon size={16} color={Colors.utility.warning} />
                }
                <View style={{ flex:1, marginLeft:10, marginRight:10 }}>
                    <Text theme="header">Contest Settings</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Manage the settings of how user will interact with the added contests</Text>
                </View>
                {is_changed ?
                <Button
                    title='SAVE'
                    padding={10}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onCompetitionUpdate(draft_competition)}
                />
                :
                <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} color={Colors.brand.midnight} size={8} />
                }
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body, padding:10, backgroundColor:Colors.shades.shade100 }}>
                <View nativeID="competition_type" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1 }}>
                        <Text theme="header_2">Competition Type</Text>
                        <Text style={{ marginTop:3 }} theme="body">{competition_type?competition_type.description:'Choose the type of competition this is.'}</Text>
                    </View>
                    <View style={{ margin:5, borderRadius:8, backgroundColor:Colors.shades.white }}>
                        <FlatList
                            data={competition_types}
                            renderItem={renderCompetitionTypes}
                            keyExtractor={(item) => item.competition_type_id.toString()}
                            horizontal
                        />
                    </View>
                </View>
                <View style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width, marginTop:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1 }}>
                        <Text theme="header_2">Length Type</Text>
                        <Text style={{ marginTop:3 }}>Choose how long this competition will remain open.</Text>
                    </View>
                    <View style={{ borderRadius:8, backgroundColor:Colors.shades.white }}>
                        <FlatList
                            data={length_types}
                            renderItem={renderLengthTypes}
                            keyExtractor={(item) => item}
                            horizontal
                        />
                    </View>
                </View>
                {competition_type?.type == 'pick' ?
                <View style={{ ...view_styles.body_row, maxWidth:width, marginTop:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1 }}>
                        <Text theme="header_2">Max Picks</Text>
                        <Text style={{ marginTop:3 }}>The total number of picks allowed by each participant.</Text>
                    </View>
                    <TextInput
                        editable={competition.competition_season_id ? false : true}
                        style={{ ...view_styles.input, width:75, textAlign:'center' }}
                        onChangeText={(text) => setDraftCompetition({ ...draft_competition, max_pick_count: text })}
                        placeholder="0"
                        value={draft_competition.max_pick_count as string}
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                :<></>}
                {competition_type?.type == 'wager' ?
                <View style={{ ...view_styles.body_row, maxWidth:width, borderBottomWidth:1, marginTop:10, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1 }}>
                        <Text theme="header_2">Initial Balance</Text>
                        <Text style={{ marginTop:3 }}>Initial balance that each player will start with at the beginning of the competition.</Text>
                    </View>
                    <TextInput
                        editable={competition.competition_season_id ? false : true}
                        style={{ ...view_styles.input, width:75, textAlign:'center' }}
                        onChangeText={(text) => setDraftCompetition({ ...draft_competition, initial_balance: text })}
                        placeholder="0"
                        value={draft_competition.initial_balance as string}
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                :<></>}
                <View>
                    <View style={{ ...view_styles.body_row, marginTop:10 }}>
                        <View style={{ flex:1 }}>
                            <Text theme="header_2">Start Time Options</Text>
                            <Text style={{ marginTop:3 }}>When should this competition stop allowing entrants.</Text>
                        </View>
                    </View>
                    <View style={{ padding:10, backgroundColor:Colors.shades.shade100, borderRadius:4 }}>
                        <FlatList
                            data={start_time_options}
                            renderItem={renderStartTimeOptions}
                            keyExtractor={(item) => item.start_time}
                        />
                    </View>
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default ContestSettingsForm




/*


            <View style={{ flexDirection:'row', flexWrap:'wrap', padding:10 }}>
            <View nativeID="contest_settings" style={{ flex:1, minWidth:300, padding:10, borderRadius:8, margin:5, backgroundColor:Colors.shades.white }}>
                <View nativeID="header_row" style={{ flexDirection:'row', alignItems:'center', padding:5, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                    <Text style={{ flex:1, marginRight:10 }} size={22} color={Colors.brand.slate} weight='bold'>Competition Info</Text>
                </View>
                <View nativeID="competition_type" style={{ flexDirection:'row', flexWrap:'wrap', padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.white }}>
                        <View style={{ flex:1, marginRight:5 }}>
                            <Text size={16} color={Colors.brand.midnight} weight='bold'>Length Type</Text>
                            <Text size={14} color={Colors.brand.midnight} weight='regular'>Choose how long this competition will remain open.</Text>
                        </View>
                        
                    </View>
                {competition_type?.type == 'pick' ?
                <View nativeID="max_picks" style={{ flexDirection:'row', flexWrap:'wrap', padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.white }}>
                    <View style={{ flex:1, marginRight:5 }}>
                        <Text size={16} color={Colors.brand.midnight} weight='bold'>Max Picks</Text>
                        <Text size={14} color={Colors.brand.midnight} weight='regular'>Maximum number of picks that will be allowed for each player in the competition</Text>
                    </View>
                    <TextInput
                        style={{ padding:10, borderRadius:8, backgroundColor:Colors.shades.white }}
                        onChangeText={(text) => onCompetitionUpdate({ ...competition, max_pick_count: text })}
                        placeholder="0"
                        value={competition.max_pick_count}
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                :<></>}
                {competition_type?.type == 'wager' ?
                <View nativeID="wager_balance" style={{ flexDirection:'row', flexWrap:'wrap', padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.white }}>
                    <View style={{ flex:1, marginRight:5 }}>
                        <Text size={16} color={Colors.brand.midnight} weight='bold'>Initial Balance</Text>
                        <Text size={14} color={Colors.brand.midnight} weight='regular'>Initial balance that each player will start with at the beginning of the competition.</Text>
                    </View>
                    <TextInput
                        style={{ padding:10, borderRadius:8, backgroundColor:Colors.shades.white }}
                        onChangeText={(text) => onCompetitionUpdate({ ...competition, initial_balance: text })}
                        placeholder="1000"
                        value={competition.initial_balance}
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                :<></>}
                
            </View>
        </View>
        <View style={{ flexDirection:'row', flexWrap:'wrap', padding:10, paddingTop:0 }}>
            <View nativeID="contest_settings" style={{ flex:1, minWidth:300, padding:10, borderRadius:8, margin:5, backgroundColor:Colors.shades.white }}>
                <View nativeID="header_row" style={{ flexDirection:'row', alignItems:'center', padding:5, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                    <Text style={{ flex:1, marginRight:10 }} size={22} color={Colors.brand.slate} weight='bold'>Competition Start</Text>
                </View>
                <View nativeID="max_picks" style={{ padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.white }}>
                    <View nativeID="descriptor">
                        <Text size={16} color={Colors.brand.midnight} weight='bold'>Start Time</Text>
                        <Text size={14} color={Colors.brand.midnight} weight='regular'>The time when tickets can no longer be purchased</Text>
                    </View>
                    <View style={{ padding:10 }}>
                        <FlatList
                            data={start_time_options}
                            renderItem={renderStartTimeOptions}
                            keyExtractor={(item) => item.start_time}
                        />
                    </View>
                </View>
            </View>
            </View>
            {competition.length_type == 'time' ?
            <View style={{ flexDirection:'row', flexWrap:'wrap', padding:10 }}>
                <View nativeID="contest_settings" style={{ flex:1, minWidth:300, padding:10, borderRadius:4, marginRight:5, ...styles.float }}>
                    <View nativeID="header_row" style={{ flexDirection:'row', alignItems:'center', padding:5, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                        <Text style={{ flex:1, marginRight:10 }} size={22} color={Colors.brand.slate} weight='bold'>Competition End Time</Text>
                    </View>
                    <View nativeID="max_picks" style={{ padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.white }}>
                        <View nativeID="descriptor">
                            <Text size={16} color={Colors.brand.midnight} weight='bold'>End Time</Text>
                            <Text size={14} color={Colors.brand.midnight} weight='regular'>When will this competition end?</Text>
                        </View>
                        <View style={{ padding:10 }}>
                           <DateTimePicker 
                            value={competition?.end_datetime}
                            onChange={(value:any) => {
                               onCompetitionUpdate({ ...competition, end_datetime: value })
                            }}
                            
                           />
                        </View>
                    </View>
                </View>
            </View>
            :<></>}


*/