import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View, FlatList } from 'react-native';
import { view_styles } from '../../constants/styles';
import { Button, Icons, Switch, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import type { CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionTypeProps } from '../../types';
import { ManageSeasonHelpers } from '../api';
import DropDown from '../../Components/Dropdown';

type SeasonSettingsFormProps = {
    width:number,
    competition_season:CompetitionSeasonProps,
    competition_types:CompetitionTypeProps[],
    competition_result_types:CompetitionResultTypeProps[],
    onSeasonUpdate: (cs:CompetitionSeasonProps) => void
}

const SeasonSettingsForm = ({ competition_season, width, competition_types, competition_result_types, onSeasonUpdate }:SeasonSettingsFormProps) => {
    const [ expanded, setExpanded ] = useState(false);
    const [ draft_season, setDraftSeason ] = useState<CompetitionSeasonProps>();

    const competition_type = competition_types.find(ct => ct.competition_type_id == draft_season?.competition_type_id);
    const competition_result_type = competition_result_types.find(crt => crt.competition_result_type_id == draft_season?.competition_result_type_id);

    useEffect(() => {
        setDraftSeason(competition_season)
    },[competition_season])

    if(!draft_season){ return <></> }

    const renderCompetitionTypes = (data:{ item:CompetitionTypeProps, index:number }) => {
        if(!draft_season) { return <></> }
        const selected = data.item.competition_type_id == draft_season.competition_type_id ? true : false
        return (
            <TouchableOpacity style={{ minWidth:75, margin:1, borderRadius:8, padding:10, backgroundColor:selected?Colors.brand.midnight:undefined }} onPress={() => onSeasonUpdate({ ...draft_season, competition_type_id: data.item.competition_type_id })}>
                <Text textAlign="center"  color={selected?Colors.shades.white:Colors.brand.midnight} weight={selected?'bold':'regular'} size={14}>{data.item.type_label}</Text>
            </TouchableOpacity>
        )
    }

    const is_changed = ManageSeasonHelpers.isSettingsChanged(competition_season, draft_season);
    const is_valid = ManageSeasonHelpers.isSettingsValid(draft_season);
    return (
        <View style={{ ...view_styles.section }}>
            <TouchableOpacity style={{ ...view_styles.section_header }} onPress={() => setExpanded(!expanded)}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Season Settings</Text>
                    <Text style={{ marginTop:3 }}>Update season settings</Text>
                </View>
                {is_changed ?
                <Button
                    title='SAVE'
                    padding={10}
                    style={{ opacity: is_valid ? 1 : 0.5 }}
                    disabled={!is_valid}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onSeasonUpdate(draft_season)}
                />
                :
                <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} color={Colors.brand.midnight} size={8} />
                }
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body }}>
                 <View style={{ ...view_styles.body_row, maxWidth:width, borderBottomWidth:1, marginTop:10, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1 }}>
                        <Text theme="header_2">Ticket Price</Text>
                        <Text style={{ marginTop:3 }}>How much does it cost to enter this season?</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, width:75, textAlign:'center' }}
                        onChangeText={(text) => setDraftSeason({ ...draft_season, ticket_price: text })}
                        placeholder="0"
                        value={draft_season.ticket_price as string}
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                <View style={{ ...view_styles.body_row, maxWidth:width, borderBottomWidth:1, marginTop:10, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1 }}>
                        <Text theme="header_2">Season Payout Allocation</Text>
                        <Text style={{ marginTop:3 }}>How much of the total pot should be allocated to the season winners?  Remaining is paid out each competition.</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, width:75, textAlign:'center' }}
                        onChangeText={(text) => setDraftSeason({ ...draft_season, payout_allocation: text })}
                        placeholder="0"
                        value={draft_season.payout_allocation as string}
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                <View nativeID="competition_type" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1 }}>
                        <Text theme="header_2">Competition Type</Text>
                        <Text style={{ marginTop:3 }} theme="body">{competition_type?competition_type.description:'Choose the type of competition this is.'}</Text>
                    </View>
                    <View style={{ margin:5, borderRadius:8, backgroundColor:Colors.shades.white }}>
                        <FlatList
                            data={competition_types}
                            renderItem={renderCompetitionTypes}
                            keyExtractor={(item) => item.competition_type_id.toString()}
                            horizontal
                        />
                    </View>
                </View>
                {competition_type?.type == 'pick' ?
                <View style={{ ...view_styles.body_row, maxWidth:width, marginTop:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1 }}>
                        <Text theme="header_2">Max Picks</Text>
                        <Text style={{ marginTop:3 }}>The total number of picks allowed by each participant.</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, width:75, textAlign:'center' }}
                        onChangeText={(text) => setDraftSeason({ ...draft_season, max_picks: text })}
                        placeholder="0"
                        value={draft_season.max_picks as string}
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                :<></>}
                {competition_type?.type == 'wager' ?
                <View style={{ ...view_styles.body_row, maxWidth:width, borderBottomWidth:1, marginTop:10, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1 }}>
                        <Text theme="header_2">Initial Balance</Text>
                        <Text style={{ marginTop:3 }}>Initial balance that each player will start with at the beginning of the competition.</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, width:75, textAlign:'center' }}
                        onChangeText={(text) => setDraftSeason({ ...draft_season, initial_balance: text })}
                        placeholder="0"
                        value={draft_season.initial_balance as string}
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                :<></>}
                {competition_type?.type == 'wager' ?
                <View style={{ ...view_styles.body_row, maxWidth:width, borderBottomWidth:1, marginTop:10, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1 }}>
                        <Text theme="header_2">Rollover Balance</Text>
                        <Text style={{ marginTop:3 }}>Should the wager balance for each player rollover from prior competitions?</Text>
                    </View>
                    <Switch
                        value={draft_season.balance_rollover}
                        switch_type="on_off"
                        onChange={(value) => onSeasonUpdate({ ...draft_season, balance_rollover: value })}
                    />
                </View>
                :<></>}
                 <View nativeID="competition_payout" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width}}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme="header_2">Competition Payout Type</Text>
                        <Text style={{ marginTop:3 }} theme="body">{competition_result_type?competition_result_type.description:'Choose the type of payout for this competition'}</Text>
                    </View>
                    <View style={{ justifyContent:'flex-end', margin:5, borderRadius:8, backgroundColor:Colors.shades.white }}>
                        <DropDown
                            dropdown_options={[
                                {value: 'competition_result_type_id', eligible_options: competition_result_types.filter(crt => crt.type != 'outpace').map(t => t.label)}
                            ]}
                            selected_value={competition_result_type?.label ?? ''}
                            onOptionSelect={(type) => {
                                const new_result_type = competition_result_types.find(crt => crt.label == type);
                                if(!new_result_type){ return }
                                onSeasonUpdate({ ...draft_season, competition_result_type_id: new_result_type.competition_result_type_id })
                            }}
                        />
                    </View>
                </View>
                <View nativeID="invite_only" style={{ ...view_styles.body_row, flexWrap:'wrap', marginTop:10, borderBottomWidth:1, borderColor:Colors.shades.shade600  }}>
                    <View style={{ flex:1, margin:5, marginRight:15 }}>
                        <Text theme='header_2'>Allow Late Buy In</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Determines if a player can join the season after it has started.</Text>
                    </View>
                    <Switch
                        value={draft_season.allow_late_buy_in}
                        switch_type="on_off"
                        onChange={(value) => onSeasonUpdate({ ...draft_season, allow_late_buy_in: value })}
                    />
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default SeasonSettingsForm