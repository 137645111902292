import React from 'react';
import { FlatList, View, Image } from 'react-native';

type ImageListProps = {
    post_id:string,
    images: any[],
    width:number
}
const ImageList = ({ post_id, width, images }:ImageListProps) => {
    
    const resizeImage = (image:any) => {
        let image_width = image.width ?? width
        let image_height = image.height ?? width
        const ratio = image_height / image_width
        let new_width = width
        let new_height = width * ratio
        return { width: new_width, height: new_height }
    }

    const renderImages = (data: { item:any, index:number }) => {
        const { width, height } = resizeImage(data.item);
        return (
            <View>
                <Image
                    source={{ uri:data.item.url }}
                    style={{ width, height }}
                    resizeMode='cover'
                />
            </View>
        )
    }

    return (
        <View>
            <FlatList
                key={`${post_id}_post_images`}
                data={images}
                horizontal
                renderItem={renderImages}
                keyExtractor={(item) => item.url}
            />
        </View>
    )
}

export default ImageList