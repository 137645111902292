import axios from "axios";
import { APIOverrides } from "../../../ApiOverrides";
import type { OrderProps, PlayerFollowerProps, PlayerFollowerStatsProps, PlayerLinkProps, PostCommentStatProps, PostProps, PostReactionProps, PublicPlayerProps } from "../../../types";

let SOCIAL_SVC_API = '';
let AUTH_SVC_API = '';
let MK_SVC_API = '';

export { SocialProfileApi }

const SocialProfileApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        SOCIAL_SVC_API = endpoints['SOCIAL_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
        MK_SVC_API = endpoints['MK_SVC_API'] as string;
    },
    getPlayerById: async(player_id:string):Promise<PublicPlayerProps | undefined> => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/${player_id}`);
            return resp.data.player
        } catch (e) {
            console.log(e);
            return undefined
        }
    },
    getPlayerLinksByPlayerId: async(player_id:string):Promise<PlayerLinkProps[]> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/links/player/${player_id}`);
            return resp.data.player_links
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getFollowerStats:async(player_id:string):Promise<PlayerFollowerStatsProps | undefined> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/followers/stats/player/${player_id}`)
            const { player_follower_stats } = resp.data;
            return player_follower_stats
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    getFollowerStatus: async(player_id:string, following_id:string):Promise<PlayerFollowerProps | undefined> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/followers/status?player_id=${player_id}&following_id=${following_id}`);
            return resp.data.player_follower
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    getPostsByPlayerId: async(player_id:string, offset:number):Promise<PostProps[]> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/posts/player/${player_id}?offset=${offset}`)
            return resp.data.posts
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getPlayerFollowersByPlayerId: async(player_id:string, status:'following'|'followers', offset:number):Promise<PlayerFollowerProps[]> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/followers/player/${player_id}?status=${status}&offset=${offset}`)
            return resp.data.player_followers
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getCommentStatsByPostId: async(post_id:string, post_type:'memo'):Promise<PostCommentStatProps | undefined> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/posts/comments/stats/post/${post_id}?post_type=${post_type}`)
            return resp.data.post_comment_stat
        } catch (e) {
            return undefined
        }
    },
    getReactionStatsByPostId: async(post_id:string, post_type:'memo') => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/posts/reactions/stats/post/${post_id}?post_type=${post_type}`)
            return resp.data.post_reaction_stats
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getReactionsByPostAndPlayer: async(player_id:string, post_id:string, post_type:'memo'):Promise<PostReactionProps[]> => {
        try {
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/posts/reactions/post/${post_id}?player_id=${player_id}&post_type=${post_type}`)
            return resp.data.post_reactions
        } catch (e) {
            console.log(e);
            return []
        }
    },
    createPostReaction: async(post_reaction:PostReactionProps):Promise<PostReactionProps|undefined> => {
        try {
            const resp = await axios.post(`${SOCIAL_SVC_API}/v1/posts/reaction/create`, { post_reaction });
            return resp.data.post_reaction
        } catch (e) {
            console.log(e);
            return undefined
        }
    },
    updatePostReaction : async(post_reaction:PostReactionProps):Promise<PostReactionProps|undefined> => {
        try {
            const resp = await axios.post(`${SOCIAL_SVC_API}/v1/posts/reaction/update`, { post_reaction })
            return resp.data.post_reaction
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    getBulkOrders: async(order_ids:string[]):Promise<OrderProps[]> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/bulk/get`, { order_ids })
            return resp.data.orders
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getOrdersByPlayerId: async(player_id:string, offset:number):Promise<OrderProps[]> => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/player/${player_id}?offset=${offset}`)
            return resp.data.orders
        } catch (e) {
            console.log(e);
            return []
        }
    }
}
