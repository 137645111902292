import React from 'react';
import { Image, View, TouchableOpacity } from 'react-native';
import { view_styles } from "../constants/styles";
import type { PlayerPodcastEpisodeProps, PodcastEpisodesProps, PodcastProps } from '../types';
import { Icons, Text } from '../Components';
import { SocialPodcastHelpers } from './api';
import Colors from '../constants/colors';
import moment from 'moment-mini';

type PodcastEpisodeCardProps = {
    podcast_episode:PodcastEpisodesProps
    player_podcast_episode?:PlayerPodcastEpisodeProps,
    podcast?:PodcastProps,
    width:number,
    onShare?:(pe:PodcastEpisodesProps) => void,
    onSelectEpisode:(pe:PodcastEpisodesProps) => void
}

const PodcastEpisodeCard = ({ width, podcast_episode, player_podcast_episode, onSelectEpisode, onShare }: PodcastEpisodeCardProps ) => {


    return (
        <TouchableOpacity style={{ ...view_styles.section, flex:1, width, borderWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onSelectEpisode(podcast_episode)}>
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>{moment(podcast_episode.publish_date).format('MMM DD YYYY')}</Text>
                </View>
                {onShare?
                <TouchableOpacity style={{ padding:5 }} onPress={() => onShare(podcast_episode)}>
                    <Icons.ShareIcon size={14} color={Colors.brand.electric} />
                </TouchableOpacity>
                :<></>}
            </View>
            <View style={{ ...view_styles.section_body, padding:0 }}>
                <View>
                    <Image
                        source={{ uri: SocialPodcastHelpers.getEpisodeImage(podcast_episode) }}
                        style={{ width: width, height: width * 0.666 }}
                    />
                    <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                        <View style={{ ...view_styles.section, borderRadius:22, padding:10 }}>
                            <View style={{ ...view_styles.body_row }}>
                                <Icons.PlayIcon size={18} color={Colors.brand.electric} />
                                {player_podcast_episode ?
                                <Text size={14} color={Colors.brand.electric} weight='semibold' style={{ marginLeft:10 }}>Resume</Text>
                                :
                                <Text size={14} color={Colors.brand.electric} weight='semibold' style={{ marginLeft:10 }}>Play</Text>
                                }
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ padding:10 }}>
                    <Text theme='header_2'>{podcast_episode.title}</Text>
                </View>
            </View>
            
        </TouchableOpacity>
    )
}

export default PodcastEpisodeCard