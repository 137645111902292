import React from 'react';
import { View } from "react-native"
import { Icons, Text, TextInput } from "../../Components"
import type { AuthStrategyIdentifierProps } from '../api/types';
import Colors from '../../constants/colors';
import { AuthenticateHelpers } from '../api';

type AuthStrategyIdentifierCardProps = {
    auth_strategy_identifier:AuthStrategyIdentifierProps,
    auth_request?: any,
    auto_focus?:boolean,
    onChangeIdentifier: (text:string,  is_valid:boolean, pretty_value?:string) => void 
}

const AuthStrategyIdentifier = ({ auth_strategy_identifier, auto_focus, auth_request, onChangeIdentifier }:AuthStrategyIdentifierCardProps) => {
    

    const getAttributeIcon = (attribute:string) => {
        switch(attribute){
            case 'email': return <Icons.EmailIcon size={20} color={Colors.brand.midnight}/>
            case 'phone': return <Icons.PhoneIcon size={20} color={Colors.brand.midnight} />
            case 'username': return <Icons.UserIcon size={20} color={Colors.brand.midnight} />
            default: return <Icons.UserIcon size={20} color={Colors.brand.midnight} />
        }
    }

    if(!auth_strategy_identifier.auth_identifier){ return <></> }
    const is_valid = AuthenticateHelpers.isAttributeValid(auth_request.attribute, auth_request.value)

    return (
        <View>
            <View style={{ margin:10 }}>
                <Text size={16} color={Colors.brand.midnight} weight='bold'>{auth_strategy_identifier.label}</Text>
                <Text style={{ marginTop:4 }} size={14} color={Colors.brand.midnight} weight='regular'>{auth_strategy_identifier.description}</Text>
                <View style={{ marginTop:5, flexDirection:'row', alignItems:'center', borderRadius:22, borderWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ paddingLeft:10, paddingRight:15 }}>
                    {getAttributeIcon(auth_strategy_identifier.auth_identifier.attribute)}
                    </View>
                    <TextInput
                        style={{ flex:1 }}
                        value={auth_request.pretty_value ?? auth_request.value}
                        placeholder={`${auth_strategy_identifier.label}`}
                        autoFocus={auto_focus}
                        placeholderTextColor={Colors.brand.slate}
                        onChangeText={(text) => {
                            let pretty_value:string | undefined = undefined
                            if(auth_request.attribute == 'phone'){
                                text = AuthenticateHelpers.unformatPhone(text)
                                pretty_value = AuthenticateHelpers.formatPhone(text)
                            }
                            onChangeIdentifier(text, is_valid.valid, pretty_value)
                        }}
                    />
                    {auth_request.value ?
                    <View style={{ padding:10 }}>
                        { is_valid.valid ?
                        <Icons.CheckCirlceIcon color={Colors.utility.success} size={14} />
                        :
                        <Icons.AlertIcon color={Colors.utility.warning} size={14} />
                        }
                    </View>
                    :<></>}
                </View>
                {auth_request.value && !is_valid.valid ?
                <Text style={{ marginTop:2 }} size={12} color={Colors.utility.warning} weight='semibold' textAlign="right">{is_valid.error}</Text>
                :<></>}
            </View>
        </View>
    )
}

export default AuthStrategyIdentifier