import React, { useState } from "react"
import { TextInput, View } from "react-native"
import type { PlayerPickProps } from "../../types"
import { CompetitionHelpers } from "../api"
import Colors from "../../constants/colors"
import { Button, Text } from "../../Components"
import moment from "moment-mini"


type WagerPickFormProps = {
    event_title:string,
    balance:number,
    event_start:string,
    player_pick: PlayerPickProps,
    loading?:string,
    width:number,
    onCancel: () => void,
    onSubmit: (pp:PlayerPickProps) => void
}

const WagerPickForm = ({ player_pick, event_title, balance, event_start, width, loading, onCancel, onSubmit }:WagerPickFormProps) => {
    const [ reviewed, setReviewed ] = useState(false);
    const [ wager, setWager ] = useState({ stake:0, stake_label: '0', potential_winnings: 0 });


    const handleWagerChange = (text:string) => {
        if(text == ''){ return setWager({ stake:0, stake_label:'0', potential_winnings:0 }) }
        let wager_amt = parseFloat(text)
        let potential_winnings = CompetitionHelpers.calcPotentialWinnings(wager_amt, player_pick.odds)
        setWager({ stake:wager_amt, stake_label:wager_amt.toString(), potential_winnings })
    }

    const handleSubmit = () => {
        if(wager.stake > balance){ return alert('Not enough balance to make pick') }
        if(!reviewed){ return setReviewed(true) }
        if(wager.stake <= 0){ return alert('Invalid wager amount') }
        if(wager.potential_winnings <= 0){ return alert('Invalid Wager') }
        onSubmit({ ...player_pick, stake: wager.stake, potential_winnings: wager.potential_winnings })
    }

    return (
        <View style={{ width, backgroundColor:Colors.shades.white, borderTopRightRadius:22, borderTopLeftRadius:22 }}>
            <View nativeID="event_info" style={{ padding:20 }}>
                <Text size={14} color={Colors.shades.black} weight='bold' textAlign="center">{moment(event_start).format('MM/DD @ hh:mm a')}</Text>
                <Text style={{ marginTop:5 }} size={14} color={Colors.shades.black} weight='bold' textAlign="center">{event_title}</Text>
            </View>
            <View nativeID="pick_details" style={{ padding:10 }}>
                <View nativeID="outcome" style={{ flexDirection:'row', margin:5 }}>
                    <Text style={{ flex:1 }} size={14} color={Colors.shades.black} weight='regular'>Outcome</Text>
                    <Text style={{ flex:2 }} size={14} color={Colors.shades.black} weight="semibold" textAlign="right">{player_pick.pick_title}</Text>
                </View>
                <View nativeID="probability" style={{ flexDirection:'row', margin:5 }}>
                    <Text style={{ flex:1 }} size={14} color={Colors.shades.black} weight='regular'>Probability</Text>
                    <Text style={{ flex:2 }} size={14} color={Colors.shades.black} weight="semibold" textAlign="right">{(player_pick.probability * 100).toFixed(2)}%</Text>
                </View>
                <View nativeID="odds" style={{ flexDirection:'row', margin:5 }}>
                    <Text style={{ flex:1 }} size={14} color={Colors.shades.black} weight='regular'>Odds</Text>
                    <Text style={{ flex:2 }} size={14} color={Colors.shades.black} weight="semibold" textAlign="right">{CompetitionHelpers.getOddsLabel(player_pick.odds)}</Text>
                </View>
            </View>
            <View nativeID="pick_input" style={{ flexDirection:'row', padding:10, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flex:1, marginRight:10 }}>
                    <Text size={14} color={Colors.brand.slate} weight='regular'>WAGER AMOUNT</Text>
                    <View style={{ flex:1, flexDirection:'row', marginTop:5, borderRadius:4, backgroundColor:Colors.shades.white, borderWidth:1, borderColor:Colors.accents.accent100 }}>
                        <Text size={14} textAlign="center" color={Colors.shades.black} style={{ padding:10, borderRightWidth:1, borderColor:Colors.accents.accent100 }}>E</Text>
                        <TextInput
                            style={{ textAlign:'center', color:Colors.shades.black, fontSize:14, backgroundColor:Colors.shades.white }}
                            value={wager.stake_label}
                            onChangeText={(text) => handleWagerChange(text)}
                        />
                    </View>
                    <Text weight='semibold' style={{ margin:5 }} color={Colors.utility.success}>{balance.toFixed(2)} Available</Text>
                </View>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text size={14} color={Colors.brand.slate} weight='regular'>WINNINGS</Text>
                    <View style={{ flexDirection:'row', marginTop:5, borderRadius:4, borderWidth:1, borderColor:Colors.accents.accent100 }}>
                        <Text size={14} textAlign="center" color={Colors.shades.black} style={{ padding:10, borderRightWidth:1, borderColor:Colors.shades.shade100 }}>E</Text>
                        <Text
                            style={{ padding:10, flex:1 }}
                            textAlign="center"
                            size={14} color={Colors.shades.black}
                        >
                            {wager.potential_winnings.toFixed(2)}
                        </Text>
                    </View>
                </View>
            </View>
            <View nativeID="pick_actions" style={{ flexDirection:'row', padding:10 }}>
                <Button
                    style={{ flex:1, marginRight:3 }}
                    borderRadius={4}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    padding={10}
                    title="Cancel"
                    title_color={Colors.brand.electric}
                    onPress={() => onCancel()}
                />
                 <Button
                    borderRadius={4}
                    style={{ flex:2, marginLeft:2, opacity:loading || wager.stake <= 0 || wager.stake > balance ? 0.5 : 1 }}
                    disabled={ loading || wager.stake <= 0 || wager.stake > balance ? true : false }
                    backgroundColor={reviewed?Colors.utility.success:Colors.brand.electric}
                    padding={10}
                    title={reviewed ? 'Submit' : 'Review'}
                    title_color={Colors.shades.white}
                    onPress={() => handleSubmit()}
                />
            </View>
        </View>
    )
}

export default WagerPickForm