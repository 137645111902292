import axios from "axios";
import type { AthleteProps, CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionPayoutTypeProps, CompetitionPlayerProps, CompetitionProps, CompetitionRecordProps, CompetitionResultProps, CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionTypeProps, CreateCompetitionProps, EventProps, LeagueProps, MarketProps, MatchProps, MyPlayerProps, PlayerBalanceProps, PlayerPickProps, PlayerReferralProps, PromoProps, PublicPlayerProps, TeamProps, TournamentProps, TradeProps } from "../../types";
import { APIOverrides } from "../../ApiOverrides";
import moment from "moment-mini";
import Colors from "../../constants/colors";

let AUTH_SVC_API = ''
let TP_SVC_API = ''
let EVENT_SVC_API = ''
let MK_SVC_API = ''
export { CompetitionSeasonApi, CompetitionApi, CompetitionHelpers, CompetitionPlayerApi, PlayerPicksApi, CompetitionResultApi, CompetitionMatchApi }

const CompetitionSeasonApi = {
    createCompetitionSeason : async(competition_season:CompetitionSeasonProps):Promise<CompetitionSeasonProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/competitions/season/create`, { competition_season })
        return resp.data.competition_season
    },
    updateCompetitionSeason : async(competition_season:CompetitionSeasonProps):Promise<CompetitionSeasonProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/competitions/season/update`, { competition_season })
        return resp.data.competition_season
    },
    getCompetitionSeasonTemplates: async(league_id?:string):Promise<CompetitionSeasonProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/seasons/templates?league_id=${league_id}`)
        return resp.data.competition_seasons
    },
    getCompetitionSeasonsByAdminId: async(status?:string):Promise<CompetitionSeasonProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/seasons/me?status=${status}`)
        return resp.data.competition_seasons
    },
    getCompetitionSeasonById: async(competition_season_id:string):Promise<CompetitionSeasonProps> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/seasons/season/${competition_season_id}`)
        return resp.data.competition_season
    },
    getLeaderboardBySeasonId: async(competition_season_id:string):Promise<any> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/leaderboard/season/${competition_season_id}`)
        return resp.data.season_leaderboard
    },
    getSponsoredCompetitionSeasons: async(sponsor_id:string):Promise<CompetitionSeasonProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/seasons/sponsored/${sponsor_id}`)
        return resp.data.competition_seasons
    },
} 

const CompetitionApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        TP_SVC_API = endpoints['TP_SVC_API'] as string;
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        MK_SVC_API = endpoints['MK_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getPlayersByPlayerIds : async(player_ids:string[]):Promise<PublicPlayerProps[]> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
        return resp.data.players
    },
    getSeasonById: async(competition_season_id:string):Promise<CompetitionSeasonProps> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/seasons/season/${competition_season_id}`);
        return resp.data.competition_season
    },
    getLeagues: async():Promise<LeagueProps[]> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues?status=active`)
        return resp.data.leagues
    },
    getMarkets: async():Promise<MarketProps[]> => {
        const resp = await axios.get(`${MK_SVC_API}/v1/markets/all`)
        return resp.data.markets
    },
    getLeagueById: async(league_id:string):Promise<LeagueProps> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues/league/${league_id}`)
        return resp.data.league
    },
    getCompetitionsByCompanyId: async(company_id:string, offset:number, status:'active'|'closed'):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/company/${company_id}?offset=${offset}&status=${status}`)
        return resp.data.competitions
    },
    getCompetitionById: async(competition_id:string):Promise<CompetitionProps> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/competition/${competition_id}`)
        return resp.data.competition
    },
    getCompetitionMatchesByCompetitionId: async(competition_id:string):Promise<{competition_matches:CompetitionMatchProps[], competition_match_markets:CompetitionMatchMarketProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/matches/${competition_id}`)
        return { competition_matches: resp.data.competition_matches, competition_match_markets: resp.data.competition_match_markets }
    },
    getEnteredCompetitions: async():Promise<{competitions: CompetitionProps[], competition_players:CompetitionPlayerProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/entered`)
        return resp.data
    },
    getCompetitionsBySeasonId: async(competition_season_id:string):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/season/${competition_season_id}`)
        return resp.data.competitions
    },
    createCompetition: async(draft_competition:CreateCompetitionProps):Promise<{competition:CompetitionProps, competition_matches:CompetitionMatchProps[], competition_match_markets:CompetitionMatchMarketProps[], competition_players:CompetitionPlayerProps[]}> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/competitions/competition/create`, { draft_competition })
        return resp.data
    },
    editCompetition: async(draft_competition:CreateCompetitionProps):Promise<{ competition:CompetitionProps, competition_matches:CompetitionMatchProps[], competition_match_markets:CompetitionMatchMarketProps[] }> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/competitions/competition/edit`, { draft_competition })
        return resp.data
    },
    getPublicCompetitions: async(offset:number):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/all?offset=${offset}&status=scheduled`)
        return resp.data.competitions
    },
    getActiveSponsoredCompetitions: async(sponsor_id:string):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/sponsored/${sponsor_id}`)
        return resp.data.competitions
    },
    getCompetitionOptions: async():Promise<{competition_types:CompetitionTypeProps[], competition_result_types:CompetitionResultTypeProps[], competition_payout_types:CompetitionPayoutTypeProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/options`)
        return resp.data
    },
    getMyDetails: async():Promise<MyPlayerProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
        return resp.data.player
    },
    getMyReferralCode: async():Promise<{ player_referral:PlayerReferralProps, promo:PromoProps }> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/referral/me`)
        return resp.data
    },
    getMyBalance: async():Promise<PlayerBalanceProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/balance/me`)
        return resp.data.player_balance
    },
}

const CompetitionMatchApi = {
    getCompetitionMatchesByCompetitionId: async(competition_id:string, status:string):Promise<{competition_matches:CompetitionMatchProps[], competition_match_markets:CompetitionMatchMarketProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/matches/${competition_id}?status=${status}`)
        return resp.data
    },
    getEventsByEventIds: async(event_ids:string[]):Promise<EventProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/events/bulk/get`, { attribute:'event_id', values: event_ids })
        return resp.data.events
    },
    getMatchesByMatchIds: async(match_ids:string[]):Promise<MatchProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/matches/bulk/get`, { attribute: 'match_id', values: match_ids })
        return resp.data.matches
    },
    getAthletesByIds : async(athlete_ids:string[]) => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/athletes/bulk/get`, { attribute: 'athlete_id', values: athlete_ids })
        return resp.data.athletes
    },
    getTournamentsByTournamentIds: async(tournament_ids:string[]):Promise<TournamentProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/tournaments/bulk/get`, { attribute: 'event_id', values: tournament_ids })
        return resp.data.tournaments
    },
    getTeamsByIds: async(team_ids:string[]) => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/teams/bulk/get`, { attribute:'team_id', values: team_ids })
        return resp.data.teams
    },
    getExoticsByIds : async(exotic_ids:string[]):Promise<any[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/exotics/bulk/get`, { attribute: 'exotic_id', values: exotic_ids })
        return resp.data.athletes
    },
}

const CompetitionPlayerApi = {
    getCompetitionPlayersByCompetition: async(competition_id:string, offset:number, status?:string):Promise<CompetitionPlayerProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/players/${competition_id}?offset=${offset}&status=${status}`)
        return resp.data.competition_players
    },
    joinCompetition: async(competition_player:CompetitionPlayerProps):Promise<{ competition:CompetitionProps, competition_player:CompetitionPlayerProps, competition_result:CompetitionResultProps, competition_record:CompetitionRecordProps }> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/competitions/player/join`, { competition_player });
        return resp.data
    },
    leaveCompetition: async(competition_player:CompetitionPlayerProps):Promise<{competition:CompetitionProps, competition_player:CompetitionPlayerProps}> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/competitions/player/leave`, { competition_player });
        return resp.data
    },
    rejectCompetition: async(competition_player:CompetitionPlayerProps):Promise<CompetitionPlayerProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/competitions/player/leave`, { competition_player });
        return resp.data.competition_player
    }
}


const PlayerPicksApi = {
    getPlayerPicksByCompetition: async(competition_id:string, player_id:string, offset:number):Promise<PlayerPickProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/picks/competition/${competition_id}?player_id=${player_id}&offset=${offset}`);
        return resp.data.player_picks
    },
    createPlayerPick : async(player_pick:PlayerPickProps):Promise<{ player_pick: PlayerPickProps, competition_player:CompetitionPlayerProps }> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/picks/pick/create`, { player_pick });
        return resp.data;
    },
    removePlayerPick: async(player_pick_id:string):Promise<{ player_pick: PlayerPickProps, competition_player:CompetitionPlayerProps }> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/picks/pick/remove`, { player_pick_id })
        return resp.data
    }
}


const CompetitionResultApi = {
    getResultsByCompetition: async(competition_id:string, offset:number, player_id_override?:string):Promise<{competition_results:CompetitionResultProps[], competition_records:CompetitionRecordProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/results/competition/${competition_id}?offset=${offset}&player_id=${player_id_override}`)
        return resp.data
    }
}

export const FINALIZED_STATUSES = [
    'closed',
    'complete',
    'cancelled',
    'unnecessary',
    'postponed',
    'suspended'
]

export const IN_PROGRESS_STATUSES = [
    'inprogress',
    'halftime',
    'intermission'
]

const CompetitionHelpers = {

    getReferralDescription : (promo:PromoProps) => {
        switch(promo.type){
            case 'promo_code': return `Get ${promo.amount} promo bucks after you verify your account`
            case 'promo_sweepstakes': return `Have a chance at UP TO 500 promo bucks after you verify your account`
            case 'referral_code': return `Get ${promo.amount} promo bucks after you verify your account. Your referrer will get the same after you make your first deposit`
            case 'referral_sweepstakes': return `Have a chance at UP TO 500 promo bucks after you verify your account. Your referrer will get the same after you make your first deposit`
            default: return ''
        }
    },
    getEmptyCompetitionSeason : ():CompetitionSeasonProps => {
    return {
        competition_season_id:'',
        season_name:'',
        ticket_price:0,
        max_picks:0,
        ticket_revenue: 0,
        tickets_available:10,
        tickets_sold: 0,
        unallocated_funds: 0,
        competition_type_id: '1',
        expected_competition_count: 0,
        initial_balance: 1000,
        balance_rollover: false,
        invite_only: true,
        season_description: '',
        image: { url: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1635988385/be_notify_icon_nqplfc.png' },
        payout_allocation:0,
        payout_amt: 0,
        allow_late_buy_in: false,
        single_buy_in: true,
        competition_result_type_id: '1',
        admin_id:'',
        status: 'active',
        scheduled_datetime: moment().add(10, 'days'),
        create_datetime:'',
        last_update_datetime:'',
        market_type: 'FOR_MONEY'
    }
},

formatPlace :(place:number) => {
    switch(place){
        case 1:
            return '1st'
        case 2:
            return '2nd'
        case 3:
            return '3rd'
        case 4:
            return '4th'
        case 5:
            return '5th'
        case 6:
            return '6th'
        case 7:
            return '7th'
        case 8:
            return '8th'
        case 9:
            return '9th'
        default:
            return `${place}`

    }
},
isSeasonValid : (s:CompetitionSeasonProps) => {
    if(!s.season_name){ return false }
    if(!s.season_description){ return false }
    if(!s.competition_result_type_id){ return false }
    return true
},
getEmptyCompetition : ():CompetitionProps => {
    return {
        competition_id: '',
        auth_strategy_id:'',
        competition_name:'',
        competition_description:'',
        buy_in: 0,
        admin_id:'',
        image: { url: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1635988385/be_notify_icon_nqplfc.png' },
        ticket_revenue:0,
        tickets_sold:0,
        available_tickets:10,
        market_ids: [],
        market_type: 'FOR_MONEY',
        status: 'scheduled',
        create_datetime:'',
        last_update_datetime: '',
        competition_type_id: '1',
        competition_result_type_id:'1',
        scheduled_datetime: '',
        entered: false,
        max_pick_count: 0,
        leagues: [],
        invite_only: false,
        length_type: 'event',
        competition_code: '',
        initial_balance: 1000,
        end_datetime:  moment().add(1, 'week')
    }
},
getClosestDay : (schedule_range:string[]):string => {
    if(schedule_range.length == 0){ return moment().format('YYYY/MM/DD') }
    let closest:any|undefined;
    let exact_day = schedule_range.find(d => d == moment().format('YYYY/MM/DD'))
    if(exact_day){ return exact_day }
    schedule_range.map(d => {
        if(!closest){ 
            closest = moment(d) 
        }
        if(moment(d).isBefore(moment())){ return } //Day is in the past
        let new_range = moment(d).diff(moment(), 'days')
        let closest_range = closest.diff(moment(), 'days')
        if(Math.abs(new_range) < Math.abs(closest_range)){ closest = moment(d) }
    })
    return closest.format('YYYY/MM/DD')
},
getEventCompMatchFromEvent : (event_id:string, event_type:'team'|'tournament'|'match'):CompetitionMatchProps => {
    return {
        competition_id:'',
        competition_match_id: '',
        event_id,
        event_type,
        status: 'inprogress',
        create_datetime:'',
        last_update_datetime:'',
        match_type: 'event'
    }
},
getMatchMarketsFromEventAndTrades : (event_id:string, trades:TradeProps[], markets:MarketProps[]):CompetitionMatchMarketProps[] => {
    let cmm:CompetitionMatchMarketProps[] = []
    markets.map(m => {
        let tst = trades.find(t => t.market_id == m.market_id && t.side == m.trade_side)
        let ntst = trades.find(t => t.market_id == m.market_id && t.side != m.trade_side)
        if(!tst || !ntst){ return }
        let tscmm:CompetitionMatchMarketProps = {
            competition_match_id: '',
            competition_match_market_id: '',
            var_1: tst.var_1,
            odds: tst.odds,
            event_id,
            event_type: 'team',
            probability: tst.probability,
            side: tst.side,
            side_id: tst.side_id,
            market_id: tst.market_id,
            status: 'active',
            create_datetime: '',
            last_update_datetime:''
        }
        cmm.push(tscmm)
        let ntscmm:CompetitionMatchMarketProps = {
            competition_match_id: '',
            competition_match_market_id: '',
            var_1: ntst.var_1,
            odds: ntst.odds,
            event_id,
            event_type: 'team',
            probability: ntst.probability,
            side: ntst.side,
            side_id: ntst.side_id,
            market_id: ntst.market_id,
            status: 'active',
            create_datetime: '',
            last_update_datetime:''
        }
        cmm.push(ntscmm)
    })
    return cmm
},
getStartTimeOptions : (draft_competition:CreateCompetitionProps, events:EventProps[]) => {
    if(events.length == 0){ return [] }
    let available_picks = (draft_competition.competition_match_markets.length / 2) + draft_competition.additional_markets.length
    let included_events = events.filter(e => draft_competition.competition_matches.map(cm => cm.event_id.toString()).includes(e.event_id.toString()))
    let additional_market_events = events.filter(e => draft_competition.additional_markets.map(m => m.event_id.toString()).includes(e.event_id.toString()))
    included_events = included_events.concat(additional_market_events).sort((a,b) => moment(a.scheduled_datetime).unix() - moment(b.scheduled_datetime).unix())
    let start_time_options:{ start_time:string, label:string }[] = []
    let earliest_event = included_events[0]
    if(!earliest_event){ return [] }
    start_time_options.push({ start_time: earliest_event.scheduled_datetime, label: `Start time of the earliest game` })
    if(available_picks === draft_competition.competition.max_pick_count){
        return start_time_options
    }
    let diff = available_picks - parseFloat(draft_competition.competition.max_pick_count as string)
    let picks_per_event = available_picks / included_events.length
    let event_to_back = diff / picks_per_event
    earliest_event = included_events[Math.ceil(event_to_back)]
    if(!earliest_event){ return start_time_options }
    start_time_options.push({ label: 'Latest start time possible based on max picks', start_time: earliest_event.scheduled_datetime })
    return start_time_options
},
getEmptyCreateCompetition : ():CreateCompetitionProps => {
    return {
        competition: {
            competition_id: '',
            auth_strategy_id: '',
            competition_name:'',
            competition_description:'',
            buy_in: 0,
            admin_id:'',
            ticket_revenue:0,
            tickets_sold:0,
            available_tickets:10,
            market_ids: [],
            market_type: 'FOR_MONEY',
            status: 'scheduled',
            create_datetime:'',
            last_update_datetime: '',
            competition_type_id: '',
            competition_result_type_id:'',
            scheduled_datetime: '',
            entered: false,
            max_pick_count: 0,
            leagues: [],
            invite_only: false,
            length_type: 'event',
            competition_code: '',
            initial_balance: 1000,
            end_datetime: ''
        },
        competition_match_markets:[],
        competition_matches:[],
        competition_players:[],
        additional_markets:[]
    }
},
getCompColors : (competition_type?:string) => {
    switch(competition_type){
        case 'pick': return { backgroundColor:'rgba(26,205,106,0.1)', borderColor:Colors.highlights.highlight400 }
        case 'wager': return { backgroundColor:'rgba(59,26,205,0.1)', borderColor:Colors.highlights.highlight100 }
        default: return { backgroundColor:Colors.brand.slate, borderColor:Colors.shades.black }
    }
},
getOddsLabel :(odds:number):string => {
    if(odds > 0){ return `+${odds.toFixed()}` }
    return odds.toFixed()
},
getVar1Label :(var_1:number, market:MarketProps):string => {
    if(market.type == 'Spread'){
        if(var_1 > 0){ return `+${var_1}` }
    }
    return var_1.toString()
},
getUniqueDivisions : (events:EventProps[]):string[] => {
    let away_divisions = events.map(e => e.away?.division ? e.away.division: '')
    let home_divisions = events.map(e => e.home?.division ? e.home.division : '')
    if(!away_divisions || !home_divisions){ return [] }
    let divisions = away_divisions.concat(home_divisions)
    let options = [ ...new Set(divisions.map(d => d)) ].sort((a,b) => a > b ? 1 : -1)
    let ranks = events.find(e => e.away?.rank || e.home?.rank)
    if(ranks){ options = ['Top25'].concat(options) }
    return options
},
getUniqueSubDivisions : (events:EventProps[]):string[] => {    
    let away_divisions = events.map(e => e.away?.sub_division ? e.away.sub_division : '')
    let home_divisions = events.map(e => e.home?.sub_division ? e.home.sub_division : '')
    return [ ...new Set(away_divisions.concat(home_divisions).map(d => d)) ].sort((a,b) => a > b ? 1 : -1)
},

createPickFromMatchMarket : (competition_id:string, mm:CompetitionMatchMarketProps):PlayerPickProps => {

    return {
        player_id:'',
        player_pick_id:'',
        side: mm.side,
        side_id: mm.side_id,
        status: 'inprogress',
        competition_id,
        competition_match_id: mm.competition_match_id,
        market_id: mm.market_id,
        odds: mm.odds as number,
        var_1: mm.var_1 as number,
        probability: mm.probability,
        create_datetime:'',
        last_update_datetime:'',
        stake:0,
        potential_winnings:0,
        winnings: 0,
        pick_title: ''
    }
},

getCompetitionPlayer : (competition_id:string):CompetitionPlayerProps => {
    return {
        competition_id,
        competition_player_id: '',
        status: 'active',
        create_datetime: '',
        last_update_datetime: '',
        player_id: '',
        wager_based_balance: 1000,
        accepted_ind: false
    }
},


 getCompTypeColor :(competition_type?:CompetitionTypeProps) => {
    switch(competition_type?.type){
        case 'pick':
            return Colors.highlights.highlight400
        case 'wager':
            return Colors.highlights.highlight100
        default:
            return Colors.brand.midnight
    }
},


calcPotentialWinnings : (amt:number, odds:number):number => {
    if (odds > 0) {
        var earningsMultiplyer = ((odds/100) + 1);
        return earningsMultiplyer * amt;
    } else {
        var earningsMultiplyer = ((100/(Math.abs(odds))) + 1);
        return earningsMultiplyer * amt
    }
},


getPickTitleForTeamEvent : (pick:PlayerPickProps, market:MarketProps, event:EventProps, athlete?:AthleteProps):string => {
    let market_side = market.side_options.find(o => o.side == pick.side)
    if(!market_side){ return pick.side }
    var re = /({.*?})/;
    let parsed_text = market_side.parseable_title.split(re)
    let new_title = '';
    parsed_text.map((t:any) => {
        if(t === ''){ return }
        if(t[0] !== '{'){ return new_title += t }
        let variable = t.slice(1)
        variable = variable.slice(0, variable.length -1)
        switch(variable){
            case 'team': 
                let team = event[pick.side as keyof EventProps]
                return new_title += team.market_name
            case 'var_1': return new_title += CompetitionHelpers.getVar1Label(pick.var_1, market)
            case 'athlete': return new_title += `${athlete?.first_name} ${athlete?.last_name}`
            case 'stat': return new_title += market.stat_label ?? market.stat
            default: return
        }
    })
    return new_title
},

    getPickTitleForMatch : (pick:PlayerPickProps, market:MarketProps, athlete?:AthleteProps, team?:TeamProps):string => {
    let market_side = market.side_options.find(o => o.side == pick.side)
    if(!market_side){ return pick.side }
    var re = /({.*?})/;
    let parsed_text = market_side.parseable_title.split(re)
    let new_title = '';
    parsed_text.map((t:any) => {
        if(t === ''){ return }
        if(t[0] !== '{'){ return new_title += t }
        let variable = t.slice(1)
        variable = variable.slice(0, variable.length -1)
        switch(variable){
            case 'team': return new_title += `${team?.market_name} ${team?.name}`
            case 'var_1': return new_title += CompetitionHelpers.getVar1Label(pick.var_1, market)
            case 'athlete': return new_title += athlete?.abbr_name
            case 'stat': return new_title += market.stat 
            default: return

        }
    })
    return new_title
}

}