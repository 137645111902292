import React, { useState } from 'react';
import { FlatList, ScrollView, TouchableOpacity, View } from "react-native"
import type { MarketProps } from '../../types';
import { Button, Switch, Text } from '../../Components';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';
import SearchBox from '../../Components/SearchBox';

type MarketSelectorProps = {
    width:number,
    height:number,
    markets:MarketProps[],
    selected_markets:string[],
    onSelectMarket: (market:MarketProps) => void,
    onDeselectMarket: (market:MarketProps) => void,
    onClose:() => void
}
const MarketSelector = ({ width, height, markets, selected_markets, onSelectMarket, onDeselectMarket, onClose } : MarketSelectorProps) => {
    const [ search_value, setSearchValue ] = useState('');

    
    const filtered_markets = markets.filter(m => `${m.type} ${m.description}`.toLowerCase().includes(search_value.toLowerCase()))


    const renderMarkets = (data:{item:MarketProps, index:number}) => {
        const selected = selected_markets.includes(data.item.market_id) ? true : false
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => selected ? onDeselectMarket(data.item) : onSelectMarket(data.item)}>
                <View style={{ flex:1, marginRight:10 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{data.item.type == 'Stat' ? data.item.stat_label : data.item.type}</Text>
                    <Text style={{ marginTop:3 }} size={14} color={Colors.brand.midnight} weight='regular'>{data.item.description}</Text>
                </View>
                <Switch 
                    switch_type="on_off"
                    value={selected}
                    onChange={() => {
                        if(!selected){ return onSelectMarket(data.item) }
                        return onDeselectMarket(data.item)
                    }}
                />
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ width, height, backgroundColor:Colors.shades.white }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>MANAGE MARKETS</Text>
                </View>
            </View>
            <ScrollView style={{ flex:1 }}>
                <View style={{ ...view_styles.section_body }}>
                    <View style={{ marginTop:0 }}>
                        <SearchBox 
                            onChange={(text) => setSearchValue(text)}
                            hide_search_button
                        />
                    </View>
                    <FlatList
                        data={filtered_markets}
                        renderItem={renderMarkets}
                        keyExtractor={(item) => item.market_id.toString()}
                    />
                </View>
            </ScrollView>
            <View style={{ ...view_styles.section_footer }}>
            <Button
                    title='CLOSE'
                    style={{ flex:1 }}
                    padding={15}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
        </View>
    )
}

export default MarketSelector