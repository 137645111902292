import { TouchableOpacity, View } from "react-native"

import React, { useEffect, useState } from "react"
import type { CompetitionProps, CompetitionResultTypeProps, CompetitionTypeProps, MyPlayerProps, PlayerBalanceProps } from "../../types"
import Colors from "../../constants/colors"
import { Button, Icons, Text } from "../../Components"
import { CompetitionHelpers } from "../api"
import { view_styles } from "../../constants/styles"
import moment from "moment-mini"

type EnterCompetitionPromptProps = {
    player?:MyPlayerProps,
    player_balance?:PlayerBalanceProps,
    competition:CompetitionProps,
    competition_type:CompetitionTypeProps,
    competition_result_type:CompetitionResultTypeProps,
    width:number,
    onVerify: () => void
    onCancel:() => void,
    onEnter: (promo_balance?:boolean) => void
}

const EnterCompetitionPrompt = ({ player, player_balance, competition, competition_type, competition_result_type, width, onCancel, onEnter, onVerify }:EnterCompetitionPromptProps) => {
    const [ use_promo_balance, setUsePromoBalance ] = useState(false)

    
    useEffect(() => {
        if(!player){ return }
    },[])

    const requires_verification = player && parseInt(competition?.buy_in as string ?? 0) > 0 && competition?.market_type == 'FOR_MONEY' && player?.vouched_status != 'verified' ? true : false
    const cl = competition.market_type == 'FOR_MONEY'?'$':'E'
    return (
        <View style={{ width, backgroundColor:Colors.shades.white, borderTopRightRadius:22, borderTopLeftRadius:22, paddingTop:22 }}>
           <View nativeID="competition_info">
            <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign="center">Competition Details</Text>
                {competition_type ?
                <View style={{ flexDirection:'row', justifyContent:'center', margin:10 }}>
                    <View style={{ padding:10, marginRight:4, backgroundColor: CompetitionHelpers.getCompColors(competition_type.type).borderColor, borderRadius:4 }}>
                        <Text size={12} color={Colors.shades.white} weight='semibold'>{competition_type.type_label}</Text>
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10, marginLeft:4, backgroundColor: Colors.shades.white, borderRadius:4, ...view_styles.float }}>
                        <Text style={{ marginRight:5 }} size={12} color={Colors.brand.midnight} weight='semibold'>{competition.invite_only?'Private':'Public'}</Text>
                        {competition.invite_only ?
                        <Icons.EyeOffIcon color={Colors.brand.midnight} size={14}/>
                        :
                        <Icons.EyeOnIcon color={Colors.brand.midnight} size={14}/>
                        }
                    </View>
                </View>
                :<></>}
                <Text size={14} color={Colors.brand.midnight} weight='bold' textAlign="center">{competition.competition_name}</Text>
            </View>
            <View>
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">{moment(competition.scheduled_datetime).format('MMM DD - hh:mm a')}</Text>
                {competition.competition_description ?
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">{competition.competition_description}</Text>
                :<></>}
                {competition_result_type ?
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">{competition_result_type.label}</Text>
                :<></>}
                {competition_type?.type == 'pick' ?
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">Picks: {competition.max_pick_count}</Text>
                :<></>}
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">Potential Winnings: {cl}{competition.ticket_revenue.toFixed(2)}</Text>
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">Ticket Price: {cl}{(competition.buy_in as number).toFixed(2)}</Text>
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">Entries: {competition.tickets_sold}/{competition.available_tickets}</Text>
            </View>
            <View nativeID="pick_actions" style={{ flexDirection:'row', padding:10 }}>
                <Button
                    style={{ flex:1, marginRight:3 }}
                    borderRadius={4}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    padding={10}
                    title="Cancel"
                    title_color={Colors.brand.electric}
                    onPress={() => onCancel()}
                />
                {requires_verification ?
                <Button
                    borderRadius={4}
                    style={{ flex:2, marginLeft:2 }}
                    backgroundColor={Colors.utility.warning}
                    padding={10}
                    title={`Verify My Account`}
                    title_color={Colors.shades.white}
                    onPress={() => onVerify()}
                />
                :
                 <Button
                    borderRadius={4}
                    style={{ flex:2, marginLeft:2 }}
                    backgroundColor={Colors.utility.success}
                    padding={10}
                    title={`Enter Competition${competition.buy_in == 0 ? ``:` ${cl}${competition.buy_in}`}`}
                    title_color={Colors.shades.white}
                    onPress={() => onEnter(use_promo_balance)}
                />
                }
            </View>
            {player_balance && player_balance.promo_balance > 0 ?
            <TouchableOpacity style={{ backgroundColor:Colors.incentive.gold, padding:10, flexDirection:'row', alignItems:'center' }} onPress={() => {
                if(use_promo_balance){
                    //Remove promo balance and open_amt
                    setUsePromoBalance(false);
                } else {
                    setUsePromoBalance(true);                    
                }
            }}>
                    <View style={{ marginRight:10, height:30, width:30, borderWidth:1, borderColor:Colors.brand.midnight, justifyContent:'center', alignItems:'center' }}>
                        {use_promo_balance ?
                        <View style={{ height:20, width:20, backgroundColor:Colors.brand.midnight }} />
                        :<></>}
                    </View>
                    <View>
                        <Text size={14} weight='bold' color={Colors.shades.white}>${player_balance?.promo_balance} Promotion Available</Text>
                        <Text style={{ marginTop:2}} size={12} color={Colors.shades.white} weight='regular'>Press / Click here to use promo balance</Text>
                    </View>
                
            </TouchableOpacity>
            :<></>}
        </View>
    )
}

export default EnterCompetitionPrompt