import React, { useEffect, useState } from 'react';
import { View, FlatList, TouchableOpacity, ScrollView, Image, ActivityIndicator } from "react-native"
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';
import { Button, Switch, Text } from '../../Components';
import DropDown from '../../Components/Dropdown';
import type { EventProps, LeagueProps } from '../../types';
import { ManageCompetitionMatchApi } from '../api';
import moment from 'moment-mini';
import SearchBox from '../../Components/SearchBox';

type ContestSelectorProps = {
    width: number,
    action_loading:boolean,
    height:number,
    leagues:LeagueProps[],
    selected_team_events:EventProps[],
    onSelectTeamEvent:(event:EventProps) => void,
    onSelectTeamEvents: (events:EventProps[]) => void,
    onDeselectTeamEvent: (event:EventProps) => void,
    onClose:() => void
}

const ContestSelector = ({ action_loading, width, height, leagues, selected_team_events, onClose, onSelectTeamEvent, onDeselectTeamEvent, onSelectTeamEvents }:ContestSelectorProps) => {
    const [ search_value, setSearchValue ] = useState('');
    const [ filters, setFilters ] = useState<{
        ranked?:boolean,
        division?:string,
        sub_division?:string
    }>({})
    const [ selector_data, setData ] = useState<{
        loading:boolean,
        events:EventProps[],
        range_dates:string[],
        active_league?:LeagueProps,
        active_date: string
    }>({
        loading:false,
        events: [],
        range_dates: [],
        active_date: moment().format('YYYY/MM/DD')
    })

    const { loading, events, range_dates, active_league, active_date } = selector_data;

    let filtered_events = events.filter(e => `${e.event_id} ${e.away?.market_name} ${e.away?.name} ${e.home?.market_name} ${e.home?.name}`.toLowerCase().includes(search_value.toLowerCase()))
    .filter(e => moment(e.scheduled_datetime).format('YYYY/MM/DD') == active_date)
    .sort((a,b) => moment(a.scheduled_datetime).unix() - moment(b.scheduled_datetime).unix());

    const divisions = [ ...new Set(filtered_events.map(e => e.away?.division ?? '').concat(filtered_events.map(e => e.home?.division ?? ''))) ].sort((a,b) => {
        return (a < b) ? -1 : (a > b) ? 1 : 0;
    })
    const show_ranked = filtered_events.find(e => e.away?.rank || e.home?.rank) ? true : false


    if(filters.division){
        filtered_events = filtered_events.filter(e => `${e.away?.division}` == filters.division || `${e.home?.division}` == filters.division)
    }
    const sub_divisions = [ ...new Set(filtered_events.filter(e => e.away?.division == filters.division || e.home?.division == filters.division).map(e => e.away?.sub_division ?? '').concat(filtered_events.map(e => e.home?.sub_division ?? ''))) ].sort((a,b) => {
        return (a < b) ? -1 : (a > b) ? 1 : 0;
    })

    if(filters.sub_division){
        filtered_events = filtered_events.filter(e => `${e.away?.sub_division}` == filters.sub_division || `${e.home?.sub_division}` == filters.sub_division)
    }
    if(filters.ranked){
        console.log('filtering to ranked')
        filtered_events = filtered_events.filter(e => e.away?.rank || e.home?.rank)
    }

    const show_add_all = filtered_events.filter(e => !selected_team_events.map(e => e.event_id.toString()).includes(e.event_id)).length > 0 ? true : false


    useEffect(() => {
        if(leagues.length == 0){ return }
        if(!active_league){ setData({ ...selector_data, active_league: leagues.sort((a,b) => a.priority - b.priority)[0] }) }
    },[leagues])

    useEffect(() => {
        if(!active_league){ return }
        getDataFromServer(active_league.league_id)
    },[active_league])

    const getDataFromServer = async(league_id:string) => {
        setData({ ...selector_data, loading:true })
        const resp = await ManageCompetitionMatchApi.getEventsByDate(moment().format('YYYY/MM/DD'), league_id);
        const unique_dates = [ ...new Set(resp.range_dates.filter(d => moment(d).isAfter(moment().subtract(1, 'days'))).sort((a,b) => moment(a).unix() - moment(b).unix()).map(d => moment(d).format('YYYY/MM/DD'))) ]
        //Check to see if we have an event on today's date
        let best_day = unique_dates.find(d => d == moment().format('YYYY/MM/DD'))
        if(!best_day){
            best_day = unique_dates[0] ?? moment().format('YYYY/MM/DD')
        }
        setData({ ...selector_data, loading:false, events: resp.events, range_dates: unique_dates, active_date: best_day })
    }

    const renderDivisions = (data: { item:string, index:number }) => {
        const selected = filters.division == data.item ? true : false
        return (
            <TouchableOpacity style={{ padding:10 }} onPress={() => selected ? setFilters({ ...filters, division: undefined }) : setFilters({ ...filters, division:data.item })}>
                <Text color={selected ? Colors.brand.midnight : Colors.brand.slate} weight={selected ? 'bold': 'regular'}>{data.item}</Text>
            </TouchableOpacity>
        )
    }
    const renderSubDivisions = (data: { item:string, index:number }) => {
        const selected = filters.sub_division == data.item ? true : false
        return (
            <TouchableOpacity style={{ padding:10 }} onPress={() => selected ? setFilters({ ...filters, sub_division: undefined }) : setFilters({ ...filters, sub_division:data.item })}>
                <Text color={selected ? Colors.brand.midnight : Colors.brand.slate} weight={selected ? 'bold': 'regular'}>{data.item}</Text>
            </TouchableOpacity>
        )
    }

    const renderDates = (data: { item: string, index:number }) => {
        const formatted_date = moment(data.item, 'YYYY/MM/DD')
        const active = active_date == data.item ? true : false
        return (
            <TouchableOpacity style={{ padding:10, justifyContent:'center', alignItems:'center' }} onPress={() => setData({ ...selector_data, active_date: data.item })}>
                <Text size={12} color={Colors.brand.midnight} textAlign='center' weight={active ? 'bold' : 'regular'}>{formatted_date.format('ddd')}</Text>
                <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} textAlign='center' weight={active ? 'bold' : 'regular'}>{formatted_date.format('MMM DD')}</Text>
            </TouchableOpacity>
        )
    }

    const renderEvents = (data: { item:EventProps, index:number }) => {
        const selected = selected_team_events.map(e => e.event_id.toString()).includes(data.item.event_id.toString()) ? true : false
        const is_live = moment(data.item.scheduled_datetime).isBefore(moment()) ? true : false
        return (
            <View style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <View style={{ flexDirection:'row', alignItems:'center', paddingRight:10, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                    <Image
                        source={{ uri: data.item.away?.image?.url }}
                        style={{ height:34, width:34, borderRadius:4 }}
                        resizeMode='cover'
                    />
                    <Text style={{ padding:5 }} theme='body'>VS</Text>
                    <Image
                        source={{ uri: data.item.home?.image?.url }}
                        style={{ height:34, width:34, borderRadius:4 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ flex:1, marginLeft: 10 }}>
                    <Text theme='header_2'>{data.item.event_title}</Text>
                    <Text style={{ marginTop:3 }} theme='body'>{data.item.time_detail == 'scheduled' ? moment(data.item.scheduled_datetime).format('MMM DD hh:mm a'): data.item.time_detail}</Text>
                </View>
                {!is_live ?
                <Switch
                    switch_type='on_off'
                    value={selected}
                    onChange={() => {
                        if(action_loading){ return }
                        if(selected){ return onDeselectTeamEvent(data.item) } 
                        return onSelectTeamEvent(data.item)
                    }}
                />
                :<></>}
            </View>
        )
    }

    return (
        <View style={{ ...view_styles.section, margin:0, width, height }}>
            <View style={{ ...view_styles.section_header, zIndex: 1}}>
                {active_league ?
                <Image
                    source={{ uri: active_league.league_image }}
                    style={{ height:50, width:50, marginRight:10, borderRadius:4 }}
                    resizeMode='cover'
                />
                :<></>}
                <View style={{ flex:1, marginRight:50 }}>
                    <Text theme='header'>MANAGE CONTESTS</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Add / Remove contests from the competition</Text>
                </View>
                <View style={{ position: 'absolute', top:10, right:10, justifyContent:'center', alignItems:'center' }}>
                    <DropDown
                        dropdown_options={[
                            {value:'league_id', eligible_options: leagues.sort((a,b) => a.priority - b.priority).map(l => l.league_name)}
                        ]}
                        selected_value={active_league?.league_name ?? 'Select League'}
                        onOptionSelect={(league_name) => { 
                            const league = leagues.find(l => l.league_name == league_name);
                            setData({ ...selector_data, active_league: league })
                        }}
                    />
                </View>
            </View> 
            <ScrollView style={{ flex:1 }}>
                <View style={{ padding:10 }}>
                    <FlatList
                        data={range_dates}
                        renderItem={renderDates}
                        horizontal
                        keyExtractor={(item) => item}
                    />
                </View>
                <View style={{ marginTop:0 }}>
                    <SearchBox 
                        onChange={(text) => setSearchValue(text)}
                        hide_search_button
                    />
                </View>
                {divisions.length > 1 ?
                <View nativeID='filters' style={{ flexDirection:'row' }}>
                    {show_ranked ?
                    <TouchableOpacity style={{ padding:10 }} onPress={() => filters.ranked ? setFilters({ ...filters, ranked: false }) : setFilters({ ...filters, ranked: true })}>
                        <Text color={filters.ranked ? Colors.brand.midnight : Colors.brand.slate} weight={filters.ranked ? 'bold': 'regular'}>Top 25</Text>
                    </TouchableOpacity>
                    :<></>}
                    <FlatList
                        data={divisions}
                        renderItem={renderDivisions}
                        horizontal
                        keyExtractor={(item) => item}
                    />
                </View>
                :<></>}
                {filters.division && sub_divisions.length > 1 ?
                <View>
                     <FlatList
                        data={sub_divisions}
                        renderItem={renderSubDivisions}
                        horizontal
                        keyExtractor={(item) => item}
                    />
                </View>
                :<></>}
                <View style={{ padding:10 }}>
                    {loading ?
                    <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
                    :<></>}
                    <FlatList
                        data={filtered_events}
                        renderItem={renderEvents}
                        keyExtractor={(item) => item.event_id.toString()}
                    />
                </View>
            </ScrollView>
            <View style={{ ...view_styles.section_footer, flexDirection:'row' }}>
                {filtered_events.length > 0 ?
                <Button
                    title='ADD ALL'
                    style={{ flex:2, marginRight:10, opacity:!show_add_all || action_loading?0.5:1 }}
                    loading={action_loading}
                    disabled={!show_add_all || action_loading}
                    padding={15}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    onPress={() => {
                        ///First remove any event that is already selected
                        let add_events = filtered_events.filter(e => !selected_team_events.map(e => e.event_id.toString()).includes(e.event_id))
                        onSelectTeamEvents(add_events);
                    }}
                />
                :<></>}
                <Button
                    title='CLOSE'
                    style={{ flex:1 }}
                    padding={15}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
        </View>
    )
}

export default ContestSelector