import React, { useEffect, useState } from 'react';
import { View, FlatList, TouchableOpacity, Image, Linking } from 'react-native';
import type { ProfileLevelProps, ProfileStepProps, SettingProps } from "../api/types"
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import BasicInfoManager from './BasicInfoManager';
import PasswordManager from './PasswordManager';
import EmailManager from './EmailManager';
import PhoneManager from './PhoneManager';
import BasicStatus from './BasicStatus';
import DOBManager from './DOBManager';
import VouchCard from './VouchCard';
import ProfilePicManager from './ProfilePicManager';
import ProfileWelcome from './ProfileWelcome';
import CodeRedeem from './CodeRedeem';
import type { CodeRequestProps, MyPlayerProps, PlayerReferralProps, PlayerSettingProps, PromoProps, PublicPlayerProps, RewardOptionProps } from '../../types';
import SettingsManager from './SettingsManager';
import { ProfileApi } from '../api';
import { view_styles } from '../../constants/styles';

type ProfileWizardProps = {
    player:MyPlayerProps,
    player_settings:PlayerSettingProps[],
    settings:SettingProps[]
    show_welcome?:boolean,
    onVouching:(active:boolean) => void,
    width:number,
    hide_powered_by?:boolean,
    hidden_groups?:string[],
    hidden_levels?:string[],
    hidden_steps?:string[],
    init_walkthrough?:{
        level: 'basic'|'verify'
    },
    onRedeemCode:(code_details:{
        code_request?:CodeRequestProps,
        promo?:PromoProps,
        player_referral?:PlayerReferralProps,
        reward_option?:RewardOptionProps,
        referrer?:PublicPlayerProps
    }) => void,
    profile_attribute?:'basic'|'phone'|'email'|'dob'|'password'|'vouch'|'notification'|'preferences',
    onClose:() => void,
    onNextStep:(step:string) => void,
    onLogout:() => void,
    onSelectLevel:(level:ProfileLevelProps) => void,
    onPlayerUpdate:(player:MyPlayerProps, attribute?:string) => void
    onUpdatePlayerSetting:(player_setting:PlayerSettingProps) => void
}
//                        { num:5, check: 'code', label: 'Promo', description: `Lets get you started with some promo bucks!`, complete:false },

const ProfileWizard = ({  player, hide_powered_by, settings, player_settings, onRedeemCode, init_walkthrough, show_welcome, onVouching, onNextStep, profile_attribute, width, hidden_groups, hidden_levels, hidden_steps, onUpdatePlayerSetting, onPlayerUpdate, onSelectLevel, onClose, onLogout }:ProfileWizardProps) => {
    const [ draft_player, setDraftPlayer ] = useState(player);
    const [ show_code_request, setShowCodeRequest ] = useState(false);
    const [ welcome_hidden, setWelcomeHidden ] = useState(false);
    const [ code_details, setCodeDetails ] = useState<
    {
        code_request?:CodeRequestProps,
        referrer?:PublicPlayerProps,
        promo?:PromoProps,
        player_referral?:PlayerReferralProps
    }
    >({})
    const [ show_logout, setShowLogout ] = useState(false);
    const [ walkthrough, setWalkthrough ] = useState<string | undefined>(undefined);
    const [ active_level, setActiveLevel ] = useState<ProfileLevelProps>()
    const [ active_step, setActiveStep ] = useState<ProfileStepProps>()
    const [ groups, setGroups ] = useState([
        { 
            priority: 1, key: 'profile', name: 'My Profile', profile_levels: [
                {
                    num: 1,
                    label: 'Basic Information',
                    description: 'Just enough information to get started playing our free to play games',
                    identifier: 'basic',
                    tracked:true,
                    pct_complete: 0,
                    profile_steps: [
                        { num:1, check: 'basic', label: 'Basic Info', description: `Let's get some basic information about you!`, complete:false },
                        { num:2, check: 'profile_pic', label: 'My Picture', description: `We initially created this based on your username using AI! Change it here!`, complete:false },
                        { num:3, check: 'password', label: 'Password', description: `Lets set a password for additional security`, complete:false },
                        { num:4, check: 'basic_review', label: 'Review', description: `Review your status!`, complete:false }
                    ]
                },
                {
                    num: 2,
                    label: 'Security & Verification',
                    description: 'Additional information needed to use real-money',
                    identifier: 'verify',
                    tracked:true,
                    pct_complete: 0,
                    profile_steps: [
                        { num:1, check: 'email', label: 'Email', description: `Lets make sure you can access your account in case you forget your password`, complete:false },
                        { num:2, check: 'phone', label: 'Phone', description: `Lets make sure you can access your account in case you forget your password`, complete:false },
                        { num:3, check: 'dob', label: 'Date of Birth', description: `We need to ensure you are at least 18 to play`, complete:false },
                        { num:4, check: 'vouch', label: 'Verify', description: `We need to ensure you are at least 18 to play`, complete:false },
        
                    ]
                }
            ] 
        },
        {
            priority: 2, key: 'preferences', name: 'Preferences', profile_levels: [
                {
                    num: 1,
                    label: 'Profile Settings',
                    description: 'Set your profile settings',
                    identifier: 'settings',
                    pct_complete: 1,
                    tracked:false,
                    profile_steps: [
                        { num: 1, check: 'preferences', label: 'Preferences', description: 'Set your profile preferences like unit size and more', complete: true },
                        { num: 2, check: 'notification', label: 'Notifications', description: 'Set how and when you would like to be notified', complete:true },      
                        { num: 3, check: 'sharing', label: 'Sharing', description: 'Set who and when people can see your activity.', complete:true },                                          
                    ]

                }
            ]
        }
    ])


    useEffect(() => {
        if(!init_walkthrough){ return }
        setWalkthrough(init_walkthrough.level)
        let walkthroughLevel = groups.find(g => g.profile_levels.find(l => l.identifier == init_walkthrough.level))?.profile_levels.find(l => l.identifier == init_walkthrough.level)
        setActiveLevel(walkthroughLevel)
        if(!show_welcome){ setActiveStep(walkthroughLevel?.profile_steps[0]) }
    },[init_walkthrough])


    useEffect(() => {
        setDraftPlayer(player)
        const groups = setupSteps(player)
        setGroups(groups);
    },[player])

    useEffect(() => {
        if(!player){ return }
        getCodeFromServer()
        const groups = setupSteps(player)
        setGroups(groups);

        if(show_welcome){
            setActiveLevel(groups[0].profile_levels[0])
        }
        if(profile_attribute){
            let profile_level:ProfileLevelProps|undefined = undefined
            let profile_step:ProfileStepProps|undefined = undefined
            groups.map(g => g.profile_levels.map((l:ProfileLevelProps) => {
                let step = l.profile_steps.find(ps => ps.check == profile_attribute)
                if(step){ 
                    profile_step = step 
                    profile_level = l
                }
            }))
            if(profile_step && profile_level){ 
                setActiveLevel(profile_level)
                setActiveStep(profile_step)
            }
        }
    },[init_walkthrough, profile_attribute, show_welcome])

    const getCodeFromServer = async() => {
        const request_resp = await ProfileApi.getMyCodeDetails()
        setCodeDetails(request_resp)
    }

    const getStepColor = (step:ProfileStepProps, active?:boolean) => {
        if(step.complete){ 
            if(active){ return Colors.highlights.highlight400 }
            return Colors.highlights.highlight400Faded 
        } else {
            if(active){ return Colors.brand.electric }
            return Colors.shades.shade600
        }
    }

    const setupSteps = (pl:MyPlayerProps):any[] => {

        let new_groups = groups.map(g => {
            g.profile_levels = g.profile_levels.map(level => {
                let new_steps:ProfileStepProps[] = []
                level.profile_steps.map(s => {
                    if(hidden_steps && hidden_steps.includes(s.check)){ return }
                    switch(s.check){
                        case 'basic_review':
                            //if(pl.vouched_status == 'verified'){ return }
                            if(!pl.no_password && !pl.auto_generated && pl.first_name && pl.last_name){ return new_steps.push({ ...s, complete:true })}
                            return new_steps.push({ ...s, complete:false })
                        case 'basic':
                            if(pl.first_name && pl.last_name){ return new_steps.push({ ...s, complete:true }) }
                            return new_steps.push({ ...s, complete:false })
                        case 'profile_pic':
                            if(pl.profile_pic && pl.profile_pic != ''){ return new_steps.push({ ...s, complete:true }) }
                            return new_steps.push({ ...s, complete:false })
                        case 'password':
                            if(!pl.no_password){ return new_steps.push({ ...s, complete:true }) }
                            return new_steps.push({ ...s, complete:false })
                        case 'email':
                            if(pl.verified){ return new_steps.push({ ...s, complete:true }) }
                            return new_steps.push({ ...s, complete:false })
                        case 'dob':
                            if(pl.dob && pl.dob != ''){ return new_steps.push({ ...s, complete:true }) }
                            return new_steps.push({ ...s, complete:false })
                        case 'vouch':
                            if(pl.vouched_status == 'verified'){ return new_steps.push({ ...s, complete:true }) }
                            return new_steps.push({ ...s, complete:false })
                        case 'phone':
                            if(pl.phone_verified){ return new_steps.push({ ...s, complete:true }) }
                            return new_steps.push({ ...s, complete:false })
                        default: return new_steps.push({ ...s, complete: false })
                    }
                })
                level.profile_steps = new_steps
                if(level.tracked){
                    let pct_complete = level.profile_steps.filter(s => s.complete).length / level.profile_steps.length
                    level.pct_complete = pct_complete
                }
                return level
            })
            return g
        })
        
        return new_groups
    }

    const renderLevels = (data: { item: ProfileLevelProps, index:number}) => {
        if(hidden_levels && hidden_levels.includes(data.item.identifier)){ return <></> }
        return (
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} onPress={() => {
                setActiveLevel(data.item)
                setActiveStep(data.item.profile_steps[0])
            }}>
                <View style={{ height:20, width:20, borderRadius:100, borderWidth:1, borderColor:data.item.pct_complete==1?Colors.utility.success:Colors.brand.slate, backgroundColor:Colors.shades.white, justifyContent:'center', alignItems:'center' }}>
                    {data.item.pct_complete == 1 ?
                    <Icons.CheckIcon color={Colors.utility.success} size={14} />
                    :<></>}
                </View>
                <View style={{ flex:1, marginLeft:10, marginRight:10 }}>
                    <Text size={16} color={Colors.brand.midnight} weight='bold'>{data.item.label}</Text>
                    <Text style={{ marginTop:3 }} size={14} color={Colors.brand.midnight} weight='regular'>{data.item.description}</Text>
                </View>
                {data.item.pct_complete ?
                <View style={{ marginRight:10 }}>
                    <Text size={14} color={Colors.brand.midnight}>{(data.item.pct_complete*100).toFixed()}%</Text>
                </View>
                :<></>}
                <Icons.ChevronIcon direction='right' size={10} color={Colors.brand.midnight} />
            </TouchableOpacity>
        )
    }

    const renderGroups = (data: { item:any, index:number }) => {
        if(hidden_groups && hidden_groups.includes(data.item.key)){ return <></> }
        return (
            <View>
                <View style={{ padding: 10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='bold'>{data.item.name}</Text>
                </View>
                <FlatList
                    data={data.item.profile_levels}
                    renderItem={renderLevels}
                    keyExtractor={(item) => item.identifier}
                />
            </View>
        )
    }

    const renderSteps = (data:{ item: ProfileStepProps, index:number }) => {
        if(!active_level){ return <></> }
        const active = data.item.check == active_step?.check ? true : false
        if(!active_level){ return <></> }
        return (
            <TouchableOpacity 
                style={{ 
                    padding:10, 
                    width: (width - 20) / active_level.profile_steps.length, 
                    backgroundColor:getStepColor(data.item, active),
                    borderRightWidth:data.index != active_level.profile_steps.length -1 ? 1: 0,
                    borderRightColor:Colors.shades.shade100,
                    borderTopRightRadius: data.index == active_level.profile_steps.length - 1 ? 22 : 0,
                    borderBottomRightRadius: data.index == active_level.profile_steps.length - 1 ? 22 : 0,
                    borderBottomLeftRadius: data.index == 0 ? 22: 0,
                    borderTopLeftRadius: data.index == 0 ? 22: 0

                }} 
                onPress={() => setActiveStep(data.item)}>
                {!walkthrough ?
                <Text size={14} textAlign='center' color={active?Colors.shades.white:Colors.brand.midnight} weight={active?'bold':'regular'}>{data.item.label}</Text>
                :<></>}
            </TouchableOpacity>
        )
    }



    return (
        <View style={{ flex:1 }}>
            {!active_level && !show_code_request ?
            <View style={{ flex:1 }}>
                <View style={{ flex:1 }}>
                    <FlatList
                        data={groups}
                        renderItem={renderGroups}
                        keyExtractor={(item) => item.key}
                    />
                </View>
                {!hide_powered_by ?
                <TouchableOpacity style={{ justifyContent:'center', alignItems:'center' }}
                onPress={() => {
                    Linking.openURL(`https://app.bettoredge.com`)
                }}>
                    <Image
                        source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1715272520/powered_be_be_irnaka.webp' }}
                        style={{ width: width * 0.6, height: (width*0.6) / 3 }}
                        resizeMode='center'
                    />
                </TouchableOpacity>
                :<></>}
                <View style={{flexDirection:'row', padding:10}}>
                    <Button
                        title='CLOSE'
                        style={{ flex:3, marginLeft:5 }}
                        title_color={Colors.brand.electric}
                        backgroundColor={Colors.shades.white}
                        borderWidth={1}
                        borderColor={Colors.brand.electric}
                        padding={15}
                        onPress={() => onClose()}
                    />
                    <TouchableOpacity style={{ backgroundColor:Colors.utility.error, borderRadius:22, padding:10, marginLeft:10, paddingLeft:20, paddingRight:20, justifyContent:'center', alignItems:'center' }}
                    onPress={() => setShowLogout(true)}>
                        <Icons.LogoutIcon color={Colors.shades.white} size={20}/>
                    </TouchableOpacity>
                </View>
                {show_logout ?
                <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                    <View style={{ backgroundColor:Colors.shades.white, padding:10, borderRadius:22, minWidth:250 }}>
                        <Text style={{ padding:15 }} size={18} color={Colors.brand.midnight} weight='bold' textAlign='center'>ARE YOU SURE?</Text>
                        <View style={{ flexDirection:'row', padding:10 }}>
                            <Button
                                title='NO'
                                style={{ flex:1, marginRight:3 }}
                                title_color={Colors.brand.electric}
                                padding={15}
                                borderWidth={1}
                                borderColor={Colors.brand.electric}
                                onPress={() => setShowLogout(false)}
                            />
                            <Button
                                title='YES - LOGOUT'
                                style={{ flex:3, marginLeft:3 }}
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.utility.error}
                                padding={15}
                                onPress={onLogout}
                            />
                        </View>
                    </View>
                </View>
                :<></>}
            </View>
            :
            <View style={{ flex:1 }}>
            {!walkthrough && active_level ?
            <TouchableOpacity style={{  flexDirection:'row', alignItems:'center', padding:10, borderTopWidth:2, borderColor:Colors.shades.shade600 }} onPress={() => {
                setActiveLevel(undefined)
                setActiveStep(undefined)
            }}>
                <Icons.ChevronIcon direction='left' size={10} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:20, marginRight:20 }}>
                    <Text size={16} color={Colors.brand.midnight} weight='bold'>{active_level.label}</Text>
                    <Text style={{ marginTop:3 }} size={14} color={Colors.brand.midnight} weight='regular'>{active_level.description}</Text>
                </View>
                {active_level.pct_complete ?
                <View>
                    <Text size={14} color={Colors.brand.midnight}>{(active_level.pct_complete*100).toFixed()}%</Text>
                </View>
                :<></>}
            </TouchableOpacity>
            :<></>} 
            <View style={{ borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:22, margin:10 }}>
                <FlatList
                    data={active_level?.profile_steps}
                    renderItem={renderSteps}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item) => item.check}
                />
            {walkthrough == 'verify' && code_details.code_request && code_details.promo ?
            <View style={{ position:'absolute', right:-4, top:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                <View style={{ height:30, width:30, backgroundColor:Colors.shades.white, borderRadius:100, justifyContent:'center', alignItems:'center', ...view_styles.float }}>
                    <Icons.GiftIcon size={18} color={Colors.incentive.gold}/>
                </View>
            </View>
            :<></>}
            </View>
            <View style={{ flex:1 }}>
                {!active_step && show_code_request ?
                <CodeRedeem
                    walkthrough={false}
                    player={draft_player}
                    onClose={() => setShowCodeRequest(false)}
                    onRedeemCode={(cd) => {
                        setCodeDetails(cd)
                        onRedeemCode(cd)
                    }}
                    onVerify={() => {
                        let verify_level = groups[0]?.profile_levels[1]
                        if(!verify_level){ return }
                        setActiveLevel(verify_level)
                        setActiveStep(verify_level.profile_steps[3])
                    }}
                />
                :<></>}
                {!active_step && show_welcome && !welcome_hidden && !show_code_request?
                <ProfileWelcome
                    player={draft_player}
                    width={width}
                    code_details={code_details}
                    onStartSetup={() => {
                        onNextStep('welcome')
                        setWelcomeHidden(true)
                        if(!active_level){ return }
                        if(walkthrough){
                            let next_step = active_level.profile_steps[0]
                            setActiveStep(next_step)   
                        }
                    }}
                    onClose={() => {
                        setWalkthrough(undefined);
                        setActiveLevel(undefined)
                        setWelcomeHidden(true)
                    }}
                />
                :active_step?.check == 'basic' ?
                <BasicInfoManager
                    player={draft_player}
                    walkthrough={walkthrough == 'basic' ? true : false}
                    onClose={() => {
                        if(profile_attribute){ return onClose() }
                        if(walkthrough){ setWalkthrough(undefined) }
                        return setActiveLevel(undefined)
                    }}
                    onNext={() => {
                        onNextStep('basic')
                        if(!active_level){ return }
                        let next_step = active_level.profile_steps[1]
                        setActiveStep(next_step)
                    }}
                    onPlayerUpdate={(player) => {
                        console.log('CALLING THIS')
                        onPlayerUpdate(player, 'basic')
                    }}
                />
                :active_step?.check == 'profile_pic'?
                <ProfilePicManager
                    player={draft_player}
                    walkthrough={walkthrough == 'basic' ? true : false}
                    onClose={() => {
                        if(profile_attribute){ return onClose() }
                        if(walkthrough){ setWalkthrough(undefined) }
                        return setActiveLevel(undefined)
                    }}
                    onNext={() => {
                        onNextStep('profile_pic')
                        if(!active_level){ return }
                        let next_step = active_level.profile_steps[2]
                        setActiveStep(next_step)
                    }}
                    onPlayerUpdate={(player) => onPlayerUpdate(player, 'profile_pic')}
                />
                :active_step?.check == 'password' ?
                <PasswordManager
                    player={draft_player}
                    onClose={() => {
                        if(profile_attribute){ return onClose() }
                        if(walkthrough){ setWalkthrough(undefined) }
                        return setActiveLevel(undefined)
                    }}
                    walkthrough={walkthrough == 'basic' ? true : false}
                    onNext={() => {
                        onNextStep('password')
                        if(!active_level){ return }
                        let next_step = active_level.profile_steps[3]
                        setActiveStep(next_step)
                    }}
                    onPlayerUpdate={(player) => onPlayerUpdate(player, 'password')}
                />
                :active_step?.check == 'email' ?
                <EmailManager
                    player={draft_player}
                    walkthrough={walkthrough == 'verify' ? true : false}
                    onClose={() => {
                        if(profile_attribute){ return onClose() }
                        if(walkthrough){ setWalkthrough(undefined) }
                        return setActiveLevel(undefined)
                    }}
                    onNext={() => {
                        onNextStep('email')
                        if(!active_level){ return }
                        let next_step = active_level.profile_steps[1]
                        setActiveStep(next_step)
                    }}
                    onPlayerUpdate={(player) => onPlayerUpdate(player, 'email')}
                />
                :active_step?.check == 'phone' ?
                <PhoneManager
                    player={draft_player}
                    onClose={() => {
                        if(profile_attribute){ return onClose() }
                        if(walkthrough){ setWalkthrough(undefined) }
                        return setActiveLevel(undefined)
                    }}
                    walkthrough={walkthrough == 'verify' ? true : false}
                    onNext={() => {
                        onNextStep('phone')
                        if(!active_level){ return }
                        let next_step = active_level.profile_steps[2]
                        setActiveStep(next_step)
                    }}
                    onPlayerUpdate={(player) => onPlayerUpdate(player, 'phone')}
                />
                :active_step?.check == 'basic_review' ?
                <BasicStatus
                    player={draft_player}
                    code_details={code_details}
                    onClose={() => {
                        if(profile_attribute){ return onClose() }
                        if(walkthrough){ setWalkthrough(undefined) }
                        return setActiveLevel(undefined)
                    }}
                    onVerify={() => {
                        onNextStep('basic_review')
                        let new_level = groups[0]?.profile_levels.find(l => l.identifier == 'verify')
                        if(!new_level){return }
                        setWalkthrough('verify')
                        setActiveLevel(new_level)
                        onSelectLevel(new_level)
                        setActiveStep(new_level.profile_steps[0])
                    }}
                />
                :active_step?.check == 'dob' ?
                <DOBManager
                    player={draft_player}
                    onClose={() => {
                        if(profile_attribute){ return onClose() }
                        if(walkthrough){ setWalkthrough(undefined) }
                        return setActiveLevel(undefined)
                    }}
                    walkthrough={walkthrough == 'verify' ? true : false}
                    onNext={() => {
                        onNextStep('dob')
                        if(!active_level){ return }
                        let next_step = active_level.profile_steps[3]
                        setActiveStep(next_step)
                    }}
                    onPlayerUpdate={(player) => onPlayerUpdate(player, 'dob')}
                />
                :active_step?.check == 'vouch' ?
                <VouchCard
                    player={draft_player}
                    onPlayerUpdate={(p) => onPlayerUpdate(p, 'vouched_status')}
                    walkthrough={walkthrough == 'verify' ? true : false}
                    code_details={code_details}
                    onVouching={onVouching}
                    onClaimGift={() => {
                        onNextStep('vouch')
                        setActiveStep(undefined);
                        setActiveLevel(undefined);
                        setShowCodeRequest(true);
                        setWalkthrough(undefined)
                    }}
                    onClose={() => {
                        if(profile_attribute){ return onClose() }
                        if(walkthrough){ setWalkthrough(undefined) }
                        return setActiveLevel(undefined)
                    }}
                />
                
                :active_step?.check == 'notification' ?
                    <SettingsManager
                        player={draft_player}
                        title='Notification Settings'
                        description='Set how and where you would like to receive notifications'
                        icon={<Icons.PreferencesIcon
                            color={Colors.shades.white}
                            size={18}
                        />}
                        settings={settings.filter(s => s.setting_type == 'notification')}
                        player_settings={player_settings}
                        onUpdatePlayerSetting={onUpdatePlayerSetting}
                    />
                :active_step?.check == 'preferences' ?
                    <SettingsManager
                        player={draft_player}
                        title='Preferences'
                        description='Manage global configrations on your account'
                        icon={<Icons.NotificationIcon
                            color={Colors.shades.white}
                            size={20}
                        />}
                        settings={settings.filter(s => s.setting_type == 'preference')}
                        player_settings={player_settings}
                        onUpdatePlayerSetting={onUpdatePlayerSetting}
                    />
                :active_step?.check == 'sharing' ?
                    <SettingsManager
                        player={draft_player}
                        title='Share Settings'
                        description='Set who can see your activity'
                        icon={<Icons.EyeOnIcon
                            color={Colors.shades.white}
                            size={20}
                        />}
                        settings={settings.filter(s => s.setting_type == 'sharing')}
                        player_settings={player_settings}
                        onUpdatePlayerSetting={onUpdatePlayerSetting}
                    />
            :<></>}
            </View>
            </View>
            }
        </View>
    )
}



export default ProfileWizard
