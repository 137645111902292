import axios from 'axios';
import { APIOverrides } from "../../ApiOverrides"
import type { AthleteProps, BestAvailableResponseProps, ContestStatProps, EventOrderStatProps, EventProps, LeagueProps, MarketProps, MarketSideOptionProps, MatchProps, OrderProps, PodcastProps, TeamProps, TournamentProps } from "../../types"
import moment from 'moment-mini';

let EVENT_SVC_API = ''
let AUTH_SVC_API = ''
let MK_SVC_API = ''
let SOCIAL_SVC_API = ''

export { MarketComponentApi, MarketComponentHelpers }

const MarketComponentApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        MK_SVC_API = endpoints['MK_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
        SOCIAL_SVC_API = endpoints['SOCIAL_SVC_API'] as string;
    },
    getMarkets:async():Promise<MarketProps[]> => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/markets/all`);
            return resp.data.markets
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getLatestTradesByEvents: async(event_type: string, event_ids:string[]) => {
        try {
            if(event_ids.length == 0){ return [] }
            const resp = await axios.post(`${MK_SVC_API}/v1/trades/event/latest/bulk/get`, { event_type, event_ids })
            return resp.data.trades
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getLeagues:async():Promise<LeagueProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues`);
            return resp.data.leagues
        } catch (e) {
            return []
        }
    },
    getBestAvailableOrders:async():Promise<BestAvailableResponseProps | undefined> => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/available`);
            return resp.data
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    getAthletesByTeamId: async(team_id:string):Promise<AthleteProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/athletes/team/${team_id}`)
            return resp.data.athletes
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getPodcastsByKeywords: async(keywords:string):Promise<PodcastProps[]> => {
        try {
            console.log(keywords)
            const resp = await axios.get(`${SOCIAL_SVC_API}/v1/podcasts/search/keywords?keywords=${keywords}`);
            console.log(resp.data)
            return resp.data.podcasts
        } catch (e) {
            return []
        }
    },
    getTeamsByIds: async(team_ids:string[]):Promise<TeamProps[]> => {
        try {
            if(team_ids.length == 0){ return [] }
            const resp = await axios.post(`${EVENT_SVC_API}/v1/teams/bulk/get`, { attribute:'team_id', values:team_ids });
            return resp.data.teams
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getMatchesByTournament: async(tournament_id:string):Promise<MatchProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/matches/tournament/${tournament_id}`);
            return resp.data.matches
        } catch (e) {
            console.log(e);
            return[]
        }
    },
    getAthletesByIds: async(athlete_ids:string[]):Promise<AthleteProps[]> => {
        try {
            if(athlete_ids.length == 0){ return [] }
            const resp = await axios.post(`${EVENT_SVC_API}/v1/athletes/bulk/get`, { attribute:'athlete_id', values:athlete_ids })
            return resp.data.athletes
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getCompanyById: async(company_id:string) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/companies/company/id/${company_id}`)
            return resp.data
        } catch (e) {
            return undefined
        }
    },
    getContestStatsByEvent: async(contest_id:string):Promise<ContestStatProps[]> => {
        try {
            console.log(EVENT_SVC_API)
            const resp = await axios.get(`${EVENT_SVC_API}/v1/events/stats/event/${contest_id}`)
            return resp.data.contest_stats
        } catch (e) {
            console.log(e);
            return []
        }
    }
}


const MarketComponentHelpers = {
    getLiqudity: (event_order_stats:EventOrderStatProps[]):{ liquidity: number, open_order_count:number } => {
        let liquidity = event_order_stats.reduce((a,b) => a + b.open_order_amount, 0);
        let open_order_count = event_order_stats.reduce((a,b) => a + parseInt(b.open_order_count as string), 0);
        return { liquidity, open_order_count }
    },
    getVar1Label: (market:MarketProps, var_1:number, side?:string) => {
        if(side == 'over'){ return `O ${var_1}` }
        if(side == 'under'){ return `U ${var_1}` }
        if(market.type != 'Spread'){ return `${var_1}` }
        if(var_1 < 0){ return `${var_1}` }
        return `+${var_1}`
    },
    getOddsLabel: (odds:number, decimals?:number) => {
        let new_odds = odds.toFixed()
        if(decimals){ new_odds = odds.toFixed(decimals) }
        if(odds < 0){ return `${new_odds}` }
        return `+${new_odds}`
    },
    getTournamentSideIds: (market:MarketProps, side_option:MarketSideOptionProps, athlete?:AthleteProps, team?:TeamProps) => {
        const o_side = market.side_options.find(so => so.side != side_option.side);
        if(!o_side){ return undefined }
        switch(side_option.id_source){
            case 'side':
                return { side_id: side_option.side, reversed_side_id: o_side.side }
            case 'athlete':
                if(!athlete){ return undefined }
                return { side_id: athlete.athlete_id, reversed_side_id: athlete.athlete_id }
            case 'team':
                if(market.level == 'team'){
                    if(!team){ return undefined }
                    return { side_id: team.team_id, reversed_side_id: team.team_id }
                }
                return undefined
            default: return undefined
        }
    },
    prettyKey : (key?:string) => {
        try {
            if(!key){ return '' }
            let nested_key = key.split(':')
            let last_key = nested_key[nested_key.length - 1]
            if(!last_key){ return key }
            let key_split = last_key.split('_')
            let pretty_key = ''
            key_split.map((k,i) => {
              let last_item = i == k.length -1 ? true : false
              let first_letter = k.charAt(0)
              first_letter = first_letter.toUpperCase()
              pretty_key += `${first_letter}${k.substring(1)}`
              if(!last_item){ pretty_key += ' ' }
            })
            return pretty_key   
        } catch (e) {
            console.log(e)
            return key
        }
      },
      getMatchSideIds: (match:MatchProps, side_option:MarketSideOptionProps) => {
        if(side_option.side == 'home'){ 
            return { side_id: match.participants[1], reversed_side_id: match.participants[0] } 
        } else {
            return { side_id: match.participants[0], reversed_side_id: match.participants[1] }
        }
      },
    getTeamEventSideIds:(event:EventProps, market:MarketProps, side_option:MarketSideOptionProps, athlete?:AthleteProps, team?:TeamProps) => {
        const o_side = market.side_options.find(so => so.side != side_option.side);
        if(!o_side){ return undefined }
        switch(side_option.id_source){
            case 'side':
                return { side_id: side_option.side, reversed_side_id: o_side.side }
            case 'athlete':
                if(!athlete){ return undefined }
                return { side_id: athlete.athlete_id, reversed_side_id: athlete.athlete_id }
            case 'team':
                if(market.level == 'event'){
                    let side_id = event[side_option.side as keyof EventProps]?.team_id
                    let reversed_side_id = event[o_side.side as keyof EventProps]?.team_id
                    if(!side_id || !reversed_side_id){ return undefined }
                    return { side_id, reversed_side_id }
                }
                if(market.level == 'team'){
                    if(!team){ return undefined }
                    return { side_id: team.team_id, reversed_side_id: team.team_id }
                }
                return undefined
            default: return undefined
        }
    },
    isEventMarketLocked : (event:EventProps, market_id:string) => {
        if(!event.supported_markets){ return true }
        let supp_market = event.supported_markets.find(sm => sm.market_id == market_id)
        if(!supp_market){ return true }
        if(supp_market.removed){ return true }
        if(['closed','complete','postponed','suspended'].includes(event.status)){ return true }
        return false
    },
    isTournamentMarketLocked: (tournament:TournamentProps, market_id:string) => {
        if(!tournament?.supported_markets){ return true }
        let supp_market = tournament.supported_markets.find(sm => sm.market_id == market_id)
        if(!supp_market){ return true }
        if(supp_market.removed){ return true }
        if(['closed','complete'].includes(tournament.status)){ return true }
        return false
    },
    isMatchMarketLocked: (match:MatchProps, market_id:string) => {
        if(!match.supported_markets){ return true }
        let supp_market = match.supported_markets.find(sm => sm.market_id == market_id);
        if(['cancelled','postponed','closed','complete'].includes(match.status)){ return true }
        if(!supp_market){ return true }
        if(supp_market.removed){ return true }
        return false
    },
    getMatchTimeDetail: (match:MatchProps) => {
        if(!match.time_detail){
            if(match.status == 'not_started'){ return moment(match.scheduled_datetime).format('MMM DD hh:mm a') }
            return match.status.toUpperCase()
        }
        if(match.time_detail == 'scheduled'){ return moment(match.scheduled_datetime).format('MMM DD hh:mm a') }
        return match.time_detail
    },
    getOrderTitleForTeamEvent : (order:OrderProps, market:MarketProps, event:EventProps, athlete?:AthleteProps, exotic?:any):string | undefined => {
        let market_side = market.side_options.find(o => o.side == order.side)
        if(!market_side){ return undefined }
        var re = /({.*?})/;
        let parsed_text = market_side.parseable_title.split(re)
        let new_title = '';
        parsed_text.map(t => {
            if(t === ''){ return }
            if(t[0] !== '{'){ return new_title += t }
            let variable = t.slice(1)
            variable = variable.slice(0, variable.length -1)
            switch(variable){
                case 'team': 
                    let team = event[order.side as keyof EventProps]
                    return new_title += team.market_name
                case 'var_1': return new_title += MarketComponentHelpers.getVar1Label(market, order.var_1)
                case 'athlete': return new_title += athlete?.abbr_name
                case 'stat': return new_title += market.stat_label ?? market.stat
                case 'exotic': return new_title += exotic?.name 
                default: return

            }
        })
        return new_title
    },
    getOrderTitleForTournament : (order:OrderProps, market:MarketProps, tournament:TournamentProps, athlete?:AthleteProps, team?:TeamProps):string | undefined => {
        if(!tournament){ return undefined }
        let market_side = market.side_options.find(o => o.side == order.side)
        if(!market_side){ return order.side }
        var re = /({.*?})/;
        let parsed_text = market_side.parseable_title.split(re)
        let new_title = '';
        parsed_text.map(t => {
            if(t === ''){ return }
            if(t[0] !== '{'){ return new_title += t }
            let variable = t.slice(1)
            variable = variable.slice(0, variable.length -1)
            switch(variable){
                case 'team': return new_title += `${team?.market_name} ${team?.name}`
                case 'var_1': return new_title += MarketComponentHelpers.getVar1Label(market, order.var_1)
                case 'athlete': return new_title += athlete?.abbr_name
                case 'stat': return new_title += market.stat 
                default: return

            }
        })
        return new_title
    },
    getOrderTitleForMatch : (order:OrderProps, market:MarketProps, match:MatchProps, athlete?:AthleteProps, team?:TeamProps):string | undefined => {
        if(!match){ return undefined }
        let market_side = market.side_options.find(o => o.side == order.side)
        if(!market_side){ return order.side }
        var re = /({.*?})/;
        let parsed_text = market_side.parseable_title.split(re)
        let new_title = '';
        parsed_text.map(t => {
            if(t === ''){ return }
            if(t[0] !== '{'){ return new_title += t }
            let variable = t.slice(1)
            variable = variable.slice(0, variable.length -1)
            switch(variable){
                case 'team': return new_title += `${team?.market_name} ${team?.name}`
                case 'var_1': return new_title += MarketComponentHelpers.getVar1Label(market, order.var_1)
                case 'athlete': return new_title += athlete?.abbr_name
                case 'stat': return new_title += market.stat 
                default: return

            }
        })
        return new_title
    }
}