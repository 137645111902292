import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import type { ExternalPriceProps, FundProps, MarketProps, OrderProps } from '../../types';
import { view_styles } from '../../constants/styles';
import { Button, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import { MarketMaketHelpers } from '../api';

type SetMarketFormProps = {
    fund:FundProps,
    form_key:string,
    orders:OrderProps[],
    market:MarketProps,
    prices:ExternalPriceProps[],
    spread_pct:string,
    onSetMarket:(orders:OrderProps[]) => void
    onClose:() => void
}
const SetMarketForm = ({ fund, form_key, orders, market, prices, onClose, onSetMarket }:SetMarketFormProps) => {
    const [ form_data, setFormData ] = useState<{
        spread_pct:string,
        trade_side?:OrderProps,
        o_side?:OrderProps
    }>({
        spread_pct: '5'
    });
    const { trade_side, o_side, spread_pct } = form_data;
    const [ work, setWork ] = useState<{
        o_work:string,
        trade_work:string
    }>({
        o_work:'100',
        trade_work: '100'
    });
    const { o_work, trade_work } = work;
    const num_o_work = parseFloat(o_work);
    const num_trade_work = parseFloat(trade_work);

    const [ odds, setOdds ] = useState<{
        o_odds:string,
        trade_odds:string
    }>({
        o_odds: '100',
        trade_odds: '100'
    });
    const { o_odds, trade_odds } = odds;
    const num_o_odds = parseFloat(o_odds)
    const num_trade_odds = parseFloat(trade_odds)
    const o_prob = MarketMaketHelpers.calcProbabilityFromOdds(num_o_odds);
    const t_prob = MarketMaketHelpers.calcProbabilityFromOdds(num_trade_odds);
    console.log(o_prob, t_prob)
    const o_pot_winnings = MarketMaketHelpers.calcPotentialWinnings(o_side?.open_amt ?? 0, num_o_odds);
    const trade_pot_winnings = MarketMaketHelpers.calcPotentialWinnings(trade_side?.open_amt ?? 0, num_trade_odds);

    const o_stake = (num_o_work / 100) * fund.working_capital;
    const trade_stake = (num_trade_work / 100) * fund.working_capital;

    const no_vig_data = MarketMaketHelpers.getNoVigPrice(prices, market);
    const o_side_price = prices.find(p => p.side == o_side?.side);
    const trade_side_price = prices.find(p => p.side == trade_side?.side);
    
    useEffect(() => {
        if(!form_key) { return }
        let trade_side_o = orders.find(o => o.side == market.trade_side);
        let o_side_o = orders.find(o => o.side != market.trade_side);
        if(!o_side_o || !trade_side_o){ return alert('Unable to process') }
        setFormData({ trade_side:trade_side_o, o_side: o_side_o, spread_pct });
        setOdds({ o_odds: MarketMaketHelpers.getOddsLabel(o_side_o.odds), trade_odds: MarketMaketHelpers.getOddsLabel(trade_side_o.odds) });
        setWork({ o_work: o_side_o.working_pct ? (o_side_o.working_pct * 100).toFixed(2) : '100', trade_work: trade_side_o.working_pct ? (trade_side_o.working_pct * 100).toFixed(2) : '100' })
    },[form_key])

    const isValid = () => {
        if(!o_side || !trade_side){ return false }
        if(num_o_work > 100 || num_o_work < 1){ return false }
        if(num_trade_work > 100 || num_trade_work < 1){ return false }
        if(fund.working_capital < 1){ return false }
        if(o_prob > .99 || o_prob < 0.01){ return false }
        if(t_prob > .99 || t_prob < 0.01){ return false }
        return true
    }

    if(!trade_side || !o_side){ return <></> }
    const actual_margin = (1 - [trade_side, o_side].reduce((a, b) => a + b.probability, 0)) * 100
    console.log(isValid())
    return (
        <View style={{ ...view_styles.section, minWidth:330 }}>
            <View style={{ ...view_styles.section_header, paddingLeft:10 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Set Market</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Customize the orders below or submit</Text>
                </View>
                <Button
                    title='X'
                    borderRadius={100}
                    backgroundColor={Colors.shades.shade100}
                    title_color={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
            <View style={{ ...view_styles.section_body, padding:0 }}>
                {no_vig_data ?
                <View style={{ ...view_styles.body_row, padding:10 }}>
                    <View style={{ flex:1 }}>
                        <Text theme='header_2'>{no_vig_data.external_name}</Text>
                        <Text style={{ marginTop:3 }} theme='body_2'>The NO VIG price retrieved from {no_vig_data.external_name}</Text>
                    </View>
                    <Text size={16}>{MarketMaketHelpers.getOddsLabel(no_vig_data.trade_side_odds)}</Text>
                </View>
                :<></>}
                {no_vig_data ?
                <View style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600, backgroundColor:Colors.shades.shade100 }}>
                    <View style={{ flex:1, marginRight:10}}>
                        <Text theme='header_2'>Customize Margin</Text>
                        <Text style={{ marginTop:3 }} theme='body_2'>Set the margin pct to automatically create prices.</Text>
                    </View>
                    {actual_margin.toFixed(2) != parseInt(spread_pct).toFixed(2) ?
                    <Text size={14} color={actual_margin < 0 ? Colors.utility.error : actual_margin < 5 ? Colors.utility.success : actual_margin > 8 ? Colors.utility.error : Colors.utility.warning} weight='bold'>{actual_margin .toFixed(2)}%</Text>
                    :<></>}
                    <TextInput
                        style={{ ...view_styles.input, width:40 }}
                        value={spread_pct}
                        onChangeText={(text) => {
                            let num_spread_pct = parseFloat(text);
                            let o_nv_prob = (no_vig_data.o_side_prob - (num_spread_pct / 100 / 2));
                            let t_nv_prob = (no_vig_data.trade_side_prob - (num_spread_pct / 100 / 2));
                            let o_nv_odds = MarketMaketHelpers.calcAmericanOddsFromProbability(o_nv_prob);
                            let t_nv_odds = MarketMaketHelpers.calcAmericanOddsFromProbability(t_nv_prob);
                            console.log(`o side: ${o_nv_prob}`)
                            console.log(`t side: ${t_nv_prob}`)

                            setOdds({ o_odds: MarketMaketHelpers.getOddsLabel(o_nv_odds), trade_odds: MarketMaketHelpers.getOddsLabel(t_nv_odds) })
                            setFormData({ ...form_data, spread_pct: text })
                        }}
                    />
                    <Text size={16} color={Colors.brand.midnight}>%</Text>
                </View>
                :<></>}
                {o_side_price && trade_side_price ?
                <View style={{ ...view_styles.body_row }}>
                    <Text style={{ flex:1 }} textAlign='center' size={14} color={Colors.brand.midnight}>{MarketMaketHelpers.getOddsLabel(trade_side_price.odds * -1)}</Text>
                    <View>
                        <Text size={14} color={Colors.brand.midnight} textAlign='center'>{o_side_price?.external_name}</Text>
                        <Text size={10} textAlign='center'>{(o_side_price.vig_pct*100).toFixed(2)}% VIG</Text>
                    </View>
                    <Text style={{ flex:1 }} textAlign='center' size={14} color={Colors.brand.midnight}>{MarketMaketHelpers.getOddsLabel(o_side_price.odds * -1)}</Text>
                </View>
                :<></>}
                <View style={{ ...view_styles.body_row, paddingBottom:10 }}>
                    <View style={{ flex:1 }}>
                        <View style={{ padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='bold' textAlign='center'>{o_side.title}</Text>
                        </View>
                    </View>
                    <View style={{ flex:1 }}>
                        <View style={{ padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='bold' textAlign='center'>{trade_side.title}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ ...view_styles.body_row, padding:10 }}>
                    <TextInput
                        style={{ flex:1, ...view_styles.input }}
                        value={o_work}
                        onChangeText={(text) => setWork({ ...work, o_work: text })}
                    />
                    <Text style={{ marginLeft:5, marginRight:5 }} size={14} color={Colors.brand.midnight}>WORK %</Text>
                    <TextInput
                        style={{ flex:1, ...view_styles.input }}
                        value={trade_work}
                        onChangeText={(text) => setWork({ ...work, trade_work: text })}
                    />
                </View>
                <View style={{ ...view_styles.body_row }}>
                    <Text style={{ flex:1 }} textAlign='center' size={14} color={Colors.brand.midnight}>${o_stake.toFixed(2)}</Text>
                    <Text size={14} color={Colors.brand.midnight} textAlign='center'>TO STAKE</Text>
                    <Text style={{ flex:1 }} textAlign='center' size={14} color={Colors.brand.midnight}>${trade_stake.toFixed(2)}</Text>
                </View>
                <View style={{ ...view_styles.body_row, padding:10 }}>
                    <TextInput
                        style={{ flex:1, ...view_styles.input }}
                        value={o_odds}
                        onChangeText={(text) => setOdds({ ...odds, o_odds: text })}
                    />
                    <Text style={{ marginLeft:5, marginRight:5 }} size={14} color={Colors.brand.midnight}>ODDS</Text>
                    <TextInput
                        style={{ flex:1, ...view_styles.input }}
                        value={trade_odds}
                        onChangeText={(text) => setOdds({ ...odds, trade_odds: text })}
                    />
                </View>
                <View style={{ ...view_styles.body_row }}>
                    <Text style={{ flex:1 }} textAlign='center' size={14} color={Colors.brand.midnight}>${o_pot_winnings.toFixed(2)}</Text>
                    <Text size={14} color={Colors.brand.midnight} textAlign='center'>TO WIN</Text>
                    <Text style={{ flex:1 }} textAlign='center' size={14} color={Colors.brand.midnight}>${trade_pot_winnings.toFixed(2)}</Text>
                </View>
            </View>
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title='SUBMIT'
                    style={{ flex:1, opacity:isValid()?1:0.5 }}
                    disabled={isValid() ? false : true}
                    borderRadius={4}
                    backgroundColor={Colors.brand.electric}
                    title_color={Colors.shades.white}
                    onPress={() => {
                        if(!o_side || !trade_side){ return alert('Unable to process') }
                        let o_order:OrderProps = {
                            ...o_side,
                            open_amt: o_stake,
                            odds: num_o_odds,
                            probability: MarketMaketHelpers.calcProbabilityFromOdds(num_o_odds),
                            potential_winnings: o_pot_winnings,
                        }
                        let t_order:OrderProps = {
                            ...trade_side,
                            open_amt: trade_stake,
                            odds: num_trade_odds,
                            probability: MarketMaketHelpers.calcProbabilityFromOdds(num_trade_odds),
                            potential_winnings: trade_pot_winnings,
                        }
                        onSetMarket([o_order, t_order]);
                    }}
                />
            </View>

        </View>
    )
}

export default SetMarketForm


/*

                
                <View style={{ ...view_styles.body_row, padding:10 }}>
                    <TextInput
                        style={{ flex:1, ...view_styles.input }}
                        value={o_side.potential_winnings}
                        onChangeText={(text) => setFormData({ 
                            ...form_data, 
                            o_side: { 
                                ...o_side, 
                                potential_winnings: text, 
                                open_amt: MarketMaketHelpers.calcSaleValue(o_side.odds, text) 
                            }
                        })}
                    />
                    <Text style={{ marginLeft:5, marginRight:5 }} size={14} color={Colors.brand.midnight}>TO WIN</Text>
                    <TextInput
                        style={{ flex:1, ...view_styles.input }}
                        value={trade_side.potential_winnings}
                        onChangeText={(text) => setFormData({ 
                            ...form_data, 
                            trade_side: { 
                                ...trade_side, 
                                potential_winnings: text, 
                                open_amt: MarketMaketHelpers.calcSaleValue(trade_side.odds, text) 
                            }
                        })}
                    />
                </View>
               
*/