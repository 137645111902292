import React, { useEffect, useState } from 'react';
import { FlatList, View, TouchableOpacity, Image, ScrollView, ActivityIndicator } from 'react-native';
import type { AthleteProps, CompetitionPlayerBracketProps, PlayerBracketProps, PublicPlayerProps, TeamProps } from '../../types';
import { BracketApi, BracketComeptitionApi } from '../api';
import Colors from '../../constants/colors';
import { Button, Text } from '../../Components';
import { CompetitionHelpers } from '../../Competition/api';
import { view_styles } from '../../constants/styles';

type CompetitionLeaderboardProps = {
    bracket_competition_id:string,
    my_competition_player_brackets:CompetitionPlayerBracketProps[],
    my_player_brackets:PlayerBracketProps[],
    view_mode: 'short'|'full',
    show_champions?:boolean,
    onViewFull?: () => void,
    onClose?:() => void
}

const CompetitionLeaderboard = ({ bracket_competition_id, my_competition_player_brackets, my_player_brackets, view_mode, show_champions, onViewFull, onClose }:CompetitionLeaderboardProps) => {
    const [ leaders_data, setData ] = useState<{
        loading:boolean,
        offset:number,
        teams:TeamProps[],
        athletes:AthleteProps[],
        competition_player_brackets:CompetitionPlayerBracketProps[],
        player_brackets:PlayerBracketProps[],
        players:PublicPlayerProps[]
    }>({
        loading:false,
        offset:0,
        teams:[],
        athletes:[],
        competition_player_brackets: [],
        player_brackets:[],
        players:[]
    })
    const { loading, offset, competition_player_brackets, players, player_brackets, teams, athletes } = leaders_data;

    let visible_leaders = competition_player_brackets.sort((a,b) => a.place - b.place);
    if(view_mode == 'short'){ visible_leaders = competition_player_brackets.slice(0, 3) }

    useEffect(() => {
        if(!bracket_competition_id){ return }
        getLeaders(bracket_competition_id, 0)
    },[bracket_competition_id])

    const getLeaders = async(id:string, page:number) => {
        setData({ ...leaders_data, loading: true })
        const resp = await BracketComeptitionApi.getCompetitionPlayerBracketsByCompetitionId(id, page);
        
        const ps = await BracketApi.getPlayersByPlayerIds(resp.competition_player_brackets.map(cpb => cpb.player_id).concat(my_competition_player_brackets[0]?.player_id ?? '0'));
        const team_ids = resp.player_brackets.filter(pb => pb.champion_id_type == 'team').map(pb => pb.champion_id ?? '');
        const athlete_ids = resp.player_brackets.filter(pb => pb.champion_id_type == 'athlete').map(pb => pb.champion_id ?? '');
        const ts = await BracketApi.getTeamsByIds(team_ids);
        const aths = await BracketApi.getAthletesByIds(athlete_ids);
        setData({ 
            ...leaders_data,
            loading:false,
            competition_player_brackets: resp.competition_player_brackets,
            player_brackets: resp.player_brackets.filter(pb => !my_player_brackets.find(mpb => mpb.player_bracket_id == pb.player_bracket_id)).concat(my_player_brackets),
            teams:ts,
            offset: page,
            athletes: aths,
            players: ps
        })
    }

    const renderLeaders = (data: { item:CompetitionPlayerBracketProps, index:number }) => {
        const player = players.find(p => p.player_id == data.item.player_id);
        const player_bracket = player_brackets.find(pb => pb.player_bracket_id == data.item.player_bracket_id);
        if(!player || !player_bracket){ return <></> }
        let champ_team = undefined, champ_athlete = undefined
        if(show_champions && player_bracket.champion_id_type == 'team'){ champ_team = teams.find(t => t.team_id == player_bracket.champion_id) }
        if(show_champions && player_bracket.champion_id_type == 'athlete'){ champ_athlete = athletes.find(t => t.athlete_id == player_bracket.champion_id) }
        return (
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', borderRadius:22, margin:6, backgroundColor:Colors.shades.white, padding:4 }}>
                 <View style={{ padding:5 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{CompetitionHelpers.formatPlace(data.item.place)}</Text>
                </View>
                <View>
                    <Image
                        source={{ uri: player.profile_pic }}
                        style={{ height:40, width:40, borderRadius:100 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ flex:1, marginLeft:5, marginRight:5 }}>
                    <Text size={14} weight='bold' color={Colors.brand.midnight}>{player_bracket.bracket_name}</Text>
                    <Text style={{ marginTop:3 }} size={12} weight='regular' color={Colors.brand.slate}>{data.item.points_scored} Points ({data.item.possible_points} Possible)</Text>
                </View>
                {champ_team ?
                <View style={{ padding:5 }}>
                    <Image
                        source={{ uri: champ_team.image?.url }}
                        style={{ height:25, width:25, borderRadius:4 }}
                        resizeMode='cover'
                    />
                </View>
                :champ_athlete ?
                <View style={{ padding:5 }}>
                    <Image
                        source={{ uri: champ_athlete.image?.url }}
                        style={{ height:25, width:25, borderRadius:4 }}
                        resizeMode='cover'
                    />
                </View>
                :<></>}
                <View style={{ padding:5 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>${data.item.winnings.toFixed(2)}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1 }}>
            {my_competition_player_brackets.length > 0 ?
            <View style={{ backgroundColor:Colors.brand.midnight, padding:10, borderTopRightRadius:8, borderTopLeftRadius:8 }}>
                <View style={{ marginBottom:5 }}>
                    <Text size={14} color={Colors.shades.white}>MY RESULTS</Text>
                </View>
                <FlatList
                    data={my_competition_player_brackets}
                    renderItem={renderLeaders}
                    keyExtractor={(item) => item.competition_player_bracket_id.toString()}
                />
            </View>
            :<></>}
            <View style={{ padding:10, backgroundColor:Colors.shades.shade100, borderRadius:8 }}>
                <View style={{ marginBottom:5 }}>
                    <Text size={14} color={Colors.brand.midnight}>RESULTS</Text>
                </View>
                {loading ?
                <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ padding:25, alignSelf:'center' }} />
                :
                <FlatList
                    data={visible_leaders}
                    renderItem={renderLeaders}
                    keyExtractor={(item) => item.competition_player_bracket_id.toString()}
                />}
            </View>
            {view_mode == 'short' && onViewFull ?
            <View style={{ padding:10 }}>
                <Button
                    title='View Full Leaderboard'
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => onViewFull()}
                />
            </View>
            :<></>}
            </ScrollView>
            {onClose ?
            <View style={{ padding:10, ...view_styles.float }}>
                <View style={{ padding:10, flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                    {offset > 0 ?
                    <Button
                        title='PREV'
                        title_color={Colors.brand.electric}
                        backgroundColor='transparent'
                        onPress={() => getLeaders(bracket_competition_id, offset - 1)}
                    />
                    :<View/>}
                    <Button
                        title='NEXT'
                        title_color={Colors.brand.electric}
                        backgroundColor='transparent'
                        onPress={() => getLeaders(bracket_competition_id, offset + 1)}
                    />
                </View>
                <Button
                    title='Close'
                    style={{ padding:15 }}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default CompetitionLeaderboard