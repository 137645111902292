import React, { useEffect, useState } from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';

import type { EventProps, LeagueProps, MatchProps, TournamentProps } from "../../types"
import { view_styles } from '../../constants/styles';
import { Checkbox, Text } from '../../Components';
import DropDown from '../../Components/Dropdown';
import moment from 'moment-mini';
import Colors from '../../constants/colors';

type ContestSelectorProps = {
    leagues:LeagueProps[],
    maxHeight?:number,
    events:EventProps[],
    tournaments:TournamentProps[],
    matches:MatchProps[],
    selected_events:string[],
    onSelectEvent:(e:EventProps) => void,
    onDeselectEvent:(e:EventProps) => void,
    selected_tournaments:string[],
    onSelectTournament:(t:TournamentProps) => void
    selected_matches:string[],
    onSelectMatch:(m:MatchProps) => void
}

const ContestSelector = ({ maxHeight, leagues, events, onSelectEvent, selected_events, onDeselectEvent }:ContestSelectorProps) => {
    const [ selector_data, setSelectorData ] = useState<{
        selected_league?:LeagueProps
    }>({
        
    });
    const { selected_league } = selector_data;

    useEffect(() => {
        if(!selected_league){ setSelectorData({ ...selector_data, selected_league: leagues.find(l => l.priority == 1) }) }
    },[leagues.length]);

    const filtered_events = events.filter(e => e.league_id == selected_league?.league_id).sort((a,b) => moment(a.scheduled_datetime).unix() - moment(b.scheduled_datetime).unix());


    const renderEvents = (data:{ item:EventProps, index:number }) => {
        const selected = selected_events.find(se => se == data.item.event_id) ? true : false;
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} onPress={() => selected ? onDeselectEvent(data.item) : onSelectEvent(data.item)}>
                <Checkbox checked={selected} disabled onSelect={() => console.log('')}/>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text size={12} weight='bold' color={Colors.brand.midnight}>{data.item.event_title}</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>{data.item.time_detail == 'scheduled' ? moment(data.item.scheduled_datetime).format('MMM DD : hh:mm a') : data.item.time_detail}</Text>
                </View>
            </TouchableOpacity>
        )
    }


    return (
        <View style={{ ...view_styles.section, maxHeight }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Select Contest</Text>
                    <Text theme='body' style={{ marginTop:3 }}>Use league dropdown to filter</Text>
                </View>
                <DropDown
                    selected_value={selected_league?.league_name ?? ''}
                    dropdown_options={[
                        { value: 'league', eligible_options: leagues.sort((a,b) => a.priority - b.priority).map(l => l.league_name) }
                    ]}
                    onOptionSelect={(option) => {
                        let league = leagues.find(l => l.league_name == option);
                        setSelectorData({ ...selector_data, selected_league: league })
                    }}
                />
            </View>
            <View style={{ ...view_styles.section_body }}>
                <FlatList
                    data={filtered_events}
                    renderItem={renderEvents}
                    keyExtractor={(item) => item.event_id.toString()}
                />
            </View>
        </View>
    )
}

export default ContestSelector