import React from 'react';
import RNSlider from '@react-native-community/slider'
import Colors from '../constants/colors';

type SliderProps = {
    width:number,
    minimum_value:number,
    maximum_value:number,
    value?:number,
    minimum_track_tint?:string,
    maximum_track_tint?:string,
    thumb_tint_color?:string,
    onSlidingStart?:(val:number) => void,
    onSlidingComplete?:(val:number) => void
}

const Slider = ({ width, value, minimum_track_tint, minimum_value, maximum_track_tint, maximum_value, thumb_tint_color, onSlidingStart, onSlidingComplete }:SliderProps) => {
    return (
        <RNSlider
            style={{ width, height:25 }}
            value={value}
            minimumValue={minimum_value}
            minimumTrackTintColor={minimum_track_tint}
            maximumValue={maximum_value}
            maximumTrackTintColor={maximum_track_tint}
            thumbTintColor={thumb_tint_color ?? Colors.brand.midnight}
            onSlidingStart={onSlidingStart}
            onSlidingComplete={onSlidingComplete}
            
        />
    )
}

export default Slider