import { TouchableOpacity, View } from "react-native"
import React, { useState }  from "react"
import type { CompetitionMatchMarketProps, MarketProps } from "../../types";
import { Button, Text, TextInput } from "../../Components";
import Colors from "../../constants/colors";
import { ManageCompetitionHelpers } from "../api";


type CompetitionMatchMarketCardProps = {
    market:MarketProps,
    competition_match_id:string,
    competition_match_markets:CompetitionMatchMarketProps[],
    side_id?:string,
    event_id:string,
    competition_summaries?:any[]
    event_type:string
    onSaveMarkets: (cmms:CompetitionMatchMarketProps[]) => void,
    onUpdateMarkets: (cmms:CompetitionMatchMarketProps[]) => void,
    onGenerateMarkets: () => void
}

const CompetitionMatchMarketCard = ({ market, competition_match_markets, onUpdateMarkets }:CompetitionMatchMarketCardProps) => {
    const [ draft_competition_match_market, setDraftCompetitionMatchMarket ] = useState<CompetitionMatchMarketProps | undefined>(undefined)

    const trade_side = competition_match_markets.find(cmm => cmm.side == market.trade_side);
    const o_side = competition_match_markets.find(cmm => cmm.side != market.trade_side);

        


    const isCMMValid = (cmm:CompetitionMatchMarketProps) => {
        if(isNaN(cmm.odds as number)){ return false }
        if(isNaN(cmm.var_1 as number)){ return false }
        if(market.type != 'Spread' && parseFloat(cmm.var_1 as string) < 0){ return false }
        if(market.var_1_required && cmm.var_1 == 0){ return false }
        return true
    }

    
    const handleUpdateCMM = () => {
        if(!draft_competition_match_market){ return }
        //First check if the draft one is valie
        if(!isCMMValid(draft_competition_match_market)){ return alert('Please correct any errors') }
        let cmm = { ...draft_competition_match_market }
        //cmm.odds = parseFloat(cmm.odds)
        //cmm.var_1 = parseFloat(cmm.var_1)
        //now we need to flip sides
        let other_cmm = competition_match_markets.find(ncm => ncm.side != cmm.side)
        if(other_cmm){
            other_cmm.var_1 = cmm.var_1
            other_cmm.odds = parseFloat(cmm.odds as string) * -1
            other_cmm.probability = ManageCompetitionHelpers.calcProbabilityFromOdds(other_cmm.odds)
        } else {
            return alert('NOT HANDLED.  PLEASE AUTO GENERATE FIRST')
        }
        if(market.type == 'Spread'){
            other_cmm.var_1 = parseFloat(other_cmm.var_1 as string) * -1
        }
        onUpdateMarkets([{ ...cmm, probability: ManageCompetitionHelpers.calcProbabilityFromOdds(parseFloat(cmm.odds as string)), status:'pending' }, { ...other_cmm, status:'pending'}])
        return setDraftCompetitionMatchMarket(undefined)
    }
        

    

    if(draft_competition_match_market){
        return (
            <View style={{ flexDirection:'row', alignItems:'center' }}>
                <Text size={14} color={Colors.brand.midnight}>{draft_competition_match_market.side.toUpperCase()}</Text>
                {market.var_1_required ?
                <TextInput
                    style={{ padding:10, borderRadius:4, backgroundColor:Colors.shades.white, marginRight:20 }}
                    value={draft_competition_match_market.var_1 as string}
                    onChangeText={(text) => setDraftCompetitionMatchMarket({ ...draft_competition_match_market, var_1: text })}
                />
                :<></>}
                <TextInput
                    style={{ padding:10, borderRadius:4, backgroundColor:Colors.shades.white }}
                    value={draft_competition_match_market.odds as string}
                    onChangeText={(text) => setDraftCompetitionMatchMarket({ ...draft_competition_match_market, odds: text })}
                />
                <Button
                    title='Submit'
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => handleUpdateCMM()}
                />
            </View>
        )
    }

    if(!o_side || !trade_side){ return <></> }

    return (
        <View style={{ flexDirection:'row' }}>
            <View>
                <TouchableOpacity disabled style={{ width:75, padding:5, backgroundColor:o_side.status == 'error' ? Colors.highlights.highlight300Faded :Colors.shades.shade600, borderRadius:4, marginRight:3 }} onPress={() => setDraftCompetitionMatchMarket(o_side)}>
                    <Text textAlign="center" size={12} color={Colors.brand.midnight} weight='semibold'>{o_side.side.toUpperCase()}{market.var_1_required?` ${ManageCompetitionHelpers.getVar1Label(o_side.var_1 as number, market)}`:''}</Text>
                    <Text textAlign="center" size={12} color={Colors.brand.midnight} weight='semibold'>{ManageCompetitionHelpers.getOddsLabel(o_side.odds as number)}</Text>
                </TouchableOpacity>
            </View>
            <View>
                <TouchableOpacity disabled style={{ width:75, padding:5, backgroundColor:trade_side.status == 'error' ? Colors.highlights.highlight300Faded :Colors.shades.shade600, borderRadius:4, marginLeft:3 }} onPress={() => setDraftCompetitionMatchMarket(trade_side)}>
                    <Text textAlign="center" size={12} color={Colors.brand.midnight} weight='semibold'>{trade_side.side.toUpperCase()}{market.var_1_required?` ${ManageCompetitionHelpers.getVar1Label(trade_side.var_1 as number, market)}`:''}</Text>
                    <Text textAlign="center" size={12} color={Colors.brand.midnight} weight='semibold'>{ManageCompetitionHelpers.getOddsLabel(trade_side.odds as number)}</Text>
                </TouchableOpacity>
            </View>
            
        </View>
    )
        
}

export default CompetitionMatchMarketCard