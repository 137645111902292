import React from 'react';
import { View, FlatList, Image } from 'react-native';
import type { FundingRequestProps, PublicPlayerProps } from "../../types"
import { view_styles } from '../../constants/styles';
import { Button, Text } from '../../Components';
import Colors from '../../constants/colors';
import moment from 'moment-mini';

type FundingRequestListProps = {
    funding_requests:FundingRequestProps[],
    players:PublicPlayerProps[],
    onApproveRequest:(funding_request_id:string) => void,
    onDeclineRequest:(funding_request_id:string) => void
}
const FundingRequestList = ({ funding_requests, players, onApproveRequest, onDeclineRequest }:FundingRequestListProps) => {

    const renderFundingRequests = (data:{ item:FundingRequestProps, index:number }) => {
        const player = players.find(p => p.player_id == data.item.player_id)
        if(!player){ return <></> }
        return (
            <View style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <Image
                    source={{ uri: player.profile_pic && player.profile_pic != '' ? player.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:40, width:40, borderRadius:100 }}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{player.first_name} {player.last_name}</Text>
                    <Text style={{ marginTop:3 }} size={12} weight='regular' color={Colors.brand.midnight}>Requested on {moment(data.item.create_datetime).format('MMM DD hh:mm a')}</Text>
                </View>
                <Text size={14} weight='bold' color={Colors.brand.midnight}>${data.item.amount.toFixed(2)}</Text>
                <Button
                    title='DECLINE'
                    style={{ marginRight:5, marginLeft:5 }}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    borderRadius={4}
                    onPress={() => onDeclineRequest(data.item.funding_request_id)}
                />
                <Button
                    title='APPROVE'
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    borderRadius={4}
                    onPress={() => onApproveRequest(data.item.funding_request_id)}
                />
            </View>
        )
    }

    return (
        <View style={{ ...view_styles.section }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Funding Requests</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Below are the pending funding requests.  Approve or decline them.</Text>
                </View>
            </View>
            <View style={{ ...view_styles.section_body, padding:0 }}>
                <FlatList
                    key={'funding_requests'}
                    data={funding_requests}
                    keyExtractor={(item) => item.funding_request_id.toString()}
                    renderItem={renderFundingRequests}
                />
            </View>
        </View>
    )
}

export default FundingRequestList