import React from 'react';
import { View, Image } from 'react-native';
import type { CompetitionProps, CompetitionSummaryProps } from '../../types';
import { Button, Text } from '../../Components';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';

type CompetitionHeaderProps = {
    width:number,
    action_loading:boolean,
    competition:CompetitionProps,
    competition_summaries:CompetitionSummaryProps[],
    competition_valid: {
        valid:boolean,
        info:boolean,
        contests:boolean,
        contest_settings: boolean,
        settings:boolean
    },
    onPause:() => void,
    onResume: () => void,
    onDelete:() => void,
    onActivate: () => void
}

const CompetitionHeader = ({ width, action_loading, competition_summaries, competition, competition_valid, onDelete, onActivate, onPause, onResume }:CompetitionHeaderProps) => {
    
    const handleActivate = () => {
        if(!competition_valid.valid){
            return alert(`Please complete all the required sections before activating`)
        }
        onActivate()
    }

    const handleResume = () => {
        if(!competition_valid.valid){
            return alert(`Please complete all the required sections before activating`)
        }
        onResume()
    }
    
    return (
        <View style={{ backgroundColor:Colors.shades.white, flexGrow:1, minWidth:330, maxWidth:width }}>
            <View nativeID="competition_header" style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <View>
                    <Image
                        source={{ uri: competition.image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1649737862/be_logo_jte2ux.webp' }}
                        style={{ height:50, width:50, borderRadius:4 }}
                        resizeMode="cover"
                    />
                </View>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>{competition.competition_name}</Text>
                    <Text style={{ marginTop:4 }} theme='header_2>'>{competition.competition_description}</Text>
                </View>
                {competition.status == 'pending'?
                <Button
                    title={'ACTIVATE'}
                    style={{ opacity:competition_valid.valid ? 1: 0.5 }}
                    loading={action_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => handleActivate()}
                />
                :<></>}
            </View>
            {competition.status == 'pending' ? 
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>COMPETITION IS PENDING</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Complete each section and press 'ACTIVATE'.</Text>
                </View>
                <Button
                    title='DELETE'
                    style={{ opacity:!action_loading? 1: 0.5 }}
                    loading={action_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={async() => {
                        const confirmed = await confirm(`Are you sure you would like to delete this competition?  This cannot be undone.`)
                        if(!confirmed){ return }
                        onDelete();
                    }}
                />
            </View>
            :<></>}
            {competition.status == 'scheduled' ?
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>COMPETITION IS ACTIVE</Text>
                    <Text style={{ marginTop:3 }} theme='body'>In order to edit this competition, you must first pause it.</Text>
                </View>
                <Button
                    title='PAUSE'
                    style={{ opacity:!action_loading && competition_valid.valid ? 1: 0.5 }}
                    loading={action_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onPause()}
                />
            </View>
            :<></>}
            {competition.status == 'paused' ?
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>EDITING COMPETITION</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Feel free to make changes. When done, simply press resume.</Text>
                </View>
                {competition_summaries.length == 0 ?
                <Button
                    title='DELETE'
                    style={{ marginRight:5, opacity:!action_loading ? 1: 0.5 }}
                    loading={action_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={async() => {
                        const confirmed = await confirm(`Are you sure you would like to delete this competition?  This cannot be undone.`)
                        if(!confirmed){ return }
                        onDelete();
                    }}
                />
                :<></>}
                <Button
                    title='RESUME'
                    style={{ opacity:!action_loading && competition_valid.valid ? 1: 0.5 }}
                    loading={action_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    onPress={() => handleResume()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default CompetitionHeader