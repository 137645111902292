import React, { useEffect, useState } from "react"
import { ActivityIndicator, View, Image, TouchableOpacity, ScrollView } from 'react-native';
import type { BEEventProps, BuySquaresResponseProps, EventProps, LeagueProps, OfferResponseProps, PlayerBalanceProps, PlayerSquareProps, PublicPlayerProps, SquareOfferProps, SquarePrizeProps, SquareProps, SquareResultProps, SquaresCompetitionProps, SquaresPayoutProps, SquaresTypeProps } from "../types"
import Colors from "../constants/colors";
import { SqauresHelpers, SquaresApi } from "./api";
import { Button, Icons, Text } from "../Components";
import SquaresBoard from "./components/SquaresBoard";
import BidForm from "./components/BidForm";
import SocketManager from "../Socket";
import EventCard from "./components/EventCard";
import ResultsCard from "./components/ResultsCard";
import SquaresDetails from "./components/SquaresDetails";
import BoardStats from "./components/BoardStats";
import SquareOwnersCard from "./components/SquareOwners";
import OfferForm from "./components/OfferForm";
import MySquaresCard from "./components/MySquaresCard";
import { view_styles } from "../constants/styles";
import MyStatsCard from "./components/MyStatsCard";
import SquareOffersCard from "./components/SquareOffersCard";
import PrizeCard from './components/PrizeCard';

type SquaresModuleProps = {
    player_id?:string,
    distinct_id:string,
    sq_comp_id:string,
    onRequestAuthenticate: (auth_strategy_id?:string) => void,
    onShareCompetition:(squares_competition:SquaresCompetitionProps) => void,
    onPlayerSelect:(player:PublicPlayerProps) => void,
    onEvent:(ev:BEEventProps) => void
}

const SquaresModule = ({ sq_comp_id, player_id, distinct_id, onRequestAuthenticate, onShareCompetition, onPlayerSelect }:SquaresModuleProps) => {
    const [ needs_reload, setNeedsReload ] = useState(false);
    const [ module_size, setModuleSize ] = useState({ width:0, height:0 })
    const [ bid_form_expanded, setBidFormExpanded ] = useState(false);
    const [ updated_from_bid, setUpdatedFromBid ]= useState<BuySquaresResponseProps>();
    const [ updated_from_offer, setUpdatedFromOffer ]= useState<OfferResponseProps>();
    const [ my_data, setMyData ] = useState<{
        player_balance?:PlayerBalanceProps
    }>({

    })
    const { player_balance } = my_data;
    const [ module_data, setModuleData ] = useState<{
        loading:boolean,
        loaded:boolean,
        event?:EventProps,
        squares_competition?:SquaresCompetitionProps,
        squares:SquareProps[],
        square_prizes:SquarePrizeProps[],
        league?:LeagueProps,
        players:PublicPlayerProps[],
        squares_payout_type?:SquaresPayoutProps,
        squares_type?: SquaresTypeProps,
        square_results:SquareResultProps[],
        square_offers:SquareOfferProps[],
        player_square_history:PlayerSquareProps[],
        player_squares:PlayerSquareProps[],
    }>({
        loading: false,
        loaded: false,
        squares:[],
        players:[],
        square_results: [],
        square_prizes:[],
        square_offers: [],
        player_square_history: [],
        player_squares: []
    });
    const [ board_data, setBoardData ] = useState<{
        draft_square_offers:SquareOfferProps[],
        square_bids:PlayerSquareProps[],
        submit_ready: { is_ready: boolean, is_loading:boolean }
    }>({
        draft_square_offers: [],
        square_bids: [],
        submit_ready: { is_loading:false, is_ready: false }
    });

    const { square_bids, draft_square_offers, submit_ready } = board_data;
    const { loading, league, event, players, loaded, squares_competition, square_prizes, squares, squares_type, squares_payout_type, square_results, square_offers, player_square_history, player_squares } = module_data;

    const squares_expected_value = SqauresHelpers.getMyExpectedValue(squares, [ ...player_squares.filter(ps => ps.status == 'active' && ps.player_id == player_id) ], squares_competition, player_id)
    const my_squares = player_squares.filter(ps => ps.status == 'active' && ps.player_id == player_id);
    const home_abbr = event?.home?.abbr??squares_competition?.event_backup?.home?.abbr
    const away_abbr = event?.away?.abbr??squares_competition?.event_backup?.away?.abbr
    const joined = SqauresHelpers.amIParticipating(player_squares, player_square_history, player_id);

    useEffect(() => {
        if(!sq_comp_id){ return }
        if(!loaded){ SquaresApi.setEnvironment(); }
        getDataFromServer(sq_comp_id);
    },[sq_comp_id, player_id])

    useEffect(() => {
        if(!updated_from_bid?.squares_competition){ return }
        handleUpdateBoardFromBuy(updated_from_bid)
    },[updated_from_bid])

    useEffect(() => {
        if(!updated_from_offer?.squares_competition){ return }
        handleUpdateBoardFromOffer(updated_from_offer)
    },[updated_from_offer])

    const getDataFromServer = async(sq_comp_id:string, options?:any) => {
        if(!options || !options.ignore_load){ setModuleData({ ...module_data, loading: true }) }
        const d = await SquaresApi.getSquareCompetitionById(sq_comp_id);
        const evs = await SquaresApi.getEventsByEventIds([d.squares_competition.event_id]);
        const league_id = evs[0]?.league_id
        let unique_player_ids = [ ...new Set(d.player_squares.map(ps => ps.player_id)) ]
        if(player_id){ 
            unique_player_ids.push(player_id) 
            const pb = await SquaresApi.getMyBalance();
            setMyData({
                ...my_data,
                player_balance:pb
            })
        }
        let ps = await SquaresApi.getPlayersByPlayerIds(unique_player_ids)
        let l:LeagueProps | undefined = undefined
        if(league_id){
            l = await SquaresApi.getLeagueById(league_id);
        }
        setModuleData({
            ...module_data,
            loaded: true,
            event: evs[0],
            league: l,
            squares_competition: d.squares_competition,
            squares_type: d.squares_type,
            squares_payout_type: d.squares_payout_type,
            squares:d.squares,
            players:ps,
            square_prizes: d.square_prizes,
            square_offers: d.square_offers,
            square_results: d.square_results,
            player_squares:d.player_squares.filter(ps => ps.status == 'active'),
            player_square_history: d.player_square_history
        })
    }


    const handleSubmitBid = async(promo_balance?:boolean) => {
        if(!squares_competition){ return }
        if(!player_id){ return onRequestAuthenticate(squares_competition.auth_strategy_id) }
        if(!submit_ready.is_ready){
            setBoardData({ ...board_data, submit_ready: { is_ready: false, is_loading:true } })
            setTimeout(() => {
                setBoardData({ ...board_data, submit_ready: { is_ready: true, is_loading:false } })
            }, 500);
            return
        }
        //if(player?.vouched_status !== 'verified' && squares_competition.market_type === 'FOR_MONEY'){ return alert('Must be verified before purchasing real money squares') }
        if(submit_ready.is_loading){ return } //Prevent button mashing
        //SUBMIT BIDS!
        setBoardData({ ...board_data, submit_ready: { ...submit_ready, is_loading:true } })
        await SquaresApi.buySquares(squares_competition.sq_comp_id, square_bids, promo_balance)
        //if(needs_reload){ handleUpdateBoardFromBuy(resp) } //If we somehow got disconnected from the socket, we need to load it
        setBoardData({ ...board_data, square_bids:[], submit_ready:{ is_loading: false, is_ready: false } })
        setBidFormExpanded(false);
    }

    const handleSubmitOffer = async() => {
        if(draft_square_offers.length === 0 || !draft_square_offers[0]){ return }
        if(!submit_ready.is_ready){
            setBoardData({ ...board_data, submit_ready: { is_ready: false, is_loading:true } })
            setTimeout(() => {
                setBoardData({ ...board_data, submit_ready: { is_ready: true, is_loading:false } })
            }, 500);
            return
        }
        if(submit_ready.is_loading){ return } //Prevent button mashing
        //SUBMIT BIDS!
        
        setBoardData({ ...board_data, submit_ready: { ...submit_ready, is_loading:true } })
        await SquaresApi.offserSquare(draft_square_offers[0])
        //if(needs_reload){ handleUpdateBoardFromOffer(resp) } //If we somehow got disconnected from the socket, we need to load it
        setBoardData({ ...board_data, draft_square_offers:[], submit_ready:{ is_loading: false, is_ready: false } })
    }

    const handleClearBids = () => {
        setBoardData({ ...board_data, square_bids: [], submit_ready: { is_ready:false, is_loading:false }})
        setBidFormExpanded(false);
    }

    const handleClearOffers = () => {
        setBoardData({ ...board_data, draft_square_offers: [], submit_ready: { is_ready:false, is_loading:false }})
    }

    const handleSquareBid = (square:SquareProps, action:'remove'|'add') => {
        if(!squares_competition){ return }
        //setSubmitReady({ isReady:false, isLoading:false })
        if(action === 'remove'){
            return setBoardData({ ...board_data, square_bids: square_bids.filter(b => b.sq_square_id != square.sq_square_id) })
        }
        let purchase_price = square.init_cost
        if(square.last_purchase_price >= square.init_cost){ purchase_price = square.last_purchase_price + 0.50 }
        let newPS:PlayerSquareProps = {
            sq_player_square_id:'', sq_comp_id:squares_competition.sq_comp_id, sq_square_id:square.sq_square_id, player_id:'', purchase_price, status:'active', create_datetime:'', last_update_datetime:''
        }
        return setBoardData({ ...board_data, square_bids: square_bids.concat(newPS) })
    }

    const handleBidAmountChange = (square_bid:PlayerSquareProps, square:SquareProps, direction:string) => {
        let new_purchase_price:number = 0
        if(direction === 'increase'){
            new_purchase_price = square_bid.purchase_price + 0.50
        } else {
            new_purchase_price = square_bid.purchase_price - 0.50
            if(new_purchase_price < square.init_cost){ return } //Do not change if the price is less than the initial cost
            if(new_purchase_price <= square.last_purchase_price){ return } //Do not change if the price is not greater than current bid
        }
        
        square_bid = { ...square_bid, purchase_price:new_purchase_price }
        setBoardData({ ...board_data, square_bids: square_bids.filter(s => s.sq_square_id != square_bid.sq_square_id).concat(square_bid) })
    }

    const handleOfferAmountChange = (square_offer:SquareOfferProps, player_square:PlayerSquareProps, direction:string) => {
        if(!player_square){ return }
        let new_purchase_price:number = 0
        if(direction === 'increase'){
            new_purchase_price = square_offer.amount + 1
        } else {
            new_purchase_price = square_offer.amount - 1
        }
        if(new_purchase_price <= player_square.purchase_price){ return } //Do not change if the price is less than the initial cost
        square_offer = { ...square_offer, amount:new_purchase_price }
        setBoardData({ ...board_data, draft_square_offers: draft_square_offers.filter(o => o.sq_player_square_id != square_offer.sq_player_square_id).concat(square_offer) })
    }

    const handleSquareOffer = (square:SquareProps, action:'remove'|'add') => {
        if(!squares_competition){ return }
        if(!player_id){ return }
        //setSubmitReady({ isReady:false, isLoading:false })
        let ps = player_squares.find(ps => ps.sq_square_id == square.sq_square_id)
        if(!ps){ return alert('Cannot put offer on this square as it is not owned by anyone') }        
        if(action === 'remove'){
            return setBoardData({ ...board_data, draft_square_offers: draft_square_offers.filter(so => so.sq_player_square_id != ps?.sq_player_square_id) })
        }
        if(draft_square_offers.length > 0){ return alert('Currently only allowed to offer to buy 1 square at a time') }
        //Todo - check if there is an existing square offer and update more than the last one (maybe)
        let purchase_price = ps.purchase_price + 1
        let newSO:SquareOfferProps = {
            sq_offer_id:'', sq_comp_id:squares_competition.sq_comp_id, sq_player_square_id:ps.sq_player_square_id, amount:purchase_price, buy_sell_ind:ps.player_id == player_id?'sell':'buy', market_type:squares_competition.market_type,
            status:'offered', offerer_id: player_id, offeree_id:ps.player_id, create_datetime:'', last_update_datetime:''
        }
        if(newSO.buy_sell_ind == 'buy'){
            let existingSellOffer = square_offers.find(o => o.sq_player_square_id == newSO.sq_player_square_id)
            if(existingSellOffer){ newSO.amount = existingSellOffer.amount }
        }
        if(newSO.buy_sell_ind == 'sell'){
            let existingSellDraftOffer = draft_square_offers.find(o => o.sq_player_square_id == newSO.sq_player_square_id)
            if(existingSellDraftOffer){ return alert('You already have an existing sale offer for this square') }
        }
        return setBoardData({ ...board_data, draft_square_offers: draft_square_offers.concat(newSO) })
    }

    const handleUpdateBoardFromOffer = (data:OfferResponseProps) => {
        //First remove squares
        //let new_ps = player_squares.filter(ps => !data.removed_player_squares.find(nps => nps.sq_player_square_id == ps.sq_player_square_id))
        //Now update squares
        let new_ps = SqauresHelpers.updatePlayerSquaresWithSavedSquares([ ...player_squares ], data.saved_player_squares)
        new_ps = SqauresHelpers.updatePlayerSquaresWithRemovedSquares(new_ps, data.removed_player_squares);
        setModuleData({
            ...module_data,
            squares_competition: data.squares_competition ? data.squares_competition : squares_competition,
            player_squares: new_ps,
            square_offers: SqauresHelpers.updateSquareOffersWithUpdatedOffers(square_offers, data.updated_offers)
        })
    }

    const handleUpdateBoardFromBuy = (data:BuySquaresResponseProps) => {
        //First remove squares
        //Now update squares
        let new_ps = SqauresHelpers.updatePlayerSquaresWithSavedSquares([ ...player_squares ], data.saved_player_squares)
        new_ps = SqauresHelpers.updatePlayerSquaresWithRemovedSquares(new_ps, data.removed_player_squares);
        let new_sq = SqauresHelpers.updateSquaresWithUpdatedSquares([ ...squares], data.updated_squares)
        let new_ps_his = SqauresHelpers.updatePlayerSquareHistoryWithRemovedSquares([ ...player_square_history], data.removed_player_squares);
        setModuleData({
            ...module_data,
            squares_competition: data.squares_competition,
            squares: new_sq,
            player_squares: new_ps,
            player_square_history: new_ps_his
        })
    }

    if(loading || !squares_competition){
        return (
            <View style={{ flex:1, backgroundColor:Colors.shades.white }}>
                <ActivityIndicator style={{ padding:20, alignSelf:'center' }} color={Colors.brand.midnight} size='large' />
            </View>
        )
    }

    const comp_status = SqauresHelpers.getCompStatus(event, squares_competition)
    return (
        <View style={{ flex:1, backgroundColor:Colors.shades.shade100 }}>
            <ScrollView style={{flex:1}} onLayout={(ev) => {
            const { width, height } = ev.nativeEvent.layout
            setModuleSize({ width, height })
        }}>

            <View nativeID="squares_header" style={{ flexDirection:'row', alignItems:'center', padding:20, backgroundColor:Colors.shades.white }}>
                <View>
                    <Image
                        source={{ uri: squares_competition.image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1649737862/be_logo_jte2ux.webp' }}
                        style={{ height:50, width:50, borderRadius:4 }}
                        resizeMode="cover"
                    />
                </View>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>{squares_competition.sq_comp_name}</Text>
                    <Text style={{ marginTop:4 }} theme='header_2>'>{squares_competition.sq_comp_description}</Text>
                </View>
                <TouchableOpacity onPress={() => onShareCompetition(squares_competition)}>
                    <Icons.ShareIcon color={Colors.brand.midnight} size={14} />
                </TouchableOpacity>
            </View>
            {event ?
            <EventCard
                event={event}
                league={league}
            />
            :<></>}
            <BoardStats
                squares_competition={squares_competition}
                squares={squares}
                player_squares={player_squares}
            />
            {joined ?
            <MyStatsCard
                player_id={player_id}
                players={players}
                home_abbr={home_abbr}
                squares_expected_value={squares_expected_value}
                away_abbr={away_abbr}
                onSquareBid={(square, action) => {
                    handleSquareBid(square, action)
                    setBidFormExpanded(true);
                }}
                squares={squares}
                player_square_history={player_square_history}
                player_squares={player_squares}
                square_results={square_results}
                squares_competition={squares_competition}
            />
            :<></>}
            {square_results.length > 0 ?
            <ResultsCard
                home_abbr={home_abbr}
                away_abbr={away_abbr}
                squares={squares}
                square_results={square_results}
                onPlayerSelect={onPlayerSelect}
            />
            :<></>}
            {square_offers.length > 0 ?
            <SquareOffersCard
                player_squares={player_squares}
                square_offers={square_offers}
                player_id={player_id}
                squares={squares}
                home_abbr={home_abbr}
                away_abbr={away_abbr}
                onRequestAuthenticate={() => onRequestAuthenticate(squares_competition.auth_strategy_id)}
            />
            :<></>}
            <PrizeCard 
                square_prizes={square_prizes}
                squares={squares}
                players={players}
                event={event}
            />
            <View>
                <SquaresBoard 
                    player_id={player_id}
                    onSquareBid={handleSquareBid}
                    onSquareOffer={handleSquareOffer}
                    onRefreshCompetition={() => getDataFromServer(sq_comp_id)}
                    square_bids={square_bids}
                    draft_square_offers={draft_square_offers}
                    squares_competition={squares_competition}
                    squares={squares}
                    event={event}
                    player_squares={player_squares}
                    player_square_history={player_square_history}
                    square_results={square_results}
                    square_offers={square_offers}
                />
                {square_bids.length > 0 && !bid_form_expanded ?
                <View style={{ position:'absolute', flexDirection:'row', alignItems:'center', top:10, left:10, backgroundColor:Colors.shades.shade600, padding:10, borderRadius:8, borderBottomLeftRadius:0,  ...view_styles.float }}>
                    <Text theme="header">{square_bids.length} Squares</Text>
                    <Button
                        title="SET AMOUNTS"
                        style={{ marginLeft:10 }}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.brand.electric}
                        onPress={() => setBidFormExpanded(true)}
                    />
                </View>
                :<></>}
            </View>
            {my_squares.length > 0 ?
            <MySquaresCard
                player_squares={my_squares}
                squares={squares}
                square_offers={square_offers}
                away_abbr={away_abbr}
                my_square_values={squares_expected_value.my_square_values}
                home_abbr={home_abbr}
                comp_status={comp_status}
                onSquareOffer={handleSquareOffer}
            />
            :<></>}
            <SquaresDetails
                squares_competition={squares_competition}
                squares_payout_type={squares_payout_type}
                squares_type={squares_type}
            />
            <SquareOwnersCard
                players={players}
                player_squares={player_squares}
                onPlayerSelect={onPlayerSelect}
            />
            <SocketManager 
                //access_token={access_token}
                onConnect={() => {
                    if(needs_reload){
                        setNeedsReload(false);
                        getDataFromServer(sq_comp_id, { ignore_load:true });
                    }
                }}
                onDisconnect={() => setNeedsReload(true)}
                distinct_id={distinct_id}
                onSocketEvent={(ev) => {
                    switch(ev.type){
                        case 'V1_UPDATE_SQUARE_DETAILS':
                            let data = ev.data as BuySquaresResponseProps | OfferResponseProps
                            if(!data.squares_competition || data.squares_competition.sq_comp_id != squares_competition.sq_comp_id){ break } //This isn't this competition;
                            if(ev.action == 'buy'){ setUpdatedFromBid(data as BuySquaresResponseProps) }
                            if(ev.action == 'offer')[ setUpdatedFromOffer(data as OfferResponseProps) ]
                            break;
                        default: break;
                    }
                }}
                subscribed_events={['V1_UPDATE_SQUARE_DETAILS']}
            />
            </ScrollView>
            {bid_form_expanded ?
            <View style={{ position:'absolute', bottom:0, left:0, right:0, top:0, justifyContent:module_size.width > 500 ? 'center' : 'flex-end', alignItems:'center', backgroundColor:Colors.shades.black_faded }}>
                <BidForm
                    player_id={player_id}
                    player_balance={player_balance}
                    home_abbr={home_abbr}
                    away_abbr={away_abbr}
                    width={module_size.width}
                    squares={squares}
                    submit_ready={submit_ready}
                    onBidAmountChange={(pb, sq, dir) => handleBidAmountChange(pb, sq, dir)}
                    market_type={squares_competition.market_type}
                    square_bids={square_bids}
                    onClearBids={handleClearBids}
                    onRequestAuthenticate={() => onRequestAuthenticate(squares_competition.auth_strategy_id)}
                    onSubmitBid={(promo_balance) => handleSubmitBid(promo_balance)}
                />
            </View>
            :<></>}
            {draft_square_offers.length > 0 ?
            <View style={{ position:'absolute', bottom:0, left:0, right:0, top:0, justifyContent:module_size.width > 500 ? 'center' : 'flex-end', alignItems:'center', backgroundColor:Colors.shades.black_faded }}>
                <OfferForm
                    player_id={player_id}
                    draft_square_offers={draft_square_offers}
                    home_abbr={home_abbr}
                    player_balance={player_balance}
                    width={module_size.width}
                    away_abbr={away_abbr}
                    squares={squares}
                    submit_ready={submit_ready}
                    market_type={squares_competition.market_type}
                    player_squares={player_squares}
                    onClearOffers={handleClearOffers}
                    onRequestAuthenticate={() => onRequestAuthenticate(squares_competition.auth_strategy_id)}
                    onOfferAmountChange={(offer, player_square, direction) => handleOfferAmountChange(offer, player_square, direction)}
                    onSubmitOffer={() => handleSubmitOffer()}
                />
            </View>
            :<></>}
        </View>

    )
}

export default SquaresModule