import moment from "moment-mini";
import Colors from "../../../../constants/colors";
import type { BestAvailableOrderProps, MarketProps, MarketSideOptionProps, OrderProps, TradeProps } from "../../../../types";
import { MarketComponentHelpers } from "../../../api";


export const MarketButtonHelpers = {
    getButtonLabel: (market:MarketProps, side_option:MarketSideOptionProps, trade:TradeProps) => {
        let v_1 = MarketComponentHelpers.getVar1Label(market, trade.var_1, side_option.side);
        let o_1 = MarketComponentHelpers.getOddsLabel(trade.odds);
        return { var_1: v_1, odds:o_1, color: trade.draft_liquidity? Colors.highlights.highlight500Faded : Colors.shades.shade100 }
    },
    getVisibleTrade: (default_price_view:'best_available'|'last_trade', market:MarketProps, side_option:MarketSideOptionProps, latest_trades:TradeProps[], best_available_orders:BestAvailableOrderProps[], side_id:string, reversed_side_id:string) => {
        const order = best_available_orders.find(o => o.market_id == market.market_id && o.side == side_option.side && o.show);
        const o_side_order = best_available_orders.find(o => o.market_id == market.market_id && o.side != side_option.side && o.show);
        let trade = latest_trades.find(t => t.market_type == 'FOR_MONEY' && t.market_id == market.market_id && t.side == side_option.side);
        switch(default_price_view){
            case 'best_available':
                if(order){ return MarketButtonHelpers.getTradeFromAvailableOrder(order, reversed_side_id) }
                if(o_side_order){ 
                    //Now lets reverse this order and get a trade for it
                    const reversed_order = MarketButtonHelpers.reverseBAOrder(o_side_order, market, side_id);
                    if(!reversed_order){ return undefined }
                    return MarketButtonHelpers.getTradeFromAvailableOrder(reversed_order, reversed_side_id)
                 }
                if(trade){ return trade }
                return undefined
            case 'last_trade':
                if(trade){ return trade }
                return undefined
            default: return undefined
        }
    },
    reverseBAOrder: (order:BestAvailableOrderProps, market:MarketProps, side_id:string):BestAvailableOrderProps | undefined => {
        const side_option = market.side_options.find(so => so.side != order.side);
        if(!side_option){ return undefined }
        return {
            ...order,
            side: side_option.side,
            side_id: side_id,
            odds: order.odds * -1,
            var_1: market.type == 'Spread' ? order.var_1 * -1 : order.var_1,
            probability: 1 - order.probability,
            title: '',
            open_amt: 0,
            called_amt: 0,
            potential_winnings: 0
        }
    },
    getTradeFromMarket: (market:MarketProps, event_id:string, event_type: 'match'|'tournament'|'team', side:string, side_type:string, side_id:string, reversed_side_id:string):TradeProps => {
        return {
            trade_id:'',
            event_id,
            event_type,
            trade_amt: 0,
            cumulative_amt: 0,
            latest:true,
            probability:0.5,
            odds: 100,
            create_datetime:'',
            last_update_datetime: '',
            market_id: market.market_id,
            var_1: 0,
            side_id,
            side_type:side_type as 'team'|'exotic'|'side'|'athlete',
            reversed_side_id,
            side,
            position_ids:[],
            market_type: 'FREE'
        }
    },
    getTradeFromAvailableOrder : (ba:BestAvailableOrderProps, reversed_side_id:string):TradeProps => {
        return {
            trade_id:'',
            event_id: ba.event_id,
            event_type: ba.event_type as 'tournament'|'team'|'match',
            cumulative_amt: 0,
            trade_amt: 0,
            create_datetime: '', last_update_datetime: '',
            market_id: ba.market_id,
            var_1: ba.var_1,
            side: ba.side,
            side_id: ba.side_id,
            draft_liquidity: ba.open_amt,
            reversed_side_id,
            probability: ba.probability,
            odds: ba.odds,
            side_type: ba.side_type as 'team'|'athlete'|'side',
            market_type: 'FOR_MONEY',
            latest: true,
            position_ids: []
        }
    },
    reverseTradeFromOrder: (order:BestAvailableOrderProps, market:MarketProps):TradeProps => {
        return {
            trade_id:'',
            side: order.side,
            side_id: order.side_id,
            reversed_side_id: '',
            market_id: order.market_id,
            event_id: order.event_id,
            event_type: order.event_type as 'team'|'tournament'|'match',
            odds: order.odds * -1,
            var_1: market.type == 'Spread' ? order.var_1 * -1 : order.var_1,
            probability: 1 - order.probability,
            side_type: order.side_type as 'athlete'|'team'|'side',
            market_type: 'FOR_MONEY',
            latest: true,
            trade_amt: 0, cumulative_amt:0,
            position_ids: [],
            last_update_datetime: '', create_datetime:''
        }
    },
    getOrderFromTrade: (trade:TradeProps, unit_size?:number, ba_order_id?:string):OrderProps => {
        let open_amt = 10
        if(unit_size){ open_amt = unit_size }
        let draft_liquidity = 0
        if(trade.draft_liquidity){ draft_liquidity = trade.draft_liquidity }
    
        return {
            order_id: '',
            player_id: '',
            be_type: 'market', //TODO - Make this dynamic,
            open_amt,
            called_amt: 0,
            buy_sell_ind: 'buy',
            rejections:0,
            commission_pct:0,
            available_for_parlays: false,
            event_id: trade.event_id,
            draft_liquidity,
            event_type: trade.event_type,
            side: trade.side as 'home'|'away'|'over'|'under'|'yes'|'no',
            side_type: trade.side_type as 'team' | 'side' | 'athlete',
            resolution_status: 'inprogress',
            side_id: trade.side_id,
            order_type: trade.limit_override ? 'limit': 'market',
            potential_winnings: 0,
            collar_pct: .01,
            positions: [],
            market_id: trade.market_id,
            title: '',
            probability: trade.probability,
            odds: trade.odds,
            var_1: trade.var_1,
            market_type: trade.market_type,
            position_ids: [],
            status: 'approved',
            expire_datetime: moment().add(1, 'hours'),
            reference_order_id:ba_order_id
        }
    }
}