import React from 'react';
import { View } from 'react-native';
import { view_styles } from '../constants/styles';
import type { TeamProps } from '../types';
import { Text } from '../Components';

type TeamCardProps = {
    team:TeamProps
}

const TeamCard = ({ team }:TeamCardProps) => {
    console.log(team)
    return (
        <View style={{ ...view_styles.section }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>{team.market_name} {team.name}</Text>
                </View>
            </View>
        </View>
    )
}

export default TeamCard