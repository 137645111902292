import { View, TouchableOpacity } from "react-native"
import { Icons, Text } from "../../Components"
import { view_styles } from "../../constants/styles"
import Colors from "../../constants/colors"
import type { PlayerSquareProps, SquareProps, SquaresCompetitionProps } from "../../types"
import React, { useState } from "react"

type BoardStatsProps = {
    squares_competition:SquaresCompetitionProps,
    squares:SquareProps[],
    player_squares:PlayerSquareProps[],
}

const BoardStats = ({ squares_competition, squares, player_squares}:BoardStatsProps) => {
    const [ expanded, setExpanded ] = useState(false);

    let total_payout = squares_competition.potential_winnings
    if(squares_competition.guaranteed_payout && squares_competition.guaranteed_payout > total_payout){ total_payout = squares_competition.guaranteed_payout }
    let iteration_payout = total_payout / squares_competition.payout_frequency
    let active_ps = player_squares.filter(ps => ps.status == 'active');
    const ownership_pct = active_ps.length / squares.length * 100

    return (
        <View style={{ ...view_styles.section, minWidth:350 }}>
                <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                    <Icons.PieIcon color={Colors.brand.midnight} size={18} />
                    <View style={{ flex:1, marginLeft:10 }}>
                        <Text theme="header">BOARD STATS</Text>
                        <Text style={{ marginTop: 4 }} theme="body_2">Current statistics from the squares board</Text>
                    </View>
                    <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} color={Colors.brand.midnight} size={8} />
                </TouchableOpacity>
                {expanded ?
                <View style={view_styles.section_body}>
                    <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{flex:1, marginRight:10}}>
                            <Text theme="header_2">Unbid Squares</Text>
                            <Text style={{ marginTop:3 }} theme="body_2">Percent of the board that has no bids</Text>
                        </View>
                        <Text theme="header_2">{(squares.length - active_ps.length).toFixed()} ({(100 - ownership_pct).toFixed(2)}%)</Text>
                    </View>
                    <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{flex:1, marginRight:10}}>
                            <Text theme="header_2">Current Total Payout</Text>
                            <Text style={{ marginTop:3 }} theme="body_2">The current total amount to be paid out to the winners</Text>
                        </View>
                        <Text size={16} color={Colors.utility.success} weight='bold'>${total_payout.toFixed(2)}</Text>
                    </View>
                    <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{flex:1, marginRight:10}}>
                            <Text theme="header_2">Current Iteration Payout</Text>
                            <Text style={{ marginTop:3 }} theme="body_2">The current amount to be paid out per quarter</Text>
                        </View>
                        <Text size={16} color={Colors.utility.success} weight='bold'>${iteration_payout.toFixed(2)}</Text>
                    </View>
                </View>
                :<></>}
            </View>
    )
}

export default BoardStats