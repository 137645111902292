
import { APIOverrides } from '../../ApiOverrides/index';
let WS_SVC_API = '';

export { SocketAPI }

const SocketAPI = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        WS_SVC_API = endpoints['WS_SVC_API'] as string;
    },
    socketConnect: () => {
        return new WebSocket(WS_SVC_API)
    }
}