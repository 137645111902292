import React from 'react';
import { Text as RNText, useWindowDimensions } from 'react-native';
import '../Font.css';
import Colors from '../constants/colors';
import { text_styles } from '../constants/styles';


export interface TextProps { 
    weight?:'bold'|'semibold'|'regular'|'light', 
    color?:string, 
    size?: number, 
    children:any, 
    selectable?:boolean,
    theme?:string,
    textAlign?:'left'|'center'|'right', 
    style?:any 
}

const STANDARD_SIZE = 414

const Text = ({ weight, color, size, children, theme, selectable, textAlign, style }: TextProps) => {
    const dimensions = useWindowDimensions();

    let rendered_size = size ?? 14
    let diff_from_normal = dimensions.width - STANDARD_SIZE
    if(diff_from_normal < 0){
        let pct_diff = Math.abs(diff_from_normal / STANDARD_SIZE)
        rendered_size = rendered_size * (1 - pct_diff)
    }

    const getFontFamily = (weight:string) => {
        switch(weight){
            case 'bold': return 'barlow-bold'
            case 'semibold': return 'barlow-semibold'
            case 'regular': return 'barlow-regular'
            case 'light': return 'barlow-light'
            default: return 'barlow-regular'
        }
    }

    const getAttributes = () => {
        let default_theme = { color:color??Colors.shades.black, fontSize: size??14, fontFamily:weight?getFontFamily(weight):'barlow-regular', textAlign: textAlign??'left'  }
        switch(theme){
            case 'header': return text_styles.header
            case 'header_2': return text_styles.header_2
            case 'error_header_2': return text_styles.error_header_2
            case 'body': return text_styles.body
            case 'error_body': return text_styles.error_body
            case 'body_2': return text_styles.body_2
            case 'error_body_2': return text_styles.error_body_2

            default: return default_theme
        }
    }
    const new_style = getAttributes()
    return (
        <RNText
            style={{ ...new_style, ...style }}
            selectable={selectable}
            >
            {children}
        </RNText>
    )
}

export default Text