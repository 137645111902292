import React, { useState } from 'react';
import { View, Image } from 'react-native';
import type { PodcastProps } from '../../../types';
import { view_styles } from '../../../constants/styles';
import { Button, Text } from '../../../Components';
import Colors from '../../../constants/colors';
import { SocialPodcastHelpers } from '../../api';
import { PodcastApi } from '../api';

type ClaimPodcastModalProps = {
    podcast:PodcastProps,
    width:number,
    onClose:() => void,
    onPodcastUpdate:(pod:PodcastProps) => void
}

const ClaimPodcastModal = ({ podcast, onClose, onPodcastUpdate }:ClaimPodcastModalProps) => {
    const [ loading, setLoading ] = useState(false);

    const handleClaimPodcast = async() => {
        setLoading(true);
        const updated_pod = await PodcastApi.claimPodcast(podcast.podcast_id);
        if(!updated_pod){ return alert('Unable to process claim, please try again later.') }
        onPodcastUpdate(updated_pod)
        setLoading(false);
    }

    return (
        <View style={{ ...view_styles.section }}>
            <View style={{ ...view_styles.section_header }}>
                <Image
                    source={{ uri: SocialPodcastHelpers.getPodcastImage(podcast) }}
                    style={{ width:50, height:50, borderRadius:4 }}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>Is this your podcast?</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Claim this podcast and get it prioritized over other similar podcasts!</Text>
                </View>
            </View>
            <View style={{ ...view_styles.section_body }}>
                <Text style={{ marginBottom:10 }} theme='header'>Benefits of claiming podcast</Text>
                <View style={{ ...view_styles.body_row, alignItems:'flex-start' }}>
                    <Text size={40} weight='bold'>*</Text>
                    <Text style={{ marginLeft:10 }} theme='header_2'>PodQuest, BettorEdge's proprietary podcast recommendation engine will suggest your podcast to interested parties based on their betting history!</Text>
                </View>
                <View style={{ ...view_styles.body_row, alignItems:'flex-start', paddingTop:0 }}>
                    <Text size={40} weight='bold'>*</Text>
                    <Text style={{ marginLeft:10 }} theme='header_2'>Adds the podcast to your profile for people to see your bets, takes, and podcasts all in one place!</Text>
                </View>
                <View style={{ ...view_styles.body_row, alignItems:'flex-start', paddingTop:0 }}>
                    <Text size={40} weight='bold'>*</Text>
                    <Text style={{ marginLeft:10 }} theme='header_2'>Additionally, it gets you a referral code allowing you to make money on users sent to BettorEdge!</Text>
                </View>
            </View>
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title='No Thanks'
                    style={{flex:1}}
                    padding={15}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
                 <Button
                    title='Request Claim'
                    style={{flex:2, marginLeft:5, opacity:loading?0.5:1}}
                    padding={15}
                    disabled={loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => handleClaimPodcast()}
                />
            </View>
        </View>
    )
}

export default ClaimPodcastModal
