import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { MarketMakerApi, MarketMaketHelpers } from './api';
import type { AthleteProps, BestAvailableResponseProps, EventProps, ExternalPriceProps, FundingRequestProps, FundOrderProps, FundOwnershipProps, FundProps, LeagueProps, MarketProps, MatchProps, OrderProps, PublicPlayerProps, TournamentProps, TradeProps } from '../types';
//import { view_styles } from '../constants/styles';
import Colors from '../constants/colors';
import ContestSelector from './components/ContestSelector';
import Dashboard from './components/Dashboard';
import MarketSelector from './components/MarketSelector';
import SetMarketForm from './components/SetMarketForm';
import FundSelector from './components/FundSelector';
import { usePlayerLocation } from '../LocationTracker/LocationStatus';
import ManageFundForm from './components/ManageFundForm';
import FundingRequestList from './components/FundingRequestList';
import FundOwnershipList from './components/FundOwnershipList';
import SocketManager from '../Socket';
import OrderForm from './components/OrderForm';

type MarketMakerProps = {
    player_id:string,
    access_token:string,
    distinct_id:string
}

const MarketMaker = ({ player_id, access_token, distinct_id }:MarketMakerProps) => {
    const [ ba_socket_data, setBASocketData ] = useState<BestAvailableResponseProps | undefined>(undefined);
    const [ socket_fund, setSocketFund ] = useState<{ fund:FundProps, orders:OrderProps[] } | undefined>(undefined);
    const [ module_data, setModuleData ] = useState<{
        loaded:boolean,
        loading:boolean,
        funds:FundProps[],
        markets:MarketProps[],
        leagues:LeagueProps[],
        events:EventProps[],
        tournaments:TournamentProps[],
        matches:MatchProps[],
    }>({
        loaded: false,
        loading:false,
        funds:[],
        leagues:[],
        markets:[],
        events:[],
        tournaments:[],
        matches:[]
    });
    const { loaded, leagues, events, markets, funds } = module_data;

    const [ editing_fund, setEditingFund ] = useState<FundProps | undefined>(undefined);
    const [ draft_order, setDraftOrder ] = useState<{
        visible:boolean,
        order?:OrderProps,
        available?:number
    }>({
        visible:false
    })
    const [ set_market_form_data, setMarketFormData ] = useState<{
        set_market_form_key:string,
        set_market_orders:OrderProps[],
        set_market_market?:MarketProps,
        set_market_form_visible:boolean,
        set_market_prices: ExternalPriceProps[]
    }>({
        set_market_form_key: '',
        set_market_form_visible: false,
        set_market_orders:[],
        set_market_prices:[]
    });
    const { set_market_form_key, set_market_orders, set_market_form_visible, set_market_market, set_market_prices } = set_market_form_data;
    const [ market_data, setMarketData ] = useState<{
        best_available:BestAvailableResponseProps
    }>({
        best_available: { events: [], tournaments: [], matches: [] }
    });
    const { best_available } = market_data;
    const [ dash_data, setDashData ] = useState<{
        fund?:FundProps,
        loaded:boolean,
        loading:boolean,
        external_name?:string,
        players:PublicPlayerProps[],
        athletes:AthleteProps[],
        funding_requests:FundingRequestProps[],
        fund_ownerships:FundOwnershipProps[],
        selected_markets:MarketProps[],
        orders:OrderProps[],
        fund_orders:FundOrderProps[],
        selected_events:EventProps[],
        trades:TradeProps[],
        external_prices:ExternalPriceProps[],
        selected_tournaments:TournamentProps[],
        selected_matches:MatchProps[]
    }>({
        loaded:false,
        loading:false,
        external_name: 'Consensus',
        players:[],
        funding_requests:[],
        athletes:[],
        fund_ownerships:[],
        fund_orders:[],
        selected_markets:[],
        orders:[],
        trades:[],
        external_prices: [],
        selected_events:[],
        selected_matches:[],
        selected_tournaments:[]
    });
    const { fund, selected_events, athletes, players, fund_orders, fund_ownerships, funding_requests, external_name, selected_matches, selected_tournaments, selected_markets, trades, external_prices, orders } = dash_data;
    const { available_orders } = MarketMaketHelpers.getMarketDataFromBestAvailable(best_available);
    const selectable_markets = MarketMaketHelpers.getSelectableMarkets(selected_events, selected_tournaments, selected_matches, markets);

    const { location, getLocation } = usePlayerLocation({ player_id });
    useEffect(() => {
        MarketMakerApi.setEnvironment();
        if(!player_id){ return }
        loadModule();
        getLocation()
    },[player_id]);

    useEffect(() => {
        if(!fund?.fund_id || !loaded){ return }
        getDashData(fund.fund_id);
    },[fund]);

    useEffect(() => {
        if(!ba_socket_data){ return }
        //Reconcile and update ba_socket data!
        setMarketData({
            ...market_data,
            best_available: MarketMaketHelpers.updateBestAvailable(best_available, ba_socket_data)
        })
    },[ba_socket_data]);

    useEffect(() => {
        if(!socket_fund){ return }
        if(socket_fund.fund.fund_id != fund?.fund_id){ return }
        setDashData({
            ...dash_data,
            orders: orders.filter(o => socket_fund.orders.find(no => no.order_id == o.order_id)).concat(socket_fund.orders),
            fund: socket_fund.fund
        })
    },[socket_fund])

    const loadModule = async() => {
        setModuleData({ ...module_data, loading:true });
        setDashData({ ...dash_data, loading:true });
        const bulk_funds = await MarketMakerApi.getMyActiveFunds(0);
        const evs = await MarketMakerApi.getActiveEvents();
        const mks = await MarketMakerApi.getMarkets();
        const ls = await MarketMakerApi.getLeagues();
        setModuleData({
            ...module_data,
            events:evs,
            funds:bulk_funds,
            loaded:true,
            loading:false,
            markets:mks,
            leagues:ls,
        });
        if(bulk_funds[0]){ setDashData({ ...dash_data, fund: bulk_funds[0] }) }
    }

    const getDashData = async(fund_id:string) => {
        //Now load Dash data
        console.log('1')
        const ba = await MarketMakerApi.getBestAvailableOrders();
        console.log('2')

        const fund_response = await MarketMakerApi.getFundById(fund_id);
        console.log('3')

        if(!fund_response){
            alert('Failed to get details for selected fund');
            return setDashData({ ...dash_data, loading:false });
        }
        const fr_requests = await MarketMakerApi.getFundingRequestsByFundId(fund_id);
        console.log('4')

        const my_action_data = await getDataForMyAction(fund_response.orders, markets);
        console.log('5')

        //Based on my action -- lets auto select events and grab all that data!!
        let player_ids = [ ...new Set(fr_requests.map(r => r.player_id).concat(fund_response.fund_ownerships.map(o => o.player_id)).map(id => id))]
        let plays = await MarketMakerApi.getPlayersByIds(player_ids)
        console.log('6')

        setMarketData({
            ...market_data,
            best_available: ba
        });
        setDashData({
            ...dash_data,
            loaded:true,
            loading:false,
            funding_requests:fr_requests,
            players:plays,
            fund_ownerships: fund_response.fund_ownerships,
            orders:fund_response.orders,
            fund_orders: fund_response.fund_orders,
            selected_events: my_action_data.events,
            trades: my_action_data.trades,
            external_prices: my_action_data.prices,
            selected_markets: my_action_data.markets
        })
    }


    const getDataForMyAction = async(orders:OrderProps[], markets:MarketProps[]) => {
        const ev_ids = orders.filter(o => o.event_type == 'team').map(o => o.event_id);
        const mks = markets.filter(m => orders.map(o => o.market_id.toString()).includes(m.market_id.toString()))
        const evs = await MarketMakerApi.getEventsByEventIds(ev_ids);
        const ts = await MarketMakerApi.getLatestTradesByEvents('team', ev_ids);
        const ps = await MarketMakerApi.getPricesByEvents('team', ev_ids);
        return { events:evs, trades:ts, markets:mks, prices: ps }
    }

    const handleSelectEvent = async(e:EventProps) => {
        if(dash_data.loading){ return }//No mashy
        setDashData({ ...dash_data, loading: true });
        const prices = await MarketMakerApi.getPricesByEvent(e.event_id, 'team');
        let athlete_ids = [ ...new Set(prices.filter(p => p.participant_type == 'athlete').map(p => p.participant_id)) ]
        let aths = await MarketMakerApi.getAthletesByIds(athlete_ids)
        const tds = await MarketMakerApi.getLatestTradesByEvents('team', [e.event_id]);
        setDashData({
            ...dash_data,
            loading:false,
            athletes:aths,
            selected_events: selected_events.concat(e),
            trades: trades.filter(t => t.event_id != e.event_id).concat(tds),
            external_prices: external_prices.filter(p => p.event_id != e.event_id).concat(prices)
        })
    }

    const handleDeselectEvent = (e:EventProps) => {
        if(dash_data.loading){ return }//No mashy
        setDashData({
            ...dash_data,
            loading:false,
            selected_events: selected_events.filter(se => se.event_id != e.event_id),
            trades: trades.filter(t => t.event_id != e.event_id)
        })
    }


    return (
        <View style={{ flex:1, backgroundColor:Colors.shades.white }}>
            <View style={{ flexDirection:'row' }}>
                <View>
                    <FundSelector
                        funds={funds}
                        maxHeight={300}
                        selected_fund_id={dash_data.fund?.fund_id}
                        onEditFund={(f) => {
                            setEditingFund(f)
                        }}
                        onCreateFund={(f) => {
                            setDashData({
                                ...dash_data,
                                fund:f
                            })
                            setEditingFund(f);
                        }}
                        onSelectFund={(f) => {
                            setDashData({
                                ...dash_data,
                                fund:f
                            })
                        }}
                    />
                    {fund ?
                    <FundOwnershipList
                        fund_ownerships={fund_ownerships}
                        players={players}
                    />
                    :<></>}
                    {fund?
                    <FundingRequestList
                        funding_requests={funding_requests}
                        players={players}
                        onApproveRequest={async(fr_id) => {
                            const app_response = await MarketMakerApi.approveRequest(fr_id)
                            if(!app_response){ return alert('Unable to process') }
                            setDashData({
                                ...dash_data,
                                fund: app_response.fund,
                                fund_ownerships: app_response.fund_ownerships,
                                funding_requests: funding_requests.filter(fr => fr.funding_request_id != fr_id)
                            })
                        }}
                        onDeclineRequest={async(fr_id) => {
                            const app_response = await MarketMakerApi.declineRequest(fr_id)
                            if(!app_response){ return alert('Unable to process') }
                            setDashData({
                                ...dash_data,
                                funding_requests: funding_requests.filter(fr => fr.funding_request_id != fr_id)
                            })
                        }} 
                    />
                    :<></>}
                    <ContestSelector 
                        maxHeight={300}
                        leagues={leagues.filter(l => l.status == 'active')}
                        events={events}
                        onSelectEvent={(e) => handleSelectEvent(e)}
                        onDeselectEvent={(e) => handleDeselectEvent(e)}
                        onSelectMatch={(m) => console.log(m)}
                        onSelectTournament={(t) => console.log(t)}
                        tournaments={[]}
                        matches={[]}
                        selected_events={selected_events.map(e => e.event_id)}
                        selected_matches={[]}
                        selected_tournaments={[]}
                    />
                    <MarketSelector
                        maxHeight={300}
                        markets={selectable_markets.sort((a,b) => parseInt(a.market_id) - parseInt(b.market_id))}
                        onSelectMarket={(m) => setDashData({ ...dash_data, selected_markets: dash_data.selected_markets.concat(m) })}
                        onDeselectMarket={(m) => setDashData({ ...dash_data, selected_markets: dash_data.selected_markets.filter(se => se.market_id != m.market_id) })}
                        selected_markets={selected_markets.map(m => m.market_id)}
                    />
                </View>
                <View style={{ flexGrow:1, flexShrink:1}}>
                    <Dashboard
                        loaded={dash_data.loaded}
                        leagues={leagues}
                        fund={fund}
                        athletes={athletes}
                        events={selected_events}
                        tournaments={selected_tournaments}
                        matches={selected_matches}
                        markets={selected_markets}
                        best_available_orders={available_orders}
                        trades={trades}
                        prices={external_prices}
                        external_name={external_name}
                        orders={orders}
                        fund_orders={fund_orders}
                        onOrder={async(o, avail) => {
                            console.log(o)
                            setDraftOrder({
                                visible:true,
                                order: o,
                                available:avail
                            })
                        }}
                        onDepositFund={async(amount) => {
                            if(!fund){ return alert('Unable to process') }
                            const dep_resp = await MarketMakerApi.depositRequest(fund.fund_id, amount);
                            console.log(dep_resp)
                        }}
                        onLayoffCapital={async(amount) => {
                            if(!fund){ return alert('Unable to process') }
                            const layoff_resp = await MarketMakerApi.layoffCapital(fund.fund_id, amount);
                            if(!layoff_resp){ return alert('Unable to process') }
                            setDashData({
                                ...dash_data,
                                fund: layoff_resp.fund,
                                orders: orders.filter(o => !layoff_resp.orders.find(newo => newo.order_id == o.order_id)).concat(layoff_resp.orders)
                            })
                        }}
                        onWorkCapital={async(amount) => {
                            if(!fund){ return alert('Unable to process') }
                            const work_response = await MarketMakerApi.workCapital(fund.fund_id, amount);
                            if(!work_response){ return alert('Unable to process') }
                            setDashData({
                                ...dash_data,
                                fund: work_response.fund,
                                orders: work_response.orders
                            })
                        }}
                        onClearMarket={async(order_ids) => {
                            const new_orders = await MarketMakerApi.cancelOrders(order_ids)
                            setDashData({
                                ...dash_data,
                                orders: orders.filter(o => !new_orders.find(no => no.order_id == o.order_id)).concat(new_orders)
                            })
                        }}
                        onSetMarket={(orders, market, prices) => {
                            if(!location.region || !location.coordinates){ return alert('Location could not be verified to process orders') }
                            orders = orders.map(o => {
                                return { ...o, location: location.coordinates, region: location.region }
                            })
                            setMarketFormData({ 
                                ...set_market_form_data,
                                set_market_form_visible:true,
                                set_market_market:market,
                                set_market_orders: orders,
                                set_market_form_key: `${Math.random()}`,
                                set_market_prices: prices
                            })
                        }}
                    />
                    {fund && draft_order.order && draft_order.visible ?
                    <View style={{ position:'absolute', bottom:0, left:0, right:0, top:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                        <OrderForm
                            fund={fund}
                            order={draft_order.order}
                            available={draft_order.available}
                            onClose={() => setDraftOrder({ visible:false })}
                            onSubmit={async(order) => {
                                if(!fund){ return alert('Unable to process') }
                                if(!location.region || !location.coordinates){ return alert('Location could not be verified to process orders') }
                                const new_orders = await MarketMakerApi.addOrdersToFund(fund.fund_id, [{ ...order, location: location.coordinates, region: location.region }]);
                                setDashData({
                                    ...dash_data,
                                    fund_orders: fund_orders.concat(new_orders.fund_orders),
                                    orders:orders.concat(new_orders.orders)
                                })
                                setDraftOrder({ visible:false });
                            }}

                        />
                    </View>
                    :<></>}
                    {fund && set_market_form_visible && set_market_market ?
                    <View style={{ position:'absolute', bottom:0, left:0, right:0, top:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                        <SetMarketForm
                            fund={fund}
                            form_key={set_market_form_key}
                            market={set_market_market}
                            orders={set_market_orders}
                            prices={set_market_prices}
                            spread_pct='5'
                            onSetMarket={async(fo) => {
                                if(!fund){ return alert('Unable to process') }
                                const new_os = await MarketMakerApi.addOrdersToFund(fund.fund_id, fo)
                                if(new_os.orders.length == 0){ return alert('Unable to process') }
                                setDashData({
                                    ...dash_data,
                                    orders: orders.concat(new_os.orders),
                                    fund_orders: fund_orders.concat(new_os.fund_orders)
                                })
                                setMarketFormData({ set_market_form_visible: false, set_market_orders:[], set_market_form_key:'', set_market_prices:[] })
                            }}
                            onClose={() => setMarketFormData({ set_market_form_visible: false, set_market_orders:[], set_market_form_key:'', set_market_prices:[] })}
                        />
                    </View>
                    :<></>}
                    {editing_fund ?
                    <View style={{ position:'absolute', bottom:0, left:0, right:0, top:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                        <ManageFundForm
                            fund={editing_fund}
                            onClose={() => setEditingFund(undefined)}
                            onFundUpdate={(f) => {
                                setEditingFund(undefined);
                                setDashData({
                                    ...dash_data,
                                    fund: f
                                })
                            }}
                        />
                    </View>
                    :<></>}
                </View>
            </View>
            <SocketManager
                subscribed_events={['V1_LOAD_BEST_AVAILABLE', 'V1_UPDATE_FUND']}
                access_token={access_token}
                distinct_id={distinct_id}
                onConnect={() => console.log('connected!')}
                onDisconnect={() => console.log('Disconnected')}
                onSocketEvent={(ev) => {
                    switch(ev.type){
                        case 'V1_LOAD_BEST_AVAILABLE':
                            return setBASocketData(ev.data);
                        case 'V1_UPDATE_FUND':
                            return setSocketFund(ev.data)
                        default: return
                    }
                }}
            />
        </View>
    )
}

export default MarketMaker