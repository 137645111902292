import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, ScrollView } from 'react-native';
import type { CompanyProps, CompetitionPayoutTypeProps, CompetitionProps, CompetitionResultTypeProps, CompetitionSeasonPlayerProps, CompetitionSeasonProps, CompetitionSeasonResultProps, CompetitionTypeProps, MyPlayerProps, PlayerBalanceProps, PublicPlayerProps } from '../types';
import Colors from '../constants/colors';
import SeasonHeader from './components/SeasonHeader';
import { SeasonApi } from './api';
import CompetitionsSection from './components/CompetitionsSection';
import SeasonSettingsSection from './components/SeasonSettingsSection';
import EnterSeasonPrompt from './components/EnterSeasonPrompt';
import SeasonLeaderboard from './components/SeasonLeaderboard';

type SeasonModuleProps = {
    competition_season_id:string,
    onShareSeason: (competition_season:CompetitionSeasonProps) => void,
    player_id?:string,
    onRequestAuthenticate: (auth_strategy_id?:string) => void,
    onCompetitionSelect: (c:CompetitionProps) => void
}

const SeasonModule = ({ competition_season_id, player_id, onShareSeason, onRequestAuthenticate, onCompetitionSelect }:SeasonModuleProps) => {
    const [ module_size, setModuleSize ] = useState({ width:0, height:0 })
    const [ show_leaderboard, setShowLeaderboard ] = useState(false);
    const [ show_enter, setShowEnter ] = useState(false);
    const [ my_data, setMyData ] = useState<{
        my_loading: boolean,
        player?:MyPlayerProps,
        player_balance?:PlayerBalanceProps
    }>({
        my_loading: false
    });
    const [ module_data, setData ] = useState<{
        loading:boolean,
        action_loading:boolean,
        competition_season?:CompetitionSeasonProps,
        competitions:CompetitionProps[],
        offset:number,
        competition_types:CompetitionTypeProps[],
        company?:CompanyProps,
        admin?:PublicPlayerProps,
        players:PublicPlayerProps[],
        competition_season_players:CompetitionSeasonPlayerProps[],
        competition_season_results:CompetitionSeasonResultProps[],
        competition_result_types:CompetitionResultTypeProps[],
        competition_payout_types:CompetitionPayoutTypeProps[]
    }>({
        loading:false,
        action_loading:false,
        competitions: [],
        offset: 0,
        competition_season_players:[],
        competition_season_results: [],
        competition_types:[],
        players: [],
        competition_result_types:[],
        competition_payout_types:[]
    });
    const { action_loading, competition_season, competitions, competition_season_players, competition_season_results, competition_types, competition_payout_types, competition_result_types } = module_data;
    const { player, player_balance } = my_data;
    useEffect(() => {
        if(!competition_season_id){ return }
        SeasonApi.setEnvironment();
        getSeasonData(competition_season_id)
    },[competition_season_id])

    useEffect(() => {
        getMyDetails();
    },[player_id])

    const getMyDetails = async() => {
        if(!player_id){ return setMyData({ ...my_data, player:undefined, player_balance: undefined }) }
        setMyData({ ...my_data, my_loading: true })
        let me = await SeasonApi.getMyDetails();
        let pb = await SeasonApi.getMyBalance();
        setMyData({
            ...my_data,
            my_loading: false,
            player: me,
            player_balance: pb
        })
    }

    const getSeasonData = async(id:string) => {
        setData({ ...module_data, loading:true })
        const season = await SeasonApi.getSeasonById(id);
        const comps = await SeasonApi.getCompetitionsBySeasonId(id)
        const opts = await SeasonApi.getCompetitionOptions();
        const season_player_resp = await SeasonApi.getSeasonPlayersBySeasonId(id, 0);
        const players = await SeasonApi.getPlayersByPlayerIds([season.admin_id])
        let cmpny:CompanyProps | undefined = undefined
        if(season.company_id){
            const companies = await SeasonApi.getCompaniesByIds([season.company_id])
            cmpny = companies[0]
        }
        setData({
            ...module_data,
            loading:false,
            competition_season: season,
            competitions:comps,
            admin: players[0],
            company: cmpny,
            competition_payout_types: opts.competition_payout_types,
            competition_result_types: opts.competition_result_types,
            competition_types: opts.competition_types,
            competition_season_players: season_player_resp.competition_season_players,
            competition_season_results: season_player_resp.competition_season_results
        })
    }

    const handleJoin = async() => {
        if(action_loading){ return } //No mashy
        if(!player_id){ return onRequestAuthenticate() }
        setData({ ...module_data, action_loading: true });
        const resp = await SeasonApi.joinSeason(competition_season_id);
        setData({
            ...module_data,
            action_loading: false,
            competition_season: resp.competition_season,
            competition_season_players: competition_season_players.filter(cp => cp.competition_season_player_id != resp.competition_season_player.competition_season_player_id).concat(resp.competition_season_player),
            competition_season_results: competition_season_results.filter(cr => cr.competition_season_result_id != resp.competition_season_result.competition_season_result_id).concat(resp.competition_season_result),
            competitions: competitions.filter(c => !resp.competitions.find(nc => nc.competition_id == c.competition_id)).concat(resp.competitions)
        })
    }

    const handleLeave = async(competition_season_player_id:string) => {
        if(action_loading){ return }
        if(!player_id){ return onRequestAuthenticate() }
        setData({ ...module_data, action_loading: true });
        const resp = await SeasonApi.leaveSeason(competition_season_player_id);
        setData({
            ...module_data,
            action_loading: false,
            competition_season: resp.competition_season,
            competition_season_players: competition_season_players.filter(cp => cp.competition_season_player_id != resp.competition_season_player.competition_season_player_id),
            competition_season_results: competition_season_results.filter(cr => cr.competition_season_result_id != resp.competition_season_result.competition_season_result_id).concat(resp.competition_season_result),
            competitions: competitions.filter(c => !resp.competitions.find(nc => nc.competition_id == c.competition_id)).concat(resp.competitions)
        })
    }

    if(!competition_season){
        return (
            <View style={{ flex:1 }}>
                <ActivityIndicator
                    size='large'
                    style={{ padding:20, alignSelf:'center' }}
                    color={Colors.brand.midnight}
                />
            </View>
        )
    }

    const selected_season_type = competition_types.find(ct => ct.competition_type_id == competition_season.competition_type_id);
    const selected_season_result_type = competition_result_types.find(crt => crt.competition_result_type_id == competition_season.competition_result_type_id);
    const authenticated_season_player = competition_season_players.find(csp => csp.player_id == player_id);
    const authenticated_season_result = competition_season_results.find(csp => csp.player_id == player_id);

    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1 }} onLayout={(ev) => {
                const { width, height } = ev.nativeEvent.layout;
                setModuleSize({ width, height });
            }}>
                <SeasonHeader
                    competition_season={competition_season}
                    competitions={competitions}
                    action_loading={action_loading}
                    authenticated_season_player={authenticated_season_player}
                    onShareSeason={onShareSeason}
                    onJoin={() => setShowEnter(true)}
                    onLeave={(competition_season_player_id) => handleLeave(competition_season_player_id)}
                />
                {selected_season_type ?
                <SeasonLeaderboard
                    authenticated_player={player}
                    authenticated_season_player={authenticated_season_player}
                    authenticated_season_result={authenticated_season_result}
                    width={module_size.width}
                    onLeaderSelect={(p) => console.log(p)}
                    view_mode='short'
                    height={module_size.height}
                    competition_season={competition_season}
                    competition_type={selected_season_type}
                    onSeeFull={() => setShowLeaderboard(true)}
                />
                :<></>}
                {selected_season_type && selected_season_result_type ?
                <SeasonSettingsSection
                    competition_season={competition_season}
                    competition_result_type={selected_season_result_type}
                    competition_type={selected_season_type}
                    competition_payouts={competition_payout_types}
                    width={module_size.width}
                />
                :<></>}
                {selected_season_type && selected_season_result_type ?
                <CompetitionsSection
                    competitions={competitions}
                    competition_result_type={selected_season_result_type}
                    competition_type={selected_season_type}
                    onCompetitionSelect={(c) => onCompetitionSelect(c)}
                    width={module_size.width}
                />
                :<></>}
            </ScrollView>
            {show_enter && selected_season_result_type && selected_season_type ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
                <EnterSeasonPrompt
                    width={module_size.width}
                    competition_season={competition_season}
                    competition_result_type={selected_season_result_type}
                    competition_type={selected_season_type}
                    player_balance={player_balance}
                    player={player}
                    onEnter={(promo) => {
                        console.log(promo)
                        handleJoin()
                        setShowEnter(false);
                    }}
                    onCancel={() => setShowEnter(false)}
                    onVerify={() => console.log('VERIFY!')}
                />
            </View>
            :<></>}
            {show_leaderboard && selected_season_type ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded }}>
                <SeasonLeaderboard
                    authenticated_player={player}
                    authenticated_season_player={authenticated_season_player}
                    authenticated_season_result={authenticated_season_result}
                    width={module_size.width}
                    onLeaderSelect={(p) => console.log(p)}
                    view_mode='full'
                    height={module_size.height}
                    competition_season={competition_season}
                    competition_type={selected_season_type}
                    onSeeFull={() => console.log('See full')}
                    onClose={() => setShowLeaderboard(false)}
                />
            </View>
            :<></>}
        </View>
    )
}

export default SeasonModule