import React, { useEffect, useState } from "react"
import { FlatList, Image, ScrollView, TouchableOpacity, View, ActivityIndicator } from "react-native"
import type { CompetitionProps, CompetitionRecordProps, CompetitionResultProps, CompetitionTypeProps, PublicPlayerProps } from "../../types"
import { CompetitionApi, CompetitionHelpers, CompetitionResultApi } from "../api"
import { Button, Icons, Text } from "../../Components"
import Colors from "../../constants/colors"
import { view_styles } from "../../constants/styles"

type CompetitionLeaderboardProps = {
    competition:CompetitionProps,
    competition_type:CompetitionTypeProps,
    viewer_id?:string,
    height: number,
    width:number,
    view_mode: 'short'|'full',
    onClose?:() => void,
    onSeeFull: () => void,
    onLeaderSelect:(player:PublicPlayerProps) => void
}

const CompetitionLeaderboard = ({ competition, competition_type, width, height, viewer_id, view_mode, onSeeFull, onClose, onLeaderSelect }:CompetitionLeaderboardProps) => {
    const [ expanded, setExpanded ] = useState(false);
    const [ data, setData ] = useState<{
        loading:boolean,
        competition_results:CompetitionResultProps[],
        competition_records:CompetitionRecordProps[],
        players:PublicPlayerProps[],
        offset:number
    }>({
        loading:false,
        competition_results:[],
        competition_records:[],
        players:[],
        offset:0
    })

    const { loading, competition_results, competition_records, players, offset } = data
    let results = [ ...competition_results ];
    
    if(view_mode == 'short'){ results = results.filter((r,i) => r ? i < 3 : i < 3) }

    let viewer = players.find(p => p.player_id == viewer_id);
    let viewer_result = competition_results.find(r => r.player_id == viewer_id);
    let viewer_record = competition_records.find(r => r.player_id == viewer_id);

    const cl = competition?.market_type == 'FOR_MONEY' ? '$' : 'E'

    useEffect(() => {
        if(!competition){ return }
        getLeaderboardFromServer(competition.competition_id, 0)
    },[competition])

    const getLeaderboardFromServer = async(competition_id:string, offset:number) => {
        setData({ ...data, loading:true })
        const result_response = await CompetitionResultApi.getResultsByCompetition(competition_id, offset)
        let player_ids = result_response.competition_results.map(cr => cr.player_id)
        let players:PublicPlayerProps[] = []
        if(player_ids.length > 0){ players = await CompetitionApi.getPlayersByPlayerIds(player_ids) }
        setData({
            ...data,
            players,
            competition_records: result_response.competition_records,
            competition_results: result_response.competition_results,
            offset,

        })
    }

    const renderResults = (data: {item:CompetitionResultProps, index:number}) => {
        let player:PublicPlayerProps | undefined = players.find(p => p.player_id == data.item.player_id)
        let record = competition_records.find(r => r.player_id == data.item.player_id)
        if(!player || !record){ return <></> }

        const is_pacer = competition.pacer_id == data.item.player_id ? true : false
        return (
            <TouchableOpacity 
                onPress={() => onLeaderSelect(player)}
                style={{ flexDirection:'row', margin:5, alignItems:'center', borderRadius:22, backgroundColor:is_pacer?Colors.highlights.highlight400Faded:Colors.shades.white, padding:8, paddingLeft:15, paddingRight:15}}>
                {is_pacer ?
                <View nativeID="pacer" style={{}}>
                    <Icons.PacerCarIcon size={28}/>
                </View>
                :
                <View nativeID="place" style={{ padding:5 }}>
                    <Text size={12} color={Colors.brand.midnight} weight="bold">{CompetitionHelpers.formatPlace(data.item.place)}</Text>
                </View>
                }
                <View nativeID="image" style={{ paddingLeft:10, paddingRight:10 }}>
                    <Image
                        source={{ uri: player.profile_pic }}
                        style={{ height:30, width:30, borderRadius:100 }}
                        resizeMode="cover"
                    />
                </View>
                <View nativeID="details" style={{ flex:1 }}>
                    <Text size={12} color={Colors.brand.midnight} weight='semibold'>{player.username}</Text>
                    {competition_type?.type == 'wager' ?
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{record.earnings.toFixed(2)} Earned ({record.remaining_stakes.toFixed(2)} at risk)</Text>
                    :
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{record.wins} - {record.losses} - {record.draws} ({record.remaining_picks} Remaining)</Text>
                    }
                </View>
                <View nativeID="winnings">
                    <Text size={12} color={Colors.brand.slate} weight='regular'>{cl}{data.item.winnings.toFixed(2)}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ ...view_styles.section, margin:view_mode == 'full'?0:10, minWidth:350, height:view_mode == 'full'?height:undefined, width: view_mode == 'full' ? width: undefined }}>
            {view_mode == 'short' ?
            <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                <Icons.PodiumIcon size={18} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header">COMPETITION LEADERBOARD</Text>
                    <Text style={{ marginTop: 4 }} theme="body_2">Leaderboard showing current standings</Text>
                </View>
                <Icons.ChevronIcon direction={expanded?'up':'down'} color={Colors.brand.midnight} size={8} />
            </TouchableOpacity>
            :<></>}
            {expanded || view_mode == 'full' ?
            <View style={{ ...view_styles.section_body }}>
            {viewer_record && viewer_result && viewer ?
            <View>
                 <View style={{ backgroundColor:Colors.brand.midnight, padding:10, marginBottom:10, borderTopRightRadius:8, borderTopLeftRadius:8 }}>
                    <Text size={14} color={Colors.shades.white} weight='regular'>MY RESULTS</Text>
                    <TouchableOpacity style={{ flexDirection:'row', marginTop:10, marginBottom:10, alignItems:'center', borderRadius:22, backgroundColor:Colors.shades.white, padding:8, paddingLeft:15, paddingRight:15}}>
                        <View nativeID="place" style={{ padding:5 }}>
                            <Text size={12} color={Colors.brand.midnight} weight="bold">{CompetitionHelpers.formatPlace(viewer_result.place)}</Text>
                        </View>
                        <View nativeID="image" style={{ paddingLeft:10, paddingRight:10 }}>
                            <Image
                                source={{ uri: viewer.profile_pic }}
                                style={{ height:30, width:30, borderRadius:100 }}
                                resizeMode="cover"
                            />
                        </View>
                        <View nativeID="details" style={{ flex:1 }}>
                            <Text size={12} color={Colors.brand.midnight} weight='semibold'>{viewer.username}</Text>
                            {competition_type?.type == 'wager' ?
                                <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{viewer_record.earnings.toFixed(2)} Earned ({viewer_record.remaining_stakes.toFixed(2)} at risk)</Text>
                                :
                                <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{viewer_record.wins} - {viewer_record.losses} - {viewer_record.draws} ({viewer_record.remaining_picks} Remaining)</Text>
                            }
                        </View>
                        <View nativeID="winnings">
                            <Text size={12} color={Colors.brand.slate} weight='regular'>{cl}{viewer_result.winnings.toFixed(2)}</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
            :<></>}
            <ScrollView style={{ backgroundColor:Colors.shades.shade100, borderBottomRightRadius:8, borderBottomLeftRadius:8 }}>
                {loading ?
                <ActivityIndicator style={{ alignSelf:'center', padding:10 }} size='large' color={Colors.brand.midnight} /> 
                :<></>}
                <FlatList
                    data={results}
                    renderItem={renderResults}
                    keyExtractor={(item, index) => item.competition_result_id ? item.competition_result_id.toString() : index.toString()}
                />
                
            </ScrollView>
            {view_mode == 'short' ?
                <Button
                    style={{ backgroundColor:undefined }}
                    title='View Full Leaderboard'
                    title_color={Colors.brand.electric}
                    title_size={14}
                    onPress={() => onSeeFull()}
                />
            :<></>}
            {onClose ?
            <View style={{ padding:10, ...view_styles.float }}>
                <View style={{ padding:10, flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                    {offset > 0 ?
                    <Button
                        title='PREV'
                        title_color={Colors.brand.electric}
                        backgroundColor='transparent'
                        onPress={() => getLeaderboardFromServer(competition.competition_id, offset - 1)}
                    />
                    :<View/>}
                    <Button
                        title='NEXT'
                        title_color={Colors.brand.electric}
                        backgroundColor='transparent'
                        onPress={() => getLeaderboardFromServer(competition.competition_id, offset + 1)}
                    />
                </View>
                <Button
                    title='Close'
                    style={{ padding:15 }}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
            :<></>}
            </View>
            :<></>}
            
        </View>
    )
}

export default CompetitionLeaderboard