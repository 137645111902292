import React from 'react';
import { Image, TouchableOpacity } from 'react-native';
import type { PodcastProps } from "../../types"
import { SocialPodcastHelpers } from '../../SocialComponents/api';

type PodcastCardProps = {
    podcast:PodcastProps,
    size:number,
    onPodcastSelect:(pod:PodcastProps) => void
}

const PodcastCard = ({podcast, size, onPodcastSelect}:PodcastCardProps) => {

    return (
        <TouchableOpacity onPress={() => onPodcastSelect(podcast)}>
            <Image
                source={{ uri: SocialPodcastHelpers.getPodcastImage(podcast) }}
                style={{ height:size, width:size }}
                resizeMode='cover'
            />
        </TouchableOpacity>
    )
}
export default PodcastCard