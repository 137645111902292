import axios from "axios";
import { APIOverrides } from "../../ApiOverrides";
import type { BEEventProps, GeneratedLinkProps, PlayerReferralProps } from '../../types';
import { Platform, Share } from 'react-native';
let ANALYTICS_SVC_API = '';
let AUTH_SVC_API = '';
export { ShareApi, ShareHelpers }

const ShareApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        ANALYTICS_SVC_API = endpoints['ANALYTICS_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getMyReferralCode: async():Promise<PlayerReferralProps | undefined> => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/referral/me`)
            return resp.data.player_referral
        } catch (e) {
            return undefined
        }
    },
    generateShortLink: async(generated_link:GeneratedLinkProps):Promise<{ generated_link:GeneratedLinkProps, url:string }> => {
        try {
            console.log('generating!!!')
            const resp = await axios.post(`${ANALYTICS_SVC_API}/v1/links/redirect/generate`, { generated_link })
            return { generated_link:resp.data.generated_link, url: `https://share.mybe.app/id/${resp.data.generated_link.visible_id}` }
        } catch (e) {
            console.log(e)
            return { generated_link, url: generated_link.redirect_url }
        }
    },
    getGeneratedLinkById: async(generated_link_id:string):Promise<{ generated_link:GeneratedLinkProps, url:string } | undefined> => {
        try {
            const resp = await axios.get(`${ANALYTICS_SVC_API}/v1/links/link/${generated_link_id}`);
            return { generated_link: resp.data.generated_link, url: `https://share.mybe.app/id/${resp.data.generated_link.visible_id}`}
        } catch (e) {
            return undefined
        }
    },
    updateGeneratedLink: async(generated_link:GeneratedLinkProps):Promise<{ url:string, generated_link:GeneratedLinkProps } | undefined> => {
        try {
            const resp = await axios.post(`${ANALYTICS_SVC_API}/v1/links/redirect/update`, { generated_link })
            return { url: `https://share.mybe.app/id/${resp.data.generated_link.visible_id}`, generated_link: resp.data.generated_link }
        } catch (e) {
            console.log(e);
            return undefined
        }
    },
    isGeneratedLinkAvailable: async(generated_link:GeneratedLinkProps):Promise<boolean> => {
        try {
            const resp = await axios.post(`${ANALYTICS_SVC_API}/v1/links/redirect/available`, { generated_link })
            return resp.data.is_available
        } catch (e) {
            console.log(e);
            return false
        }
    },
    share: async(title:string, text?:string, link?:string, generated_link_id?:string):Promise<BEEventProps | undefined> => { 
        try {
            if (Platform.OS === 'web') {
                if (navigator.share) {
                    const resp = await navigator.share({
                        title,
                        text:text,
                        url: link 
                    })
                    console.log(resp)
                    return {
                        event_name: 'generated_link',
                        event_data: {
                            generated_link_id
                        },
                        level:1
                    }
                } else {
                    console.log('DIDNT SHARE!!!')
                    // Fallback for browsers that don't support Web Share API
                    alert('Sharing is not supported in this browser.');
                    return undefined
                }
            } else {
                await Share.share({
                    message: text,
                    url: link ?? '',
                });
                return {
                    event_name: 'generated_link',
                    event_data: {
                        generated_link_id
                    },
                    level:1
                }
            }
        } catch (error) {
            console.error('Error sharing:', error);
            return undefined
        }
    }
}


const ShareHelpers = {
    nativeShareAvailable : () => {
        if(!navigator){ return false }
        if(!navigator.share){ return false }
        return true
    },
    extractQueryParams: (url?:string):{ key:string, value:string }[] => {
        if(!url){ return [] }
        let query_params:{key:string, value:string}[] = []
        const parsedPath2 = url.split(/[?&]/)
        parsedPath2.map(p => {
            let vars = p.split('=')
            if(vars.length !== 2){ return }
            const queryVariable = vars[0]
            const queryValue = vars[1]
            if(!queryVariable || !queryValue){ return }
            query_params.push({ key: queryVariable, value: queryValue })
        })
        return query_params
    },
    appendPlayerReferral: async(url:string) => {
        //First lets snag the parameters
        let params = ShareHelpers.extractQueryParams(url);
        let refer_param = params.find(p => p.key == 'refer');
        if(!refer_param){
            const pr = await ShareApi.getMyReferralCode();
            if(pr){
                //Now let us update the url with the players referral code!
                if(params.length == 0){ url += `?refer=${pr.referral_code}` }
                else { url += `&refer=${pr.referral_code}` }
            }
        }
        return url
    }
}