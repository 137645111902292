import axios from "axios"
import { APIOverrides } from "../../ApiOverrides"
import type { BuySquaresResponseProps, EventProps, LeagueProps, OfferResponseProps, PlayerBalanceProps, PlayerSquareProps, PublicPlayerProps, SquareOfferProps, SquarePrizeProps, SquareProps, SquareResultProps, SquaresCompetitionProps, SquaresPayoutProps, SquaresTypeProps, SquareValueProps } from "../../types"
import moment from "moment-mini"

let AUTH_SVC_API = ''
let TP_SVC_API = ''
let EVENT_SVC_API = ''
//let MK_SVC_API = ''

export { SquaresApi, SqauresHelpers }

const SquaresApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        TP_SVC_API = endpoints['TP_SVC_API'] as string;
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        //MK_SVC_API = endpoints['MK_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getSquareCompetitionById: async(sq_comp_id:string):Promise<{ squares_competition:SquaresCompetitionProps, square_results:SquareResultProps[], squares:SquareProps[], player_squares:PlayerSquareProps[], square_offers:SquareOfferProps[], squares_type:SquaresTypeProps, squares_payout_type: SquaresPayoutProps, player_square_history:PlayerSquareProps[], square_prizes:SquarePrizeProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/tp/squares/comp/details/${sq_comp_id}`)
        return resp.data
    },
    getEventsByEventIds: async(event_ids:string[]):Promise<EventProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/events/bulk/get`, { attribute:'event_id', values: event_ids })
        return resp.data.events
    },
    getLeagueById: async(league_id:string):Promise<LeagueProps> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues/league/${league_id}`)
        return resp.data.league
    },
    buySquares: async(sq_comp_id:string, player_squares:PlayerSquareProps[], promo_balance?:boolean):Promise<BuySquaresResponseProps> => {
        const resp = await axios.post(`${TP_SVC_API}/tp/squares/square/buy`, { sq_comp_id, player_squares, promo_balance })
        if(resp.data.saved_player_squares.length != player_squares.length){ alert('Some of your bids could not be processed as they were outbid before purchasing') }
        return resp.data
    },
    offserSquare: async(square_offer:SquareOfferProps):Promise<OfferResponseProps> => {
        const resp = await axios.post(`${TP_SVC_API}/tp/squares/square/offer`, { square_offer })
        return resp.data
    },
    getMyBalance: async():Promise<PlayerBalanceProps> => {
      const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/balance/me`);
      return resp.data.player_balance
    },
    getPlayersByPlayerIds : async(player_ids:string[]):Promise<PublicPlayerProps[]> => {
        if(player_ids.length == 0){ return [] }
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
        return resp.data.players
    }
}


const SqauresHelpers = {
    getCompStatus:(event?:EventProps, squares_competition?:SquaresCompetitionProps) => {
        if(!event){ return 'closed' }
        if(['complete','closed','postponed','cancelled'].includes(event?.status)){ return 'closed' }
        if(!squares_competition){ return 'not_started' }
        let now = moment()
        if(moment(squares_competition.begin_datetime).isAfter(now)){ return 'not_started' }
        if(squares_competition.complete_ind){ return 'closed' }
        return 'in_progress'
    },
    getOwnerShipPercents : (ps:PlayerSquareProps[]) => {
        let active_ps = ps.filter(p => p.status == 'active');
        const unique_players = [ ...new Set(active_ps.map(s => s.player_id)) ];
        const ownerships: { player_id: string, owned_squares: number, owned_pct: number }[] = []
        unique_players.map(id => {
            let id_ps = active_ps.filter(p => p.player_id == id);
            ownerships.push({ player_id: id, owned_squares:id_ps.length, owned_pct: id_ps.length / active_ps.length })
        })
        return ownerships.sort((a,b) => b.owned_squares - a.owned_squares)
    },
    amIParticipating: (player_squares:PlayerSquareProps[], player_square_history:PlayerSquareProps[], player_id?:string) => {
        if(!player_id){ return false }
        const i_have_one = player_squares.find(s => s.player_id == player_id)
        if(i_have_one){ return true }
        const i_have_history = player_square_history.find(s => s.player_id == player_id)
        if(i_have_history){ return true }
        return false
    },
    updateSquaresWithUpdatedSquares : (squares:SquareProps[], updated_squares:SquareProps[]) => {
      updated_squares.map((s:SquareProps) => {
          squares = squares.filter(square => square.sq_square_id != s.sq_square_id).concat(s)
      })
      return squares
  },
   updatePlayerSquaresWithSavedSquares : (pSquares:PlayerSquareProps[], saved_player_squares:PlayerSquareProps[]) => {
      saved_player_squares.map(ps => {
          pSquares = pSquares.filter(square => square.sq_player_square_id != ps.sq_player_square_id).concat(ps)
      })
      return pSquares
  },
  updatePlayerSquaresWithRemovedSquares : (pSquares:PlayerSquareProps[], removed_player_squares:PlayerSquareProps[]) => {
      removed_player_squares.map((ps:PlayerSquareProps) => {
          let existIndex = pSquares.findIndex(s => s.sq_player_square_id == ps.sq_player_square_id)
          if(existIndex != -1){
              pSquares.splice(existIndex, 1)
          }
      })
      return pSquares
  },
    updatePlayerSquareHistoryWithRemovedSquares : (pSquareHistory:PlayerSquareProps[], removed_player_squares:PlayerSquareProps[]) => {
      removed_player_squares.map((ps:PlayerSquareProps) => {
          if(ps.player_id == ps.player_id){ pSquareHistory = pSquareHistory.concat(ps) }
      })
      return pSquareHistory
  },
    updateSquareOffersWithUpdatedOffers : (square_offers:SquareOfferProps[], updated_offers:SquareOfferProps[]) => {
      updated_offers.map(o => {
          switch(o.status){
              case 'offered': square_offers = square_offers.concat(o); break;
              case 'accepted': square_offers = square_offers.filter(so => so.sq_offer_id != o.sq_offer_id); break;  
              case 'rejected': square_offers = square_offers.filter(so => so.sq_offer_id != o.sq_offer_id); break; 
              case 'withdrawn': square_offers = square_offers.filter(so => so.sq_offer_id != o.sq_offer_id); break;
              default: break;
          }
      })
      return square_offers
  },
    getMyExpectedValue: (squares:SquareProps[], player_squares:PlayerSquareProps[], squares_competition?:SquaresCompetitionProps, player_id?:string ) => {
        if(!squares_competition){ return {  ev_pct: 0, money_in: 0, expected_payout:0, expected_value: 0, my_square_values: [], other_square_values: [] } }
        let total_payout = squares_competition.potential_winnings
        if(squares_competition.guaranteed_payout && squares_competition.guaranteed_payout > total_payout){ total_payout = squares_competition.guaranteed_payout }
        let square_values:SquareValueProps[] = []
        squares.map(s => {
            let square_probability = square_probabilities.find(p => p.square_score_x == s.square_score_x && p.square_score_y == s.square_score_y);
            let next_purchase_price = squares_competition.minimum_square_price
            if(s.last_purchase_price > 0){ next_purchase_price = s.last_purchase_price + 0.50 }
            if(!square_probability){ square_values.push({ sq_square_id:s.sq_square_id, probability:0, expected_value:0, expected_payout:0, next_purchase_price, after_purchase_expected_payout:0, after_purchase_expected_value:0}); return; }
            let expected_payout = square_probability.probability * total_payout;
            let expected_value = expected_payout - s.last_purchase_price
            let after_purchase_expected_payout = (square_probability.probability * (total_payout + next_purchase_price))
            let after_purchase_expected_value = after_purchase_expected_payout - next_purchase_price
            square_values.push({ sq_square_id:s.sq_square_id, probability: square_probability.probability, expected_payout, expected_value, next_purchase_price, after_purchase_expected_payout, after_purchase_expected_value })
            return;
        });


        let open_square_probabilities = square_values.filter(sp => squares.filter(s => s.status == 'open').map(s => s.sq_square_id.toString()).includes(sp.sq_square_id));
        let my_player_squares = player_squares.filter(ps => ps.player_id == player_id);

        let pctOwnership = my_player_squares.length / squares.filter(s => s.status == 'filled').length
        let sqToPurchase = Math.floor(pctOwnership * open_square_probabilities.length)
        
        //ownerPcts.push({ player_id: p, pct:pctOwnership })
        for (let x = 0; x < sqToPurchase; x++) {
            let squaresForPurchase = squares.filter(s => s.status === 'open')
            let square = squaresForPurchase[x]
            if(square){
                let pSQ:PlayerSquareProps = {
                    sq_player_square_id:'', sq_comp_id:square.sq_comp_id, sq_square_id:square.sq_square_id, player_id:player_id ?? '', purchase_price:0, status:'active', create_datetime:'', last_update_datetime:''
                }
                my_player_squares.push(pSQ)
            }
        }

        //Ok we have square values added.  Now lets go through the players stuff to see what their current value is
        let my_square_values = square_values.filter(sv => my_player_squares.map(ps => ps.sq_square_id.toString()).includes(sv.sq_square_id.toString()))
        let other_square_values = square_values.filter(sv => !my_player_squares.find(mps => mps.sq_square_id == sv.sq_square_id))
        let expected_payout = my_square_values.reduce((a,b) => a + b.expected_payout, 0)
        let money_in = my_player_squares.reduce((a,b) => a + b.purchase_price, 0)
        let expected_value = expected_payout - money_in
        let ev_pct = expected_value / money_in

        
        return { ev_pct, money_in, expected_payout, expected_value, other_square_values, my_square_values: my_square_values }
    }
}


const square_probabilities = [
    {
      "square_score_x": 0,
      "square_score_y": 0,
      "win_count": 225,
      "probability": 0.069962687
    },
    {
      "square_score_x": 7,
      "square_score_y": 0,
      "win_count": 222,
      "probability": 0.069029851
    },
    {
      "square_score_x": 0,
      "square_score_y": 7,
      "win_count": 167,
      "probability": 0.051927861
    },
    {
      "square_score_x": 7,
      "square_score_y": 7,
      "win_count": 155,
      "probability": 0.048196517
    },
    {
      "square_score_x": 0,
      "square_score_y": 3,
      "win_count": 143,
      "probability": 0.044465174
    },
    {
      "square_score_x": 3,
      "square_score_y": 0,
      "win_count": 135,
      "probability": 0.041977612
    },
    {
      "square_score_x": 3,
      "square_score_y": 7,
      "win_count": 122,
      "probability": 0.037935323
    },
    {
      "square_score_x": 7,
      "square_score_y": 3,
      "win_count": 121,
      "probability": 0.037624378
    },
    {
      "square_score_x": 4,
      "square_score_y": 0,
      "win_count": 106,
      "probability": 0.032960199
    },
    {
      "square_score_x": 3,
      "square_score_y": 3,
      "win_count": 91,
      "probability": 0.02829602
    },
    {
      "square_score_x": 4,
      "square_score_y": 7,
      "win_count": 83,
      "probability": 0.025808458
    },
    {
      "square_score_x": 0,
      "square_score_y": 4,
      "win_count": 77,
      "probability": 0.023942786
    },
    {
      "square_score_x": 7,
      "square_score_y": 4,
      "win_count": 75,
      "probability": 0.023320896
    },
    {
      "square_score_x": 6,
      "square_score_y": 0,
      "win_count": 64,
      "probability": 0.019900498
    },
    {
      "square_score_x": 0,
      "square_score_y": 6,
      "win_count": 61,
      "probability": 0.018967662
    },
    {
      "square_score_x": 7,
      "square_score_y": 6,
      "win_count": 56,
      "probability": 0.017412935
    },
    {
      "square_score_x": 3,
      "square_score_y": 6,
      "win_count": 47,
      "probability": 0.014614428
    },
    {
      "square_score_x": 4,
      "square_score_y": 3,
      "win_count": 46,
      "probability": 0.014303483
    },
    {
      "square_score_x": 4,
      "square_score_y": 4,
      "win_count": 44,
      "probability": 0.013681592
    },
    {
      "square_score_x": 6,
      "square_score_y": 3,
      "win_count": 41,
      "probability": 0.012748756
    },
    {
      "square_score_x": 1,
      "square_score_y": 7,
      "win_count": 40,
      "probability": 0.012437811
    },
    {
      "square_score_x": 4,
      "square_score_y": 1,
      "win_count": 37,
      "probability": 0.011504975
    },
    {
      "square_score_x": 6,
      "square_score_y": 7,
      "win_count": 37,
      "probability": 0.011504975
    },
    {
      "square_score_x": 0,
      "square_score_y": 9,
      "win_count": 36,
      "probability": 0.01119403
    },
    {
      "square_score_x": 4,
      "square_score_y": 6,
      "win_count": 34,
      "probability": 0.010572139
    },
    {
      "square_score_x": 3,
      "square_score_y": 4,
      "win_count": 33,
      "probability": 0.010261194
    },
    {
      "square_score_x": 3,
      "square_score_y": 1,
      "win_count": 33,
      "probability": 0.010261194
    },
    {
      "square_score_x": 1,
      "square_score_y": 0,
      "win_count": 32,
      "probability": 0.009950249
    },
    {
      "square_score_x": 7,
      "square_score_y": 9,
      "win_count": 31,
      "probability": 0.009639303
    },
    {
      "square_score_x": 7,
      "square_score_y": 1,
      "win_count": 30,
      "probability": 0.009328358
    },
    {
      "square_score_x": 0,
      "square_score_y": 1,
      "win_count": 29,
      "probability": 0.009017413
    },
    {
      "square_score_x": 0,
      "square_score_y": 8,
      "win_count": 27,
      "probability": 0.008395522
    },
    {
      "square_score_x": 9,
      "square_score_y": 7,
      "win_count": 26,
      "probability": 0.008084577
    },
    {
      "square_score_x": 6,
      "square_score_y": 4,
      "win_count": 25,
      "probability": 0.007773632
    },
    {
      "square_score_x": 1,
      "square_score_y": 3,
      "win_count": 23,
      "probability": 0.007151741
    },
    {
      "square_score_x": 1,
      "square_score_y": 4,
      "win_count": 22,
      "probability": 0.006840796
    },
    {
      "square_score_x": 4,
      "square_score_y": 8,
      "win_count": 21,
      "probability": 0.006529851
    },
    {
      "square_score_x": 0,
      "square_score_y": 5,
      "win_count": 21,
      "probability": 0.006529851
    },
    {
      "square_score_x": 6,
      "square_score_y": 1,
      "win_count": 21,
      "probability": 0.006529851
    },
    {
      "square_score_x": 0,
      "square_score_y": 2,
      "win_count": 20,
      "probability": 0.006218905
    },
    {
      "square_score_x": 7,
      "square_score_y": 8,
      "win_count": 18,
      "probability": 0.005597015
    },
    {
      "square_score_x": 6,
      "square_score_y": 6,
      "win_count": 18,
      "probability": 0.005597015
    },
    {
      "square_score_x": 1,
      "square_score_y": 9,
      "win_count": 17,
      "probability": 0.00528607
    },
    {
      "square_score_x": 8,
      "square_score_y": 0,
      "win_count": 17,
      "probability": 0.00528607
    },
    {
      "square_score_x": 9,
      "square_score_y": 0,
      "win_count": 16,
      "probability": 0.004975124
    },
    {
      "square_score_x": 8,
      "square_score_y": 7,
      "win_count": 16,
      "probability": 0.004975124
    },
    {
      "square_score_x": 2,
      "square_score_y": 7,
      "win_count": 16,
      "probability": 0.004975124
    },
    {
      "square_score_x": 3,
      "square_score_y": 9,
      "win_count": 15,
      "probability": 0.004664179
    },
    {
      "square_score_x": 1,
      "square_score_y": 1,
      "win_count": 15,
      "probability": 0.004664179
    },
    {
      "square_score_x": 3,
      "square_score_y": 8,
      "win_count": 15,
      "probability": 0.004664179
    },
    {
      "square_score_x": 6,
      "square_score_y": 8,
      "win_count": 14,
      "probability": 0.004353234
    },
    {
      "square_score_x": 2,
      "square_score_y": 3,
      "win_count": 14,
      "probability": 0.004353234
    },
    {
      "square_score_x": 5,
      "square_score_y": 7,
      "win_count": 14,
      "probability": 0.004353234
    },
    {
      "square_score_x": 9,
      "square_score_y": 3,
      "win_count": 13,
      "probability": 0.004042289
    },
    {
      "square_score_x": 1,
      "square_score_y": 6,
      "win_count": 13,
      "probability": 0.004042289
    },
    {
      "square_score_x": 9,
      "square_score_y": 4,
      "win_count": 13,
      "probability": 0.004042289
    },
    {
      "square_score_x": 6,
      "square_score_y": 5,
      "win_count": 12,
      "probability": 0.003731343
    },
    {
      "square_score_x": 8,
      "square_score_y": 1,
      "win_count": 12,
      "probability": 0.003731343
    },
    {
      "square_score_x": 5,
      "square_score_y": 3,
      "win_count": 12,
      "probability": 0.003731343
    },
    {
      "square_score_x": 9,
      "square_score_y": 6,
      "win_count": 12,
      "probability": 0.003731343
    },
    {
      "square_score_x": 3,
      "square_score_y": 2,
      "win_count": 11,
      "probability": 0.003420398
    },
    {
      "square_score_x": 3,
      "square_score_y": 5,
      "win_count": 11,
      "probability": 0.003420398
    },
    {
      "square_score_x": 2,
      "square_score_y": 0,
      "win_count": 11,
      "probability": 0.003420398
    },
    {
      "square_score_x": 8,
      "square_score_y": 4,
      "win_count": 11,
      "probability": 0.003420398
    },
    {
      "square_score_x": 4,
      "square_score_y": 5,
      "win_count": 10,
      "probability": 0.003109453
    },
    {
      "square_score_x": 7,
      "square_score_y": 5,
      "win_count": 10,
      "probability": 0.003109453
    },
    {
      "square_score_x": 7,
      "square_score_y": 2,
      "win_count": 10,
      "probability": 0.003109453
    },
    {
      "square_score_x": 6,
      "square_score_y": 9,
      "win_count": 10,
      "probability": 0.003109453
    },
    {
      "square_score_x": 2,
      "square_score_y": 6,
      "win_count": 10,
      "probability": 0.003109453
    },
    {
      "square_score_x": 4,
      "square_score_y": 9,
      "win_count": 9,
      "probability": 0.002798507
    },
    {
      "square_score_x": 1,
      "square_score_y": 5,
      "win_count": 8,
      "probability": 0.002487562
    },
    {
      "square_score_x": 8,
      "square_score_y": 3,
      "win_count": 8,
      "probability": 0.002487562
    },
    {
      "square_score_x": 1,
      "square_score_y": 8,
      "win_count": 8,
      "probability": 0.002487562
    },
    {
      "square_score_x": 4,
      "square_score_y": 2,
      "win_count": 8,
      "probability": 0.002487562
    },
    {
      "square_score_x": 5,
      "square_score_y": 1,
      "win_count": 8,
      "probability": 0.002487562
    },
    {
      "square_score_x": 5,
      "square_score_y": 0,
      "win_count": 8,
      "probability": 0.002487562
    },
    {
      "square_score_x": 9,
      "square_score_y": 9,
      "win_count": 7,
      "probability": 0.002176617
    },
    {
      "square_score_x": 2,
      "square_score_y": 8,
      "win_count": 7,
      "probability": 0.002176617
    },
    {
      "square_score_x": 8,
      "square_score_y": 6,
      "win_count": 7,
      "probability": 0.002176617
    },
    {
      "square_score_x": 9,
      "square_score_y": 1,
      "win_count": 7,
      "probability": 0.002176617
    },
    {
      "square_score_x": 6,
      "square_score_y": 2,
      "win_count": 7,
      "probability": 0.002176617
    },
    {
      "square_score_x": 1,
      "square_score_y": 2,
      "win_count": 7,
      "probability": 0.002176617
    },
    {
      "square_score_x": 2,
      "square_score_y": 5,
      "win_count": 6,
      "probability": 0.001865672
    },
    {
      "square_score_x": 2,
      "square_score_y": 1,
      "win_count": 6,
      "probability": 0.001865672
    },
    {
      "square_score_x": 8,
      "square_score_y": 2,
      "win_count": 6,
      "probability": 0.001865672
    },
    {
      "square_score_x": 8,
      "square_score_y": 9,
      "win_count": 6,
      "probability": 0.001865672
    },
    {
      "square_score_x": 2,
      "square_score_y": 4,
      "win_count": 6,
      "probability": 0.001865672
    },
    {
      "square_score_x": 8,
      "square_score_y": 5,
      "win_count": 6,
      "probability": 0.001865672
    },
    {
      "square_score_x": 5,
      "square_score_y": 6,
      "win_count": 6,
      "probability": 0.001865672
    },
    {
      "square_score_x": 5,
      "square_score_y": 8,
      "win_count": 5,
      "probability": 0.001554726
    },
    {
      "square_score_x": 9,
      "square_score_y": 5,
      "win_count": 4,
      "probability": 0.001243781
    },
    {
      "square_score_x": 5,
      "square_score_y": 4,
      "win_count": 3,
      "probability": 0.000932836
    },
    {
      "square_score_x": 9,
      "square_score_y": 8,
      "win_count": 3,
      "probability": 0.000932836
    },
    {
      "square_score_x": 2,
      "square_score_y": 9,
      "win_count": 3,
      "probability": 0.000932836
    },
    {
      "square_score_x": 5,
      "square_score_y": 2,
      "win_count": 3,
      "probability": 0.000932836
    },
    {
      "square_score_x": 5,
      "square_score_y": 9,
      "win_count": 3,
      "probability": 0.000932836
    },
    {
      "square_score_x": 8,
      "square_score_y": 8,
      "win_count": 3,
      "probability": 0.000932836
    },
    {
      "square_score_x": 9,
      "square_score_y": 2,
      "win_count": 2,
      "probability": 0.000621891
    },
    {
      "square_score_x": 2,
      "square_score_y": 2,
      "win_count": 1,
      "probability": 0.000310945
    },
    {
      "square_score_x": 5,
      "square_score_y": 5,
      "win_count": 0,
      "probability": 0
    }
  ]