
import React from 'react';
import { TextInput as RNTextInput, useWindowDimensions } from 'react-native';
import Colors from '../constants/colors';

type TextInputProps = {
    value?:string,
    key?:string,
    placeholder?:string,
    autoFocus?:boolean,
    multiline?:boolean,
    keyboardType?:string,
    textContentType?:string,
    editable?:boolean,
    secureTextEntry?:boolean,
    onSubmitEditing?: (evt:any) => void,
    onBlur?: (evt:any) => void,
    placeholderTextColor?:string,
    onChangeText:(text:string) => void,
    onContentSizeChange?:(data:any) => void,
    style?:any
}

const STANDARD_SIZE = 414

const TextInput = ({ onChangeText, key, placeholderTextColor, value, placeholder, style, autoFocus, multiline, keyboardType, textContentType, editable, secureTextEntry, onContentSizeChange, onSubmitEditing, onBlur } : TextInputProps) => {
    const dimensions = useWindowDimensions();

    let rendered_size = style?.fontSize ?? 14
    let diff_from_normal = dimensions.width - STANDARD_SIZE
    if(diff_from_normal < 0){
        let pct_diff = Math.abs(diff_from_normal / STANDARD_SIZE)
        rendered_size = rendered_size * (1 - pct_diff)
    }
    
    return (
        <RNTextInput
            key={key}
            onContentSizeChange={onContentSizeChange}
            multiline={multiline ? true : false}
            style={{ 
                padding:15, 
                borderRadius:22, 
                borderWidth:1,
                textAlign:'center', 
                fontFamily:'barlow-bold', 
                fontSize:rendered_size,
                borderColor:Colors.shades.shade600,
                backgroundColor:editable == false ? Colors.shades.shade100 : Colors.shades.white,
                ...style 
            }}
            editable={editable ?? true}
            placeholder={placeholder}
            value={value}
            onSubmitEditing={onSubmitEditing}
            onBlur={onBlur}
            secureTextEntry={secureTextEntry}
            placeholderTextColor={placeholderTextColor}
            autoFocus={autoFocus}
            textContentType={textContentType as any}
            keyboardType={keyboardType as any}
            onChangeText={onChangeText}
        />
    )
}

export default TextInput