import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import Colors from '../constants/colors';


type SwitchProps = { 
    value: boolean, 
    switch_type: 'binary' | 'on_off', 
    disabled?:boolean,
    onChange:(value:boolean) => void 
}
const Switch = ({ value, disabled, switch_type, onChange }:SwitchProps) => {

    const handleChange = () => {
        if(onChange){
            onChange(!value)
        }
    }

    return (
        <View style={{ flexDirection:'row', alignItems:'flex-start' }}>
            {switch_type == 'on_off' ?
            <TouchableOpacity 
            disabled={disabled}
            style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', backgroundColor:value?Colors.highlights.highlight400:Colors.brand.slate, padding:3, borderRadius:12, width:48,height:24}} onPress={() => handleChange()}>
            { value ? 
                <>
                <View />
                <View style={{ borderRadius:100, width:18, height:18, backgroundColor:Colors.shades.white}} />
                </>
            :
                <>
                <View style={{ borderRadius:100, width:18, height:18, backgroundColor:Colors.shades.white}} />
                <View/>
                </>
            }
        </TouchableOpacity>
        :
        <TouchableOpacity 
        disabled={disabled}
        style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', backgroundColor:Colors.shades.white, padding:3, borderRadius:12, width:48,height:24}} onPress={() => handleChange()}>
            { value ? 
                <>
                <View />
                <View style={{ borderRadius:100, width:18, height:18, backgroundColor:Colors.highlights.highlight400}} />
            </>
            :
            <>
                <View style={{ borderRadius:100, width:18, height:18, backgroundColor:Colors.highlights.highlight400}} />
                <View />
            </>
            }
        </TouchableOpacity>
        }
        </View>
    )
}


export default Switch