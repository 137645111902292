import React, { useState } from 'react';
import { View } from 'react-native';
import type { AthleteProps, BestAvailableOrderProps, EventProps, ExternalPriceProps, FundOrderProps, FundProps, MarketProps, MatchProps, OrderProps, TeamProps, TournamentProps, TradeProps } from '../../types';
import { Button, Text } from '../../Components';
import { view_styles } from '../../constants/styles';
import Colors from '../../constants/colors';
import { MarketMaketHelpers } from '../api';
import WeightProgress from './WeightProgress';

type ContestMarketProps = {
    contest_id:string,
    contest_type:string,
    fund:FundProps,
    market:MarketProps,
    best_available_orders:BestAvailableOrderProps[],
    latest_trades:TradeProps[],
    side_type?:string,
    side_id?:string,
    orders:OrderProps[],
    fund_orders:FundOrderProps[],
    var_1:number,
    unique_var_1:number[],
    event?:EventProps,
    tournament?:TournamentProps,
    match?:MatchProps,
    athlete?:AthleteProps,
    team?:TeamProps,
    external_prices:ExternalPriceProps[],
    backup_prices?:ExternalPriceProps[],
    onSetMarket:(orders:OrderProps[], market:MarketProps, prices:ExternalPriceProps[]) => void
    onClearMarket:(order_ids:string[]) => void,
    onOrder:(order:OrderProps, available?:number) => void
}

const ContestMarket = ({ fund, event, external_prices, side_id, side_type, market, orders, best_available_orders, latest_trades, var_1, athlete, backup_prices, onSetMarket, onClearMarket, onOrder }:ContestMarketProps) => {
    const [ selected_var_1 ] = useState(var_1);
    /*
    const [ review_order, setReviewOrder ] = useState<{
        visible: boolean,
        order?:OrderProps,
        side: 'other' | 'trade'
    }>({
        visible:false,
        side: 'trade'
    });
    */
    let work_allowed = fund.working_capital > 1 ? true : false;
    const { var_1_orders, var_1_available, var_1_prices } = MarketMaketHelpers.filterByVar1(selected_var_1, orders, best_available_orders, external_prices, market, side_type, side_id, backup_prices);
    const all_order_stats = MarketMaketHelpers.getOrderStats(var_1_orders);
    const { o_side_ba_mine, trade_side_ba_mine, trade_side_orders, o_side_orders, trade_side_ba, o_side_ba } = MarketMaketHelpers.getSideMarketData(var_1_orders, var_1_available, latest_trades, market);
    const trade_side_order_stats = MarketMaketHelpers.getOrderStats(trade_side_orders, !trade_side_ba_mine?trade_side_ba : undefined);
    //const hedges = MarketMaketHelpers.getHedgesFromPositions(orders, [market]);
    //const hedged_amt = (hedges.reduce((a,b) => a + b.delayed_cash_draw, 0) * -1) + hedges.reduce((a,b) => a + b.delayed_cash_no_draw, 0);
    const o_side_order_stats = MarketMaketHelpers.getOrderStats(o_side_orders, !o_side_ba_mine?o_side_ba:undefined);
    const stake_weight = MarketMaketHelpers.getWeight(o_side_order_stats.stake_potential_winnings, trade_side_order_stats.stake_potential_winnings, market);
   // const open_weight = MarketMaketHelpers.getWeight(o_side_order_stats.open_potential_winnings, trade_side_order_stats.open_potential_winnings, market);
    const o_price = var_1_prices.find(p => p.side != market.trade_side);
    const trade_price = var_1_prices.find(p => p.side == market.trade_side);


    const handleSelectBA = (ba:BestAvailableOrderProps) => {
        const side_option = market.side_options.find(so => so.side == ba.side)
        if(!side_option){ return alert('Unable to process') }
        if(!event){ return alert('Not set up for events!!!') }
        const side_id_data = MarketMaketHelpers.getTeamEventSideIds(event, market, side_option)
        if(!side_id_data){ return alert('Unable to process') }
        const ba_trade = MarketMaketHelpers.getTradeFromAvailableOrder(ba, side_id_data.reversed_side_id);
        //console.log(ba_trade);
        const order = MarketMaketHelpers.getOrderFromTrade(ba_trade, 10, ba.order_id);
        const order_title = MarketMaketHelpers.getOrderTitleForTeamEvent(order, market, event, athlete);
        if(!order_title){ return alert('Unable to buy order') }
        
        let working_pct = order.open_amt / fund.working_capital
        if(working_pct > 1){ working_pct = 1 }
        onOrder({
            ...order,
            title: order_title,
            expire_datetime: event.scheduled_datetime,
            working_pct
        }, ba.open_amt)
    }

    const handleActionSelect = (price?:ExternalPriceProps) => {
        if(!price){ return alert('Unable to create order here') }
        if(!event){ return alert('Not set up for events!!!') }
        const side_option = market.side_options.find(so => so.side == price.side)
        if(!side_option){ return alert('Unable to process') }
        const side_id_data = MarketMaketHelpers.getTeamEventSideIds(event, market, side_option)
        if(!side_id_data){ return alert('Unable to process') }
        if(!o_price || !trade_price){ return alert('Unable to process') }
        
        const trade = MarketMaketHelpers.getTradeFromPrice({ ...price, probability: price.probability - 0.05, odds: MarketMaketHelpers.calcAmericanOddsFromProbability(price.probability - 0.05) }, side_id_data.reversed_side_id);
        const order = MarketMaketHelpers.getOrderFromTrade(trade, fund.working_capital);
        const order_title = MarketMaketHelpers.getOrderTitleForTeamEvent(order, market, event, athlete);
        if(!order_title){ return alert('Unable to buy order') }
        
        let working_pct = order.open_amt / fund.working_capital
        if(working_pct > 1){ working_pct = 1 }
        onOrder({
            ...order,
            title: order_title,
            expire_datetime: event.scheduled_datetime,
            working_pct
        })
    }

    return (
        <View style={{ flex:1 }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header_2'>{market.type == 'Stat' ? market.stat_label : market.type} {market.var_1_required?MarketMaketHelpers.getVar1Label(market, var_1):''}</Text>
                </View>
                {trade_price ?
                <View>
                    <Text size={12} color={Colors.brand.midnight} weight='bold'>{MarketMaketHelpers.getOddsLabel(trade_price.odds)}</Text>
                    <Text theme='body_2'>{trade_price.external_name}</Text>
                </View>
                :<></>}
                {all_order_stats.open_amt == 0 ?
                    <Button
                        title='SET'
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.brand.electric}
                        onPress={() => {
                            if(!event){ return alert('Unable to set market') }
                            const set_orders = MarketMaketHelpers.setMarkets(fund, var_1_available, var_1_prices, market, .05, event);
                            if(set_orders.length != 2){ return alert('Unable to set market at this time') }
                            return onSetMarket(set_orders, market, var_1_prices);
                        }}
                    />
                    :
                    <Button
                        title='CLEAR'
                        backgroundColor={Colors.utility.error}
                        title_color={Colors.shades.white}
                        onPress={() => onClearMarket(orders.map(o => o.order_id))}
                    />
                    }
            </View>
            <View style={{ flexDirection:'row', padding:0 }}>
                <View style={{ flex:1, flexDirection:'row', justifyContent:'flex-start' }}>
                    <View style={{ backgroundColor:Colors.shades.shade100, padding:3, paddingLeft:10, paddingRight:10, margin:1 }}>
                        <View style={{ padding:2 }}>
                            <Text size={12} color={Colors.brand.midnight}>DEPLOYED</Text>
                        </View>
                        <View style={{ flex:1, padding:2 }}>
                            <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign='center'>${o_side_order_stats.stake_potential_winnings.toFixed(2)}</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} textAlign='center'>{MarketMaketHelpers.getOddsLabel(o_side_order_stats.stake_odds)}</Text>
                        </View>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} textAlign='center'>To Win</Text>
                    </View>
                    <View style={{ flex:1 }} />
                    <View style={{ padding:3, paddingLeft:10, paddingRight:10, margin:1 }}>
                        <View style={{ padding:2, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <Text size={12} color={Colors.brand.midnight} textAlign='center'>WORKING</Text>
                        </View>
                        <View style={{ padding:2 }}>
                            <Text size={12} color={Colors.brand.midnight} weight='bold' textAlign='center'>${o_side_order_stats.open_amt.toFixed(2)}</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular' textAlign='center'>{MarketMaketHelpers.getOddsLabel(o_side_order_stats.open_odds)}</Text>
                        </View>
                        {o_side_order_stats.open_amt > 0.01 ?
                        <Button
                            title='CANCEL'
                            title_color={Colors.shades.white}
                            padding={5}
                            title_size={10}
                            borderRadius={2}
                            backgroundColor={Colors.utility.error}
                            onPress={() => onClearMarket(o_side_orders.map(o => o.order_id))}
                        />
                        :
                        <Button
                            title='WORK'
                            disabled={!work_allowed}
                            style={{ opacity: work_allowed ? 1 : 0.5 }}
                            title_color={Colors.shades.white}
                            padding={5}
                            title_size={10}
                            borderRadius={2}
                            backgroundColor={Colors.brand.electric}
                            onPress={() => handleActionSelect(o_price)}
                        />
                        }
                    </View>
                    {o_side_ba ?
                    <View style={{ backgroundColor:MarketMaketHelpers.getOrderColor(o_side_ba), padding:3, paddingLeft:10, paddingRight:10, margin:1}}>
                        <View style={{ padding:2, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <Text size={12} color={Colors.brand.midnight} textAlign='center'>AVAIL</Text>
                        </View>
                        <View style={{ padding:2 }}>
                            <Text size={12} color={Colors.brand.midnight} weight='bold' textAlign='center'>${o_side_ba.open_amt.toFixed(2)}</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular' textAlign='center'>{MarketMaketHelpers.getOddsLabel(o_side_ba.odds)}</Text>
                        </View>
                        {!o_side_ba_mine ?
                        <Button
                            title='BUY'
                            title_color={Colors.shades.white}
                            disabled={!work_allowed}
                            style={{ opacity: work_allowed ? 1 : 0.5 }}
                            padding={5}
                            title_size={10}
                            borderRadius={2}
                            backgroundColor={Colors.utility.success}
                            onPress={() => handleSelectBA(o_side_ba)}
                        />
                        :
                        <Button
                            title='MINE'
                            title_color={Colors.shades.white}
                            disabled
                            style={{ opacity:0.5 }}
                            padding={5}
                            title_size={10}
                            borderRadius={2}
                            backgroundColor={Colors.brand.slate}
                            onPress={() => console.log('')}
                        />
                        }
                    </View>
                    :<></>}
                </View>
                <View style={{ borderWidth:1, borderColor:Colors.brand.midnight, height:70, alignSelf:'center', margin:5 }} />
                <View style={{ flex:1, flexDirection:'row', justifyContent:'flex-end' }}>
                {trade_side_ba ?
                    <View style={{ backgroundColor:MarketMaketHelpers.getOrderColor(trade_side_ba), padding:3, paddingLeft:10, paddingRight:10, margin:1}}>
                        <View style={{ padding:2, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <Text size={12} color={Colors.brand.midnight} textAlign='center'>AVAIL</Text>
                        </View>
                        <View style={{ padding:2 }}>
                            <Text size={12} color={Colors.brand.midnight} weight='bold' textAlign='center'>${trade_side_ba.open_amt.toFixed(2)}</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular' textAlign='center'>{MarketMaketHelpers.getOddsLabel(trade_side_ba.odds)}</Text>
                        </View>
                        {!trade_side_ba_mine ?
                        <Button
                            title='BUY'
                            title_color={Colors.shades.white}
                            disabled={!work_allowed}
                            style={{ opacity: work_allowed ? 1 : 0.5 }}
                            padding={5}
                            title_size={10}
                            borderRadius={2}
                            backgroundColor={Colors.utility.success}
                            onPress={() => handleSelectBA(trade_side_ba)}
                        />
                        :
                        <Button
                            title='MINE'
                            title_color={Colors.shades.white}
                            disabled
                            style={{ opacity:0.5 }}
                            padding={5}
                            title_size={10}
                            borderRadius={2}
                            backgroundColor={Colors.brand.slate}
                            onPress={() => console.log('')}
                        />
                        }
                    </View>
                :<></>}
                <View style={{  padding:3, paddingLeft:10, paddingRight:10, margin:1 }}>
                    <View style={{ padding:2, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                        <Text size={12} color={Colors.brand.midnight} textAlign='center'>WORKING</Text>
                    </View>
                    <View style={{ padding:2 }}>
                        <Text size={12} color={Colors.brand.midnight} weight='bold' textAlign='center'>${trade_side_order_stats.open_amt.toFixed(2)}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular' textAlign='center'>{MarketMaketHelpers.getOddsLabel(trade_side_order_stats.open_odds)}</Text>
                    </View>
                    {trade_side_order_stats.open_amt > 0.01 ?
                    <Button
                        title='CANCEL'
                        disabled={trade_side_order_stats.open_amt < 0.01 ? true : false}
                        style={{ opacity: trade_side_order_stats.open_amt < 0.01 ? 0.5: 1 }}
                        title_color={Colors.shades.white}
                        padding={5}
                        title_size={10}
                        borderRadius={2}
                        backgroundColor={Colors.utility.error}
                        onPress={() => onClearMarket(trade_side_orders.map(o => o.order_id))}
                    />
                    :
                    <Button
                        title='WORK'
                        title_color={Colors.shades.white}
                        disabled={!work_allowed}
                        style={{ opacity: work_allowed ? 1 : 0.5 }}
                        padding={5}
                        title_size={10}
                        borderRadius={2}
                        backgroundColor={Colors.brand.electric}
                        onPress={() => handleActionSelect(trade_price)}
                    />
                    }
                </View>
                <View style={{ flex:1 }} />
                <View style={{ backgroundColor:Colors.shades.shade100, padding:3, paddingLeft:10, paddingRight:10, margin:1 }}>
                        <View style={{ padding:2 }}>
                            <Text size={12} color={Colors.brand.midnight}>DEPLOYED</Text>
                        </View>
                        <View style={{ flex:1, padding:2 }}>
                            <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign='center'>${trade_side_order_stats.stake_potential_winnings.toFixed(2)}</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} textAlign='center'>{MarketMaketHelpers.getOddsLabel(trade_side_order_stats.stake_odds)}</Text>
                        </View>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} textAlign='center'>To Win</Text>
                    </View>
                </View>
            </View>
            <WeightProgress
                min_value={0}
                max_value={100}
                value={stake_weight.weighted_pct}
                bar_height={10}
            />
        </View>
        
        
    )
}

export default ContestMarket



/*
<View style={{}}>
                    <View style={{ height:50, borderWidth:2, borderColor:Colors.brand.midnight }} />
                    {all_order_stats.open_amt == 0 ?
                    <Button
                        title='SET'
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.brand.electric}
                        onPress={() => {
                            if(!event){ return alert('Unable to set market') }
                            const set_orders = MarketMaketHelpers.setMarkets(fund, var_1_available, var_1_prices, market, .05, event);
                            if(set_orders.length != 2){ return alert('Unable to set market at this time') }
                            return onSetMarket(set_orders, market, var_1_prices);
                        }}
                    />
                    :
                    <Button
                        title='X'
                        backgroundColor={Colors.utility.error}
                        title_color={Colors.shades.white}
                        onPress={() => onClearMarket(orders.map(o => o.order_id))}
                    />
                    }
                </View>
*/


/*
<View style={{ flexDirection:'row' }}>
            <View style={{flex:1}}>
                <View style={{ flexDirection:'row' }}>
                    <Text style={{ flex:0.5, padding:5 }} textAlign='center' size={14} color={Colors.brand.midnight}>Side</Text>
                    <Text style={{ flex:1, padding:5 }} textAlign='center' size={14}>Available</Text>
                    <Text style={{ flex:1, padding:5 }} textAlign='center' size={14}>My Action</Text>
                    <Text style={{ flex:1, padding:5, backgroundColor:Colors.shades.shade100 }} color={Colors.brand.midnight} weight='bold' textAlign='center' size={14}>Total Position</Text>
                </View>
                <View nativeID='o_side' style={{ flex:1, flexDirection:'row' }}>
                    <View style={{ flex:0.5, margin:2, padding:10 }}>
                        <Text size={14} color={Colors.brand.midnight} textAlign='center'>{market.side_options.find(o => o.side != market.trade_side)?.side.toUpperCase()}</Text>
                    </View>
                    <View style={{ flex:1, margin:2 }}>
                        {o_side_ba ?
                        <View style={{ flex:1, flexDirection:'row', alignItems:'center', borderRadius:8, borderWidth:1, borderColor:Colors.shades.shade600 }}>
                            {!o_side_ba_mine ?
                            <Button
                                title='B'
                                title_color={Colors.shades.white}
                                style={{ marginLeft:2 }}
                                borderRadius={6}
                                backgroundColor={Colors.utility.success}
                                onPress={() => handleSelectBA(o_side_ba)}
                            />
                            :<></>}
                            <Text style={{ padding:10, flex:1 }} size={14} color={Colors.brand.midnight} textAlign='center'>${o_side_ba.open_amt.toFixed(2)}</Text>
                            <Text style={{ padding:10, backgroundColor:MarketMaketHelpers.getOrderColor(o_side_ba), borderTopRightRadius:8, borderBottomRightRadius:8 }}>({MarketMaketHelpers.getOddsLabel(o_side_ba.odds)})</Text>
                        </View>
                        :<></>}                
                    </View>
                <View style={{ flex:1, margin:2 }}>
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', borderRadius:8, borderWidth:1, borderColor:Colors.shades.shade600 }}>
                        {o_side_order_stats.open_amt > 0 ?
                        <Button
                            title='C'
                            title_color={Colors.shades.white}
                            style={{ marginLeft:2 }}
                            borderRadius={6}
                            backgroundColor={Colors.utility.error}
                            onPress={() => onClearMarket(o_side_orders.filter(o => o.status == 'approved').map(o => o.order_id))}
                        />
                        :<></>}
                        <Text style={{ padding:10, flex:1 }} size={14} color={Colors.brand.midnight} textAlign='center'>${o_side_order_stats.open_earnings.toFixed(2)}</Text>
                        <Text style={{ padding:10, backgroundColor:Colors.shades.shade100, borderTopRightRadius:8, borderBottomRightRadius:8 }}>({MarketMaketHelpers.getOddsLabel(o_side_order_stats.open_odds)})</Text>
                    </View>
                </View>
                <View style={{ flex:1, padding:2, backgroundColor:Colors.shades.shade100, justifyContent:'center' }}>
                    <TouchableOpacity style={{}}>
                        <Text size={14} color={Colors.brand.midnight} textAlign='center' weight='bold'>${o_side_order_stats.stake_amt.toFixed(2)} to win ${o_side_order_stats.stake_potential_winnings.toFixed(2)}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <View nativeID='trade_side' style={{ flex:1, flexDirection:'row' }}>
                <View style={{ flex:0.5, margin:2, padding:10 }}>
                    <Text size={14} color={Colors.brand.midnight} textAlign='center'>{market.trade_side.toUpperCase()}</Text>
                </View>
                <View style={{ flex:1, margin:2 }}>
                    {trade_side_ba ?
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', borderRadius:8, borderWidth:1, borderColor:Colors.shades.shade600 }}>
                        {!trade_side_ba_mine ?
                        <Button
                            title='B'
                            title_color={Colors.shades.white}
                            style={{ marginLeft:2 }}
                            borderRadius={6}
                            backgroundColor={Colors.utility.success}
                            onPress={() => handleSelectBA(trade_side_ba)}
                        />
                        :<></>}
                        <Text style={{ padding:10, flex:1 }} size={14} color={Colors.brand.midnight} textAlign='center' weight='bold'>${trade_side_ba.open_amt.toFixed(2)}</Text>
                        <Text style={{ padding:10, backgroundColor:MarketMaketHelpers.getOrderColor(trade_side_ba), borderTopRightRadius:8, borderBottomRightRadius:8 }}>({MarketMaketHelpers.getOddsLabel(trade_side_ba.odds)})</Text>
                    </View>
                    :<></>}                
                </View>
                <View style={{ flex:1, margin:2 }}>
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', borderRadius:8, borderWidth:1, borderColor:Colors.shades.shade600 }}>
                        {trade_side_order_stats.open_amt > 0 ?
                        <Button
                            title='C'
                            title_color={Colors.shades.white}
                            style={{ marginLeft:2 }}
                            borderRadius={6}
                            backgroundColor={Colors.utility.error}
                            onPress={() => onClearMarket(trade_side_orders.filter(o => o.status == 'approved').map(o => o.order_id))}
                        />
                        :<></>}
                        <Text style={{ padding:10, flex:1 }} size={14} color={Colors.brand.midnight} textAlign='center'>${trade_side_order_stats.open_earnings.toFixed(2)}</Text>
                        <Text style={{ padding:10, backgroundColor:Colors.shades.shade100, borderTopRightRadius:8, borderBottomRightRadius:8 }}>({MarketMaketHelpers.getOddsLabel(trade_side_order_stats.open_odds)})</Text>
                    </View>
                    
                </View>
                <View style={{ flex:1, padding:2, backgroundColor:Colors.shades.shade100, justifyContent:'center' }}>
                    <TouchableOpacity style={{}}>
                        <Text size={14} color={Colors.brand.midnight} textAlign='center' weight='bold'>${trade_side_order_stats.stake_amt.toFixed(2)} to win ${trade_side_order_stats.stake_potential_winnings.toFixed(2)}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            
        </View>
        </View>
            <View style={{ ...view_styles.section_footer }}>
                <View style={{ flex:1, flexDirection:'row' }}>
                    {o_side_order_stats.open_amt + trade_side_order_stats.open_amt > 0 ?
                    <Button
                        title='CLEAR'
                        style={{ marginRight:4 }}
                        backgroundColor={Colors.utility.error}
                        title_color={Colors.shades.white}
                        onPress={() => onClearMarket(orders.map(o => o.order_id))}
                    />
                    :<></>}
                    <Button
                        title='Set Market'
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.utility.success}
                        onPress={() => {
                            if(!event){ return alert('Unable to set market') }
                            const set_orders = MarketMaketHelpers.setMarkets(fund, var_1_available, var_1_prices, market, .05, event);
                            if(set_orders.length != 2){ return alert('Unable to set market at this time') }
                            return onSetMarket(set_orders, market, var_1_prices);
                        }}
                    />
                </View>
                <Text size={14} weight='bold' color={Colors.brand.midnight}>${(Math.min(o_side_order_stats.stake_potential_winnings, trade_side_order_stats.stake_potential_winnings) - all_order_stats.stake_amt).toFixed(2)}</Text>
            </View>
*/