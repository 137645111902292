import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, ScrollView, Image } from 'react-native';
import type { PlayerFollowerProps, PublicPlayerProps } from '../../../types';
import { SocialProfileApi } from '../api';
import Colors from '../../../constants/colors';
import { view_styles } from '../../../constants/styles';
import { Button, Text } from '../../../Components';
import PlayerList from '../../PlayerList';

type PlayerFollowersListProps = {
    status: 'following'|'followers',
    viewing_player:PublicPlayerProps,
    total?:number,
    player_id?:string,
    height?:number,
    onClose:() => void
}

const PlayerFollowersList = ({ status, total, height, viewing_player, player_id, onClose }:PlayerFollowersListProps) => {
    const [ list_data, setData ] = useState<{
        loading:boolean,
        player_followers:PlayerFollowerProps[],
        offset:number
    }>({
        loading:false,
        player_followers:[],
        offset:0
    });
    const { loading, player_followers, offset } = list_data;

    useEffect(() => {
        getDataFromServer(0);
    },[]);

    const getDataFromServer = async(offset:number) => {
        if(loading){ return } //No mashy mashy
        setData({ ...list_data, loading:true });
        let pfs = await SocialProfileApi.getPlayerFollowersByPlayerId(viewing_player.player_id, status, offset);
        setData({
            ...list_data,
            loading:false,
            player_followers:pfs,
            offset
        })
    }


    return (
        <View style={{ height, ...view_styles.section }}>
            <View style={{ ...view_styles.section_header }}>
                <Image
                    source={{ uri: viewing_player.profile_pic && viewing_player.profile_pic != '' ? viewing_player.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ width: 32, height: 32, borderRadius:100 }}
                    resizeMode='cover'
                />
                <View style={{flex:1, marginLeft:10 }}>
                    <Text theme='header'>{status}</Text>
                    <Text style={{ marginTop:3 }} theme='body'>{status == 'followers' ? `@${viewing_player.username} Followers`: `Players @${viewing_player.username} is following`}</Text>
                </View>
                {total ?
                <Text theme='header'>{total}</Text>
                :<></>}
            </View>
            <ScrollView style={{ ...view_styles.section_body }}>
                {loading ? 
                <ActivityIndicator
                    size={'large'}
                    color={Colors.brand.midnight}
                    style={{ padding:10, alignSelf:'center' }}
                />
                :<></>}
                <PlayerList
                    players={player_followers.map(pf => pf.playerDetails as PublicPlayerProps)}
                    onPlayerSelect={(p) => console.log(p)}
                    options={{
                        direction: 'horizontal',
                        follow_status: player_id?true:false
                    }}
                />
            </ScrollView>
            <View style={{ ...view_styles.section_footer }}>
                {offset > 0 ?
                <Button
                    title='PREV'
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => getDataFromServer(offset - 1)}
                />
                :<View/>}
                <View style={{ flex:1 }} />
                <Button
                    title='NEXT'
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => getDataFromServer(offset + 1)}
                />
            </View>
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    style={{ flex:1 }}
                    title='CLOSE'
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
        </View>
    )
}

export default PlayerFollowersList