import { TextInput, View } from "react-native"
import Button from "./Button";
import React, { useState } from "react";
import Icons from "./Icons";
import Colors from "../constants/colors";

type SearchBoxProps = {
    onSearch?: (text:string) => void,
    onChange? : (text:string) => void,
    hide_search_button?:boolean,
    placeholder?: string,
    style?:any
}

const SearchBox = ({ placeholder, onSearch, onChange, hide_search_button, style }:SearchBoxProps) => {
    const [ search_value, setSearchValue ] = useState('');

    const handleSearch = () => {
        if(!onSearch){ return }
        if(search_value == ''){ return alert('Please provide search value') }
        onSearch(search_value)
        setSearchValue('')
    }

    return (
        <View style={{ flex:1, flexDirection:'row', alignItems:'center', padding:5 }}>
            <View style={{ flex:1, flexDirection:'row', alignItems:'center', marginRight:10, borderWidth:1, borderRadius:8, borderColor:Colors.shades.shade400 }}>
                <View style={{ padding:10 }}>
                    <Icons.SearchIcon size={12} color={Colors.brand.midnight} />
                </View>
                <TextInput
                    placeholder={placeholder ?? 'Search'}
                    value={search_value}
                    placeholderTextColor={Colors.shades.shade400}
                    style={{ flex:1, padding:10, color:Colors.brand.midnight, fontFamily: 'barlow-semibold', fontSize:14, ...style }}
                    onChangeText={(text) => {
                        setSearchValue(text)
                        if(onChange){ onChange(text) }
                    }}
                />
            </View>
            {!hide_search_button ?
            <Button
                title="Search"
                title_color={Colors.shades.white}
                backgroundColor={Colors.brand.midnight}
                borderRadius={8}
                onPress={() => handleSearch()}
            />
            :<></>}
        </View>
    )
}

export default SearchBox