import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Text } from '../../Components';
import Colors from '../../constants/colors';
import type { PlayerSquareProps, SquareOfferProps, SquareProps } from '../../types';
import { SquaresApi } from '../api';
import { view_styles } from '../../constants/styles';

type SquareOfferCardProps = { 
    player_id?:string, 
    square:SquareProps, 
    player_square:PlayerSquareProps, 
    square_offer:SquareOfferProps, 
    home_abbr?:string,
    away_abbr?:string,
    onRequestAuthenticate: () => void
}
const SquareOfferCard = ({ player_id, square, player_square, square_offer, away_abbr, home_abbr, onRequestAuthenticate }: SquareOfferCardProps ) => {
    const [ loading, setLoading ] = useState(false)
    const handleResponse = async(response:string) => {
        if(loading){ return  } //No mashymashy
        if(!player_id){ onRequestAuthenticate() }
        setLoading(true)
        switch(response){
            case 'accepted':
                await SquaresApi.offserSquare({ ...square_offer, status: 'accepted' })
                break
            case 'rejected':
                await SquaresApi.offserSquare({ ...square_offer, status: 'rejected' })
                break
            case 'withdrawn':
                await SquaresApi.offserSquare({ ...square_offer, status: 'withdrawn' })
                break
            default: break
        }
        setLoading(false)
    }
    
    
    const cl = square_offer.market_type === 'FOR_MONEY' ? '$' : 'E'
    const mine = square_offer.offerer_id == player_id ? true : false
    return (
        <View style={{ ...view_styles.section, minWidth:250, flex:1 }}>
            <View style={{ ...view_styles.section_header, backgroundColor:square_offer.buy_sell_ind == 'buy' ? Colors.brand.midnight : Colors.brand.cyan}}>
                <Text style={{flex:1}} size={16} weight='bold' color={Colors.shades.white}>Squares{square_offer.buy_sell_ind === 'sell'?' Sale':' Buy'} Offer</Text>
            </View>
            <View style={view_styles.section_body}>
                <View style={view_styles.body_row}>
                    <Text style={{ flex:1 }} theme='body'>Square</Text>
                    <Text theme='header_2'>{home_abbr+': '??''}{square.square_score_x}, {away_abbr+': '??''}{square.square_score_y}</Text>
                </View>
                <View style={view_styles.body_row}>
                    <Text style={{ flex:1 }} theme='body'>Purchased For</Text>
                    <Text theme='header_2'>{cl}{player_square.purchase_price.toFixed(2)}</Text>
                </View>
                <View style={view_styles.body_row}>
                    <Text style={{ flex:1 }} theme='body'>Offer Amount</Text>
                    <Text theme='header_2'>{cl}{square_offer.amount.toFixed(2)}</Text>
                </View>
            </View>
            <View style={{ ...view_styles.section_footer,backgroundColor:square_offer.buy_sell_ind == 'buy' ? Colors.brand.midnight : Colors.brand.cyan, }}>
            {mine ?
                <View style={{  flexDirection:'row', alignItems:'center', borderBottomRightRadius:10, borderBottomLeftRadius:10}}>
                    <View style={{flex:1, maxWidth:140}}>
                        <Text size={12} color={Colors.shades.white} weight='semibold'>WITHDRAW OFFER?</Text>
                        <Text size={12} color={Colors.shades.white} weight='regular'>Do you wish to withdraw this offer?</Text>
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center', paddingLeft:10}}>
                        <TouchableOpacity style={{flex:1, borderWidth:1, borderColor:Colors.shades.white, borderRadius:60, padding:10, marginLeft:4}} onPress={() => handleResponse('withdrawn')}>
                            <Text size={12} weight='semibold' textAlign='center' color={Colors.shades.white}>Withdraw</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                :
                <View style={{ flexDirection:'row', alignItems:'center', borderBottomRightRadius:10, borderBottomLeftRadius:10}}>
                    <View style={{flex:1, maxWidth:140}}>
                        <Text size={12} color={Colors.shades.white} weight='semibold'>ACCEPT OFFER?</Text>
                        <Text size={12} color={Colors.shades.white} weight='regular'>Do you wish to sell your square for this offer?</Text>
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center', paddingLeft:10}}>
                        <TouchableOpacity style={{flex:1, borderRadius:60, padding:10, marginRight:4, backgroundColor:Colors.shades.white}} onPress={() => handleResponse('accepted')}>
                            <Text size={12} weight='semibold' textAlign='center' color={Colors.brand.midnight}>Accept</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{flex:1, borderWidth:1, borderColor:Colors.shades.white, borderRadius:60, padding:10, minWidth:75, marginLeft:4}} onPress={() => handleResponse('rejected')}>
                            <Text size={12} weight='semibold' textAlign='center' color={Colors.shades.white}>Decline</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                }
            </View>
            
        </View>
    )
}


export default SquareOfferCard