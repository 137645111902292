import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView, FlatList, TouchableOpacity, ActivityIndicator } from 'react-native';
import type { EventProps, OrderProps, TeamProps, TradeProps } from '../../types';
import { EventComponentApi } from '../api';
import { Text } from '../../Components';
import LinearGradient from 'react-native-linear-gradient';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';
import TeamEventList from '../../MarketComponents/components/TeamEventList';

type TeamProfileProps = {
    team_id:string,
    height:number,
    distinct_id?:string,
    show_grades?:boolean,
    show_id?:boolean,
    init_expanded_ids?:string[],
    onTeamSelect?:(team_id:string) => void,
    onActivate?:(event_id:string, event_type:string) => void,
    onViewEvent?:(data:{ event_id:string, event_type:string, market_id:string, side_id?:string, side_type?:string }) => void,
    onOrder?:(o:OrderProps) => void,
    onCompetitionSelect?:(competition_id:string) => void,
    onSquaresSelect?:(sq_comp_id:string) => void,
    onTradeLongPress?:(trade:TradeProps) => void,
    onViewAdditionalMarkets?:(event_id:string, event_type:string) => void
}

const TeamProfile = ({ team_id, height, distinct_id, show_grades, show_id, init_expanded_ids, onTeamSelect, onActivate, onViewEvent, onOrder, onCompetitionSelect, onSquaresSelect, onTradeLongPress, onViewAdditionalMarkets }: TeamProfileProps ) => {
    const [ module_size, setModuleSize ] = useState({ height:777, width:330 });
    const [ team_data, setData ] = useState<{
        loading:boolean,
        team?:TeamProps,
        event_toggle:string,
    }>({
        loading: false,
        event_toggle: 'Upcoming',
    });
    const { team, event_toggle } = team_data;
    const [ event_data, setEventData ] = useState<{
        event_loading:boolean,
        events:EventProps[],
        event_offset:number
    }>({
        event_loading: false,
        events: [],
        event_offset: 0
    });
    const { event_loading, events } = event_data;

    useEffect(() => {
        EventComponentApi.setEnvironment();
        getTeamData(team_id)
    },[team_id]);

    useEffect(() => {
        getEventData(0);
    },[event_toggle])

    const getTeamData = async(id:string) => {
        setData({ ...team_data, loading:true });
        const team = await EventComponentApi.getTeamById(id);
        setData({
            ...team_data,
            loading: false,
            team:team,
        })
    }

    const getEventData = async(offset:number) => {
        setEventData({ ...event_data, event_loading:true });
        let evs:EventProps[] = [];
        switch(event_toggle){
            case 'Upcoming':
                evs = await EventComponentApi.getUpcomingScheduleByTeams(team_id, offset);
                break
            case 'Past':
                evs = await EventComponentApi.getPastScheduleByTeams(team_id, offset);
                break
            default: break
        }
        setEventData({
            ...event_data,
            event_loading: false,
            event_offset: offset,
            events: evs
        })
    }



    const renderEventToggles = (data:{ item:string, index:number }) => {
        const active = data.item == event_toggle ? true : false
        return (
            <TouchableOpacity style={{ width: (module_size.width - 65) / 2, padding:15, borderRadius:22, backgroundColor:active?Colors.brand.midnight:'transparent' }}
            onPress={() => setData({ ...team_data, event_toggle: data.item })}>
                <Text textAlign='center' weight='bold' size={14} color={active ?Colors.shades.white:Colors.brand.midnight}>{data.item}</Text>
            </TouchableOpacity>
        )
    }

    if(!team){ return <></> }
    return (
        <View style={{ height }} onLayout={(ev) => {
            const { height, width } = ev.nativeEvent.layout;
            setModuleSize({ height, width })
        }}>
            <View style={{ position:'absolute', top:0, left:0, right:0 }}>
                <LinearGradient style={{ opacity:0.5, height:module_size.width, borderBottomLeftRadius:40, borderBottomRightRadius:40 }} colors={[team.primary_color ?? Colors.brand.midnightTopGradient, team.secondary_color ?? Colors.brand.midnight]} nativeID='team_header'/>        
                <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, alignItems:'center' }}>
                    <Image
                        source={{ uri:team.image?.url }}
                        style={{ height: module_size.width*0.75, width:module_size.width*0.75 }}
                        resizeMode='cover'
                    />
                </View>
            </View>
            <ScrollView style={{ flex:1 }}>
                <View style={{ ...view_styles.section, marginTop:module_size.width *0.5 }}>
                    <View style={{ ...view_styles.section_header }}>
                        <Image
                            style={{ height:40, width:40 }}
                            source={{ uri: team.image?.url }}
                            resizeMode='cover'
                        />
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text theme='header'>{team.market_name} {team.name}</Text>
                            <Text style={{ marginTop:4 }} theme='body_2'>{team.division} - {team.sub_division}</Text>
                        </View>
                        {team.rank ?
                        <Text size={22} color={Colors.brand.cyan} weight='bold'>{team.rank}</Text>
                        :<></>}
                    </View>
                </View>

                <View style={{ ...view_styles.section }}>
                    <View style={{ ...view_styles.section_header }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header'>Events</Text>
                            <Text style={{ marginTop:3 }} theme='body_2'>Events that this team is participating in</Text>
                        </View>
                    </View>
                    <View style={{ ...view_styles.section_body, padding:0 }}>
                        <View style={{ ...view_styles.body_row, margin:10, marginLeft:20, marginRight:20, borderRadius:22, padding:1, backgroundColor:Colors.shades.shade100 }}>
                            <FlatList
                                key={'toggles'}
                                horizontal
                                showsHorizontalScrollIndicator={false}
                                data={['Upcoming', 'Past']}
                                renderItem={renderEventToggles}
                                keyExtractor={(item) => item}

                            />
                        </View>
                        {event_loading ?
                        <ActivityIndicator
                            size='large'
                            color={Colors.brand.midnight}
                            style={{ padding:20, alignSelf:'center' }}
                        />
                        :<></>}
                        <TeamEventList
                            list_key={`${team_id}_${event_toggle}`}
                            distinct_id={distinct_id}
                            show_id={show_id}
                            show_grades={show_grades}
                            init_expanded_ids={init_expanded_ids}
                            onTeamSelect={onTeamSelect}
                            events={events}
                            onTradeLongPress={onTradeLongPress}
                            onActivate={onActivate}
                            onCompetitionSelect={onCompetitionSelect}
                            onSquaresSelect={onSquaresSelect}
                            onOrder={onOrder}
                            onViewEvent={onViewEvent}
                            onViewAdditionalMarkets={onViewAdditionalMarkets}

                        />
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

export default TeamProfile