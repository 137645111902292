import React from 'react';
import { FlatList, TouchableOpacity, View, Image } from 'react-native';
import { Icons, Text } from '../../Components';
import Colors from '../../constants/colors';

type OptionType = { key:string, label:string, description: string }
const options:OptionType[] = [
    { key: 'brackets', label: 'Active League Brackets', description: 'View all brackets that are currently active' },
    { key: 'my_brackets', label: 'My Brackets', description: 'View all my filled out brackets' },
    { key: 'competitions', label: 'Bracket Competitions', description: 'View all bracket competitions' }
]

type RoomMenuProps = {
    visible: boolean,
    onClose?:() => void,
    onOptionSelect:(option:OptionType) => void
}

const RoomMenu = ({ visible, onOptionSelect, onClose }: RoomMenuProps ) => {

    const renderOptions = (data: { item:OptionType, index:number }) => {
        return (
            <TouchableOpacity style={{ flexDirection:'row', padding:20, alignItems:'center', borderBottomWidth:1, borderColor:Colors.shades.shade600 }}
            onPress={() => onOptionSelect(data.item)}>
                <View style={{ flex:1 }}>
                    <Text size={16} color={Colors.brand.midnight} weight='bold'>{data.item.label}</Text>
                    <Text style={{ marginTop:3 }} size={14} color={Colors.brand.slate} weight='regular'>{data.item.description}</Text>
                </View>
                <Icons.ChevronIcon direction='right' color={Colors.brand.midnight} size={12} />
            </TouchableOpacity>
        )
    }

    if(!visible){ return <></> }
    return (
        <View>
            <TouchableOpacity
                disabled={!onClose}
                style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}
                onPress={() => onClose ? onClose() : console.log('')}
                >
                {onClose ?
                <View style={{ marginRight:20 }}>
                    <Icons.ChevronIcon direction='left' color={Colors.brand.midnight} size={12} />
                </View>
                :<></>}
                <View style={{ flex:1 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='bold'>Bracket Room</Text>
                    <Text style={{ marginTop:4 }} size={14} color={Colors.brand.slate} weight='regular'>Select an option below to continue</Text>
                </View>
                <Image
                    source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1677259366/Bracket_image_h0fkku.webp' }}
                    style={{ width:70, height:45, borderRadius:4 }}
                    resizeMode='cover'
                />
            </TouchableOpacity>
            <View style={{ padding:10 }}>
                <FlatList
                    data={options}
                    renderItem={renderOptions}
                    keyExtractor={(item) => item.key}
                />
            </View>
        </View>
    )
}

export default RoomMenu