import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import type { AccountProps } from '../../types';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { WalletApi } from '../api';

type UnlinkAccountCardProps = {
    account:AccountProps,
    onCancel: () => void,
    onRemove: (acct:AccountProps) => void
}

const RemoveAccountCard = ({ account, onCancel, onRemove }:UnlinkAccountCardProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ disclaimer, setDisclaimer ] = useState(false);

    //Player accounts
    //Filter to ensure only this player and not inactive accounts

    const handleRemove = async() => {
        if(!account){ return }
        if(loading){ return }
        if(!disclaimer){ return }
        setLoading(true);
        const resp = await WalletApi.removeAccount(account.account_id)
        onRemove(resp.account)
        setLoading(false);
    }

    return (
        <View style={{ flex:1 }}>
            <View nativeID='snooze_disclaimer' style={{ flex:1, margin:10 }}>
                <TouchableOpacity style={{ flexDirection:'row', padding:5 }} onPress={() => setDisclaimer(!disclaimer)}>
                    <View style={{ justifyContent:'center', marginRight:10, alignItems:'center', height:25, width:25, borderRadius:4, backgroundColor: disclaimer ? Colors.brand.midnight : 'transparent', borderWidth:1, borderColor:Colors.brand.midnight }}>
                        {disclaimer ?
                        <Icons.CheckIcon size={14} color={Colors.shades.white} />
                        :<></>}
                    </View>
                    <Text size={14} color={Colors.brand.midnight} weight='regular'>I confirm my request to remove my account.  I understand that this will take up to 24 hours to come into effect. I understand that I will no longer be able to use this account to transact.</Text>
                </TouchableOpacity>
            </View>
            <View nativeID='action_row' style={{ flexDirection:'row', padding:20 }}>
                <Button
                    style={{ flex:1, marginRight:5, opacity: 1 }}
                    title={'CANCEL'}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => onCancel()}
                />
                <Button
                    style={{ flex:3, opacity: !disclaimer||loading?0.5:1 }}
                    disabled={!disclaimer}
                    loading={loading}
                    title={'REMOVE ACCOUNT'}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    padding={15}
                    onPress={() => handleRemove()}
                />
            </View>
        </View>
    )
}

export default RemoveAccountCard