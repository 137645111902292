import TeamEventList from "../MarketComponents/components/TeamEventList";
import AthleteCard from "./AthleteCard";
import TeamCard from "./TeamCard";
import TeamProfile from "./TeamProfile";


export default {
    TeamCard,
    AthleteCard,
    TeamProfile,
    TeamEventList
}