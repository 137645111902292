import axios from 'axios';
import { APIOverrides } from "../../ApiOverrides";
import type { BracketCompetitionProps, BracketProps, CompanyProps, CompetitionPayoutTypeProps, CompetitionProps, CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionTypeProps, EventProps, LeagueProps, PublicPlayerProps, SquaresCompetitionProps } from '../../types';

let AUTH_SVC_API = ''
let TP_SVC_API = ''
let EVENT_SVC_API = ''

export { EngageApi, EngageHelpers }

const EngageApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        TP_SVC_API = endpoints['TP_SVC_API'] as string;
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getActivePublicCompetitions: async():Promise<CompetitionProps[]> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v2/competitions/public`)
            return resp.data.competitions
        } catch (e) {
            console.log(e)
            return []
        }
    },
    searchCompanies: async(search_value:string, offset:number):Promise<CompanyProps[]> => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/companies/search`, { search_value, offset })
            return resp.data.companies
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getPrivateEngage: async(code:string):Promise<{ competition?:CompetitionProps, squares_competition?:SquaresCompetitionProps, competition_season?:CompetitionSeasonProps, bracket_competition?:BracketCompetitionProps }> => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v2/competitions/private`, { code })
            return resp.data;
        } catch (e) {
            console.log(e)
            return {}
        }
    },
    getActivePublicSeasons: async():Promise<CompetitionSeasonProps[]> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v2/competitions/seasons/public`)
            return resp.data.competition_seasons
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getActivePublicSquares: async():Promise<SquaresCompetitionProps[]> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/squares/public`)
            return resp.data.squares_competitions
        } catch (e) {
            return []
        }
    },
    getLeagues: async():Promise<LeagueProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues`)
            return resp.data.leagues
        } catch (e) {
            console.log(e)
            return []
        }

    },
    getBracketsByIds: async(bracket_ids:string[]):Promise<BracketProps[]> => {
        try {
            if(bracket_ids.length == 0){ return [] }
            const resp = await axios.post(`${EVENT_SVC_API}/v1/brackets/bulk/get`, { bracket_ids })
            return resp.data.brackets
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getEventsByEventIds: async(event_ids:string[]):Promise<EventProps[]> => {
        try {
            if(event_ids.length == 0){ return [] }
            const resp = await axios.post(`${EVENT_SVC_API}/v1/events/bulk/get`, { attribute:'event_id', values: event_ids })
            return resp.data.events
        } catch (e) {
            return []
        }
    },
    getCompetitionOptions: async():Promise<{competition_types:CompetitionTypeProps[], competition_result_types:CompetitionResultTypeProps[], competition_payout_types:CompetitionPayoutTypeProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/options`)
        return resp.data
    },
    getActivePublicBrackets: async():Promise<BracketCompetitionProps[]> => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/brackets/public`)
            return resp.data.bracket_competitions
        } catch (e) {
            return []
        }
    },
    getCompaniesByIds: async(company_ids:string[]):Promise<CompanyProps[]> => {
        try {
            if(company_ids.length == 0){ return [] }
            const resp = await axios.post(`${AUTH_SVC_API}/v1/companies/bulk/get`, { company_ids })
            return resp.data.companies
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getPlayersByPlayerIds : async(player_ids:string[]):Promise<PublicPlayerProps[]> => {
        try {
            if(player_ids.length == 0){ return [] }

            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
            return resp.data.players 
        } catch (e) {
            console.log(e)
            return []
        }
    },
}


const EngageHelpers = {
    getCompanyIdsFromEngage: (comps:CompetitionProps[], sq_comps:SquaresCompetitionProps[], brackets:BracketCompetitionProps[]) => {
        let ids = comps.filter(c => c.company_id).map(c => c.company_id ?? '0')
        ids = ids.concat(sq_comps.filter(s => s.company_id).map(s => s.company_id ?? '0'))
        ids = ids.concat(brackets.filter(b => b.company_id).map(b => b.company_id ?? '0'))
        return [ ...new Set(ids.map(id => id)) ]
    },
    getPlayerIdsFromEngage: (comps:CompetitionProps[], sq_comps:SquaresCompetitionProps[], brackets:BracketCompetitionProps[]) => {
        let ids = comps.map(c => c.admin_id);
        ids = ids.concat(comps.filter(c => c.pacer_id).map(c => c.pacer_id ?? '0'))
        ids = ids.concat(sq_comps.map(s => s.admin_id))
        ids = ids.concat(brackets.map(b => b.admin_id))
        return [ ...new Set(ids.map(id => id)) ]
    },
    getEventIdsFromSquares: (squares:SquaresCompetitionProps[]) => {
        const ids = squares.filter(s => s.event_id).map(s => s.event_id)
        return [ ...new Set(ids.map(id => id)) ] 
    },
    getBracketIdsFromBracketComps: (bc:BracketCompetitionProps[]) => {
        const ids = bc.map(c => c.bracket_id)
        return [ ...new Set(ids.map(id => id)) ] 
    },
    getSeasonPayoutAmounts: (competition_season:CompetitionSeasonProps) => {
        let total_payout = competition_season.ticket_revenue
        if(competition_season.promo_amt){ total_payout = total_payout + parseFloat(competition_season.promo_amt as string) }
        if(competition_season.guaranteed_payout && competition_season.guaranteed_payout > total_payout){ total_payout = competition_season.guaranteed_payout }

        let season_payout = parseFloat(competition_season.payout_allocation as string) * total_payout
        let competition_payout = total_payout - season_payout
        return { season_payout, competition_payout }
    },
}