import { SocketAPI } from './api/index';
import React, { useEffect, useState } from "react"
import { AppState, ActivityIndicator } from 'react-native';
import Colors from '../constants/colors';

type SocketManagerProps = {
    access_token?:string,
    distinct_id?:string,
    onConnect:() => void,
    onDisconnect:() => void,
    onSocketEvent:(event:any) => void,
    subscribed_events: string[]
}

const SocketManager = ({ access_token, distinct_id, onSocketEvent, subscribed_events, onConnect, onDisconnect }:SocketManagerProps) => {
    const [ visible, setVisible ] = useState(true);
    const [ ws, setWs ] = useState<any>();
    const [ connected, setConnected ] = useState(false);

    useEffect(() => {
        SocketAPI.setEnvironment();
        AppState.addEventListener('change', handleEventChange)
        return () => {
            console.log('closed!!!')
        }
    },[])

    useEffect(() => {

        if(visible){ 
           if(!ws || ws.readyState != 1) { return connectSocket(access_token, distinct_id) }
           if(access_token && distinct_id){ 
            console.log('authningn')
            socketAuthenticate(access_token, distinct_id) 
        }
        }
        else {
            if(ws){ ws.close() }
        }
    },[visible, access_token, distinct_id])


    const handleEventChange = (state:string) => {
        if(state == 'active'){ setVisible(true) }
        else { setVisible(false) }
    }

    const connectSocket = (access_token?:string, distinct_id?:string) => {
        let new_ws = SocketAPI.socketConnect();
        let sub:any = undefined
        new_ws.onopen = async() => {
            setConnected(true)
            onConnect();
            new_ws.send(JSON.stringify({ type: 'PING', data: new Date().toTimeString() }));
            if(access_token && distinct_id){
                new_ws.send(JSON.stringify({
                    type: 'AUTHENTICATE',
                    access_token,
                    device_id: distinct_id
                }))
            }
        }

        new_ws.onmessage = (body:any) => {
            let message = JSON.parse(body.data);
            if(message.type == 'PONG' && new_ws && new_ws.readyState == 1){ 
                setTimeout(() => { 
                    if(new_ws && new_ws.readyState == 1){
                        new_ws.send(JSON.stringify({ type: 'PING', data: new Date().toTimeString() })) 
                    }
                }, 10000); 
            }
            if(subscribed_events.includes(message.type)){ onSocketEvent(message) }
        }
        new_ws.onclose = () => {
            //setWs(undefined);
            if(sub){ sub.remove() }
            setConnected(false)
            onDisconnect();
        }
        setWs(new_ws);
    }

    const socketAuthenticate = (access_token:string, device_id:string) => {
        if(!ws || ws.readyState !== 1){ return } //Cannot set authenticate without a connected websocket
        ws.send(JSON.stringify({
            type: 'AUTHENTICATE',
            access_token,
            device_id
        }))
    }
    if(ws && connected){ return <></> }
    return (
        <ActivityIndicator style={{ position:'absolute', bottom:5, right:5 }} size='large' color={Colors.brand.midnight}/>
    )
    
}

export default SocketManager