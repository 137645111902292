import React, { useEffect, useState } from 'react';
import { View } from "react-native"
import PlayerBrackets from './components/PlayerBrackets';
import { BracketApi, BracketComeptitionApi } from './api';
import type { AthleteProps, BracketCompetitionProps, BracketProps, CompetitionPlayerBracketProps, CompetitionResultTypeProps, LeagueProps, MyPlayerProps, PlayerBalanceProps, PlayerBracketProps, TeamProps } from '../types';
import Colors from '../constants/colors';
import BracketPlay from './components/BracketPlay';
import PlayerBracketManager from './components/PlayerBracketManager';
import BracketSelector from './components/BracketSelector';
import RoomMenu from './components/RoomMenu';
import moment from 'moment-mini';
import BracketCompetitionCard from './components/BracketCompetitionCard';
import BracketCompetitionSelector from './components/BracketCompetitionSelector';

type BracketRoomProps = {
    player_id?:string,
    bracket_id?:string,
    player_balance?:PlayerBalanceProps,
    bracket_competition_id?:string,
    onShareCompetition:(bracket_competition_id:string) => void,
    onRequestAuthenticate: () => void,
    onNotEnoughBalance:(data:{ bracket_competition:BracketCompetitionProps }) => void,
    onClose?:() => void
}

const BracketRoom = ({ player_id, bracket_id, bracket_competition_id, player_balance, onRequestAuthenticate, onShareCompetition, onClose, onNotEnoughBalance }:BracketRoomProps) => {
    const [ room_size, setRoomSize ] = useState({ width:0, height:0 })
    const [ local_balance, setLocalBalance ] = useState<PlayerBalanceProps|undefined>(undefined);
    const [ room_data, setData ] = useState<{
        loaded:boolean,
        active_view: 'home' | 'select_bracket' | 'select_player_bracket' | 'play_bracket' | string,
        brackets:BracketProps[],
        leagues:LeagueProps[],
        competitions:BracketCompetitionProps[],
        competition_result_types:CompetitionResultTypeProps[],
        active_player_bracket?:PlayerBracketProps,
        active_bracket_id?:string,
        active_competition_id?:string
    }>({
        loaded:false,
        leagues: [],
        competitions:[],
        competition_result_types: [],
        active_view: 'home',
        brackets: [],
    })
    const [ my_data, setMyData ] = useState<{
        my_data_loaded:boolean,
        player?:MyPlayerProps,
        player_brackets:PlayerBracketProps[],
        champion_teams:TeamProps[],
        champion_athletes:AthleteProps[],
        bracket_competitions:BracketCompetitionProps[],
        competition_player_brackets:CompetitionPlayerBracketProps[]
    }>({
        my_data_loaded:false,
        player_brackets: [],
        champion_athletes: [],
        champion_teams: [],
        bracket_competitions: [],
        competition_player_brackets: []
    })
    const { my_data_loaded, player, player_brackets, bracket_competitions, competition_player_brackets, champion_teams, champion_athletes } = my_data;
    const { loaded, active_competition_id, brackets, active_view, active_bracket_id, active_player_bracket, competition_result_types, leagues } = room_data;

    const visible_player_brackets = player_brackets.filter(pb => pb.status != 'deleted');
    useEffect(() => {
        getData()
    },[])

    useEffect(() => {
        if(!loaded){ return }
        getMyData();
    },[player_id, loaded])

    useEffect(() => {
        if(!player_balance){ return }
        setLocalBalance(player_balance);
    },[player_balance])

    useEffect(() => {
        if(!bracket_competition_id || !loaded){ return }
        setData({ ...room_data, active_competition_id: bracket_competition_id })
    },[bracket_competition_id])

    /*
    useEffect(() => {
        if(active_brackets.length == 0){ return }
        setData({ ...room_data, active_player_bracket: active_brackets[0], active_view:'manage_bracket' })
    },[active_brackets])
    */

   
    const getMyData = async() => {
        if(!player_id){ setMyData({ ...my_data, my_data_loaded:true }) }
        let player = await BracketComeptitionApi.getMyDetails();
        let pbs = await BracketComeptitionApi.getMyPlayerBrackets();
        let champ_team_ids = pbs.player_brackets.filter(pb => pb.champion_id_type == 'team').map(b => b.champion_id ?? '')
        const champ_teams = await BracketApi.getTeamsByIds(champ_team_ids);
        let champ_athlete_ids = pbs.player_brackets.filter(pb => pb.champion_id_type == 'athlete').map(b => b.champion_id ?? '')
        const champ_athletes = await BracketApi.getAthletesByIds(champ_athlete_ids);
        setMyData({
            ...my_data,
            player: player,
            my_data_loaded: true,
            player_brackets: pbs.player_brackets.filter(pb => pb.status != 'deleted').sort((a,b) => moment(a.create_datetime).unix() - moment(b.create_datetime).unix()),
            bracket_competitions: pbs.bracket_competitions,
            champion_athletes: champ_athletes,
            champion_teams: champ_teams,
            competition_player_brackets: pbs.competition_player_brackets
        })
        const pb = await BracketComeptitionApi.getMyBalance();
        setLocalBalance(pb)
    }

    const getData = async() => {
        BracketApi.setEnvironment();
        const b = await BracketApi.getActiveBrackets();
        const ls = await BracketApi.getLeagues();
        const res = await BracketComeptitionApi.getCompetitionOptions();
        let comps = await BracketComeptitionApi.getBracketCompetitions();
        setData({
            ...room_data,
            brackets:b,
            leagues: ls,
            competition_result_types: res.competition_result_types,
            loaded: true,
            active_bracket_id: bracket_id,
            competitions: comps,
            active_competition_id: bracket_competition_id,
            active_view: bracket_competition_id ? 'competition' : bracket_id ? 'play_bracket' : 'home'
        })
        
    }

    if(!loaded || !my_data_loaded){ return }

    return (
        <View style={{ flex:1, maxWidth:600, backgroundColor:Colors.shades.white }} onLayout={(ev) => {
            const { width, height } = ev.nativeEvent.layout;
            setRoomSize({ width, height })
        }}>
            <RoomMenu
                visible={active_view == 'home' ? true : false}
                onClose={onClose}
                onOptionSelect={(option) => {
                    switch(option.key){
                        case 'brackets': return setData({ ...room_data, active_view: 'select_bracket' })
                        case 'my_brackets': {
                            if(!player_id){ return onRequestAuthenticate() }
                            return setData({ ...room_data, active_view:'select_player_bracket' })
                        }
                        case 'competitions': return setData({ ...room_data, active_view:'select_competition' })
                        default: return
                    }
                }}
            />
            <BracketSelector
                visible={active_view == 'select_bracket' ? true : false}
                brackets={brackets}
                leagues={leagues}
                onClose={() => setData({ ...room_data, active_view: 'home' })}
                onSelectBracket={async(brack) => {
                    let my_p_b = visible_player_brackets.find(b => b.bracket_id == brack.bracket_id);
                    setData({
                        ...room_data,
                        active_player_bracket:my_p_b,
                        active_bracket_id: brack.bracket_id,
                        active_view:'play_bracket'
                    })
                }}
            />
            <PlayerBrackets
                visible={active_view == 'select_player_bracket' ? true : false}
                brackets={brackets}
                leagues={leagues}
                teams={champion_teams}
                athletes={champion_athletes}
                player_brackets={visible_player_brackets}
                competition_player_brackets={competition_player_brackets}
                bracket_competitions={bracket_competitions}
                onClose={() => setData({ ...room_data, active_view:'home' })}
                onSelect={(pb) => setData({ ...room_data, active_view: 'manage_bracket', active_player_bracket: pb, active_bracket_id:pb.bracket_id })}
            />
            <BracketCompetitionSelector
                brackets={brackets}
                leagues={leagues}
                visible={active_view == 'select_competition' ? true: false}
                onCompetitionSelect={(bc) => {
                    setData({ ...room_data, active_view: 'competition', active_competition_id:bc.bracket_competition_id })
                }}
                onClose={() => setData({ ...room_data, active_view:'home' })}
            />
            <BracketCompetitionCard
                player={player}
                onNotEnoughBalance={onNotEnoughBalance}
                player_balance={local_balance}
                bracket_competition_id={active_competition_id}
                competition_result_types={competition_result_types}
                height={room_size.height}
                onUpdateMyData={(data) => {

                    setMyData({
                        ...my_data,
                        player_brackets: player_brackets.filter(pb => !data.player_brackets.find(npb => npb.player_bracket_id == pb.player_bracket_id)).concat(data.player_brackets),
                        competition_player_brackets: competition_player_brackets.filter(cpb => !data.competition_player_brackets.find(ncpb => ncpb.competition_player_bracket_id == cpb.competition_player_bracket_id)).concat(data.competition_player_brackets)
                    })
                    setData({
                        ...room_data,
                        active_bracket_id: data.bracket_id
                    })
                }}
                onEnterCompetition={(result) => {
                    setMyData({
                        ...my_data,
                        competition_player_brackets: competition_player_brackets.filter(cpb => cpb.competition_player_bracket_id != result.competition_player_bracket.competition_player_bracket_id).concat(result.competition_player_bracket)
                    })
                }}
                onLeaveCompetition={(result) => {
                    setMyData({
                        ...my_data,
                        competition_player_brackets: competition_player_brackets.filter(cpb => cpb.competition_player_bracket_id != result.competition_player_bracket.competition_player_bracket_id)
                    })
                }}
                onShareCompetition={onShareCompetition}
                onViewBracket={(bracket_id) => {
                    const cpb = competition_player_brackets.find(cpb => cpb.bracket_competition_id == active_competition_id);
                    let my_pb = visible_player_brackets.find(pb => pb.player_bracket_id == cpb?.player_bracket_id);
                    if(!my_pb){
                        my_pb = visible_player_brackets.find(pb => pb.bracket_id == bracket_id);
                    } 
                    setData({
                        ...room_data,
                        active_view: 'play_bracket',
                        active_bracket_id: bracket_id,
                        active_player_bracket: my_pb
                    })
                }}
                my_comp_player_brackets={competition_player_brackets.filter(cpb => cpb.bracket_competition_id == active_competition_id)}
                my_player_brackets={visible_player_brackets.filter(pb => pb.bracket_id == active_bracket_id)}
                onClose={() => setData({ ...room_data, active_view: 'select_competition', active_competition_id:undefined })}
                visible={active_view == 'competition' ? true : false}
            />
            <PlayerBracketManager
                visible={active_view == 'manage_bracket' ? true : false}
                player_bracket={active_player_bracket}
                width={room_size.width}
                teams={champion_teams}
                athletes={champion_athletes}
                onEditPicks={(pb) => {
                    setData({ ...room_data, active_player_bracket: pb, active_view: 'play_bracket' })
                }}
                competition_player_brackets={competition_player_brackets.filter(cpb => cpb.player_bracket_id == active_player_bracket?.player_bracket_id)}
                onSelectCompetition={(bc) => {
                    setData({ ...room_data, active_view:'competition', active_competition_id: bc.bracket_competition_id })
                }}
                bracket_competitions={bracket_competitions.filter(bc => bc.bracket_id == active_player_bracket?.bracket_id)}
                onClose={() => setData({ ...room_data, active_view: 'select_player_bracket' })}
                onUpdateBracket={(pb) => {
                    setMyData({ ...my_data, player_brackets: player_brackets.filter(b => b.player_bracket_id != pb.player_bracket_id).concat(pb).sort((a,b) => moment(a.create_datetime).unix() - moment(b.create_datetime).unix()) })
                    setData({ ...room_data, active_player_bracket: pb })
                }}
            />
            <BracketPlay
                visible={active_view == 'play_bracket' ? true : false}
                player={player}
                onClose={() => {
                    if(active_competition_id){ return setData({ ...room_data, active_view: 'competition' }) }
                    setData({ ...room_data, active_view:'select_bracket' })
                }}
                onRequestAuthenticate={onRequestAuthenticate}
                onChangePlayerBracket={() => {
                    setData({
                        ...room_data,
                        active_view: 'select_player_bracket'
                    })
                }}
                onPlayerBracketUpdate={(pb, ncpbs) => {
                    setData({ ...room_data, active_player_bracket:pb })
                    setMyData({ 
                        ...my_data, 
                        player_brackets: player_brackets.filter(opb => opb.player_bracket_id != pb.player_bracket_id).concat(pb),
                        competition_player_brackets: competition_player_brackets.filter(cpb => !ncpbs.find(ncpb => ncpb.competition_player_bracket_id == cpb.competition_player_bracket_id)).concat(ncpbs)
                    })
                }}
                bracket_id={active_bracket_id}
                room_size={room_size}
                player_bracket_id={active_player_bracket?.player_bracket_id}
            />
        </View>
    )
}

export default BracketRoom