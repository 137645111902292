import type { BestAvailableOrderProps, BestAvailableResponseProps, EventOrderStatProps, MatchProps, TournamentProps, TradeProps } from "../../../../types";

export { TournamentListHelpers }

const TournamentListHelpers = {
    getMarketDataFromBestAvailable: (ba:BestAvailableResponseProps, tournaments:TournamentProps[], matches:MatchProps[]) => {
        let updated = false;
        let event_order_stats:EventOrderStatProps[] = []
        let available_orders:BestAvailableOrderProps[] = []
        let latest_trades:TradeProps[] = []

        ba.tournaments.map(ba_t => {
            if(!ba_t.supported_markets){ return }
            if(!tournaments.find(e => e.tournament_id == ba_t.tournament_id)){ return }
            updated = true
            ba_t.supported_markets.map(sm => {
                if(sm.available_orders){ available_orders = available_orders.concat(sm.available_orders) }
                if(sm.order_stats){ event_order_stats = event_order_stats.concat(sm.order_stats) }
                if(sm.latest_trades){ latest_trades = latest_trades.concat(sm.latest_trades) }
            })
        })

        let m_event_order_stats:EventOrderStatProps[] = []
        let m_available_orders:BestAvailableOrderProps[] = []
        let m_latest_trades:TradeProps[] = []
        ba.matches.map(match => {
            let found_tourney = tournaments.find(t => t.tournament_id == match.tournament_id);
            if(!found_tourney){ return }
            let found_match = matches.find(m => m.match_id == match.match_id);
            if(!found_tourney && !found_match){ return }
            updated = true
            match.supported_markets.map(sm => {
                if(sm.available_orders){ m_available_orders = m_available_orders.concat(sm.available_orders) }
                if(sm.order_stats){ m_event_order_stats = m_event_order_stats.concat(sm.order_stats) }
                if(sm.latest_trades){ m_latest_trades = m_latest_trades.concat(sm.latest_trades) }
            })
        })

        return { updated, event_order_stats, available_orders, latest_trades, m_event_order_stats, m_available_orders, m_latest_trades }
    }
}