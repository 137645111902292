import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import type { FundProps } from '../../types';
import { view_styles } from '../../constants/styles';
import { Button, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import { MarketMakerApi } from '../api';

type ManageFundFormProps = {
    fund: FundProps
    onFundUpdate:(fund:FundProps) => void,
    onClose:() => void
}
const ManageFundForm = ({ fund, onFundUpdate, onClose }:ManageFundFormProps) => {
    const [ form_data, setFormData ] = useState<{
        loading:boolean,
        draft_fund?:FundProps
    }>({
        loading:false
    });
    const { loading, draft_fund } = form_data;

    useEffect(() => {
        setFormData({ ...form_data, draft_fund: fund })
    },[fund])

    if(!draft_fund){ return <></> }

    const is_name_changed = fund.name != draft_fund.name ? true : false

    return (
        <View style={{ ...view_styles.section }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Manage Fund</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Update fund settings below and save</Text>
                </View>
                <Button
                    title='X'
                    title_color={Colors.brand.midnight}
                    borderRadius={100}
                    backgroundColor={Colors.shades.shade100}
                    onPress={() => onClose()}
                />
            </View>
            <View style={{ ...view_styles.section_body }}>
                <View style={{ ...view_styles.body_row }}>
                    <View style={{ flex:1 }}>
                        <Text theme='header_2'>Fund Name</Text>
                        <View style={{ marginTop:5, flexDirection:'row', alignItems:'center' }}>
                            <TextInput
                                style={{ ...view_styles.input }}
                                value={draft_fund.name}
                                onChangeText={(text) => setFormData({ ...form_data, draft_fund: { ...draft_fund, name: text } })}
                            />
                             <Button
                                title='SAVE'
                                backgroundColor={Colors.utility.success}
                                style={{ opacity: is_name_changed && !loading ? 1: 0.5, marginLeft:10 }}
                                disabled={!is_name_changed || loading}
                                onPress={async() => {
                                    setFormData({ ...form_data, loading: true });
                                    let new_fund = await MarketMakerApi.udpateFundName(fund.fund_id, draft_fund.name)
                                    if(!new_fund){ return alert('Unable to process') }
                                    setFormData({
                                        ...form_data,
                                        loading: false,
                                        draft_fund: new_fund
                                    });
                                    onFundUpdate(new_fund)
                                }}
                            />
                        </View>
                       
                    </View>
                </View>
            </View>
            
        </View>
    )
}

export default ManageFundForm