import React from 'react';
import { TouchableOpacity, View } from "react-native"
import type { EventProps, PlayerSquareProps, SquareOfferProps, SquareProps, SquareResultProps, SquareStateProps } from "../../types"
import Colors from "../../constants/colors"
import { Icons, Text } from "../../Components"

type SquareCardProps = {
    square:SquareProps,
    player_square?:PlayerSquareProps,
    event?:EventProps, 
    player_id?:string,
    event_status:'not_started'|'in_progress'|'closed', 
    market_type:string, 
    square_result?:SquareResultProps,
    draft_player_square?:PlayerSquareProps, 
    square_offer?:SquareOfferProps,
    draft_square_offer?:SquareOfferProps,
    outbid_player_square?:PlayerSquareProps,
    square_state_override?:SquareStateProps,
    ip_result?:boolean 
    onSquareSelect: (data:any, hey:any) => void,
}
const SquareCard = ({ player_id, square, player_square, event, event_status, market_type, draft_player_square, square_offer, draft_square_offer, outbid_player_square, square_state_override, onSquareSelect }:SquareCardProps) => {

    const cl = market_type == 'FOR_MONEY' ? '$' : 'E';

    const getStateOfSquare = ():SquareStateProps => {
        if(square_state_override){ return square_state_override }
        if(event_status === 'not_started'){
            //No bids on the square
            if(!player_square && !draft_player_square){ return 'no_bid' }
            //There is a bid and currently no draft bid and there is an outbid square
            if(player_square && !draft_player_square && outbid_player_square){ return 'outbid' }
           //There is a bid and currently no draft bid 
            if(player_square && !draft_player_square){ return 'existing_bid' }
            //There is a draft bid on the square and no previous bid
            if(!player_square && draft_player_square){ return 'bid_draft' }
            //There is a draft bid on the square, but a previous bid is higher
            if(player_square && draft_player_square && draft_player_square.purchase_price <= player_square.purchase_price){ return 'outbid_draft' }
            //There is a draft bid on the square and it is higher than the previous bid
            if(player_square && draft_player_square){ return 'bid_draft' }
            return 'no_bid'
        }

        //There is an existing bid and no current offer
        if(player_square && !draft_square_offer && !square_offer){ return 'no_offer' }
        //There is an existing bid and an existing offer and currently no draft offer
        if(player_square && !draft_square_offer && square_offer && square_offer.buy_sell_ind === 'sell'){ return 'existing_sell_offer' }
        if(player_square && !draft_square_offer && square_offer && square_offer.buy_sell_ind === 'buy'){ return 'existing_buy_offer' }
        //There is an existing bid and an existing draft buy offer
        if(player_square && draft_square_offer && draft_square_offer.buy_sell_ind === 'buy'){ return 'draft_buy_offer' }
        //There is an existing bid and an existing draft sell offer
        if(player_square && draft_square_offer && draft_square_offer.buy_sell_ind === 'sell'){ return 'draft_sell_offer' }
        return 'no_offer'
    }

    const getSquareBackgroundColor = (square_state:SquareStateProps) => {
        switch(square_state){
            case 'no_bid': return Colors.shades.white
            case 'existing_bid': 
                if(player_square?.player_id == player_id){ return Colors.brand.electric } else { return Colors.shades.white }
            case 'bid_draft': return Colors.shades.white
            case 'outbid_draft': return Colors.shades.white
            case 'outbid': return `rgba(255,71,71,0.2)`
            case 'no_offer': 
                if(player_square?.player_id == player_id){ return Colors.brand.electric } else { return Colors.shades.white }
            case 'draft_buy_offer': return Colors.shades.white
            case 'draft_sell_offer': return Colors.shades.white
            case 'existing_buy_offer':
                if(player_square?.player_id == player_id){ return 'rgba(125,205,133,0.2)' } else { return Colors.shades.white }
            case 'existing_sell_offer':
                if(player_square?.player_id == player_id){ return '#D9E8F7' } else { return '#D9E8F7' }
            default: return Colors.shades.white
        }
    }
    const getSquareBorderWidth = (square_state:SquareStateProps) => {
        switch(square_state){
            case 'no_bid': return 0
            case 'existing_bid': return 0
            case 'bid_draft': return 2
            case 'outbid_draft': return 2
            case 'outbid': return 0
            case 'no_offer': return 0
            case 'draft_buy_offer': return 2
            case 'draft_sell_offer': return 2
            case 'existing_buy_offer':
                if(player_square?.player_id == player_id){ return 1 } else { return 0 }
            case 'existing_sell_offer': 
                if(player_square?.player_id == player_id){ return 1 } else { return 1 }

            default: return 0
        }
    }
    const getSquareBorderColor = (square_state:SquareStateProps) => {
        switch(square_state){
            case 'no_bid': return Colors.shades.white
            case 'existing_bid': return Colors.shades.white
            case 'bid_draft': return Colors.brand.electric
            case 'outbid_draft': return Colors.utility.error
            case 'outbid': return Colors.utility.error
            case 'no_offer': return Colors.shades.white
            case 'draft_buy_offer': return Colors.brand.electric
            case 'draft_sell_offer': return Colors.brand.electric
            case 'existing_buy_offer':
                if(player_square?.player_id == player_id){ return Colors.utility.success } else { return Colors.shades.white }
            case 'existing_sell_offer': 
                if(player_square?.player_id == player_id){ return Colors.brand.cyan } else { return Colors.brand.electric }
            default: return Colors.shades.white
        }
    }
    const getSquareFontColor = (square_state:SquareStateProps) => {
        switch(square_state){
            case 'no_bid': return Colors.brand.slate
            case 'existing_bid':
                if(player_square?.player_id == player_id){ return Colors.shades.white } else { return Colors.brand.slate }
            case 'bid_draft': return Colors.brand.electric
            case 'outbid_draft': return Colors.utility.error
            case 'outbid': return Colors.utility.error
            case 'no_offer':
                if(player_square?.player_id == player_id){ return Colors.shades.white } else { return Colors.brand.electric }
            case 'draft_buy_offer': return Colors.brand.electric
            case 'draft_sell_offer': return Colors.brand.electric
            case 'existing_buy_offer': 
                if(player_square?.player_id == player_id){ return Colors.brand.slate } else { return Colors.brand.slate }
            case 'existing_sell_offer':
                if(player_square?.player_id == player_id){ return Colors.brand.slate } else { return Colors.brand.slate }
            default: return Colors.brand.slate
        }
    }

    const isUserIconVisible = (square_state:SquareStateProps) => {
        if(!square_state){ return false }
        if(player_square?.player_id == player_id){ return true }
        if(draft_square_offer){ return true }
        if(draft_player_square){ return true }
        if(player_square && player_square.player_id == player_id){ return true }
        return false
    }

    const getSquareBorderTopWidth = (square_state:SquareStateProps) => {
        switch(square_state){
            case 'existing_sell_offer': return 6
            case 'existing_buy_offer': 
                if(player_square?.player_id == player_id){ return 6 } else { return 0 }

            default: return getSquareBorderWidth(square_state)
        }
    }

    const getSquareText = (square_state:SquareStateProps) => {
        switch(square_state){
            case 'no_bid': return 'BID'
            case 'existing_bid': return `${cl}${player_square?.purchase_price?.toFixed(2)}`
            case 'bid_draft': return `${cl}${draft_player_square?.purchase_price?.toFixed(2)}`
            case 'outbid_draft': return `${cl}${draft_player_square?.purchase_price?.toFixed(2)}`
            case 'outbid': return `${cl}${player_square?.purchase_price?.toFixed(2)}`
            case 'no_offer': 
                if(player_square?.player_id == player_id){ return 'SELL' } else { return 'OFFER' }
            case 'draft_buy_offer': return `${cl}${draft_square_offer?.amount?.toFixed(2)}`
            case 'draft_sell_offer': return `${cl}${draft_square_offer?.amount?.toFixed(2)}`
            case 'existing_buy_offer': return `${cl}${square_offer?.amount?.toFixed(2)}`
            case 'existing_sell_offer': return `${cl}${square_offer?.amount?.toFixed(2)}`
            default: return 'BID'
        }
    }
    const square_state = getStateOfSquare()
    let showSubText:string|undefined
    if(square_state === 'existing_buy_offer' && player_square?.player_id != player_id){ showSubText = 'OFFER' }
    if(square_state === 'existing_buy_offer' && player_square?.player_id == player_id){ showSubText = 'SELL' }
    if(square_state === 'existing_sell_offer' && player_square?.player_id != player_id){ showSubText = 'BUY' }
    return (
        <TouchableOpacity style={{ margin:3, height:40, width:40, borderRadius:4, justifyContent:'center', alignItems:'center', backgroundColor:getSquareBackgroundColor(square_state), borderWidth:getSquareBorderWidth(square_state), borderTopWidth:getSquareBorderTopWidth(square_state), borderColor:getSquareBorderColor(square_state) }} onPress={() => onSquareSelect(square, draft_player_square||draft_square_offer?'remove':'add')}>
            {isUserIconVisible(square_state) ? 
            <Icons.UserIcon size={14} color={getSquareFontColor(square_state)} />
            :<></>}
            <View>
                {square_state == 'no_bid' ?
                <Icons.UnbidSquare color={event?.home?.primary_color} secondary_color={event?.away?.primary_color}/>
                :
                <Text size={12} weight='regular' color={getSquareFontColor(square_state)} textAlign='center'>{getSquareText(square_state)}</Text>
                }
            </View>
            {showSubText ?
            <Text size={12} weight='regular' color={Colors.brand.electric} textAlign='center'>{showSubText}</Text>
            :<></>}
        </TouchableOpacity>
    )
}

export default SquareCard