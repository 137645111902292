import { ContentState } from "draft-js";
import isUrl from "is-url";

export const rti_findPlayerEntities = (contentBlock:any, callback:any, contentState:any) => {
    contentBlock.findEntityRanges(
      (character:any) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'PLAYER'
        );
      },
      callback
    );
  }


  export const rti_findTagEntities = (contentBlock:any, callback:any, contentState:any) => {
    contentBlock.findEntityRanges(
      (character:any) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'TAG'
        );
      },
      callback
    );
  }



type ValidatedURLType = { url:string, error:boolean, message?:string }
export const validateUrl = (url:string):ValidatedURLType => {
  if(isUrl(url)){ return { url, error:false } }
  let newURL = `https://${url}`
  if(!isUrl(newURL)){ return { url, error:true, message: 'URL is not valid' } }
  return { url:newURL, error:false }
}

export const createDraftJSFromText = (text:string) => {
  if(text === '' || text === ' '){ return ContentState.createFromText('') }
  return ContentState.createFromText(text)
}