import React from 'react';
import { Image, TouchableOpacity, View } from "react-native"
import type { CodeRequestProps, PlayerReferralProps, PromoProps, PublicPlayerProps, RewardOptionProps } from '../../types';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { CompetitionHelpers } from '../api';



type NavOutPromptProps = {
    image_url?:string,
    title:string
    code_details: { 
        promo?:PromoProps, 
        code_request?: CodeRequestProps, 
        player_referral?:PlayerReferralProps, 
        referrer?:PublicPlayerProps, 
        reward_option?:RewardOptionProps 
    },
    body:string,
    max_width?:number
    hide_accept?:boolean,
    accept_title?:string,
    cancel_title?:string,
    onCancel:() => void,
    onAccept: () => void
}

const NavOutPrompt = ({ image_url, code_details, accept_title, cancel_title, title, hide_accept, body, onCancel, onAccept, max_width }:NavOutPromptProps) => {

    const { code_request, promo, referrer } = code_details;

    return (
        <View style={{ minWidth:350, borderRadius:8, maxWidth:max_width, backgroundColor:Colors.shades.white, minHeight:350 }}>
            <View nativeID="title" style={{ padding:10, backgroundColor:Colors.shades.shade100, borderTopRightRadius:8, borderTopLeftRadius:8 }}>
                <Text size={18} color={Colors.brand.midnight} textAlign="center" weight='bold'>{title}</Text>
            </View>
            <View nativeID="body_image" style={{ flex:1, padding:20 }}>
                <View nativeID="image" style={{ justifyContent:'center', alignItems:'center' }}>
                    <Image
                        source={{ uri: image_url }}
                        style={{ height:150, width:150, borderRadius:8 }}
                        resizeMode="cover"
                    />
                </View>
                <View style={{ padding:10 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign="center">{body}</Text>
                </View>
            </View>
            {code_request && !['closed','fulfilled'].includes(code_request.status) && promo ?
            <TouchableOpacity style={{ margin:5, flexDirection:'row', backgroundColor:Colors.shades.white, borderRadius:8, shadowColor: "rgba(0, 0, 0, 0.06)", shadowOffset: { width: 0, height: 10 }, shadowRadius: 10, shadowOpacity: 1 }}
            onPress={() => onAccept()}>
                <View style={{ padding:10, justifyContent:'center' }}>
                    <Icons.GiftIcon size={30} color={Colors.incentive.gold} />
                </View>
                <View style={{flex:1, backgroundColor:Colors.incentive.gold_faded, padding:10 }}>
                    <Text style={{ paddingBottom:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} size={16} color={Colors.brand.midnight} weight='bold'>@{referrer?.username} has a gift for you</Text>
                    <Text style={{ paddingTop:5 }} size={12} color={Colors.brand.midnight} weight='semibold'>{CompetitionHelpers.getReferralDescription(promo)}</Text>
                </View>
            </TouchableOpacity>
            :<></>}
            <View nativeID="action_row" style={{ borderTopWidth:1, borderColor:Colors.shades.shade600, padding:10, flexDirection:'row' }}>
                <Button
                    title={cancel_title ?? 'No Thanks'}
                    style={{ flex:1, margin:2 }}
                    title_color={Colors.brand.midnight}
                    borderRadius={8}
                    borderWidth={1}
                    borderColor={Colors.brand.midnight}
                    onPress={() => onCancel()}
                />
                {!hide_accept ?
                <Button
                    style={{flex:1, margin:2}}
                    title={accept_title ?? 'Submit'}
                    title_color={Colors.shades.white}
                    borderRadius={8}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onAccept()}
                />
                :<></>}
            </View>
        </View>
    )
}

export default NavOutPrompt