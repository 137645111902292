import React from 'react';
import { View, FlatList, TouchableOpacity, Image, ScrollView } from "react-native"
import type { AthleteProps, BracketCompetitionProps, BracketProps, CompetitionPlayerBracketProps, LeagueProps, PlayerBracketProps, TeamProps } from '../../types';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import moment from 'moment-mini';

type PlayerBracketSelectorProps = {
    visible:boolean,
    brackets:BracketProps[],
    leagues:LeagueProps[],
    teams:TeamProps[],
    athletes:AthleteProps[],
    player_brackets:PlayerBracketProps[],
    bracket_competitions:BracketCompetitionProps[],
    competition_player_brackets:CompetitionPlayerBracketProps[]
    onClose:() => void,
    onSelect:(pb:PlayerBracketProps) => void,
}

const PlayerBracketSelector = ({ visible, brackets, leagues, teams, athletes, player_brackets, onSelect, onClose }:PlayerBracketSelectorProps) => {

    const active_brackets = player_brackets.filter(pb => pb.status == 'active');

    const unique_bracket_ids = [ ...new Set(active_brackets.map(b => b.bracket_id)) ]


    const renderPlayerBrackets = (data : { item:PlayerBracketProps, index:number }) => {
        const champion_team = data.item.champion_id_type == 'team' ? teams.find(t => t.team_id == data.item.champion_id) : undefined
        const champion_athlete = data.item.champion_id_type == 'athlete' ? athletes.find(t => t.team_id == data.item.champion_id) : undefined
        return (
            <TouchableOpacity style={{ padding:10, flexDirection:'row', alignItems:'center', borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onSelect(data.item)}>
                {champion_team ?
                <View style={{ marginRight:5 }}>
                    <Image
                        source={{ uri: champion_team.image?.url }}
                        style={{ height:35, width:35, borderRadius:4 }}
                        resizeMode='cover'
                    />
                </View>
                :champion_athlete ?
                <View style={{ marginRight:5 }}>
                    <Image
                        source={{ uri: champion_athlete.image?.url }}
                        style={{ height:35, width:35, borderRadius:4 }}
                        resizeMode='cover'
                    />
                </View>
                :<></>}
                <View style={{ flex:1 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{data.item.bracket_name}</Text>
                    <Text style={{ marginTop:4 }} size={12} color={Colors.brand.slate} weight='regular'>{(data.item.completion_pct*100).toFixed()}% Complete</Text>
                </View>
                <Button
                    title='VIEW'
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    disabled
                    onPress={() => console.log('')}
                />
            </TouchableOpacity>
        )
    }

    const renderBrackets = (data: { item:string, index:number }) => {
        const bracket = brackets.find(b => b.bracket_id == data.item);
        if(!bracket){ return <></> }
        const league= leagues.find(l => l.league_id == bracket.league_id);
        if(!league){ return <></> }
        const b_pbs = player_brackets.filter(pb => pb.bracket_id == data.item);
        const started = moment().isAfter(moment(bracket.scheduled_datetime)) ? true : false
        return (
            <View>
                <TouchableOpacity style={{ padding:10, backgroundColor:Colors.shades.shade100, flexDirection:'row', alignItems:'center' }}>
                    <View>
                        <Image
                            source={{ uri: league.league_image }}
                            style={{ height:35, width:35, borderRadius:4 }}
                        />
                    </View>
                    <View style={{ flex:1 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{bracket.bracket_name}</Text>
                        <Text style={{ marginTop:4 }} size={12} color={Colors.brand.slate} weight='bold'>{started?'In Progress':`Starts at ${moment(bracket.scheduled_datetime).format('MMM DD YYYY hh:mm a')}`}</Text>
                    </View>
                </TouchableOpacity>
                <View style={{ padding:10 }}>
                    <FlatList
                        data={b_pbs}
                        renderItem={renderPlayerBrackets}
                        keyExtractor={(item) => item.player_bracket_id.toString()}
                    />
                </View>
            </View>
        )
    }

    if(!visible){ return <></> }

    return (
        <View style={{ flex:1 }}>
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade100 }} onPress={() => onClose()}>
                <Icons.ChevronIcon direction='left' size={12} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:20 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='bold'>My Brackets</Text>
                    <Text style={{ marginTop:4 }} size={14} color={Colors.brand.slate} weight='regular'>Select a filled bracket below to manage it</Text>
                </View>
            </TouchableOpacity>
            <ScrollView style={{flex:1}}>
                <FlatList
                    data={unique_bracket_ids}
                    renderItem={renderBrackets}
                    keyExtractor={(item) => item.toString()}
                />
            </ScrollView>
        </View>
    )
}

export default PlayerBracketSelector