import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from "react-native";
import { SocialComponentApi } from './api';
import PlayerCard from './PlayerCard';
import type { PlayerFollowerProps, PublicPlayerProps } from '../types';

type PlayerListProps = {
    players:PublicPlayerProps[],
    style?: any,
    onPlayerSelect:(p:PublicPlayerProps) => void,
    options: {
        follow_status?:boolean,
        direction: 'horizontal' | 'vertical',
    }
}

const PlayerList = ({ players, style, options, onPlayerSelect }:PlayerListProps) => {
    const [ size, setSize ] = useState({ width: 0, height:0 });
    const [ follow_status_loading, setFollowStatusLoading ] = useState(false);
    const [ player_followers, setPlayerFollowers ] = useState<PlayerFollowerProps[]>([]);

    const { follow_status, direction } = options;

    useEffect(() => { SocialComponentApi.setEnvironment() },[])

    useEffect(() => {
        if(players.length == 0){ return }
        getListData();
    },[players.length])

    const getListData = async() => {
        if(follow_status){
            setFollowStatusLoading(true);
            const player_followers = await SocialComponentApi.getBulkPlayerfollowers(players.map(p => p.player_id));
            console.log(player_followers)
            setPlayerFollowers(player_followers)
            setFollowStatusLoading(false);
        }
    }
    const new_style = direction == 'vertical' ? local_styles.container_vertical : local_styles.containter_horizontal
    const alignment = direction == 'vertical' ? 'horizontal' : 'vertical'
    const player_width = direction == 'vertical' ? size.width - 20: (size.width - 40) / 2
    return (
        <View style={{ ...new_style, ...style }} onLayout={(ev) => {
            const { width, height } = ev.nativeEvent.layout
            setSize({ width, height })
        }}>
            {players.map(p => {
                let following = player_followers.find(pf => pf.following_id == p.player_id);
                let follower = player_followers.find(pf => pf.player_id == p.player_id);
                return (
                    <View key={p.player_id.toString()} style={{ width: player_width, margin:8 }}>
                        <PlayerCard
                            width={player_width}
                            style={{ borderRadius:8 }}
                            player={p}
                            float={true}
                            alignment={alignment}
                            player_following={following}
                            player_follower={follower}
                            show_follow={options.follow_status}
                            follow_loading={follow_status_loading}
                            onSelectPlayer={onPlayerSelect}
                            onFollowingUpdate={(new_pf) => {
                                setPlayerFollowers(player_followers.filter(pf => pf.player_follower_id != new_pf.player_follower_id).concat(new_pf))
                            }}
                        />
                    </View>

                )
            })}
        </View>
    )
}

const local_styles = StyleSheet.create({
    container_vertical: { flexDirection:'column', alignItems:'center' },
    containter_horizontal: { flexDirection:'row', flexWrap: 'wrap', justifyContent:'center' }
})


export default PlayerList