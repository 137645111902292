import React from 'react';
import { View, Image, ScrollView } from 'react-native';
import Colors from '../../constants/colors';
import { Button, Icons, Text } from '../../Components';
import { ProfileHelpers } from '../api';
import type { CodeRequestProps, MyPlayerProps, PlayerReferralProps, PromoProps, PublicPlayerProps } from '../../types';
import { view_styles } from '../../constants/styles';


type ProfileWelcomeProps = {
    player:MyPlayerProps,
    width:number,
    onStartSetup: () => void,
    code_details: {
        code_request?:CodeRequestProps,
        referrer?:PublicPlayerProps,
        promo?:PromoProps,
        player_referral?:PlayerReferralProps
    }
    onClose:() => void
}

const ProfileWelcome = ({ player, code_details, width, onStartSetup, onClose }:ProfileWelcomeProps) => {

    const { code_request, promo, referrer } = code_details;
    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1 }}>
                <View style={{ padding:20 }}>
                    <Text size={30} color={Colors.brand.midnight} weight='bold'>Welcome @{player.username}!</Text>
                    <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>We are so glad that you found us! Use the setup wizard to complete your account setup.</Text>
                </View>
                <Image
                    source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1714506366/Welcome_James_pfzn7z.webp' }}
                    style={{ width, height:width, opacity:0.6 }}
                    resizeMode='cover'
                />
            </ScrollView>
            {code_request && !['closed','fulfilled'].includes(code_request.status) && promo ?
            <View style={{ margin:15, marginTop:-50, flexDirection:'row', backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float }}>
                <View style={{ padding:10, justifyContent:'center' }}>
                    <Icons.GiftIcon size={40} color={Colors.incentive.gold} />
                </View>
                <View style={{flex:1, backgroundColor:Colors.incentive.gold_faded, padding:10 }}>
                    <Text style={{ paddingBottom:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} size={16} color={Colors.brand.midnight} weight='bold'>@{referrer?.username} has a gift for you</Text>
                    <Text style={{ paddingTop:5 }} size={12} color={Colors.brand.midnight} weight='semibold'>{ProfileHelpers.getReferralDescription(promo)}</Text>
                </View>
            </View>
            :<></>}
            <View style={{ flexDirection:'row', padding:10 }}>
                <Button
                    title={`DO IT LATER`}
                    style={{ flex:3, marginRight:5 }}
                    borderColor={Colors.brand.electric}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    padding={15}
                    onPress={() => onClose()}
                />
                <Button
                    title='SET UP MY ACCOUNT'
                    style={{ flex:5 }}
                    backgroundColor={Colors.utility.success}
                    title_color={Colors.shades.white}
                    padding={15}
                    onPress={() => onStartSetup()}
                />
            </View>
        </View>
    )
}

export default ProfileWelcome