import React from 'react';
import { Image, View } from 'react-native';
import { view_styles } from '../../../constants/styles';
import type { PostProps, PublicPlayerProps } from '../../../types';
import { Text } from '../../../Components';
import moment from 'moment-mini';

type PostHeaderProps = {
    player: PublicPlayerProps,
    post:PostProps
}

const PostHeader = ({ player, post }:PostHeaderProps) => {
    return (
        <View style={{ ...view_styles.section_header, paddingLeft:10, padding:10 }}>
            <Image
                source={{ uri: player.profile_pic && player.profile_pic != '' ? player.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                style={{ height:32, width:32, borderRadius:100 }}
                resizeMode='cover'
            />
            <View style={{ flex:1, marginLeft:10 }}>
                <Text theme='header_2'>{player.first_name} {player.last_name}</Text>
                <Text style={{ marginTop:3 }} theme='body_2'>@{player.username} • {moment(post.create_datetime).fromNow()}</Text>
            </View>
        </View>
    )
}

export default PostHeader