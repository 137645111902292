import axios from "axios"
import type { AthleteProps, BracketCompetitionProps, BracketCompetitionScoringRuleProps, BracketGroupProps, BracketProps, BracketRoundProps, CompetitionPayoutTypeProps, CompetitionPlayerBracketProps, CompetitionResultTypeProps, CompetitionTypeProps, EventProps, LeagueProps, MyPlayerProps, PlayerBalanceProps, PlayerBracketPickProps, PlayerBracketProps, PublicPlayerProps, RoundEventProps, TeamProps } from "../../types";
import { APIOverrides } from "../../ApiOverrides";
import moment from "moment-mini";
export { BracketApi, BracketComeptitionApi, BracketCompetitionHelpers }

let AUTH_SVC_API = ''
let EVENT_SVC_API = ''
let TP_SVC_API = ''


const BracketApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        TP_SVC_API = endpoints['TP_SVC_API'] as string;
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getBracketById: async(bracket_id:string):Promise<{ bracket:BracketProps, bracket_rounds:BracketRoundProps[], round_events:RoundEventProps[], bracket_groups:BracketGroupProps[] }> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/brackets/bracket/${bracket_id}`)
        return resp.data
    },
    getLeagues: async():Promise<LeagueProps[]> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues`)
        return resp.data.leagues
    },
    getActiveBrackets: async():Promise<BracketProps[]> => {
        const resp = await axios.get(`${EVENT_SVC_API}/v1/brackets/active`)
        return resp.data.brackets
    },
    getPlayersByPlayerIds : async(player_ids:string[]):Promise<PublicPlayerProps[]> => {
        if(player_ids.length == 0){ return [] }
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
        return resp.data.players
    },
    getEventsByEventIds: async(event_ids:string[]):Promise<EventProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/events/bulk/get`, { attribute:'event_id', values: event_ids })
        return resp.data.events
    },
    getAthletesByIds : async(athlete_ids:string[]):Promise<AthleteProps[]> => {
        if(athlete_ids.length == 0){ return [] }
        const resp = await axios.post(`${EVENT_SVC_API}/v1/athletes/bulk/get`, { attribute: 'athlete_id', values: athlete_ids })
        return resp.data.athletes
    },
    getTeamsByIds: async(team_ids:string[]):Promise<TeamProps[]> => {
        if(team_ids.length == 0){ return [] }
        const resp = await axios.post(`${EVENT_SVC_API}/v1/teams/bulk/get`, { attribute:'team_id', values: team_ids })
        return resp.data.teams
    },
    getExoticsByIds : async(exotic_ids:string[]):Promise<any[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/exotics/bulk/get`, { attribute: 'exotic_id', values: exotic_ids })
        return resp.data.athletes
    },
}

const BracketComeptitionApi = {
    getMyDetails: async():Promise<MyPlayerProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
        return resp.data.player
    },
    getMyBalance: async():Promise<PlayerBalanceProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/balance/me`)
        return resp.data.player_balance
    },
    createBracketCompetition: async(bracket_competition:BracketCompetitionProps, bracket_competition_scoring_rules:BracketCompetitionScoringRuleProps[]):Promise<{bracket_competition:BracketCompetitionProps, bracket_competition_scoring_rules:BracketCompetitionScoringRuleProps[]}> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/brackets/competition/create`, { bracket_competition, bracket_competition_scoring_rules })
        return resp.data
    },
    getCompetitionOptions: async():Promise<{competition_types:CompetitionTypeProps[], competition_result_types:CompetitionResultTypeProps[], competition_payout_types:CompetitionPayoutTypeProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/options`)
        return resp.data
    },
    getBracketCompetitions: async(status?:string):Promise<BracketCompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/brackets/competitions/all?status=${status}`)
        return resp.data.bracket_competitions
    },
    getBracketcompetitionByCode: async(competition_code:string):Promise<BracketCompetitionProps | undefined> => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/brackets/competitions/code`, { competition_code })
            return resp.data.bracket_competition
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    isCodeAvailable : async(competition_code:string):Promise<Boolean> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/brackets/code/available`, { competition_code })
        return resp.data.available
    },
    getBracketCompetitionById: async(bracket_competition_id:string):Promise<{bracket_competition:BracketCompetitionProps, competition_player_brackets:CompetitionPlayerBracketProps[], player_brackets:PlayerBracketProps[], bracket_competition_scoring_rules:BracketCompetitionScoringRuleProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/brackets/competition/${bracket_competition_id}`)
        return resp.data
    },
    getPlayerBracketById: async(player_bracket_id:string):Promise<{player_bracket:PlayerBracketProps, player_bracket_picks:PlayerBracketPickProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/brackets/bracket/${player_bracket_id}`)
        return resp.data
    },
    createPlayerBracket: async(player_bracket:PlayerBracketProps):Promise<PlayerBracketProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/brackets/bracket/create`, { player_bracket })
        return resp.data.player_bracket
    },
    updatePlayerBracket: async(player_bracket:PlayerBracketProps):Promise<PlayerBracketProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/brackets/bracket/update`, { player_bracket })
        return resp.data.player_bracket
    },
    deletePlayerBracket: async(player_bracket_id:string):Promise<PlayerBracketProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/brackets/bracket/delete`, { player_bracket_id })
        return resp.data.player_bracket
    },
    getMyPlayerBrackets: async(bracket_id?:string, bracket_competition_id?:string):Promise<{ player_brackets: PlayerBracketProps[], competition_player_brackets:CompetitionPlayerBracketProps[], bracket_competitions:BracketCompetitionProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/brackets/player?bracket_id=${bracket_id}&bracket_competition_id=${bracket_competition_id}`)
        return resp.data
    },
    savePick: async(player_bracket_pick:PlayerBracketPickProps):Promise<{player_bracket:PlayerBracketProps, player_bracket_picks:PlayerBracketPickProps[], competition_player_brackets:CompetitionPlayerBracketProps[]}> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/brackets/pick/create`, { player_bracket_pick })
        return resp.data
    },
    joinBracketCompetition: async(competition_player_bracket:CompetitionPlayerBracketProps):Promise<{bracket_competition:BracketCompetitionProps, competition_player_bracket: CompetitionPlayerBracketProps}> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/brackets/competition/join`, { competition_player_bracket })
        return resp.data
    },
    leaveBracketCompetition: async(competition_player_bracket_id:string):Promise<{bracket_competition:BracketCompetitionProps, competition_player_bracket:CompetitionPlayerBracketProps}> => {
        const resp = await axios.post(`${TP_SVC_API}/v1/brackets/competition/leave`, { competition_player_bracket_id })
        return resp.data
    },
    getCompetitionPlayerBracketsByCompetitionId: async(bracket_competition_id:string, offset:number):Promise<{ competition_player_brackets:CompetitionPlayerBracketProps[], player_brackets:PlayerBracketProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/brackets/competitionplayer/competition/${bracket_competition_id}?offset=${offset}`)
        return resp.data
    },
    getMyCompetitionPlayerBrackets: async():Promise<{ competition_player_brackets:CompetitionPlayerBracketProps[], bracket_competitions:BracketCompetitionProps[] }> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/brackets/competitionplayer/me`)
        return resp.data
    }
}


type BracketRoundStatus = { bracket_round_id:string, completion_pct: number }
type BracketGroupStatus = { bracket_group_id:string, completion_pct:number }
type NonGroupEventProps = {
    round_event_id:string,
    round_event:RoundEventProps,
    event_number:number,
    championship:boolean,
    player_bracket_pick?:PlayerBracketPickProps,
    away_placeholder_pick?:PlayerBracketPickProps,
    home_placeholder_pick?:PlayerBracketPickProps,
    orientation?: 'left'|'right'
}

const BracketCompetitionHelpers = {
    getPayout: (bc:BracketCompetitionProps):number => {
        let payout = bc.ticket_revenue
        if(bc.promo_payout){ payout += bc.promo_payout }
        if(bc.guaranteed_payout && payout  < bc.guaranteed_payout){ payout = bc.guaranteed_payout }
        return payout
    },
    canLeave: (bc?:BracketCompetitionProps) => {
        if(!bc){ return false }
        if(moment().isBefore(moment(bc.scheduled_datetime))){ return true }
        return false
    },
    canEnter: (bc?:BracketCompetitionProps) => {
        if(!bc) { return false }
        if(moment().isAfter(moment(bc.scheduled_datetime))){ return false }
        return true
    },
    canMakePick: (bracket?:BracketProps) => {
        if(!bracket){ return false }
        if(moment().isAfter(moment(bracket.scheduled_datetime))){ return false }
        return true
    },
    isEnoughBalance: (bc:BracketCompetitionProps, player_balance?:PlayerBalanceProps) => {
        if(!player_balance) return false
        if(bc.market_type == 'FREE' && player_balance.free_market_balance < bc.buy_in){ return false }
        if(bc.market_type == 'FOR_MONEY' && player_balance.balance < bc.buy_in){ return false }
        return true
    },
    showChampions:(bc:BracketCompetitionProps) => {
        if(!bc){ return false }
        if(moment().isBefore(moment(bc.scheduled_datetime))){ return false }
        return true
    },
    canDeletePlayerBracket: (pb:PlayerBracketProps, cpbs:CompetitionPlayerBracketProps[]) => {
        if(cpbs.length > 0){ return false }
        if(pb.status != 'active'){ return false }
        return true
    },
    getBuyInTitle: (bc:BracketCompetitionProps) => {
        if(bc.buy_in == 0){ return `Join For Free` }
        const cl = bc.market_type == 'FREE' ? 'E' : '$'
        return `Join Competition ${cl}${bc.buy_in}`
    },
    genEmptyBracketCompetition: ():BracketCompetitionProps => {
        let code = `${Math.floor(100000 + Math.random() * 900000)}`
        return {
            bracket_competition_id: '',
            bracket_id: '',
            competition_name:'',
            competition_description: '',
            code_valid: true,
            competition_code: code,
            create_datetime: '',
            last_update_datetime: '',
            image: { url: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1677259366/Bracket_image_h0fkku.webp' },
            status: 'scheduled',
            buy_in: 0,
            market_type: 'FOR_MONEY',
            scheduled_datetime: '',
            scoring_system: 'progressive',
            max_brackets: 100,
            max_brackets_per_player: 1,
            invite_only: true,
            ticket_revenue: 0,
            tickets_sold: 0,
            admin_id: '',
            competition_result_type_id: ''
        }
    },
    getDefaultScoringRules : (bracket_competition_id:string, bracket_rounds:BracketRoundProps[]):BracketCompetitionScoringRuleProps[] => {
        let rules:BracketCompetitionScoringRuleProps[] = []
        let next_points = 1;
        bracket_rounds.sort((a,b) => a.round_number - b.round_number).map(br => {
                rules.push({
                    bracket_competition_scoring_rule_id: '',
                    bracket_competition_id,
                    round_number: br.round_number,
                    win_points: br.carry_through?0:next_points,
                    underdog_bonus: false,
                    underdog_multiplier: 0,
                    create_datetime:'',
                    last_update_datetime:''
                })
                if(!br.carry_through){ next_points = next_points * 2 }
        })
        return rules
    },
    createEmptyPlayerBracket : (player_id:string, bracket_id:string):PlayerBracketProps => {
        let title = `My Bracket ${Math.floor(Math.random() * 100)}`
        return {
            player_bracket_id: '',
            player_id: player_id,
            bracket_id: bracket_id,
            bracket_name: title,
            status: 'active',
            completion_status: 'inprogress',
            completion_pct: 0,
            create_datetime: '',
            last_update_datetime: ''
        }
    },
    createPlayerBracketPick : (bracket_id:string, round_event:RoundEventProps, pick_side:'away'|'home', pick_side_id:string, pick_seed: number):PlayerBracketPickProps => {
    
        return {
            player_bracket_pick_id: '',
            player_bracket_id: '',
            bracket_id: bracket_id,
            round_event_id: round_event.round_event_id,
            pick_side,
            pick_side_id,
            pick_seed,
            underdog_pick: false,
            status: 'inprogress',
            pick_side_type: 'team',
            event_sequence: round_event.event_number,
            points: 0,
            create_datetime: '',
            last_update_datetime:''
        }
    },
    getBracketPickProgress : (pbp:PlayerBracketPickProps[], bracket_rounds:BracketRoundProps[], bracket_groups:BracketGroupProps[], round_events:RoundEventProps[]):{ round_status:BracketRoundStatus[], group_status:BracketGroupStatus[] } => {
        let round_status:BracketRoundStatus[] = []
        bracket_rounds.map(br => {
            if(br.carry_through){ return round_status.push({ bracket_round_id: br.bracket_round_id, completion_pct: 1 }) }
            let events = round_events.filter(re => re.bracket_round_id == br.bracket_round_id)
            let picks = pbp.filter(pick => pick.status != 'deleted' && events.map(e => e.round_event_id.toString()).includes(pick.round_event_id.toString()))
            let completion_pct = picks.length / events.length
            return round_status.push({ bracket_round_id: br.bracket_round_id, completion_pct })
        })
    
        let group_status:BracketGroupStatus[] = []
        bracket_groups.map(bg => {
            let events = round_events.filter(re => re.bracket_group_id == bg.bracket_group_id && !re.no_pick);
            if(events.length == 0){ return group_status.push({ bracket_group_id:bg.bracket_group_id, completion_pct: 1 }) }
            let picks = pbp.filter(pick => pick.status != 'deleted' && events.map(e => e.round_event_id.toString()).includes(pick.round_event_id.toString()))
            let completion_pct = picks.length / events.length
            return group_status.push({ bracket_group_id: bg.bracket_group_id, completion_pct })
        })
    
        return { round_status, group_status }
    },
    getNonGroupGames : (round_events:RoundEventProps[], bracket_groups:BracketGroupProps[], player_bracket_picks:PlayerBracketPickProps[]):NonGroupEventProps[] => {
        let non_group_events:NonGroupEventProps[] = []
        let non_group_games = round_events.filter(re => re.bracket_group_id == '0')
        if(non_group_games.length == 0){ return [] }
        let championship_game = round_events.sort((a,b) => b.event_number - a.event_number)[0]
        if(championship_game){
            const champ_pick = player_bracket_picks.find(pbp => pbp.round_event_id == championship_game.round_event_id && pbp.status != 'deleted')
            const away_champ_pick = player_bracket_picks.find(pbp => pbp.round_event_id == championship_game.away_placeholder && pbp.status != 'deleted')
            const home_champ_pick = player_bracket_picks.find(pbp => pbp.round_event_id == championship_game.home_placeholder && pbp.status != 'deleted')
    
            non_group_events.push({
                round_event_id: championship_game.round_event_id,
                event_number: championship_game.event_number,
                round_event: championship_game,
                championship: true,
                away_placeholder_pick: away_champ_pick,
                home_placeholder_pick: home_champ_pick,
                player_bracket_pick: champ_pick
            })
        }
        //Find the side of the bracket the game needs to be on
        non_group_games.map(ngg => {
            if(championship_game?.round_event_id == ngg.round_event_id){ return }
            let away_placeholder_game = round_events.find(re => re.round_event_id == ngg.away_placeholder);
            let away_group = bracket_groups.find(bg => bg.bracket_group_id == away_placeholder_game?.bracket_group_id)
            //let home_placeholder_game = round_events.find(re => re.round_event_id == ngg.home_placeholder);
            //let home_group = bracket_groups.find(bg => bg.bracket_group_id == home_placeholder_game?.bracket_group_id)
            let orientation:'left'|'right' = 'left'
            if(away_group?.orientation != 'left'){ orientation = 'right' }
            let pbp = player_bracket_picks.find(pbp => pbp.round_event_id == ngg.round_event_id && pbp.status != 'deleted')
            const away_ff_pick = player_bracket_picks.find(pbp => pbp.round_event_id == ngg.away_placeholder && pbp.status != 'deleted')
            const home_ff_pick = player_bracket_picks.find(pbp => pbp.round_event_id == ngg.home_placeholder && pbp.status != 'deleted')
    
            non_group_events.push({
                round_event_id: ngg.round_event_id,
                championship: false,
                orientation,
                player_bracket_pick: pbp,
                round_event: ngg,
                away_placeholder_pick: away_ff_pick,
                home_placeholder_pick: home_ff_pick,
                event_number: ngg.event_number
            })
    
        })
    
        return non_group_events
    },
    generateCompetitionPlayerBracket : (bracket_competition_id:string, player_bracket_id:string):CompetitionPlayerBracketProps => {
        return {
            competition_player_bracket_id: '',
            bracket_competition_id,
            player_bracket_id,
            place:0,
            possible_points:0,
            player_id: '',
            status: 'active',
            winnings: 0,
            points_scored:0,
            create_datetime: '',
            last_update_datetime: ''
        }
    },
    formatPlace :(place:number) => {
        switch(place){
            case 1:
                return '1st'
            case 2:
                return '2nd'
            case 3:
                return '3rd'
            case 4:
                return '4th'
            case 5:
                return '5th'
            case 6:
                return '6th'
            case 7:
                return '7th'
            case 8:
                return '8th'
            case 9:
                return '9th'
            default:
                return `${place}`
    
        }
    },
}