import React, { useEffect, useState } from "react"
import { FlatList, View, ActivityIndicator} from "react-native"
import { Text } from '../../Components';
import { ItemOrderApi, WalletApi } from '../api';
import type { AccountProps, ItemOrderProps, TransactionDetailProps, TransactionProps } from '../../types';
import Colors from '../../constants/colors';
import moment from 'moment-mini';

type ItemOrderDetailsProps = {
    item_order: ItemOrderProps,
}

const ItemOrderDetails = ({ item_order }:ItemOrderDetailsProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState<{
        account?:AccountProps,
        transaction?:TransactionProps,
        transaction_details:TransactionDetailProps[]
    }>({
        transaction_details: []
    });

    const { account, transaction, transaction_details } = data;


    useEffect(() => {
        getDataFromServer(item_order)
    },[item_order])

    const getDataFromServer = async(item_order:ItemOrderProps) => {
        if(!item_order.transaction_id){ return }
        setLoading(true);
        const resp = await ItemOrderApi.getTransactionById(item_order.transaction_id)
        const acct = await WalletApi.getAccountById(resp.transaction.account_id)
        setData({
            transaction: resp.transaction,
            transaction_details: resp.transaction_details,
            account:acct
        })
        setLoading(false)
    }

    const renderTransactionDetails = (data : { item:TransactionDetailProps, index:number }) => {
        return (
            <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='regular'>{data.item.description}</Text>
                <Text size={14} color={Colors.brand.midnight} weight="bold">${data.item.amount.toFixed(2)}</Text>
            </View>
        )
    }

    if(!item_order){ return <></> }

    let sub_total = parseFloat(item_order.amount as string)
    let detail_total = transaction_details.filter(td => !td.fee_type).reduce((a,b) => a + b.amount, 0)
    let unsettled = sub_total - detail_total
    


    return (
        <View nativeID="item_order_details">
            {account ?
                <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                    <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='regular'>Account</Text>
                    <Text size={14} color={Colors.brand.midnight} weight="bold">{account.account_label}</Text>
                </View>
            :<></>}
            {loading ?
            <ActivityIndicator size='small' color={Colors.brand.midnight} />
            :<></>}
            <FlatList
                data={transaction_details}
                renderItem={renderTransactionDetails}
            />
            {transaction?.status == 'posted' && transaction?.estimated_settle_datetime && moment().isBefore(moment(transaction.estimated_settle_datetime)) ?
                <View>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='regular'>Estimated Settle Time</Text>
                        <Text size={14} color={Colors.brand.midnight} weight="bold">{moment(transaction.estimated_settle_datetime).format('MM/DD @ h a')}</Text>
                    </View>
                    {unsettled > 0 ?
                    <View style={{ padding:10, backgroundColor:Colors.shades.shade100, borderRadius:8, marginTop:10 }}>
                        <View style={{ flexDirection:'row', alignItems:'center' }}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='regular'>Awaiting Settlement</Text>
                            <Text size={14} color={Colors.utility.warning} weight="bold">${unsettled.toFixed(2)}</Text>
                        </View>
                        <Text style={{ marginTop:10 }} size={12} color={Colors.brand.midnight} weight='regular'>This amount exceeded your <Text size={12} weight='bold'>Instant Deposit Limit</Text> and will be added to your wallet after the transaction settles based on the date noted above.</Text>
                    </View>
                    :<></>}
                </View>
            :<></>}
            {false && item_order.type == 'wallet' && transaction?.status == 'posted' ?
            <View style={{ borderRadius:8, backgroundColor:Colors.utility.warning, padding:10, marginTop:10 }}>
                <Text size={14} color={Colors.shades.white} weight='semibold' textAlign="center">Deposits in posted status are not eligible for withdraw until they settle.</Text>
            </View>
            :<></>}
        </View>
    )
}
export default ItemOrderDetails