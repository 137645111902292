import React from 'react';
import { Image, View } from "react-native"
import type { MyPlayerProps, PlayerBalanceProps } from "../../types"
import Colors from '../../constants/colors';
import { Text } from '../../Components';

type PlayerBalanceCardProps = {
    player_balance:PlayerBalanceProps,
    player:MyPlayerProps
}

const PlayerBalanceCard = ({ player, player_balance }:PlayerBalanceCardProps) => {

    return (
        <View style={{ flexDirection:'row', alignItems:'center' }}>
            <View>
                <Image
                    source={{ uri: player.profile_pic }}
                    style={{ height:35, width:35, borderRadius:100 }}
                    resizeMode='cover'
                />
            </View>
            <View style={{ flex:1, marginLeft:10 }}>
                <Text size={14} color={Colors.brand.midnight} weight='bold'>{player.first_name} {player.last_name}</Text>
                <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate}>P{player_balance.promo_balance.toFixed(2)} - E{player_balance.free_market_balance.toFixed(2)}</Text>
            </View>
            <View>
                <Text size={14} color={Colors.brand.midnight} weight='bold'>${player_balance.balance.toFixed(2)}</Text>
            </View>
        </View>
    )
}

export default PlayerBalanceCard