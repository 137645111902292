import React, { useState } from 'react'
import { View, TouchableOpacity, FlatList } from 'react-native';
import Text from './Text';
import Colors from '../constants/colors';
import Icons from './Icons';

type DropdownProps = {
    selected_value:string,
    invalid?: boolean,
    errors?:string[],
    dropdown_options: { value:string, eligible_options: string[] }[]
    read_only?:boolean
    onOptionSelect: (option:string) => void
}
const DropDown = ({ invalid, errors, read_only, selected_value, dropdown_options, onOptionSelect }:DropdownProps) => {
    const [ expanded, setExpanded ] = useState(false);
    let options:string[] = []

    dropdown_options.map(o => {
        options = options.concat(o.eligible_options)
    })

    const handleOptionSelect = (option:string) => {
        setExpanded(false);
        onOptionSelect(option)
    }


    const renderErrors = (data : { item:string, index:number }) => {
        return (
            <Text style={{ margin:4 }} size={12} color={Colors.utility.error} weight='regular'>{data.item}</Text>
        )
    }


    const renderOptions = (data: { item: string, index:number }) => {
        const last_item = data.index == options.length - 1 ? true : false
        return (
            <TouchableOpacity style={{ paddingTop:10, paddingBottom:last_item?0:10, borderBottomWidth:last_item?0:1, borderColor:Colors.shades.shade600 }} onPress={() => handleOptionSelect(data.item)}>
                <Text size={14} weight='regular' color={Colors.brand.midnight}>{data.item}</Text>
            </TouchableOpacity>
        )
    }

    return (
        <View>
            <View style={{ borderRadius:8, borderWidth:1, borderColor:invalid?Colors.utility.error:Colors.shades.shade100, backgroundColor:Colors.shades.white }}>
                <TouchableOpacity disabled={read_only} style={{ flexDirection:'row', alignItems:'center', padding:10 }} onPress={() => setExpanded(!expanded)}>
                    <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight="semibold">{ selected_value ?? 'Select Value'}</Text>
                    {!read_only ?
                    <Icons.ChevronIcon
                        direction={expanded?'up':'down'}
                        color={Colors.brand.midnight}
                        size={8}
                    />
                    :<></>}
                </TouchableOpacity>
                {expanded ?
                <View style={{padding:10 }}>
                    <FlatList
                        data={options}
                        renderItem={renderOptions}
                    />
                </View>
                :<></>}
            </View>
            {invalid && errors && errors.length > 0 ?
            <FlatList
                data={errors}
                renderItem={renderErrors}
                keyExtractor={(item) => item}
            />
            :<></>}
        </View>
    )
}

export default DropDown
