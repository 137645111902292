import React from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import type { ArticleFeedProps, ArticleProps, BEEventProps } from "../types"
import { Text } from '../Components';
import { view_styles } from '../constants/styles';
import Colors from '../constants/colors';

type ArticleCardProps = {
    article_feed?:ArticleFeedProps,
    article:ArticleProps,
    width:number,
    onEvent?:(be_event:BEEventProps) => void,
    onSelectArticle:(article:ArticleProps) => void
}
const ArticleCard = ({ article_feed, article, width, onSelectArticle, onEvent }:ArticleCardProps) => {

    return (
        <TouchableOpacity style={{ ...view_styles.section, width, flex:1 }} onPress={() => {
                onSelectArticle(article)
                if(onEvent){
                    onEvent({
                        event_name: 'article_click',
                        event_data: {
                            article_id: article.article_id,
                            company_id: article.company_id,
                        },
                        level:2
                    })
                }
            }}>
            {article_feed ?
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header_2'>{article_feed.feed_name}</Text>
                </View>
            </View>
            :<></>}
            <View style={{ ...view_styles.section_body, padding:0 }}>
                <View>
                    <Image
                        source={{ uri: article.image?.url }}
                        style={{ width:width, height:width * 0.66 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ padding:5 }}>
                    <Text theme='header_2'>{article.title}</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
}

export default ArticleCard