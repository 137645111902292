import axios from "axios"
import type { PollCampaignGoalProps, PollCampaignLeaderProps, PollCampaignProps, PollOptionProps, PollProps, PollResponseProps, PollSummaryProps } from "../../types";
import { APIOverrides } from "../../ApiOverrides";
import Colors from "../../constants/colors";

let AUTH_SVC_API = ''

export { 
    PollCampaignApi, 
    PollCampaignHelpers,
    PollApi, 
    PollCampaignGoalApi, 
    PollOptionApi, 
    PollResponseApi,
    PollResponseHelpers
}

const PollCampaignApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getPlayersByPlayerIds : async(player_ids:string[]) => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
        return resp.data.players
    },
    createPollCampaign: async(poll_campaign:PollCampaignProps):Promise<PollCampaignProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/campaigns/campaign/create`, { poll_campaign })
        return resp.data.poll_campaign
    },
    updatePollCampaign: async(poll_campaign:PollCampaignProps):Promise<PollCampaignProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/campaigns/campaign/update`, { poll_campaign })
        return resp.data.poll_campaign
    },
    getPollCampaignById : async(poll_campaign_id:string):Promise<PollCampaignProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/campaigns/campaign/${poll_campaign_id}`)
        return resp.data.poll_campaign
    },
    searchPollCampaigns : async(search_value:string, offset:number):Promise<PollCampaignProps[]> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/campaigns/search`, { search_value, offset })
        return resp.data.poll_campaigns
    },
    closePollCampaign : async(poll_campaign_id:string):Promise<PollCampaignProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/campaigns/campaign/close`, { poll_campaign_id })
        return resp.data.poll_campaign
    },
    copyPollCampaign : async(poll_campaign_id:string):Promise<PollCampaignProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/campaigns/campaign/copy`, { poll_campaign_id })
        return resp.data.poll_campaign
    },
    archivePollCampaign : async(poll_campaign_id:string):Promise<PollCampaignProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/campaigns/campaign/archive`, { poll_campaign_id })
        return resp.data.poll_campaign
    },
    deletePollCampaign : async(poll_campaign_id:string):Promise<PollCampaignProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/campaigns/campaign/delete`, { poll_campaign_id })
        return resp.data.poll_campaign
    },
    getPollCampaignsByPlayerId : async(player_id:string, offset:number, status?:string):Promise<PollCampaignProps[]> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/campaigns/player/${player_id}?offset=${offset}&status=${status}`)
        return resp.data.poll_campaigns
    },
    getLeadersByCampaignId : async(poll_campaign_id:string, offset:number):Promise<{ poll_campaign_leaders: PollCampaignLeaderProps[], my_leaderboard?:PollCampaignLeaderProps}> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/leaders/campaign/${poll_campaign_id}?offset=${offset}`)
        return resp.data
    },
    getPollCampaignsByCompanyCode : async(code:string, offset:number, status:string):Promise<PollCampaignProps[]> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/campaigns/company/${code}?offset=${offset}&status=${status}`)
        return resp.data.poll_campaigns
    }
}

const PollCampaignHelpers = {
    isCampaignComplete: (polls:PollProps[], poll_responses:PollResponseProps[], poll_campaign?:PollCampaignProps) => {
        if(!poll_campaign){ return false }
        if(polls.length == 0){ return false }
        if(poll_campaign.status != 'active'){ return true }
        if(poll_responses.length == polls.length){ return true }
        if(!polls.find(p => p.status != 'closed')) { return true }
        let non_closed_polls = polls.filter(p => p.status == 'active')
        let missing_response = false
        non_closed_polls.map(p => {
            if(missing_response){ return }
            let response = poll_responses.find(pr => pr.poll_id == p.poll_id)
            if(!response){ missing_response = true }
        })
        return !missing_response
    },
    formatPlace :(place:number|string) => {
        switch(place){
            case 1:
                return '1st'
            case 2:
                return '2nd'
            case 3:
                return '3rd'
            case 4:
                return '4th'
            case 5:
                return '5th'
            case 6:
                return '6th'
            case 7:
                return '7th'
            case 8:
                return '8th'
            case 9:
                return '9th'
            default:
                return `${place}`

        }
    },
}

const PollApi = {
    createPoll : async(poll:PollProps):Promise<PollProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/poll/create`, { poll })
        return resp.data.poll
    },
    updatePoll : async(poll:PollProps):Promise<{ poll: PollProps, poll_options:PollOptionProps[] }> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/poll/update`, { poll })
        return { poll: resp.data.poll, poll_options:resp.data.poll_options }
    },
    activatePoll : async(poll_id:string):Promise<{ poll:PollProps, poll_options:PollOptionProps[] }> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/poll/activate`, { poll_id })
        return { poll: resp.data.poll, poll_options: resp.data.poll_options }
    },
    closePoll : async(poll_id:string, winning_option_id?:string, winning_value?:string):Promise<PollProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/poll/close`, { poll_id, winning_option_id, winning_value })
        return resp.data.poll 
    },
    deletePoll : async(poll_id:string):Promise<PollProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/poll/delete`, { poll_id })
        return resp.data.poll
    },
    getPollsByCampaignId : async(poll_campaign_id:string):Promise<{ polls:PollProps[], poll_campaign_goals:PollCampaignGoalProps[] }> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/campaign/${poll_campaign_id}`)
        return { polls: resp.data.polls, poll_campaign_goals: resp.data.poll_campaign_goals }
    },
    notifyPollResponders : async(poll_campaign_ids:string[], player_notification:any):Promise<void> => {
        await axios.post(`${AUTH_SVC_API}/v1/polls/campaigns/notify`, { poll_campaign_ids, player_notification })
        return
    },
    getPollsByRelatedIdAndType : async(related_type:string, related_id:string):Promise<PollProps[]> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/related/${related_type}/${related_id}`)
        return resp.data.polls
    },
    getPollById : async(poll_id:string):Promise<{ poll:PollProps, poll_options:PollOptionProps[], poll_summaries: PollSummaryProps[] }> => {
        console.log('getting data')
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/poll/${poll_id}`)

        const { poll, poll_options, poll_summaries } = resp.data;
        return { poll, poll_options, poll_summaries }
    }
}

const PollCampaignGoalApi = {
    getPollCampaignGoalById : async(poll_campaign_goal_id:string):Promise<PollCampaignGoalProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/goals/goal/${poll_campaign_goal_id}`)
        return resp.data.poll_campaign_goal
    },
    createPollCampaignGoal : async(poll_campaign_goal:PollCampaignGoalProps):Promise<PollCampaignGoalProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/goals/goal/create`, { poll_campaign_goal })
        return resp.data.poll_campaign_goal
    },
    updatePollCampaignGoal : async(poll_campaign_goal:PollCampaignGoalProps):Promise<PollCampaignGoalProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/goals/goal/update`, { poll_campaign_goal })
        return resp.data.poll_campaign_goal
    }
}

const PollResponseApi = {
    createPollResponse : async(poll_response:PollResponseProps):Promise<PollResponseProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/poll/response/create`, { poll_response })
        return resp.data.poll_response
    },
    getResponseByPollId: async(poll_id:string):Promise<PollResponseProps | undefined> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/response/poll/${poll_id}`)
        return resp.data.poll_response
    },
    getRespondersByPollId: async(poll_id:string, offset:number):Promise<string[]> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/responders/poll/${poll_id}?offset=${offset}`)
        return resp.data.player_ids
    },
    getRespondersByCampaignId: async(poll_campaign_id:string, offset:number):Promise<string[]> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/responders/campaign/${poll_campaign_id}?offset=${offset}`)
        return resp.data.player_ids
    },
    getMyCampaignResponses: async(poll_campaign_id:string):Promise<PollResponseProps[]> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/polls/campaigns/responses/${poll_campaign_id}`)
        return resp.data.poll_responses
    }
}

const PollResponseHelpers = {
    getOptionColor: (poll:PollProps, po:PollOptionProps, pr?:PollResponseProps) => {
        if(poll.status != 'closed'){
            if(!pr){ return Colors.shades.white }
            if(pr.status == 'pending'){ return Colors.shades.white }
            if(po.poll_option_id == pr?.poll_option_id && pr.result_ind == 'lose'){ return Colors.highlights.highlight300Faded }
            switch(po.result_ind){
                case 'win':
                    return Colors.highlights.highlight400Faded
                default:
                    return Colors.shades.white
            }
        } else {
            switch(po.result_ind){
                case 'win':
                    return Colors.highlights.highlight400Faded
                default:
                    return Colors.shades.white
            }
        }
    },
    nextAllowed: (polls:PollProps[], poll_responses:PollResponseProps[], active_poll_id?:string) => {
        if(!active_poll_id){ return false }
        if(poll_responses.find(pr => pr.poll_id == active_poll_id)){ return true }
        let active_poll = polls.find(p => p.poll_id == active_poll_id)
        if(!active_poll){ return false }
        if(active_poll.status != 'active'){ return true }
        return false
    },
    updateSummariesWithResponse : (pr:PollResponseProps, summaries:PollSummaryProps[]) => {
        let new_total = summaries.reduce((a,b) => a + parseFloat(b.stake as unknown as string), 0) + parseFloat(pr.stake as string)
        summaries = summaries.map(s => {
            if(s.poll_option_id == pr.poll_option_id){
                s.count += 1
                s.stake += parseFloat(pr.stake as string)
            }
            s.pct = s.stake / new_total
            return s
        })
        return summaries
    }
}

const PollOptionApi = {
    createPollOption : async(poll_option:PollOptionProps):Promise<PollOptionProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/poll/option/create`, { poll_option })
        return resp.data.poll_option
    },
    updatePollOption : async(poll_option:PollOptionProps):Promise<PollOptionProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/polls/poll/option/update`, { poll_option })
        return resp.data.poll_option
    }
}


