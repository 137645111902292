import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Text } from '../../../Components';
import Colors from '../../../constants/colors';

export const rti_playerEntity = (props:any) => {
    const entity = props.contentState.getEntity(props.entityKey).getData();
    console.log(entity)
    return (
        <TouchableOpacity onPress={() => console.log('Go to profile')}>
            <Text size={14} color={Colors.brand.electric} weight='semibold'>{props.children}</Text>
        </TouchableOpacity>
    )
}


export const rti_tagEntity = (props:any) => {
    const entity = props.contentState.getEntity(props.entityKey).getData();
    console.log(entity)
    return (
        <TouchableOpacity onPress={() => console.log('olah!')}>
            <Text size={14} color={Colors.brand.electric} weight='semibold'>{props.children}</Text>
        </TouchableOpacity>
    )
}