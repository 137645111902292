
import { StyleSheet } from 'react-native';
import Colors from './colors';


export const view_styles = StyleSheet.create({
    wrapper: {
        flexDirection:'row',
        flexWrap: 'wrap'
    },
    input: { padding:10, borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:4 },
    container: {
        backgroundColor:Colors.shades.white,
        borderTopRightRadius:22,
        borderTopLeftRadius:22
    },
    float: {
        shadowColor: "rgba(0, 0, 0, 0.06)", shadowOffset: { width: 0, height: 10 }, shadowRadius: 10, shadowOpacity: 1
    },
    section: { margin:10, backgroundColor:Colors.shades.white, borderRadius:8,  shadowColor: "rgba(0, 0, 0, 0.06)", shadowOffset: { width: 0, height: 10 }, shadowRadius: 10, shadowOpacity: 1 },
    section_header: { padding:10, borderTopRightRadius:8, borderTopLeftRadius:8, paddingLeft:20, paddingRight:20, flexDirection:'row', alignItems:'center', borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 },
    section_body: { flex:1, padding:20 },
    section_footer: { padding:10, borderBottomRightRadius:8, borderBottomLeftRadius:8, paddingLeft:20, paddingRight:20, flexDirection:'row', alignItems:'center', borderTopWidth:1, borderTopColor:Colors.shades.shade600, backgroundColor:Colors.shades.shade100 },
    body_row : { padding:5, flexDirection:'row', alignItems:'center' }
})

export const button_styles = StyleSheet.create({
    wizard_valid: {
        backgroundColor:Colors.shades.white, height:50, width:50, justifyContent:'center', alignItems:'center', borderRadius:100, ...view_styles.float
    },
    wizard_invalid: {
        backgroundColor:Colors.shades.white, height:50, width:50, justifyContent:'center', alignItems:'center', borderRadius:100, opacity:0.5
    }
})


export const text_styles = StyleSheet.create({
    dark_heading_1: {
        fontSize: 16,
        color:Colors.brand.midnight
    },
    dark_heading_2: {
        fontSize: 14,
        color:Colors.brand.midnight
    },
    dark_heading_3: {
        fontSize: 12,
        color:Colors.brand.midnight
    },
    light_heading_1: {
        fontSize: 16,
        color:Colors.shades.white
    },
    light_heading_2: {
        fontSize: 14,
        color:Colors.shades.white
    },
    light_heading_3: {
        fontSize: 12,
        color:Colors.shades.white
    },
    header: { color:Colors.brand.midnight, fontSize: 16, fontFamily: 'barlow-bold', textAlign:'left' },
    header_2: { color:Colors.brand.midnight, fontSize: 14, fontFamily: 'barlow-semibold', textAlign:'left' },
    error_header_2: { color:Colors.utility.error, fontSize: 14, fontFamily: 'barlow-semibold', textAlign:'left' },

    body: { color:Colors.brand.midnight, fontSize: 14, fontFamily: 'barlow-regular', textAlign:'left' },
    error_body: { color:Colors.utility.error, fontSize: 14, fontFamily: 'barlow-regular', textAlign:'left' },
    body_2: { color:Colors.brand.midnight, fontSize: 12, fontFamily: 'barlow-regular', textAlign:'left' },
    error_body_2: { color:Colors.utility.error, fontSize: 12, fontFamily: 'barlow-regular', textAlign:'left' }
})