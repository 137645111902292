import axios from 'axios';
import { APIOverrides } from '../../ApiOverrides';
import type { ACHDetailProps, AccountActionProps, AccountLimitProps, AccountProps, AccountSnoozeProps, CardDetailProps, CodeRequestProps, ItemOrderProps, ItemProps, MyPlayerProps, PlayerAddressProps, PlayerBalanceProps, PlayerDepositLimitProps, PlayerReferralProps, PlayerWithdrawLimitProps, PromoProps, PublicPlayerProps, RewardOptionProps, TransactionDetailProps, TransactionProps, WalletSettingsProps } from '../../types';
import moment from 'moment-mini';
import Colors from '../../constants/colors';

let WALLET_SVC_API = ''
let AUTH_SVC_API = ''
export { WalletApi, WalletHelpers, ItemOrderApi, ItemOrderHelpers }

const WalletApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        WALLET_SVC_API = endpoints['WALLET_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getMyDetails: async():Promise<MyPlayerProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
        return resp.data.player
    },
    getMyCodeDetails: async():Promise<{ code_request?:CodeRequestProps, promo?:PromoProps, player_referral?:PlayerReferralProps, referrer?:PublicPlayerProps, reward_option?:RewardOptionProps }> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/request/me`)
        return resp.data
    },
    getMyReferralDetails: async():Promise<{player_referral:PlayerReferralProps, promo:PromoProps}> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/referral/me`);
        return resp.data
    },
    updateDefaultAccount:async(account_id:string):Promise<AccountProps[]> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/accounts/account/default`, { account_id })
        return resp.data.accounts
    },
    getAddressById: async(player_address_id:string):Promise<PlayerAddressProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/addresses/address/${player_address_id}`)
        return resp.data.player_address
    },
    getMyAddresses: async():Promise<PlayerAddressProps[]> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/addresses/me`)
        return resp.data.player_addresses
    },
    createAddress: async(player_address:PlayerAddressProps):Promise<PlayerAddressProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/addresses/address/create`, { player_address })
        return resp.data.player_address
    },
    deleteAddress: async(player_address_id:string):Promise<PlayerAddressProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/addresses/address/delete`, { player_address_id })
        return resp.data.player_address
    },
    getMyBalance: async():Promise<PlayerBalanceProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/balance/me`)
        return resp.data.player_balance
    },
    getMySettings: async():Promise<WalletSettingsProps> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/settings/me`);
        return resp.data.wallet_settings
    },
    agreeToTerms: async():Promise<WalletSettingsProps> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/settings/terms/agree`)
        return resp.data.wallet_settings
    } ,
    setPin: async(pin:string):Promise<WalletSettingsProps> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/settings/pin/set`, { pin })
        return resp.data.wallet_settings
    },
    changePin: async(pin:string, new_pin:string):Promise<WalletSettingsProps> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/settings/pin/change`, { pin, new_pin })
        return resp.data.wallet_settings
    },
    authenticatePin: async(pin:string):Promise<WalletSettingsProps> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/settings/pin/authenticate`, { pin })
        return resp.data.wallet_settings
    },
    getMyAccounts: async():Promise<AccountProps[]> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/accounts/me`);
        return resp.data.accounts
    },
    getMyDepositLimit: async():Promise<PlayerDepositLimitProps> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/limits/deposit/me`)
        return resp.data.deposit_limit
    },
    getMyWithdrawLimit: async():Promise<PlayerWithdrawLimitProps> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/limits/withdraw/me`)
        return resp.data.withdraw_limit
    },
    getSnoozeByAccountId: async(account_id:string):Promise<AccountSnoozeProps|undefined> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/snoozes/account/${account_id}`);
        return resp.data.account_snooze
    },
    createAccountSnooze: async(account_snooze:AccountSnoozeProps):Promise<AccountSnoozeProps> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/snoozes/account/create`, { account_snooze })
        return resp.data.account_snooze
    },
    getLimitByAccountId: async(account_id:string):Promise<AccountLimitProps|undefined> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/limits/account/${account_id}`);
        return resp.data.account_limit
    },
    createAccountLimit: async(account_limit:AccountLimitProps):Promise<AccountLimitProps> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/limits/account/create`, { account_limit })
        return resp.data.account_limit
    },
    removeAccountLimit: async(account_limit_id:string):Promise<AccountLimitProps> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/limits/account/remove`, { account_limit_id })
        return resp.data.account_limit
    },
    getAccountById: async(account_id:string):Promise<AccountProps> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/accounts/account/${account_id}`)
        return resp.data.account
    },
    removeAccount: async(account_id:string):Promise<{ account:AccountProps, ach_detail?:ACHDetailProps, card_detail?:CardDetailProps }> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/accounts/account/remove`, { account_id })
        return { account: resp.data.account, ach_detail:resp.data.ach_detail, card_detail:resp.data.card_detail }
    },
    addACHAccount: async(account:AccountProps, ach_detail:ACHDetailProps):Promise<{ account:AccountProps, ach_detail:ACHDetailProps }> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/accounts/account/ach/add`, { account, ach_detail })
        return { account: resp.data.account, ach_detail: resp.data.ach_detail }
    },
    verifyACHAccount: async(account_id:string, amount_1:number, amount_2:number):Promise<{ account:AccountProps, status:'success'|'fail' }> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/accounts/account/ach/verify`, { account_id, amount_1, amount_2 })
        return { account: resp.data.account, status:resp.data.status }
    },
    updateACHAccount: async(account:AccountProps, ach_detail:ACHDetailProps):Promise<{ account: AccountProps, ach_detail:ACHDetailProps }> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/accounts/account/ach/update`, { account, ach_detail })
        return resp.data
    },
    getACHDetailByAccountId: async(account_id:string):Promise<ACHDetailProps> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/accounts/ach/${account_id}`)
        return resp.data.ach_detail
    },
    linkACHAccount: async(account_id:string, player_address_id:string):Promise<AccountProps> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/accounts/account/ach/link`, { account_id, player_address_id })
        return resp.data.account
    },
    addCardAccount: async(account:AccountProps, card_detail:CardDetailProps):Promise<{ account:AccountProps, card_detail:CardDetailProps }> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/accounts/account/card/add`, { account, card_detail })
        return { account:resp.data.account, card_detail:resp.data.card_detail }
    },
    getCardDetailByAccountId: async(account_id:string):Promise<CardDetailProps> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/accounts/card/${account_id}`)
        return resp.data.card_detail
    },
}

const ItemOrderApi = {
    getItemByIdentifier: async(identifier:string) => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/items/identifier/${identifier}`)
        return resp.data.item
    },
    getTransactionById: async(transaction_id:string):Promise<{ transaction:TransactionProps, transaction_details:TransactionDetailProps[] }> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/transactions/transaction/${transaction_id}`)
        return resp.data
    },
    getMyItemOrders: async(offset:number):Promise<{item_orders:ItemOrderProps[], items:ItemProps[]}> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/orders/me?offset=${offset}`)
        return resp.data
    }
}

const ItemOrderHelpers = {
    genDepositOrder : (item:ItemProps, account?:AccountProps):ItemOrderProps => {
        let amount = 25
        let fee = 0
        if(account){
            fee = account.transaction_fee * amount
        }
        let total_amount = amount + fee
        return {
            items: [{
                item_id: item.item_id,
                amount: amount,
                quantity: 1
            }],
            type: 'wallet',
            item_order_id: '',
            amount,
            total_amount,
            description: 'One time deposit',
            location: {},
            region: '',
            fee_1: "Transaction Fee",
            fee_1_amount: fee,
            player_id: '',
            status: 'pending',
            create_datetime: '',
            last_update_datetime: ''
        }
    },
    getStatusLabel : (item_order:ItemOrderProps) => {
        let label = { text: item_order.status, color:Colors.brand.electric }
        switch(item_order.status){
            case 'failed':
                label.text = 'Failed'
                label.color = Colors.utility.error
                break
            case 'processing':
                label.text = 'Posted'
                label.color = Colors.utility.warning
                break
            case 'transaction_pending':
                label.text = 'Pending'
                label.color = Colors.brand.electric
                break
            case 'cancelled':
                label.text = 'Cancelled'
                label.color = Colors.utility.error
                break
            case 'processed':
                label.text = 'Settled'
                label.color = Colors.utility.success
                break
            default: break
        }
        return label
    },
    isWithdrawOrderValid: (account:AccountProps, withdraw_limit:PlayerWithdrawLimitProps, deposit_limit:PlayerDepositLimitProps, player_balance:PlayerBalanceProps, wallet_settings?:WalletSettingsProps, draft_order?:ItemOrderProps):{ available_for_withdraw:number, errors:string[]} => {
        if(!draft_order){ return { available_for_withdraw: 0, errors:['Awaiting draft order'] } }
        if(!wallet_settings){ return {available_for_withdraw: 0, errors: ['Awaiting players setting values'] } }


        let errors:string[] = []
        let amount = parseFloat(draft_order.amount as string);
        if(isNaN(amount)){ errors.push('Invalid Amount') }
        if(amount < 1){ errors.push('Minumim withdrawal amount is $1') }
        if(wallet_settings.status != 'active'){ errors.push('Your account is not setup for withdraws') }
        if(amount > player_balance.balance){ errors.push('Amount exceeds your balance amount') }


        let transactions_remaining = withdraw_limit.withdraws_per_week - withdraw_limit.week_withdraw_count
        if(transactions_remaining <=0){ errors.push(`Already reached your max week withdraw transactions of ${withdraw_limit.withdraws_per_week}`) }
        let available_for_withdraw = player_balance.balance - deposit_limit.instant_deposits;
        switch(account.account_type){
            case 'card':
                let card_available = withdraw_limit.card_daily_limit - withdraw_limit.last_days_card_withdraws
                if(amount > card_available){ errors.push(`This amount exceeds your daily limit for card accounts`) }
                if(!wallet_settings.allow_cards){ errors.push(`Your account does not currently allow card withdraws`) }
                break
            case 'ach':
                let ach_available = withdraw_limit.ach_daily_limit - withdraw_limit.last_days_ach_withdraws

                if(amount > ach_available){ errors.push('This amount exceeds your daily limit for ach accounts') }
                if(account?.ach_detail?.ach_type=='manual' && !wallet_settings.allow_manual_ach){ errors.push(`Your account does not currently allow manual ACH withdraws`) }
                break
            case 'paypal':
                let pp_available = withdraw_limit.card_daily_limit - withdraw_limit.last_days_card_withdraws

                if(amount > pp_available){ errors.push(`This amount exceeds your daily limit for paypal`) }
                if(!wallet_settings.allow_paypal){ errors.push(`Your account does not currently allow paypal withdraws`)}
                break
            default: 
                errors.push('This account is not eligible for withdraws');
                break
        }
        return { available_for_withdraw, errors }
    },
    isDepositOrdervalid :(account:AccountProps, deposit_limit:PlayerDepositLimitProps, wallet_settings?:WalletSettingsProps, draft_order?:ItemOrderProps):string[] => {
        if(!draft_order){ return ['Awaiting draft order'] }
        if(!wallet_settings){ return ['Awaiting players setting values'] }
        let errors:string[] = []
        let amount = parseFloat(draft_order.amount as string);
        if(isNaN(amount)){ errors.push('Invalid Amount') }

        if(amount < 25){ errors.push('Amount must be greater than $25')}
        if(wallet_settings.status != 'active'){ errors.push(`Your account is not setup for deposits`) }
        switch(account.account_type){
            case 'card':
                let card_available = deposit_limit.card_daily_limit - deposit_limit.last_days_card_deposits
                if(amount > card_available){ errors.push(`This amount exceeds your daily limit for card accounts`) }
                if(!wallet_settings.allow_cards){ errors.push(`Your account does not currently allow card deposits`) }
                break
            case 'ach':
                let ach_available = deposit_limit.ach_daily_limit - deposit_limit.last_days_ach_deposits
                if(amount > ach_available){ errors.push('This amount exceeds your daily limit for ach accounts') }
                if(account?.ach_detail?.ach_type=='manual' && !wallet_settings.allow_manual_ach){ errors.push(`Your account does not currently allow manual ACH deposits`) }
                break
            case 'paypal':
                let pp_available = deposit_limit.card_daily_limit - deposit_limit.last_days_card_deposits
                if(amount > pp_available){ errors.push(`This amount exceeds your daily limit for paypal`) }
                if(!wallet_settings.allow_paypal){ errors.push(`Your account does not currently allow paypal deposits`)}
                break
            default: 
                errors.push('This account is not eligible for deposits');
                break
        }
        return errors
    },
    genWithdrawOrder : (item:ItemProps, account:AccountProps):ItemOrderProps => {
        let amount = 1
        let fee = account.transaction_fee * amount
        let total_amount = amount + fee
        return {
            items: [{
                item_id: item.item_id,
                amount: amount,
                quantity: 1
            }],
            type: 'wallet',
            item_order_id: '',
            amount,
            total_amount,
            description: item.description,
            location: {},
            region: '',
            fee_1: "Transaction Fee",
            fee_1_amount: fee,
            player_id: '',
            status: 'pending',
            create_datetime: '',
            last_update_datetime: ''
        }
    },

    getDepositAvailable: (deposit_limit:PlayerDepositLimitProps, account:AccountProps) => {
        let deposit_available = 0
        if(deposit_limit){
            deposit_available = deposit_limit.card_daily_limit - deposit_limit.last_days_card_deposits
            if(account?.account_type == 'ach'){
                deposit_available = deposit_limit.ach_daily_limit - deposit_limit.last_days_ach_deposits
            }
        }
        return deposit_available
    }
}

const WalletHelpers = {
    isWalletAuthenticated: (wallet_settings:WalletSettingsProps):boolean => {
        if(!wallet_settings.agreed_to_terms || !wallet_settings.pin || !wallet_settings.auth_end_datetime || moment().isAfter(moment(wallet_settings.auth_end_datetime))){ return false }
        return true
    },
    formatBalance: (balance:number) => {
        let round_down_balance = Math.round(balance * 100) / 100;
        let str_balance = round_down_balance.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return str_balance

    },
    genWalkthroughSteps: (wallet_settings:WalletSettingsProps, accounts:AccountProps[], my_orders:{ item_orders:ItemOrderProps[], items:ItemProps[] }) => {
        let steps = [
            { priority: 1, step: 'welcome', label: 'Setup Wallet Security', complete: false },
            { priority: 2, step: 'my_balance', label: 'Understand My Balances', complete:true },
            { priority: 3, step: 'fund_account', label: 'Select a method to fund your account', complete:true },
            { priority: 4, step: 'deposit', label: 'Make Your First Deposit', complete: false },
            { priority: 5, step: 'withdraw', label: 'Get Setup For Withdrawals', complete:false }
        ]
        steps = steps.map(s => {
            switch(s.step){
                case 'welcome':
                    if(wallet_settings.agreed_to_terms && wallet_settings.pin){ return { ...s, complete: true } }
                    return s
                case 'withdraw':
                    let added_ach_account = accounts.find(a => a.account_type == 'ach' && !a.ibt_placeholder)
                    if(added_ach_account){ return { ...s, complete:true } }
                    return s
                case 'deposit':
                    let deposit = my_orders.item_orders.find(o => ['processing','processed','settled'].includes(o.status) && o.type == 'wallet');
                    if(deposit){ return { ...s, complete:true } }
                    return s
                default: return s
            }
        })
        return steps
    },
    isNumber(value?: string | number): boolean
    {
        return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
    },
    getEmptyCardAccount: ():AccountProps => {
        return {
            account_id: '',
            account_type: 'card',
            player_id: '',
            transaction_fee: 0.045,
            create_datetime:'',
            last_update_datetime: '',
            status: 'pending',
            account_label: ''
        }
    },
    getCardBrandFromNumber: (card_number:string):string => {
        if(!card_number){ return '' }
        let first_number = card_number[0]
        switch(first_number){
            case '3': return 'AMEX'
            case '4': return 'VISA'
            case '5': return 'MASTERCARD'
            case '6': return 'DISCOVER'
            default: return ''
        }
    },
    getEmptyCardDetails:():CardDetailProps => {
        return {
            account_id: '',
            card_detail_id: '',
            first_name: '',
            last_name: '',
            card_number:'',
            create_datetime:'',
            last_update_datetime:'',
            masked_card_number: '',
            expiration_date: '',
            encryption_iv:'',
            cvv: '',
            status: 'active'
        }
    },
    getReferralDescription : (promo:PromoProps) => {
        switch(promo.type){
            case 'promo_code': return `Get ${promo.amount} promo bucks after you verify your account`
            case 'promo_sweepstakes': return `Have a chance at UP TO 500 promo bucks after you verify your account`
            case 'referral_code': return `Get ${promo.amount} promo bucks after you verify your account. Your referrer will get the same after you make your first deposit`
            case 'referral_sweepstakes': return `Have a chance at UP TO 500 promo bucks after you verify your account. Your referrer will get the same after you make your first deposit`
            default: return ''
        }
    } ,
    isCardValid: (card_detail:CardDetailProps) => {
        let valid = {
            valid: false,
            card_number: WalletHelpers.isNumberValid(card_detail.card_number),
            expiration_date: WalletHelpers.isExpireValid(card_detail.expiration_date),
            first_name: card_detail.first_name.length > 0 ? true : false,
            last_name: card_detail.last_name.length > 0 ? true : false
        }
        if(valid.card_number && valid.expiration_date && valid.first_name && valid.last_name){ valid.valid = true }
        return valid
    },
    isNumberValid: (card_number:string) => {
        if(isNaN(parseInt(card_number))){ return false }
        
        let brand = WalletHelpers.getCardBrandFromNumber(card_number)
        switch(brand){
            case 'AMEX':
                if(card_number.length != 15){ return false }
                return true
            default: 
                if(card_number.length != 16){ return false }
                return true
        }
    },
    isExpireValid : (expiry:string) => {
        if(expiry.length != 7){ return false }
        let moment_date = moment(expiry, 'MM/YYYY')
        if(!moment_date.isValid()){ return false }
        if(moment_date.isBefore(moment())){ return false }
        return true
    },
    formatCardNumber : (card_number:string) => {
        let brand = WalletHelpers.getCardBrandFromNumber(card_number)
        if(!brand){ return card_number }
        let split_number = card_number.split('')
        let fcn = ''
        switch(brand){
            case 'AMEX':
                split_number.map((n, i) => {
                    
                    if(i === 4 || i === 10){ fcn += ` ${n}` }
                    else { fcn+= n }
                })
                break
            default: 
                split_number.map((n, i) => {
                    if(i === 4 || i === 8 || i === 12){ fcn += ` ${n}` }
                    else { fcn += n }
                })
                break
        }
        return fcn
    },
    unformatCardNumber: (card_number:string) => {
        return card_number.replace(/\s/g, "");
    },
    getEmptyACHAccount:():AccountProps => {
        return {
            account_id: '',
            account_type: 'ach',
            transaction_fee: 0,
            player_id: '',
            create_datetime:'',
            last_update_datetime: '',
            status: 'pending',
            account_label: ''
        }
    },
    getEmptyACHDetail:():ACHDetailProps => {
        return {
            account_id: '',
            ach_detail_id:'',
            ach_type:'manual',
            create_datetime:'',
            account_type: 'checking',
            last_update_datetime:'',
            bank_name: '',
            routing_number: '',
            account_number: '',
            external_id: '',
            payment_provider:'payliance'
        }
    },
    getAccountOptions: (account:AccountProps):AccountActionProps[] => {
        if(!account){ console.log('') }
        return [
            { action_id: '1', action_type: 'deposit', action_title: 'Deposit Funds', action_description: 'Deposit to your BettorEdge balance using this account' },
            { action_id: '2', action_type: 'withdraw', action_title: 'Withdraw Funds', action_description: 'Withdraw funds to this bank account from your BettorEdge balance' },
            { action_id: '3', action_type: 'limits', action_title: 'Account Limits', action_description: 'Set personal deposit limits to manage how much you use BettorEdge' },
            { action_id: '4', action_type: 'snoozes', action_title: 'Snooze Account', action_description: 'Set snooze for this account to take a break from BettorEdge' },
            { action_id: '5', action_type: 'remove', action_title: 'Remove Account', action_description: 'Remove and delete this account from your profile' },
        ]
    },
    /**
     * Get the frequency from the days
     * @param days 
     * @returns 
     */
    getFrequencyFromDays:(days:number):string => {
        switch(days){
            case 1: return 'Daily'
            case 7: return 'Weekly'
            case 30: return 'Monthly'
            default: return 'Daily'
        }
    },

    getEmptyAddress : ():PlayerAddressProps => {
        return {
            player_address_id: '',
            name: '',
            street: '',
            state: '',
            city: '',
            country: 'US',
            type: 'billing',
            zip: '',
            player_id :'',
            create_datetime: '',
            last_update_datetime:'',
            status:'active'
        }
    },
    isAddressValid: (address?:PlayerAddressProps):string[] => {
        let errors:string[] = []
        if(!address){ return ['There is no address'] }
        const { street, city, country, zip, state, name } = address
        if(street == '' || city == '' || country == '' || zip == '' || state == '' || name == ''){ errors.push('Please complete all required fields') }
        if(state.length != 2){ errors.push('State Codes must be 2 letters') }
        return errors
    }

}