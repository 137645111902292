import React from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import type { CompetitionSeasonProps } from '../../types';
import { view_styles } from '../../constants/styles';
import { Text } from '../../Components';
import Colors from '../../constants/colors';
import moment from 'moment-mini';

type SeasonCardProps = {
    competition_season:CompetitionSeasonProps,
    width: number,
    onSelectSeason: (cs:CompetitionSeasonProps) => void
}
const MAX_IMAGE_WIDTH = 175

const SeasonCard = ({ competition_season, width, onSelectSeason }:SeasonCardProps) => {
    const image_width = width > MAX_IMAGE_WIDTH ? MAX_IMAGE_WIDTH : width

    return (
        <TouchableOpacity style={{ ...view_styles.section, width:image_width, backgroundColor:Colors.brand.mint, borderRadius:8 }} onPress={() => onSelectSeason(competition_season)}>
            <View style={{ padding:10, flexDirection:'row', alignItems:'center' }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>SEASON</Text>
                </View>
                <Text theme='header'>${parseFloat(competition_season.ticket_price as string).toFixed(2)}</Text>
            </View>
            <View style={{ backgroundColor:Colors.shades.shade100 }}>
                <Image
                    source={{ uri: competition_season.image?.url }}
                    style={{ height:image_width, width:image_width, borderRadius:8 }}
                    resizeMode='cover'
                />
                 <View style={{ position:'absolute', backgroundColor:Colors.shades.white, bottom:0, left:0, right:0, padding:10, borderBottomRightRadius:8, borderBottomLeftRadius:8 }}>
                    <Text theme='header_2'>{competition_season.season_name}</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>Starts {moment(competition_season.scheduled_datetime).format('MMM DD hh:mm a')}</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
}

export default SeasonCard