
import React, { useState } from 'react';
import { View, FlatList, ScrollView, TouchableOpacity, Image } from "react-native"
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import type { AccountProps, MyPlayerProps, PlayerBalanceProps } from '../../types';
import { WalletHelpers } from '../api';

type WalletActionSelectorProps ={
    player:MyPlayerProps,
    player_balance:PlayerBalanceProps,
    accounts:AccountProps[],
    onClose: () => void,
    walkthrough_steps:any[],
    onActionSelect:(action:any, account?:AccountProps) => void,
    onStartWalkthrough:(walkthrough:any, account?:AccountProps) => void
}

const sections = [
    { 
        section: 'accounts', label:'Account Actions', actions : [
            { 
                action :'my_accounts', label: "View My Accounts", icon:<Icons.ListIcon color={Colors.brand.midnight} size={12}/>, description: 'Manage your connected accounts.  Transact, snooze, set limits or remove an existing account.'
            },
            { 
                action :'wallet_settings', label: "View My Settings", icon:<Icons.SettingsIcon color={Colors.brand.midnight} size={20}/>, description: 'View your current settings including your deposit and withdraw limits.'
            },
            
        ] 
    },
    { 
        section: 'transact', label:'Transact', actions : [
            { 
                action :'order_history', label: "View Order History", icon:<Icons.TransactionIcon size={20} color={Colors.brand.midnight} />, description: 'View and manage past orders made on BettorEdge'
            },
            { 
                action :'deposit', label: "Make A Deposit", icon:<Icons.DepositIcon size={20} color={Colors.brand.midnight} />, description: 'Fund your BettorEdge real-money balance by making a deposit from an existing account or new one.'
            },
            { 
                action :'withdraw', label: "Make A Withdrawal", icon:<Icons.WithdrawIcon size={20} color={Colors.brand.midnight} />, description: 'Move funds from your BettorEdge real-money balance to a connected ACH account.'
            },
            
        ] 
    }
]

const WalletActionSelector = ({ player, walkthrough_steps, player_balance, accounts, onClose, onActionSelect, onStartWalkthrough }: WalletActionSelectorProps) => {
    const [ show_steps, setShowSteps ] = useState(false);
    const [ width, setWidth ] = useState(0);

    const handleActionSelect = (action:any) => {
        switch(action.action){
            case 'deposit':
                let default_account = accounts.find(a => a.default_account)
                if(!default_account){ default_account = accounts.find(a => a.account_type == 'paypal')}
                return onActionSelect(action, default_account)
            case 'withdraw':
                let withdraw_account = accounts.find(a => !a.ibt_placeholder && a.account_type == 'ach' && a.status == 'active')
                if(!withdraw_account){ return onActionSelect({ action: 'ach_add' }) }
                return onActionSelect(action, withdraw_account)
            default: onActionSelect(action)
        }
    }

    const renderActions = (data:{ item:any, index:number }) => {
        return (
            <TouchableOpacity style={{ padding:10, flexDirection:'row', alignItems:'center', borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}
            onPress={() => handleActionSelect(data.item)}>

                <View style={{ flex:1, marginRight:10 }}>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                        {data.item.icon?
                            data.item.icon
                        :<></>}
                        <Text style={{ marginLeft:5 }} size={16} color={Colors.brand.midnight} weight='bold'>{data.item.label}</Text>
                    </View>
                    <Text style={{ padding:5 }} size={14} color={Colors.brand.midnight} weight='regular'>{data.item.description}</Text>
                </View>
                <Icons.ChevronIcon direction='right' color={Colors.brand.midnight} size={12} />
            </TouchableOpacity>
        )
    }

    const renderSections = (data: { item:any, index:number }) => {
        return (
            <View style={{ padding:5 }}>
                <Text size={16} color={Colors.brand.midnight} weight='regular'>{data.item.label.toUpperCase()}</Text>
                <View style={{ padding:5 }}>
                    <FlatList
                        key='actions'
                        data={data.item.actions}
                        renderItem={renderActions}
                        keyExtractor={(item) => item.action}
                    />
                </View>
                
            </View>
        )
    }

    const handleStepSelect = (step:any, index:number) => {
        console.log(step)
        switch(step.step){
            case 'deposit':
                let default_account = accounts.find(a => a.default_account)
                if(!default_account){ default_account = accounts.find(a => a.account_type == 'paypal') }
                if(!default_account){ return }
                return onStartWalkthrough({ enabled:true, steps: walkthrough_steps, active_step: step.step, next_step: walkthrough_steps[index + 1]?.step}, default_account)
            default:
                return onStartWalkthrough({ enabled:true, steps: walkthrough_steps, active_step: step.step, next_step: walkthrough_steps[index + 1]?.step})
        }
        
    }

    const renderSteps = (data: { item:any, index:number }) => {
        return (
            <TouchableOpacity
                style={{ margin:5, padding:10, flexDirection:'row', alignItems:'center', borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:8 }}
                onPress={() => handleStepSelect(data.item, data.index)}>
                <Icons.CheckCirlceIcon size={14} color={data.item.complete?Colors.utility.success:Colors.brand.slate}/>
                <Text style={{ flex:1, marginLeft:10 }} size={14} color={Colors.brand.midnight}>{data.item.label}</Text>
                <Icons.ChevronIcon direction='right' size={8} color={Colors.brand.midnight} />
            </TouchableOpacity>
        )
    }

    const pct_complete = walkthrough_steps.filter(s => s.complete).length / walkthrough_steps.length
    
    return (
        <View style={{ flex:1 }} onLayout={(ev) => {
            const { width } = ev.nativeEvent.layout
            setWidth(width)
        }}>
            <View style={{ padding:10, flexDirection:'row', alignItems:'center' }}>
                <Image
                    source={{ uri: player.profile_pic }}
                    style={{ height:60, width:60, borderRadius:4 }}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:5 }}>
                    <Text size={20} color={Colors.brand.midnight} weight='bold'>Wallet Balances</Text>
                    <View style={{ flexDirection:'row' }}>
                        <View style={{ margin:3, backgroundColor:Colors.brand.cobalt, padding:5, borderRadius:8 }}>
                            <Text size={14} color={Colors.shades.white} weight='bold'>${WalletHelpers.formatBalance(player_balance.balance)}</Text>
                        </View>
                        <View style={{ margin:3, backgroundColor:Colors.brand.cobalt, padding:5, borderRadius:8 }}>
                            <Text size={14} color={Colors.shades.white} weight='bold'>P{WalletHelpers.formatBalance(player_balance.promo_balance)}</Text>
                        </View>
                        <View style={{ margin:3, backgroundColor:Colors.brand.cyan, padding:5, borderRadius:8 }}>
                        <Text size={14} color={Colors.shades.white} weight='bold'>E{WalletHelpers.formatBalance(player_balance.free_market_balance)}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <ScrollView style={{ flex:1 }}>

                {pct_complete < 1 ?
                <View style={{ backgroundColor:Colors.shades.white, padding:10 }}>
                    <View>
                        <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:10 }} onPress={() => setShowSteps(!show_steps)}>
                            <View style={{ flex:1, marginRight:10 }}>
                                <Text color={Colors.brand.midnight} size={14} weight='bold'>Setup your wallet</Text>
                                <Text style={{ marginTop:3 }} color={Colors.brand.slate} size={14} weight='regular'>Complete all the steps below to have full access to move funds in and out of BettorEdge</Text>
                            </View>
                            <Icons.ChevronIcon direction={show_steps ? 'up' : 'down'} color={Colors.brand.midnight} size={10} />
                        </TouchableOpacity>
                        <View style={{ flexDirection:'row', justifyContent:'center', alignItems:'center', }}>
                            <Text size={14} weight='bold'>{(pct_complete * 100).toFixed()}%</Text>
                            <View style={{ marginLeft:20, height:10, width:width - 100, backgroundColor:Colors.brand.slate, borderRadius:15 }}>
                                <View style={{ position:'absolute', top:0, left:0, bottom:0, width: (width-100)*pct_complete, backgroundColor:Colors.utility.success, borderRadius:15 }} />
                            </View>
                        </View>
                    </View>
                    {show_steps ?
                    <View style={{marginTop:5, borderTopWidth:1, borderColor:Colors.shades.shade600 }}>
                        <FlatList
                            data={walkthrough_steps}
                            renderItem={renderSteps}
                            keyExtractor={(item) => item.step}
                        />
                    </View>
                    :<></>}
                </View>
                :<></>}
                <View style={{ padding:10 }}>
                    <FlatList
                        key={'wallet_sections'}
                        data={sections}
                        renderItem={renderSections}
                        keyExtractor={(item) => item.section}
                    />
                </View>
            </ScrollView>
            <View nativeID='action_row' style={{ padding:20 }}>
                <Button
                    title='CLOSE'
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => onClose()}
                />
            </View>
           
        </View>
    )
}

export default WalletActionSelector