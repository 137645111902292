import React, { useState } from 'react';
import { View, Image, TouchableOpacity, Linking, ScrollView } from "react-native";
import type { MyPlayerProps, WalletSettingsProps } from '../../types';
import { Button, Checkbox, Icons, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import { WalletApi } from '../api';
import moment from 'moment-mini';
import { button_styles } from '../../constants/styles';

type WalletSetupProps = {
    player: MyPlayerProps,
    wallet_settings: WalletSettingsProps,
    onSettingsUpdate:(ws:WalletSettingsProps) => void,
    onClose?:() => void,
    onNext?:() => void
}

const WalletSetup = ({ wallet_settings, onSettingsUpdate, onClose, onNext }:WalletSetupProps) => {
    const [ terms_agreed, setTermsAgreed ] = useState(false);
    const [ auth_error, setAuthError ] = useState<string>();
    const [ pin_view, setPinView ] = useState<'set'|'confirm'>('set');
    const [ pin_settings, setPinSettings ] = useState({
        pin: '',
        confirm_pin:''
    })
    const { pin, confirm_pin } = pin_settings;

    const pin_valid = pin.length >= 4 ? true:false;
    const go_valid = pin_valid && confirm_pin == pin ? true : false;

    const handleAgreeTerms = async() => {
        const ws = await WalletApi.agreeToTerms()
        onSettingsUpdate(ws)
    }

    const handleSetPin = async() => {
        if(!pin_valid || !go_valid){ return }
        const ws = await WalletApi.setPin(pin)
        onSettingsUpdate(ws);
    }

    const handleAuthPin = async() => {
        if(!pin_valid){ return }
        try {
            const ws = await WalletApi.authenticatePin(pin);
            onSettingsUpdate(ws);
        } catch (e) {
            setAuthError('Invalid Pin - Please try again')
        }
    }

    const auth_required = wallet_settings.auth_end_datetime && moment().isAfter(wallet_settings.auth_end_datetime) ? true : false

    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>MY WALLET</Text>
                {onNext ?
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>Welcome to your wallet.  Go through the wizard to get set up to deposit and withdraw!</Text>
                :!wallet_settings.agreed_to_terms ?
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>Welcome to your wallet.  This is where your sensitive account details are kept.  Please review our terms and conditions!</Text>
                :
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>Welcome to your wallet.  Please enter your security pin to continue!</Text>
                }
            </View>
            <ScrollView style={{ flex:1 }}>
            {!wallet_settings.agreed_to_terms ?
            <View style={{ flex:1, padding:20 }}>
                <View>
                    <Text size={20} color={Colors.brand.slate} weight='semibold' textAlign='center'>BettorEdge Wallet</Text>
                    <Text style={{ marginTop:5 }} size={26} color={Colors.brand.midnight} weight='bold' textAlign='center'>Terms and Conditions</Text>
                </View>
                <View style={{ justifyContent:'center', alignItems:'center' }}>
                <Image
                    source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1717098691/Signing_the_contract_stfvtj.png' }}
                    style={{ width: 250, height:250 }}
                    resizeMode='cover'
                />
                
                </View>
                <TouchableOpacity onPress={() => Linking.openURL('https://www.bettoredge.com/wallet-terms-of-use')}>
                    <Text size={14} color={Colors.brand.midnight} textAlign='center'>Press here to review our <Text size={14} color={Colors.brand.electric} weight='bold'>Wallet Terms of Use</Text>. To continue setting up your wallet please review and agree to the terms.</Text>
                </TouchableOpacity>
                <TouchableOpacity 
                    style={{ marginTop:15, flexDirection:'row', alignItems:'center', backgroundColor:Colors.highlights.highlight400Faded, padding:20, borderRadius:8 }}
                    onPress={() => setTermsAgreed(!terms_agreed)}>
                    <Text style={{ flex:1 }} size={16} color={Colors.brand.midnight}>I have read and agree to the terms</Text>
                    <Checkbox size={30} checked={terms_agreed} disabled onSelect={() => console.log('')}/>
                </TouchableOpacity>
            </View>
            : !wallet_settings.pin ?
            <View style={{ flex:1, padding:20 }}>
                <View>
                    <Text size={20} color={Colors.brand.slate} weight='semibold' textAlign='center'>BettorEdge Wallet</Text>
                    <Text style={{ marginTop:5 }} size={26} color={Colors.brand.midnight} weight='bold' textAlign='center'>{pin_view == 'set' ? 'Set' : 'Confirm'} Security PIN</Text>
                </View>
                <View style={{ justifyContent:'center', alignItems:'center' }}>
                    <Image
                        source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1717099686/Secure_lock_and_key_successfully_unlocked_it6hy0.png' }}
                        style={{ width: 250, height:250 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ marginBottom:15 }}>
                    <Text size={14} color={Colors.brand.midnight} textAlign='center'>Let's secure your sensitive information. Before doing any activity in your wallet, you will be required to enter a pin.  Lets set it now.</Text>
                </View>
                {pin_view == 'set' ?
                <TextInput
                    style={{ width:200, alignSelf:'center' }}
                    placeholder='HARDPIN'
                    placeholderTextColor={Colors.brand.slate}
                    value={pin}
                    onChangeText={(text) => setPinSettings({ ...pin_settings, pin: text })}
                />
                :
                <View>
                    <TextInput
                        style={{ width:200, alignSelf:'center' }}
                        placeholder='RE-Enter PIN'
                        placeholderTextColor={Colors.brand.slate}
                        value={confirm_pin}
                        onChangeText={(text) => setPinSettings({ ...pin_settings, confirm_pin: text })}
                    />
                </View>
                
                }
            </View>
            :auth_required ?
            <View style={{ flex:1, padding:20 }}>
                <View>
                    <Text size={20} color={Colors.brand.slate} weight='semibold' textAlign='center'>BettorEdge Wallet</Text>
                    <Text style={{ marginTop:5 }} size={26} color={Colors.brand.midnight} weight='bold' textAlign='center'>Enter Security PIN</Text>
                </View>
                <View style={{ justifyContent:'center', alignItems:'center' }}>
                    <Image
                        source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1717099686/Secure_lock_and_key_successfully_unlocked_it6hy0.png' }}
                        style={{ width: 250, height:250 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ marginBottom:15 }}>
                    <Text size={14} color={Colors.brand.midnight} textAlign='center'>Before you can continue to your wallet.  Please enter your security pin.</Text>
                </View>
                
                <TextInput
                    style={{ width:200, alignSelf:'center' }}
                    placeholder='ENTER PIN'
                    placeholderTextColor={Colors.brand.slate}
                    value={pin}
                    onChangeText={(text) => setPinSettings({ ...pin_settings, pin: text })}
                />
                {auth_error ? 
                <View style={{ padding:10 }}>
                    <Text size={14} color={Colors.utility.warning} weight='bold' textAlign='center'>{auth_error}</Text>
                </View>
                :<></>}
            </View>
            :
            <View style={{ flex:1 }}>
                <View style={{ justifyContent:'center', alignItems:'center' }}>
                    <Image
                        source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1717099686/Secure_lock_and_key_successfully_unlocked_it6hy0.png' }}
                        style={{ width: 250, height:250 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ margin:15, borderRadius:8, padding:10 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='bold' textAlign='center'>WALLET SECURED!</Text>
                    <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} textAlign='center'>Select next to continue your wallet set up by adding a transaction account.</Text>
                </View>
            </View>
            }
            </ScrollView>
            <View style={{ flexDirection:'row', padding:20 }}>
                {!wallet_settings.agreed_to_terms && onClose ?
                <View style={{ flex:1, flexDirection:'row' }}>
                    <Button
                        title='EXIT SETUP'
                        style={{ flex:1, marginRight:5 }}
                        title_color={Colors.utility.error}
                        backgroundColor='transparent'
                        onPress={() => onClose()}
                    />
                    <Button
                        title='I AGREE'
                        style={{ flex:3, opacity:terms_agreed?1:0.5 }}
                        disabled={!terms_agreed}
                        backgroundColor={Colors.brand.electric}
                        title_color={Colors.shades.white}
                        padding={15}
                        onPress={() => handleAgreeTerms()}
                    />
                </View>
                : !wallet_settings.pin && pin_view == 'set' ?
                    <Button
                        title='CONFIRM PIN'
                        style={{ flex:3, opacity:pin_valid?1:0.5 }}
                        disabled={!pin_valid}
                        backgroundColor={Colors.utility.success}
                        title_color={Colors.shades.white}
                        padding={15}
                        onPress={() => setPinView('confirm')}
                    />
                : !wallet_settings.pin && pin_view == 'confirm' ?
                    <Button
                        title='LETS GO!'
                        style={{ flex:3, opacity:go_valid ? 1: 0.5}}
                        disabled={!go_valid}
                        backgroundColor={Colors.utility.success}
                        title_color={Colors.shades.white}
                        padding={15}
                        onPress={() => handleSetPin()}
                    />
                : auth_required ?
                <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                    {onClose ?
                    <Button
                        style={{ flex:1, marginRight:5 }}
                        title='CLOSE'
                        title_color={Colors.utility.error}
                        backgroundColor='transparent'
                        onPress={() => onClose()}
                    />
                    :<></>}
                    <Button
                        title='SUBMIT'
                        style={{ flex:3, opacity:pin_valid ? 1: 0.5}}
                        disabled={!pin_valid}
                        backgroundColor={Colors.utility.success}
                        title_color={Colors.shades.white}
                        padding={15}
                        onPress={() => handleAuthPin()}
                    />
                </View>
                : onClose && onNext ?
                <View style={{ flex:1, flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                    <Button
                        title='EXIT SETUP'
                        title_color={Colors.utility.error}
                        backgroundColor='transparent'
                        onPress={() => onClose()}
                    />
                    <TouchableOpacity
                        style={button_styles.wizard_valid}
                        onPress={() => onNext()}
                        //disabled={loading}
                        >
                        <Icons.ChevronIcon size={20} direction='right' color={Colors.brand.midnight}/>
                    </TouchableOpacity>
                </View>
                :<></>
                }
            </View>
        </View>
    )
}

export default WalletSetup