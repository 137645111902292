import React, { useState } from 'react';
import { View, Image, FlatList, TouchableOpacity } from 'react-native';
import type { AthleteProps, BestAvailableOrderProps, EventProps, ExternalPriceProps, FundOrderProps, FundProps, LeagueProps, MarketProps, MatchProps, OrderProps, TournamentProps, TradeProps } from '../../types';
import { view_styles } from '../../constants/styles';
import { Text } from '../../Components';
import ContestMarket from './ContestMarkets';
import { MarketMaketHelpers } from '../api';
import Colors from '../../constants/colors';

type ContestProps = {
    fund:FundProps,
    league?:LeagueProps,
    contest_id:string,
    contest_type:string,
    time_detail:string,
    contest_title:string,
    best_available_orders:BestAvailableOrderProps[],
    latest_trades:TradeProps[],
    external_prices:ExternalPriceProps[],
    backup_prices?:ExternalPriceProps[],
    orders:OrderProps[],
    init_show_detail?:boolean,
    athletes:AthleteProps[],
    fund_orders:FundOrderProps[],
    markets:MarketProps[],
    event?:EventProps,
    tournament?:TournamentProps,
    match?:MatchProps,
    onSetMarket:(orders:OrderProps[], market:MarketProps, prices:ExternalPriceProps[]) => void
    onClearMarket:(order_ids:string[]) => void,
    onOrder:(order:OrderProps, available?:number) => void
}

const Contest = ({ fund, init_show_detail, contest_id, time_detail, event, external_prices, backup_prices, tournament, match, contest_type, league, contest_title, best_available_orders, latest_trades, orders, fund_orders, markets, onSetMarket, onClearMarket, onOrder }:ContestProps) => {
    const [ show_detail, setShowDetail ] = useState(init_show_detail ? true : false);
    const [ viewing_markets, setViewingMarkets ] = useState<MarketProps[]>([])

    const { stake_amt } = MarketMaketHelpers.getOrderStats(orders);
    let my_action_market_ids = [ ...new Set(orders.map(o => o.market_id.toString()).concat(viewing_markets.map(m => m.market_id.toString())))  ]
    const my_action_markets = markets.filter(m => my_action_market_ids.includes(m.market_id.toString()))
    const my_action_event_markets = my_action_markets.filter(m => m.level == 'event');
    //const my_action_player_markets = my_action_markets.filter(m => m.level != 'event');
    const other_markets:any[] = markets.filter(m => !my_action_market_ids.includes(m.market_id.toString()));
    
    
    const hedges = MarketMaketHelpers.getHedgesFromPositions(orders, markets);
    const positive_hedges = hedges.reduce((a,b) => a + b.delayed_cash_no_draw, 0);
    const negative_hedges = hedges.reduce((a,b) => a + b.delayed_cash_draw, 0);
    const net_hedge = positive_hedges - negative_hedges

    const renderEventMarkets = (data:{ item:MarketProps, index:number }) => {
        const market_orders = orders.filter(o => o.market_id == data.item.market_id);
        const market_fund_orders = fund_orders.filter(fo => market_orders.map(o => o.order_id.toString()).includes(fo.order_id.toString()))
        const market_trades = latest_trades.filter(t => t.market_id == data.item.market_id);
        const market_available_orders = best_available_orders.filter(o => o.market_id == data.item.market_id);
        const market_prices = external_prices.filter(p => p.market_id == data.item.market_id);
        let backup_market_prices = backup_prices ? backup_prices.filter(p => p.market_id == data.item.market_id) : []
        const { unique_var_1, default_var_1 } = MarketMaketHelpers.getAvailableVar1s(market_orders, market_available_orders, market_prices, data.item, backup_market_prices);
        return (
            <View style={{ borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                <ContestMarket
                    fund={fund}
                    contest_id={contest_id}
                    contest_type={contest_type}
                    market={data.item}
                    orders={market_orders}
                    fund_orders={market_fund_orders}
                    unique_var_1={unique_var_1}
                    latest_trades={market_trades}
                    best_available_orders={market_available_orders}
                    var_1={default_var_1}
                    external_prices={market_prices}
                    backup_prices={backup_market_prices}
                    event={event}
                    tournament={tournament}
                    match={match}
                    onSetMarket={onSetMarket}
                    onClearMarket={onClearMarket}
                    onOrder={onOrder}
                />
            </View>
        )
    }

    /*
    const renderNonEventMarkets = (data:{item:MarketProps, index: number}) => {
        const side_id_response = MarketMaketHelpers.getAvailableSideIds(orders, best_available_orders, external_prices, data.item);
        side_id_response.unique_side_ids
        return (
            <View>

            </View>
        )
    }
        */

    return (
        <View style={{ ...view_styles.section, margin:5, borderWidth:1, borderColor:Colors.shades.shade600, padding:0 }}>
            <TouchableOpacity style={{ ...view_styles.section_header }} onPress={() => setShowDetail(!show_detail)}>
                <View style={{}}>
                    {league ?
                    <Image
                        source={{ uri: league.league_image }}
                        style={{ height:30, width:30, alignSelf:'center' }}
                        resizeMode='cover'
                    />
                    :<></>}
                </View>
                <View style={{ flex:1, padding:8, justifyContent:'center' }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{contest_title}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='semibold'>{time_detail}</Text>
                </View>

                <View style={{ marginLeft:10, padding:5, backgroundColor:Colors.shades.shade100, borderRadius:4 }}>
                    <Text size={12} color={Colors.brand.midnight} textAlign='center' weight='bold'>{[].length}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} textAlign='center' weight='bold'>Markets</Text>
                </View>
                <View style={{ marginLeft:10, padding:5, backgroundColor:Colors.shades.shade100, borderRadius:4 }}>
                    <Text size={12} color={Colors.brand.midnight} textAlign='center' weight='bold'>${stake_amt.toFixed(2)}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} textAlign='center' weight='bold'>Position</Text>
                </View>
                <View style={{ marginLeft:10, padding:5}}>
                    <Text size={12} color={Colors.brand.midnight} textAlign='center' weight='bold'>${net_hedge.toFixed(2)}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} textAlign='center' weight='bold'>Hedge</Text>
                </View>
            </TouchableOpacity>
            {show_detail ?
            <View>
                <FlatList
                    data={my_action_event_markets}
                    renderItem={renderEventMarkets}
                    keyExtractor={(item) => item.toString()}
                />
                <FlatList
                    data={[]}
                    renderItem={renderEventMarkets}
                    keyExtractor={(item) => item.toString()}
                />
                {other_markets.length > 0 ?
                <View style={{ ...view_styles.section_footer }}>
                    {other_markets.map(m => {
                        return (
                            <TouchableOpacity onPress={() => setViewingMarkets(viewing_markets.filter(vm => vm.market_id != m.market_id).concat(m))}>
                                <Text>{m.type == 'Stat' ? m.stat_label : m.type}</Text>
                            </TouchableOpacity>
                        )
                    })}
                </View>
                :<></>}
            </View>
            :<></>}
        </View>
    )
}

export default Contest