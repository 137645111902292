import React from 'react';
import { TouchableOpacity, FlatList, View, Image, ScrollView } from "react-native"
import type { BracketProps, LeagueProps } from '../../types';
import Colors from '../../constants/colors';
import { Button, Icons, Text } from '../../Components';
import moment from 'moment-mini';

type BracketSelectorProps = {
    visible: boolean,
    brackets: BracketProps[],
    leagues:LeagueProps[],
    onSelectBracket:(bracket:BracketProps) => void,
    onClose:() => void
}

const BracketSelector = ({ visible, leagues, brackets, onSelectBracket, onClose }:BracketSelectorProps) => {

    const unique_league_ids = [ ...new Set(brackets.map(b => b.league_id)) ]


    const renderBrackets = (data: { item:BracketProps, index:number }) => {
        const started = moment().isAfter(moment(data.item.scheduled_datetime)) ? true : false;
        return (
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomColor:Colors.shades.shade600, borderBottomWidth:1 }} onPress={() => onSelectBracket(data.item)}>
                <View style={{ flex:1 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{data.item.bracket_name}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate} weight='regular'>{started?'In Progress':`Starts at ${moment(data.item.scheduled_datetime).format('MMM DD YYYY hh:mm a')}`}</Text>
                </View>
                <Button
                    title='VIEW'
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    disabled
                    onPress={() => console.log('')}
                />
            </TouchableOpacity>
        )
    }

    const renderLeagues = ( data : { item:string, index:number }) => {
        const league = leagues.find(l => l.league_id == data.item);
        const league_brackets = brackets.filter(b => b.league_id == data.item);
        if(!league){ return <></> }
        return (
            <View>
                <View style={{ flexDirection:'row', alignItems:'center', padding:10, backgroundColor:Colors.shades.shade100 }}>
                    <View>
                        <Image
                            source={{ uri: league.league_image }}
                            style={{ height:30, width:30, borderRadius:4 }}
                            resizeMode='cover'
                        />
                    </View>
                    <View style={{ flex:1, marginLeft:10 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{league.league_name}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate}>{league_brackets.length} Brackets</Text>
                    </View>
                </View>
                <FlatList
                    data={league_brackets}
                    renderItem={renderBrackets}
                    keyExtractor={(item) => item.bracket_id.toString()}
                />
            </View>
        )
    }

    if(!visible){ return <></> }
    return (
        <View style={{ flex:1 }}>
             <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade100 }} onPress={() => onClose()}>
                <Icons.ChevronIcon direction='left' size={12} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:20 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='bold'>Active Brackets</Text>
                    <Text style={{ marginTop:4 }} size={14} color={Colors.brand.slate} weight='regular'>Select a bracket below to make or edit picks</Text>
                </View>
            </TouchableOpacity>
            <ScrollView style={{ flex:1 }}>
                <FlatList
                    data={unique_league_ids}
                    renderItem={renderLeagues}
                    keyExtractor={(item) => item.toString()}
                />
            </ScrollView>
        </View>
    )
}

export default BracketSelector