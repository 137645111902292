import React, { useEffect, useRef, useState } from 'react';
import { View, FlatList, TouchableOpacity, ActivityIndicator, Image } from 'react-native';
import { view_styles } from '../../../constants/styles';
import Colors from '../../../constants/colors';
import type { AthleteProps, BestAvailableOrderProps, EventOrderStatProps, LeagueProps, MarketProps, MatchProps, OrderProps, TeamProps, TournamentProps, TradeProps } from '../../../types';
import { Button, Icons, LinearDiagnal, Text, TextInput } from '../../../Components';
import { MarketComponentApi, MarketComponentHelpers } from '../../api';
import AthleteTournamentMarket from '../AthleteTournamentMarket';
import TeamTournamentMarket from '../TeamTournamentMarket';
import MatchMarket from '../MatchMarket';
import moment from 'moment-mini';
import { TournamentMarketHelpers } from './api';
import { useIsInViewport } from '../../../Components/ViewportObserver';


type TournamentMarketProps = {
    tournament:TournamentProps,
    league?:LeagueProps,
    markets:MarketProps[],
    hide_liquidity?:boolean,
    width?:number,
    hide_match_liquidity?:boolean,
    show_grades?:boolean,
    hide_participant_liquidity?:boolean,
    activate_loading?:boolean,
    wrap_markets?:boolean,
    latest_trades:TradeProps[],
    best_available_orders:BestAvailableOrderProps[],
    match_available_orders:BestAvailableOrderProps[],
    event_order_stats:EventOrderStatProps[],
    match_order_stats:EventOrderStatProps[],
    match_latest_trades:TradeProps[],
    market_selector_location?:string,
    onShare?:(t:TournamentProps) => void,
    onView:(data:{ event_id:string, event_type:string, market_id:string, side_type?:string, side_id?:string }) => void,
    onTradeLongPress:(trade:TradeProps) => void,
    onActivate?:(event_id:string, event_type:string) => void,
    onViewAdditionalMarkets: (event_id:string, event_type:string) => void,
    onOrder:(order:OrderProps) => void
}

const TournamentMarket = ({ tournament, wrap_markets, width, market_selector_location, hide_match_liquidity, show_grades, hide_participant_liquidity, league, markets, latest_trades, hide_liquidity, best_available_orders, event_order_stats, match_available_orders, match_latest_trades, match_order_stats, activate_loading, onView, onTradeLongPress, onViewAdditionalMarkets, onOrder, onActivate, onShare }:TournamentMarketProps) => {
    const [ search_value, setSearchValue ] = useState<string | undefined>(undefined);
    const [ tournament_data, setTournamentData ] = useState<{
        loaded:boolean,
        active_type:'futures'|'matches',
        loading:boolean,
        athletes:AthleteProps[],
        teams:TeamProps[],
        matches:MatchProps[],
        match_trades:TradeProps[],
        active_market?:MarketProps
    }>({
        loading:false,
        loaded:false,
        active_type: 'futures',
        athletes:[],
        match_trades:[],
        teams:[],
        matches:[]
    });
    const { loaded, athletes, active_type, loading, matches, teams, active_market, match_trades } = tournament_data;
    const [ show_markets, setShowMarkets ] = useState(false);

    const ref = useRef(null);
    const is_in_viewport = useIsInViewport(ref)

    let supported_markets = markets.filter(m => tournament.supported_markets.find(sm => sm.market_id == m.market_id));
    let all_supported_markets = supported_markets
    if(active_type == 'futures'){
        supported_markets = supported_markets.filter(sm => sm.event_type == 'tournament');
    } else {
        supported_markets = supported_markets.filter(sm => sm.event_type == 'match');
    }

    const { show_athletes, show_teams, show_matches, show_futures } = TournamentMarketHelpers.visibleMarketTypes(all_supported_markets);
    const { liquidity, open_order_count } = MarketComponentHelpers.getLiqudity(event_order_stats.concat(match_order_stats.filter(m => matches.find(nm => nm.match_id == m.event_id))));
    const sorted_athletes = TournamentMarketHelpers.sortAthletes(event_order_stats, latest_trades, athletes, active_market, search_value);
    const sorted_teams = TournamentMarketHelpers.sortTeams(event_order_stats, latest_trades, teams, active_market, search_value);
    let sorted_matches = TournamentMarketHelpers.sortMatches(match_order_stats, matches, active_market, search_value, athletes, teams);
    if(!wrap_markets){ sorted_matches = sorted_matches.filter(m => !['closed','cancelled','postponed','suspended'].includes(m.status)) }
    const market_width = TournamentMarketHelpers.getMarketWrapWidth(50, width, wrap_markets)
    useEffect(() => {
        if(!is_in_viewport || loaded || loading){ return }
        MarketComponentApi.setEnvironment();
        getTournamentData(tournament)
    },[is_in_viewport, tournament.tournament_id]);

    const getTournamentData = async(t:TournamentProps) => {
        if(loaded || loading){ return }
        setTournamentData({ ...tournament_data, loading:true });
        let t_ids:string[] = [];
        let a_ids:string[] = [];
        let mtchs:MatchProps[] = await MarketComponentApi.getMatchesByTournament(t.tournament_id);
        let trades = await MarketComponentApi.getLatestTradesByEvents('match', mtchs.map(m => m.match_id));
        mtchs.map(m => {
            if(t.participant_type == 'athlete'){ a_ids = a_ids.concat(m.participants) }
            if(t.participant_type == 'team'){ t_ids = t_ids.concat(m.participant_type) }
        })
        if(t.participant_type == 'team'){ t_ids = t_ids.concat(t.participants) }
        if(t.participant_type == 'athlete'){ a_ids = a_ids.concat(t.participants) }

        let sm = tournament.supported_markets[0]
        let market = markets.find(m => m.market_id == sm?.market_id);
        let type = 'futures'
        if(market?.event_type == 'match'){ type = 'matches' }
        const aths = await MarketComponentApi.getAthletesByIds(a_ids);
        const tms = await MarketComponentApi.getTeamsByIds(t_ids);
        setTournamentData({
            ...tournament_data,
            loaded: true,
            loading:false,
            active_type: type as 'futures' | 'matches',
            athletes: aths,
            match_trades: trades,
            teams: tms,
            matches: mtchs,
            active_market: market
        })
    }

    const renderMarkets = (data: { item:MarketProps, index:number }) => {
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => {
                setTournamentData({ ...tournament_data, active_market: data.item });
                setShowMarkets(false);
            }}>
                <Text size={12} color={Colors.brand.midnight} weight='semibold'>{data.item.type == 'Stat' ? data.item.stat_label : data.item.type}</Text>
            </TouchableOpacity>
        )
    }

    const renderMatches = (data: { item:MatchProps, index:number }) => {
        if(!active_market){ return <></> }
        let home_athlete = athletes.find(a => a.athlete_id == data.item.participants[1]);
        let away_athlete = athletes.find(a => a.athlete_id == data.item.participants[0]);
        let home_team = teams.find(a => a.team_id == data.item.participants[1]);
        let away_team = teams.find(a => a.team_id == data.item.participants[0]);

        let m_available_orders = match_available_orders.filter(ba => ba.event_id == data.item.match_id && ba.event_type == 'match');
        let m_trades = match_latest_trades.filter(t => t.event_id == data.item.match_id && t.event_type == 'match');
        if(m_trades.length == 0){
            m_trades = match_trades.filter(t => t.event_id == data.item.match_id && t.event_type == 'match');
        }
        let m_order_stats = match_order_stats.filter(os => os.event_id == data.item.match_id && os.event_type == 'match');

        return (
            <View style={{ }}>
                <MatchMarket
                    match={data.item}
                    league={league}
                    away_athlete={away_athlete}
                    home_athlete={home_athlete}
                    away_team={away_team}
                    show_grades={show_grades}
                    width={wrap_markets ? market_width : undefined}
                    home_team={home_team}
                    hide_liquidity={hide_match_liquidity ? true : false}
                    market={active_market}
                    latest_trades={m_trades}
                    best_available_orders={m_available_orders}
                    event_order_stats={m_order_stats}
                    onOrder={onOrder}
                    onTradeLongPress={onTradeLongPress}
                    onView={onView}
                    default_price_view='best_available'

                />
            </View>
        )
    }

    const renderTeams = (data:{ item:TeamProps, index:number }) => {
        if(!active_market){ return <></> }
        let team_available_orders = best_available_orders.filter(ba => ba.side_type == 'team' && ba.side_id == data.item.team_id);
        let team_trades = latest_trades.filter(t => t.side_type == 'team' && t.side_id == data.item.team_id);
        let team_order_stats = event_order_stats.filter(os => os.side_type == 'team' && os.side_id == data.item.team_id);
        return (
            <View>
                <TeamTournamentMarket
                    team={data.item}
                    event_type='tournament'
                    onTradeLongPress={onTradeLongPress}
                    onView={onView}
                    width={wrap_markets ? market_width : undefined}
                    show_grades={show_grades}
                    hide_liquidity={hide_participant_liquidity ? true : false}
                    tournament={tournament}
                    event_order_stats={team_order_stats}
                    best_available_orders={team_available_orders}
                    latest_trades={team_trades}
                    default_price_view='best_available'
                    market={active_market}
                    onOrder={onOrder}
                />
            </View>
        )
    }

    const renderAthletes = (data:{ item:AthleteProps, index:number }) => {
        if(!active_market){ return <></> }
        let team_available_orders = best_available_orders.filter(ba => ba.side_type == 'athlete' && ba.side_id == data.item.athlete_id);
        let team_trades = latest_trades.filter(t => t.side_type == 'athlete' && t.side_id == data.item.athlete_id);
        let team_order_stats = event_order_stats.filter(os => os.side_type == 'athlete' && os.side_id == data.item.athlete_id);
        return (
            <View>
                <AthleteTournamentMarket
                    athlete={data.item}
                    event_type='tournament'
                    onTradeLongPress={onTradeLongPress}
                    onView={onView}
                    show_grades={show_grades}
                    hide_liquidity={hide_participant_liquidity ? true : false}
                    tournament={tournament}
                    width={wrap_markets ? market_width : undefined}
                    event_order_stats={team_order_stats}
                    best_available_orders={team_available_orders}
                    latest_trades={team_trades}
                    default_price_view='best_available'
                    market={active_market}
                    onOrder={onOrder}
                />
            </View>
        )
    }

    return (
        <View ref={ref} style={{ ...view_styles.section, borderWidth:1, borderColor:Colors.shades.shade600 }}>
            <View style={{ ...view_styles.section_header }}>
                {league ?
                <Image
                    source={{ uri: league.league_image }}
                    style={{ height:40, width: 40, marginRight:10 }}
                    resizeMode='cover'
                />
                :<></>}
                <View style={{ flex:1 }}>
                    <Text theme='header_2'>{tournament.tournament_name}</Text>
                    <Text style={{ marginTop:3 }} theme='body'>{moment(tournament.scheduled_datetime).format('MMM DD YYYY')}</Text>
                </View>
                {onActivate && tournament.status != 'closed' ?
                <Button
                    title={'Refresh'}
                    style={{ opacity: activate_loading ? 0.5 : 1, marginLeft:5 }}
                    disabled={activate_loading}
                    loading={activate_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onActivate(tournament.tournament_id, 'tournament')}
                />
                :<></>}
                {onShare ?
                <TouchableOpacity style={{ marginLeft:10 }} onPress={() => onShare(tournament)}>
                    <Icons.ShareIcon size={14} color={Colors.brand.electric} />
                </TouchableOpacity>
                :<></>}
            </View>
            {loading || !loaded ?
            <View style={{ padding:20 }}>
                <ActivityIndicator size={'large'} color={Colors.brand.midnight} />
            </View>
            :
            <View>
            {market_selector_location == 'top_right' ?
            <View style={{ ...view_styles.body_row, paddingLeft:10, paddingRight:10, zIndex:1, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flexDirection:'row', alignItems:'center', flex:1, marginRight:10, paddingLeft:10, backgroundColor:Colors.shades.white, borderRadius:22 }}>
                    <Icons.SearchIcon color={Colors.brand.electric} size={18} />
                    <TextInput
                        style={{ flex:1, marginLeft:10, borderRadius:0, borderTopRightRadius:22, borderBottomRightRadius:22 }}
                        placeholder='Search athletes, teams, matches ...'
                        placeholderTextColor={Colors.accents.accent200}
                        onChangeText={(text) => setSearchValue(text)}
                    />
                </View>
                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', backgroundColor:Colors.shades.white, borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:4, padding:10, ...view_styles.float }} onPress={() => setShowMarkets(!show_markets)}>
                    <Text style={{ marginRight:10 }} size={14} color={Colors.brand.midnight}>{active_market?.type == 'Stat' ? active_market.stat_label : active_market?.type}</Text>
                    <Icons.ChevronIcon color={Colors.brand.midnight} size={14} />
                </TouchableOpacity>
                {show_markets ?
                <View style={{ ...view_styles.section, position:'absolute', top:5, right:5, minWidth:175 }}>
                    <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header_2'>Select Market</Text>
                        </View>
                        <Button
                            title='X'
                            title_weight='regular'
                            borderRadius={100}
                            backgroundColor={Colors.shades.white}
                            padding={5}
                            onPress={() => setShowMarkets(false)}
                        />
                    </View>
                    <View style={{ ...view_styles.section_body, padding:10 }}>
                        <FlatList
                            data={supported_markets}
                            renderItem={renderMarkets}
                            keyExtractor={(item) => item.market_id.toString()}
                        />
                    </View>
                </View>
                :<></>}
            </View>
            :<></>}
            <View style={{ flexDirection:'row', alignItems:'center' }}>
                <View style={{flex:1}}>
                    <LinearDiagnal label_size={14} label={active_type == 'futures' ? 'FUTURES' : 'MATCHES'} left_color={Colors.utility.warning} right_color={Colors.utility.error}/>
                </View>
                {((active_type == 'matches' && show_futures) || (active_type == 'futures' && show_matches)) ?
                <Button
                    title={`SEE ${active_type == 'futures' ? 'MATCHES' : 'FUTURES'}`}
                    padding={15}
                    title_color={Colors.brand.electric}
                    onPress={() => {
                        let new_active_market = active_market
                        if(active_type == 'futures'){
                            //need to find the match markets
                            new_active_market = all_supported_markets.find(sm => sm.event_type == 'match');
                        } else {
                            new_active_market = all_supported_markets.find(sm => sm.event_type == 'tournament');
                        }
                        setTournamentData({ ...tournament_data, active_type: active_type == 'futures' ? 'matches': 'futures', active_market: new_active_market })
                    }}
                />
                :<></>}
            </View>
            {active_type == 'futures' && show_teams && teams.length > 0 ?
            <View>
                {wrap_markets ?
                <View style={{ flexDirection:'row', flexWrap:'wrap', justifyContent:'center' }}>
                    {sorted_teams.map((t,i) => {
                        return renderTeams({ item:t, index:i })
                    })}
                </View>
                :
                <FlatList
                    data={sorted_teams}
                    renderItem={renderTeams}
                    horizontal
                    keyExtractor={(item) => item.team_id.toString()}
                />
                }
            </View>
            :<></>}
            {active_type == 'futures' && show_athletes && athletes.length > 0 ?
            <View>
                {wrap_markets ?
                <View style={{ flexDirection:'row', flexWrap:'wrap', justifyContent:'center' }}>
                    {sorted_athletes.map((a,i) => {
                        return renderAthletes({ item:a, index:i })
                    })}
                </View>
                :
                <FlatList
                    data={sorted_athletes}
                    renderItem={renderAthletes}
                    horizontal
                    keyExtractor={(item) => item.athlete_id.toString()}
                />
                }
            </View>
            :active_type == 'matches' && show_matches && matches.length > 0 ?
            <View>
                {wrap_markets ?
                <View style={{ flexDirection:'row', flexWrap:'wrap', justifyContent:'center' }}>
                    {sorted_matches.map((m,i) => {
                        return renderMatches({ item:m, index:i })
                    })}
                </View>
                :
                <FlatList
                    data={sorted_matches}
                    renderItem={renderMatches}
                    keyExtractor={(item) => item.match_id.toString()}
                    horizontal
                />
                }
            </View>
            :<></>}
            </View>
            }
            
            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, flexDirection:'row', alignItems:'center', flexWrap:'wrap', borderBottomLeftRadius:8, borderBottomRightRadius:8 }}>
                {!hide_liquidity ?
                <TouchableOpacity style={{ flex:1, flexDirection:'row', minWidth:115 }} onPress={() => {
                    if(!active_market){ return }
                    onViewAdditionalMarkets(tournament.tournament_id, 'tournament');
                }}>
                    <View style={{ backgroundColor:Colors.shades.white, height:30, width:30, borderRadius:100, justifyContent:'center', alignItems:'center', ...view_styles.float }}>
                        <Icons.LiquidityIcon color={Colors.brand.midnight} size={30}/>
                    </View>
                    <View style={{ marginLeft:5 }}>
                        <Text theme='body_2'>Tournament Liquidity</Text>
                        <Text size={12} color={Colors.brand.electric} weight='semibold'>${liquidity.toFixed()} {open_order_count} Orders</Text>
                    </View>
                </TouchableOpacity>
                :<View style={{flex:1}} />}
                {!market_selector_location ?
                <View>
                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', backgroundColor:Colors.shades.white, borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:4, padding:10, ...view_styles.float }} onPress={() => setShowMarkets(!show_markets)}>
                    <Text style={{ marginRight:10 }} size={14} color={Colors.brand.midnight}>{active_market?.type == 'Stat' ? active_market.stat_label : active_market?.type}</Text>
                    <Icons.ChevronIcon color={Colors.brand.midnight} size={14} />
                </TouchableOpacity>
                {show_markets ?
                <View style={{ ...view_styles.section, position:'absolute', bottom:5, right:5, minWidth:175 }}>
                    <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header_2'>Select Market</Text>
                        </View>
                        <Button
                            title='X'
                            title_weight='regular'
                            borderRadius={100}
                            backgroundColor={Colors.shades.white}
                            padding={5}
                            onPress={() => setShowMarkets(false)}
                        />
                    </View>
                    <View style={{ ...view_styles.section_body, padding:10 }}>
                        <FlatList
                            data={supported_markets}
                            renderItem={renderMarkets}
                            keyExtractor={(item) => item.market_id.toString()}
                        />
                    </View>
                </View>
                :<></>}
                </View>
                :<></>}
            </View>
        </View>
    )
}

export default TournamentMarket