import React from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import type { PodcastProps } from '../types';
import { view_styles } from '../constants/styles';
import { Text } from '../Components';
import { SocialPodcastHelpers } from './api';
import moment from 'moment-mini';

type PodcastCardType = {
    podcast:PodcastProps,
    width:number,
    onSelectPodcast:(podcast:PodcastProps) => void
}
const PodcastCard = ({ podcast, width, onSelectPodcast }:PodcastCardType) => {
    return (
        <TouchableOpacity style={{ ...view_styles.section, width, flex:1 }} onPress={() => onSelectPodcast(podcast)}>
            <Image
                source={{ uri: SocialPodcastHelpers.getPodcastImage(podcast) }}
                style={{ width:width, height:width*0.666, borderTopRightRadius:8, borderTopLeftRadius:8 }}
                resizeMode='cover'
            />
            <View style={{ padding:10, flex:1 }}>
                <Text theme='header_2'>{podcast.title}</Text>
            </View>
            <View style={{ ...view_styles.section_footer }}>
                <Text theme='body'>{moment(podcast.last_update_datetime).fromNow()}</Text>
            </View>
        </TouchableOpacity>
    )
}
export default PodcastCard