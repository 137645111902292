import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Icons, Text, TextInput } from "../../Components";
import Colors from "../../constants/colors";
import type { AuthExecutionRequestProps, AuthStrategyIdentifierProps, AuthStrategyProps, AuthenticatedProps } from '../api/types';
import { AuthenticateApi, AuthenticateHelpers } from '../api';
import { Linking, TouchableOpacity, View , FlatList, Image } from 'react-native';
import type { CompanyProps, PlayerReferralProps, PromoProps, PublicPlayerProps } from '../../types';
import { view_styles } from '../../constants/styles';
import { ProfileHelpers } from '../../ProfileManager/api';
import AuthStrategyIdentifier from './AuthStrategyIdentifier';

type StrategyFormProps = {
    visible:boolean,
    auth_strategy_id:string,
    strategy: { auth_strategy?:AuthStrategyProps, auth_strategy_identifiers:AuthStrategyIdentifierProps[] }
    width:number,
    company_id?:string,
    distinct_id:string,
    source:string,
    theme?:'full'|'mini',
    company?:CompanyProps,
    opt_in_url?:string,
    show_referral?:boolean,
    referral_code?:string,
    onLogin:() => void,
    onAuthenticated: (auth_response:AuthenticatedProps) => void
}

const mini_steps = ['intro', 'execution', 'verify']

const StrategyForm = ({ visible, company, theme, strategy, auth_strategy_id, company_id, distinct_id, source, show_referral, referral_code, width, opt_in_url, onAuthenticated, onLogin }:StrategyFormProps) => {

    const [ action_loading, setActionLoading ] = useState(false);
    const [ active_view, setActiveView ] = useState('authenticate');
    const [ code_details, setCodeDetails ] = useState<{
        player_referral?:PlayerReferralProps,
        promo?:PromoProps,
        referrer?:PublicPlayerProps
    }>({})
    const [ active_mini_index, setActiveMiniIndex ] = useState(0);
    const [ opted_in, setOptedIn ] = useState(true);
    const [ active_index, setActiveIndex ] = useState<number>(0)
    const [ token, setToken ] = useState('');
    const [ verify_error, setVerifyError ] = useState<string|undefined>();
    const [ verify_attempt_allowed, setVeryAttemptAllowed ] = useState(true);
    const [ authenticated, setAuthenticated ] = useState(false);
    const [ auth_execution_request, setAuthExecutionRequest ] = useState<AuthExecutionRequestProps>({
        auth_strategy_id: auth_strategy_id,
        company_id: company_id,
        device_id:distinct_id,
        source,
        referral_code,
        attributes: []
    })
    const [ verify_state, setVerifyState ] = useState<{
        new_player?:boolean,
        verification_session_id:string,
        session_expire_datetime:any,
        auth_strategy_identifier_id:string
    } | undefined>(undefined)
    

    const { auth_strategy, auth_strategy_identifiers } = strategy;
    const verify_strategy_identifier = auth_strategy_identifiers[0]//.find(asi => asi.auth_strategy_identifier_id == verify_state?.auth_strategy_identifier_id);

    useEffect(() => {
        if(!auth_strategy){ return }
        setUpWizard(auth_strategy.auth_strategy_id)
    },[auth_strategy, referral_code])

    const setUpWizard = async(auth_strategy_id:string) => {
        //Get auth strategy from server
        if(referral_code && show_referral){
            const code_response = await AuthenticateApi.getReferralByCode(referral_code)
            setCodeDetails(code_response)
        } else {
            setCodeDetails({})
        }
        let unique_attributes = auth_strategy_identifiers.map(asi => {
            //if(!asi.auth_identifier){ return }
            return { attribute: asi.auth_identifier?.attribute ?? '', value: '' }
        })
        setAuthExecutionRequest({
            auth_strategy_id,
            company_id,
            device_id:distinct_id,
            source,
            referral_code,
            attributes: unique_attributes
        })

       
    }

    const verifyAuthStrategyIdentifier = async() => {
        if(authenticated){ return }
        if(!verify_state){ return  }
        if(action_loading){ return } //No mashy mashy
        if(!verify_attempt_allowed){ return } 
        setVeryAttemptAllowed(false);
        setActionLoading(true);
        const { result, player_id, access_token, refresh_token, expire_datetime } = await AuthenticateApi.verifyAuthStrategyIdentifier(verify_state.auth_strategy_identifier_id, verify_state.verification_session_id, token, distinct_id);
        if(result == 'fail' || !access_token || !refresh_token || !player_id || !auth_strategy_id){
            setVerifyError(`Invalid code / password.  Please try again after 10 seconds`)
            setTimeout(() => {
                setVeryAttemptAllowed(true);
                setVerifyError(undefined)
                setActionLoading(false);
            }, 10000);
        } else {
            setAuthenticated(true);
            setTimeout(() => {
                onAuthenticated({
                    new_player:verify_state.new_player,
                    player_id,
                    access_token,
                    refresh_token,
                    expire_datetime
                });
                setActionLoading(false);
            }, 1500);
        }
    }

    const executeAuthStrategy = async() => {
        setActionLoading(true);
        const { result, verification_session_id, session_expire_datetime, auth_strategy_identifier_id, new_player } = await AuthenticateApi.executeAuthStrategy(auth_execution_request);
        if(result == 'fail'){
            setActionLoading(false);
            return
        }
        setVerifyState({
            verification_session_id,
            session_expire_datetime,
            auth_strategy_identifier_id,
            new_player
        })
        setActiveView('verify');
        setActionLoading(false);
    }

    const renderAuthStrategyIdentifiers = (data: { item:AuthStrategyIdentifierProps, index: number }) => {
        if(!data.item.auth_identifier){ return <></> }
        const auth_request = auth_execution_request.attributes.find(a => a.attribute == data.item.auth_identifier?.attribute)
        if(!auth_request){ return <></> }

        return (
            <AuthStrategyIdentifier
                auth_strategy_identifier={data.item}
                auth_request={auth_request}
                onChangeIdentifier={(text, is_valid, pretty_value) => {
                    setAuthExecutionRequest({ 
                        ...auth_execution_request, 
                        attributes: auth_execution_request.attributes.filter(a => a.attribute != auth_request.attribute).concat({ 
                            attribute: auth_request.attribute, 
                            value: text,
                            pretty_value,
                            is_valid
                        }) 
                    })
                }}
            />
        )
    }

    const strategy_valid = AuthenticateHelpers.isStrategyValid(auth_execution_request)
    if(!visible || !auth_strategy){ return <></> }

    if(theme == 'mini'){
        const active_mini_step = mini_steps[active_mini_index];
        const active_strategy_identifier = auth_strategy_identifiers[active_index]
        const auth_request = auth_execution_request.attributes.find(a => a.attribute == active_strategy_identifier?.auth_identifier?.attribute)
        const last_index = auth_strategy_identifiers.length - 1 == active_index ? true : false
        if(!auth_request || !active_mini_step){ return <></> }
        return (
            <View style={{ width }}>
                {active_mini_step == 'intro' ?
                <View style={{ flex:1 }}>
                    <View style={{ flexDirection:'row' }}>
                        {company ?
                        <View style={{ padding:10 }}>
                            <Image
                                source={{ uri: company.company_image?.url }}
                                style={{ height:60, width:60 }}
                                resizeMode='cover'
                            />
                        </View>
                        :<></>}
                        <View style={{ flex:1, padding:10 }}>
                            <Text size={16} color={Colors.brand.midnight} weight='bold'>Welcome!</Text>
                            <Text style={{ marginTop:4 }} size={14} color={Colors.brand.midnight}>Please complete the required steps to participate with {company?.company_name ?? 'Bettoredge'}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection:'row', padding:10, paddingTop:0 }}>
                        {opt_in_url ?
                        <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                            <Checkbox 
                                size={20}
                                checked={opted_in}
                                onSelect={(checked) => setOptedIn(checked)}
                            />
                            <Text style={{ marginLeft:15 }} size={14} color={Colors.brand.midnight}>By continuing, you agree to the terms and conditions outlined
                            <TouchableOpacity onPress={() => Linking.openURL(opt_in_url) }>
                                <Text size={14} color={Colors.brand.electric} weight='bold'> here.</Text>
                            </TouchableOpacity>
                            </Text>
                        </View>
                        :<></>}
                        <Button
                            title={`Next`}
                            style={{  opacity:opt_in_url && !opted_in ? 0.5:1 }}
                            disabled={opt_in_url && !opted_in ? true : false}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.brand.electric}
                            //borderRadius={8}
                            padding={15}
                            onPress={() => {
                                setActiveMiniIndex(active_mini_index + 1)
                            }}
                        />
                    </View>
                </View>
                :active_mini_step == 'execution' ?
                <View>

                {active_view == 'authenticate' && active_strategy_identifier?
                <View style={{ maxWidth: 600 }}>
                    <AuthStrategyIdentifier
                        auth_strategy_identifier={active_strategy_identifier}
                        auth_request={auth_request}
                        auto_focus={true}
                        onChangeIdentifier={(text, is_valid, pretty_value) => {
                            setAuthExecutionRequest({ 
                                ...auth_execution_request, 
                                attributes: auth_execution_request.attributes.filter(a => a.attribute != auth_request.attribute).concat({ 
                                    attribute: auth_request.attribute, 
                                    value: text,
                                    pretty_value,
                                    is_valid
                                }) 
                            })
                        }}
                    />
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10, paddingTop:0 }}>
                    {active_index != 0 ?
                    <Button
                        title={`Back`}
                        style={{ flex:1, marginRight:5}}
                        title_color={Colors.brand.electric}
                        borderWidth={1}
                        borderColor={Colors.brand.electric}
                        backgroundColor={Colors.shades.white}
                        //borderRadius={8}
                        padding={15}
                        onPress={() => {
                            setActiveIndex(active_index - 1)
                        }}
                    />
                    :<></>}
                    {last_index ?
                    <Button
                        title={`Let's Go!`}
                        loading={action_loading}

                        style={{ flex:3, opacity:strategy_valid && opted_in && !action_loading?1:0.5 }}
                        disabled={action_loading || !strategy_valid || !opted_in}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.brand.electric}
                        //borderRadius={8}
                        padding={15}
                        onPress={() => {
                            executeAuthStrategy()
                        }}
                    />
                    :

                        <Button
                            title={`Next`}
                            style={{ flex:3, opacity:auth_request?.is_valid?1:0.5 }}
                            disabled={!auth_request?.is_valid}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.brand.electric}
                            //borderRadius={8}
                            padding={15}
                            onPress={() => {
                                setActiveIndex(active_index + 1)
                            }}
                        />
                    }
                    </View>
                </View>
                : active_view == 'verify' && verify_strategy_identifier ?
                <View nativeID="sign_up" style={{ flex:1, maxWidth:600 }}>
                <View style={{ padding:10 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='semibold'>{verify_strategy_identifier.verify_label??'Enter Verification Code'}</Text>
                    <View style={{ padding:10 }}>
                        <TextInput
                            style={{ textAlign:'center', fontFamily:'barlow-bold', fontSize:14, borderWidth:1, borderColor:Colors.shades.shade600 }}
                            value={token}
                            keyboardType='default'
                            placeholder='Enter Code'
                            autoFocus
                            textContentType={verify_strategy_identifier.secure_text_entry ? undefined : 'oneTimeCode'}
                            secureTextEntry={verify_strategy_identifier.secure_text_entry}
                            placeholderTextColor={Colors.brand.slate}
                            onChangeText={(text) => {
                                setToken(text);
                            }}
                        />
                    </View>
                    {verify_error ?
                    <Text style={{ marginTop:10 }} size={14} color={Colors.utility.warning}>{verify_error}</Text>
                    :<></>}
                </View>
                <View nativeID="action_row" style={{ flexDirection:'row', padding:10, paddingTop:0 }}>
                <Button
                        title={'BACK'}
                        loading={action_loading}
                        style={{ flex:1, marginRight:5 }}
                        title_color={Colors.brand.electric}
                        borderColor={Colors.brand.electric}
                        borderWidth={1}
                        padding={15}
                        onPress={() => {
                            setActiveView('authenticate');
                            setVerifyState(undefined)
                        }}
                    />
                    <Button
                        title={verify_attempt_allowed && !action_loading? 'Verify': 'Retry in 10 seconds'}
                        loading={action_loading}
                        style={{ flex:3, opacity:token&&verify_attempt_allowed?1:0.5 }}
                        disabled={!token || action_loading || !verify_attempt_allowed}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.utility.success}
                        //borderRadius={8}
                        padding={15}
                        onPress={() => {
                            verifyAuthStrategyIdentifier()
                        }}
                    />
                </View>
            </View>
               :<></> }

                </View>
                :<></>}
                
                
            </View>
        )
    }

    return (
        <View style={{ width:width, flex:1 }}>
            {active_view == 'authenticate' ?
            <View nativeID="sign_up" style={{ flex:1 }}>
                {show_referral && code_details.player_referral && code_details.promo ?
                <View style={{ margin:15, flexDirection:'row', backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float }}>
                    <View style={{ padding:10, justifyContent:'center' }}>
                        <Icons.GiftIcon size={40} color={Colors.incentive.gold} />
                    </View>
                    <View style={{flex:1, backgroundColor:Colors.incentive.gold_faded, padding:10 }}>
                        <Text style={{ paddingBottom:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} size={16} color={Colors.brand.midnight} weight='bold'>@{code_details.referrer?.username} has a gift for you</Text>
                        <Text style={{ paddingTop:5 }} size={12} color={Colors.brand.midnight} weight='semibold'>{ProfileHelpers.getReferralDescription(code_details.promo)}</Text>
                    </View>
                </View>
                :<></>}
                <View style={{ flex:1, padding:10 }}>
                    <View style={{ padding:10 }}>
                        <FlatList
                            data={auth_strategy_identifiers.sort((a,b) => a.priority - b.priority)}
                            renderItem={renderAuthStrategyIdentifiers}
                            keyExtractor={(item) => item.auth_strategy_identifier_id.toString()}
                        />
                    </View>
                </View>
                <View nativeID="action_row" style={{ padding:10, paddingLeft:20, paddingRight:20 }}>
                    <Button
                        title={`Let's Go!`}
                        loading={action_loading}
                        style={{ opacity:strategy_valid && opted_in?1:0.5 }}
                        disabled={action_loading || !strategy_valid || !opted_in}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.brand.electric}
                        //borderRadius={8}
                        padding={15}
                        onPress={() => {
                            executeAuthStrategy()
                        }}
                    />
                </View>
                {auth_strategy?.allow_login ?
                <View style={{ padding:10, backgroundColor:Colors.shades.shade100 }}>
                    <Button
                        title='Already Signed Up? Login Here'
                        title_color={Colors.brand.electric}
                        backgroundColor='transparent'
                        onPress={() => onLogin()}
                    />
                </View>
                :<></>}
                {active_view == 'authenticate' && opt_in_url ?
                <View style={{ flexDirection:'row', alignItems:'center', padding:20 }}>
                    <Checkbox 
                        size={25}
                        checked={opted_in}
                        onSelect={(checked) => setOptedIn(checked)}
                    />
                    <Text style={{ marginLeft:15 }} size={14} color={Colors.brand.midnight}>By continuing, you agree to the terms and conditions outlined
                    <TouchableOpacity onPress={() => Linking.openURL(opt_in_url) }>
                        <Text size={14} color={Colors.brand.electric} weight='bold'> here.</Text>
                    </TouchableOpacity>
                    </Text>
                </View>
                :<></>}
            </View>
            
            : active_view == 'verify' && verify_strategy_identifier ?
            
            <View nativeID="sign_up" style={{ flex:1 }}>
                <View style={{ flex:1, justifyContent:'center', alignItems:'center', padding:20 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='semibold' textAlign="center">{verify_strategy_identifier.verify_label??'Enter Verification Code'}</Text>
                    <View style={{ padding:10 }}>
                        <TextInput
                            style={{ textAlign:'center', fontFamily:'barlow-bold', fontSize:14, borderWidth:1, borderColor:Colors.shades.shade600 }}
                            value={token}
                            keyboardType='default'
                            autoFocus
                            textContentType={verify_strategy_identifier.secure_text_entry ? undefined : 'oneTimeCode'}
                            secureTextEntry={verify_strategy_identifier.secure_text_entry}
                            placeholderTextColor={Colors.brand.slate}
                            onChangeText={(text) => {
                                setToken(text);
                            }}
                        />
                    </View>
                    {verify_error ?
                    <Text style={{ marginTop:10 }} size={14} color={Colors.utility.warning}>{verify_error}</Text>
                    :<></>}
                </View>
                <View nativeID="action_row" style={{ flexDirection:'row', padding:10, paddingLeft:20, paddingRight:20 }}>
                <Button
                        title={'BACK'}
                        loading={action_loading}
                        style={{ flex:1, marginRight:5 }}
                        //disabled={!token || action_loading || !verify_attempt_allowed}
                        title_color={Colors.brand.electric}
                        borderColor={Colors.brand.electric}
                        borderWidth={1}
                        //backgroundColor={Colors.utility.success}
                        //borderRadius={8}
                        padding={15}
                        onPress={() => {
                            setActiveView('authenticate');
                            setVerifyState(undefined)
                        }}
                    />
                    <Button
                        title={verify_attempt_allowed && !action_loading? 'Verify': 'Retry in 10 seconds'}
                        loading={action_loading}
                        style={{ flex:3, opacity:token&&verify_attempt_allowed?1:0.5 }}
                        disabled={!token || action_loading || !verify_attempt_allowed}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.utility.success}
                        //borderRadius={8}
                        padding={15}
                        onPress={() => {
                            verifyAuthStrategyIdentifier()
                        }}
                    />
                </View>
            </View>
            
            :<></>}
        </View>
    )
}

export default StrategyForm