import React, { useEffect, useState } from 'react';
import { CompetitionApi, CompetitionHelpers, CompetitionMatchApi, CompetitionPlayerApi, PlayerPicksApi } from './api';
import { View, ScrollView, Image, TouchableOpacity, ActivityIndicator } from 'react-native';
import { view_styles } from '../constants/styles';
import { Button, Icons, Spring, Text } from '../Components';
import Colors from '../constants/colors';
import type { AthleteProps, BEEventProps, CodeRequestProps, CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionPayoutTypeProps, CompetitionPlayerProps, CompetitionProps, CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionTypeProps, EventProps, LeagueProps, MarketProps, MatchProps, MyPlayerProps, PlayerBalanceProps, PlayerPickProps, PlayerReferralProps, PromoProps, PublicPlayerProps, RewardOptionProps, TeamProps, TournamentProps } from '../types';
import CompetitionInfoCard from './components/CompetitionInfoCard';
import CompetitionLeaderboard from './components/CompetitionLeaderboard';
import WagerPickForm from './components/WagerPickForm';
import CompetitionPlay from './components/CompetitionPlay';
import EnterCompetitionPrompt from './components/EnterCompetitionPrompt';
import moment from 'moment-mini';
import LeaderPicks from './components/LeaderPicks';
import NavOutPrompt from './components/NavOutPrompt';
import SeasonCard from './components/SeasonCard';

type CompetitionModuleProps = {
    player_id?:string,
    competition_id:string,
    onClose:() => void,
    onEvent?:(event:BEEventProps) => void,
    onManageCompetition:(competition_id:string) => void,
    onRequestAuthentication:(auth_strategy_id?:string) => void,
    onShareCompetition:(competition:CompetitionProps) => void,
    onViewCompetitionSeason:(cs:CompetitionSeasonProps) => void
}

const CompetitionModule = ({ competition_id, player_id, onManageCompetition, onRequestAuthentication, onShareCompetition, onClose, onViewCompetitionSeason }:CompetitionModuleProps) => {
    const [ viewing_leader, setViewingLeader ] = useState<PublicPlayerProps|undefined>();
    const [ show_full_leaderboard, setShowFullLeaderboard ] = useState(false);
    const [ module_size, setModuleSize ] = useState({ width:0, height:0 });
    const [ show_enter_prompt, setShowEnterPrompt ] = useState(false);
    const [ draft_player_pick, setDraftPlayerPick ] = useState<{draft_pick?:PlayerPickProps, event_title?:string, event_start?:string}>({});

    const [ my_data, setMyData ] = useState<{
        player?:MyPlayerProps,
        loading:boolean,
        player_balance?:PlayerBalanceProps,
        player_picks:PlayerPickProps[]
    }>({
        loading:false,
        player_picks: []
    })
    const { loading, player_picks, player, player_balance } = my_data;

    const [ pick_loading, setPickLoading ] = useState<undefined|string>(undefined)


    const [ competition_data, setCompetitionData ] = useState<{
        loaded:boolean,
        competition?:CompetitionProps,
        tournaments:TournamentProps[],
        matches:MatchProps[],
        events:EventProps[],
        athletes:AthleteProps[],
        teams:TeamProps[],
        markets:MarketProps[],
        leagues:LeagueProps[],
        competition_payout_types:CompetitionPayoutTypeProps[],
        competition_players:CompetitionPlayerProps[],
        competition_matches:CompetitionMatchProps[],
        competition_match_markets:CompetitionMatchMarketProps[],
        competition_types:CompetitionTypeProps[],
        competition_result_types:CompetitionResultTypeProps[],
        competition_type?:CompetitionTypeProps,
        competition_result_type?:CompetitionResultTypeProps,
        competition_season?:CompetitionSeasonProps,

    }>({
        loaded:false,
        tournaments:[], 
        events:[],
        athletes: [],
        teams: [],
        matches: [],
        leagues: [],
        markets:[],
        competition_types:[],
        competition_result_types:[],
        competition_payout_types:[],
        competition_matches:[],
        competition_players:[],
        competition_match_markets:[],
    })

    const [ nav_out, setNavOut ] = useState<{
        visible:boolean,
        image_url?:string,
        title:string
        code_details?: { 
            promo?:PromoProps, 
            code_request?: CodeRequestProps, 
            player_referral?:PlayerReferralProps, 
            referrer?:PublicPlayerProps, 
            reward_option?:RewardOptionProps 
        },
        body:string,
        max_width?:number
        hide_accept?:boolean,
        accept_title?:string,
        cancel_title?:string,
    }>({
        visible: false,
        title: '',
        body: ''
    })

    const { 
        competition,
        loaded,
        leagues,
        markets,
        events,
        competition_season,
        athletes,
        teams,
        tournaments,
        matches,
        competition_players,
        competition_result_type,
        competition_payout_types,
        competition_type,
        competition_matches,
        competition_match_markets 
    } = competition_data;

    const authenticated_competition_player = competition_players.find(cp => cp.player_id == player_id && cp.status == 'active');
    const in_progress = moment().isAfter(moment(competition?.scheduled_datetime)) ? true : false;
    const can_leave = competition?.entered && moment().isBefore(moment(competition.scheduled_datetime)) && player_picks.length == 0 && !loading
    const can_join = !authenticated_competition_player && moment().isBefore(moment(competition?.scheduled_datetime)) && !loading ? true : false

    useEffect(() => {
        CompetitionApi.setEnvironment();
        if(!loaded){ getCompetitionFromServer(competition_id) }
        if(player_id){ getPlayerData(competition_id, player_id, 0) }
    },[competition_id, player_id])


    useEffect(() => {
        if(!competition){ return }
        if(player_picks.length > 0 && player_picks.length == competition?.max_pick_count){
            if(nav_out.visible){ return }
            setNavOut({
                visible: true,
                title: 'Your picks are all set!',
                image_url: competition.image?.url,
                body: 'Way to go! All your picks are locked.  Feel free to edit your picks up until the start time of each contest.',
                cancel_title:'CLOSE'
            })
        }
    },[competition?.max_pick_count, player_picks.length])



    const getPlayerData = async(competition_id:string, player_id:string, offset:number) => {
        setMyData({ ...my_data, loading:true })
        const pps = await PlayerPicksApi.getPlayerPicksByCompetition(competition_id, player_id, offset)
        const player_balance = await CompetitionApi.getMyBalance();
        const me = await CompetitionApi.getMyDetails();
        setMyData({ player:me, player_balance, player_picks:pps, loading:false })
    }

    //This will snag all the data we need to launch the competition
    const getCompetitionFromServer = async(competition_id:string) => {
        const { competition_types, competition_result_types, competition_payout_types } = await CompetitionApi.getCompetitionOptions();
        const ls = await CompetitionApi.getLeagues()
        const cms_response = await CompetitionApi.getCompetitionMatchesByCompetitionId(competition_id)

        //Lets get the additional data needed!
        let athletes: AthleteProps[] = []
        let teams: TeamProps[] = []
        let events:EventProps[] = []
        let tournaments:TournamentProps[] = []
        let matches: MatchProps[] = []
        const markets = await CompetitionApi.getMarkets();
        let athlete_ids = cms_response.competition_matches.filter(cm => cm.side_type_override == 'athlete' && cm.side_id_override).map(cm => cm.side_id_override ?? '');
        let team_ids = cms_response.competition_matches.filter(cm => cm.side_type_override == 'team' && cm.side_id_override).map(cm => cm.side_id_override ?? '');
        const event_ids = cms_response.competition_matches.filter(cm => cm.event_type == 'team').map(cm => cm.event_id)
        event_ids.map(eid => {
            let side_id_cm = cms_response.competition_matches.find(cm => cm.event_type == 'team' && cm.event_id == eid && cm.side_id_override)
            if(side_id_cm){ 
                //expanded_markets.push(eid) 
            }
        })
        //setExpandedMarkets(expanded_markets)
        const match_ids = cms_response.competition_matches.filter(cm => cm.event_type == 'match').map(cm => cm.event_id)
        if(event_ids.length > 0){ events = await CompetitionMatchApi.getEventsByEventIds(event_ids) }
        if(match_ids.length > 0){ matches = await CompetitionMatchApi.getMatchesByMatchIds(match_ids) }
        if(matches.length > 0){
            let tournament_ids = matches.map(m => m.tournament_id)
            tournaments = await CompetitionMatchApi.getTournamentsByTournamentIds(tournament_ids)
            let new_athlete_ids:string[] = []
            let new_team_ids: string[] = []
            matches.filter(m => m.participant_type == 'athlete').map(m => m.participants.map(p => new_athlete_ids.push(p)))
            matches.filter(m => m.participant_type == 'team').map(m => m.participants.map(p => new_team_ids.push(p)))
            athlete_ids = athlete_ids.concat(new_athlete_ids);
            team_ids = team_ids.concat(new_team_ids)
        }
        if(athlete_ids.length > 0){ athletes = await CompetitionMatchApi.getAthletesByIds(athlete_ids) }
        if(team_ids.length > 0){ teams = await CompetitionMatchApi.getTeamsByIds(team_ids) }
        const cps = await CompetitionPlayerApi.getCompetitionPlayersByCompetition(competition_id, 0, 'active')
        const c = await CompetitionApi.getCompetitionById(competition_id);
        let comp_season:CompetitionSeasonProps | undefined = undefined
        if(c.competition_season_id){ comp_season = await CompetitionApi.getSeasonById(c.competition_season_id) }

        setCompetitionData({
            loaded:true,
            competition: c,
            competition_season: comp_season,
            competition_matches: cms_response.competition_matches,
            competition_match_markets: cms_response.competition_match_markets,
            events,
            athletes,
            teams,
            tournaments,
            leagues: ls,
            competition_types:competition_types,
            competition_result_types:competition_result_types,
            matches,
            competition_payout_types,
            competition_players: cps,
            competition_type: competition_types.find(ct => ct.competition_type_id == c.competition_type_id),
            competition_result_type:competition_result_types.find(crt => crt.competition_result_type_id == c.competition_result_type_id),
            markets
        })
        //setLoading(false);
    }

    const handleEnterCompetition = async(promo_balance?:boolean) => {
        if(!player_id){ return onRequestAuthentication(competition?.auth_strategy_id) }
        if(loading){ return }
        setShowEnterPrompt(false);
        setMyData({ ...my_data, loading:true });
        
        const response = await CompetitionPlayerApi.joinCompetition({ 
            ...CompetitionHelpers.getCompetitionPlayer(competition_id), 
            promo_balance 
        })
        setCompetitionData({
            ...competition_data,
            competition: response.competition,
            competition_players: competition_players.filter(cp => cp.competition_player_id != response.competition_player.competition_player_id).concat(response.competition_player)
        })
        setMyData({ ...my_data, loading:false });
    }

    const handleLeaveCompetition = async() => {
        if(!player_id || !authenticated_competition_player){ return }
        setMyData({ ...my_data, loading:true })
        const response = await CompetitionPlayerApi.leaveCompetition(authenticated_competition_player)
        setCompetitionData({
            ...competition_data,
            competition: response.competition,
            competition_players: competition_players.filter(cp => cp.competition_player_id != response.competition_player.competition_player_id).concat(response.competition_player)
        })
        setMyData({ ...my_data, loading:false })
    }


    const handleWagerSubmit = async(pp:PlayerPickProps) => {
        setPickLoading(pp.competition_match_id)
        const new_pp = await PlayerPicksApi.createPlayerPick(pp)
        setMyData({
            ...my_data,
            player_picks: player_picks.filter(ps => ps.player_pick_id != new_pp.player_pick.player_pick_id).concat(new_pp.player_pick)
        })
        setDraftPlayerPick({})
        setPickLoading(undefined)
    }

    const handlePick = async(pp:PlayerPickProps, event_title:string, event_start:string) => {
        if(pick_loading){ return }//No mashy mashy
        if(!competition){ return }
        if(!player_id){
            return onRequestAuthentication()
        }
        if(!authenticated_competition_player){ return setShowEnterPrompt(true); }
        if(!competition_type){ return alert('Unable to process pick') }
        
        setPickLoading(pp.competition_match_id)
        //If the pick needs to be removed
        if(pp.player_pick_id && pp.player_pick_id != ''){
            const rem_pp = await PlayerPicksApi.removePlayerPick(pp.player_pick_id)
            setMyData({
                ...my_data,
                player_picks: player_picks.filter(ps => ps.player_pick_id != rem_pp.player_pick.player_pick_id)
            })
            setPickLoading(undefined)
            return
        }
 
        //If this is a new pick
        //First check to see if they already have one on the market / event combo
        let existing_pick = player_picks.find(p => p.competition_match_id == pp.competition_match_id && p.market_id == pp.market_id)
        if(existing_pick){
            setPickLoading(undefined)
            return alert('Can only pick one side of each market.  Remove the other side before selecting this one.')
        }

        if(competition_type.type == 'pick'){
            if(player_picks.length >= parseFloat(competition.max_pick_count as string)){ 
                setPickLoading(undefined)
                return alert('Already reached maximum picks') 

            }
            const new_pp = await PlayerPicksApi.createPlayerPick(pp)
            setMyData({
                ...my_data,
                player_picks: player_picks.filter(ps => ps.player_pick_id != new_pp.player_pick.player_pick_id).concat(new_pp.player_pick)
            })
            setPickLoading(undefined)
        }
        if(competition_type.type == 'wager'){
            setPickLoading(undefined)
            setDraftPlayerPick({ draft_pick: pp, event_start, event_title })
        }
    }

    if(!competition || !competition_type || !competition_result_type){
        return (
            <View style={{ flex:1 }}>
                <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
            </View>
        )
    }

    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1 }} onLayout={(ev) => {
                const { width, height } = ev.nativeEvent.layout
                setModuleSize({ width, height })
            }}>
                <View style={{ backgroundColor:Colors.shades.white }}>
                    <View nativeID="competition_header" style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View>
                            <Image
                                source={{ uri: competition.image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1649737862/be_logo_jte2ux.webp' }}
                                style={{ height:50, width:50, borderRadius:4 }}
                                resizeMode="cover"
                            />
                        </View>
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text theme='header'>{competition.competition_name}</Text>
                            <Text style={{ marginTop:4 }} theme='header_2>'>{competition.competition_description}</Text>
                        </View>
                        <TouchableOpacity style={{ paddingLeft:10 }} onPress={() => onShareCompetition(competition)}>
                            <Icons.ShareIcon color={Colors.brand.midnight} size={14} />
                        </TouchableOpacity>
                    </View>
                    {competition_season ?
                    <SeasonCard
                        competition_season={competition_season}
                        authenticated_competition_player={authenticated_competition_player}
                        onViewSeason={(cs) => onViewCompetitionSeason(cs)}
                    />
                    :
                    <View style={{ flexDirection:'row', padding:10, backgroundColor:Colors.shades.shade100 }}>
                        <View style={{ flex:1 }}>
                            <Text size={12} color={Colors.brand.midnight} weight='regular'>{competition.status == 'closed' ? 'COMPLETED' : in_progress ? 'IN PROGRESS' : 'JOIN UNTIL'}</Text>
                            {in_progress ?
                            <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='bold'>{moment(competition.scheduled_datetime).format('MMM DD hh:mm a')}</Text>
                            :
                            <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='bold'>{moment(competition.scheduled_datetime).format('MMM DD hh:mm a')}</Text>
                            }
                        </View>
                            {!authenticated_competition_player ?
                            <Button
                                style={{ flex:1, opacity: can_join ? 1: 0.5 }}
                                disabled={!can_join}
                                loading={loading}
                                title={`JOIN`}
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.utility.success}
                                onPress={() => { 
                                    if(competition.competition_season_id){ return alert('Please select the season to join!') }
                                    setShowEnterPrompt(true)
                                }}
                            />
                            : competition.status != 'closed' ?
                            <Button
                                style={{ flex:1, opacity: can_leave ? 1: 0.5 }}
                                title='LEAVE'
                                loading={loading}
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.utility.error}
                                onPress={() => {
                                    if(competition.competition_season_id){ return alert('You can only leave via the season') }
                                    if(!can_leave){ return alert('Please remove all picks and try again') }
                                    handleLeaveCompetition()
                                }}
                            />
                            :<></>}
                    </View>
                    }
                </View>
                <CompetitionInfoCard
                    competition={competition}
                    competition_type={competition_type}
                    competition_result_type={competition_result_type}
                    competition_payouts={competition_payout_types.filter(cpt => cpt.competition_result_type_id == competition_result_type?.competition_result_type_id)}
                />
                <CompetitionLeaderboard 
                    competition={competition}
                    width={module_size.width}
                    competition_type={competition_type}
                    height={module_size.height}
                    onLeaderSelect={(player) => setViewingLeader(player)}
                    view_mode="short"
                    onSeeFull={() => setShowFullLeaderboard(true)}
                    viewer_id={player_id}
                />  
                <CompetitionPlay
                    competition={competition}
                    competition_type={competition_type}
                    competition_matches={competition_matches}
                    competition_match_markets={competition_match_markets}
                    leagues={leagues}
                    events={events}
                    pick_loading={pick_loading}
                    athletes={athletes}
                    markets={markets}
                    tournaments={tournaments}
                    matches={matches}
                    player_picks={player_picks}
                    player_id={player_id}
                    competition_players={competition_players}
                    authenticated_competition_player={authenticated_competition_player}
                    height={module_size.height}
                    width={module_size.width}
                    onPick={handlePick}
                    onRequestAuthentication={() => onRequestAuthentication(competition.auth_strategy_id)}
                />
  
                <View style={{ ...view_styles.section }}></View>
            </ScrollView>
            {player?.player_id == competition.admin_id ?
            <View style={{ ...view_styles.body_row, padding:0, paddingRight:10, backgroundColor:Colors.shades.shade100 }}>
                <Image
                    source={{ uri: player.profile_pic }}
                    style={{ height:55, width:55 }}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:10, padding:5 }}>
                    <Text theme='header_2'>ADMIN ACTIONS</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>Press manage to make changes to this competition</Text>
                </View>
                <Button
                    title='MANAGE'
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    onPress={() => onManageCompetition(competition_id)}
                />
            </View>
            :<></>}
            {show_full_leaderboard ?
            <View style={{ position:'absolute', bottom:0, left:0, right:0, top:0 }}>
                <Spring
                    slide='vertical'
                    to={0}
                    from={500}
                >
                    <CompetitionLeaderboard 
                        competition={competition}
                        competition_type={competition_type}
                        height={module_size.height}
                        width={module_size.width}
                        onLeaderSelect={(player) => setViewingLeader(player)}
                        view_mode='full'
                        onClose={() => setShowFullLeaderboard(false)}
                        onSeeFull={() => setShowFullLeaderboard(!show_full_leaderboard)}
                        viewer_id={player_id}
                    />    
                </Spring>
            </View>
            :<></>}
            {nav_out.visible ?
            <View style={{ position:'absolute', justifyContent:'center', alignItems:'center', bottom:0, left:0, right:0, top:0, backgroundColor:Colors.shades.black_faded }}>
                <Spring
                    slide='vertical'
                    to={0}
                    from={500}
                >
                    <NavOutPrompt
                        title={nav_out.title}
                        body={nav_out.body}
                        image_url={nav_out.image_url}
                        hide_accept={true}
                        code_details={{}}
                        max_width={module_size.width * 0.75}
                        cancel_title={nav_out.cancel_title}
                        onCancel={() => setNavOut({ ...nav_out, visible:false })}
                        onAccept={() => setNavOut({ ...nav_out, visible:false })}
                    />
                </Spring>
            </View>
            :<></>}
            {draft_player_pick?.draft_pick ?
            <View 
                style={{ position:'absolute', top:0, right:0, left:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end', alignItems:'center' }}>
            <Spring
                slide='vertical'
                to={0}
                from={200}
            >
                <WagerPickForm
                    event_start={draft_player_pick.event_start ?? ''}
                    event_title={draft_player_pick.event_title ?? ''}
                    width={module_size.width}
                    balance={authenticated_competition_player?.wager_based_balance ?? 0}
                    loading={pick_loading}
                    player_pick={draft_player_pick.draft_pick}
                    onCancel={() => setDraftPlayerPick({})}
                    onSubmit={(pp) => handleWagerSubmit(pp)}
                />
                </Spring>
            </View>
            :<></>}
            {show_enter_prompt ?
            <View 
                style={{ position:'absolute', top:0, right:0, left:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end', alignItems:'center' }}>
            <Spring
                slide='vertical'
                to={0}
                from={200}
            >
                <EnterCompetitionPrompt
                    competition={competition}
                    competition_result_type={competition_result_type}
                    competition_type={competition_type}
                    player_balance={player_balance}
                    player={player}
                    width={module_size.width}
                    onCancel={() => setShowEnterPrompt(false)}
                    onEnter={(promo_balance) => handleEnterCompetition(promo_balance)}
                    onVerify={() => console.log('Handle verify')}
                />
                </Spring>
            </View>
            :<></>}
            {viewing_leader ?
            <View 
                style={{ position:'absolute', top:0, right:0, left:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end', alignItems:'center' }}>
            <Spring
                slide='vertical'
                to={0}
                from={200}
            >
                <LeaderPicks
                    competition={competition}
                    leader={viewing_leader}
                    competition_matches={competition_matches}
                    competition_match_markets={competition_match_markets}
                    markets={markets}
                    events={events}
                    tournaments={tournaments}
                    matches={matches}
                    athletes={athletes}
                    teams={teams}
                    competition_type={competition_type}
                    height={module_size.height}
                    width={module_size.width}
                    onClose={() => setViewingLeader(undefined)}
                />
                </Spring>
            </View>
            :<></>}
            {competition.status == 'inactive' ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                <View style={{ ...view_styles.section, width: '70%', backgroundColor:Colors.shades.white }}>
                    <View style={{ ...view_styles.section_header, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.shade100 }}>
                        <Text theme='header'>Competition Has Been Deleted</Text>
                    </View>
                    <View style={{ ...view_styles.section_body, justifyContent:'center', alignItems:'center' }}>
                        <Image
                            source={{ uri: competition.image?.url }}
                            style={{ height:75, width: 75 }}
                            resizeMode='cover'
                        />
                        <Text theme='header_2'>{competition.competition_name}</Text>
                    </View>
                    <View style={{ ...view_styles.section_footer }}>
                        <Button
                            title='CLOSE'
                            style={{ flex:1 }}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.utility.error}
                            padding={15}
                            onPress={() => onClose()}
                        />
                    </View>
                </View>
            </View>
            :<></>}
            {competition.status == 'paused' ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                <View style={{ ...view_styles.section, width: '70%', backgroundColor:Colors.shades.white }}>
                    <View style={{ ...view_styles.section_header, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.shade100 }}>
                        <Text theme='header'>Competition Is Paused</Text>
                    </View>
                    <View style={{ ...view_styles.section_body, justifyContent:'center', alignItems:'center' }}>
                        <Image
                            source={{ uri: competition.image?.url }}
                            style={{ height:75, width: 75 }}
                            resizeMode='cover'
                        />
                        <Text theme='header_2'>{competition.competition_name}</Text>
                    </View>
                    <View style={{ ...view_styles.section_footer }}>
                        <Button
                            title='CLOSE'
                            style={{ flex:1 }}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.utility.error}
                            padding={15}
                            onPress={() => onClose()}
                        />
                    </View>
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default CompetitionModule