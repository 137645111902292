import React, { useState } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { view_styles } from "../../constants/styles"
import type { AthleteProps, BestAvailableOrderProps, EventProps, ExternalPriceProps, FundOrderProps, FundProps, LeagueProps, MarketProps, MatchProps, OrderProps, SortedContestProps, TournamentProps, TradeProps } from "../../types"
import { Text } from '../../Components';
import Contest from './Contest';
import { MarketMaketHelpers } from '../api';
import Colors from '../../constants/colors';
import FundActions from './FundActions';

type DashboardProps = {
    loaded:boolean,
    fund?:FundProps,
    leagues:LeagueProps[],
    events:EventProps[],
    markets:MarketProps[],
    tournaments:TournamentProps[],
    matches:MatchProps[],
    best_available_orders:BestAvailableOrderProps[],
    trades:TradeProps[],
    prices:ExternalPriceProps[],
    orders:OrderProps[],
    fund_orders:FundOrderProps[],
    athletes:AthleteProps[],
    external_name?:string,
    onSetMarket:(orders:OrderProps[], market:MarketProps, prices:ExternalPriceProps[]) => void,
    onClearMarket:(order_ids:string[]) => void,
    onWorkCapital:(amount:number) => void,
    onLayoffCapital: (amount: number) => void,
    onDepositFund: (amount:number) => void,
    onOrder:(order:OrderProps, available?:number) => void
}


const Dashboard = ({ fund, loaded, external_name, athletes, prices, leagues, events, tournaments, matches, markets, orders, fund_orders, best_available_orders, trades, onOrder, onSetMarket, onClearMarket, onWorkCapital, onLayoffCapital, onDepositFund }:DashboardProps) => {
    const [ dash_size, setDashSize ] = useState({ height:400, width:400 });
    console.log(dash_size)
    const contests = MarketMaketHelpers.sortContests(events, tournaments, matches, markets)
    const renderContests = (data:{item:SortedContestProps, index:number}) => {
        if(!fund){ return <></> }
        const league = leagues.find(l => l.league_id == data.item.league_id)
        const contest_orders = orders.filter(o => o.event_type == data.item.contest_type && o.event_id == data.item.id);
        const contest_fund_orders = fund_orders.filter(fo => contest_orders.map(o => o.order_id.toString()).includes(fo.order_id.toString()));
        const contest_trades = trades.filter(t => t.event_type == data.item.contest_type && t.event_id == data.item.id);
        const contest_ba = best_available_orders.filter(o => o.event_type == data.item.contest_type && o.event_id == data.item.id);
        
        const event = events.find(e => e.event_id == data.item.id && data.item.contest_type == 'team');
        const tournament = tournaments.find(t => t.tournament_id == data.item.id && data.item.contest_type == 'tournament');
        const match = matches.find(m => m.match_id == data.item.id && data.item.contest_type == 'match');
        const contest_prices = prices.filter(p => p.event_type == data.item.contest_type && p.event_id == data.item.id);
        const book_prices = contest_prices.filter(p => p.external_name == external_name)
        return (
            <View style={{ flexGrow:1, flexShrink:1 }} key={`${data.item.contest_type}${data.item.id}`}>
                <Contest
                    contest_id={data.item.id}
                    contest_type={data.item.contest_type}
                    time_detail={data.item.time_detail}
                    league={league}
                    event={event}
                    athletes={athletes}
                    tournament={tournament}
                    match={match}
                    init_show_detail={data.index == 0 ? true : false}
                    fund={fund}
                    contest_title={data.item.title}
                    latest_trades={contest_trades}
                    best_available_orders={contest_ba}
                    external_prices={book_prices}
                    backup_prices={contest_prices}
                    orders={contest_orders}
                    fund_orders={contest_fund_orders}
                    onSetMarket={onSetMarket}
                    onClearMarket={onClearMarket}
                    onOrder={onOrder}
                    markets={data.item.markets}
                />
            </View>
        )
    }

    return (
        <View style={{ ...view_styles.section, flexGrow:1, flexShrink:1 }} onLayout={(ev) => {
            const { width, height } = ev.nativeEvent.layout;
            setDashSize({ width, height })
        }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Dashboard</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Select contests and markets to see activity and market options</Text>
                </View>
            </View>
            {fund?
            <FundActions
                fund={fund}
                onDepositFund={onDepositFund}
                onLayoffCapital={onLayoffCapital}
                onWorkCapital={onWorkCapital}
                onReleaseFunds={() => console.log('WITDHRAW!!!')}
            />
            :<></>}
            <View style={{ flexDirection:'row', flexWrap:'wrap' }}>
                {contests.sort((a,b) => a.scheduled_datetime.unix() - b.scheduled_datetime.unix()).map((c,i) => {
                    return renderContests({ item: c, index:i })
                })}
            </View>
            {!loaded ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                <View style={{ ...view_styles.section, minWidth:200, minHeight:200, justifyContent:'center', alignItems:'center' }}>
                    <ActivityIndicator style={{ alignSelf:'center'}} size='large' color={Colors.brand.midnight} />
                    <Text style={{ marginTop:10 }} theme='header_2'>Loading Your Data...</Text>
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default Dashboard