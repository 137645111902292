import React, { useEffect } from 'react';
import { View } from 'react-native';

type EmbedPreviewProps = {
    widget_element: any,
    script_source:string,
    div_id:string
}

const EmbedPreview = ({ div_id, widget_element, script_source } : EmbedPreviewProps) => {

    useEffect(() => {
        initiateScript(script_source);
    },[widget_element])
    
    const initiateScript = (source:string) => {
        const scriptRunTime = document.createElement('script');
        const scriptMain = document.createElement('script');
        scriptRunTime.src = 'https://widgets.bettoredge.com/widget.common.js'
        scriptMain.src = source
        const container = document.getElementById(div_id);
        (container as HTMLElement).appendChild(scriptRunTime);
        (container as HTMLElement).appendChild(scriptMain);
    }
    

    return (
        <View>
            {widget_element}
        </View>
    )

}

export default EmbedPreview