import TeamEventMarket from './components/TeamEventMarket';
import AthleteMarket from './components/AthleteMarket';
import EventMarket from './components/EventMarket';
import AthleteTournamentMarket from './components/AthleteTournamentMarket';
import TeamEventList from './components/TeamEventList';
import TournamentMarket from './components/TournamentMarket';
import MatchMarket from './components/MatchMarket';
import TournamentList from './components/TournamentList';

export default {
    TeamEventMarket,
    AthleteMarket,
    EventMarket,
    AthleteTournamentMarket,
    TeamEventList,
    TournamentMarket,
    MatchMarket,
    TournamentList
}