import React from 'react';
import { FlatList, TouchableOpacity, View } from "react-native";
import type { BracketGroupProps, BracketRoundProps } from '../../../types';
import { Button, Checkbox, Text } from '../../../Components';
import Colors from '../../../constants/colors';

type BracketNavigatorProps = {
    bracket_rounds:BracketRoundProps[],
    bracket_groups:BracketGroupProps[],
    selected_round?:BracketRoundProps,
    selected_group?:BracketGroupProps,
    onSelectRound:(r:BracketRoundProps) => void,
    onSelectGroup:(g:BracketGroupProps) => void,
    onClose: () => void
}

const BracketNavigator = ({ bracket_rounds, bracket_groups, selected_round, selected_group, onSelectGroup, onSelectRound, onClose }:BracketNavigatorProps) => {

    const renderRounds = (data : { item:BracketRoundProps, index:number }) => {
        const selected = selected_round?.bracket_round_id == data.item.bracket_round_id ? true : false
        return (
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', width:120, padding:10 }} onPress={() => onSelectRound(data.item)}>
                <Checkbox size={20} fill_style={{ borderRadius:100 }} style={{ borderRadius:100, marginRight:5 }} checked={selected} disabled onSelect={() => console.log('')}/>
                <Text size={14} weight={selected?'bold':'regular'} color={Colors.brand.midnight}>{data.item.round_name}</Text>
            </TouchableOpacity>
        )
    }

    const renderGroups = (data : { item:BracketGroupProps, index:number }) => {
        const selected = selected_group?.bracket_group_id == data.item.bracket_group_id ? true : false
        return (
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', width:120, padding:10 }} onPress={() => onSelectGroup(data.item)}>
                <Checkbox size={20} fill_style={{ borderRadius:100 }} style={{ borderRadius:100, marginRight:5 }} checked={selected} disabled onSelect={() => console.log('')}/>
                <Text size={14} weight={selected?'bold':'regular'} color={Colors.brand.midnight}>{data.item.group_name}</Text>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ minWidth:200 }}>
            <View style={{ padding:10, flexDirection:'row', alignItems:'center', backgroundColor:Colors.shades.shade100, borderTopRightRadius:8, borderTopLeftRadius:8 }}>
                <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>Bracket Filters</Text>
                <Button
                    title='X'
                    title_color={Colors.utility.error}
                    padding={10}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
            </View>
            <View style={{ borderBottomWidth:1, borderColor:Colors.shades.shade600, padding:10 }}>
                <View style={{ padding:5 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>Groups</Text>
                </View>
                <FlatList
                    data={bracket_groups}
                    renderItem={renderGroups}
                    keyExtractor={(item) => item.bracket_group_id.toString()}
                />
            </View>
            <View style={{ borderBottomWidth:1, borderColor:Colors.shades.shade600, padding:10 }}>
                <View style={{ padding:5 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>Rounds</Text>
                </View>
                <FlatList
                    data={bracket_rounds}
                    renderItem={renderRounds}
                    keyExtractor={(item) => item.bracket_round_id.toString()}
                />
            </View>
        </View>
    )
}

export default BracketNavigator