import React, { useState } from "react"
import type { MyPlayerProps, PlayerDepositLimitProps, PlayerReferralProps, PlayerWithdrawLimitProps, PromoProps } from '../../types';
import { View, TouchableOpacity, ScrollView } from 'react-native';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { WalletHelpers } from '../api';

type BEWalletProps = {
    player:MyPlayerProps,
    deposit_limit:PlayerDepositLimitProps,
    withdraw_limit:PlayerWithdrawLimitProps,
    my_referral: {
        player_referral?:PlayerReferralProps,
        promo?:PromoProps
    }
    onPremium?:() => void,
    onClose:() => void
}

const WalletSettings = ({ player, my_referral, deposit_limit, withdraw_limit, onPremium, onClose }:BEWalletProps) => {
    const [ active_tab, setActiveTab ] = useState('deposit');

    //const promo = useSelector((state:RootState) => state.wallet.promo, (left, right) => left?.promo_id == right?.promo_id);
    //const player_referral = useSelector((state:RootState) => state.wallet.player_referral, (left, right) => left?.last_update_datetime == right?.last_update_datetime);

    const { player_referral, promo } = my_referral
    //const tabs = ['referral','deposit','withdraw']



    if(!player){ return <></> }
    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>WALLET SETTINGS</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>Below are your overall wallet settings & configurations. These are set based on account type and past transaction history.</Text>
            </View>
            <View style={{ flexDirection: 'row', paddingLeft:10, paddingRight:10 }}>
                <TouchableOpacity 
                    style={{ flex:1, borderWidth:1,borderColor:Colors.shades.shade600, padding:10, borderTopRightRadius:8, borderTopLeftRadius:8, backgroundColor:active_tab=='deposit'?Colors.brand.midnight:undefined }}
                    onPress={() => setActiveTab('deposit')}
                    >
                    <Text weight={active_tab == 'deposit'?'bold':'regular'} textAlign="center" size={14} color={active_tab=='deposit'?Colors.shades.white:Colors.brand.midnight}>Deposit</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={{ flex:1, borderWidth:1, borderColor:Colors.shades.shade600, padding:10, borderTopRightRadius:8, borderTopLeftRadius:8, backgroundColor:active_tab=='withdraw'?Colors.brand.midnight:undefined }}
                    onPress={() => setActiveTab('withdraw')}
                    >
                    <Text weight={active_tab == 'withdraw'?'bold':'regular'} textAlign="center" size={14} color={active_tab=='withdraw'?Colors.shades.white:Colors.brand.midnight}>Withdraw</Text>
                </TouchableOpacity>
                <TouchableOpacity 
                    style={{ flex:1, borderWidth:1, borderColor:Colors.shades.shade600, padding:10, borderTopRightRadius:8, borderTopLeftRadius:8, backgroundColor:active_tab=='referral'?Colors.brand.midnight:undefined }}
                    onPress={() => setActiveTab('referral')}
                    >
                    <Text weight={active_tab == 'referral'?'bold':'regular'} textAlign="center" size={14} color={active_tab=='referral'?Colors.shades.white:Colors.brand.midnight}>Referral</Text>
                </TouchableOpacity>
            </View>
            <ScrollView nativeID="settings" style={{ flex:1, marginLeft:10, marginRight:10, borderRightWidth:1, borderLeftWidth:1, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                {active_tab == 'deposit' ?
                <View>
                    {deposit_limit ?
                    <View nativeID="deposit_limit">
                        <View style={{ backgroundColor:Colors.shades.shade100, padding:10 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='semibold'>Deposit Settings</Text>
                            <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>Below are your current deposit limits.  These may change based on historical transaction results.</Text>
                        </View>
                        <View style={{ padding:10 }}>

                        <View style={{ flexDirection:'row', alignItems:'flex-start', padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <View style={{ flex:1 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>ACH Daily Limit</Text>
                                <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>The maximum deposit amount allowed per day from an ACH account</Text>
                            </View>
                            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, marginLeft:10, borderRadius:8 }}>
                                <Text size={14} color={Colors.brand.midnight} weight="bold">${deposit_limit.ach_daily_limit}</Text>
                            </View>
                        </View>
                        <View style={{ marginTop:10, flexDirection:'row', alignItems:'flex-start', padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <View style={{ flex:1 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>Card / Paypal Daily Limit</Text>
                                <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>The maximum deposit amount allowed per day from a card account</Text>
                            </View>
                            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, marginLeft:10, borderRadius:8 }}>
                                <Text size={14} color={Colors.brand.midnight} weight="bold">${deposit_limit.card_daily_limit}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection:'row', alignItems:'flex-start', marginTop:10, padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <View style={{ flex:1 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>Instant Deposit Limit</Text>
                                <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>The total amount of unsettled deposits that will be instantly added to your wallet.  Any deposit over this amount will be held until it settles.</Text>
                            </View>
                            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, marginLeft:10, borderRadius:8 }}>
                                <Text size={14} color={Colors.brand.midnight} weight="bold">${deposit_limit.instant_limit}</Text>
                            </View>
                        </View>
                        <View style={{ marginTop:10, flexDirection:'row', alignItems:'flex-start', padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <View style={{ flex:1 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>Hold Days</Text>
                                <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>The number of days a deposit transaction will be held (not settled) before it can be withdrawn</Text>
                            </View>
                            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, marginLeft:10, borderRadius:8 }}>
                                <Text size={14} color={Colors.brand.midnight} weight="bold">{deposit_limit.hold_days}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection:'row', alignItems:'flex-start', marginTop:10, padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <View style={{ flex:1 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>Instant Deposit Available</Text>
                                <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>Your instant deposit limit minus your unsettled deposits</Text>
                            </View>
                            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, marginLeft:10, borderRadius:8 }}>
                                <Text size={14} color={deposit_limit.instant_available > 0 ? Colors.utility.success : Colors.utility.error} weight="bold">${deposit_limit.instant_available.toFixed(2)}</Text>
                            </View>
                        </View>
                        </View>
                    </View>
                    :<></>}
                </View>
                :active_tab == 'withdraw' ?
                <View>
                    {withdraw_limit ?
                    <View nativeID="withdraw_settings">
                    <View style={{ backgroundColor:Colors.shades.shade100, padding:10 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='semibold'>Withdraw Settings</Text>
                        <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>Below are your current withdraw settings and limits.  These may change based on historical transaction results.</Text>
                    </View>
                    <View style={{ padding:10 }}>
                        <View style={{ flexDirection:'row', alignItems:'flex-start', padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <View style={{ flex:1 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>Weekly Withdraw Transaction Limit</Text>
                                <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>The number of withdraws allowed per rolling week.</Text>
                            </View>
                            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, marginLeft:10, borderRadius:8 }}>
                                <Text size={14} color={Colors.brand.midnight} weight="bold">{withdraw_limit.withdraws_per_week}</Text>
                            </View>
                        </View>
                        {withdraw_limit?.week_withdraw_count ?
                        <View style={{ marginTop:10, flexDirection:'row', alignItems:'flex-start', padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <View style={{ flex:1 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>Withdraws Remaining</Text>
                                <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>Withdraw transaction limit minus the number of withdraws already processed in the last 7 days</Text>
                            </View>
                            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, marginLeft:10, borderRadius:8 }}>
                                <Text size={14} color={Colors.brand.midnight} weight="bold">{withdraw_limit.withdraws_per_week - withdraw_limit.week_withdraw_count}</Text>
                            </View>
                        </View>
                        :<></>}
                        <View style={{ marginTop:10, flexDirection:'row', alignItems:'flex-start', padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <View style={{ flex:1 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>ACH Withdraw Daily Limit</Text>
                                <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>Maximum amount of withdraws that can be made per rolling 24 hours to an ACH account.</Text>
                            </View>
                            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, marginLeft:10, borderRadius:8 }}>
                                <Text size={14} color={Colors.brand.midnight} weight="bold">{withdraw_limit.ach_daily_limit}</Text>
                            </View>
                        </View>
                        <View style={{ marginTop:10, flexDirection:'row', alignItems:'flex-start', padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                            <View style={{ flex:1 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>Card Withdraw Daily Limit</Text>
                                <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>Maximum amount of withdraws that can be made per rolling 24 hours to a card account.</Text>
                            </View>
                            <View style={{ backgroundColor:Colors.shades.shade100, padding:10, marginLeft:10, borderRadius:8 }}>
                                <Text size={14} color={Colors.brand.midnight} weight="bold">{withdraw_limit.card_daily_limit}</Text>
                            </View>
                        </View>
                    </View>
                    </View>
                    :<></>}
                </View>
                :active_tab == 'referral' ?
                <View> 
                {player_referral && promo ?
                    <View nativeID="referral_settings">
                        <View style={{ backgroundColor:Colors.shades.shade100, padding:10 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='semibold'>Referral Settings</Text>
                            <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>Below are your current referral settings. Share your code with others to earn cash from referrals.</Text>
                        </View>
                        <View style={{ padding:10 }}>
                            {false ?
                            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:10, borderRadius:8, backgroundColor:Colors.brand.electric }}>
                                <Icons.ShareIcon color={Colors.shades.white} size={8}/>
                                <Text style={{ marginLeft:5 }} size={10} color={Colors.shades.white} weight='regular'>Share Code</Text>
                            </TouchableOpacity>
                            :<></>}
                            <View style={{ flexDirection:'row', alignItems:'flex-start', padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                                <View style={{ flex:1 }}>
                                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>My Referral Code</Text>
                                    <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='regular'>{WalletHelpers.getReferralDescription(promo)}</Text>
                                </View>
                                <View style={{ backgroundColor:Colors.shades.shade100, padding:10, marginLeft:10, borderRadius:8 }}>
                                    <Text size={14} color={Colors.brand.midnight} weight="bold">{player_referral.referral_code}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    :<></>}
                </View>
                :<></>}
            </ScrollView>
            {false && player?.type == 'freemium' ?
            <View style={{ backgroundColor:Colors.shades.shade600, borderRadius:8, padding:10, margin:10 }}>
                <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign="center">Want better limits and higher referral awards?</Text>
                <TouchableOpacity style={{ marginTop:5, borderRadius:8, backgroundColor:Colors.incentive.gold, padding:15 }} onPress={() => {if(onPremium){ return onPremium() }}}>
                    <Text size={14} color={Colors.shades.white} weight='bold' textAlign="center">VIEW PREMIUM</Text>
                </TouchableOpacity>
            </View>
            :<></>}
            <View style={{ padding:20 }}>
                <Button
                    title='BACK'
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => onClose()}
                />
            </View>
        </View>
    )
}

export default WalletSettings

