import { TouchableOpacity, View } from "react-native"

import React, { useEffect, useState } from "react"
import type { CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionTypeProps, MyPlayerProps, PlayerBalanceProps } from "../../types"
import Colors from "../../constants/colors"
import { Button, Icons, Text } from "../../Components"
import { view_styles } from "../../constants/styles"
import moment from "moment-mini"
import { SeasonHelpers } from "../api"

type EnterSeasonPromptProps = {
    player?:MyPlayerProps,
    player_balance?:PlayerBalanceProps,
    competition_season:CompetitionSeasonProps,
    competition_type:CompetitionTypeProps,
    competition_result_type:CompetitionResultTypeProps,
    width:number,
    onVerify: () => void
    onCancel:() => void,
    onEnter: (promo_balance?:boolean) => void
}

const EnterSeasonPrompt = ({ player, player_balance, competition_season, competition_type, competition_result_type, width, onCancel, onEnter, onVerify }:EnterSeasonPromptProps) => {
    const [ use_promo_balance, setUsePromoBalance ] = useState(false)

    
    useEffect(() => {
        if(!player){ return }
    },[])

    const requires_verification = player && parseInt(competition_season?.ticket_price as string ?? 0) > 0 && competition_season?.market_type == 'FOR_MONEY' && player?.vouched_status != 'verified' ? true : false
    const cl = competition_season.market_type == 'FOR_MONEY'?'$':'E'

    const { season_payout, competition_payout } = SeasonHelpers.getPayoutAmounts(competition_season)

    return (
        <View style={{ width, backgroundColor:Colors.shades.white, borderTopRightRadius:22, borderTopLeftRadius:22, paddingTop:22 }}>
           <View nativeID="competition_info">
            <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign="center">Season Details</Text>
                {competition_type ?
                <View style={{ flexDirection:'row', justifyContent:'center', margin:10 }}>
                    <View style={{ padding:10, marginRight:4, backgroundColor: Colors.brand.midnight }}>
                        <Text size={12} color={Colors.shades.white} weight='semibold'>{competition_type.type_label}</Text>
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10, marginLeft:4, backgroundColor: Colors.shades.white, borderRadius:4, ...view_styles.float }}>
                        <Text style={{ marginRight:5 }} size={12} color={Colors.brand.midnight} weight='semibold'>{competition_season.invite_only?'Private':'Public'}</Text>
                        {competition_season.invite_only ?
                        <Icons.EyeOffIcon color={Colors.brand.midnight} size={14}/>
                        :
                        <Icons.EyeOnIcon color={Colors.brand.midnight} size={14}/>
                        }
                    </View>
                </View>
                :<></>}
                <Text size={14} color={Colors.brand.midnight} weight='bold' textAlign="center">{competition_season.season_name}</Text>
            </View>
            <View>
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">{moment(competition_season.scheduled_datetime).format('MMM DD - hh:mm a')}</Text>
                {competition_season.season_description ?
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">{competition_season.season_description}</Text>
                :<></>}
                {competition_result_type ?
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">{competition_result_type.label}</Text>
                :<></>}
                {competition_type?.type == 'pick' ?
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">Picks: {competition_season.max_picks}</Text>
                :<></>}
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">Potential Season Winnings: {cl}{season_payout.toFixed(2)}</Text>
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">Potential Competition Winnings: {cl}{competition_payout.toFixed(2)}</Text>
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">Ticket Price: {cl}{(competition_season.ticket_price as number).toFixed(2)}</Text>
                <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign="center">Entries: {competition_season.tickets_sold}/{competition_season.tickets_available}</Text>
            </View>
            <View nativeID="pick_actions" style={{ flexDirection:'row', padding:10 }}>
                <Button
                    style={{ flex:1, marginRight:3 }}
                    borderRadius={4}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    padding={10}
                    title="Cancel"
                    title_color={Colors.brand.electric}
                    onPress={() => onCancel()}
                />
                {requires_verification ?
                <Button
                    borderRadius={4}
                    style={{ flex:2, marginLeft:2 }}
                    backgroundColor={Colors.utility.warning}
                    padding={10}
                    title={`Verify My Account`}
                    title_color={Colors.shades.white}
                    onPress={() => onVerify()}
                />
                :
                 <Button
                    borderRadius={4}
                    style={{ flex:2, marginLeft:2 }}
                    backgroundColor={Colors.utility.success}
                    padding={10}
                    title={`Enter Season${competition_season.ticket_price == 0 ? ``:` ${cl}${competition_season.ticket_price}`}`}
                    title_color={Colors.shades.white}
                    onPress={() => {
                        if(player_balance && competition_season.market_type == 'FOR_MONEY' && player_balance.balance < parseFloat(competition_season.ticket_price as string)){ return alert('Not enough balance to enter. Please make a deposit before continuing') }
                        
                        onEnter(use_promo_balance)
                    }}
                />
                }
            </View>
            {player_balance && player_balance.promo_balance > 0 ?
            <TouchableOpacity style={{ backgroundColor:Colors.incentive.gold, padding:10, flexDirection:'row', alignItems:'center' }} onPress={() => {
                if(use_promo_balance){
                    //Remove promo balance and open_amt
                    setUsePromoBalance(false);
                } else {
                    setUsePromoBalance(true);                    
                }
            }}>
                    <View style={{ marginRight:10, height:30, width:30, borderWidth:1, borderColor:Colors.brand.midnight, justifyContent:'center', alignItems:'center' }}>
                        {use_promo_balance ?
                        <View style={{ height:20, width:20, backgroundColor:Colors.brand.midnight }} />
                        :<></>}
                    </View>
                    <View>
                        <Text size={14} weight='bold' color={Colors.shades.white}>${player_balance?.promo_balance} Promotion Available</Text>
                        <Text style={{ marginTop:2}} size={12} color={Colors.shades.white} weight='regular'>Press / Click here to use promo balance</Text>
                    </View>
                
            </TouchableOpacity>
            :<></>}
        </View>
    )
}

export default EnterSeasonPrompt