import React, { useState } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import type { CompanyProps, CompetitionProps, CompetitionResultTypeProps, CompetitionTypeProps, PublicPlayerProps } from "../../types"
import { view_styles } from '../../constants/styles';
import moment from 'moment-mini';
import CompetitionCard from '../../Engage/components/CompetitionCard';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';

type CompetitionsSectionProps = {
    width:number,
    competitions:CompetitionProps[],
    competition_result_type:CompetitionResultTypeProps,
    competition_type:CompetitionTypeProps,
    admin?:PublicPlayerProps,
    company?:CompanyProps,
    onCompetitionSelect:(c:CompetitionProps) => void
}

const CompetitionsSection = ({ competitions, width, admin, company, competition_result_type, competition_type, onCompetitionSelect }:CompetitionsSectionProps) => {
    const [ expanded, setExpanded ] = useState(true);
    const [ active_tab, setActiveTab ] = useState('active');

    let filtered_competitions = [ ...competitions ]
    if(active_tab == 'active'){ filtered_competitions = filtered_competitions.filter(c => c.status != 'closed') }
    if(active_tab == 'closed'){ filtered_competitions = filtered_competitions.filter(c => c.status == 'closed') }

    const renderCompetitions = (data: { item:CompetitionProps, index:number }) => {
        return (
            <View>
                <CompetitionCard
                    competition={data.item}
                    competition_result_type={competition_result_type}
                    competition_type={competition_type}
                    company={company}
                    admin={admin}
                    onCompetitionSelect={(c) => onCompetitionSelect(c)}
                />
            </View>
        )
    }

    return (
        <View style={{ ...view_styles.section, maxWidth: width }}>
            <TouchableOpacity style={{ ...view_styles.section_header }} onPress={() => setExpanded(!expanded)}>
                <Icons.ListIcon size={12} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>Included Competitions</Text>
                    <Text style={{ marginTop:3 }}>View the list of competitions that are included in this season.</Text>
                </View>
                <Icons.ChevronIcon direction={expanded?'up':'down'} color={Colors.brand.midnight} size={8} />
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body, padding:0 }}>
                <View style={{ ...view_styles.body_row, margin:10, backgroundColor:Colors.shades.white, borderRadius:22, borderWidth:4, borderColor:Colors.shades.shade100}}>
                <Button
                    title='ACTIVE'
                    title_color={active_tab == 'active' ? Colors.shades.white : Colors.brand.midnight}
                    title_weight={active_tab == 'active' ? 'bold' : 'regular'}
                    padding={15}
                    title_size={12}
                    style={{flex:1}}
                    borderRadiusOverride={{
                        borderTopLeftRadius: 22,
                        borderBottomLeftRadius:22,
                        borderTopRightRadius:0,
                        borderBottomRightRadius:0
                    }}
                    backgroundColor={active_tab == 'active' ? Colors.brand.midnight : Colors.shades.white}
                    onPress={() => setActiveTab('active')}
                />
                <Button
                    title='CLOSED'
                    style={{flex:1}}
                    title_size={12}
                    title_color={active_tab == 'closed' ? Colors.shades.white : Colors.brand.midnight}
                    title_weight={active_tab == 'closed' ? 'bold' : 'regular'}
                    padding={15}
                    borderRadiusOverride={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius:0,
                        borderTopRightRadius:22,
                        borderBottomRightRadius:22
                    }}
                    backgroundColor={active_tab == 'closed' ? Colors.brand.midnight : Colors.shades.white}
                    onPress={() => setActiveTab('closed')}
                />
            </View>
                <FlatList
                    data={filtered_competitions.sort((a,b) => moment(b.scheduled_datetime).unix() - moment(a.scheduled_datetime).unix())}
                    renderItem={renderCompetitions}
                />
            </View>
            :<></>}
        </View>
    )
}

export default CompetitionsSection