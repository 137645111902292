import React from 'react';
import { Image, View, TouchableOpacity } from 'react-native';
import { view_styles } from '../../constants/styles';
import { Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import type { CompanyProps, EventProps, PublicPlayerProps, SquaresCompetitionProps } from '../../types';
import moment from 'moment-mini';

type SquaresCompetitionCardProps = {
    squares_competition:SquaresCompetitionProps,
    admin?:PublicPlayerProps,
    event?:EventProps
    company?:CompanyProps,
    onSelectCompetition:(sq:SquaresCompetitionProps) => void
}

const SquaresCompetitionCard = ({ squares_competition, admin, event, company, onSelectCompetition }:SquaresCompetitionCardProps) => {
    let current_payout = squares_competition.potential_winnings
    if(squares_competition.guaranteed_payout && squares_competition.guaranteed_payout > current_payout){ current_payout = squares_competition.guaranteed_payout }
    const is_live = moment().isAfter(moment(squares_competition.begin_datetime)) ? true : false
    return (
        <TouchableOpacity style={{ ...view_styles.section, padding:0, borderWidth:1, borderColor:Colors.shades.shade600  }} onPress={() => onSelectCompetition(squares_competition)}>
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <Image
                    source={{ uri: squares_competition.image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:45, width:45, borderRadius:4}}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:10, marginRight:10, borderRightWidth:1, borderRightColor:Colors.shades.shade600 }}>
                    <Text theme='header_2'>{squares_competition.sq_comp_name}</Text>
                    <Text style={{ marginTop:3 }} theme='body'>{squares_competition.sq_comp_description}</Text>
                </View>
                <Text size={20} color={Colors.brand.electric} weight='bold'>${squares_competition.minimum_square_price}</Text>
            </View>
            <View style={{ ...view_styles.section_body, padding:10, paddingTop:20, paddingBottom:20 }}>
                <View style={{ ...view_styles.body_row, padding:0 }}>
                    {event ?
                    <View style={{ flex:1, flexDirection:'row', marginRight:10, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{ flexDirection:'row', alignItems:'center' }}>
                            <Image
                                source={{ uri: event.away?.image?.url }}
                                style={{ height:30, width:30 }}
                                resizeMode='cover'
                            />
                            <Text style={{ padding:5 }} theme='header_2'>VS</Text>
                            <Image
                                source={{ uri: event.home?.image?.url }}
                                style={{ height:30, width:30 }}
                                resizeMode='cover'
                            />
                        </View>
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text theme='header_2'>{event.event_title}</Text>
                            <Text style={{ marginTop:3 }} theme='body'>{event.time_detail == 'scheduled' ? moment(event.scheduled_datetime).format('MMM DD hh:mm a') : event.time_detail}</Text>
                        </View>
                    </View>
                    :<></>}
                    <View style={{ justifyContent:'center', alignItems:'center' }}>
                        <Icons.SquaresIcon size={24} color={Colors.brand.midnight} />
                        <Text style={{ marginTop:3 }} theme='header_2'>AUCTION</Text>
                    </View>
                </View>
            </View>
            <View style={{ ...view_styles.section_footer, backgroundColor:company?Colors.incentive.gold_faded:Colors.shades.shade100 }}>
                {company ?
                <Image
                    source={{ uri: company.company_image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:30, width:30, borderRadius:4}}
                    resizeMode='cover'
                />
                :admin ?
                <Image
                    source={{ uri: admin.profile_pic && admin.profile_pic != '' ? admin.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:30, width:30, borderRadius:4}}
                    resizeMode='cover'
                />
                :<></>}
                <View style={{ flex:1, marginLeft:20, marginRight:20 }}>
                    <Text theme='header_2'>CURRENT PAYOUT</Text>
                    {is_live ? 
                    <Text style={{ marginTop:3 }} color={Colors.utility.error} weight='bold' size={12}>AUCTION COMPLETE</Text>
                    :
                    <Text style={{ marginTop:3 }} theme='body_2'>Auction Ends {moment(squares_competition.begin_datetime).fromNow()}</Text>
                    }
                </View>
                <Text color={Colors.utility.success} size={20} weight='bold'>${current_payout.toFixed(2)}</Text>
            </View>
        </TouchableOpacity>
    )
}

export default SquaresCompetitionCard