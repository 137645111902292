import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, FlatList, Image, ScrollView } from "react-native";
import type { AthleteProps, BracketCompetitionProps, CompetitionPlayerBracketProps, PlayerBracketProps, TeamProps } from '../../types';
import Colors from '../../constants/colors';
import { Button, Icons, Text, TextInput } from '../../Components';
import { BracketComeptitionApi, BracketCompetitionHelpers } from '../api';

type PlayerBracketManagerProps = {
    visible:boolean,
    width:number,
    teams:TeamProps[],
    athletes:AthleteProps[],
    player_bracket?:PlayerBracketProps,
    competition_player_brackets:CompetitionPlayerBracketProps[],
    bracket_competitions:BracketCompetitionProps[],
    onUpdateBracket: (pb:PlayerBracketProps) => void,
    onEditPicks: (pb:PlayerBracketProps) => void,
    onSelectCompetition: (bc:BracketCompetitionProps) => void,
    onClose: () =>  void
}

const PlayerBracketManager = ({ visible, teams, athletes, player_bracket, bracket_competitions, competition_player_brackets, onEditPicks, onSelectCompetition, onUpdateBracket, onClose }:PlayerBracketManagerProps) => {
    const [ search_value, setSearchValue ] = useState('');
    const [ search_error, setSearchError ] = useState<string|undefined>(undefined)
    const [ info_expanded, setInfoExpanded ] = useState(true);
    const [ manage_data, setData ] = useState<{
        loading:boolean,
        draft_bracket?:PlayerBracketProps,
        active_comp_toggle:'entered'|'eligible',
    }>({
        loading: false,
        draft_bracket: player_bracket,
        active_comp_toggle: 'entered'
    })
    const { draft_bracket, active_comp_toggle } = manage_data;
    let visible_competitions = [ ...bracket_competitions ];
    if(search_value){
        visible_competitions = visible_competitions.filter(c => `${c.competition_name.toLowerCase()}`.includes(search_value.toLowerCase()))
    }

    useEffect(() => {
        setData({ ...manage_data, draft_bracket: player_bracket })
    },[player_bracket])


    const handleFindPrivate = async() => {
        const bc = await BracketComeptitionApi.getBracketcompetitionByCode(search_value)
        if(!bc){ return setSearchError(`Unable to find competition with the provided code`) }
        return onSelectCompetition(bc)
    }


    const renderCompetitions = (data: { item:BracketCompetitionProps, index:number }) => {
        const payout = BracketCompetitionHelpers.getPayout(data.item)
        return (
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}
            onPress={() => onSelectCompetition(data.item)}>
                <View>
                    <Image
                        source={{ uri: data.item.image?.url }}
                        style={{ height:35, width:35, borderRadius:3 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ flex:1, marginLeft:5 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{data.item.competition_name}</Text>
                    <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular'>Current Payout: ${payout.toFixed(2)}</Text>
                </View>
                <View style={{ padding:10 }}>
                    <Text size={16} weight='bold' color={Colors.brand.midnight} textAlign='center'>${data.item.buy_in}</Text>
                    <Text style={{ marginTop:5 }} size={12} weight='bold' color={Colors.brand.slate} textAlign='center'>{data.item.tickets_sold} / {data.item.max_brackets}</Text>
                </View>
                <Icons.ChevronIcon direction='right' color={Colors.brand.midnight} size={12} />
            </TouchableOpacity>
        )
    }

    const renderCompPlayerBrackets = (data: { item: CompetitionPlayerBracketProps, index:number }) => {
        const bracket_competition = bracket_competitions.find(c => c.bracket_competition_id == data.item.bracket_competition_id);
        if(!bracket_competition){ return <></> }
        return (
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}
            onPress={() => onSelectCompetition(bracket_competition)}>
                <View>
                    <Image
                        source={{ uri: bracket_competition.image?.url }}
                        style={{ height:35, width:35, borderRadius:3 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ flex:1, marginLeft:5 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{bracket_competition.competition_name}</Text>
                    <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular'>{data.item.points_scored} Points ({data.item.possible_points} possible)</Text>
                </View>
                <View style={{ padding:10 }}>
                    <Text size={16} weight='bold' color={Colors.brand.midnight} textAlign='center'>{BracketCompetitionHelpers.formatPlace(data.item.place)}</Text>
                    <Text style={{ marginTop:5 }} size={12} weight='bold' color={Colors.brand.slate} textAlign='center'>${data.item.winnings.toFixed(2)}</Text>
                </View>
                <Icons.ChevronIcon direction='right' color={Colors.brand.midnight} size={12} />
            </TouchableOpacity>
        )
    }

    if(!visible || !player_bracket || !draft_bracket){ return <></> }

    const is_changed = JSON.stringify(draft_bracket) != JSON.stringify(player_bracket) ? true : false
    const champion_team = player_bracket.champion_id_type == 'team' ? teams.find(t => t.team_id == player_bracket.champion_id) : undefined
    const champion_athlete = player_bracket.champion_id_type == 'athlete' ? athletes.find(t => t.team_id == player_bracket.champion_id) : undefined
    const can_delete = BracketCompetitionHelpers.canDeletePlayerBracket(player_bracket, competition_player_brackets)

    return (
        <View style={{ flex:1 }}>
              <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade100 }} onPress={() => onClose()}>
                <Icons.ChevronIcon direction='left' size={12} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:20 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='bold'>{player_bracket.bracket_name}</Text>
                    <Text style={{ marginTop:4 }} size={14} color={Colors.brand.slate} weight='regular'>Manage your bracket and enter competitions</Text>
                </View>
            </TouchableOpacity>
            <ScrollView style={{ flex:1}}>

            <View nativeID='bracket_info'>
                <TouchableOpacity style={{ padding:20, flexDirection:'row', alignItems:'center', backgroundColor:Colors.shades.shade100 }} onPress={() => setInfoExpanded(!info_expanded)}>
                    <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='bold'>Bracket Details</Text>
                    <Icons.ChevronIcon direction={info_expanded?'up':'down'} color={Colors.brand.midnight} size={10} />
                </TouchableOpacity>
                {info_expanded ?
                <View style={{ padding:10, borderRadius:8, backgroundColor:Colors.shades.white }}>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10 }}>
                        <Text style={{ flex:1 }} size={14} weight='bold' color={Colors.brand.midnight}>Name</Text>
                        <TextInput
                            editable={player_bracket.status == 'active' ? true : false}
                            value={draft_bracket.bracket_name}
                            style={{ borderRadius:8, padding:10 }}
                            onChangeText={(text) => setData({ ...manage_data, draft_bracket: { ...draft_bracket, bracket_name:text } })}
                        />
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10 }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>% Complete</Text>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{(player_bracket.completion_pct * 100).toFixed(2)}%</Text>
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10 }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>Champion</Text>
                        {player_bracket.champion_id_type == 'team' && champion_team ?
                        <View style={{ flexDirection:'row', alignItems:'center' }}>
                            <Image
                                source={{ uri: champion_team.image?.url }}
                                style={{ height:25, width:25, marginRight:10 }}
                                resizeMode='cover'
                            />
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>{champion_team.market_name} {champion_team.name}</Text>
                        </View>
                        :player_bracket.champion_id_type == 'athlete' && champion_athlete ?
                        <View style={{ flexDirection:'row', alignItems:'center' }}>
                            <Image
                                source={{ uri: champion_athlete.image?.url }}
                                style={{ height:25, width:25, marginRight:10 }}
                                resizeMode='cover'
                            />
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>{champion_athlete.first_name} {champion_athlete.last_name}</Text>
                        </View>
                        :<></>}
                    </View>
                    
                    <View style={{ padding:10, flexDirection:'row', alignItems:'center' }}>
                        <View style={{ flex:1 }} />
                        {can_delete ?
                        <Button
                            title='DELETE'
                            style={{ marginRight:4 }}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.utility.error}
                            onPress={async() => {
                                const new_b = await BracketComeptitionApi.deletePlayerBracket(draft_bracket.player_bracket_id);
                                onUpdateBracket(new_b)
                            }}
                        />
                        :<></>}
                        {is_changed ?
                        <Button
                            title='SAVE'
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.utility.success}
                            onPress={async() => {
                                const new_b = await BracketComeptitionApi.updatePlayerBracket(draft_bracket);
                                onUpdateBracket(new_b)
                            }}
                        />
                        : player_bracket.status == 'active' ?
                        <Button
                            title='UPDATE PICKS'
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.brand.electric}
                            onPress={() => onEditPicks(player_bracket)}
                        />
                        :<></>}
                    </View>
                </View>
                :<></>}
                {player_bracket.status == 'deleted' ?
                <View style={{ position:'absolute', top:0, bottom:0, left:0, right:0, backgroundColor:Colors.shades.black_faded_heavy, justifyContent:'center', alignItems:'center' }}>
                    <View style={{ borderRadius:8, backgroundColor:Colors.shades.white, height:120, width:200, padding:10, justifyContent:'center', alignItems:'center' }}>
                        <Text size={18} weight='bold' color={Colors.utility.error}>DELETED!</Text>
                    </View>
                </View>
                :<></>}
            </View>
            {player_bracket.status != 'deleted' ?
            <View>
                <View nativeID='competitions'>
                    <View style={{ padding:10, paddingLeft:20, flexDirection:'row', alignItems:'center', backgroundColor:Colors.shades.shade100 }}>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='bold'>Competitions</Text>
                        <View style={{ flexDirection:'row', alignItems:'center', borderRadius:22, backgroundColor:Colors.shades.white }}>
                            <TouchableOpacity style={{ padding:10, borderTopLeftRadius:22, borderBottomLeftRadius:22, backgroundColor:active_comp_toggle=='entered' ? Colors.brand.midnight: 'transparent' }} onPress={() => setData({ ...manage_data, active_comp_toggle: 'entered' })}>
                                <Text size={14}  textAlign='center' color={active_comp_toggle=='entered'?Colors.shades.white:Colors.brand.midnight} weight={active_comp_toggle=='entered'?'bold':'regular'}>ENTERED</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ padding:10, borderTopRightRadius:22, borderBottomRightRadius:22, backgroundColor:active_comp_toggle=='eligible' ? Colors.brand.midnight: 'transparent' }} onPress={() => setData({ ...manage_data, active_comp_toggle: 'eligible' })}>
                                <Text size={14} textAlign='center' color={active_comp_toggle=='eligible'?Colors.shades.white:Colors.brand.midnight} weight={active_comp_toggle=='eligible'?'bold':'regular'}>ELIGIBLE</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
                <View nativeID='competition_list'>
                    {active_comp_toggle == 'entered' ?
                    <FlatList
                        data={competition_player_brackets.filter(cpb => cpb.player_bracket_id == player_bracket.player_bracket_id)}
                        renderItem={renderCompPlayerBrackets}
                        keyExtractor={(item) => item.competition_player_bracket_id.toString()}
                    />
                    : active_comp_toggle == 'eligible' ?
                    <View>
                    <View style={{ padding:20, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                    <View style={{ marginBottom:10 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='semibold'>Have a private code?</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate}>Enter it below and select search.  If found, we will send you to the competition.</Text>
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center' }}>
                        <TextInput
                            style={{ flex:1 }}
                            value={search_value}
                            onChangeText={(text) => {
                                if(search_error){ setSearchError(undefined) }
                                setSearchValue(text)
                            }}
                            placeholder='Search or Enter Private Code'
                            placeholderTextColor={Colors.brand.slate}
                        />
                        {search_value ?
                        <Button
                            title='Find Private'
                            style={{ marginLeft:5 }}
                            padding={15}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.brand.electric}
                            onPress={() => handleFindPrivate()}
                        />
                        :<></>}
                    </View>
                    {search_error ?
                    <Text style={{ padding:10 }} size={14} color={Colors.utility.warning} weight='regular' textAlign='center'>{search_error}</Text>
                    :<></>}
                    </View>
                    <FlatList
                        data={visible_competitions}
                        keyExtractor={(item) => item.bracket_competition_id.toString()}
                        renderItem={renderCompetitions}
                    />
                    </View>
                    :<></>}
                </View>
            </View>
            :<></>}
            </ScrollView>
        </View>
    )
}

export default PlayerBracketManager