import axios from "axios";
import { APIOverrides } from "../../ApiOverrides";
import type { AthleteProps, BestAvailableOrderProps, BestAvailableResponseProps, EventOrderStatProps, EventProps, ExternalPriceProps, FundingRequestProps, FundOrderProps, FundOwnershipProps, FundProps, HedgedPositionProps, HedgeProps, LeagueProps, MarketProps, MarketSideOptionProps, MatchProps, OrderProps, PositionProps, PublicPlayerProps, SortedContestProps, TeamProps, TournamentProps, TradeProps } from "../../types";
import moment from "moment-mini";
import Colors from "../../constants/colors";

let EVENT_SVC_API = '';
let MK_SVC_API = '';
let AUTH_SVC_API = '';

export { MarketMakerApi, MarketMaketHelpers }

const MarketMakerApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        MK_SVC_API = endpoints['MK_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getPlayersByIds: async(player_ids:string[]):Promise<PublicPlayerProps[]> => {
        try {
            if(player_ids.length == 0){ return [] }
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
            return resp.data.players
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getMyActiveFunds: async(offset:number):Promise<FundProps[]> => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/funds/me/active?offset=${offset}`);
            return resp.data.funds
        } catch (e) {
            console.log(e);
            return []
        }
    },
    createFund: async(name:string, minimum_investment:number, market_type: string):Promise<FundProps | undefined> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/funds/fund/create`, { name, minimum_investment, market_type });
            return resp.data.fund
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    udpateFundName: async(fund_id:string, name:string):Promise<FundProps | undefined> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/funds/fund/update/name`, { fund_id, name });
            return resp.data.fund
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    udpateFundMinimum: async(fund_id:string, minimum_investment:string):Promise<FundProps | undefined> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/funds/fund/update/min`, { fund_id, minimum_investment });
            return resp.data.fund
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    getFundById: async(fund_id:string):Promise<undefined | { fund:FundProps, orders:OrderProps[], fund_ownerships:FundOwnershipProps[], fund_orders:FundOrderProps[] }> => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/funds/fund/${fund_id}`);
            return resp.data
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    getFundingRequestsByFundId: async(fund_id:string):Promise<FundingRequestProps[]> => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/funds/requests/fund/${fund_id}`);
            return resp.data.funding_requests
        } catch (e) {
            console.log(e);
            return []
        }
    },
    depositRequest: async(fund_id:string, amount:number):Promise<FundProps | undefined> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/funds/deposit/request/create`, { fund_id, amount })
            return resp.data.fund
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    approveRequest: async(funding_request_id:string):Promise<{ fund:FundProps, funding_request:FundingRequestProps, fund_ownerships:FundOwnershipProps[] } | undefined> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/funds/deposit/request/approve`, { funding_request_id })
            return resp.data
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    declineRequest: async(funding_request_id:string):Promise<FundingRequestProps | undefined> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/funds/deposit/request/decline`, { funding_request_id })
            return resp.data.funding_request
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    workCapital: async(fund_id:string, amount:number):Promise<{ fund:FundProps, orders:OrderProps[] } | undefined> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/funds/capital/work`, { fund_id, amount })
            return resp.data
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    layoffCapital: async(fund_id:string, amount:number):Promise<{ fund:FundProps, orders:OrderProps[] } | undefined> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/funds/capital/layoff`, { fund_id, amount })
            console.log(resp.data)
            return resp.data
        } catch (e) {
            console.log(e)
            return undefined
        }
    },
    addOrdersToFund:async(fund_id:string, orders:OrderProps[]):Promise<{ orders:OrderProps[], fund_orders:FundOrderProps[] }> => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/funds/fund/orders/add`, { fund_id, orders })
            return resp.data.orders
        } catch (e) {
            return { orders:[], fund_orders:[] }
        }
    },
    getLeagues: async():Promise<LeagueProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/leagues`);
            return resp.data.leagues
        } catch (e) {
            return []
        }
    },
    getAthletesByIds : async(athlete_ids:string[]) => {
        if(athlete_ids.length == 0){ return [] }
        try {
            const resp = await axios.post(`${EVENT_SVC_API}/v1/athletes/bulk/get`, { attribute: 'athlete_id', values: athlete_ids })
            return resp.data.athletes
        } catch (e) {
            return []
        }
    },
    getMarkets: async():Promise<MarketProps[]> => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/markets/all`);
            return resp.data.markets
        } catch (e) {
            return []
        }
    },
    getMyAction: async():Promise<OrderProps[]> => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/action/me`);
            return resp.data.orders
        } catch (e) {
            return []
        }
    },
    getActiveEvents: async():Promise<EventProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/events/active?expanded=true`);
            return resp.data.events
        } catch (e) {
            return []
        }
    },
    getEventsByEventIds: async(event_ids:string[]):Promise<EventProps[]> => {
        if(event_ids.length == 0){ return [] }
        try {
            const resp = await axios.post(`${EVENT_SVC_API}/v1/events/bulk/get`, { attribute:'event_id', values: event_ids })
            return resp.data.events
        } catch (e) {
            return []
        }
    },
    getBestAvailableOrders: async():Promise<BestAvailableResponseProps> => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/available`)
            return resp.data
        } catch (e) {
            return { events: [], tournaments: [], matches:[] }
        }
    },
    getPricesByEvents: async(event_type:string, event_ids:string[]):Promise<ExternalPriceProps[]> => {
        if(event_ids.length == 0){ return [] }
        try {
            const resp = await axios.post(`${EVENT_SVC_API}/v1/prices/latest/bulk/get`, { event_type, event_ids });
            return resp.data.prices
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getPricesByEvent: async(event_id:string, event_type:string):Promise<ExternalPriceProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/prices/latest/${event_id}/${event_type}`);
            return resp.data.prices
        } catch (e) {
            console.log(e);
            return []
        }
    },
    getLatestTradesByEvents: async(event_type: string, event_ids:string[]) => {
        try {
            if(event_ids.length == 0){ return [] }
            const resp = await axios.post(`${MK_SVC_API}/v1/trades/event/latest/bulk/get`, { event_type, event_ids })
            return resp.data.trades
        } catch (e) {
            console.log(e)
            return []
        }
    },
    cancelOrders: async(order_ids:string[]):Promise<OrderProps[]> => {
        if(order_ids.length == 0){ return [] }
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/bulk/cancel`, { order_ids });
            return resp.data.orders
        } catch (e) {
            alert('Unable to process cancellation')
            return []
        }
    }
}

const MarketMaketHelpers = {
    getOrderStats:(orders:OrderProps[], ba_order?:BestAvailableOrderProps) => {
        let called_amt = 0, open_amt = 0, open_potential_winnings = 0, stake_amt = 0, stake_potential_winnings = 0
        let open_probability = 0, stake_probability = 0, open_odds = 0, stake_odds = 0, cash_rcvd = 0, delayed_cash = 0, winnings = 0, net_winnings = 0, commission = 0
        let stake_earnings = 0
        
        let wi_potential_winnings = 0, wi_stake_amt = 0, wi_probability = 0
        let wi_recommended = false;
        
        orders.map(o => {
            if(o.status == 'approved'){ 
                open_amt += o.open_amt 
                open_potential_winnings += o.potential_winnings
            }
            called_amt += o.called_amt
            if(o.positions){
                o.positions.map((p) => {
                    stake_amt += p.stake
                    stake_potential_winnings += p.potential_winnings
                    cash_rcvd += p.cash_rcvd
                    delayed_cash += p.delayed_cash
                    winnings += p.winnings
                    net_winnings += p.net_winnings
                    commission += p.commission
                })
            }
        })
        stake_earnings = stake_potential_winnings - stake_amt
        let open_earnings = open_potential_winnings - open_amt
        if(open_potential_winnings > 0){
            open_probability = open_amt / open_potential_winnings
            open_odds = MarketMaketHelpers.calcAmericanOddsFromProbability(open_probability);
        }
        if(stake_potential_winnings > 0){
            stake_probability = stake_amt / stake_potential_winnings
            stake_odds = MarketMaketHelpers.calcAmericanOddsFromProbability(stake_probability)
        }
        if(ba_order){
            wi_potential_winnings = stake_potential_winnings + ba_order.potential_winnings
            wi_stake_amt = stake_amt + ba_order.open_amt
            wi_probability = wi_stake_amt / wi_potential_winnings
            if(wi_probability < stake_probability){ wi_recommended = true }
        }
        return { open_amt, stake_earnings, open_odds, stake_probability, stake_odds, open_earnings, wi_potential_winnings, wi_stake_amt, wi_probability, wi_recommended, called_amt, open_potential_winnings, stake_amt, stake_potential_winnings, open_probability, cash_rcvd, delayed_cash, winnings, net_winnings, commission }
    },
    getWeight: (o_side_potential_winnings: number, trade_side_potential_winnings:number, market:MarketProps) => {
        const total = o_side_potential_winnings + trade_side_potential_winnings;
        let weighted_pct = 0;
        const difference = trade_side_potential_winnings - o_side_potential_winnings;
        const direction = difference > 0 ? market.trade_side : difference == 0 ? 'neutral' : market.side_options.find(so => so.side != market.trade_side)?.side;

        if(total > 0){
            weighted_pct = difference / total
        }
        return { direction, weighted_pct, difference }
    },
    sortContests: (events:EventProps[], tournaments:TournamentProps[], matches:MatchProps[], markets:MarketProps[]):SortedContestProps[] => {
        let contests:SortedContestProps[] = []
        events.map(e => {
            let e_markets = e.supported_markets ? markets.filter(m => e.supported_markets?.map(sm => sm.market_id.toString()).includes(m.market_id.toString())) : []
            contests.push({ 
                league_id: e.league_id, 
                id: e.event_id, 
                title: e.event_title, 
                contest_type: 'team', 
                time_detail: e.time_detail == 'scheduled' ? moment(e.scheduled_datetime).format('MMM DD : hh:mm a'): e.time_detail ?? '', 
                scheduled_datetime: moment(e.scheduled_datetime),
                markets: e_markets
            })
        })
        tournaments.map(t => {
            let t_markets = t.supported_markets ? markets.filter(m => t.supported_markets?.map(sm => sm.market_id.toString()).includes(m.market_id.toString())) : []

            contests.push({ markets: t_markets, league_id: t.league_id, id: t.tournament_id, title: t.tournament_name, contest_type: 'tournament', time_detail: moment(t.scheduled_datetime).format('MMM DD : hh:mm a'), scheduled_datetime: moment(t.scheduled_datetime) })
        })
        matches.map(m => {
            const tourney = tournaments.find(t => t.tournament_id == m.tournament_id);
            let m_markets = m.supported_markets ? markets.filter(mk => m.supported_markets?.map(sm => sm.market_id.toString()).includes(mk.market_id.toString())) : []
            contests.push({ markets:m_markets, league_id:tourney?.league_id, id: m.match_id, title: m.match_title, contest_type: 'match', time_detail: moment(m.scheduled_datetime).format('MMM DD : hh:mm a'), scheduled_datetime: moment(m.scheduled_datetime) })
        })
        return contests
    },
    calcAmericanOddsFromProbability: (probability:number) => {
        let p = probability*100; //Convert to whole number
        if (p >= 100){ return -99999 }
        if(p === 0){ return 9999 }
        if(p === 50){ return 100 }
        if(p < 50){
            return (100/(p/100))-100
        }
        return (p/(1-(p/100)))*-1
    },
    getSelectableMarkets: (events:EventProps[], tournaments:TournamentProps[], matches:MatchProps[], markets:MarketProps[]):MarketProps[] => {
        let market_ids:string[] = []
        events.map(e => {
            if(e.supported_markets){
                market_ids = market_ids.concat(e.supported_markets.map(sm => sm.market_id))
            }
        });
        tournaments.map(e => {
            if(e.supported_markets){
                market_ids = market_ids.concat(e.supported_markets.map(sm => sm.market_id))
            }
        })
        matches.map(e => {
            if(e.supported_markets){
                market_ids = market_ids.concat(e.supported_markets.map(sm => sm.market_id))
            }
        })
        market_ids = [ ...new Set(market_ids.map(id => id.toString())) ]
        return markets.filter(m => market_ids.includes(m.market_id.toString()))
    },
    updateBestAvailable: (ba:BestAvailableResponseProps, new_ba:BestAvailableResponseProps):BestAvailableResponseProps => {
        let events = ba.events.filter(e => !new_ba.events.find(newe => newe.event_id == e.event_id)).concat(new_ba.events);
        let tournaments = ba.tournaments.filter(t => !new_ba.tournaments.find(newt => newt.tournament_id == t.tournament_id)).concat(new_ba.tournaments);
        let matches = ba.matches.filter(m => !new_ba.matches.find(newb => newb.match_id == m.match_id)).concat(new_ba.matches);
        return { events, tournaments, matches }
    },
    getMarketDataFromBestAvailable: (ba:BestAvailableResponseProps):{ updated:boolean, latest_trades:TradeProps[], event_order_stats:EventOrderStatProps[], available_orders:BestAvailableOrderProps[] } => {
        let updated = false;
        let event_order_stats:EventOrderStatProps[] = []
        let available_orders:BestAvailableOrderProps[] = []
        let latest_trades:TradeProps[] = []
        ba.events.map(ba_event => {
            if(!ba_event.supported_markets){ return }
            updated = true
            ba_event.supported_markets.map(sm => {
                if(sm.available_orders){ available_orders = available_orders.concat(sm.available_orders) }
                if(sm.order_stats){ event_order_stats = event_order_stats.concat(sm.order_stats) }
                if(sm.latest_trades){ latest_trades = latest_trades.concat(sm.latest_trades) }
            })
        })

        ba.tournaments.map(ba_event => {
            if(!ba_event.supported_markets){ return }
            updated = true
            ba_event.supported_markets.map(sm => {
                if(sm.available_orders){ available_orders = available_orders.concat(sm.available_orders) }
                if(sm.order_stats){ event_order_stats = event_order_stats.concat(sm.order_stats) }
                if(sm.latest_trades){ latest_trades = latest_trades.concat(sm.latest_trades) }
            })
        })

        ba.matches.map(ba_event => {
            if(!ba_event.supported_markets){ return }
            updated = true
            ba_event.supported_markets.map(sm => {
                if(sm.available_orders){ available_orders = available_orders.concat(sm.available_orders) }
                if(sm.order_stats){ event_order_stats = event_order_stats.concat(sm.order_stats) }
                if(sm.latest_trades){ latest_trades = latest_trades.concat(sm.latest_trades) }
            })
        })
        return { updated, event_order_stats, available_orders, latest_trades }
    },
    getSideMarketData : (orders:OrderProps[], best_available_orders:BestAvailableOrderProps[], trades:TradeProps[], market:MarketProps, side_type?:string, side_id?:string) => {
        console.log(side_type, side_id);
        let trade_side_orders = orders.filter(o => o.side == market.trade_side);
        let o_side_orders = orders.filter(o => o.side != market.trade_side);
        let trade_side_ba = best_available_orders.find(o => o.side == market.trade_side);
        let o_side_ba = best_available_orders.find(o => o.side != market.trade_side)
        let trade_side_trades = trades.filter(t => t.side == market.trade_side);
        let o_side_trades = trades.filter(t => t.side != market.trade_side);
        const o_side = market.side_options.find(so => so.side != market.trade_side)?.side
        let trade_side_ba_mine = orders.find(o => o.order_id == trade_side_ba?.order_id) ? true : false;
        let o_side_ba_mine = orders.find(o => o.order_id == o_side_ba?.order_id) ? true : false;
        return { trade_side:market.trade_side, o_side, trade_side_orders, o_side_orders, trade_side_ba, o_side_ba, trade_side_trades, o_side_trades, trade_side_ba_mine, o_side_ba_mine }
    },
    getOrderColor: (order:OrderProps | BestAvailableOrderProps) => {
        if(!order.grade){ return Colors.shades.shade600 }
        if(order.grade > 98){ return Colors.highlights.highlight400Faded }
        return Colors.highlights.highlight300Faded
    },
    getOrderFromTrade: (trade:TradeProps, unit_size?:number, ba_order_id?:string):OrderProps => {
        let open_amt = 10
        if(unit_size){ open_amt = unit_size }
        let draft_liquidity = 0
        if(trade.draft_liquidity){ draft_liquidity = trade.draft_liquidity }
        return {
            order_id: '',
            player_id: '',
            be_type: 'market', //TODO - Make this dynamic,
            open_amt,
            called_amt: 0,
            buy_sell_ind: 'buy',
            rejections:0,
            commission_pct:0,
            available_for_parlays: false,
            event_id: trade.event_id,
            draft_liquidity,
            event_type: trade.event_type,
            side: trade.side as 'home'|'away'|'over'|'under'|'yes'|'no',
            side_type: trade.side_type as 'team' | 'side' | 'athlete',
            resolution_status: 'inprogress',
            side_id: trade.side_id,
            order_type: trade.limit_override ? 'limit': 'market',
            potential_winnings: 0,
            collar_pct: .01,
            positions: [],
            market_id: trade.market_id,
            title: '',
            probability: trade.probability,
            odds: trade.odds,
            var_1: trade.var_1,
            market_type: trade.market_type,
            position_ids: [],
            status: 'approved',
            expire_datetime: moment().add(1, 'hours'),
            reference_order_id:ba_order_id
        }
    },
    getTeamEventSideIds:(event:EventProps, market:MarketProps, side_option:MarketSideOptionProps, athlete?:AthleteProps, team?:TeamProps) => {
        const o_side = market.side_options.find(so => so.side != side_option.side);
        if(!o_side){ return undefined }
        switch(side_option.id_source){
            case 'side':
                return { side_id: side_option.side, reversed_side_id: o_side.side }
            case 'athlete':
                if(!athlete){ return undefined }
                return { side_id: athlete.athlete_id, reversed_side_id: athlete.athlete_id }
            case 'team':
                if(market.level == 'event'){
                    let side_id = event[side_option.side as keyof EventProps]?.team_id
                    let reversed_side_id = event[o_side.side as keyof EventProps]?.team_id
                    if(!side_id || !reversed_side_id){ return undefined }
                    return { side_id, reversed_side_id }
                }
                if(market.level == 'team'){
                    if(!team){ return undefined }
                    return { side_id: team.team_id, reversed_side_id: team.team_id }
                }
                return undefined
            default: return undefined
        }
    },
    getTournamentSideIds: (market:MarketProps, side_option:MarketSideOptionProps, athlete?:AthleteProps, team?:TeamProps) => {
        const o_side = market.side_options.find(so => so.side != side_option.side);
        if(!o_side){ return undefined }
        switch(side_option.id_source){
            case 'side':
                return { side_id: side_option.side, reversed_side_id: o_side.side }
            case 'athlete':
                if(!athlete){ return undefined }
                return { side_id: athlete.athlete_id, reversed_side_id: athlete.athlete_id }
            case 'team':
                if(market.level == 'team'){
                    if(!team){ return undefined }
                    return { side_id: team.team_id, reversed_side_id: team.team_id }
                }
                return undefined
            default: return undefined
        }
    },
    getTradeFromAvailableOrder : (ba:BestAvailableOrderProps, reversed_side_id:string):TradeProps => {
        return {
            trade_id:'',
            event_id: ba.event_id,
            event_type: ba.event_type as 'tournament'|'team'|'match',
            cumulative_amt: 0,
            trade_amt: 0,
            create_datetime: '', last_update_datetime: '',
            market_id: ba.market_id,
            var_1: ba.var_1,
            side: ba.side,
            side_id: ba.side_id,
            draft_liquidity: ba.open_amt,
            reversed_side_id,
            probability: ba.probability,
            odds: ba.odds,
            side_type: ba.side_type as 'team'|'athlete'|'side',
            market_type: 'FOR_MONEY',
            latest: true,
            position_ids: []
        }
    },
    reverseBAOrder: (order:BestAvailableOrderProps, market:MarketProps, side_id:string):BestAvailableOrderProps | undefined => {
        const side_option = market.side_options.find(so => so.side != order.side);
        if(!side_option){ return undefined }
        return {
            ...order,
            side: side_option.side,
            side_id: side_id,
            odds: order.odds * -1,
            var_1: market.type == 'Spread' ? order.var_1 * -1 : order.var_1,
            probability: 1 - order.probability,
            title: '',
            open_amt: 0,
            called_amt: 0,
            potential_winnings: 0
        }
    },
    getTradeFromPrice:(price:ExternalPriceProps, reversed_side_id:string):TradeProps => {
        return {
            trade_id:'',
            event_type: price.event_type as TradeProps['event_type'],
            trade_amt: 0,
            side_type: price.participant_type as TradeProps['side_type'],
            cumulative_amt: 0,
            side: price.side,
            odds: price.odds,
            reversed_side_id: reversed_side_id,
            probability: price.probability,
            side_id: price.participant_id,
            position_ids: [],
            market_type:'FOR_MONEY',
            market_id: price.market_id,
            event_id: price.event_id,
            var_1: price.var_1,
            latest:true,
            create_datetime:'',
            last_update_datetime:''
        }
    },
    getVisibleTrade: (default_price_view:'best_available'|'last_trade', market:MarketProps, side_option:MarketSideOptionProps, latest_trades:TradeProps[], best_available_orders:BestAvailableOrderProps[], side_id:string, reversed_side_id:string) => {
        const order = best_available_orders.find(o => o.market_id == market.market_id && o.side == side_option.side && o.show);
        const o_side_order = best_available_orders.find(o => o.market_id == market.market_id && o.side != side_option.side && o.show);
        let trade = latest_trades.find(t => t.market_type == 'FOR_MONEY' && t.market_id == market.market_id && t.side == side_option.side);
        switch(default_price_view){
            case 'best_available':
                if(order){ return MarketMaketHelpers.getTradeFromAvailableOrder(order, reversed_side_id) }
                if(o_side_order){ 
                    //Now lets reverse this order and get a trade for it
                    const reversed_order = MarketMaketHelpers.reverseBAOrder(o_side_order, market, side_id);
                    if(!reversed_order){ return undefined }
                    return MarketMaketHelpers.getTradeFromAvailableOrder(reversed_order, reversed_side_id)
                 }
                if(trade){ return trade }
                return undefined
            case 'last_trade':
                if(trade){ return trade }
                return undefined
            default: return undefined
        }
    },
    getHedgesFromPositions: (orders:OrderProps[], markets:MarketProps[]) => {
    //1) Set up arrays to store hedges
        let hedges:HedgeProps[] = []
        let hedgeable_positions:Array<OrderProps[]> = []
        //Only allow real money orders for hedging
        orders = orders.filter(o => o.market_type === 'FOR_MONEY' && o.resolution_status !== 'closed')

        //2) Get the unique markets (and common var_1s) that are eligible to be hedged 
        let orders_with_positions = orders.filter(o => o.positions[0])
        let unique_markets = [ ...new Set(orders_with_positions.map(o => `${o.event_id}:${o.event_type}:${o.market_id}:${Math.abs(o.var_1)}`)) ]
    
        //3) For each unique market and var_1 combo, determin if there are positions on the opposite side of each other
        //If so, then store them into an array of orders
        unique_markets.map(id => {
            //1) Get orders that are associated with the market
            let market_orders = orders_with_positions.filter(o => `${o.event_id}:${o.event_type}:${o.market_id}:${Math.abs(o.var_1)}` == id)
            if(market_orders.length < 2){ return } //Impossible to hedge with less than 2 orders
            let unique_sides = [ ...new Set(market_orders.map(o => o.side)) ]
            if(unique_sides.length < 0){ return } //Must have more than 1 side

            hedgeable_positions.push(market_orders)
        })

        //Return nothing if there are no hedgeable positions
        if(hedgeable_positions.length === 0){ return [] }

        //Awesome! We have some hedgeable positions
        hedgeable_positions.map(hp => {
            if(!hp[0]){ return }
            //Get an empty hedge object to store our hedges
            let hedge = MarketMaketHelpers.getEmptyHedge()
            let market = markets.find(m => m.market_id == hp[0]?.market_id)
            if(!market){ return } //Error out if there is no market
            if(market.level !== 'event'){ return } //Dont if level is not event

            let trade_side_orders = hp.filter(o => o.side == market?.trade_side)
            let o_side_orders = hp.filter(o => o.side != market?.trade_side)
            
            //New
            if(market.type == 'Spread'){
                if(!trade_side_orders[0]){ return } //Make sure there is at least 1 trade side order
                let trade_side_o = trade_side_orders[0]
                if(!trade_side_o){ return }
                o_side_orders = o_side_orders.filter(o => o.var_1 == trade_side_o.var_1 * -1)
            }

            if(trade_side_orders.length === 0 || o_side_orders.length === 0){ return }



            hedge.market_id = market.market_id
            hedge.market_type = hp[0].market_type
            hedge.var_1 = Math.abs(hp[0].var_1)
            hedge.player_id = hp[0].player_id
            hedge.event_id = hp[0].event_id
            hedge.event_type = hp[0].event_type
            hedge.market_id = hp[0].market_id
        


            //Set up arrays to store the positions on each side of the hedge
            let trade_side_positions:PositionProps[] = []
            let o_side_positions:PositionProps[] = [] 
            trade_side_orders.map(o => {
                trade_side_positions = trade_side_positions.concat(o.positions)
            })
            o_side_orders.map(o => {
                o_side_positions = o_side_positions.concat(o.positions)
            })

            //Calculate the winnings from each outcome (side a wins, side b wins, or draw)
            let trade_side_pot_winnings = trade_side_positions.reduce((a,b) => a + b.potential_winnings, 0)
            let o_side_pot_winnings = o_side_positions.reduce((a,b) => a + b.potential_winnings, 0)
            let draw_winnings = trade_side_positions.concat(o_side_positions).reduce((a,b) => a + b.stake, 0)

            //Get the minimum payout possible of the 3 outcomes which is the hedged amount
            let hedged_amt = Math.min(trade_side_pot_winnings, o_side_pot_winnings)
            if(isNaN(hedged_amt)){ return }
            hedge.hedged_amt = hedged_amt

            //Now lets cals out hedged positions
            let hedged_positions:HedgedPositionProps[] = []
            
            trade_side_positions.map(p => {
                //Need to determine the weight of the position on the total potential winnings and reduce the stake proportionately
                let pct = p.potential_winnings / trade_side_pot_winnings
                //console.log(hedged_amt, pct, p.probability)
                let stake_from_hedge = hedged_amt * pct * p.probability
                hedge.stake_reduction += stake_from_hedge 
                let new_stake = p.stake - stake_from_hedge
                hedged_positions.push({
                    hedge_id:'',
                    hedged_position_id: '',
                    position_id: p.position_id,
                    original_stake: p.stake,
                    new_stake,
                    delayed_cash_draw: 0,
                    delayed_cash_no_draw: 0,
                    original_potential_winnings: p.potential_winnings,
                    new_potential_winnings: new_stake / p.probability,
                    status: 'pending',
                    create_datetime:'',
                    last_update_datetime:''
                })
            })

            o_side_positions.map(p => {
                let pct = p.potential_winnings / o_side_pot_winnings
                let stake_from_hedge = hedged_amt * pct * p.probability
                hedge.stake_reduction += stake_from_hedge 
                let new_stake = p.stake - stake_from_hedge
                hedged_positions.push({
                    hedge_id:'',
                    hedged_position_id: '',
                    position_id: p.position_id,
                    original_stake: p.stake,
                    new_stake,
                    delayed_cash_draw: 0,
                    delayed_cash_no_draw: 0,
                    original_potential_winnings: p.potential_winnings,
                    new_potential_winnings: new_stake / p.probability,
                    status: 'pending',
                    create_datetime:'',
                    last_update_datetime:''
                })
            })


            //Cash rcvd will be the lower of the hedged amount or the reduction in stake
            let cash_rcvd = Math.min(hedge.stake_reduction, hedged_amt, draw_winnings)
            if(isNaN(cash_rcvd) || cash_rcvd < 0.01){ return }
            hedge.cash_rcvd = cash_rcvd
            let delayed_cash = hedged_amt - hedge.stake_reduction
            let total_og_stake = hedged_positions.reduce((a,b) => a + b.original_stake, 0)
            let delayed_cash_draw = 0, delayed_cash_no_draw = 0;
            if(delayed_cash < 0){
                delayed_cash_draw = delayed_cash * -1
                //hedge.delayed_cash_draw = delayed_cash * -1 //Need to make positive
            } else {
                delayed_cash_no_draw = delayed_cash
                //hedge.delayed_cash_no_draw = delayed_cash
            }
            hedge.delayed_cash_draw = delayed_cash_draw
            hedge.delayed_cash_no_draw = delayed_cash_no_draw

            hedged_positions.map(hp => {
                let pct_of_total = hp.original_stake / total_og_stake
                hp.delayed_cash_draw = pct_of_total * delayed_cash_draw
                hp.delayed_cash_no_draw = pct_of_total * delayed_cash_no_draw
                return hp
            })

            hedges.push({ ...hedge, hedged_positions:hedged_positions })
        })

        return hedges
    },
    getEmptyHedge: ():HedgeProps => {
        return {
            hedge_id: '',
            hedged_amt: 0,
            event_id: '',
            event_type: '',
            market_id: '',
            var_1: 0,
            delayed_cash_draw: 0,
            delayed_cash_no_draw: 0,
            cash_rcvd: 0,
            stake_reduction: 0,
            market_type: 'FOR_MONEY',
            create_datetime: '',
            last_udpate_datetime:'',
            player_id: '',
            status: 'pending'
        }
    },
    getVar1Label: (market:MarketProps, var_1:number, side?:string) => {
        if(side == 'over'){ return `O ${var_1}` }
        if(side == 'under'){ return `U ${var_1}` }
        if(market.type != 'Spread'){ return `${var_1}` }
        if(var_1 < 0){ return `${var_1}` }
        return `+${var_1}`
    },
    getOddsLabel: (odds:number, decimals?:number) => {
        let new_odds = odds.toFixed()
        if(decimals){ new_odds = odds.toFixed(decimals) }
        if(odds < 0){ return `${new_odds}` }
        return `+${new_odds}`
    },
    getOrderTitleForTeamEvent : (order:OrderProps, market:MarketProps, event:EventProps, athlete?:AthleteProps, exotic?:any):string | undefined => {
        let market_side = market.side_options.find(o => o.side == order.side)
        if(!market_side){ return undefined }
        var re = /({.*?})/;
        let parsed_text = market_side.parseable_title.split(re)
        let new_title = '';
        parsed_text.map(t => {
            if(t === ''){ return }
            if(t[0] !== '{'){ return new_title += t }
            let variable = t.slice(1)
            variable = variable.slice(0, variable.length -1)
            switch(variable){
                case 'team': 
                    let team = event[order.side as keyof EventProps]
                    return new_title += team.market_name
                case 'var_1': return new_title += MarketMaketHelpers.getVar1Label(market, order.var_1)
                case 'athlete': return new_title += athlete?.abbr_name
                case 'stat': return new_title += market.stat_label ?? market.stat
                case 'exotic': return new_title += exotic?.name 
                default: return

            }
        })
        return new_title
    },
    getOrderTitleForTournament : (order:OrderProps, market:MarketProps, tournament:TournamentProps, athlete?:AthleteProps, team?:TeamProps):string | undefined => {
        if(!tournament){ return undefined }
        let market_side = market.side_options.find(o => o.side == order.side)
        if(!market_side){ return order.side }
        var re = /({.*?})/;
        let parsed_text = market_side.parseable_title.split(re)
        let new_title = '';
        parsed_text.map(t => {
            if(t === ''){ return }
            if(t[0] !== '{'){ return new_title += t }
            let variable = t.slice(1)
            variable = variable.slice(0, variable.length -1)
            switch(variable){
                case 'team': return new_title += `${team?.market_name} ${team?.name}`
                case 'var_1': return new_title += MarketMaketHelpers.getVar1Label(market, order.var_1)
                case 'athlete': return new_title += athlete?.abbr_name
                case 'stat': return new_title += market.stat 
                default: return

            }
        })
        return new_title
    },
    getOrderTitleForMatch : (order:OrderProps, market:MarketProps, match:MatchProps, athlete?:AthleteProps, team?:TeamProps):string | undefined => {
        if(!match){ return undefined }
        let market_side = market.side_options.find(o => o.side == order.side)
        if(!market_side){ return order.side }
        var re = /({.*?})/;
        let parsed_text = market_side.parseable_title.split(re)
        let new_title = '';
        parsed_text.map(t => {
            if(t === ''){ return }
            if(t[0] !== '{'){ return new_title += t }
            let variable = t.slice(1)
            variable = variable.slice(0, variable.length -1)
            switch(variable){
                case 'team': return new_title += `${team?.market_name} ${team?.name}`
                case 'var_1': return new_title += MarketMaketHelpers.getVar1Label(market, order.var_1)
                case 'athlete': return new_title += athlete?.abbr_name
                case 'stat': return new_title += market.stat 
                default: return

            }
        })
        return new_title
    },
    setMarkets: (fund:FundProps, best_available:BestAvailableOrderProps[], external_prices:ExternalPriceProps[], market:MarketProps, margin:number, event:EventProps) => {
        let orders:OrderProps[] = []
        market.side_options.map(so => {
            let side_id_response = MarketMaketHelpers.getTeamEventSideIds(event, market, so);
            if(!side_id_response){ return }
            let ba = best_available.find(ba => ba.side == so.side)
            let o_ba = best_available.find(ba => ba.side != so.side);
            if(ba && o_ba){
                let ba_trade = MarketMaketHelpers.getTradeFromAvailableOrder(ba, side_id_response.reversed_side_id);
                let ba_order = MarketMaketHelpers.getOrderFromTrade(ba_trade, 10, ba.order_id);
                let ba_title = MarketMaketHelpers.getOrderTitleForTeamEvent(ba_order, market, event);

                if(!ba_title){ return }
                return orders.push({ ...ba_order, title: ba_title })
            }
            let ep = external_prices.find(p => p.side == so.side);
            let o_ep = external_prices.find(p => p.side != so.side);
            if(ep && o_ep){
                console.log(ep)
                console.log(market)
                let ep_trade = MarketMaketHelpers.getTradeFromPrice(ep, side_id_response.reversed_side_id);
                let ep_order = MarketMaketHelpers.getOrderFromTrade(ep_trade, 10);
                let ep_title = MarketMaketHelpers.getOrderTitleForTeamEvent(ep_order, market, event);
                if(!ep_title){ return }
                return orders.push({ ...ep_order, title: ep_title })
            }
            return
        })

        if(orders.length != 2){ return [] }

        //OK!! Lets figured out the buffer odds!
        let no_vig_trade_price = .5
        let no_vig_o_price = .5
        let no_vig_data = MarketMaketHelpers.getNoVigPrice(external_prices, market);

        if(no_vig_data){
            no_vig_o_price = no_vig_data.o_side_prob
            no_vig_trade_price = no_vig_data.trade_side_prob
        }

        //Now lets add a buffer to each one based on the margin provided!
        no_vig_trade_price -= (margin * 0.5)
        let novig_trade_side_odds = Math.round(MarketMaketHelpers.calcAmericanOddsFromProbability(no_vig_trade_price))

        no_vig_o_price -= (margin * 0.5)

        let novig_o_side_odds = Math.round(MarketMaketHelpers.calcAmericanOddsFromProbability(no_vig_o_price))

        //OK!!! Now lets add the propbabilities to the orders!
        let trade_side_order = orders.find(o => o.side == market.trade_side);
        let o_side_order = orders.find(o => o.side != market.trade_side);
        if(!trade_side_order || !o_side_order){ return [] }

        let higher_probability_order = no_vig_trade_price >= no_vig_o_price ? 'trade' : 'other'
        let trade_side_stake = 0, o_side_stake = 0
        if(higher_probability_order == 'trade'){
            console.log('min was trade side')
            trade_side_stake = fund.working_capital
            //If that is the stake, then the potential winnigns is
            let pot_win = MarketMaketHelpers.calcPotentialWinnings(trade_side_stake, novig_trade_side_odds);
            o_side_stake = MarketMaketHelpers.calcSaleValue(novig_o_side_odds, pot_win);
        } else {
            console.log('min was o side!!')
            o_side_stake = fund.working_capital
            //If that is the stake, then the potential winnigns is
            let pot_win = MarketMaketHelpers.calcPotentialWinnings(o_side_stake, novig_o_side_odds);
            trade_side_stake = MarketMaketHelpers.calcSaleValue(novig_trade_side_odds, pot_win);
        }

        let o_side_working = o_side_stake / fund.working_capital
        let t_side_working = trade_side_stake / fund.working_capital

        o_side_order = {
            ...o_side_order,
            probability: no_vig_o_price,
            odds: novig_o_side_odds,
            fulfill_action: 'drain',
            expire_datetime: event.scheduled_datetime,
            working_pct:o_side_working,
            potential_winnings: MarketMaketHelpers.calcPotentialWinnings(o_side_stake, novig_o_side_odds),
            open_amt: o_side_stake,
        }
        trade_side_order = {
            ...trade_side_order,
            probability: no_vig_trade_price,
            odds: novig_trade_side_odds,
            fulfill_action: 'drain',
            expire_datetime: event.scheduled_datetime,
            working_pct: t_side_working,
            potential_winnings: MarketMaketHelpers.calcPotentialWinnings(trade_side_stake, novig_trade_side_odds),
            open_amt: trade_side_stake,
        }

        return [ o_side_order, trade_side_order ]
    },
    calcSaleValue: (odds:number, potential_winnings:number | string) => {
        potential_winnings = parseInt(potential_winnings as string);
        if(isNaN(potential_winnings)){ return 0 }
        let cash;
        if (odds > 0) {
            var earningsMultiplyer = (odds/100) + 1;
            cash = potential_winnings / earningsMultiplyer
        } else {
            var earningsMultiplyer = (100/(Math.abs(odds))) + 1;
            cash = potential_winnings / earningsMultiplyer
        }
        return cash
    },
    getNoVigPrice: (prices:ExternalPriceProps[], market:MarketProps) => {
        if(!prices || prices.length != 2){ return undefined }
        let external_name = prices[0]?.external_name
        if(!external_name){ return undefined }
        let total_probability = prices.reduce((a,b) => a + b.probability, 0);
        let vig_pct = total_probability - 1
        let trade_side_prob = prices.find(p => p.side == market.trade_side)?.probability
        let o_side_prob = prices.find(p => p.side != market.trade_side)?.probability
        if(!trade_side_prob || !o_side_prob){ return undefined }
        trade_side_prob -= (vig_pct * 0.5)
        o_side_prob -= (vig_pct * 0.5)

        let trade_side_odds = MarketMaketHelpers.calcAmericanOddsFromProbability(trade_side_prob);
        let o_side_odds = MarketMaketHelpers.calcAmericanOddsFromProbability(o_side_prob);

        return { external_name, trade_side_prob, o_side_prob, trade_side_odds, o_side_odds }
    },
    getAvailableVar1s: (my_orders:OrderProps[], available_orders:BestAvailableOrderProps[], prices:ExternalPriceProps[], market:MarketProps, backup_prices?:ExternalPriceProps[]) => {
        let default_var_1 = 0
        if(!market.var_1_required){ return { unique_var_1: [0], default_var_1 } }
        let trade_side_my_orders = my_orders.filter(o => o.side == market.trade_side);
        let o_side_my_orders = my_orders.filter(o => o.side != market.trade_side);
        
        if(prices.length == 0 && backup_prices){
            prices = backup_prices
        }

        let trade_side_avail = available_orders.filter(o => o.side == market.trade_side);
        let o_side_avail = available_orders.filter(o => o.side != market.trade_side);

        let trade_side_prices = prices.filter(p => p.side == market.trade_side);
        let o_side_prices = prices.filter(p => p.side != market.trade_side);

        let trade_side_var_1 = trade_side_my_orders.map(o => o.var_1).concat(trade_side_avail.map(o => o.var_1)).concat(trade_side_prices.map(p => p.var_1));
        let o_side_var_1 = o_side_my_orders.map(o => o.var_1).concat(o_side_avail.map(a => a.var_1)).concat(o_side_prices.map(p => p.var_1));



        if(market.type == 'Spread'){
            o_side_var_1 = o_side_var_1.map(var_1 => var_1 * -1)
        }
        let all_var_1 = trade_side_var_1.concat(o_side_var_1)
        let unique_var_1 = [ ... new Set(all_var_1.map(var_1 => var_1))]

        //Grade latest my order
        let my_order = my_orders.filter(o => o.status == 'approved').sort((a,b) => moment(b.create_datetime).unix() - moment(a.create_datetime).unix())[0]
        if(my_order){
            default_var_1 = my_order.var_1
            //Determin if we need to swap
            if(my_order.side != market.trade_side && market.type == 'Spread'){ default_var_1 = default_var_1 * -1 }
        } else {
            let price = trade_side_prices[0]
            if(price){ 
                default_var_1 = price.var_1
            } else if(unique_var_1[0]) {
                default_var_1 = unique_var_1[0]
            }
        }
        return { unique_var_1, default_var_1 }
    },
    filterByVar1: (var_1:number, my_orders:OrderProps[], available_orders:BestAvailableOrderProps[], prices:ExternalPriceProps[], market:MarketProps, side_type?:string, side_id?:string, backup_prices?:ExternalPriceProps[]) => {
        
        let o_var_1 = market.type == 'Spread' ? var_1 * -1 : var_1; 

        let trade_side_my_orders = my_orders.filter(o => o.side == market.trade_side && o.var_1 == var_1);
        let o_side_my_orders = my_orders.filter(o => o.side != market.trade_side && o.var_1 == o_var_1);
        
        let trade_side_avail = available_orders.filter(o => o.side == market.trade_side && o.var_1 == var_1);
        let o_side_avail = available_orders.filter(o => o.side != market.trade_side && o.var_1 == o_var_1);

        let trade_side_prices = prices.filter(p => p.side == market.trade_side && p.var_1 == var_1);
        let o_side_prices = prices.filter(p => p.side != market.trade_side && p.var_1 == o_var_1);

        if(trade_side_prices.length == 0 && backup_prices){
            trade_side_prices = backup_prices.filter(p => p.side == market.trade_side && p.var_1 == var_1 && p.market_id == market.market_id);
            o_side_prices = backup_prices.filter(p => p.side != market.trade_side && p.var_1 == o_var_1 && p.market_id == market.market_id);
        }

        if(market.level != 'event'){
            trade_side_my_orders = trade_side_my_orders.filter(so => so.side_type == side_type && so.side_id == side_id);
            o_side_my_orders = o_side_my_orders.filter(so => so.side_type == side_type && so.side_id == side_id);
            trade_side_avail = trade_side_avail.filter(ts => ts.side_type == side_type && ts.side_id == side_id);
            o_side_avail = o_side_avail.filter(os => os.side_type == side_type && os.side_id == side_id);
            trade_side_prices = trade_side_prices.filter(tsp => tsp.participant_type == side_type && tsp.participant_id == side_id);
            o_side_prices = o_side_prices.filter(osp => osp.participant_type == side_type && osp.participant_id == side_id);
        }

        return { var_1_orders: trade_side_my_orders.concat(o_side_my_orders), var_1_available: trade_side_avail.concat(o_side_avail), var_1_prices: trade_side_prices.concat(o_side_prices) }

    },
    calcPotentialWinnings : (amt:number, odds:number):number => {
        if (odds > 0) {
            var earningsMultiplyer = ((odds/100) + 1);
            return earningsMultiplyer * amt;
        } else {
            var earningsMultiplyer = ((100/(Math.abs(odds))) + 1);
            return earningsMultiplyer * amt
        }
    },
    calcProbabilityFromOdds : (odds:number) => {
        let prob;
        if(odds < 0) {
            odds = Math.abs(odds)
            prob = odds / (odds + 100) * 100
            return prob / 100
        } else {
            prob = 100 / (odds + 100) * 100
            return prob / 100
        }
    },
    getAvailableSideIds: (my_orders:OrderProps[], available_orders:BestAvailableOrderProps[], prices:ExternalPriceProps[], market:MarketProps) => {
        let default_side_id = '0';
        if(!['athlete','team','exotic'].includes(market.level)){ return { side_type: market.level, unique_side_ids: [], default_side_id } }
        let o_sides = my_orders.map(o => o.side_id);
        let avail_sides = available_orders.map(o => o.side_id);
        let price_sides = prices.map(p => p.participant_id);

        let unique_side_ids = o_sides.concat(avail_sides).concat(price_sides)
        unique_side_ids = [ ...new Set(unique_side_ids.map(id => id)) ]

        let my_order = my_orders[0]
        if(my_order){
            default_side_id = my_order.side_id
        } else if(available_orders[0]){
            default_side_id = available_orders[0].side_id
        } else if(prices[0]){
            default_side_id = prices[0].participant_id
        }

        return { side_type: market.level, unique_side_ids, default_side_id }

    }
}