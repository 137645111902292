import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Editor, EditorState, convertFromRaw, CompositeDecorator } from 'draft-js';
import 'draft-js/dist/Draft.css';
import type { RawDraftJSProps } from '../../../types';
import { rti_playerEntity, rti_tagEntity } from './DraftTextEntities';
import { rti_findPlayerEntities, rti_findTagEntities, createDraftJSFromText } from './EntityHelpers';

type DraftTextViewerProps = {
    post_id:string,
    text_fallback: string,
    formatted_body?: RawDraftJSProps,
    view_type: 'memo'|'comment'|'message'
}

function DraftTextViewer({ post_id, formatted_body, text_fallback }:DraftTextViewerProps) {
    const [ failed, setFailed ] = useState(false)
    const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const decorator = new CompositeDecorator([
    {
        strategy: rti_findPlayerEntities,
        component: rti_playerEntity,
        props: { test: 'hey!' }
    },
    {
        strategy: rti_findTagEntities,
        component: rti_tagEntity,
        props: { test: 'hey!' }
    }
]);


  useEffect(() => {
      if(formatted_body){
          let readContent = convertFromRaw(formatted_body as any)
          setEditorState(EditorState.createWithContent(readContent, decorator))
          setFailed(false)
      } else { 
            if(!text_fallback){ setFailed(true) }
            else { setEditorState(EditorState.createWithContent(createDraftJSFromText(text_fallback))); setFailed(false) }
       }
  },[post_id, formatted_body, text_fallback])


  if(failed){ return (<></>) }
  return (
      <View>
            <Editor
                readOnly 
                editorState={editorState} 
                onChange={setEditorState} /> 
      </View>
    
  )
}

export default DraftTextViewer

