import React, { useState } from 'react';
import { TouchableOpacity, View, FlatList } from 'react-native';
import { view_styles } from '../../constants/styles';
import type { CompetitionProps, CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionTypeProps } from '../../types';
import CompetitionCard from '../../Engage/components/CompetitionCard';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import moment from 'moment-mini';

type CompetitionsFormProps = {
    competition_season:CompetitionSeasonProps,
    competitions:CompetitionProps[],
    competition_types:CompetitionTypeProps[],
    competition_result_types:CompetitionResultTypeProps[],
    onAddCompetition:() => void,
    onCompetitionSelect:(c:CompetitionProps) => void
}

const CompetitionsForm = ({ competition_season, competitions, competition_result_types, competition_types, onAddCompetition, onCompetitionSelect }:CompetitionsFormProps) => {
    const [ expanded, setExpanded ] = useState(true);
    const [ active_tab, setActiveTab ] = useState('active');

    let filtered_comptitions = [ ...competitions ]
    if(active_tab == 'active'){ filtered_comptitions = filtered_comptitions.filter(c => c.status != 'closed') }
    else { filtered_comptitions = filtered_comptitions.filter(c => c.status == 'closed') }

    const renderCompetitions = (data:{item:CompetitionProps, index:number}) => {
        const competition_type = competition_types.find(ct => ct.competition_type_id == data.item.competition_type_id)
        const competition_result_type = competition_result_types.find(crt => crt.competition_result_type_id == data.item.competition_result_type_id);
        if(!competition_type || !competition_result_type){ return <></> }
        return (
            <View style={{ margin:4 }}>
                <CompetitionCard
                    competition={data.item}
                    competition_result_type={competition_result_type}
                    competition_type={competition_type}
                    onCompetitionSelect={(comp) => onCompetitionSelect(comp)}
                />
            </View>
        )
    }

    return (
        <View style={{ ...view_styles.section }}>
            <TouchableOpacity style={{ ...view_styles.section_header }} onPress={() => setExpanded(!expanded)}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Competitions</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Below are the competitions that are included in this season</Text>
                </View>
                <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} size={8} color={Colors.brand.midnight} />
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body, padding:0 }}>
                <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                    <View style={{ flex:1 }}>
                        <Text theme='header_2'>{active_tab == 'active' ? 'Active Competitions' : 'Closed Competitions'}</Text>
                    </View>
                    <Button
                        title='ADD'
                        style={{ opacity: competition_season.following_season_id ? 0.5: 1 }}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.utility.success}
                        onPress={() => {
                            if(competition_season.following_season_id){ return alert('Competitions are created based on the template season and cannot be customized') }
                            onAddCompetition()
                        }}
                    />
                </View>
                <View style={{ ...view_styles.body_row, padding:0, margin:10, backgroundColor:Colors.shades.white, borderRadius:22, borderWidth:4, borderColor:Colors.shades.shade100}}>
                <Button
                    title='ACTIVE'
                    title_color={active_tab == 'active' ? Colors.shades.white : Colors.brand.midnight}
                    title_weight={active_tab == 'active' ? 'bold' : 'regular'}
                    padding={15}
                    title_size={12}
                    style={{flex:1}}
                    borderRadiusOverride={{
                        borderTopLeftRadius: 22,
                        borderBottomLeftRadius:22,
                        borderTopRightRadius:0,
                        borderBottomRightRadius:0
                    }}
                    backgroundColor={active_tab == 'active' ? Colors.brand.midnight : Colors.shades.white}
                    onPress={() => setActiveTab('active')}
                />
                <Button
                    title='CLOSED'
                    title_size={12}
                    title_color={active_tab == 'closed' ? Colors.shades.white : Colors.brand.midnight}
                    title_weight={active_tab == 'closed' ? 'bold' : 'regular'}
                    padding={15}
                    style={{flex:1}}
                    borderRadiusOverride={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius:0,
                        borderTopRightRadius:22,
                        borderBottomRightRadius:22
                    }}
                    backgroundColor={active_tab == 'closed' ? Colors.brand.midnight : Colors.shades.white}
                    onPress={() => setActiveTab('closed')}
                />
            </View>
                <FlatList
                    data={filtered_comptitions.sort((a,b) => moment(b.scheduled_datetime).unix() - moment(a.scheduled_datetime).unix())}
                    renderItem={renderCompetitions}
                    keyExtractor={(item) => item.competition_id.toString()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default CompetitionsForm