import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View, ActivityIndicator } from "react-native"
import Text from '../../../Components/Text';
import type { BestAvailableOrderProps, MarketProps, MarketSideOptionProps, OrderProps, TradeProps } from '../../../types';
import Colors from '../../../constants/colors';
import { MarketButtonHelpers } from './api';
import OrderGradeBar from '../OrderGradeBar';
import { Icons } from '../../../Components';

type MarketButtonProps = {
    market:MarketProps,
    side_option:MarketSideOptionProps,
    side_label?:string,
    locked?:boolean,
    order?:BestAvailableOrderProps,
    trade:TradeProps,
    show_grades?:boolean,
    onPress: (order:OrderProps) => void,
    onLongPress:(trade:TradeProps) => void
}
const MarketCard = ({ side_option, locked, show_grades, market, trade, order, side_label, onPress, onLongPress }:MarketButtonProps) => {
    const [ updated, setUpdated ] = useState<{
        iteration: number,
        iteration_trade?:TradeProps,
        loading:boolean
    }>({
        iteration: 1,
        loading: false
    });
    const { iteration, loading, iteration_trade } = updated;

    const { var_1, odds, color } = MarketButtonHelpers.getButtonLabel(market, side_option, trade);
    const handlePress = () => {
        return onPress(MarketButtonHelpers.getOrderFromTrade(trade, undefined, order?.order_id))
    }

    useEffect(() => {
        if(iteration == 1){ return setUpdated({ iteration: 2, loading: false, iteration_trade:trade }) }
        if(JSON.stringify(trade) != JSON.stringify(iteration_trade)){
            setUpdated({ ...updated, loading:true })
            setTimeout(() => {
                setUpdated({ iteration: 2, loading:false, iteration_trade: trade })
            }, 600);
        }
    },[trade])

    return (
        <TouchableOpacity 
            style={{ flex:1, backgroundColor:color, borderRadius:4}}
            onPress={() => handlePress()}
            disabled={loading || locked ? true : false}
            onLongPress={() => onLongPress({ ...trade, market:market })}
            >
            <View style={{ flex:1, padding:5, justifyContent:'center'  }}>
                {!market.var_1_required && market.show_side_option ?
                <Text size={12} color={Colors.brand.midnight} weight='regular' textAlign='center'>{side_label?side_label:side_option.label.toUpperCase()}</Text>
                :<></>}
                {market.var_1_required ?
                <Text size={12} color={Colors.brand.midnight} weight='regular' textAlign='center'>{var_1}</Text>
                :<></>}
                <Text style={{ marginTop:2 }} size={12} color={Colors.brand.midnight} weight='semibold' textAlign='center'>{odds}</Text>
            </View>
            {order ?
                <OrderGradeBar
                    grade={order.grade}
                    view_type='market'
                    mask={!show_grades}
                />
            :<></>}
            {loading ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                <ActivityIndicator size='small' color={Colors.shades.white} />
            </View>
            :<></>}
            {locked ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.black_faded_super_heavy, borderRadius:4 }}>
                <Icons.LockClosedIcon size={22} color={Colors.utility.error} />
            </View>
            :<></>}
        </TouchableOpacity>
    )
}

export default React.memo(MarketCard, (left, right) => {
    if(JSON.stringify(left) != JSON.stringify(right)){ return false }
    return true
})