import React, { useState } from 'react';
import { View, TouchableOpacity, FlatList, Image } from 'react-native';
import { view_styles } from '../../constants/styles';
import { Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import type { EventProps, PublicPlayerProps, SquarePrizeProps, SquareProps } from '../../types';

type PrizeCardProps = {
    square_prizes:SquarePrizeProps[]
    event?:EventProps,
    squares: SquareProps[],
    players:PublicPlayerProps[]
}

const PrizeCard = ({ event, square_prizes, players, squares }:PrizeCardProps) => {
    const [ expanded, setExpanded ] = useState(false);

    const renderPrizes = (data: { item:SquarePrizeProps, index:number }) => {
        const square = squares.find(s => s.sq_square_id == data.item.sq_square_id)
        const winner = players.find(p => p.player_id == data.item.winner_id)
        if(!square){ return <></> }
        return (
            <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                <Image
                    source={{ uri: data.item.image.url }}
                    style={{ height: 40, width:40, borderRadius:4 }}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header_2'>{data.item.description}</Text>
                    {winner ?
                    <Text style={{marginTop:3}} size={12} color={Colors.incentive.gold} weight={'bold'}>Winner: @{winner.username}</Text>
                    :<></>}
                </View>
                {data.item.status == 'active' ?
                <View style={{ padding:10 }}>
                    <Icons.UnbidSquare color={event?.home?.primary_color} secondary_color={event?.away?.primary_color} size={20} />
                    <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                        <View style={{ backgroundColor:Colors.shades.white, borderRadius:4, height:25, width:25, justifyContent:'center', alignItems:'center' }}>
                            <Text theme='header'>?</Text>
                        </View>
                    </View>
                </View>
                :data.item.status == 'awarded'?
                <View style={{ padding:10, flexDirection:'row', alignItems:'center' }}>
                    {winner ?
                    <Image
                        source={{ uri: winner.profile_pic && winner.profile_pic != '' ? winner.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                        style={{ height:40, width:40, borderRadius:4, marginRight:10 }}
                        resizeMode='cover'
                    />
                    :<></>}
                    <View>
                        <Icons.UnbidSquare color={Colors.shades.shade100} secondary_color={Colors.shades.shade100} size={20} />
                        <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                            <Text>{square.square_score_x}, {square.square_score_y}</Text>
                        </View>
                    </View>
                </View>
                :<></>}
            </View>
        )
    }

    return (
        <View style={{ ...view_styles.section, minWidth:350 }}>
            <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                <Icons.AwardRibbonIcon size={18} color={Colors.incentive.gold} />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>HIDDEN PRIZES</Text>
                    <Text style={{ marginTop:3 }} theme="body_2">This board contains some hidden prizes! Check them out here!</Text>
                </View>
                <Icons.ChevronIcon direction={true?'down':'up'} size={8} color={Colors.brand.midnight} />
            </TouchableOpacity>
            {expanded ?
            <View style={view_styles.section_body}>
                <FlatList
                    data={square_prizes.sort((a,b) => parseInt(a.square_prize_id) - parseInt(b.square_prize_id))}
                    renderItem={renderPrizes}
                    keyExtractor={(item) => item.square_prize_id.toString()}
                />
                 <View style={{ marginTop:10, flexDirection:'row', alignItems:'center', borderRadius:8, backgroundColor:Colors.incentive.gold_faded, padding:10 }}>
                    <Icons.AlertIcon color={Colors.brand.midnight} size={16} />
                    <View style={{ flex:1, marginLeft:5 }}>
                        <Text theme='header_2' color={Colors.brand.midnight}>Prizes are hidden behind random squares on the board. End up with this square after the auction period and win the prize!</Text>
                        <Text style={{ marginTop:3 }} theme='body' color={Colors.brand.midnight}>Square will be visible once the auction period ends</Text>
                    </View>
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default PrizeCard 