import React, { useEffect, useState } from 'react';
import { View, FlatList, ActivityIndicator } from 'react-native';
import type { BestAvailableOrderProps, BestAvailableResponseProps, EventOrderStatProps, EventProps, MarketProps, OrderProps, TradeProps } from '../../../types';
import TeamEventMarket from '../TeamEventMarket';
import { MarketComponentApi } from '../../api';
import { TeamEventListHelpers } from './api';
import SocketManager from '../../../Socket';
import Colors from '../../../constants/colors';

type TeamEventListProps = {
    list_key:string //Change key to reload data
    events:EventProps[],
    distinct_id?:string,
    show_grades?:boolean,
    show_id?:boolean,
    hide_liquidity?:boolean,
    init_expanded_ids?:string[],
    onTeamSelect?:(team_id:string) => void,
    onActivate?:(event_id:string, event_type:string) => void,
    onViewEvent?:(data:{ event_id:string, event_type:string, market_id:string, side_id?:string, side_type?:string }) => void,
    onOrder?:(o:OrderProps) => void,
    onCompetitionSelect?:(competition_id:string) => void,
    onSquaresSelect?:(sq_comp_id:string) => void,
    onTradeLongPress?:(trade:TradeProps) => void,
    onViewAdditionalMarkets?:(event_id:string, event_type:string) => void
}

const TeamEventList = ({ events, show_grades, show_id, hide_liquidity, list_key, distinct_id, init_expanded_ids, onTeamSelect, onViewEvent, onOrder, onCompetitionSelect, onSquaresSelect, onTradeLongPress, onViewAdditionalMarkets, onActivate }:TeamEventListProps) => {
    const [ needs_reload, setNeedsReload ] = useState(false);
    const [ socket_response, setSocketResponse ] = useState<{ event_ids:string[], ba_response:BestAvailableResponseProps } | undefined>(undefined);
    const [ event_data, setListData ] = useState<{
        loading:boolean,
        markets:MarketProps[],
        latest_trades: TradeProps[],
        available_orders: BestAvailableOrderProps[],
        order_stats:EventOrderStatProps[]
    }>({
        loading:false,
        latest_trades: [],
        markets:[],
        available_orders: [],
        order_stats: []
    });
    const { loading, available_orders, order_stats, latest_trades, markets } = event_data;

    useEffect(() => {
        MarketComponentApi.setEnvironment();
        if(events.length > 0){ getDataFromServer() }
    },[list_key, events.length]);

    useEffect(() => {
        if(!socket_response){ return }
        const updated_data = TeamEventListHelpers.getEventMarketDataFromBestAvailable(socket_response.ba_response, events);
        if(!updated_data.updated){ return }
        setListData({
            ...event_data,
            order_stats: order_stats.filter(os => !socket_response.event_ids.find(new_id => new_id == os.event_id)).concat(updated_data.event_order_stats),
            available_orders: available_orders.filter(os => !socket_response.event_ids.find(new_id => new_id == os.event_id)).concat(updated_data.available_orders),
            latest_trades: latest_trades.filter(lt => !socket_response.event_ids.find(new_id => new_id == lt.event_id)).concat(updated_data.latest_trades)
        })
    },[socket_response])

    const getDataFromServer = async() => {
        setListData({ ...event_data, loading:true });
        const ba = await MarketComponentApi.getBestAvailableOrders();
        if(!ba){ return }
        const lt = await MarketComponentApi.getLatestTradesByEvents('team', events.map(e => e.event_id));
        const ev_data = TeamEventListHelpers.getEventMarketDataFromBestAvailable(ba, events);
        const maks = await MarketComponentApi.getMarkets();
        setListData({
            ...event_data,
            loading:false,
            latest_trades: lt,
            markets: maks,
            order_stats: ev_data.event_order_stats,
            available_orders: ev_data.available_orders
        });
    }

    const renderEvents = (data: { item:EventProps, index:number }) => {
        const ba = available_orders.filter(o => o.event_id == data.item.event_id && o.event_type == 'team');
        const order_st = order_stats.filter(os => os.event_id == data.item.event_id && os.event_type == 'team');
        const lts = latest_trades.filter(lt => lt.event_id == data.item.event_id && lt.event_type == 'team');
        let init_expanded = false
        if(init_expanded_ids && init_expanded_ids.includes(data.item.event_id)){ init_expanded = true }
        return (
            <TeamEventMarket
                event={data.item}
                show_grades={show_grades}
                show_id={show_id ? 'true' : undefined}
                markets={markets}
                init_expanded={init_expanded}
                latest_trades={lts}
                best_available_orders={ba}
                hide_liquidity={hide_liquidity}
                onTeamSelect={onTeamSelect}
                default_price_view='best_available'
                event_order_stats={order_st}
                onActivate={onActivate ? onActivate : undefined}
                onView={(ev) => {
                    if(onViewEvent){ onViewEvent(ev) }
                }}
                onOrder={(o) => onOrder ? onOrder(o) : console.log('')}
                onCompetitionSelect={(c) => onCompetitionSelect ? onCompetitionSelect(c) : console.log('')}
                onSquaresSelect={(s) => onSquaresSelect ? onSquaresSelect(s) : console.log(s)}
                onViewAdditionalMarkets={(event_id, event_type) => onViewAdditionalMarkets ? onViewAdditionalMarkets(event_id, event_type) : console.log('')}
                onTradeLongPress={(t) => onTradeLongPress ? onTradeLongPress(t) : console.log(t)}
            />
        )
    }

    return (
        <View>
            {loading ?
                <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
            :<></>}
            <FlatList
                key={'events_list'}
                data={events}
                renderItem={renderEvents}
                keyExtractor={(item) => item.event_id.toString()}
            />
            <SocketManager
                distinct_id={distinct_id}
                onConnect={() => {
                    if(needs_reload){
                        getDataFromServer();
                        setNeedsReload(false);
                    }
                }}
                onDisconnect={() => setNeedsReload(true)}
                onSocketEvent={(ev) => {
                    if(ev.type != 'V1_LOAD_BEST_AVAILABLE'){ return }
                    let event_ids = ev.data.events.map((e:EventProps) => e.event_id);
                    setSocketResponse({ event_ids, ba_response: ev.data });
                }}
                subscribed_events={['V1_LOAD_BEST_AVAILABLE']}
            />
        </View>
    )
}

export default TeamEventList