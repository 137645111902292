import React, { useEffect, useState } from 'react';
import { PollApi, PollCampaignApi, PollResponseApi } from './api';
import { View, ScrollView, ActivityIndicator } from 'react-native';
import type { BEEventProps, PollCampaignGoalProps, PollCampaignProps, PollOptionProps, PollProps, PollResponseProps } from '../types';
import Colors from '../constants/colors';
import CampaignHeader from './components/CampaignHeader';
import CampaignProgressBar from './components/CampaignProgressBar';
import PollQuestionsSection from './components/PollQuestionsSection';
import { Spring } from '../Components';
//import PollCard from './components/PollCard';
import CampaignResult from './components/CampaignResult';
import CampaignLeaderboard from './components/CampaignLeaderboard';
import PollCard from './components/PollCard';
import SocketManager from '../Socket';

type PollCampaignModuleProps = {
    poll_campaign_id:string,
    poll_id?:string,
    player_id?:string,
    distinct_id:string,
    height:number,
    onEvent?:(be_event:BEEventProps) => void,
    onRequestAuthenticate: (auth_strategy_id?:string, company_id?:string) => void,
    onSharePollCampaign: (poll_campaign:PollCampaignProps) => void,
    onComplete:() => void
}
const PollCampaign = ({ height, distinct_id, poll_campaign_id, player_id, onRequestAuthenticate }: PollCampaignModuleProps ) => {
    const [ needs_reload, setNeedsReload ] = useState(false);
    const [ socket_polls, setSocketPolls ] = useState<PollProps[]>([]);
    //const [ socket_poll_options, setSocketPollOptions ] = useState<PollOptionProps[]>([]);
    const [ socket_campaign, setSocketCampaign ] = useState<PollCampaignProps|undefined>(undefined);
    const [ module_data, setModuleData ] = useState<{
        loading: boolean,
        active_poll_id?:string,
        poll_campaign?:PollCampaignProps,
        polls:PollProps[],
        poll_campaign_goals:PollCampaignGoalProps[]
    }>({
        loading: false,
        polls: [],
        poll_campaign_goals:[]
    });
    const [ full_leaderboard, setFullLeaderboard ] = useState(false);
    const [ my_data, setMyData ] = useState<{
        action_loading:boolean,
        poll_responses:PollResponseProps[]
    }>({
        action_loading: false,
        poll_responses: []
    });
    const { poll_responses, action_loading } = my_data;
    const { poll_campaign, polls, active_poll_id } = module_data;
    const active_response = poll_responses.find(r => r.poll_id == active_poll_id);
    const active_polls = polls.filter(p => p.status != 'inactive').sort((a,b) => a.priority - b.priority);

    useEffect(() => {
        PollCampaignApi.setEnvironment();
        getDataFromServer();
    },[poll_campaign_id, player_id])

    useEffect(() => {
        if(socket_polls.length == 0){ return }
        setModuleData({
            ...module_data,
            polls: polls.filter(p => !socket_polls.find(sp => sp.poll_id == p.poll_id)).concat(socket_polls)
        })
    },[socket_polls])

    useEffect(() => {
        if(!socket_campaign){ return }
        setModuleData({
            ...module_data,
            poll_campaign: socket_campaign
        })
    },[socket_campaign]);

    const getDataFromServer = async() => {
        console.log('RELOADING DATA!!!')
        setModuleData({ ...module_data, loading:true });
        const pc = await PollCampaignApi.getPollCampaignById(poll_campaign_id);
        const ps_resp = await PollApi.getPollsByCampaignId(poll_campaign_id);
        if(player_id){
            let my_responses = await PollResponseApi.getMyCampaignResponses(poll_campaign_id);
            setMyData({
                ...my_data,
                poll_responses: my_responses
            })
        }
        setModuleData({
            ...module_data,
            poll_campaign: pc,
            polls: ps_resp.polls,
            poll_campaign_goals: ps_resp.poll_campaign_goals, 
            loading:false
        })
    }

    const handleSubmitResponse = async(pr:PollResponseProps) => {
        if(!player_id){ return onRequestAuthenticate(poll_campaign?.auth_strategy_id, poll_campaign?.company_id) }
        try {
            if(action_loading){ return }
            setMyData({ ...my_data, action_loading: true })
            let new_pr = await PollResponseApi.createPollResponse(pr);
            setMyData({
                ...my_data,
                poll_responses: poll_responses.filter(pr => pr.poll_response_id != new_pr.poll_response_id).concat(new_pr)
            })
        } catch (e) {
            alert('Unable to process!')
        }
    }

    const handleNext = async() => {
        if(!active_poll_id){ return }
        let active_index = active_polls.findIndex(p => p.poll_id == active_poll_id);
        let new_poll = active_polls.find((p,i) => i > active_index && ['active','closed'].includes(p.status));
        if(!new_poll){
            setModuleData({ ...module_data, active_poll_id: undefined })
            setFullLeaderboard(true);
            return
        }
        setModuleData({ ...module_data, active_poll_id: new_poll.poll_id })
    }

    if(!poll_campaign){
        return (
            <View style={{ flex:1 }}>
                <ActivityIndicator
                    style={{ padding:20, alignSelf:'center' }}
                    size='large'
                    color={Colors.brand.midnight}
                />
            </View>
        )
    }

    return (
        <View style={{ backgroundColor:Colors.shades.white, height }}>
            <CampaignHeader
                poll_campaign={poll_campaign}
                onSharePollCampaign={(pc) => console.log(pc)}
            />
            <ScrollView style={{ flex:1 }}>
                <View style={{ backgroundColor:Colors.shades.shade100 }}>
                    <CampaignProgressBar
                        poll_campaign={poll_campaign}
                        polls={active_polls}
                        active_poll='0'
                        onPollSelect={(p) => console.log(p)}
                        poll_responses={poll_responses}
                    />
                </View>
                <View nativeID='leaderboard'>
                    <CampaignResult
                        poll_campaign={poll_campaign}
                        onShowFullLeaderboard={() => setFullLeaderboard(true)}
                    />
                </View>
                <View nativeID='poll_questions'>
                        <PollQuestionsSection
                            poll_campaign={poll_campaign}
                            polls={active_polls}
                            poll_responses={poll_responses}
                            onSelectPoll={(p) => {
                                if(!player_id){ return onRequestAuthenticate(poll_campaign.auth_strategy_id, poll_campaign.company_id) }
                                if(p.status == 'paused'){ return alert('This question is currently paused. Please open when it becomes active') }
                                setModuleData({ ...module_data, active_poll_id: p.poll_id })
                            }}
                        />
                </View>
            </ScrollView>
            {active_poll_id ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'flex-end', backgroundColor:Colors.shades.black_faded }}>
                <Spring
                    slide='vertical'
                    to={0}
                    from={500}
                    style={{ maxHeight:height }}
                >
                    <PollCard
                        poll_id={active_poll_id}
                        action_loading={action_loading}
                        poll_response={active_response}
                        onSkip={() => handleNext()}
                        onCancel={() => setModuleData({ ...module_data, active_poll_id: undefined })}
                        onResponse={(response) => handleSubmitResponse(response)}
                        onShowAuthenticate={() => console.log('authenticate!!')}
                    />
                </Spring>
            </View>
            :<></>}
            {full_leaderboard ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'flex-end', backgroundColor:Colors.shades.black_faded }}>
                <Spring
                    slide='vertical'
                    to={0}
                    from={500}
                    style={{ maxHeight:height }}
                >
                    <CampaignLeaderboard
                        height={height}
                        poll_campaign={poll_campaign}
                        view_mode='full'
                        onClose={() => setFullLeaderboard(false)}
                    />
                </Spring>
            </View>
            :<></>}
            <SocketManager 
                onConnect={() => {
                    if(needs_reload){
                        setNeedsReload(false);
                        getDataFromServer();
                    }
                }}
                onDisconnect={() => setNeedsReload(true)}
                distinct_id={distinct_id}
                onSocketEvent={(ev) => {
                    switch(ev.type){
                        case 'UPDATE_POLL_CAMPAIGNS':
                            if(ev.poll_campaign.poll_campaign_id != poll_campaign_id){ return } //This is not our poll!
                            setSocketCampaign(ev.poll_campaign)
                            break;
                        case 'UPDATE_POLLS':
                            if(ev.polls.length == 0){ return }
                            if(!ev.polls[0]){ return }
                            if(ev.polls[0].poll_campaign_id != poll_campaign_id){ return }//This is not our poll campaign!
                            setSocketPolls(ev.polls)
                            break
                        case 'UPDATE_POLL_OPTIONS':
                            if(ev.poll_options.length == 0){ return }
                            let existing_polls = polls.filter(p => ev.poll_options.map((o:PollOptionProps) => o.poll_id.toString()).includes(p.poll_id.toString()))
                            if(existing_polls.length == 0){ return } //No our poll!
                            //setSocketPollOptions(ev.poll_options)
                            break
                        default: break;
                    }
                }}
                subscribed_events={['UPDATE_POLL_CAMPAIGNS', 'UPDATE_POLLS','UPDATE_POLL_OPTIONS']}
            />
        </View>
    )
    

}

export default PollCampaign