import React, { useState } from 'react';
import { View, Image, TouchableOpacity, Linking } from "react-native";
import { Button, Checkbox, Text } from '../../Components';
import type { BracketCompetitionProps, CompetitionPlayerBracketProps, MyPlayerProps, PlayerBalanceProps, PlayerBracketProps } from '../../types';
import { BracketCompetitionHelpers } from '../api';
import Colors from '../../constants/colors';
import PlayerBalanceCard from './PlayerBalanceCard';

type JoinCompetitionCardProps = {
    player?:MyPlayerProps,
    loading?:boolean,
    player_bracket:PlayerBracketProps,
    player_balance?:PlayerBalanceProps,
    bracket_competition:BracketCompetitionProps,
    onNotEnoughBalance:(data:{ bracket_competition:BracketCompetitionProps }) => void,
    onEnterCompetition:(cpb:CompetitionPlayerBracketProps) => void,
    onClose:() => void
}

const JoinCompetitionCard = ({ player, player_balance, loading, player_bracket, bracket_competition, onNotEnoughBalance, onEnterCompetition, onClose }:JoinCompetitionCardProps) => {
    const [ disclaimed, setDisclaimed ] = useState(true);

    const buy_in_label = BracketCompetitionHelpers.getBuyInTitle(bracket_competition)

    const handleEnter = () => {
        const is_enough_balance = BracketCompetitionHelpers.isEnoughBalance(bracket_competition, player_balance);
        if(!is_enough_balance){ return onNotEnoughBalance({ bracket_competition }) }
        const cpb = BracketCompetitionHelpers.generateCompetitionPlayerBracket(bracket_competition.bracket_competition_id, player_bracket.player_bracket_id)
        onEnterCompetition(cpb)
    }

    return (
        <View style={{ minWidth:300, backgroundColor:Colors.shades.white, borderRadius:8 }}>
            <View style={{ borderTopRightRadius:8, borderTopLeftRadius:8, backgroundColor:Colors.shades.shade100, padding:15 }}>
                <Text size={16} color={Colors.brand.midnight} weight='bold'>{bracket_competition.competition_name}</Text>
            </View>
            {player && player_balance ?
            <View style={{ padding:5, backgroundColor:Colors.incentive.gold_faded }}>
                <PlayerBalanceCard 
                    player={player}
                    player_balance={player_balance}
                />
            </View>
            :<></>}
            <View style={{ justifyContent:'center', alignItems:'center' }}>
                <Image
                    source={{ uri: bracket_competition.image?.url }}
                    style={{ height:240, width:240 }}
                    resizeMode='cover'
                />
            </View>
            <View style={{ flexDirection:'row', alignItems:'center', padding:10 }}>
                <View style={{ flex:1 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{player_bracket.bracket_name}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate} weight='regular'>{(player_bracket.completion_pct*100).toFixed(2)}% Complete</Text>
                </View>
            </View>
            {bracket_competition.opt_in_url ?
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:20, backgroundColor:Colors.shades.shade100 }} onPress={() => setDisclaimed(!disclaimed)}>
                <Checkbox size={24} checked={disclaimed} disabled onSelect={() => console.log('')}/>
                <Text color={Colors.brand.midnight} weight='regular' size={14} style={{ flex:1, marginLeft:10 }}>I agree to the terms and conditiions outlined <TouchableOpacity onPress={() => Linking.openURL(bracket_competition.opt_in_url??'')}><Text size={14} color={Colors.brand.electric} weight='bold'>HERE</Text></TouchableOpacity></Text>
            </TouchableOpacity>
            :<></>}
            <View style={{ flexDirection:'row', alignItems:'center', padding:10, backgroundColor:Colors.shades.shade100, borderBottomRightRadius:8, borderBottomLeftRadius:8 }}>
                <Button
                    title='Close'
                    style={{ flex:1, marginRight:5 }}
                    padding={15}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onClose()}
                />
                <Button
                    title={buy_in_label}
                    disabled={loading || !disclaimed}
                    style={{ flex:2, opacity: !disclaimed||loading?0.5:1  }}
                    loading={loading}
                    padding={15}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => handleEnter()}

                />
            </View>
        </View>
    )
}

export default JoinCompetitionCard