import React, { useState, useEffect } from 'react';
import type { PublicPlayerProps } from '../../types';
import { FlatList, ScrollView, TouchableOpacity, View, Image, ActivityIndicator } from 'react-native';
import SearchBox from '../../Components/SearchBox';
import { view_styles } from '../../constants/styles';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { ManageCompetitionApi } from '../api';
import moment from 'moment-mini';

type PlayerSelectorProps = {
    width: number,
    height:number,
    onSelectPlayer: (player:PublicPlayerProps) => void,
    onClose: () => void
}

const PlayerSelector = ({ width, height, onSelectPlayer, onClose }:PlayerSelectorProps) => {
    const [ search_value, setSearchValue ] = useState('');
    const [ selector_data, setData ] = useState<{
        loading:boolean,
        offset:number,
        players:PublicPlayerProps[]
    }>({
        loading: false,
        offset:0,
        players:[]
    })
    const { loading, offset, players } = selector_data;

    useEffect(() => {
        searchPlayers(0)
    },[])

    const searchPlayers = async(offset:number) => {
        setData({ ...selector_data, loading:true })
        const ps = await ManageCompetitionApi.searchPlayers(search_value, offset);
        setData({
            ...selector_data,
            players: ps,
            loading:false,
            offset: offset
        })
    }

    const renderPlayers = (data:{item:PublicPlayerProps, index:number}) => {
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onSelectPlayer(data.item)}>
                <Image
                    source={{ uri: data.item.profile_pic && data.item.profile_pic != '' ? data.item.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:40, width:40, borderRadius:4 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header_2">@{data.item.username}</Text>
                    <Text style={{ marginTop:3 }} theme="body">Joined {moment(data.item.create_datetime).format('MMM YYYY')}</Text>
                </View>
                <Icons.ChevronIcon direction="right" color={Colors.brand.midnight} size={8} />
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ width, height, backgroundColor:Colors.shades.white }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>MANAGE MARKETS</Text>
                </View>
            </View>
            <ScrollView style={{ flex:1 }}>
                <View style={{ ...view_styles.section_body }}>
                    <View style={{ marginTop:0 }}>
                        <SearchBox 
                            onSearch={() => searchPlayers(0)}
                            onChange={(text) => setSearchValue(text)}
                        />
                    </View>
                    {loading ?
                    <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
                    :<></>}
                    <FlatList
                        data={players}
                        renderItem={renderPlayers}
                        keyExtractor={(item) => item.player_id.toString()}
                    />
                </View>
                <View style={{ padding:10, flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                    {offset > 0 ?
                    <Button
                        title='PREV'
                        title_color={Colors.brand.electric}
                        backgroundColor='transparent'
                        onPress={() =>  searchPlayers(offset - 1)}
                    />
                    :<View/>}
                    <Button
                        title='NEXT'
                        title_color={Colors.brand.electric}
                        backgroundColor='transparent'
                        onPress={() => searchPlayers(offset + 1)}
                    />
                </View>
            </ScrollView>
            <View style={{ ...view_styles.section_footer }}>
                <Button
                    title='CLOSE'
                    style={{ flex:1 }}
                    padding={15}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
        </View>
    )
}

export default PlayerSelector