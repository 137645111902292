import { View, FlatList, Image, TouchableOpacity } from 'react-native';
import type { PlayerSquareProps, PublicPlayerProps } from "../../types";
import { view_styles } from '../../constants/styles';
import { Icons, Text } from '../../Components';
import { SqauresHelpers } from '../api';
import Colors from '../../constants/colors';
import React, { useState } from 'react';


type SquareOwnersCardProps = {
    player_squares:PlayerSquareProps[],
    players:PublicPlayerProps[],
    onPlayerSelect:(player:PublicPlayerProps) => void
}
const SquareOwnersCard = ({ player_squares, players, onPlayerSelect }:SquareOwnersCardProps) => {
    const [ expanded, setExpanded ] = useState(false);

    const ownerships = SqauresHelpers.getOwnerShipPercents(player_squares);

    const renderOwners = (data:{ item: { player_id:string, owned_squares:number, owned_pct:number }, index:number }) => {
        const player = players.find(p => p.player_id == data.item.player_id);
        if(!player){  return <></> }
        return (
            <TouchableOpacity style={{ margin:3, borderRadius:4, borderColor:Colors.shades.shade600, justifyContent:'center', maxWidth:100, ...view_styles.float }} onPress={() => onPlayerSelect(player)}>
                <Image
                    source={{ uri: player.profile_pic && player.profile_pic != '' ? player.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:90, width:90, borderRadius:4 }}
                    resizeMode='cover'
                />
                <View style={{ position:'absolute', left:0, right:0, bottom:0, borderBottomRightRadius:4, borderBottomLeftRadius:4, backgroundColor:Colors.shades.white }}>
                    <View style={{ padding:10 }}>
                        <Text theme='header_2'>@{player.username}</Text>
                    </View>
                    <View style={{ position:'absolute', left:0, right:0, top:-15, justifyContent:'center', alignItems:'center' }}>
                        <View style={{ height:30, width:30, backgroundColor:Colors.shades.white, borderRadius:100, justifyContent:'center', alignItems:'center' }}>
                            <Text color={Colors.brand.midnight} size={12} weight='bold'>{(data.item.owned_pct * 100).toFixed()}%</Text>
                        </View>
                        
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ ...view_styles.section, minWidth:350 }}>
            <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                <Icons.OwnedSquareIcon size={18} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>SQUARE OWNERS</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>Players that own or have highest bid on at least 1 square</Text>
                </View>
                <Icons.ChevronIcon direction={expanded?'up':'down'} size={8} color={Colors.brand.midnight} />
            </TouchableOpacity>
            {expanded ?
            <View style={view_styles.section_body}>
                <FlatList
                    data={ownerships}
                    renderItem={renderOwners}
                    horizontal
                    keyExtractor={(item) => item.player_id.toString()}
                />
            </View>
            :<></>}
        </View>   
    )
}

export default SquareOwnersCard