import React, { useState } from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';

import type { CompetitionPayoutTypeProps, CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionTypeProps } from "../../types"
import { view_styles } from '../../constants/styles';
import { Icons, Text } from '../../Components';
import { SeasonHelpers } from '../api';
import Colors from '../../constants/colors';

type SeasonSettingsSectionProps = {
    width:number,
    competition_season:CompetitionSeasonProps,
    competition_type:CompetitionTypeProps,
    competition_result_type:CompetitionResultTypeProps,
    competition_payouts:CompetitionPayoutTypeProps[]
}

const SeasonSettingsSection = ({ width, competition_season, competition_type, competition_result_type, competition_payouts }:SeasonSettingsSectionProps) => {
    const [ expanded, setExpanded ] = useState(true);

    const { season_payout, competition_payout } = SeasonHelpers.getPayoutAmounts(competition_season)
    const cl = competition_season.market_type == 'FOR_MONEY' ? '$' : 'E'

    const renderPayouts = (data: { item:CompetitionPayoutTypeProps, index:number }) => {
        return (
            <View style={{ ...view_styles.body_row, padding:8, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <Text style={{ flex:1 }} theme='header_2'>{SeasonHelpers.formatPlace(data.item.tier)}{data.item.tier!= 1 ? ' Or Better': ''}</Text>
                <Text theme='header_2'>Split {data.item.payout}%</Text>
            </View>
        )
    }

    return (
        <View style={{ ...view_styles.section, maxWidth: width }}>
            <TouchableOpacity style={{ ...view_styles.section_header }} onPress={() => setExpanded(!expanded)}>
                <Icons.SettingsIcon size={18} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>Season Details</Text>
                    <Text style={{ marginTop:3 }} theme='body'>View important details about this season.</Text>
                </View>
                <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} size={8} color={Colors.brand.midnight} />
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body }}>
                <View style={view_styles.body_row}>
                    <View style={{ flex:1 }}>
                        <Text theme='header_2'>Competition Type</Text>
                        <Text style={{ marginTop:3 }} theme='body_2'>Each competition within this season will use this type</Text>
                    </View>
                    <Text theme='header'>{competition_type.type_label.toUpperCase()}</Text>
                </View>
                <View style={view_styles.body_row}>
                    <View style={{ flex:1 }}>
                        <Text theme='header_2'>Season Winner Allocation</Text>
                        <Text style={{ marginTop:3 }} theme='body_2'>How much of the total pot is dedicated to the season winner?</Text>
                    </View>
                    <View style={{ justifyContent:'center', alignItems:'center' }}>
                        <Text theme='header_2'>{cl}{season_payout.toFixed(2)}</Text>
                        <Text style={{ marginTop:3 }} theme='body'>{(parseFloat(competition_season.payout_allocation as string) * 100).toFixed(2)}%</Text>
                    </View>
                </View>
                <View style={view_styles.body_row}>
                    <View style={{ flex:1 }}>
                        <Text theme='header_2'>Each Competition Allocation</Text>
                        <Text style={{ marginTop:3 }} theme='body_2'>How much of the total pot is dedicated to each competition winner?</Text>
                    </View>
                    <View style={{ justifyContent:'center', alignItems:'center' }}>
                        <Text theme='header_2'>{cl}{competition_payout.toFixed(2)}</Text>
                        <Text style={{ marginTop:3 }} theme='body'>{(( 1 - (parseFloat(competition_season.payout_allocation as string))) * 100).toFixed(2)}%</Text>
                    </View>
                </View>
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="header_2">Payout Type</Text>
                    <Text theme="header_2">{competition_result_type.label.toUpperCase()}</Text>
                </View>
                <View style={{ padding:10, borderRadius:8, backgroundColor:Colors.shades.shade100 }}>
                    <Text style={{ padding:5 }} theme="header_2">{competition_result_type.description}</Text>
                    <View style={{ padding:10 }}>
                        <FlatList
                            data={competition_payouts.filter(cp => cp.competition_result_type_id == competition_season.competition_result_type_id).sort((a,b) => b.tier - a.tier)}
                            renderItem={renderPayouts}
                            keyExtractor={(item) => item.competition_payout_type_id.toString()}
                        />
                    </View>
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default SeasonSettingsSection