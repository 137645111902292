import React from 'react';
import { ActivityIndicator, FlatList, View } from "react-native"
import MarketsCard from "./MarketsCard"
import moment from 'moment-mini';
import type { AthleteProps, CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionProps, CompetitionTypeProps, MarketProps, MatchProps, PlayerPickProps, TeamProps } from '../../types';
import { CompetitionHelpers } from '../api';
import { Text } from '../../Components';
import Colors from '../../constants/colors';
import LinearDiagnal from '../../Components/LinearDiagnal';
import { AthleteImage } from '../../Components/Jerseys';


type MatchMarketCardProps = {
    match:MatchProps,
    competition:CompetitionProps,
    competition_type:CompetitionTypeProps,
    home_athlete?:AthleteProps,
    away_athlete?:AthleteProps,
    home_team?:TeamProps,
    away_team?:TeamProps,
    markets:MarketProps[]
    onPick:(pp:PlayerPickProps, event_title:string, event_start:string) => void
    mode: 'view'|'play'
    loading?:boolean,
    //orientation:'horizontal'|'vertical',
    player_picks:PlayerPickProps[],
    competition_match:CompetitionMatchProps,
    competition_match_markets:CompetitionMatchMarketProps[]
}
const MatchMarketCard = ({ match, home_athlete, away_athlete, competition_type, markets, competition_match, player_picks, competition_match_markets, mode, onPick, loading }:MatchMarketCardProps) => {
    const wager_picks = player_picks.filter(pp => pp.stake > 0)
    const market = markets.find(m => m.market_id == competition_match.market_id_override)
    
    const handleMarketSelect = async(mm:CompetitionMatchMarketProps, player_pick?:PlayerPickProps) => {
        if(!match){ return }
        if(match.participant_type != 'athlete'){ return alert('Not set up for team based matches') }
        let market = markets.find(m => m.market_id == mm.market_id)
        if(!match || !competition_type || !market || !away_athlete || !home_athlete){ return alert('Unable to process') }
        if(moment(match.scheduled_datetime).isBefore(moment())){ return alert('This event has already started') }
        if(player_pick){
            return onPick(player_pick, match.match_title, match.scheduled_datetime)
            //await CompetitionStoreActions.removePlayerPick(player_pick.player_pick_id)
        } else {
            let pp = CompetitionHelpers.createPickFromMatchMarket(competition_match.competition_id, mm)
            let pick_title = CompetitionHelpers.getPickTitleForMatch(pp, market)
            return onPick({ ...pp, pick_title }, match.match_title, match.scheduled_datetime)
        }
    }


    const renderWagerPicks = (data: { item:PlayerPickProps, index:number }) => {
        const locked = mode == 'view' && moment().isBefore(moment(match.scheduled_datetime)) ? true : false
        return (
            <View style={{ flexDirection:'row', marginTop:5 }}>
                <Text style={{ flex:1 }} size={12} color={Colors.highlights.highlight200}>{locked?'WAGER LOCKED':data.item.pick_title}</Text>
                <Text style={{ flex:1 }} size={12} color={Colors.highlights.highlight200} textAlign="right">{locked?'':`${data.item.stake.toFixed(2)} to win ${data.item.potential_winnings.toFixed(2)}`}</Text>
            </View>
        )
    }

    if(!match || !away_athlete || !home_athlete){ return <></> }

    return (
        <View style={{ flex:1 }}>
            <View style={{ flexDirection:'row', alignItems:'center', borderTopRightRadius:8, borderTopLeftRadius:8, padding:10, backgroundColor:Colors.shades.shade100 }}>
                <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>{match.match_title}</Text>
                <Text size={12} color={Colors.brand.midnight} weight='regular'>{match.status == 'not_started'?moment(match.scheduled_datetime).format('MM/DD hh:mm a'):match.status}</Text>
            </View>
            {market ?
                <LinearDiagnal 
                label={market.stat_label ?? market.type}
                label_size={10}
                style={{  padding:5 }}
                left_color={Colors.incentive.gold}
                right_color={'#ebd197'}
            />
            :<></>}
            <View style={{ flex:1, padding:10 }}>
                {match.participant_type == 'athlete' ?
                <View nativeID="event_details" style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                    <View nativeID="away_athlete" style={{ flex:1 }}>
                        <AthleteImage size={24} athlete={away_athlete}/>
                        <Text style={{ padding:10 }} size={14} color={Colors.brand.midnight} weight='bold' textAlign="center">{away_athlete.first_name} {away_athlete.last_name}</Text>
                    </View>
                    <Text size={14} color={Colors.brand.midnight} style={{ padding:20 }} weight='bold'>VS</Text>
                    <View nativeID="away_athlete" style={{ flex:1 }}>
                        <AthleteImage size={24} athlete={home_athlete}/>
                        <Text style={{ padding:10 }} size={14} color={Colors.brand.midnight} weight='bold' textAlign="center">{home_athlete.first_name} {home_athlete.last_name}</Text>
                    </View>
                </View>
                :<></>}
                <View nativeID="market_details">
                    <MarketsCard
                        player_picks={player_picks}
                        mode={mode}
                        markets={markets}
                        orientation="horizontal"
                        disabled={moment().isAfter(moment(match.scheduled_datetime))?true:false}
                        locked={mode == 'view' && moment().isBefore(moment(match.scheduled_datetime)) ? true : false}
                        competition_match_markets={competition_match_markets}
                        onMarketSelect={(mm, player_pick) => handleMarketSelect(mm, player_pick)}
                    />
                    {loading ?
                    <View style={{ position:'absolute', top:0, right:0, left:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                        <ActivityIndicator
                            size='large'
                            color={Colors.brand.midnight}
                        />
                    </View>
                    :<></>}
                </View>
            </View>
            {wager_picks.length > 0 ?
            <View nativeID="wager_picks">
                <FlatList
                    data={wager_picks}
                    renderItem={renderWagerPicks}
                    keyExtractor={(item) => item.player_pick_id.toString()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default MatchMarketCard