import axios from "axios";
import { APIOverrides } from "../../ApiOverrides";
import type { CompanyProps, CompetitionPayoutTypeProps, CompetitionProps, CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionTypeProps, MyPlayerProps } from "../../types";
import type { CompanyMemberProps } from "../../Authenticator/api/types";
import moment from "moment-mini";


let AUTH_SVC_API = ''
let TP_SVC_API = ''
//let EVENT_SVC_API = ''
//let MK_SVC_API = ''


export { ManageSeasonApi, ManageSeasonHelpers }

const ManageSeasonApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        TP_SVC_API = endpoints['TP_SVC_API'] as string;
        //EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        //MK_SVC_API = endpoints['MK_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getCompetitionOptions: async():Promise<{competition_types:CompetitionTypeProps[], competition_result_types:CompetitionResultTypeProps[], competition_payout_types:CompetitionPayoutTypeProps[]}> => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/options`)
        return resp.data
    },
    getSeasonTemplates: async():Promise<CompetitionSeasonProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/seasons/templates`);
        return resp.data.competition_seasons
    },
    getMyDetails: async():Promise<MyPlayerProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
        return resp.data.player
    },
    getMyCompanies : async():Promise<{companies:CompanyProps[], company_members:CompanyMemberProps[]}> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/companies/me`)
        const { companies, company_members } = resp.data
        return { companies, company_members }
    },
    activateSeason: async(competition_season_id:string):Promise<CompetitionSeasonProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/season/activate`, { competition_season_id });
        return resp.data.competition_season
    },
    deleteSeason: async(competition_season_id:string):Promise<CompetitionSeasonProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/season/delete`, { competition_season_id });
        return resp.data.competition_season
    },
    getSeasonById: async(competition_season_id:string):Promise<CompetitionSeasonProps> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/seasons/season/${competition_season_id}`);
        return resp.data.competition_season
    },
    getCompetitionsBySeasonId: async(competition_season_id:string):Promise<CompetitionProps[]> => {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/season/${competition_season_id}`);
        return resp.data.competitions
    },
    createSeason: async(competition_season:CompetitionSeasonProps):Promise<CompetitionSeasonProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/seasons/season/create`, { competition_season });
        return resp.data.competition_season
    },
    updateSeason: async(competition_season:CompetitionSeasonProps):Promise<CompetitionSeasonProps> => {
        const resp = await axios.post(`${TP_SVC_API}/v2/competitions/seasons/season/update`, { competition_season })
        return resp.data.competition_season
    }
}


const ManageSeasonHelpers = {
    getPendingSeason: ():CompetitionSeasonProps => {
        return {
            competition_season_id: '',
            admin_id: '',
            season_name:'',
            season_description:'Pending Description',
            invite_only: true,
            ticket_revenue:0,
            tickets_available: 10,
            tickets_sold:0,
            single_buy_in: true,
            expected_competition_count: 1,
            ticket_price:0,
            max_picks: 0,
            initial_balance: 1000,
            balance_rollover: false,
            unallocated_funds: 0,
            competition_type_id: '1',
            allow_late_buy_in: false,
            payout_allocation: 1,
            competition_result_type_id: '1',
            payout_amt: 0,
            scheduled_datetime: moment().add(1, 'week'),
            status: 'pending',
            market_type: 'FOR_MONEY',
            create_datetime: '',
            last_update_datetime:''
        }
    },
    isSeasonValid: (cs:CompetitionSeasonProps):string[] => {
        let errors:string[] = []
        if(!cs.season_name){ errors.push('Please provide a valid name') }
        if(!cs.competition_result_type_id){ errors.push('Please give a valid competition result type') }
        return errors
    },
    isInfoChanged: (cs:CompetitionSeasonProps, ds:CompetitionSeasonProps) => {
        let init_cs = JSON.stringify({ season_name: cs.season_name, season_description: cs.season_description, invite_only:cs.invite_only })
        let draft_cs = JSON.stringify({ season_name: ds.season_name, season_description: ds.season_description, invite_only:ds.invite_only })
        return draft_cs != init_cs ? true : false
    },
    isSettingsChanged: (cs:CompetitionSeasonProps, ds:CompetitionSeasonProps) => {
        let init_cs = JSON.stringify({ ticket_price:cs.ticket_price, payout_allocation:cs.payout_allocation, competition_type_id:cs.competition_type_id, competition_result_type_id:cs.competition_result_type_id, max_picks: cs.max_picks, initial_balance: cs.initial_balance, allow_late_buy_in:cs.allow_late_buy_in })
        let draft_cs = JSON.stringify({ ticket_price:ds.ticket_price, payout_allocation: ds.payout_allocation, competition_type_id:ds.competition_type_id, competition_result_type_id:ds.competition_result_type_id, max_picks: ds.max_picks, initial_balance: ds.initial_balance, allow_late_buy_in:ds.allow_late_buy_in })
        return draft_cs != init_cs ? true : false
    },
    isSettingsValid: (ds:CompetitionSeasonProps) => {
        if(!ds.competition_type_id){ return false }
        if(!ds.competition_result_type_id){ return false }
        let payout_allocation = parseFloat(ds.payout_allocation as string)
        let ticket_price = parseFloat(ds.ticket_price as string)
        if(isNaN(payout_allocation)){ return false }
        if(isNaN(ticket_price)){ return false }
        if(ticket_price < 0){ return false }
        if(payout_allocation <= 0){ return false }
        if(payout_allocation > 1){ return false }
        return true
    }
}