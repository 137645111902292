import React, { useEffect, useState } from 'react';
import { deviceDetect } from 'react-device-detect';
import { BEEventApi } from './api';

type BEAnalyticsObserverProps = {
    source:string;
    session_id:string;
    distinct_id:string;
}

const BEAnalyticsObserver = ({ source, session_id, distinct_id }:BEAnalyticsObserverProps) => {

    const [ init_loaded, setInitLoaded ] = useState(false);
    const device_data = deviceDetect(undefined);

    useEffect(() => {
        updateAnalytics()
    },[device_data, session_id, distinct_id])

    const updateAnalytics = async() => {
        //Get 
        BEEventApi.setDistinctId(distinct_id)
        BEEventApi.setSource(source)
        const is_pwa = isPWA();
        const notification_permission = getNotificationPermissions();
        BEEventApi.setDevice({ ...device_data, is_pwa, notification_permission })
        BEEventApi.setSessionId(session_id)
        if(!init_loaded){ 
            let url = window?.location?.href
            sendIncomingUserEvent(url)
            setInitLoaded(true)
        }
    }
    return <></>
}

const getNotificationPermissions = () => {
    if (!("Notification" in window)) {
        return 'not_allowed'
      }
    return window.Notification.permission
}

const isPWA = () => {
    const mqStandAlone = '(display-mode: standalone)';
    const new_nav = navigator as any
    if (new_nav.standalone || window.matchMedia(mqStandAlone).matches) {
      return true
    }
    return false
}


const sendIncomingUserEvent = (url:string) => {
    const parsed_url = url.split(/[?&]/)
    const new_data:any = {
        href: url
    };
    parsed_url.map(p => {
      let vars = p.split('=')
      if(vars.length !== 2){ return }
      if(!vars[0]){ return }
      new_data[vars[0]] = vars[1]
    })
    let reference_id = new_data['eid']
    let referrer = document?.referrer
    if(referrer){ new_data['referrer'] = referrer }
    BEEventApi.saveEvent({
        event_name: 'incoming_user',
        reference_id,
        event_data: new_data,
        level:1
    })
}

export default BEAnalyticsObserver