import React from 'react';
import { View } from "react-native"
import Colors from "../../../constants/colors"
import type { AthleteProps, BracketRoundProps, PlayerBracketPickProps, RoundEventProps, TeamProps } from '../../../types';
import RoundEventCard from './RoundEvent';
import { view_styles } from '../../../constants/styles';
import { Text } from '../../../Components';

type BracketRoundSectionProps = {
    player_bracket_id?:string,
    round_event_loading?:string,
    player_bracket_picks: PlayerBracketPickProps[],
    bracket_round:BracketRoundProps,
    round_events:RoundEventProps[],
    teams:TeamProps[],
    athletes:AthleteProps[],
    hide_titles?:boolean,
    width:number,
    orientation:'left'|'right',
    active_bracket_group_id?:string,
    onPick: (player_bracket_pick:PlayerBracketPickProps) => void,
    bracket_size: { width:number, height:number },
    left_bar?:boolean,
    right_bar?:boolean
}

const BracketRoundSection = ({ bracket_round, round_event_loading, teams, round_events, width, hide_titles, player_bracket_picks, active_bracket_group_id, onPick }:BracketRoundSectionProps) => {

    if(active_bracket_group_id){
        round_events = round_events.filter(re => re.bracket_group_id == active_bracket_group_id)
    }

    const renderRoundEvents = (data: { item:RoundEventProps, index:number}) => {
        const player_bracket_pick = player_bracket_picks.find(pbr => pbr.round_event_id == data.item.round_event_id);
        const away_placeholder_pick = player_bracket_picks.find(pbr => pbr.round_event_id == data.item.away_placeholder);
        const home_placeholder_pick = player_bracket_picks.find(pbr => pbr.round_event_id == data.item.home_placeholder);
        const away_placeholder_team = teams.find(t => t.team_id == away_placeholder_pick?.pick_side_id)
        const home_placeholder_team = teams.find(t => t.team_id == home_placeholder_pick?.pick_side_id)

        const home_team = teams.find(t => t.team_id == data.item.home_side_id);
        const away_team = teams.find(t => t.team_id == data.item.away_side_id);
        const loading = round_event_loading == data.item.round_event_id ? true : false
        return (
            <View style={{ margin:2, flex:1, flexGrow:1, justifyContent:'center' }}>
                <View style={{ margin:1, ...view_styles.float }}>
                    <RoundEventCard
                        round_event={data.item}
                        loading={loading}
                        round_events={round_events}
                        away_team={away_team}
                        home_team={home_team}
                        away_placeholder_team={away_placeholder_team}
                        home_placeholder_team={home_placeholder_team}
                        player_bracket_pick={player_bracket_pick}
                        width={width}
                        no_pick={bracket_round.carry_through ? true : false}
                        away_placeholder_pick={away_placeholder_pick}
                        home_placeholder_pick={home_placeholder_pick}
                        onPick={(player_bracket_pick) => {
                            if(bracket_round.carry_through){ return alert('Event is not eligible for picks') }
                            onPick(player_bracket_pick)
                        }}
                    />
                </View>
            </View>
        )
    }


    return (
        <View style={{ }}>
            {!hide_titles ?
            <View style={{ padding:10 }}>
                <Text size={14} color={Colors.brand.midnight} weight='bold' textAlign="center">{bracket_round.round_name}</Text>
            </View>
            :<></>}
            <View nativeID="round_events" style={{ flex:1 }}>
                {round_events.filter(re => re.bracket_round_id == bracket_round.bracket_round_id).sort((a,b) => a.event_number - b.event_number).map((re, i) => {
                    return renderRoundEvents({ item: re, index:i })
                })}
            </View>
        </View>
    )
}

export default BracketRoundSection