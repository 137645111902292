import React, { useEffect, useState } from "react"
import { View } from "react-native"
import { Text } from "../../Components"
import Colors from "../../constants/colors"

type ResponseTimerProps = {
    poll_id:string,
    
    seconds: number,
    onTimesUp: () => void
}
const ResponseTimer = ({ seconds, onTimesUp }:ResponseTimerProps) => {
    const [ seconds_remaining, setSecondsRemaining ] = useState(seconds);
    const [ times_up, setTimesUp ] = useState(false);

    useEffect(() => {
        if(seconds_remaining > 0){
            setTimeout(() => setSecondsRemaining(seconds_remaining - 1), 1000);
        } else {
            setTimesUp(true)
        }
    },[seconds_remaining])

    
    useEffect(() => {
        if(!times_up){ return }
        onTimesUp()
    },[times_up])

    return (
        <View style={{ width:50, padding:10, borderRadius:22, backgroundColor:seconds_remaining < 5 ? Colors.utility.error : seconds_remaining < 10 ? Colors.utility.warning  : Colors.utility.success }}>
            <Text textAlign="center" size={14} color={Colors.shades.white} weight='bold'>{seconds_remaining}</Text>
        </View>
    )
}

export default ResponseTimer

