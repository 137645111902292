import React from 'react';
import { View, FlatList, ActivityIndicator } from 'react-native';
import { useEffect, useState } from "react"
import type {  OrderProps, PostProps, PublicPlayerProps } from "../../../types"
import { SocialProfileApi } from '../api';
import PostCard from '../../PostCard';
import Colors from '../../../constants/colors';
import { Button } from '../../../Components';

type PostsListProps = {
    viewing_player:PublicPlayerProps,
    player_id?:string,
    onRequestAuthenticate:() => void,
    onCopyOrder?:(order:OrderProps) => void,
    onFadeOrder?:(order:OrderProps) => void,
    onViewComments:(post_id:string) => void
}

const PostsList = ({ viewing_player, player_id, onRequestAuthenticate, onCopyOrder, onFadeOrder, onViewComments}:PostsListProps) => {
    const [ post_data, setPostData ] = useState<{
        posts_loading:boolean,
        posts:PostProps[],
        orders:OrderProps[],
        posts_offset:number
    }>({
        posts_loading: false,
        posts:[],
        orders:[],
        posts_offset:0
    });
    const { posts_loading, posts, orders, posts_offset } = post_data;
    
    useEffect(() => {
        getPostData(0);
    },[]);

    const getPostData = async(offset:number) => {
        setPostData({ ...post_data, posts_loading:true });
        let os:OrderProps[] = []
        const ps = await SocialProfileApi.getPostsByPlayerId(viewing_player.player_id, offset);

        //Now we need to get the data within the posts!
        let order_ids:string[] = []
        ps.map(p => {
            if(p.memo_orders && p.memo_orders.length > 0){ order_ids = order_ids.concat(p.memo_orders) }
        })
        if(order_ids.length > 0){ os = await SocialProfileApi.getBulkOrders(order_ids) }

        //Now we need to get all the event data from the 

        setPostData({
            ...post_data,
            posts_loading:false,
            posts: posts.concat(ps),
            orders: orders.concat(os),
            posts_offset: offset
        })
    }


    const renderPosts = (data: { item:PostProps, index:number }) => {
        const post_orders = data.item.memo_orders ?  orders.filter(o => data.item.memo_orders.includes(o.order_id)) : []
        return (
            <View>
                <PostCard 
                    player={viewing_player}
                    orders={post_orders}
                    post={data.item}
                    player_id={player_id}
                    onCopyOrder={onCopyOrder}
                    onFadeOrder={onFadeOrder}
                    onViewComments={onViewComments}
                    onRequestAuthenticate={onRequestAuthenticate}
                />
            </View>
        )
    }


    return (
        <View>
            <View>
                {posts_loading ?
                <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ padding:10, alignSelf:'center' }} />
                :<></>}
                <FlatList
                    key={'player_posts'}
                    data={posts}
                    renderItem={renderPosts}
                    keyExtractor={(item) => item.memo_post_id?.toString()}
                />
                <View style={{ padding:20 }}>
                    <Button
                        title='Load More'
                        title_color={Colors.brand.electric}
                        backgroundColor={Colors.shades.white}
                        borderWidth={1}
                        borderColor={Colors.brand.electric}
                        onPress={() => getPostData(posts_offset + 1)}
                    />
                </View>
            </View>
        </View>
    )
}

export default React.memo(PostsList, (left,right) => {
    if(left.viewing_player?.player_id != right.viewing_player?.player_id){ return false }
    if(left.player_id != right.player_id){ return false }
    return true
})