import React, { useEffect, useState } from 'react';
import { View, FlatList, ActivityIndicator } from 'react-native';
import type { BestAvailableOrderProps, BestAvailableResponseProps, EventOrderStatProps, LeagueProps, MarketProps, MatchProps, OrderProps, TournamentProps, TradeProps } from '../../../types';
import { MarketComponentApi } from '../../api';
import SocketManager from '../../../Socket';
import Colors from '../../../constants/colors';
import TournamentMarket from '../TournamentMarket';
import { TournamentListHelpers } from './api';
import moment from 'moment-mini';

type TournamentListProps = {
    list_key:string //Change key to reload data
    tournaments:TournamentProps[],
    distinct_id?:string,
    show_grades?:boolean,
    hide_liquidity?:boolean,
    hide_match_liquidity?:boolean,
    hide_participant_liquidity?:boolean,
    show_id?:boolean,
    init_expanded_ids?:string[],
    onTeamSelect?:(team_id:string) => void,
    onActivate?:(event_id:string, event_type:string) => void,
    onViewEvent?:(data:{ event_id:string, event_type:string, market_id:string, side_id?:string, side_type?:string }) => void,
    onOrder?:(o:OrderProps) => void,
    onTradeLongPress?:(trade:TradeProps) => void,
    onViewAdditionalMarkets?:(event_id:string, event_type:string) => void
}

const TournamentList = ({ tournaments, hide_liquidity, hide_match_liquidity, hide_participant_liquidity, show_grades, list_key, distinct_id, onViewEvent, onOrder, onTradeLongPress, onViewAdditionalMarkets, onActivate }:TournamentListProps) => {
    const [ needs_reload, setNeedsReload ] = useState(false);
    const [ socket_response, setSocketResponse ] = useState<{ tournament_ids:string[], matches:MatchProps[], ba_response:BestAvailableResponseProps } | undefined>(undefined);
    const [ event_data, setListData ] = useState<{
        loading:boolean,
        markets:MarketProps[],
        leagues:LeagueProps[],
        tournament_latest_trades: TradeProps[],
        tournament_available_orders: BestAvailableOrderProps[],
        tournament_order_stats:EventOrderStatProps[],
        match_latest_trades:TradeProps[],
        match_available_orders:BestAvailableOrderProps[],
        match_order_stats:EventOrderStatProps[]
    }>({
        loading:false,
        leagues:[],
        tournament_latest_trades: [],
        match_latest_trades:[],
        markets:[],
        tournament_available_orders: [],
        match_available_orders:[],
        tournament_order_stats: [],
        match_order_stats:[]
    });
    const { loading, leagues, tournament_available_orders, tournament_order_stats, tournament_latest_trades, match_available_orders, match_order_stats, match_latest_trades, markets } = event_data;

    useEffect(() => {
        MarketComponentApi.setEnvironment();
        if(tournaments.length > 0){ getDataFromServer() }
    },[list_key, tournaments.length]);

    useEffect(() => {
        if(!socket_response){ return }
        const updated_data = TournamentListHelpers.getMarketDataFromBestAvailable(socket_response.ba_response, tournaments, socket_response.matches);
        if(!updated_data.updated){ return }
        let socket_match_ids = socket_response.matches.map(m => m.match_id);
        setListData({
            ...event_data,
            tournament_order_stats: tournament_order_stats.filter(os => !socket_response.tournament_ids.find(new_id => new_id == os.event_id)).concat(updated_data.event_order_stats),
            tournament_available_orders: tournament_available_orders.filter(os => !socket_response.tournament_ids.find(new_id => new_id == os.event_id)).concat(updated_data.available_orders),
            tournament_latest_trades: tournament_latest_trades.filter(lt => !socket_response.tournament_ids.find(new_id => new_id == lt.event_id)).concat(updated_data.latest_trades),
            match_order_stats: match_order_stats.filter(os => !socket_match_ids.find(new_id => new_id == os.event_id)).concat(updated_data.m_event_order_stats),
            match_available_orders: match_available_orders.filter(os => !socket_match_ids.find(new_id => new_id == os.event_id)).concat(updated_data.m_available_orders),
            match_latest_trades: match_latest_trades.filter(os => !socket_match_ids.find(new_id => new_id == os.event_id)).concat(updated_data.m_latest_trades)
        })
    },[socket_response])

    const getDataFromServer = async() => {
        setListData({ ...event_data, loading:true });
        const ba = await MarketComponentApi.getBestAvailableOrders();
        if(!ba){ return }
        const lt = await MarketComponentApi.getLatestTradesByEvents('tournament', tournaments.map(e => e.tournament_id));
        const ev_data = TournamentListHelpers.getMarketDataFromBestAvailable(ba, tournaments, []);
        const maks = await MarketComponentApi.getMarkets();
        const lgs = await MarketComponentApi.getLeagues();

        setListData({
            ...event_data,
            loading:false,
            leagues:lgs,
            tournament_latest_trades: lt,
            markets: maks,
            tournament_order_stats: ev_data.event_order_stats,
            tournament_available_orders: ev_data.available_orders,
            match_latest_trades: ev_data.m_latest_trades,
            match_available_orders: ev_data.m_available_orders,
            match_order_stats: ev_data.m_event_order_stats
        });
    }

    const renderTournaments = (data: { item:TournamentProps, index:number }) => {
        const t_ba = tournament_available_orders.filter(o => o.event_id == data.item.tournament_id && o.event_type == 'tournament');
        const t_order_st = tournament_order_stats.filter(os => os.event_id == data.item.tournament_id && os.event_type == 'tournament');
        const t_lts = tournament_latest_trades.filter(lt => lt.event_id == data.item.tournament_id && lt.event_type == 'tournament');
        const league = leagues.find(l => l.league_id == data.item.league_id);
        return (
            <TournamentMarket
                tournament={data.item}
                markets={markets}
                latest_trades={t_lts}
                league={league}
                show_grades={show_grades}
                match_available_orders={match_available_orders}
                match_latest_trades={match_latest_trades}
                hide_liquidity={hide_liquidity}
                hide_match_liquidity={hide_match_liquidity}
                hide_participant_liquidity={hide_participant_liquidity}
                match_order_stats={match_order_stats}
                best_available_orders={t_ba}
                event_order_stats={t_order_st}
                onActivate={onActivate ? onActivate : undefined}
                onView={(ev) => {
                    if(onViewEvent){ onViewEvent(ev) }
                }}
                onOrder={(o) => onOrder ? onOrder(o) : console.log('')}
                onViewAdditionalMarkets={(event_id, event_type) => onViewAdditionalMarkets ? onViewAdditionalMarkets(event_id, event_type) : console.log('')}
                onTradeLongPress={(t) => onTradeLongPress ? onTradeLongPress(t) : console.log(t)}
            />
        )
    }

    return (
        <View>
            {loading ?
                <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
            :<></>}
            {markets.length > 0 ?
            <FlatList
                key={'tournament_list'}
                data={tournaments.sort((a,b) => moment(a.scheduled_datetime).unix() - moment(b.scheduled_datetime).unix())}
                renderItem={renderTournaments}
                keyExtractor={(item) => item.tournament_id.toString()}
            />
            :<></>}
            <SocketManager
                distinct_id={distinct_id}
                onConnect={() => {
                    if(needs_reload){
                        getDataFromServer();
                        setNeedsReload(false);
                    }
                }}
                onDisconnect={() => setNeedsReload(true)}
                onSocketEvent={(ev) => {
                    if(ev.type != 'V1_LOAD_BEST_AVAILABLE'){ return }
                    let tournament_ids = ev.data.tournaments.map((e:TournamentProps) => e.tournament_id);
                    setSocketResponse({ tournament_ids, matches:ev.data.matches, ba_response: ev.data });
                }}
                subscribed_events={['V1_LOAD_BEST_AVAILABLE']}
            />
        </View>
    )
}

export default TournamentList