import React, { useEffect, useState } from "react"
import { FlatList, Image, ScrollView, TouchableOpacity, View } from "react-native"
import TeamEventCard from "./TeamEventCard"

import AthleteMarketCard from "./AthleteMarketCard"
import MatchMarketCard from "./MatchMarketCard"
import type { AthleteProps, CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionProps, CompetitionRecordProps, CompetitionResultProps, CompetitionTypeProps, EventProps, MarketProps, MatchProps, PlayerPickProps, PublicPlayerProps, TeamProps, TournamentProps } from "../../types"
import { CompetitionResultApi, PlayerPicksApi } from "../api"
import Colors from "../../constants/colors"
import { Button, Text } from "../../Components"


type LeaderPicksProps = {
    competition:CompetitionProps,
    markets:MarketProps[],
    events:EventProps[],
    width:number
    matches:MatchProps[],
    tournaments:TournamentProps[],
    athletes:AthleteProps[],
    teams:TeamProps[],
    competition_type:CompetitionTypeProps,
    competition_matches:CompetitionMatchProps[],
    competition_match_markets:CompetitionMatchMarketProps[],
    height: number,
    leader:PublicPlayerProps,
    onNext?:() => void
    onPrevious?:() => void,
    onClose:() => void
}
const LeaderPicks = ({ markets, events, matches, athletes, competition, competition_type, competition_matches, competition_match_markets, leader, height, width, onClose, onNext, onPrevious }:LeaderPicksProps) => {
    const [ data, setData ] = useState<{
        loading:boolean,
        player_picks: PlayerPickProps[],
        result?: CompetitionResultProps,
        record?:CompetitionRecordProps
    }>({
        loading:false,
        player_picks: []
    });

    const { player_picks, result, record } = data;
    
    let filtered_matches = competition_matches.filter(cm => player_picks.map(pp => pp.competition_match_id.toString()).includes(cm.competition_match_id))

    useEffect(() => {
        if(!competition || !leader){ return }
        getPlayerPicksFromServer(competition.competition_id, leader.player_id, 0)
    },[competition, leader.player_id])

    const getPlayerPicksFromServer = async(competition_id:string, player_id:string, offset:number) => {
        setData({ ...data, loading:true });
        const pps = await PlayerPicksApi.getPlayerPicksByCompetition(competition_id, player_id, offset)
        const { competition_records, competition_results } = await CompetitionResultApi.getResultsByCompetition(competition_id, 0, player_id)
        setData({
            ...data,
            loading:false,
            player_picks: pps,
            result: competition_results.find(cr => cr.player_id == player_id),
            record: competition_records.find(cr => cr.player_id == player_id)
        });
    }

    const renderEvents = (data:{ item:CompetitionMatchProps, index:number }) => {
        const match_markets = competition_match_markets.filter(cmm => cmm.competition_match_id == data.item.competition_match_id);
        const event_picks = player_picks.filter(pp => pp.competition_match_id == data.item.competition_match_id)
        
        switch(data.item.event_type){
            case 'team':
                const event = events.find(e => e.event_id == data.item.event_id)
                if(!event){ return <></> }
                if(!data.item.side_type_override){
                    return (
                        <View style={{ margin:5, padding:10, borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:8, backgroundColor:Colors.shades.white }}>
                            <TeamEventCard
                                competition={competition}
                                competition_type={competition_type}
                                event={event}
                                markets={markets}
                                player_picks={event_picks}
                                mode='view'
                                onPick={() => console.log('')}
                                competition_match={data.item}
                                competition_match_markets={match_markets}
                            />
                        </View>
                    )
                }
                switch(data.item.side_type_override){
                    case 'athlete':
                        const athlete = athletes.find(a => a.athlete_id == data.item.side_id_override);
                        if(!athlete){ return <></> }
                        return (
                            <View style={{ margin:5, padding:10, borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:8, backgroundColor:Colors.shades.white }}>
                                <AthleteMarketCard
                                    event={event}
                                    athlete={athlete}
                                    competition={competition}
                                    competition_type={competition_type}
                                    markets={markets}
                                    competition_match={data.item}
                                    mode='view'
                                    orientation='horizontal'
                                    onPick={() => console.log('')}
                                    competition_match_markets={match_markets}
                                    player_picks={event_picks}
                                />
                            </View>
                        )
                    default: return <></>
                }

            case 'match':
                const match = matches.find(m => m.match_id == data.item.event_id)
                if(!match){ return <></> }
                let home_athlete = athletes.find(a => a.athlete_id == match.participants[0])
                let away_athlete = athletes.find(a => a.athlete_id == match.participants[1])
                return (
                    <View style={{ margin:5, padding:10, borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:8, backgroundColor:Colors.shades.white }}>
                        <MatchMarketCard
                            match={match}
                            competition={competition}
                            competition_type={competition_type}
                            home_athlete={home_athlete}
                            away_athlete={away_athlete}
                            markets={markets}
                            competition_match={data.item}
                            mode='view'
                            onPick={() => console.log('')}
                            competition_match_markets={match_markets}
                            player_picks={event_picks}
                        />
                    </View>
                )

            default: return <></>
        }


       
    }

    const cl = competition?.market_type == 'FOR_MONEY' ? '$' : 'E'

    return (
        <View style={{ flex:1, height, width, backgroundColor:Colors.shades.white }}>
            {record && result ?
            <View nativeID="leader_header">
                <View style={{ backgroundColor:Colors.brand.midnight, padding:10, marginBottom:10, paddingTop:20 }}>
                    <Text size={18} color={Colors.shades.white} weight='bold' textAlign="center">Leaderboard Picks</Text>
                    <View style={{ flexDirection:'row', marginTop:15, marginBottom:10, alignItems:'center', borderRadius:22, backgroundColor:Colors.shades.white, padding:8, paddingLeft:15, paddingRight:15}}>
                        <View nativeID="place" style={{ padding:5 }}>
                            <Text size={12} color={Colors.brand.midnight} weight="bold">{result.place}</Text>
                        </View>
                        <View nativeID="image" style={{ paddingLeft:10, paddingRight:10 }}>
                            <Image
                                source={{ uri: leader.profile_pic }}
                                style={{ height:30, width:30, borderRadius:100 }}
                                resizeMode="cover"
                            />
                        </View>
                        <View nativeID="details" style={{ flex:1 }}>
                            <Text size={12} color={Colors.brand.midnight} weight='semibold'>{leader.username}</Text>
                            {competition_type?.type == 'wager' ?
                                <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{record.earnings.toFixed(2)} Earned ({record.remaining_stakes.toFixed(2)} at risk)</Text>
                                :
                                <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{record.wins} - {record.losses} - {record.draws} ({record.remaining_picks} Remaining)</Text>
                            }
                        </View>
                        <View nativeID="winnings">
                            <Text size={12} color={Colors.brand.slate} weight='regular'>{cl}{result.winnings.toFixed(2)}</Text>
                        </View>
                    </View>
                    {onNext && onPrevious ?
                    <View nativeID="nav" style={{ flexDirection:'row', alignItems:'center', margin:10, marginBottom:0 }}>
                        <TouchableOpacity style={{ flex:1 }}>
                            <Text size={14} color={Colors.shades.white} weight='regular'>Previous</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flex:1 }}>
                            <Text size={14} color={Colors.shades.white} weight='regular' textAlign="right">Next</Text>
                        </TouchableOpacity>
                    </View>
                    :<></>}
                </View>
            </View>
            :<></>}
    
            <ScrollView style={{flex:1, backgroundColor:Colors.shades.white}} nativeID="leader_picks">
                <Text style={{ margin:10 }} color={Colors.brand.midnight} textAlign="center" weight='bold' size={18}>{leader.username} Picks</Text>
                <Text style={{ margin:10 }} color={Colors.brand.slate} textAlign="center" weight='bold' size={14}>Locked picks will appear after event is live</Text>
                <View nativeID="picks" style={{ marginTop:10 }}>
                    <FlatList
                        scrollEnabled
                        data={filtered_matches}
                        renderItem={renderEvents}
                        keyExtractor={(item) => item.competition_match_id.toString()}
                    />
                </View>
            </ScrollView>
            <View nativeID="action_row" style={{ padding:10 }}>
                <Button
                    title="Close"
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    borderRadius={22}
                    title_size={14}
                    onPress={() => onClose()}
                />
            </View>
        </View>    
    )
}

export default LeaderPicks