import React from 'react';
import { Image, TouchableOpacity, View, ActivityIndicator } from "react-native"
import Colors from "../../../constants/colors"
import type { AthleteProps, EventProps, PlayerBracketPickProps, RoundEventProps, TeamProps } from "../../../types"
import { Text } from '../../../Components';
import { BracketCompetitionHelpers } from '../../api';


type RoundEventCardProps = {
    round_event:RoundEventProps,
    round_events:RoundEventProps[],
    event?:EventProps,
    player_bracket_pick?:PlayerBracketPickProps,
    away_team?:TeamProps,
    home_team?:TeamProps,
    away_placeholder_team?:TeamProps,
    home_placeholder_team?:TeamProps,
    away_athlete?:AthleteProps,
    home_athlete?:AthleteProps,
    loading?:boolean,
    width:number,
    no_pick?:boolean,
    away_placeholder_pick?:PlayerBracketPickProps,
    home_placeholder_pick?:PlayerBracketPickProps,
    onPick:(player_bracket_pick:PlayerBracketPickProps) => void
}

const RoundEventCard = ({ event, loading, away_team, away_placeholder_team, home_placeholder_team, home_team, width, no_pick, round_event, player_bracket_pick, away_placeholder_pick, home_placeholder_pick, onPick }:RoundEventCardProps) => {
    //AWAY PLACEHOLDER
    const round_width = width//width > 600 ? undefined : width / 2

    const handleSelectAway = () => {
        if(loading){ return }
        //Okay! Lets get the side_id
        let side:'away' = 'away'
        let side_id:string|undefined = undefined
        let side_type:string = 'team'
        let pick_seed:number|undefined = 0
        if(away_placeholder_pick){
            side_id = away_placeholder_pick.pick_side_id
            side_type = 'team'
            pick_seed = away_placeholder_pick.pick_seed
        } else if(round_event.away_side_id){
            side_id = round_event.away_side_id
            side_type = 'team'
            pick_seed = round_event.away_seed
        }

        if(!side_id || !pick_seed){ return alert('Unable to process') }
        let new_pick = BracketCompetitionHelpers.createPlayerBracketPick(round_event.bracket_id, round_event, side, side_id, pick_seed)
        onPick({ ...new_pick, player_bracket_pick_id: player_bracket_pick?.player_bracket_pick_id??'', pick_side_type: side_type })
    }

    const handleSelectHome = () => {
        //Okay! Lets get the side_id
        if(loading){ return }
        let side:'home' = 'home'
        let side_id:string|undefined = undefined
        let side_type:string = 'team'
        let pick_seed:number|undefined = 0
        if(home_placeholder_pick){
            side_id = home_placeholder_pick.pick_side_id
            side_type = 'team'
            pick_seed = home_placeholder_pick.pick_seed
        } else if(round_event.home_side_id){
            side_id = round_event.home_side_id
            side_type = 'team'
            pick_seed = round_event.home_seed
        }

        if(!side_id || !pick_seed){ return alert('Unable to process') }
        let new_pick = BracketCompetitionHelpers.createPlayerBracketPick(round_event.bracket_id, round_event, side, side_id, pick_seed)
        onPick({ ...new_pick, player_bracket_pick_id: player_bracket_pick?.player_bracket_pick_id??'', pick_side_type: side_type })
    }

    const getPickColor = (side:'away'|'home', pbb?:PlayerBracketPickProps) => {
        if(!pbb || pbb.pick_side != side){ return Colors.brand.midnight }
        switch(pbb.result_ind){
            case 'win': return Colors.utility.success
            case 'lose': return Colors.utility.error
            default: return Colors.brand.electric 
        }
    }

    /*
    const getSideColor = (side:'away'|'home') => {
        if(!player_bracket_pick){ return Colors.brand.midnight }
        if(!player_bracket_pick.result_ind){ return Colors.brand.midnight }
        if(player_bracket_pick.result_ind == 'lose'){ return Colors.utility.error }
        if(player_bracket_pick.result_ind == 'win'){ return Colors.utility.success }
    }

    const isPicked = (side:'away'|'home') => {
        if(!player_bracket_pick){ return false }
        if(player_bracket_pick.pick_side == side){ return true } else { return false }
    }
    */

    const renderButton = (side:'away'|'home') => {
        if(no_pick){
            return (
                <View style={{ height:15, width:15, borderRadius:100, backgroundColor:Colors.shades.shade400 }} />
            )
        }
        if(!player_bracket_pick || player_bracket_pick.pick_side != side){
            return (
                <View style={{ height:15, width:15, borderRadius:100, borderWidth:1, borderColor:Colors.brand.electric }} />
            )
        }
        switch(player_bracket_pick.result_ind){
            case 'win':
                return (
                    <View style={{ height:15, width:15, borderRadius:100, borderWidth:1, borderColor:Colors.brand.electric, backgroundColor:Colors.utility.success }} />
                )
            case 'lose':
                return (
                    <View style={{ height:15, width:15, borderRadius:100, borderWidth:1, borderColor:Colors.brand.electric, backgroundColor:Colors.utility.error }} />
                )
            default: 
                return (
                    <View style={{ height:15, width:15, borderRadius:100, borderWidth:1, borderColor:Colors.brand.electric, backgroundColor:Colors.brand.electric }} />
                )
        }
    }


    const getScore = (side_id?:string) => {
        if(!event?.home || !event?.away){ return 0 }
        let side = event.home.team_id == side_id ? 'home' : 'away'
        if(side == 'away'){ return event.away_team_score }
        else { return event.home_team_score }
    }

    /*
    let winning_side:string | undefined = undefined
    if(round_event.status == 'closed'){
        winning_side = round_event.winning_side_id == round_event.away_side_id ? 'away' : 'home'
    }
    */
    const renderSide = (side:'away'|'home') => {
        switch(side){
            case 'away':
                return (
                    <View style={{  }}>
                        {round_event?.away_side_id && away_placeholder_pick && away_placeholder_team ?
                        <View style={{ padding:4, borderTopRightRadius:4, borderTopLeftRadius:4, backgroundColor:Colors.shades.shade600 }}>
                            <Text size={10} weight='semibold' color={away_placeholder_pick.result_ind=='win'?Colors.utility.success:Colors.utility.error}>{away_placeholder_team.market_name} {away_placeholder_team.name}</Text>
                        </View>
                        :<></>}
                        <View style={{ flexDirection:'row', alignItems:'center', padding:8 }}>
                            <View style={{ flex:1, marginRight:10, marginLeft:0 }}>
                                {round_event.away_side_id && away_team ?
                                <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                                    <Image
                                        source={{ uri: away_team.image?.url }}
                                        style={{ height:20, width:20, marginRight:2 }}
                                        resizeMode="cover"
                                    />

                                    <Text style={{ paddingRight: 5, borderRightWidth:1, borderColor:Colors.shades.shade100 }} size={10} color={Colors.brand.midnight} weight='regular'>{round_event.away_seed}</Text>
                                    <Text style={{ flex:1 }} size={10} color={getPickColor('home', player_bracket_pick)} weight={'semibold'}>{away_team.market_name} {away_team.name}</Text>                                
                                </View>
                                :
                                <View>
                                    {away_placeholder_pick && away_placeholder_team ?
                                    <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                                        <Image
                                            source={{ uri: away_placeholder_team.image?.url }}
                                            style={{ height:20, width:20, marginRight:2 }}
                                            resizeMode="cover"
                                        />
                                        <Text style={{ paddingRight: 5, borderRightWidth:1, borderColor:Colors.shades.shade100 }} size={10} color={Colors.brand.electric} weight='regular'>{away_placeholder_pick.pick_seed}</Text>
                                        <Text style={{ flex:1 }} size={10} color={Colors.brand.electric} weight='semibold'>{away_placeholder_team.market_name} {away_placeholder_team.name}</Text>
                                    </View>
                                    :
                                    <Text color='transparent'>select</Text>
                                    }
                                </View>
                                }
                            </View>
                            <View style={{ flexDirection:'row', alignItems:'center', borderLeftWidth:1, borderLeftColor:Colors.shades.shade600 }}>
                                {round_event.event_id ?
                                    <Text style={{ margin:5}} size={10} color={Colors.brand.midnight} weight={'semibold'}>{getScore(away_team?.team_id)}</Text>
                                :<></>}
                                {renderButton('away')}
                            </View>
                        </View>
                    </View>
                )
            case 'home':
                return (
                    <View style={{  }}>
                        <View style={{ flexDirection:'row', alignItems:'center', padding:8}}>

                            <View style={{ flex:1, marginRight:10 }}>
                                {round_event.home_side_id && home_team ?
                                <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                                    <Image
                                        source={{ uri: home_team.image?.url }}
                                        style={{ height:20, width:20, marginRight:2 }}
                                        resizeMode="cover"
                                    />
                                    <Text style={{ paddingRight: 5, borderRightWidth:1, borderColor:Colors.shades.shade100 }} size={10} color={Colors.brand.midnight} weight='regular'>{round_event.home_seed}</Text>
                                    <Text style={{ flex:1 }} size={10} color={getPickColor('home', player_bracket_pick)} weight={'semibold'}>{home_team.market_name} {home_team.name}</Text>
                                </View>
                                :
                                <View>
                                    {home_placeholder_pick && home_placeholder_team ?
                                    <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                                        <Image
                                            source={{ uri: home_placeholder_team.image?.url }}
                                            style={{ height:20, width:20, marginRight:2 }}
                                            resizeMode="cover"
                                        />
                                        <Text style={{ paddingRight: 5, borderRightWidth:1, borderColor:Colors.shades.shade100 }} size={10} color={Colors.brand.electric} weight='regular'>{home_placeholder_pick.pick_seed}</Text>
                                        <Text style={{ flex:1 }} size={10} color={Colors.brand.electric} weight={'semibold'}>{home_placeholder_team.market_name} {home_placeholder_team.name}</Text>
                                    </View>
                                    :
                                    <Text color='transparent'>select</Text>
                                    }
                                </View>
                                }
                            </View>
                            <View style={{ flexDirection:'row', alignItems:'center', borderLeftWidth:1, borderLeftColor:Colors.shades.shade600 }}>
                                {round_event.event_id ?
                                    <Text style={{ margin:5}} size={10} color={Colors.brand.midnight} weight={'semibold'}>{getScore(home_team?.team_id)}</Text>
                                :<></>}
                                {renderButton('home')}
                            </View>
                        </View>
                        {round_event?.home_side_id && home_placeholder_pick && home_placeholder_team?
                        <View style={{ padding:4, borderBottomRightRadius:4, borderBottomLeftRadius:4, backgroundColor:Colors.shades.shade600 }}>
                            <Text size={10} weight='semibold' color={home_placeholder_pick.result_ind=='win'?Colors.utility.success:Colors.utility.error}>{home_placeholder_team.market_name} {home_placeholder_team.name}</Text>
                        </View>
                        :<></>}
                    </View>
                )
        }
    }


    return (
        <View style={{ minWidth:125, width:round_width, backgroundColor:no_pick?Colors.shades.shade100:Colors.shades.white, borderRadius:4 }}>
            <TouchableOpacity style={{ borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => handleSelectAway()}>
                {renderSide('away')}
            </TouchableOpacity>
            <TouchableOpacity onPress={() => handleSelectHome()}>
                {renderSide('home')}
            </TouchableOpacity>
            {loading ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                <ActivityIndicator
                    size='large'
                    color={Colors.brand.midnight}
                />
            </View>
            :<></>}
        </View>
    )
}

export default RoundEventCard