import { View, TouchableOpacity, FlatList } from 'react-native';
import type { PlayerSquareProps, SquareOfferProps, SquareProps, SquareValueProps } from '../../types';
import { view_styles } from '../../constants/styles';
import { Button, Icons, Text } from '../../Components';
import React, { useState } from 'react';
import Colors from '../../constants/colors';
import { SquaresApi } from '../api';

type MySquaresCardProps = {
    player_squares:PlayerSquareProps[],
    squares:SquareProps[],
    square_offers:SquareOfferProps[],
    away_abbr?:string,
    home_abbr?:string,
    my_square_values:SquareValueProps[],
    comp_status: string,
    onSquareOffer: (square:SquareProps, action:'add'|'remove') => void
}

const MySquaresCard = ({ player_squares, squares, away_abbr, home_abbr, square_offers, my_square_values, comp_status, onSquareOffer }: MySquaresCardProps) => {
    const [ expanded, setExpanded ] = useState(false);
    
    const renderPlayerSquares = (data: { item:SquareValueProps, index: number }) => {
        const player_square = player_squares.find(ps => ps.sq_square_id == data.item.sq_square_id);
        const square = squares.find(s => s.sq_square_id == data.item.sq_square_id);
        if(!square || !player_square){ return <></> }
        const square_offer = square_offers.find(so => so.sq_player_square_id == player_square.sq_player_square_id && so.status == 'offered');
        const square_value = my_square_values.find(sv => sv.sq_square_id == player_square.sq_square_id);
        return (
            <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header_2'>SQUARE: {home_abbr}: {square.square_score_x}, {away_abbr}: {square.square_score_y}</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>${player_square.purchase_price.toFixed(2)}</Text>
                </View>
                {square_value ?
                <View style={{ paddingRight:20, paddingLeft:20 }}>
                    <Text size={14} weight='bold' color={square_value.expected_value > 0 ? Colors.utility.success : Colors.utility.error}>EV ${square_value.expected_payout.toFixed(2)}</Text>
                </View>
                :<></>}
                {comp_status == 'in_progress' ?
                <View>
                    {square_offer ?
                    <Button
                        title='WITHDRAW'
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.utility.warning}
                        onPress={async() => await SquaresApi.offserSquare({ ...square_offer, status: 'withdrawn' })}
                    />
                    :
                    <Button
                        title='SELL'
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.brand.electric}
                        onPress={() => onSquareOffer(square, 'add')}
                    />
                    }
                </View>
                :<></>}
            </View>
        )
    }


    return (
        <View style={{ ...view_styles.section, minWidth:350 }}>
            <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                <View style={{ height:18, width:18, borderRadius:100, backgroundColor:Colors.highlights.highlight200, justifyContent:'center', alignItems:'center' }}>
                    <Text size={10} weight='bold' color={Colors.shades.white} textAlign='center'>{player_squares.length}</Text>
                </View>
                <View style={{flex:1, marginLeft:10}}>
                    <Text theme='header'>MY SQUARES</Text>
                    <Text style={{ marginTop:3 }} theme='body_2'>Square you either own or have the highest bid on</Text>
                </View>
                <Icons.ChevronIcon direction={expanded?'up':'down'} size={8} color={Colors.brand.midnight}/>
            </TouchableOpacity>
            {expanded ? 
            <View style={view_styles.section_body}>
                <FlatList
                    data={my_square_values.sort((a,b) => b.expected_value - a.expected_value)}
                    renderItem={renderPlayerSquares}
                    keyExtractor={(item) => item.sq_square_id.toString()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default MySquaresCard