import React, { useEffect, useState } from 'react';
import { View } from "react-native";
import type { BestAvailableOrderProps, EventProps, MarketProps, MatchProps, PublicPlayerProps, TournamentProps } from "../types";
import { PromotedOrderApi, PromotedOrderHelpers } from './api';
import { Button, Text } from '../Components';
import Colors from '../constants/colors';

type PromotedOrderProps = {
    player_id?:string,
    order:BestAvailableOrderProps,
    market:MarketProps,
    event?:EventProps,
    tournament?:TournamentProps,
    match?:MatchProps,
    onShareOrder:(order:BestAvailableOrderProps) => void,
    onViewMarket: (order:BestAvailableOrderProps) => void,
    onBuyOrder:(order:BestAvailableOrderProps) => void,
    onRejectOrder:(order:BestAvailableOrderProps) => void
}

const PromotedOrder = ({ order, player_id, event, tournament, match }:PromotedOrderProps) => {
    const [ player, setPlayer ] = useState<PublicPlayerProps>();
    const { contest_title, contest_time_detail } = PromotedOrderHelpers.getContestDetails(order, event, tournament, match);

    useEffect(() => {
        if(!order?.player_id){ return }
        if(player?.player_id == order.player_id){ return } //Already have the player
        getDataFromServer(order);
    },[order?.player_id])

    const getDataFromServer = async(order:BestAvailableOrderProps) => {
        const ps = await PromotedOrderApi.getPlayersByPlayerIds([order.player_id]);

        setPlayer(ps[0]);
    }

    console.log(contest_title, player_id)
    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:10 }}>
                <Text textAlign='center' size={14} color={Colors.brand.midnight}>{contest_time_detail}</Text>
            </View>
            <Button
                title='BUY'
                title_color={Colors.shades.white}
                backgroundColor={Colors.brand.electric}
                onPress={() => 
                    console.log('BUY THIS ORDER!!')
                }
            />
        </View>
    )
}

export default PromotedOrder