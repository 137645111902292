import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View, Image } from 'react-native';
import { view_styles } from '../../constants/styles';
import { Button, Icons, Switch, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import ImageUploader from '../../Components/ImageUploader';
import type { CompetitionSeasonProps, MyPlayerProps } from '../../types';
import { ManageSeasonHelpers } from '../api';

type SeasonInfoFormProps = {
    width:number,
    player?:MyPlayerProps,
    competition_season:CompetitionSeasonProps,
    onSeasonUpdate: (cs:CompetitionSeasonProps) => void
}

const SeasonInfoForm = ({ competition_season, player, width, onSeasonUpdate }:SeasonInfoFormProps) => {
    const [ expanded, setExpanded ] = useState(false);
    const [ draft_season, setDraftSeason ] = useState<CompetitionSeasonProps>();

    useEffect(() => {
        setDraftSeason(competition_season)
    },[competition_season])

    if(!draft_season){ return <></> }

    const is_changed = ManageSeasonHelpers.isInfoChanged(competition_season, draft_season);

    return (
        <View style={{ ...view_styles.section }}>
            <TouchableOpacity style={{ ...view_styles.section_header }} onPress={() => setExpanded(!expanded)}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Season Info</Text>
                    <Text style={{ marginTop:3 }}>Update high level information for this competition season</Text>
                </View>
                {is_changed ?
                <Button
                    title='SAVE'
                    padding={10}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onSeasonUpdate(draft_season)}
                />
                :
                <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} color={Colors.brand.midnight} size={8} />
                }
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body }}>
                <View nativeID="competition_image" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme='header_2'>Competition Image</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Give this competition an image so it is recognizable to those trying to join.</Text>
                    </View>
                    <ImageUploader
                        public_id={`season_image_${draft_season.competition_season_id}_${Math.random()}`}
                        onFinishUpload={obj => onSeasonUpdate({ ...draft_season, image: { ...obj, url:obj.secure_url } })}
                    >
                        <Image
                            source={{ uri: draft_season.image?.url }}
                            style={{ height: 50, width:50, borderRadius:4 }}
                            resizeMode='cover'
                        />
                        <Text size={12} color={Colors.brand.electric} textAlign='center'>CHANGE</Text>
                    </ImageUploader>
                </View>
                <View nativeID="competition_name" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1, margin:5, minWidth:200 }}>
                        <Text theme='header_2'>Competition Name</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Give the competition a memorable name that aligns with the type and payout.</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, flexGrow:1, margin:5 }}
                        onChangeText={(text) => setDraftSeason({ ...draft_season, season_name:text })}
                        value={draft_season.season_name}
                        placeholder="Competition Name"
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>

                <View nativeID="competition_description" style={{ ...view_styles.body_row, flexWrap:'wrap', maxWidth:width, marginTop:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flex:1, margin:5, minWidth:200  }}>
                        <Text theme='header_2'>Competition Description</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Give the competition a memorable description that would entice users to participate.</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, flexGrow:1, margin:5 }}
                        onChangeText={(text) => setDraftSeason({ ...draft_season, season_description:text })}
                        value={draft_season.season_description}
                        placeholder="Pick 5 games ATS from NFL Week 6 matchups"
                        placeholderTextColor={Colors.brand.slate}
                    />
                </View>
                {player?.role == 'admin' ?
                <View nativeID="invite_only" style={{ ...view_styles.body_row, flexWrap:'wrap', marginTop:10, borderBottomWidth:1, borderColor:Colors.shades.shade600  }}>
                    <View style={{ flex:1, margin:5, marginRight:15 }}>
                        <Text theme='header_2'>Make Template</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Can this season be selected by others to replicate?  Any competition created in this competition will be extrapolated to others.</Text>
                    </View>
                    <Switch
                        value={draft_season.template ?? false}
                        switch_type="on_off"
                        onChange={(value) => onSeasonUpdate({ ...draft_season, template: value })}
                    />
                </View>
                :<></>}
                <View nativeID="invite_only" style={{ ...view_styles.body_row, flexWrap:'wrap', marginTop:10, borderBottomWidth:1, borderColor:Colors.shades.shade600  }}>
                    <View style={{ flex:1, margin:5, marginRight:15 }}>
                        <Text theme='header_2'>Make Private</Text>
                        <Text style={{ marginTop:3 }} theme='body'>Only access this competition using an invite code.</Text>
                    </View>
                    <Switch
                        value={draft_season.invite_only}
                        switch_type="on_off"
                        onChange={(value) => onSeasonUpdate({ ...draft_season, invite_only: value })}
                    />
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default SeasonInfoForm