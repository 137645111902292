import { Button, Icons, Text } from "../../Components";
import type { EventProps, PlayerSquareProps, SquareOfferProps, SquareProps, SquareResultProps, SquaresCompetitionProps } from "../../types"
import { View, FlatList, Image, TouchableOpacity } from 'react-native';
import SquareCard from "./SquareCard";
import Countdown from './Countdown';
import { SqauresHelpers } from "../api";
import Colors from "../../constants/colors";
import { view_styles } from "../../constants/styles";
import React, { useState } from "react";
import moment from "moment-mini";

type SquaresBoardProps = {
    squares_competition:SquaresCompetitionProps,
    squares:SquareProps[],
    event?:EventProps,
    onRefreshCompetition:() => void,
    onSquareBid:(square:SquareProps, action:'add'|'remove') => void,
    onSquareOffer:(square:SquareProps, action:'add'|'remove') => void,
    player_id?:string,
    square_offers:SquareOfferProps[],
    player_squares:PlayerSquareProps[],
    player_square_history:PlayerSquareProps[],
    square_results:SquareResultProps[],
    square_bids:PlayerSquareProps[],
    draft_square_offers:SquareOfferProps[]

}
const SquaresBoard = ({ 
    player_id, event, square_bids, draft_square_offers, squares_competition, player_square_history, square_offers, squares, player_squares, onRefreshCompetition, onSquareBid, onSquareOffer 
}:SquaresBoardProps) => {
    const [ expanded, setExpanded ] = useState(true);
    const [ times_up, setTimesUp ] = useState(false);
    const [ refresh_allowed, setRefreshAllowed ] = useState(false);

    const comp_status = SqauresHelpers.getCompStatus(event, squares_competition);
    const unique_score_y = [ ...new Set(squares.map(s => s.square_score_y)) ].sort((a,b) => a - b)

    const handleSquareSelect = (square:SquareProps, action:'remove'|'add') => {
        switch(comp_status){
            case 'not_started': 
                if(times_up){ return alert('Auction has ended') }
                return onSquareBid(square, action)
            case 'closed': return alert('Auction squares has completed')
            case 'in_progress': return onSquareOffer(square, action) 
            default: return
        }
    }
    
    const renderXSquares = (data: { item:SquareProps, index:number }) => {
        let player_square = player_squares.find(s => s.sq_square_id == data.item.sq_square_id)
        let draft_player_square = square_bids.find(s => s.sq_square_id == data.item.sq_square_id)
        let draft_square_offer = draft_square_offers.find(s => s.sq_player_square_id == player_square?.sq_player_square_id)
        let outbid_player_square:PlayerSquareProps | undefined = undefined
        let square_offer = square_offers.find(s => s.sq_player_square_id == player_square?.sq_player_square_id)
        if(player_id && player_square && player_square.player_id != player_id){ 
            outbid_player_square = player_square_history.find(s => s.sq_square_id == data.item.sq_square_id) 
        }
        const event_status = SqauresHelpers.getCompStatus(event, squares_competition);
        return (
            <SquareCard
                player_id={player_id}
                square={data.item}
                outbid_player_square={outbid_player_square}
                market_type={squares_competition.market_type}
                player_square={player_square}
                draft_player_square={draft_player_square}
                draft_square_offer={draft_square_offer}
                square_offer={square_offer}
                event={event}
                event_status={event_status}
                onSquareSelect={(square, action) => handleSquareSelect(square, action)}
            />
        )
    }
    
    const renderYSquares = (data: { item:number, index:number }) => {
        const x_squares = squares.filter(s => s.square_score_y == data.item);
        return (
            <View>
                <FlatList
                    data={x_squares.sort((a,b) => a.square_score_x - b.square_score_x)}
                    horizontal
                    scrollEnabled={true}
                    renderItem={renderXSquares}
                    keyExtractor={(item) => item.sq_square_id.toString()}
                />
            </View>
        )
    }

    const away_name = `${event?.away?.market_name} ${event?.away?.name}`
    const TEXT_LENGTH = away_name.length * 8
    const TEXT_HEIGHT = 20;
    const OFFSET = TEXT_LENGTH / 2 - TEXT_HEIGHT / 2
    
    let total_payout = squares_competition.potential_winnings
    if(squares_competition.guaranteed_payout && squares_competition.guaranteed_payout > total_payout){ total_payout = squares_competition.guaranteed_payout }

    return (
        <View style={{ ...view_styles.section, minWidth:350, maxWidth:600 }}>
            <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                <Icons.SquaresIcon size={18} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header">SQUARES BOARD</Text>
                    <Text style={{ marginTop:3 }} theme="body_2">Bid, buy, sell squares using the board below.</Text>
                </View>
                <View style={{ paddingLeft:10, paddingRight:10 }}>
                    <Text size={14} color={Colors.utility.success} weight='bold'>${total_payout.toFixed(2)}</Text>
                </View>
                <Icons.ChevronIcon direction={expanded?'up':'down'} color={Colors.brand.midnight} size={8} />
                <View style={{ position:'absolute', top:0, left:10, bottom:0, justifyContent:'center' }}>
                    <Countdown 
                        end_datetime={moment(squares_competition.begin_datetime)}
                        label={'Auction ends in'}
                        onTimesUp={() => {
                            setTimesUp(true);
                            setTimeout(() => {
                                setRefreshAllowed(true);
                            }, 5000);
                        }}
                    />
                </View>
            </TouchableOpacity>
            {expanded ?
            <View style={view_styles.section_body} nativeID="board">
                <View style={{ flexDirection:'row', overflow:'scroll', marginLeft:20 }}>
                    <View>
                        <View style={{ flexDirection:'row',  marginLeft:4 }}>
                            {unique_score_y.map(num => {
                                return (
                                    <View key={`y_${num}`} style={{ margin:3, justifyContent:'center', alignItems:'center', height:20, width:40, backgroundColor:'transparent' }}>
                                        <Text theme="header_2" textAlign="center">{num}</Text>
                                    </View>
                                )
                            })}
                        </View>
                        <View style={{ backgroundColor:Colors.shades.shade100, padding:2, borderRadius:8, marginLeft:2 }}>
                            <FlatList
                                data={unique_score_y}
                                scrollEnabled
                                renderItem={renderYSquares}
                                keyExtractor={(item) => `x_${item}`}
                            />
                        </View>
                    </View>
                </View>
                <View style={{ position:'absolute', top: 47, backgroundColor:'transparent' }}>
                    {unique_score_y.map(num => {
                        return (
                            <View key={`x_${num}`} style={{ margin:3, justifyContent:'center', alignItems:'center', height:40, width:20, backgroundColor:'transparent' }}>
                                <Text theme='header_2'>{num}</Text>
                            </View>
                        )
                    })}
                </View>
                {event && event.home ?
                <View style={{ position:'absolute', top:4, left:0, right:0, justifyContent:'center', alignItems:'center' }}>
                    <Text theme="header_2">{event.home.market_name} {event.home.name}</Text>
                </View>
                :<></>}
                {event && event.away ?
                <View style={{ position:'absolute', top:0, left:4, bottom:0, justifyContent:'center' }}>
                    <View style={{ width: TEXT_HEIGHT, height: TEXT_LENGTH }}>
                        <Text 
                            textAlign="center"
                            style={{
                                transform: [
                                    { rotate: "-90deg" }, 
                                    { translateX: -OFFSET }, 
                                    { translateY: -OFFSET }
                                ],
                                width: TEXT_LENGTH,
                                height: TEXT_HEIGHT
                            }}
                            theme="header_2"
                        >
                            {away_name}
                        </Text>
                    </View>
                </View>
                :<></>}
                {times_up ?
                <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                    <View style={{ ...view_styles.section, width:250, height:250 }}>
                        <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100, justifyContent:'center' }}>
                            <Text theme="header">AUCTION HAS ENDED</Text>
                        </View>
                        <View style={{ ...view_styles.section_body, justifyContent:'center', alignItems:'center'}}>
                            <Image
                                source={{ uri : squares_competition.image?.url }}
                                style={{ height:75, width:75, borderRadius:4, marginBottom:20 }}
                                resizeMode="cover"
                            />
                            <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign="center">Assigning unbid squares based on board ownership % ...</Text>
                        </View>
                        <View style={{ ...view_styles.section_footer }}>
                            <Button
                                title="SEE FINAL BOARD"
                                style={{ flex:1, opacity:refresh_allowed?1:0.5 }}
                                disabled={!refresh_allowed}
                                title_color={Colors.brand.electric}
                                borderRadius={8}
                                borderWidth={1}
                                borderColor={Colors.brand.electric}
                                backgroundColor={Colors.shades.white}
                                onPress={() => {
                                    setRefreshAllowed(false);
                                    onRefreshCompetition();
                                    setTimesUp(false);
                                }}
                            />
                        </View>
                    </View>
                </View>
                :<></>}
            </View>
            :<></>}
        </View>
    )
}

export default SquaresBoard