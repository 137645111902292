import React, { useEffect, useRef } from 'react';
import { View, Image, Linking, TouchableOpacity } from 'react-native';
import type { BEEventProps, CompanyProps } from '../../types';
import { view_styles } from '../../constants/styles';
import { Text } from '../../Components';
import Colors from '../../constants/colors';
import { useIsInViewport } from '../../Components/ViewportObserver';

type CompanyCardProps = {
    company:CompanyProps,
    width:number,
    onEvent?:(be_event:BEEventProps) => void,
    onSelectCompany?:(company:CompanyProps) => void
}
const MAX_IMAGE_WIDTH = 175

const CompanyCard = ({company, width, onEvent, onSelectCompany}:CompanyCardProps) => {
    
    const company_ref = useRef(null)
    const isInViewport = useIsInViewport(company_ref);
    useEffect(() => {
        if(isInViewport && onEvent){
            let be_event:BEEventProps = {
                event_name:'company_view',
                event_data: {
                    view_type: 'engage',
                    view_location: 'header',
                },
                level:2
            }
            
            onEvent(be_event)
        }
    },[isInViewport])

    const image_width = width > MAX_IMAGE_WIDTH ? MAX_IMAGE_WIDTH : width

    let description = company.company_description
    if(description.length > 50){
        description = `${description.slice(0, 50)}...`
    }

    return (
        <TouchableOpacity ref={company_ref} style={{ ...view_styles.section, width:image_width, backgroundColor:company.brand_primary ?? Colors.shades.shade100, borderRadius:8  }}
        onPress={() => {
            if(onSelectCompany){ return onSelectCompany(company) }
            if(company.company_url){ Linking.openURL(company.company_url) }
            return
        }}>
            <View style={{ padding:10, flexDirection:'row', alignItems:'center' }}>
                <View style={{ flex:1 }}>
                    <Text size={14} color={Colors.shades.white} weight='bold'>{company.company_name}</Text>
                </View>
            </View>
            <View style={{ backgroundColor:Colors.shades.shade100, borderBottomRightRadius:8, borderBottomLeftRadius:8 }}>
                <Image
                    source={{ uri: company.company_image?.url }}
                    style={{ height: image_width, width:image_width, borderBottomRightRadius:8, borderBottomLeftRadius:8 }}
                    resizeMode='cover'
                />
                 <View style={{ position:'absolute', backgroundColor:Colors.shades.white, bottom:0, left:0, right:0, padding:10, borderBottomRightRadius:8, borderBottomLeftRadius:8 }}>
                    <Text theme='header_2'>{description}</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
}

export default CompanyCard