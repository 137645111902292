import React, { useEffect, useState } from 'react';
import { View, ScrollView, ActivityIndicator } from 'react-native';
import SeasonInfoForm from './components/SeasonInfoForm';
import { ManageSeasonApi, ManageSeasonHelpers } from './api';
import type { CompetitionPayoutTypeProps, CompetitionProps, CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionTypeProps, MyPlayerProps } from '../types';
import Colors from '../constants/colors';
import CompetitionsForm from './components/CompetitionsForm';
import SeasonSettingsForm from './components/SeasonSettingsForm';
import CreateCompetitionForm from '../CompetitionManager/components/CreateCompetitionForm';
import SeasonHeader from './components/SeasonHeader';

type SeasonManagerProps = {
    competition_season_id:string,
    player_id?:string,
    onCompetitionCreate:(c:CompetitionProps) => void,
    onCompetitionSelect:(c:CompetitionProps) => void
}

const SeasonManager = ({ competition_season_id , onCompetitionCreate, onCompetitionSelect}:SeasonManagerProps) => {
    const [ show_create_competition, setShowCreateCompetition ] = useState(false);
    const [ module_size, setModuleSize ] = useState({ width: 0, height: 0 });
    const [ season_data, setData ] = useState<{
        loading: boolean,
        action_loading:boolean,
        player?:MyPlayerProps,
        competition_season?:CompetitionSeasonProps,
        competition_types:CompetitionTypeProps[],
        competition_result_types:CompetitionResultTypeProps[],
        competition_payout_types:CompetitionPayoutTypeProps[],
        competitions:CompetitionProps[]
    }>({
        loading:false,
        action_loading: false,
        competition_payout_types:[],
        competition_result_types: [],
        competition_types:[],
        competitions: []
    })
    const { action_loading, player, competition_types, competition_result_types, competition_season, competitions } = season_data;

    useEffect(() => {
        ManageSeasonApi.setEnvironment();
        getDataFromServer(competition_season_id);
    },[competition_season_id])

    const getDataFromServer = async(id:string) => {
        setData({ ...season_data, loading: true });
        const cs = await ManageSeasonApi.getSeasonById(id);
        const comps = await ManageSeasonApi.getCompetitionsBySeasonId(id);
        const opts = await ManageSeasonApi.getCompetitionOptions();
        const me = await ManageSeasonApi.getMyDetails();
        setData({ 
            ...season_data,
            competition_season: cs,
            competitions: comps,
            player:me,
            competition_payout_types: opts.competition_payout_types,
            competition_result_types: opts.competition_result_types,
            competition_types: opts.competition_types
        })
    }


    if(!competition_season){
        return (
            <View style={{ flex:1 }}>
                <ActivityIndicator size={'large'} color={Colors.brand.midnight} style={{ padding:20, alignSelf:'center' }} />
            </View>
        )
    }

    const errors = ManageSeasonHelpers.isSeasonValid(competition_season)


    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1 }} onLayout={(ev) => {
                const { width, height } = ev.nativeEvent.layout;
                setModuleSize({ width, height })
            }}>
                <SeasonHeader
                    competition_season={competition_season}
                    width={module_size.width}
                    action_loading={action_loading}
                    season_valid={errors.length > 0 ? false : true}
                    onActivate={async() => {
                        setData({ ...season_data, action_loading: true })
                        const new_cs = await ManageSeasonApi.activateSeason(competition_season.competition_season_id);
                        setData({
                            ...season_data,
                            action_loading: false,
                            competition_season:new_cs
                        })
                    }}
                    onDelete={async() => {
                        setData({ ...season_data, action_loading: true })
                        const new_cs = await ManageSeasonApi.deleteSeason(competition_season.competition_season_id);
                        setData({
                            ...season_data,
                            action_loading: false,
                            competition_season:new_cs
                        })
                    }}
                />
                <SeasonInfoForm
                    width={module_size.width}
                    player={player}
                    competition_season={competition_season}
                    onSeasonUpdate={async(cs) => {
                        setData({ ...season_data, action_loading: true })
                        const new_s = await ManageSeasonApi.updateSeason(cs);
                        setData({ ...season_data, action_loading: false, competition_season: new_s })
                    }}
                />
                <SeasonSettingsForm
                    width={module_size.width}
                    competition_season={competition_season}
                    competition_types={competition_types}
                    competition_result_types={competition_result_types}
                    onSeasonUpdate={async(cs) => {
                        setData({ ...season_data, action_loading: true })
                        const new_s = await ManageSeasonApi.updateSeason(cs);
                        setData({ ...season_data, action_loading: false, competition_season: new_s })
                    }}
                />
                <CompetitionsForm
                    onAddCompetition={() => setShowCreateCompetition(true)}
                    competitions={competitions}
                    onCompetitionSelect={(c) => onCompetitionSelect(c)}
                    competition_season={competition_season}
                    competition_result_types={competition_result_types}
                    competition_types={competition_types}
                />
            </ScrollView>
            {show_create_competition ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
                <CreateCompetitionForm
                    width={module_size.width}
                    competition_season={competition_season}
                    onClose={() => setShowCreateCompetition(false)}
                    onCreate={(competition) => {
                        setData({
                            ...season_data,
                            competitions: competitions.concat(competition)
                        })
                        onCompetitionCreate(competition)
                    }}
                />
            </View>
            :<></>}
        </View>
    )
}

export default SeasonManager