import React from 'react';
import { View, FlatList, Image } from 'react-native';
import type { FundOwnershipProps, PublicPlayerProps } from "../../types"
import { view_styles } from '../../constants/styles';
import { Text } from '../../Components';
import Colors from '../../constants/colors';
import moment from 'moment-mini';

type FundOwnershipListProps = {
    fund_ownerships:FundOwnershipProps[],
    players:PublicPlayerProps[]
}
const FundOwnershipList = ({ fund_ownerships, players }:FundOwnershipListProps) => {

    const renderFundOwnerships = (data:{ item:FundOwnershipProps, index:number }) => {
        const player = players.find(p => p.player_id == data.item.player_id);
        if(!player){ return <></> }
        return (
            <View style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <Image
                    source={{ uri: player.profile_pic && player.profile_pic != '' ? player.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:40, width:40, borderRadius:100 }}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{player.first_name} {player.last_name}</Text>
                    <Text style={{ marginTop:3 }} size={12} weight='regular' color={Colors.brand.midnight}>Owner since {moment(data.item.create_datetime).format('MMM DD YYYY')}</Text>
                </View>
                <Text size={14} weight='bold' color={Colors.brand.midnight}>{(data.item.ownership_pct * 100).toFixed(2)}%</Text>
            </View>
        )
    }

    return (
        <View style={{ ...view_styles.section }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>Fund Owners</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Below are the players that have invested in this fund sorted by their ownership pct.</Text>
                </View>
            </View>
            <View style={{ ...view_styles.section_body, padding:0 }}>
                <FlatList
                    key={'fund_ownerships'}
                    data={fund_ownerships.sort((a,b) => b.ownership_pct - a.ownership_pct)}
                    keyExtractor={(item) => item.fund_ownership_id.toString()}
                    renderItem={renderFundOwnerships}
                />
            </View>
        </View>
    )
}

export default FundOwnershipList