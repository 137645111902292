import React from 'react';
import { View, TouchableOpacity ,Image } from 'react-native'
import Colors from '../../constants/colors';
import type { CompetitionProps, CompetitionSeasonPlayerProps, CompetitionSeasonProps, MyPlayerProps } from '../../types';
import { Button, Icons, Text } from '../../Components';
import { SeasonHelpers } from '../api';
import moment from 'moment-mini';

type SeasonHeaderProps = {
    competition_season:CompetitionSeasonProps,
    competitions:CompetitionProps[],
    player?:MyPlayerProps,
    action_loading:boolean,
    authenticated_season_player?:CompetitionSeasonPlayerProps,
    onShareSeason:(cs:CompetitionSeasonProps) => void,
    onJoin:(competition_season:CompetitionSeasonProps) => void,
    onLeave:(competition_season_player_id:string) => void

}
const SeasonHeader = ({ action_loading, authenticated_season_player, competition_season, competitions, onShareSeason, onJoin, onLeave }:SeasonHeaderProps) => {

    const can_join = SeasonHelpers.canJoin(competition_season, competitions);
    const can_leave = true;

    return (
        <View style={{ backgroundColor:Colors.shades.white }}>
            <View nativeID="competition_header" style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <View>
                    <Image
                        source={{ uri: competition_season.image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1649737862/be_logo_jte2ux.webp' }}
                        style={{ height:50, width:50, borderRadius:4 }}
                        resizeMode="cover"
                    />
                </View>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>{competition_season.season_name}</Text>
                    <Text style={{ marginTop:4 }} theme='header_2>'>{competition_season.season_description}</Text>
                </View>
                <TouchableOpacity style={{ paddingLeft:10 }} onPress={() => onShareSeason(competition_season)}>
                    <Icons.ShareIcon color={Colors.brand.midnight} size={14} />
                </TouchableOpacity>
            </View>
            
            <View style={{ flexDirection:'row', padding:10, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flex:1 }}>
                    <Text size={12} color={Colors.brand.midnight} weight='regular'>{competition_season.status == 'closed' ? 'COMPLETED' : competition_season.status == 'pending' ? 'UNDER CONSTRUCTION': can_join ? 'JOIN' : 'IN PROGRESS'}</Text>
                    {competition_season.status == 'pending' ?
                    <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='bold'>The admin is still working on this season</Text>
                    : can_join ?
                    <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='bold'>{competition_season.allow_late_buy_in ? 'anytime during the season' : `until ${moment(competition_season.scheduled_datetime).format('MMM DD hh:mm a')}`}</Text>
                    :
                    <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight} weight='bold'>{moment(competition_season.scheduled_datetime).format('MMM DD hh:mm a')}</Text>
                    }
                </View>
                {!authenticated_season_player ?
                <Button
                    title='JOIN'
                    disabled={action_loading}
                    style={{ opacity: action_loading ? 0.5 : 1 }}
                    loading={action_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onJoin(competition_season)}
                />
                :
                <Button
                    title='LEAVE'
                    backgroundColor={Colors.utility.error}
                    loading={action_loading}
                    title_color={Colors.shades.white}
                    style={{ opacity: can_leave && !action_loading ? 1: 0.5 }}
                    disabled={!can_leave || action_loading}
                    onPress={() => onLeave(authenticated_season_player.competition_season_player_id)}
                />
                }
            </View>
        </View>
    )
}

export default SeasonHeader