import React from 'react';
import { View, TouchableOpacity, ScrollView } from "react-native"
import type { AccountProps, MyPlayerProps, PlayerBalanceProps } from '../../types';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { button_styles } from '../../constants/styles';

type MyBalanceProps = {
    player:MyPlayerProps,
    player_balance:PlayerBalanceProps,
    balance_account?:AccountProps,
    walkthrough?:boolean,
    onClose:() => void,
    onNext:() => void
}

const MyBalance = ({ player_balance, onClose, onNext }:MyBalanceProps) => {


    return (
        <View style={{ flex:1 }}>
             <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>BETTOREDGE BALANCES</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>All order and competition activity goes in and out of your BettorEdge balances.  The specific balance depends on which app-mode you are in.</Text>
            </View>
            <ScrollView style={{ flex:1, padding:10 }}>
                <View style={{ padding:10, paddingBottom:0 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='bold' textAlign='left'>APP MODES</Text>
                </View>
                <View style={{ backgroundColor:Colors.brand.cobalt, padding:10, borderRadius:8, margin:10 }}>
                    <View style={{ padding:5, flexDirection:'row', alignItems:'center' }}>
                        <Text style={{ flex:1 }} size={26} color={Colors.shades.white} weight='semibold'>Real-Money Mode</Text>
                        <Icons.USDIcon size={30} color={Colors.shades.white} />
                    </View>
                    <View style={{ padding:5, paddingBottom:10, borderBottomWidth:1, borderBottomColor:Colors.accents.accent200 }}>
                        <Text size={14} weight='regular' color={Colors.shades.shade100}>When in real-money mode.  The app header will show as this dark blue color.  All activity when in this mode will use your real-money (USD) balance.</Text>
                    </View>
                    <View style={{ padding:10, flexDirection:'row', alignItems:'center' }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.shades.white}>Real-Money / USD ($)</Text>
                        <Text size={16} weight='bold' color={Colors.shades.white}>${player_balance.balance.toFixed(2)}</Text>
                    </View>
                    <View style={{ padding:10 }}>
                        <View style={{ flexDirection:'row', alignItems:'center' }}>
                            <Text style={{ flex:1 }} size={14} color={Colors.shades.white}>Promo (P)</Text>
                            <Text size={16} weight='bold' color={Colors.shades.white}>P{player_balance.promo_balance.toFixed(2)}</Text>
                        </View>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.utility.warning} weight='semibold'>Promo balance can be used for all real-money activities. Winnings will be returned as Real-Money/USD ($).</Text>
                    </View>
                </View>
                
                <View style={{ backgroundColor:Colors.brand.cyan, padding:10, borderRadius:8, margin:10 }}>
                    <View style={{ padding:5, flexDirection:'row', alignItems:'center' }}>
                        <Text style={{ flex:1 }} size={26} color={Colors.shades.white} weight='semibold'>Demo Mode</Text>
                        <Icons.EdgeCoinIcon size={30} color={Colors.shades.white} />
                    </View>
                    <View style={{ padding:5, paddingBottom:10, borderBottomWidth:1, borderBottomColor:Colors.accents.accent100 }}>
                        <Text size={14} weight='regular' color={Colors.shades.shade100}>When in demo mode.  The app header will show as this light blue color.  All activity when in this mode will use your Edge Coin (E) balance.</Text>
                    </View>
                    <View style={{ padding:10, flexDirection:'row', alignItems:'center' }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.shades.white}>Edge Coins (E)</Text>
                        <Text size={16} weight='bold' color={Colors.shades.white}>E{player_balance.free_market_balance.toFixed(2)}</Text>
                    </View>
                </View>
            </ScrollView>
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    title='EXIT SETUP'
                    title_color={Colors.utility.error}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
                <TouchableOpacity
                    style={button_styles.wizard_valid}
                    onPress={() => onNext()}
                    //disabled={loading}
                    >
                    <Icons.ChevronIcon size={20} direction='right' color={Colors.brand.midnight}/>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default MyBalance