import { TouchableOpacity, View } from "react-native"
import type { SquaresCompetitionProps, SquaresPayoutProps, SquaresTypeProps } from "../../types"
import { view_styles } from "../../constants/styles"
import { Icons, Text } from "../../Components"
import Colors from "../../constants/colors"
import React, { useState } from "react"

type SquaresDetailsProps = {
    squares_competition:SquaresCompetitionProps,
    squares_type?:SquaresTypeProps,
    squares_payout_type?:SquaresPayoutProps
}

const SquaresDetails = ({ squares_competition, squares_type, squares_payout_type }:SquaresDetailsProps) => {
    const [ expanded, setExpanded ] = useState(false);
    return (
        <View nativeID="competition_details" style={{ ...view_styles.section, minWidth:350 }}>
            <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                <Icons.SettingsIcon size={18} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme="header">COMPETITION DETAILS</Text>
                    <Text style={{ marginTop: 4 }} theme="body_2">Detailed Information on this competition</Text>
                </View>
                <Icons.ChevronIcon direction={expanded?'up':'down'} color={Colors.brand.midnight} size={8} />
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body }}>
                {squares_type ?
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="body">Type</Text>
                    <Text theme="header_2">{squares_type.sq_type_name}</Text>
                </View>
                :<></>}
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="body">Initial Square Price</Text>
                    <Text theme="header_2">${squares_competition.minimum_square_price.toFixed(2)}</Text>
                </View>
                {squares_payout_type ?
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="body">Payout</Text>
                    <Text theme="header_2">{squares_payout_type.sq_payout_name.toUpperCase()}</Text>
                </View>
                :<></>}
                <View style={view_styles.body_row}>
                    <Text style={{flex:1}} theme="body">Payout Frequency</Text>
                    <Text theme="header_2">{squares_competition.payout_frequency} Quarters</Text>
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default SquaresDetails