import React, { useState } from 'react';
import { ScrollView, View, FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import { Button, Icons, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import type { SettingProps } from '../api/types';
import type { MyPlayerProps, PlayerSettingProps } from '../../types';
import { ProfileApi } from '../api';

type SettingsManagerProps = {
    title: string,
    description: string,
    player:MyPlayerProps,
    settings:SettingProps[],
    icon:any,
    player_settings: PlayerSettingProps[],
    onUpdatePlayerSetting:(ps:PlayerSettingProps) => void
}

const SettingsManager = ({ player, title, description, icon, settings, player_settings, onUpdatePlayerSetting }:SettingsManagerProps) => {
    const [ draft_setting, setDraftSetting ] = useState<PlayerSettingProps>()

    const handleUpdateSetting = async(setting:SettingProps, option:string, status:'active'|'inactive', player_setting?:PlayerSettingProps) => {
        if(player_setting){ player_setting.status = status }
        else {
            player_setting = {
                player_setting_id:'',
                player_id: '',
                setting_id: setting.setting_id,
                selected_option: option,
                create_datetime: '', last_update_datetime: '',
                status
            }
        }
        player_setting = await ProfileApi.updatePlayerSetting(player_setting)
        setDraftSetting(undefined)
        onUpdatePlayerSetting(player_setting)
    }

    const renderPreferences = (data: { item:SettingProps, index:number }) => {
        const my_settings = player_settings.filter(ps => ps.setting_id == data.item.setting_id);
        const editing = data.item.setting_id == draft_setting?.setting_id ? true : false;
        let shown_setting = editing ? draft_setting : my_settings[0]
        const is_changed = shown_setting?.selected_option != my_settings[0]?.selected_option ? true : false
        let disabled = false
        if(data.item.setting == 'collar_pct' && player.type != 'premium'){ disabled = true }
        return (
            <View style={local_styles.settings_container}>
                <View style={{ padding:5, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <View style={{ flexDirection:'row', alignItems:'center' }}>
                        <Text style={{flex:1}} size={14} color={Colors.brand.midnight} weight='bold'>{data.item.setting_label}</Text>
                        {disabled ?
                        <Text style={{ marginLeft:5 }} size={14} color={Colors.incentive.gold} weight='bold'>PREMIUM ONLY</Text>
                        :<></>}
                    </View>
                    <Text style={{ marginTop:5 }} size={12} color={Colors.brand.midnight}>{data.item.setting_description}</Text>
                </View>
                <View>
                    {data.item.option_type == 'number_input' ?
                    <View>
                        <View style={{flexDirection:'row', alignItems:'center', padding:10}}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>My Setting</Text>
                            <TextInput
                                style={{ padding:10 }}
                                editable={editing}
                                value={shown_setting?.selected_option}
                                onChangeText={(text) => {
                                    if(!draft_setting){ return }
                                    setDraftSetting({ ...draft_setting, selected_option: text })
                                }}
                            />
                            {!editing ?
                            <Button
                                title='EDIT'
                                style={{ marginLeft:5, opacity:disabled?0.5:1 }}
                                disabled={disabled}
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.brand.electric}
                                onPress={() => setDraftSetting(my_settings[0])}
                            />
                            :
                            <Button
                                title='X'
                                style={{ marginLeft:5 }}
                                title_color={Colors.utility.error}
                                borderColor={Colors.utility.error}
                                borderWidth={1}
                                onPress={() => setDraftSetting(undefined)}
                            />
                            }
                        </View>
                        {is_changed ?
                        <Button
                            title='SAVE'
                            backgroundColor={Colors.utility.success}
                            title_color={Colors.shades.white}
                            onPress={() => {
                                if(!shown_setting){ return }
                                handleUpdateSetting(data.item, shown_setting.selected_option, 'active', shown_setting)}
                            }
                        />
                        :<></>}
                    </View>
                    :data.item.option_type == 'select' ?
                    <View nativeID='setting_options' style={{ padding:15, flexDirection:'row', justifyContent:'space-between' }}>
                    {data.item.setting_options.map(so => {
                        const my_setting = my_settings.find(ms => ms.selected_option == so)
                        return (
                            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }}
                            onPress={() => handleUpdateSetting(data.item, so, my_setting?.status == 'active' ? 'inactive' : 'active', my_setting)}>
                                <View style={{ height:20, width:20, justifyContent:'center', alignItems:'center', borderWidth:1, borderRadius:100, borderColor:Colors.brand.electric, backgroundColor:my_setting?.status == 'active' ? Colors.brand.electric : 'transparent' }}>
                                    {my_setting?.status == 'active' ?
                                    <Icons.CheckIcon color={Colors.shades.white} size={10} />
                                    :<></>}
                                </View>
                                <Text style={{ marginLeft:10 }} size={12} color={Colors.brand.midnight} weight='semibold'>{so.toUpperCase()}</Text>
                            </TouchableOpacity>
                        )
                    })}
                </View>
                    :<></>}
                </View>
            </View>
        )
    }

    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1 }}>
                <View nativeID='preference_settings' style={{ padding:30 }}>
                    <View nativeID='header' style={{ flexDirection:'row', alignItems:'center' }}>
                        <View style={{ padding:10, backgroundColor:Colors.brand.electric, borderRadius:8 }}>
                            {icon}
                        </View>
                        <View style={{ flex:1, marginLeft:5 }}>
                            <Text size={16} color={Colors.brand.midnight} weight='bold'>{title}</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{description}</Text>
                        </View>
                    </View>
                    <View nativeID='notification_options' style={{ marginTop:20 }}>
                        <FlatList
                            data={settings}
                            renderItem={renderPreferences}
                            keyExtractor={(item) => item.setting_id.toString()}
                        />
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

const local_styles = StyleSheet.create({
    settings_container: {
        margin:3,
        backgroundColor:Colors.shades.shade100,
        padding:10
    }
})

export default SettingsManager