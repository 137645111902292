import React, { useEffect, useState } from "react";
import { View } from 'react-native';
import Colors from "../../constants/colors";
import type { Moment } from "moment-mini";
import moment from "moment-mini";
import { Text } from "../../Components";

type CountdownProps = {
    end_datetime:Moment
    label?:string,
    onTimesUp: () => void
}
const Countdown = ({ end_datetime, label, onTimesUp }:CountdownProps) => {
    const [ started, setStarted ] = useState(false);
    const [ seconds_remaining, setSecondsRemaining ] = useState(-1);
    const [ times_up, setTimesUp ] = useState(false);

    useEffect(() => {
        let diff_seconds = end_datetime.diff(moment(), 'seconds')
        if(diff_seconds < 0){ return }
        setSecondsRemaining(diff_seconds)
        setStarted(true)
    },[end_datetime])

    useEffect(() => {
        if(!started){ return }
        if(seconds_remaining > 0){
            setTimeout(() => setSecondsRemaining(seconds_remaining - 1), 1000);
        } else {
            setTimesUp(true)
        }
    },[seconds_remaining])

    
    useEffect(() => {
        if(!times_up){ return }
        onTimesUp()
    },[times_up])

    if(seconds_remaining < 0 || seconds_remaining > 600){ return <></> }

    return (
        <View style={{ flexDirection:'row', alignItems:'center', minWidth:50, padding:10, borderRadius:22, backgroundColor:seconds_remaining < 30 ? Colors.utility.error : seconds_remaining < 100 ? Colors.utility.warning  : Colors.utility.success }}>
            {label ?
            <Text style={{ marginRight:5 }} size={14} color={Colors.shades.white} weight='regular'>{label}</Text>
            :<></>}
            <Text textAlign="center" size={14} color={Colors.shades.white} weight='bold'>{seconds_remaining}</Text>
        </View>
    )
}

export default Countdown