import React from 'react';
import { ActivityIndicator, FlatList, View } from "react-native"
import MarketsCard from "./MarketsCard"
import type { AthleteProps, CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionProps, CompetitionTypeProps, EventProps, MarketProps, PlayerPickProps } from '../../types';
import moment from 'moment-mini';
import { CompetitionHelpers, FINALIZED_STATUSES } from '../api';
import { Text } from '../../Components';
import Colors from '../../constants/colors';
import LinearDiagnal from '../../Components/LinearDiagnal';
import { AthleteImage } from '../../Components/Jerseys';

type AthleteMarketCardProps = {
    competition:CompetitionProps,
    competition_type:CompetitionTypeProps,
    event:EventProps,
    markets:MarketProps[],
    athlete:AthleteProps,
    onPick:(pp:PlayerPickProps, event_title:string, event_start:string) => void
    mode: 'view'|'play'
    loading?:boolean,
    orientation:'horizontal'|'vertical',
    player_picks:PlayerPickProps[],
    competition_match:CompetitionMatchProps,
    competition_match_markets:CompetitionMatchMarketProps[]
}

const AthleteMarketCard = ({  markets, competition_type, event, athlete, mode, loading, player_picks, orientation, competition_match, competition_match_markets, onPick }:AthleteMarketCardProps) => {
    
    const market = markets.find(m => m.market_id == competition_match.market_id_override)
    const wager_picks = player_picks.filter(pp => pp.stake > 0)

    const handleMarketSelect = async(mm:CompetitionMatchMarketProps, player_pick?:PlayerPickProps) => {
        let market = markets.find(m => m.market_id == mm.market_id)
        if(!event || !competition_type || !market){ return alert('Unable to process') }
        if(moment(event.scheduled_datetime).isBefore(moment())){ return alert('This event has already started') }
        if(player_pick){
            return onPick(player_pick, event.event_title, event.scheduled_datetime)
            //await CompetitionStoreActions.removePlayerPick(player_pick.player_pick_id)
        } else {
            let pp = CompetitionHelpers.createPickFromMatchMarket(competition_match.competition_id, mm)
            let pick_title = CompetitionHelpers.getPickTitleForTeamEvent(pp, market, event, athlete)
            return onPick({ ...pp, pick_title }, event.event_title, event.scheduled_datetime)
        }
    }

    const renderWagerPicks = (data: { item:PlayerPickProps, index:number }) => {
        const locked = mode == 'view' && moment().isBefore(moment(event.scheduled_datetime)) ? true : false
        return (
            <View style={{ flexDirection:'row', marginTop:5 }}>
                <Text style={{ flex:1 }} size={12} color={Colors.highlights.highlight200}>{locked?'WAGER LOCKED':data.item.pick_title}</Text>
                <Text style={{ flex:1 }} size={12} color={Colors.highlights.highlight200} textAlign="right">{locked?'':`${data.item.stake.toFixed(2)} to win ${data.item.potential_winnings.toFixed(2)}`}</Text>
            </View>
        )
    }

    if(!athlete){ return <></> }
    return (
        <View style={{ flex:1 }}>
            {market ?
                <LinearDiagnal 
                label={market.stat_label ?? market.type}
                label_size={10}
                style={{ borderTopLeftRadius:8, padding:5  }}
                left_color={Colors.incentive.gold}
                right_color={'#ebd197'}
            />
            :<></>}
            <View style={{ padding:10, flex:1, flexDirection:orientation=='horizontal'?'row':'column', alignItems:orientation=='horizontal'?'center':undefined }}>
                
                <View nativeID="event_details" style={{ flex:1, flexGrow:1, flexDirection:orientation=='horizontal'?'row':'column', alignItems:'center' }}>
                    <View nativeID="athlete_image">
                        <AthleteImage athlete={athlete} size={38} number_size={10}/>
                    </View>
                    <View>
                        <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign="center">{athlete.first_name} {athlete.last_name}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular' textAlign="center">{athlete.team ? `${athlete.team.abbr} (${athlete.position})`:`${athlete.position}`}</Text>
                    </View>
                </View>
                <View nativeID="market_details" style={{flex:orientation=='horizontal'?1:undefined, margin:5 }}>
                    {orientation == 'horizontal' && market ?
                    <Text style={{ margin:2 }} size={14} color={Colors.shades.black} textAlign="center">{market.description}</Text>
                    :<></>}
                    <MarketsCard
                        player_picks={player_picks}
                        mode={mode}
                        markets={markets}
                        orientation="horizontal"
                        disabled={moment().isAfter(moment(event.scheduled_datetime)) || FINALIZED_STATUSES.includes(event.status) ? true : false}
                        locked={mode == 'view' && moment().isBefore(moment(event.scheduled_datetime)) ? true : false}
                        competition_match_markets={competition_match_markets}
                        onMarketSelect={(mm, player_pick) => handleMarketSelect(mm, player_pick)}
                    />
                    {loading ?
                    <View style={{ position:'absolute', top:0, right:0, left:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                        <ActivityIndicator
                            size='large'
                            color={Colors.brand.midnight}
                        />
                    </View>
                    :<></>}
                </View>
            </View>
            {wager_picks.length > 0 ?
            <View nativeID="wager_picks">
                <FlatList
                    data={wager_picks}
                    renderItem={renderWagerPicks}
                    keyExtractor={(item) => item.player_pick_id.toString()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default AthleteMarketCard