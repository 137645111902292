import { View, FlatList, Image, TouchableOpacity } from "react-native"
import { view_styles } from "../../constants/styles"
import { Icons, Text } from "../../Components"
import Colors from "../../constants/colors"
import type { PublicPlayerProps, SquareProps, SquareResultProps } from "../../types"
import React, { useState } from "react"

type ResultCardProps = {
    square_results:SquareResultProps[],
    squares:SquareProps[],
    home_abbr?:string,
    away_abbr?:string,
    onPlayerSelect:(player:PublicPlayerProps) => void
}

const ResultsCard = ({ square_results, squares, home_abbr, away_abbr, onPlayerSelect }:ResultCardProps) => {
    const [ expanded, setExpanded ] = useState(false);

    const renderResults = (data: { item:SquareResultProps, index:number }) => {
        if(!data.item.playerDetails){ return <></> }
        const square = squares.find(s => s.sq_square_id == data.item.sq_square_id);
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onPlayerSelect(data.item.playerDetails)}>
                <View style={{ marginRight:10 }}>
                    <Text theme="header_2">Q{data.item.iteration_num}</Text>
                </View>
                <Image
                    source={{ uri: data.item.playerDetails.profile_pic && data.item.playerDetails.profile_pic != '' ? data.item.playerDetails.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:40, width:40, borderRadius:100 }}
                    resizeMode="cover"
                />
                <View style={{ flex:1, marginLeft:5 }}>
                    <Text theme="header_2">@{data.item.playerDetails.username}</Text>
                    {square ?
                    <Text style={{ marginTop:3 }} theme='body_2'>{home_abbr}: {square.square_score_x}, {away_abbr}: {square.square_score_y}</Text>
                    :<></>}
                </View>
                <View style={{ paddingLeft:5, paddingRight:5 }}>
                    <Text size={14} color={Colors.utility.success} weight='bold'>${data.item.winnings.toFixed(2)}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ ...view_styles.section, minWidth:350 }}>
            <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                <Icons.TrophyIcon size={18} color={Colors.incentive.gold} />
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>WINNERS</Text>
                    <Text style={{ marginTop:3 }} theme="body_2">See the winners of past quarters</Text>
                </View>
                <Icons.ChevronIcon direction={true?'down':'up'} size={8} color={Colors.brand.midnight} />
            </TouchableOpacity>
            {expanded ?
            <View style={view_styles.section_body}>
                <FlatList
                    data={square_results.sort((a,b) => a.iteration_num - b.iteration_num)}
                    renderItem={renderResults}
                    keyExtractor={(item) => item.squares_result_id.toString()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default ResultsCard