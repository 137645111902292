import React, { useState } from 'react';
import { View, StyleSheet } from "react-native"
import LinearGradient from "react-native-linear-gradient";
import Colors from '../constants/colors';
import Text from './Text';

type LinearDiagnalProps = {
    label?: string,
    label_size?:number,
    label_color?:string,
    left_color:string,
    right_color:string
    no_buffer?:boolean,
    style?:any,
    children?:any
}
const LinearDiagnal = ({ left_color, right_color, no_buffer, label, label_size, label_color, style, children }:LinearDiagnalProps) => {
    const [ height, setHeight ] = useState(0);
    return (
        <View style={{ flexDirection:'row' }} onLayout={(ev) => {
            const { height } = ev.nativeEvent.layout
            setHeight(height)
        }}>
            <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={[left_color, right_color]} style={{ flex:4, padding:10, ...style }}>
                {label ?
                <Text size={label_size ?? 14} color={label_color ?? Colors.shades.white} weight='bold'>{label}</Text>
                :<></>}
                {children}
            </LinearGradient>
            <View style={{ ...local_styles.triangleCorner, flex:1/2, marginLeft:-1, borderTopWidth:height, borderTopColor:right_color }} />
            {!no_buffer ?
            <View style={{ flex:1 }}/>
            :<></>}
        </View>
    )
}

const local_styles = StyleSheet.create({
    triangleCorner: {
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderRightWidth: 20,
        borderTopWidth: 20,
        borderRightColor: "transparent",
        //borderTopColor: "red",
      }
})

export default LinearDiagnal