import React from 'react';
import { TouchableOpacity, View } from "react-native"
import { Text } from "../../Components"
import Colors from "../../constants/colors"
import { view_styles } from "../../constants/styles"

type BidToggleProps = { 
    cl: string,
    sq_square_id:string,
    amount:number,
    onAmountChange:(dir:'increase'|'decrease') => void
}

const BidToggle = ({ cl, amount, onAmountChange }:BidToggleProps) => {
    return (
        <View style={{ ...view_styles.body_row, borderWidth:1, borderColor:Colors.accents.accent200, borderRadius:4, padding:0 }}>
            <TouchableOpacity style={{ justifyContent:'center', alignItems:'center', padding:12, borderRightWidth:1, borderRightColor:Colors.accents.accent200}} onPress={() => onAmountChange('decrease')}>
                <Text size={16} weight='semibold' color={Colors.brand.electric} textAlign='center'>-</Text>
            </TouchableOpacity>
            <Text style={{ flex:1, marginLeft:4, marginRight:4 }} theme="header_2">{cl}{amount.toFixed(2)}</Text>
            <TouchableOpacity style={{ justifyContent:'center', alignItems:'center', padding:12,  borderLeftWidth:1, borderLeftColor:Colors.accents.accent200}} onPress={() => onAmountChange('increase')}>
                <Text size={16} weight='semibold' color={Colors.brand.electric} textAlign='center'>+</Text>
            </TouchableOpacity>
        </View>
    )
}

export default BidToggle