import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import type { FundProps, OrderProps } from '../../types';
import { view_styles } from '../../constants/styles';
import { Button, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import { MarketMaketHelpers } from '../api';

type OrderFormProps = {
    fund:FundProps,
    order:OrderProps,
    available?:number,
    onClose:() => void,
    onSubmit: (order:OrderProps) => void
}

const OrderForm = ({ fund, order, available, onClose, onSubmit }:OrderFormProps) => {

    const [ working_pct, setWorkingPct ] = useState<string>('100');
    const [ odds, setOdds ] = useState<string>('100');
    const open_amt = (fund.working_capital * (parseFloat(working_pct) / 100))
    const potential_winnings = MarketMaketHelpers.calcPotentialWinnings(open_amt, parseFloat(odds));

    let is_valid = isNaN(open_amt) || isNaN(potential_winnings) ? false : true
    if(parseFloat(working_pct) > 100 || parseFloat(working_pct) < 1){ is_valid = false }

    let available_set = false
    if(available && available.toFixed(2) == open_amt.toFixed(2)){ available_set = true }

    useEffect(() => {
        setOdds(MarketMaketHelpers.getOddsLabel(order.odds))
        if(available){
            updateWorkingPctForAvailable(available)
        }
    },[order, available]);

    const updateWorkingPctForAvailable = (avail:number) => {
        let init_working_pct = avail / fund.working_capital
        if(init_working_pct > 1){ init_working_pct = 1 }
        setWorkingPct((init_working_pct*100).toFixed(2))
    }

    if(!order){ return <></> }

    return (
        <View style={{ ...view_styles.section }}>
            <View style={{ ...view_styles.section_header }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>{fund.name}</Text>
                </View>
                <View style={{ paddingLeft:10, paddingRight:10 }}>
                    <Text theme='body'>${fund.working_capital.toFixed(2)}</Text>
                </View>
                <Button
                    title='X'
                    borderRadius={100}
                    backgroundColor={Colors.shades.shade100}
                    title_color={Colors.utility.error}
                    onPress={() => onClose()}
                />
            </View>
            <View style={{ ...view_styles.section_body }}>
                <View style={{ ...view_styles.body_row }}>
                    <View style={{ flex:1, marginRight:10 }}>
                        <Text theme='header_2'>Odds</Text>
                        <Text theme='body'>What odds would you like to offer?</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, width:75 }}
                        value={odds}
                        onChangeText={(text) => setOdds(text)}
                    />
                </View>
                <View style={{ ...view_styles.body_row }}>
                    <View style={{ flex:1, marginRight:10 }}>
                        <Text theme='header_2'>Working Pct</Text>
                        <Text theme='body'>How much of your working capital should be alloted to this order?</Text>
                    </View>
                    <TextInput
                        style={{ ...view_styles.input, width:75 }}
                        value={working_pct}
                        onChangeText={(text) => setWorkingPct(text)}
                    />
                </View>
                {odds == MarketMaketHelpers.getOddsLabel(order.odds) && available ?
                <View style={{ ...view_styles.body_row }}>
                    <View style={{ flex:1 }}>
                        <Text theme='header_2'>Available</Text>
                        <Text style={{ marginTop:3 }}>Set draft order to buy all the available amount</Text>
                    </View>
                    <Button
                        title={`$${available.toFixed(2)}`}
                        style={{ opacity: available_set ? 0.5: 1 }}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.brand.electric}
                        onPress={() => {
                            updateWorkingPctForAvailable(available)
                        }}
                    />
                </View>
                :<></>}
            </View>
            <View style={{ ...view_styles.section_footer }}>
                <View style={{ flex:1 }}>
                    <Text theme='header_2'>{order.title}</Text>
                </View>
                <Text theme='body'>${open_amt.toFixed(2)} to win ${potential_winnings.toFixed(2)}</Text>
                <Button
                    title='SUBMIT'
                    style={{ marginLeft:10, opacity: is_valid ? 1 : 0.5 }}
                    disabled={!is_valid}
                    backgroundColor={Colors.utility.success}
                    title_color={Colors.shades.white}
                    onPress={() => {
                        let float_odds = parseFloat(odds);
                        let float_working_pct = parseFloat(working_pct) / 100;
                        if(isNaN(float_odds) || isNaN(float_working_pct)){ return alert('Unable to process!') }
                        onSubmit({ 
                            ...order,
                            open_amt,
                            potential_winnings,
                            odds: float_odds,
                            probability: MarketMaketHelpers.calcProbabilityFromOdds(float_odds),
                            working_pct:float_working_pct,
                            fund_id: fund.fund_id
                        })
                    }}
                />
            </View>
        </View>
    )
}

export default OrderForm