import React from 'react';
import { ActivityIndicator, FlatList, TouchableOpacity, View } from "react-native"
import { Text } from "../../Components"
import type { PlayerBalanceProps, PlayerSquareProps, SquareOfferProps, SquareProps } from "../../types"
import BidToggle from "./BidToggle"
import { view_styles } from "../../constants/styles"
import Colors from "../../constants/colors"

type OfferFormProps = {
    player_id?:string,
    player_balance?:PlayerBalanceProps,
    market_type:string,
    home_abbr?:string,
    away_abbr?:string,
    width:number
    submit_ready: { is_loading:boolean, is_ready:Boolean }
    onSubmitOffer: () => void,
    onClearOffers: () => void,
    onOfferAmountChange: (offer:SquareOfferProps, player_square:PlayerSquareProps, direction: 'increase'|'decrease') => void,
    onRequestAuthenticate: () => void,
    draft_square_offers:SquareOfferProps[],
    player_squares:PlayerSquareProps[],
    squares:SquareProps[]
}

const OfferForm = ({ player_id, player_balance, market_type, width, squares, submit_ready, home_abbr, away_abbr, player_squares, draft_square_offers, onRequestAuthenticate, onOfferAmountChange, onClearOffers, onSubmitOffer }:OfferFormProps) => {
    const cl = market_type == 'FOR_MONEY' ? '$' : 'E'
    const total_offer_amount = draft_square_offers.reduce((a,b) => a + b.amount, 0)

    let can_submit = player_balance && total_offer_amount < player_balance.balance ? true : false
    
    
    const renderDraftOffers = (data: { item:SquareOfferProps, index:number }) => {
        let ps = player_squares.find(psQ => psQ.sq_player_square_id == data.item.sq_player_square_id)
        if(!ps){ return (<></>) }
        let square = squares.find(s => s.sq_square_id == ps?.sq_square_id)
        if(!square){ return ( <></> ) }
        return (
            <View style={{flex:1, flexDirection:'row', alignItems:'center', marginBottom:8}}>
                <View style={{flex:1}}>
                    <Text theme='body'>SQUARE: {home_abbr}: {square.square_score_x}, {away_abbr}: {square.square_score_y}</Text>
                    <Text theme='body_2'>Purchased Price: {cl}{ps.purchase_price.toFixed(2)}</Text>
                </View>
                <View>
                    <BidToggle sq_square_id={square.sq_square_id} amount={data.item.amount} cl={cl}  onAmountChange={(dir) => onOfferAmountChange(data.item, ps, dir)}/>
                </View>
            </View>
        )
    }

    if(!draft_square_offers[0]){ return <></> }

    return (
        <View style={{ ...view_styles.section, width, padding:0, maxHeight:550, maxWidth:600 }}>
            <View style={ view_styles.section_header }>
                <View style={{ flex:1 }}>
                    <Text theme="header">SET YOUR {draft_square_offers[0].buy_sell_ind==='buy'?'OFFER AMOUNTS':'SELL PRICE'}</Text>
                    {draft_square_offers[0].buy_sell_ind === 'buy' ?
                    <Text style={{ marginTop:3 }} theme="body_2">How much are you willing to offer the current square owner</Text>
                    :
                    <Text style={{ marginTop:3 }} theme="body_2">How much are you willing to sell the selected square for?</Text>
                    }
                </View>
                <View style={{ height:24, width:24, borderRadius:100, backgroundColor:Colors.highlights.highlight200, justifyContent:'center', alignItems:'center', marginLeft: 5 }}>
                    <Text size={12} color={Colors.shades.white} textAlign='center'>{draft_square_offers.length}</Text>
                </View>
            </View>

            <View style={{ ...view_styles.section_body }}>
                <FlatList data={draft_square_offers.sort((a,b) => parseInt(a.sq_player_square_id) - parseInt(b.sq_player_square_id))} renderItem={renderDraftOffers} keyExtractor={(item) => `o-${item.sq_player_square_id}`}/>
                <View style={view_styles.body_row}>
                    <Text style={{ flex:1 }} theme='body'>Total</Text>
                    <Text theme='header_2'>{cl}{total_offer_amount.toFixed(2)}</Text>
                </View>
                <View style={{ ...view_styles.body_row }}>
                    <TouchableOpacity style={{ flex:1/3, padding:12, borderRadius:4, borderWidth:1, borderColor:Colors.brand.cobalt, justifyContent:'center', alignItems:'center', marginRight:4 }} onPress={() => onClearOffers()}>
                        <Text size={14} weight='semibold' textAlign='center' color={Colors.brand.cobalt}>Clear</Text>
                    </TouchableOpacity>
                    {player_id ?
                    <TouchableOpacity 
                    disabled={!can_submit}
                    style={{ flex:2/3, padding:12, borderRadius:4, backgroundColor:submit_ready.is_ready?Colors.highlights.highlight400:Colors.highlights.highlight200, justifyContent:'center', alignItems:'center', marginLeft:4, opacity:submit_ready.is_loading || !can_submit ? 0.5:1 }} onPress={() => onSubmitOffer()}>
                        {submit_ready.is_loading ?
                        <ActivityIndicator size='small' color={Colors.shades.white}/>
                        :
                        <Text size={14} weight='semibold' textAlign='center' color={Colors.shades.white}>{submit_ready.is_ready?'Submit Offers':'Review Offers'}</Text>
                        }
                    </TouchableOpacity>
                    :
                    <TouchableOpacity style={{ flex:2/3, padding:12, borderRadius:4, backgroundColor:Colors.highlights.highlight400, justifyContent:'center', alignItems:'center', marginLeft:4 }} onPress={() => onRequestAuthenticate()}>
                        <Text size={14} weight='semibold' textAlign='center' color={Colors.shades.white}>Sign Up</Text>
                    </TouchableOpacity>
                    }
                </View>
            </View>
        </View>
    )
}

export default OfferForm