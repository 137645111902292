import React from 'react';
import { View } from 'react-native';
import { view_styles } from '../constants/styles';
import type { AthleteProps } from '../types';

type AthleteCardProps = {
    team:AthleteProps
}

const AthleteCard = ({ }:AthleteCardProps) => {
    return (
        <View style={{ ...view_styles.section }}>
            
        </View>
    )
}

export default AthleteCard