import React, { useState } from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';
import type { FundProps } from "../../types";
import { view_styles } from '../../constants/styles';
import { Button, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';

type FundActionsProps = {
    fund:FundProps,
    onWorkCapital:(amount:number) => void,
    onLayoffCapital:(amount: number) => void,
    onDepositFund:(amount: number) => void,
    onReleaseFunds:(amount:number) => void
}

const FundActions = ({ fund, onWorkCapital, onLayoffCapital, onDepositFund }:FundActionsProps) => {
    const [ show_actions, setShowActions ] = useState(false);
    const [ selected_action, setSelectedAction ] = useState<any | undefined>(undefined);
    const [ amount, setAmount ] = useState('');

    const actions = [
        {action:'deposit', label:'Deposit Capital', color:Colors.utility.success},
        {action:'work', label:'Work Capital', color:Colors.brand.electric},
        {action:'layoff', label:'Layoff Capital', color:Colors.utility.error}
    ]

    const renderActions = (data:{item:any, index:number}) => {
        return (
            <TouchableOpacity style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => setSelectedAction(data.item)}>
                <View style={{ flex:1 }}>
                    <Text size={14} color={data.item.color} weight='semibold'>{data.item.label}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    let action_valid = selected_action && !isNaN(parseFloat(amount)) ? true : false

    const handleAction = (selected_action:{ action:string }) => {
        setSelectedAction(undefined);
        setShowActions(false);
        switch(selected_action.action){
            case 'deposit':
                return onDepositFund(parseFloat(amount))
            case 'work':
                return onWorkCapital(parseFloat(amount))
            case 'layoff':
                return onLayoffCapital(parseFloat(amount))
            default: return alert('Not handled')
        }
    }

    return (
        <View style={{ ...view_styles.section_header, zIndex:1 }}>
            <View style={{ flex:1 }}>
                <Text theme='header'>{fund.name}</Text>
                <Text style={{ marginTop:3 }} theme='body'>${fund.invested_capital.toFixed(2)} Total Invested</Text>
            </View>
            <View style={{ padding:10, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                <Text textAlign='center' size={14} color={Colors.utility.success}>${fund.available_capital.toFixed(2)}</Text>
                <Text textAlign='center' size={14} color={Colors.utility.success}>Available</Text>
            </View>
            <View style={{ padding:10, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                <Text textAlign='center' size={14} color={Colors.brand.electric}>${fund.working_capital.toFixed(2)}</Text>
                <Text textAlign='center' size={14} color={Colors.brand.electric}>Working</Text>
            </View>
            <View style={{ padding:10 }}>
                <Text textAlign='center' size={14} color={Colors.brand.midnight}>${fund.deployed_capital.toFixed(2)}</Text>
                <Text textAlign='center' size={14} color={Colors.brand.midnight}>Deployed</Text>
            </View>
            <Button
                title='ACTIONS'
                style={{ marginLeft:10 }}
                onPress={() => setShowActions(true)}
                backgroundColor={Colors.brand.electric}
                title_color={Colors.shades.white}
            />
            {show_actions ?
            <View style={{ position:'absolute', top:0, right:0 }}>
                <View style={{ ...view_styles.section, minWidth:275 }}>
                    <View style={{ ...view_styles.section_header }}>
                        <View style={{ flex:1 }}>
                            <Text theme='header_2'>Fund Actions</Text>
                        </View>
                        <Button
                            title='X'
                            title_color={Colors.brand.midnight}
                            borderRadius={100}
                            backgroundColor={Colors.shades.shade100}
                            onPress={() => setShowActions(false)}
                        />
                    </View>
                    {!selected_action ?
                    <View style={{ ...view_styles.section_body, padding:0 }}>
                        <FlatList
                            data={actions}
                            key={'action_list'}
                            keyExtractor={(item) => item.action}
                            renderItem={renderActions}
                        />
                    </View>
                    :
                    <View>
                        <View style={{ ...view_styles.section_body, padding:0 }}>
                            <View style={{ padding:10 }}>
                                <Text size={14} color={Colors.brand.midnight} textAlign='center'>{selected_action.label}</Text>
                            </View>
                            <View style={{ ...view_styles.body_row }}>
                                <Text size={14} color={Colors.brand.midnight} weight='bold'>$</Text>
                                <TextInput
                                    style={{ ...view_styles.input, marginLeft:3, flex:1 }}
                                    placeholder='0'
                                    placeholderTextColor={Colors.shades.shade600}
                                    value={amount}
                                    onChangeText={(text) => setAmount(text)}
                                />
                            </View>
                        </View>
                        <View style={{ ...view_styles.section_footer }}>
                            <Button
                                title='CANCEL'
                                style={{ flex:1, minWidth:80, marginRight:5 }}
                                borderRadius={8}
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.utility.error}
                                onPress={() => setSelectedAction(undefined)}
                            />
                            <Button
                                title='SUBMIT'
                                style={{ flex:2, opacity: action_valid ? 1 : 0.5 }}
                                disabled={!action_valid}
                                borderRadius={8}
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.utility.success}
                                onPress={() => handleAction(selected_action)}
                            />
                        </View>
                    </View>
                    
                    }
                </View>
            </View>
            :<></>}
        </View>
    )

}

export default FundActions