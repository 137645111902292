import { View, TouchableOpacity, FlatList } from "react-native"
import { Button, Icons, Text } from "../../Components"
import { view_styles } from "../../constants/styles"
import Colors from "../../constants/colors"
import type { PlayerSquareProps, PublicPlayerProps, SquareProps, SquareResultProps, SquaresCompetitionProps, SquaresExpectedValueProps, SquareValueProps } from "../../types"
import React, { useState } from "react"
import moment from "moment-mini"

type MyStatsCardProps = {
    player_id?:string,
    squares:SquareProps[],
    home_abbr?:string,
    away_abbr?:string,
    squares_expected_value: SquaresExpectedValueProps
    players:PublicPlayerProps[],
    squares_competition:SquaresCompetitionProps,
    player_squares:PlayerSquareProps[],
    onSquareBid:(square:SquareProps, action:'add'|'remove') => void,
    player_square_history:PlayerSquareProps[],
    square_results: SquareResultProps[]
}

const MyStatsCard = ({ player_id, players, squares_expected_value, away_abbr, home_abbr, squares_competition, player_squares, player_square_history, squares, square_results, onSquareBid}:MyStatsCardProps) => {
    const [ expanded, setExpanded ] = useState(false);

    const comp_started = moment(squares_competition.begin_datetime).isBefore(moment()) ? true : false

    let total_payout = squares_competition.potential_winnings
    if(squares_competition.guaranteed_payout && squares_competition.guaranteed_payout > total_payout){ total_payout = squares_competition.guaranteed_payout }
    let active_ps = player_squares.filter(ps => ps.status == 'active');
    const my_ps = active_ps.filter(ps => ps.player_id == player_id)
    let my_outbid_squares = [ ...new Set(player_square_history.filter(psh => psh.status == 'inactive').map(ps => ps.sq_square_id)) ]
    my_outbid_squares = my_outbid_squares.filter(id => !my_ps.map(ps => ps.sq_square_id.toString()).includes(id.toString()))
    const me = players.find(p => p.player_id == player_id);
    console.log(me?.type)
    const my_ownership = my_ps.length / active_ps.length * 100
    const money_in = my_ps.reduce((a,b) => a + b.purchase_price, 0);
    const money_out = square_results.filter(sr => sr.sq_player_square_id &&  my_ps.map(mp => mp.sq_player_square_id.toString()).includes(sr.sq_player_square_id.toString())).reduce((a,b) => a + b.winnings, 0)
    const earnings = money_out - money_in
    let roi = 0
    if(money_in > 0){
        roi = earnings / money_in
    }

    const suggested_squares = squares_expected_value.other_square_values.filter(sv => sv.after_purchase_expected_value > 0).sort((a,b) => b.after_purchase_expected_value - a.after_purchase_expected_value).slice(0, 3)

    const renderSuggestions = (data: { item:SquareValueProps, index:number }) => {
        const square = squares.find(s => s.sq_square_id == data.item.sq_square_id);
        if(!square){ return <></> }
        
        return (
            <View style={{ backgroundColor:Colors.shades.white, borderRadius:8, width:110, margin:3, ...view_styles.float }}>
                <View style={{ flexDirection:'row', alignItems:'center', padding:5, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600, borderTopRightRadius:8, borderTopLeftRadius:8 }}>
                    <View style={{flex:1}}>
                        <Icons.ChevronIcon direction="up" color={Colors.utility.success} size={8} />
                    </View>
                    <Text color={Colors.utility.success} weight='bold' size={14}>EV ${data.item.after_purchase_expected_payout.toFixed(2)}</Text>
                </View>
                <View style={{ padding:10, justifyContent:'center' }}>
                    <View style={{ flexDirection:'row', alignItems:'center' }}>
                        <Text style={{ flex:1 }} theme='body'>{home_abbr}</Text>
                        <Text theme='header_2'>{square.square_score_x}</Text>
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center' }}>
                        <Text style={{ flex:1 }} theme='body'>{away_abbr}</Text>
                        <Text theme='header_2'>{square.square_score_y}</Text>
                    </View>
                </View>
                <View style={{ padding:5 }}>
                    <Button
                        title={`$${data.item.next_purchase_price}`}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.brand.electric}
                        onPress={() => onSquareBid(square, 'add')}
                    />
                </View>
            </View>
        )
    }


    let ev_pct = 0
    if(!isNaN(squares_expected_value.ev_pct)){ ev_pct = squares_expected_value.ev_pct }

    return (
        <View style={{ ...view_styles.section, minWidth:350 }}>
                <TouchableOpacity style={view_styles.section_header} onPress={() => setExpanded(!expanded)}>
                    <View style={{ justifyContent:'center', alignItems:'center' }}>
                        <Text size={14} color={squares_expected_value.expected_value > 0 ? Colors.utility.success: Colors.utility.error} weight='bold'>{(ev_pct * 100).toFixed(2)}%</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>EV</Text>
                    </View>
                    <View style={{ flex:1, marginLeft:10 }}>
                        <Text theme="header">MY STATS (BETA)</Text>
                        <Text style={{ marginTop: 4 }} theme="body_2">Current statistics from the squares board</Text>
                    </View>
                    <Icons.ChevronIcon direction={expanded ? 'up' : 'down'} color={Colors.brand.midnight} size={8} />
                </TouchableOpacity>
                {expanded && !comp_started ?
                 <View style={{ padding:5, backgroundColor:Colors.incentive.gold_faded}}>
                    <View style={{ padding:5 }}>
                        <Text theme="header">Suggested Squares</Text>
                        <Text style={{ marginTop:3 }} theme="body_2">Suggestions are determined by running thousands of simulations based on the board in its current state. These will change frequently as the board changes.</Text>
                    </View> 
                    <View style={{ marginTop:3, padding:10, justifyContent:'center', alignItems:'center' }}>
                        <FlatList
                            data={suggested_squares}
                            horizontal
                            renderItem={renderSuggestions}
                            keyExtractor={(item) => item.sq_square_id.toString()}
                            />
                    </View>
                </View>
                :<></>}
                {expanded ?
                <View style={view_styles.section_body}>
                    <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{flex:1, marginRight:10}}>
                            <Text theme="header_2">Expected Value (pct)</Text>
                            <Text style={{ marginTop:3 }} theme="body_2">Based on your owned squares and ownership pct (for extrapolation), we can calculate the probable earnings of your squares over thousands of simulations</Text>
                        </View>
                        <View style={{ justifyContent:'center', alignItems:'center' }}>
                            <Text color={squares_expected_value.expected_value > 0 ? Colors.utility.success: Colors.utility.error} weight='bold' size={14}>${squares_expected_value.expected_value.toFixed(2)}</Text>
                            <Text style={{ marginTop:3 }} color={squares_expected_value.expected_value > 0 ? Colors.utility.success: Colors.utility.error} weight='regular' size={14}>({(ev_pct * 100).toFixed(2)}%)</Text>
                        </View>
                    </View>
                    <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{flex:1, marginRight:10}}>
                            <Text theme="header_2">Outbid Squares</Text>
                            <Text style={{ marginTop:3 }} theme="body_2">Number of squares you bid on but were outbid or sold</Text>
                        </View>
                        <Text theme="header_2">{my_outbid_squares.length} Squares</Text>
                    </View>
                    <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{flex:1, marginRight:10}}>
                            <Text theme="header_2">My Ownership (Pct)</Text>
                            <Text style={{ marginTop:3 }} theme="body_2">How much of the total board do I own? (All unbid squares will be extrapolated based on this ownership pct)</Text>
                        </View>
                        <Text theme="header_2">{my_ps.length} Squares ({(my_ownership).toFixed()}%)</Text>
                    </View>
                    <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{flex:1, marginRight:10}}>
                            <Text theme="header_2">My Total Paid</Text>
                            <Text style={{ marginTop:3 }} theme="body_2">How much have I paid for my ownership pct</Text>
                        </View>
                        <Text theme="header_2">${(money_in).toFixed(2)} ({((money_in / total_payout)*100).toFixed(2)}%)</Text>
                    </View>
                    <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{flex:1, marginRight:10}}>
                            <Text theme="header_2">Winnings Received</Text>
                            <Text style={{ marginTop:3 }} theme="body_2">How much have I received from winning or selling squares</Text>
                        </View>
                        <Text theme="header_2">${(money_out).toFixed(2)}</Text>
                    </View>
                    <View style={{ ...view_styles.body_row, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{flex:1, marginRight:10}}>
                            <Text theme="header_2">ROI</Text>
                            <Text style={{ marginTop:3 }} theme="body_2">What is my current return on investment (earnings / total paid)</Text>
                        </View>
                        <Text color={roi > 0 ? Colors.utility.success : Colors.utility.error} weight='bold' size={14}>{(roi * 100).toFixed(2)}%</Text>
                    </View>
                </View>
                :<></>}
            </View>
    )
}

export default MyStatsCard