import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, TouchableOpacity, ScrollView, Image } from "react-native";
import type { AthleteProps, BracketGroupProps, BracketProps, BracketRoundProps, CompetitionPlayerBracketProps, EventProps, MyPlayerProps, PlayerBracketPickProps, PlayerBracketProps, RoundEventProps, TeamProps } from '../../../types';
import { BracketApi, BracketComeptitionApi, BracketCompetitionHelpers } from '../../api';
import { Button, Icons, Text } from '../../../Components';
import Colors from '../../../constants/colors';
import BracketRoundSection from './BracketRound';
import BracketNavigator from './BracketNavigator';
import { view_styles } from '../../../constants/styles';

type BracketPlayProps = {
    visible: boolean,
    player?:MyPlayerProps
    room_size:{ width:number, height:number },
    player_bracket_id?:string,
    bracket_id?:string,
    onRequestAuthenticate: () => void,
    onChangePlayerBracket: (bracket_id:string) => void,
    onClose: () => void
    onPlayerBracketUpdate: (pb:PlayerBracketProps, cpbs:CompetitionPlayerBracketProps[]) => void,
}
const MIN_ROUND_WIDTH = 175
const MAX_ROUND_WIDTH = 200;
const BracketPlay = ({ visible, player, room_size, bracket_id, player_bracket_id, onClose, onPlayerBracketUpdate, onChangePlayerBracket, onRequestAuthenticate }:BracketPlayProps) => {
    const [ round_event_loading, setRoundEventLoading ] = useState<string|undefined>(undefined);
    const [ show_filter, setShowFilter ] = useState(false);
    const [ play_data, setPlayData ] = useState<{
        play_loading:boolean,
        player_bracket?:PlayerBracketProps,
        player_bracket_picks:PlayerBracketPickProps[]
    }>({
        play_loading:false,
        player_bracket_picks: []
    });
    const [ bracket_data, setData ] = useState<{
        loading:boolean,
        bracket?:BracketProps,
        bracket_groups:BracketGroupProps[],
        bracket_rounds:BracketRoundProps[],
        round_events:RoundEventProps[],
        teams:TeamProps[],
        athletes:AthleteProps[],
        selected_group?:BracketGroupProps,
        selected_round?:BracketRoundProps,
        events:EventProps[]
    }>({
        loading:false,
        bracket_groups: [],
        bracket_rounds: [],
        round_events: [],
        teams: [],
        athletes: [],
        events: []
    })
    const { play_loading, player_bracket, player_bracket_picks } = play_data;
    const { loading, bracket_groups, bracket_rounds, round_events, bracket, teams, athletes, selected_group, selected_round } = bracket_data;

    const champion_team = player_bracket?.champion_id_type == 'team' ? teams.find(t => t.team_id == player_bracket?.champion_id) : undefined
    const champion_athlete = player_bracket?.champion_id_type == 'athlete' ? athletes.find(a => a.athlete_id == player_bracket?.champion_id) : undefined

    let visible_rounds = bracket_rounds
    if(selected_round){
        let next_round = bracket_rounds.findIndex(r => r.bracket_round_id == selected_round.bracket_round_id);
        visible_rounds = bracket_rounds.filter((_, i) => i == next_round || i == next_round + 1)
    }

    let round_width = (room_size.width / 2) - 15
    if(round_width < MIN_ROUND_WIDTH){ round_width = MIN_ROUND_WIDTH }
    if(round_width > MAX_ROUND_WIDTH){ round_width = MAX_ROUND_WIDTH }
    useEffect(() => {
        if(!bracket_id || !visible){ return }
        getData(bracket_id)
    },[visible, bracket_id])

    useEffect(() => {
        if(!player_bracket_id){ return }
        getPlayData(player_bracket_id);
    },[player_bracket_id])

    const getPlayData = async(id:string) => {
        setPlayData({ ...play_data, play_loading: true })
        const resp = await BracketComeptitionApi.getPlayerBracketById(id)
        setPlayData({
            ...play_data,
            play_loading:false,
            player_bracket_picks: resp.player_bracket_picks,
            player_bracket:resp.player_bracket
        })
    }

    const handlePick = async(pick:PlayerBracketPickProps) => {
        if(!BracketCompetitionHelpers.canMakePick(bracket)){ return alert('Bracket as already started') }
        if(!player){ return onRequestAuthenticate() }
        if(!bracket_id){ return }
        setRoundEventLoading(pick.round_event_id);
        let new_pick:PlayerBracketPickProps = { ...pick, bracket_id: bracket_id }
        if(!player_bracket){
            const new_pb = await BracketComeptitionApi.createPlayerBracket({
                player_bracket_id: '',
                player_id: '',
                bracket_name: `${player.username} Bracket ${Math.floor(Math.random() * (100 - 1 + 1) + 1)}`,
                create_datetime: '', last_update_datetime: '',
                bracket_id: bracket_id,
                completion_pct: 0,
                completion_status: 'inprogress',
                status: 'active'
            })
            new_pick.player_bracket_id = new_pb.player_bracket_id
        } else {
            new_pick.player_bracket_id = player_bracket.player_bracket_id
        }
        const resp = await BracketComeptitionApi.savePick(new_pick);
        setPlayData({
            ...play_data,
            player_bracket: resp.player_bracket,
            player_bracket_picks: player_bracket_picks.filter(pbp => !resp.player_bracket_picks.find(npb => npb.player_bracket_pick_id == pbp.player_bracket_pick_id)).concat(resp.player_bracket_picks.filter(pb => pb.status != 'deleted'))
        })
        onPlayerBracketUpdate(resp.player_bracket, resp.competition_player_brackets)
        setRoundEventLoading(undefined)
    }
    const getData = async(id:string) => {
        setData({ ...bracket_data, loading:true })
        const b = await BracketApi.getBracketById(id);
        let team_ids:string[] = []
        let athlete_ids:string[] = []
        
        b.round_events.map(re => {
            if(re.away_side_type == 'team' && re.away_side_id){ team_ids.push(re.away_side_id) }
            if(re.home_side_type == 'team' && re.home_side_id){ team_ids.push(re.home_side_id) }
            if(re.away_side_type == 'athlete' && re.away_side_id){ athlete_ids.push(re.away_side_id) }
            if(re.home_side_type == 'athlete' && re.home_side_id){ athlete_ids.push(re.home_side_id) }
        })
        let ts = await BracketApi.getTeamsByIds(team_ids);
        let aths = await BracketApi.getAthletesByIds(athlete_ids);

        setData({
            ...bracket_data,
            bracket: b.bracket,
            bracket_groups: b.bracket_groups,
            bracket_rounds: b.bracket_rounds,
            round_events: b.round_events,
            teams: ts,
            loading:false,
            athletes: aths
        })
    }

    const renderBracketRounds = (data: { item:BracketRoundProps, index:number }) => {
        return (
            <BracketRoundSection 
                round_event_loading={round_event_loading}
                bracket_round={data.item}
                round_events={round_events}
                teams={teams}
                athletes={athletes}
                player_bracket_picks={player_bracket_picks}
                width={round_width}
                orientation='right'
                onPick={(pick) => handlePick(pick)}
                bracket_size={room_size}
            
            />
        )
    }


    if(!visible){ return <></> }

    if(!bracket || loading || play_loading){
        return (
            <View>
                <ActivityIndicator size='large' style={{ padding:20, alignSelf:'center' }} color={Colors.brand.midnight} />
            </View>
        )
    }
    return (
        <View style={{ flex:1 }}>
            <View style={{ zIndex:10, flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade100 }}>
                <TouchableOpacity style={{ flex:1, flexDirection:'row', alignItems: 'center' }} onPress={() => onClose()}>
                    <Icons.ChevronIcon direction='left' size={12} color={Colors.brand.midnight} />
                    <View style={{ flex:1, marginLeft:20, marginRight:10 }}>
                        <Text size={18} color={Colors.brand.midnight} weight='bold'>{bracket.bracket_name}</Text>
                    </View>
                </TouchableOpacity>
                <View style={{ zIndex:10 }}>
                    <Button
                        title='Filter'
                        backgroundColor={Colors.brand.electric}
                        title_color={Colors.shades.white}
                        onPress={() => setShowFilter(true)}
                    />
                    {show_filter ?
                    <View style={{ position:'absolute', top:5, right:5, backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float }}>
                        <BracketNavigator
                            bracket_groups={bracket_groups}
                            bracket_rounds={bracket_rounds}
                            selected_group={selected_group}
                            selected_round={selected_round}
                            onSelectGroup={(g) => setData({ ...bracket_data, selected_group: selected_group?.bracket_group_id == g.bracket_group_id ? undefined : g })}
                            onSelectRound={(r) => setData({ ...bracket_data, selected_round: selected_round?.bracket_round_id == r.bracket_round_id ? undefined : r })}
                            onClose={() => setShowFilter(false)}
                        />
                    </View>
                    :<></>}
                </View>
            </View>
            {player_bracket ?
            <View style={{ padding:20, flexDirection:'row', alignItems:'center', backgroundColor:Colors.shades.shade100 }}>
                {champion_team ?
                <View style={{ justifyContent:'center', alignItems:'center', marginRight:10 }}>
                    <Image
                        source={{ uri: champion_team.image?.url }}
                        style={{ height:20, width:20, borderRadius:4 }}
                        resizeMode='cover'
                    />
                    <Text style={{ marginTop:2 }} size={12} color={Colors.brand.midnight} weight='bold' textAlign='center'>{champion_team.abbr}</Text>
                </View>
                :champion_athlete ?
                <View style={{ justifyContent:'center', alignItems:'center', marginRight:10 }}>
                    <Image
                        source={{ uri: champion_athlete.image?.url }}
                        style={{ height:20, width:20, borderRadius:4 }}
                        resizeMode='cover'
                    />
                    <Text style={{ marginTop:2 }} size={12} color={Colors.brand.midnight} weight='bold' textAlign='center'>{champion_athlete.abbr_name}</Text>
                </View>
                :<></>}
                <View style={{ flex:1 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{player_bracket.bracket_name}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{(player_bracket.completion_pct*100).toFixed(2)}% Complete</Text>
                </View>
                <Button
                    title='Change'
                    backgroundColor={Colors.brand.electric}
                    title_color={Colors.shades.white}
                    onPress={() => onChangePlayerBracket(bracket.bracket_id)}
                />
            </View>
            :<></>}
            <ScrollView style={{ flex:1, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flex:1, flexDirection:'row', padding:10, overflow:'scroll' }}>
                    {visible_rounds.sort((a,b) => a.round_number - b.round_number).map((br,i) => {
                        return renderBracketRounds({ item:br, index:i })
                    })}
                </View>
            </ScrollView>
        </View>
    )
}

export default BracketPlay