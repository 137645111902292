import moment from "moment-mini"
import axios from 'axios';
import type { BestAvailableOrderProps, EventProps, MatchProps, PublicPlayerProps, TournamentProps } from "../../types"
import { APIOverrides } from "../../ApiOverrides";
let AUTH_SVC_API = ''

export { PromotedOrderApi, PromotedOrderHelpers }


const PromotedOrderApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getPlayersByPlayerIds : async(player_ids:string[]):Promise<PublicPlayerProps[]> => {
        if(player_ids.length == 0){ return [] }
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
        return resp.data.players
    }
}


const PromotedOrderHelpers = {
    getContestDetails: (order:BestAvailableOrderProps, event?:EventProps, tournament?:TournamentProps, match?:MatchProps):{ contest_title:string, contest_time_detail:string } => {
        const error_response = { contest_title: '', contest_time_detail: '' }
        switch(order.event_type){
            case 'team':
                if(!event){ return error_response }
                let ev_time_detail = event.time_detail
                if(!ev_time_detail || ev_time_detail == 'scheduled'){ 
                    ev_time_detail = moment(event.scheduled_datetime).format('MMM DD hh:mm a') 
                }
                return { contest_title: event.event_title ?? '', contest_time_detail: ev_time_detail }
            case 'tournament':
                if(!tournament){ return error_response }
                return { contest_title: tournament.tournament_name, contest_time_detail: moment(tournament.scheduled_datetime).format('MMM DD hh:mm a') }
            case 'match':
                if(!match){ return error_response }
                return { contest_title: match.match_title, contest_time_detail: moment(match.scheduled_datetime).format('MMM DD hh:mm a') }
            default: return error_response
        }
    }
}