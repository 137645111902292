import React, { useEffect, useRef, useState } from 'react';
import { View, Platform, Image, ActivityIndicator, TouchableOpacity } from 'react-native';
import { SocialComponentApi, SocialPodcastHelpers } from './api';
import type { PlayerPodcastEpisodeProps, PlayerPodcastProps, PodcastEpisodesProps } from '../types';
import { Button, Icons, Text } from '../Components';
import { view_styles } from '../constants/styles';
import Colors from '../constants/colors';
import Slider from '../Components/Slider';

type AudioPlayerProps = {
    podcast_episode_id:string,
    player_id?:string,
    onClose:() => void
}
const AudioPlayer = ({podcast_episode_id, player_id, onClose}:AudioPlayerProps) => {
    const [ audio_size, setAudioSize ] = useState({ width:50 });
    const [ audio_data, setAudioData ] = useState<{
        audio_loading:boolean,
        podcast_episode?:PodcastEpisodesProps,
    }>({
        audio_loading:false,
    });
    const { audio_loading, podcast_episode } = audio_data;
    const [ time_data, setTimeData ] = useState<{
        duration: number,
        loaded:boolean,
        status: 'paused'|'playing',
        current_time: number,
        time_remaining:number,
        minimum_value: number,
        maximum_value:number
    }>({
        duration:0,
        loaded:false,
        status: 'paused',
        current_time:0,
        time_remaining:0,
        minimum_value:0,
        maximum_value: 0
    });
    const { loaded, status, current_time, time_remaining, maximum_value, minimum_value } = time_data;

    const [ player_data, setPlayerData ] = useState<{
        player_loading:boolean,
        player_podcast?:PlayerPodcastProps,
        player_podcast_episode?:PlayerPodcastEpisodeProps
    }>({
        player_loading: false
    });
    const { player_loading, player_podcast } = player_data;

    const audio_ref:any = useRef(null)

    useEffect(() => {
        const handleBeforeUnload = async(event:any) => {
            event.preventDefault();
            event.returnValue = ''; // This is required for some browsers to show a confirmation dialog
            // Perform any cleanup or save data here
            if(audio_ref?.current){
                await SocialComponentApi.pausePodcastEpisode(podcast_episode_id, audio_ref.current.currentTime);
            }
          };
          
          if(Platform.OS == 'web'){
            window.addEventListener('beforeunload', handleBeforeUnload);
          }
      
          return () => {
            if(Platform.OS == 'web'){
                window.removeEventListener('beforeunload', handleBeforeUnload);
            }
          };
    },[])


    useEffect(() => {
        SocialComponentApi.setEnvironment();
        getAudioData(podcast_episode_id);
    },[podcast_episode_id]);

    const getAudioData = async(pod_episode_id:string) => {
        setAudioData({ ...audio_data, audio_loading:true })
        const episode = await SocialComponentApi.getPodcastEpisodeById(pod_episode_id);
        setAudioData({
            ...audio_data,
            audio_loading: false,
            podcast_episode: episode
        })
    }

    const getPlayerData = async(pod_episode_id:string) => {
        setPlayerData({ ...player_data, player_loading: true });
        const p_data = await SocialComponentApi.getPlayerPodcastEpisode(pod_episode_id);
        setPlayerData({ 
            ...player_data,
            player_loading: false,
            player_podcast:p_data?.player_podcast,
            player_podcast_episode:p_data?.player_podcast_episode
        });
        return p_data
    }

    const startAudio = async() => {
        if(!audio_ref?.current || !loaded){ return alert('Unable to play audio at this time') }
        let cur_time = audio_ref.current.currentTime
        let dur = audio_ref.current.duration
        audio_ref?.current.play();
        if(player_id){
            setPlayerData({ ...player_data, player_loading: true });
            const p_resp = await SocialComponentApi.startPodcastEpisode(podcast_episode_id);
            setPlayerData({ ...player_data, player_loading: false, player_podcast: p_resp?.player_podcast, player_podcast_episode: p_resp?.player_podcast_episode });
        }
        setTimeData({
            ...time_data,
            current_time: cur_time,
            time_remaining: dur - cur_time,
            duration: dur,
            status: 'playing'
        })
    }

    const pauseAudio = async() => {
        if(!audio_ref?.current || !loaded){ return alert('Unable to play audio at this time') }
        let cur_time = audio_ref.current.currentTime
        let dur = audio_ref.current.duration
        audio_ref?.current.pause();

        if(player_id){
            setPlayerData({ ...player_data, player_loading: true });
            const p_resp = await SocialComponentApi.pausePodcastEpisode(podcast_episode_id, cur_time);
            setPlayerData({ ...player_data, player_loading: false, player_podcast, player_podcast_episode: p_resp });
        }
        setTimeData({
            ...time_data,
            current_time: cur_time,
            time_remaining: dur - cur_time,
            duration: dur,
            status: 'paused'
        })
    }

    const handleTimeChange = () => {
        if(!loaded || player_loading || audio_loading){ return }
        if(!audio_ref?.current){ return }//Error
        let cur_time = audio_ref.current.currentTime
        let dur = audio_ref.current.duration
        setTimeData({
            ...time_data,
            current_time: cur_time,
            time_remaining: dur - cur_time,
            duration: dur,
        })
    }

    const handleLoad = async() => {
        if(!audio_ref?.current){ return alert('Unable to play audio') }
        //First, lets grab the duration of the podcast episode
        const dur = audio_ref.current.duration
        let cur_time = 0
        if(player_id){
            let p_data = await getPlayerData(podcast_episode_id);
            if(p_data?.player_podcast_episode && audio_ref?.current){
                audio_ref.current.currentTime = p_data.player_podcast_episode.pause_timestamp
                cur_time = p_data.player_podcast_episode.pause_timestamp
            }
        }

        setTimeData({
            ...time_data,
            current_time: cur_time,
            minimum_value: 0,
            maximum_value: dur,
            time_remaining: dur - cur_time,
            status: 'playing',
            loaded:true
        });
        audio_ref.current.play()
    }

    const handleStartTimeChange = () => {
        if(!audio_ref?.current || !loaded){ return }
        const cur = audio_ref.current.currentTime
        let dur = audio_ref.current.duration
        setTimeData({ 
            ...time_data,
            current_time: cur,
            duration: dur,
            time_remaining: dur - cur,
            status: 'paused'
        })
        audio_ref.current.pause();
    }

    const handleManualTimeChange = (val:number) => {
        if(!audio_ref?.current || !loaded){ return }
        audio_ref.current.currentTime = val
        let dur = audio_ref.current.duration
        setTimeData({
            ...time_data,
            current_time: val,
            duration: dur,
            status: 'playing',
            time_remaining: dur - val
        });
        audio_ref.current.play();
    }




    if(Platform.OS != 'web'){ return <></> } //Only built for web at this moment

    return (
        <View style={{ ...view_styles.section, flex:1 }} onLayout={(ev) => {
            const { width } = ev.nativeEvent.layout
            setAudioSize({ width })
        }}>
            {!podcast_episode || audio_loading ?
            <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ alignSelf:'center' }} />
            :
            <View style={{ ...view_styles.body_row }}>
                <View>
                    <Image
                        source={{ uri: SocialPodcastHelpers.getEpisodeImage(podcast_episode) }}
                        style={{ width: audio_size.width * 0.1, height: audio_size.width * 0.1 }}
                        resizeMode='cover'
                    />
                    <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                        {status == 'paused' ?
                        <TouchableOpacity style={{ ...view_styles.float, backgroundColor:Colors.shades.white, borderRadius:100, padding:10 }} onPress={() => startAudio() }>
                            <Icons.PlayIcon size={12} color={Colors.brand.electric} />
                        </TouchableOpacity>
                        :status == 'playing' ?
                        <TouchableOpacity style={{ ...view_styles.float, backgroundColor:Colors.shades.white, borderRadius:100, padding:10 }} onPress={() => pauseAudio() }>
                            <Icons.PauseIcon size={12} color={Colors.utility.error} />
                        </TouchableOpacity>
                        :<></>}
                    </View>
                </View>
                <View style={{ flex:1, ...view_styles.body_row }}>
                    <View style={{ flex:1 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{podcast_episode.title}</Text>
                        <View style={{ flexDirection:'row', alignItems:'center' }}>
                            <Text style={{ padding:5, width:50, alignSelf:'center' }} size={12} color={Colors.brand.midnight} weight='regular'>{SocialPodcastHelpers.formatTime(current_time)}</Text>
                            <Slider
                                width={audio_size.width * 0.47}
                                value={current_time}
                                minimum_value={minimum_value}
                                maximum_value={maximum_value}
                                onSlidingStart={() => handleStartTimeChange()}
                                onSlidingComplete={(val) => handleManualTimeChange(val)}
                            />
                            <Text style={{ padding:5, width:50, alignSelf:'center' }} size={12} color={Colors.brand.midnight} weight='regular'>-{SocialPodcastHelpers.formatTime(time_remaining)}</Text>
                        </View>
                        {player_loading || audio_loading ?
                        <View style={{ position:'absolute', top:0, left:0, bottom:0, right:0, justifyContent:'center' }}>
                            <ActivityIndicator size='small' color={Colors.brand.midnight} />
                        </View>
                        :<></>}
                    </View>
                    <View style={{ marginLeft:10, flexDirection:'row', alignItems:'center' }}>
                        <Button
                            style={{ borderRadius:100, padding:5, backgroundColor:Colors.shades.shade100, ...view_styles.float }}
                            title='X'
                            title_size={12}
                            title_color={Colors.brand.midnight}
                            onPress={async() => {
                                await pauseAudio();
                                onClose();
                            }}
                        />
                        
                    </View>
                </View>
            </View>
            }
            {podcast_episode ?
            <audio onLoad={(ev) => console.log(ev)} ref={audio_ref} onDurationChange={() => handleLoad()} onTimeUpdate={() => handleTimeChange()} src={podcast_episode.url} />
            :<></>}
        </View>

    )
}

export default AudioPlayer