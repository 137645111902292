import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, Image, TouchableOpacity, FlatList, StyleSheet, ScrollView } from 'react-native';
import type { BracketCompetitionProps, BracketCompetitionScoringRuleProps, BracketProps, BracketRoundProps, CompetitionPlayerBracketProps, CompetitionResultTypeProps, MyPlayerProps, PlayerBalanceProps, PlayerBracketProps, PublicPlayerProps } from '../../types';
import { BracketApi, BracketComeptitionApi, BracketCompetitionHelpers } from '../api';
import Colors from '../../constants/colors';
import { Button, Icons, Spring, Text } from '../../Components';
import moment from 'moment-mini';
import CompetitionLeaderboard from './CompetitionLeaderboard';
import JoinCompetitionCard from './JoinCompetitionCard';
import { view_styles } from '../../constants/styles';

type BracketCompetitionCardProps = {
    visible: boolean,
    player?:MyPlayerProps,
    player_balance?:PlayerBalanceProps,
    bracket_competition_id?:string,
    height:number
    onNotEnoughBalance:(data:{ bracket_competition:BracketCompetitionProps }) => void,
    onViewBracket:(bracket_id:string) => void,
    onUpdateMyData: (data: {bracket_id:string, player_brackets:PlayerBracketProps[], competition_player_brackets:CompetitionPlayerBracketProps[]}) => void,
    onShareCompetition:(bracket_competition_id:string) => void,
    onEnterCompetition: (resp: {competition_player_bracket:CompetitionPlayerBracketProps, bracket_competition:BracketCompetitionProps}) => void,
    onLeaveCompetition: (resp: {competition_player_bracket:CompetitionPlayerBracketProps, bracket_competition:BracketCompetitionProps}) => void,
    competition_result_types:CompetitionResultTypeProps[],
    my_comp_player_brackets:CompetitionPlayerBracketProps[],
    my_player_brackets:PlayerBracketProps[],
    onClose:() => void
}

const BracketCompetitionCard = ({ visible, player_balance, player, height, my_comp_player_brackets, competition_result_types, my_player_brackets, bracket_competition_id, onNotEnoughBalance, onUpdateMyData, onViewBracket, onClose, onLeaveCompetition, onShareCompetition, onEnterCompetition }:BracketCompetitionCardProps) => {
    const [ action_loading, setActionLoading ] = useState(false);
    const [ joining_bracket, setJoiningBracket ] = useState<PlayerBracketProps | undefined>(undefined);
    const [ show_rules, setShowRules ] = useState(false);
    const [ show_bracket, setShowBracket ] = useState(true);
    const [ show_leaderboard, setShowLeaderboard ] = useState(false);
    const [ show_info, setShowInfo ] = useState(true);
    const [ show_leaders, setShowLeaders ] = useState(true);
    const [ card_data, setData ] = useState<{
        loading:boolean,
        bracket_competition?:BracketCompetitionProps,
        bracket?:BracketProps,
        bracket_rounds:BracketRoundProps[],
        competition_player_brackets:CompetitionPlayerBracketProps[],
        bracket_competition_scoring_rules:BracketCompetitionScoringRuleProps[],
        player_brackets:PlayerBracketProps[],
        players:PublicPlayerProps[],
        loaded:boolean
    }>({
        loading:false,
        loaded:false,
        bracket_rounds: [],
        competition_player_brackets: [],
        bracket_competition_scoring_rules:[],
        player_brackets: [],
        players: []
    })

    const {loading, loaded, bracket_competition, bracket, bracket_rounds, competition_player_brackets, bracket_competition_scoring_rules } = card_data;
    const result_type = competition_result_types.find(crt => crt.competition_result_type_id == bracket_competition?.competition_result_type_id);
    useEffect(() => {
        if(!bracket_competition_id){ return }
        getCompetitionData(bracket_competition_id)
    },[bracket_competition_id])

    const getCompetitionData = async(id:string) => {
        setData({ ...card_data, loading:true });
        const resp = await BracketComeptitionApi.getBracketCompetitionById(id);
        if(player){
            const my_cpbs = resp.competition_player_brackets.filter(cpb => cpb.player_id == player.player_id)
            const my_pbs = resp.player_brackets.filter(pbs => pbs.player_id == player.player_id)
            onUpdateMyData({ player_brackets: my_pbs, competition_player_brackets: my_cpbs, bracket_id: resp.bracket_competition.bracket_id })
        }
        const br_resp = await BracketApi.getBracketById(resp.bracket_competition.bracket_id);
        const player_ids = resp.competition_player_brackets.map(pb => pb.player_id);
        const ps = await BracketApi.getPlayersByPlayerIds(player_ids);
        setData({
            ...card_data,
            loading:false,
            bracket_competition: resp.bracket_competition,
            bracket_competition_scoring_rules: resp.bracket_competition_scoring_rules,
            competition_player_brackets:resp.competition_player_brackets,
            player_brackets: resp.player_brackets,
            players: ps,
            bracket: br_resp.bracket,
            bracket_rounds: br_resp.bracket_rounds,
            loaded: true
        })
    }

    const can_leave = BracketCompetitionHelpers.canLeave(bracket_competition);

    const handleEnter = async(cpb:CompetitionPlayerBracketProps) => {
        setActionLoading(true);
        const resp = await BracketComeptitionApi.joinBracketCompetition(cpb);
        setData({
            ...card_data,
            bracket_competition: resp.bracket_competition,
            competition_player_brackets: competition_player_brackets.filter(cpb => cpb.competition_player_bracket_id != resp.competition_player_bracket.competition_player_bracket_id).concat(resp.competition_player_bracket)
        })
        onEnterCompetition(resp);
        setJoiningBracket(undefined)
        setActionLoading(false);
    }

    const handleLeave = async(cpb_id:string) => {
        setActionLoading(true);
        const resp = await BracketComeptitionApi.leaveBracketCompetition(cpb_id)
        setData({
            ...card_data,
            bracket_competition: resp.bracket_competition,
            competition_player_brackets: competition_player_brackets.filter(cpb => cpb.competition_player_bracket_id != resp.competition_player_bracket.competition_player_bracket_id)
        })
        onLeaveCompetition(resp);
        setActionLoading(false);
    }

    const renderPlayerBrackets = (data: { item: PlayerBracketProps, index:number }) => {
        const cpb = my_comp_player_brackets.find(mcpb => mcpb.player_bracket_id == data.item.player_bracket_id);
        return (
            <View style={{ flexDirection:'row', padding:5 }}>
                <TouchableOpacity style={{ flex:1 }} onPress={() => onViewBracket(data.item.bracket_id)}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{data.item.bracket_name}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>{(data.item.completion_pct*100).toFixed(2)}% Complete</Text>
                </TouchableOpacity>
                {!cpb ?
                <Button
                    title='Join'
                    loading={action_loading}
                    backgroundColor={Colors.utility.success}
                    title_color={Colors.shades.white}
                    onPress={() => setJoiningBracket(data.item)}
                />
                :cpb && can_leave ?
                <Button
                    title='Leave'
                    disabled={action_loading}
                    style={{ opacity: action_loading?0.5:1 }}
                    loading={action_loading}
                    backgroundColor={Colors.utility.error}
                    title_color={Colors.shades.white}
                    onPress={() => handleLeave(cpb.competition_player_bracket_id)}
                />
                :<></>}
            </View>
        )
    }

    const renderRules = (data: { item:BracketCompetitionScoringRuleProps, index:number }) => {
        if(!bracket){ return <></> }
        const bracket_round = bracket_rounds.find(br => br.round_number == data.item.round_number);
        if(!bracket_round){ return <></> }
        return (
            <View style={{ flexDirection:'row', padding:10 }}>
                <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>{bracket_round.round_name}</Text>
                <Text size={14} color={Colors.brand.midnight} weight='bold'>{data.item.win_points} Points</Text>
            </View>
        )
    }

    if(!visible){ return <></> }

    if(!loaded || !bracket_competition || !bracket || loading){
        return (
            <View>
                <ActivityIndicator style={{ padding:20, alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
            </View>
        )
    }

    let payout = BracketCompetitionHelpers.getPayout(bracket_competition);
    
    return (
        <View style={{ flex:1, backgroundColor:Colors.shades.shade100 }}>
            <View style={{ borderBottomWidth:1, borderColor:Colors.shades.shade600, backgroundColor:Colors.shades.white }}>
                <View style={{ padding:20, flexDirection:'row', alignItems:'center' }}>
                    <TouchableOpacity style={{ flex:1, flexDirection:'row', alignItems:'center' }} onPress={() => onClose()}>
                        <View style={{ padding:10 }}>
                            <Icons.ChevronIcon size={12} color={Colors.brand.midnight} direction='left' />
                        </View>
                        <View>
                            <Image
                                source={{ uri: bracket_competition.image?.url }}
                                style={{ height:50, width:50, borderRadius:4 }}
                                resizeMode='cover'
                            />
                        </View>
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text weight='bold' size={14} color={Colors.brand.midnight}>{bracket_competition.competition_name}</Text>
                            <Text style={{ marginTop:4 }} size={12} color={Colors.brand.slate}>{bracket_competition.competition_description}</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ padding:10 }} onPress={() => onShareCompetition(bracket_competition.bracket_competition_id)}>
                        <Icons.ShareIcon color={Colors.brand.electric} size={12} />
                    </TouchableOpacity>
                </View>
            </View>
            <ScrollView style={{ flex:1 }}>
            <View style={local_styles.header}>
                <TouchableOpacity style={local_styles.section_header} onPress={() => setShowBracket(!show_bracket)}>
                    <View>
                    <Image
                        source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1677259366/Bracket_image_h0fkku.webp' }}
                        style={{ width:50, height:35, borderRadius:4 }}
                        resizeMode='cover'
                    />
                    </View>
                    <View style={{ flex:1, marginLeft:10 }}>
                        <Text size={16} color={Colors.brand.midnight} weight='bold'>{bracket.bracket_name}</Text>
                        <Text style={{ marginTop:3 }} size={14} color={Colors.brand.slate} weight='regular'>Bracket starts {moment(bracket.scheduled_datetime).format('MMM DD YYYY hh:mm a')}</Text>
                    </View>
                    <Icons.ChevronIcon size={10} direction={show_bracket ? 'up' : 'down'} color={Colors.brand.midnight} />
                </TouchableOpacity>
                {show_bracket ?
                <View style={{ padding:20 }}>
                    {my_player_brackets.length > 0 ?
                    <View style={{ borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:8 }}>
                         <View style={{ borderBottomWidth:1, borderBottomColor:Colors.shades.shade600, padding:10 }}>
                            <Text size={16} color={Colors.brand.slate} weight='regular'>MY ELIGIBLE BRACKETS</Text>
                        </View>
                        <View style={{ padding:10 }}>
                            <FlatList
                                data={my_player_brackets}
                                renderItem={renderPlayerBrackets}
                                keyExtractor={(item) => item.player_bracket_id.toString()}
                            />
                        </View>
                    </View>
                    :<></>}
                    <View style={{ margin:10, marginBottom:0 }}>
                        <Button
                            title='Fill Out Bracket'
                            padding={15}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.brand.electric}
                            onPress={() => onViewBracket(bracket.bracket_id)}
                        />
                    </View>
                </View>
                :<></>}
            </View>
            <View style={local_styles.header}>
                <TouchableOpacity style={local_styles.section_header} onPress={() => setShowInfo(!show_info)}>
                    <View style={{ flex:1 }}>
                        <Text size={16} color={Colors.brand.midnight} weight='bold'>COMPETITION DETAILS</Text>
                        <Text style={{ marginTop:3 }} size={14} color={Colors.brand.slate} weight='regular'>Detailed Information on this competition</Text>
                    </View>
                    <Icons.ChevronIcon size={10} direction={show_info ? 'up' : 'down'} color={Colors.brand.midnight} />
                </TouchableOpacity>
                {show_info ?
                <View style={{ padding:10, margin:10, borderWidth:1, borderRadius:8, borderColor:Colors.shades.shade600 }}>
                     <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{ flex:1 }} size={14} weight='bold' color={Colors.brand.midnight}>Entries</Text>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{bracket_competition.tickets_sold} / {bracket_competition.max_brackets}</Text>
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{ flex:1 }} size={14} weight='bold' color={Colors.brand.midnight}>Ticket Price</Text>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>${bracket_competition.buy_in.toFixed(2)}</Text>
                    </View>
                    {result_type ?
                    <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{ flex:1 }} size={14} weight='bold' color={Colors.brand.midnight}>Payout Type</Text>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{result_type.label}</Text>
                    </View>
                    :<></>}
                    <View style={{ flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{ flex:1 }} size={14} weight='bold' color={Colors.brand.midnight}>Current Payout</Text>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>${payout.toFixed(2)}</Text>
                    </View>
                    {bracket_competition.prize_override ?
                    <View style={{ marginTop:5, backgroundColor:Colors.incentive.gold_faded, padding:10, borderRadius:8 }}>
                        <View style={{ padding:5 }}>
                            <Text size={14} color={Colors.incentive.gold} weight='bold'>Additional Prize</Text>
                        </View>
                        <View style={{ padding:5, flexDirection:'row', alignItems:'center' }}>
                            <Image
                                source={{ uri: bracket_competition.prize_image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1697121009/Medal_and_trophy_awarded_for_success_wkuk6r.png' }}
                                style={{ height:40, width:40, borderRadius:4 }}
                                resizeMode='cover'
                            />
                            <View style={{ flex:1, marginLeft:5 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='bold'>{bracket_competition.prize_override}</Text>
                            </View>
                        </View>
                    </View>
                    :<></>}
                </View>
                :<></>}
            </View>
            <View style={local_styles.header}>
                <TouchableOpacity style={local_styles.section_header} onPress={() => setShowRules(!show_rules)}>
                    <View style={{ flex:1 }}>
                        <Text size={16} color={Colors.brand.midnight} weight='bold'>SCORING RULES</Text>
                        <Text style={{ marginTop:3 }} size={14} color={Colors.brand.slate} weight='regular'>Rules on how picks are scored within each bracket round</Text>
                    </View>
                    <Icons.ChevronIcon size={10} direction={show_rules ? 'up' : 'down'} color={Colors.brand.midnight} />
                </TouchableOpacity>
                {show_rules ?
                <View style={{ padding:10, marginBottom:15, backgroundColor:Colors.shades.shade600, borderRadius:8, marginLeft:10, marginRight:10 }}>
                    <FlatList
                        data={bracket_competition_scoring_rules.sort((a,b) => a.round_number - b.round_number)}
                        renderItem={renderRules}
                        keyExtractor={(item) => item.bracket_competition_scoring_rule_id.toString()}
                    />
                </View>
                :<></>}
            </View>
            <View style={local_styles.header}>
                <TouchableOpacity style={local_styles.section_header} onPress={() => setShowLeaders(!show_leaders)}>
                    <View style={{ flex:1 }}>
                        <Text size={16} color={Colors.brand.midnight} weight='bold'>LEADERBOARD</Text>
                        <Text style={{ marginTop:3 }} size={14} color={Colors.brand.slate} weight='regular'>Leaderboard showing points and the current payouts.</Text>
                    </View>
                    <Icons.ChevronIcon size={10} direction={show_leaders ? 'up' : 'down'} color={Colors.brand.midnight} />
                </TouchableOpacity>
                {show_leaders ?
                <View style={{ padding:10 }}>
                    <CompetitionLeaderboard
                        my_competition_player_brackets={my_comp_player_brackets}
                        my_player_brackets={my_player_brackets}
                        bracket_competition_id={bracket_competition.bracket_competition_id}
                        view_mode='short'
                        show_champions={BracketCompetitionHelpers.showChampions(bracket_competition)}
                        onViewFull={() => setShowLeaderboard(true)}
                    />
                </View>
                :<></>}
            </View>
            </ScrollView>
            {show_leaderboard ?
            <View style={{ position: 'absolute', left:0, right:0, bottom:0 }}>
                <Spring
                    to={0}
                    from={500}
                    slide='vertical'
                >
                    <View style={{ height, backgroundColor:Colors.shades.white }}>
                        <CompetitionLeaderboard
                            bracket_competition_id={bracket_competition.bracket_competition_id}
                            view_mode='full'
                            show_champions={BracketCompetitionHelpers.showChampions(bracket_competition)}
                            onClose={() => setShowLeaderboard(false)}
                            my_competition_player_brackets={my_comp_player_brackets}
                            my_player_brackets={my_player_brackets}
                        />
                    </View>
                </Spring>
            </View>
            :<></>}
            {joining_bracket ?
            <View style={{ position:'absolute', top:0, bottom:0, left:0, right:0, backgroundColor:Colors.shades.black_faded_heavy, justifyContent:'center', alignItems:'center' }}>
                <JoinCompetitionCard
                    player={player}
                    loading={action_loading}
                    player_balance={player_balance}
                    bracket_competition={bracket_competition}
                    player_bracket={joining_bracket}
                    onClose={() => setJoiningBracket(undefined)}
                    onNotEnoughBalance={onNotEnoughBalance}
                    onEnterCompetition={(cpb) => handleEnter(cpb)}
                />
            </View>
            :<></>}
        </View>
    )
}


const local_styles = StyleSheet.create({
    header: { margin:10, backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float },
    section_header: { padding:10, paddingLeft:20, paddingRight:20, flexDirection:'row', alignItems:'center' }
})

export default BracketCompetitionCard