import React from 'react';
import { View, Image } from 'react-native';
import type { CompetitionSeasonProps } from '../../types';
import { Button, Text } from '../../Components';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';

type CompetitionHeaderProps = {
    width:number,
    action_loading:boolean,
    competition_season:CompetitionSeasonProps,
    season_valid:boolean,
    onDelete:() => void,
    onActivate: () => void
}

const SeasonHeader = ({ width, action_loading, competition_season, season_valid, onDelete, onActivate }:CompetitionHeaderProps) => {
    
    const handleActivate = () => {
        if(!season_valid){
            return alert(`Please complete all the required sections before activating`)
        }
        onActivate()
    }
    
    return (
        <View style={{ backgroundColor:Colors.shades.white, flexGrow:1, minWidth:330, maxWidth:width }}>
            <View nativeID="competition_header" style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <View>
                    <Image
                        source={{ uri: competition_season.image?.url ?? 'https://res.cloudinary.com/hoabts6mc/image/upload/v1649737862/be_logo_jte2ux.webp' }}
                        style={{ height:50, width:50, borderRadius:4 }}
                        resizeMode="cover"
                    />
                </View>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text theme='header'>{competition_season.season_name}</Text>
                    <Text style={{ marginTop:4 }} theme='header_2>'>{competition_season.season_description}</Text>
                </View>
                {competition_season.status == 'pending'?
                <Button
                    title={'ACTIVATE'}
                    style={{ opacity:season_valid ? 1: 0.5 }}
                    loading={action_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => handleActivate()}
                />
                :<></>}
            </View>
            {competition_season.status == 'pending' ? 
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header'>SEASON IS PENDING</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Complete each section and press 'ACTIVATE'.</Text>
                </View>
                <Button
                    title='DELETE'
                    style={{ opacity:!action_loading? 1: 0.5 }}
                    loading={action_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.error}
                    onPress={async() => {
                        const confirmed = await confirm(`Are you sure you would like to delete this competition?  This cannot be undone.`)
                        if(!confirmed){ return }
                        onDelete();
                    }}
                />
            </View>
            :<></>}
        </View>
    )
}

export default SeasonHeader