import React, { useEffect, useState } from 'react';
import { FlatList, TouchableOpacity, View, Image, ScrollView } from 'react-native';
import type { BracketCompetitionProps, BracketProps, LeagueProps } from '../../types';
import { Button, Icons, Text, TextInput } from '../../Components';
import { BracketComeptitionApi } from '../api';
import Colors from '../../constants/colors';
import moment from 'moment-mini';

type BracketCompetitionSelectorProps = {
    visible:boolean,
    leagues:LeagueProps[],
    brackets:BracketProps[],
    onClose:() => void,
    onCompetitionSelect:(bracket_competition:BracketCompetitionProps) => void
}
const BracketCompetitionSelector = ({ visible, leagues, brackets, onClose, onCompetitionSelect }:BracketCompetitionSelectorProps) => {
    const [ search_value, setSearchValue ] = useState('');
    const [ search_error, setSearchError ] = useState<string|undefined>(undefined);
    const [ card_data, setData ] = useState<{
        loading:boolean,
        shown_brackets:string[]
        bracket_competitions:BracketCompetitionProps[]
    }>({
        loading:false,
        shown_brackets: [],
        bracket_competitions: []
    })
    const { loading, bracket_competitions, shown_brackets } = card_data;
    let visible_competitions = [ ...bracket_competitions ];
    if(search_value){
        visible_competitions = visible_competitions.filter(c => `${c.competition_name.toLowerCase()}`.includes(search_value.toLowerCase()))
    }
    const unique_bracket_ids = [ ...new Set(visible_competitions.map(bc => bc.bracket_id)) ]

    useEffect(() => {
        if(!visible){ return }
        getData();
    },[visible])

    const getData = async() => {
        const comps = await BracketComeptitionApi.getBracketCompetitions();
        setData({
            ...card_data,
            bracket_competitions: comps
        })
    }

    const handleFindPrivate = async() => {
        const bc = await BracketComeptitionApi.getBracketcompetitionByCode(search_value)
        if(!bc){ return setSearchError(`Unable to find competition with the provided code`) }
        return onCompetitionSelect(bc)
    }

    const renderCompetitions = (data: { item:BracketCompetitionProps, index:number } ) => {
        return (
            <TouchableOpacity style={{ padding:10, flexDirection:'row', alignItems:'center', borderBottomWidth:1, borderColor:Colors.shades.shade600 }}
            onPress={() => onCompetitionSelect(data.item)}>
                <View>
                    <Image
                        source={{ uri: data.item.image?.url }}
                        style={{ height:40, width:40, borderRadius:4 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ flex:1, marginLeft:5 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{data.item.competition_name}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate} weight='regular'>{data.item.competition_description}</Text>
                </View>
                <Icons.ChevronIcon size={10} color={Colors.brand.midnight} direction='right' />
            </TouchableOpacity>
        )
    }

    const renderBrackets = (data: { item:string, index:number }) => {
        const bracket = brackets.find(b => b.bracket_id == data.item);
        if(!bracket){ return <></> }
        const league = leagues.find(l => l.league_id == bracket.league_id);
        const comps = visible_competitions.filter(bc => bc.bracket_id == data.item);
        const selected = shown_brackets.includes(data.item)
        const started = moment().isAfter(moment(bracket.scheduled_datetime)) ? true : false;
        return (
            <View>
                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:10, backgroundColor:Colors.shades.shade100 }}
                onPress={() => selected ? setData({ ...card_data, shown_brackets: shown_brackets.filter(b => b != data.item) }) : setData({ ...card_data, shown_brackets: shown_brackets.concat(data.item) })}>
                    <View>
                        <Image
                            source={{ uri: league?.league_image }}
                            style={{ height:40, width:40, borderRadius:4 }}
                            resizeMode='cover'
                        />
                    </View>
                    <View style={{ flex:1, marginLeft:5 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{bracket.bracket_name}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate}>{started? 'In Progress' : `Starts at ${moment(bracket.scheduled_datetime).format('MMM DD YYYY hh:mm a')}`}</Text>
                    </View>
                    <View style={{ justifyContent:'center', alignItems:'center', padding:5, paddingRight:15 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>{comps.length}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate} weight='regular'>COMPETITIONS</Text>
                    </View>
                    <Icons.ChevronIcon direction={selected?'up':'down'} size={10} color={Colors.brand.midnight} />
                </TouchableOpacity>
                {selected ?
                <View style={{ padding:10 }}>
                    <FlatList
                        data={comps}
                        renderItem={renderCompetitions}
                        keyExtractor={(item) => item.bracket_competition_id.toString()}
                    />
                </View>
                :<></>}
            </View>
        )
    }

    if(!visible || loading){ return <></> }
    return (
        <View style={{ flex:1 }}>
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:20, borderBottomWidth:1, borderColor:Colors.shades.shade100 }} onPress={() => onClose()}>
                <Icons.ChevronIcon direction='left' size={12} color={Colors.brand.midnight} />
                <View style={{ flex:1, marginLeft:20 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='bold'>Bracket Competitions</Text>
                    <Text style={{ marginTop:4 }} size={14} color={Colors.brand.slate} weight='regular'>Select a competition below to see more details</Text>
                </View>
            </TouchableOpacity>
            
            <ScrollView style={{ flex:1 }}>
                <View style={{ padding:20 }}>
                    <View style={{ marginBottom:10 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='semibold'>Have a private code?</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate}>Enter it below and select search.  If found, we will send you to the competition.</Text>
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center' }}>
                        <TextInput
                            style={{ flex:1 }}
                            value={search_value}
                            onChangeText={(text) => {
                                if(search_error){ setSearchError(undefined) }
                                setSearchValue(text)
                            }}
                            placeholder='Search or Enter Private Code'
                            placeholderTextColor={Colors.brand.slate}
                        />
                        {search_value ?
                        <Button
                            title='Find Private'
                            style={{ marginLeft:5 }}
                            padding={15}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.brand.electric}
                            onPress={() => handleFindPrivate()}
                        />
                        :<></>}
                    </View>
                    {search_error ?
                    <Text style={{ padding:10 }} size={14} color={Colors.utility.warning} weight='regular' textAlign='center'>{search_error}</Text>
                    :<></>}
                </View>
                <FlatList
                    data={unique_bracket_ids}
                    renderItem={renderBrackets}
                    keyExtractor={(item) => item.toString()}
                />
            </ScrollView>
        </View>
    )
}

export default BracketCompetitionSelector