import React from 'react';
import { ActivityIndicator, FlatList, Image, View } from "react-native"
import type { CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionProps, CompetitionTypeProps, EventProps, MarketProps, PlayerPickProps } from "../../types"
import MarketsCard from './MarketsCard';
import { Text } from '../../Components';
import Colors from '../../constants/colors';
import moment from 'moment-mini';
import { CompetitionHelpers, FINALIZED_STATUSES } from '../api';

type TeamEventCardProps = {
    competition:CompetitionProps,
    competition_type:CompetitionTypeProps,
    markets:MarketProps[],
    event:EventProps,
    onPick:(pp:PlayerPickProps, event_title:string, event_start:string) => void
    mode: 'view'|'play'
    loading?:boolean,
    player_picks:PlayerPickProps[],
    competition_match:CompetitionMatchProps,
    competition_match_markets:CompetitionMatchMarketProps[]
}

const TeamEventCard = ({ loading, event, markets, competition_type, competition_match, competition_match_markets, player_picks, mode, onPick }:TeamEventCardProps) => {

    const wager_picks = player_picks.filter(pp => pp.stake > 0)

    const handleMarketSelect = async(mm:CompetitionMatchMarketProps, player_pick?:PlayerPickProps) => {
        let market = markets.find(m => m.market_id == mm.market_id)
        if(!event || !competition_type || !market){ return alert('Unable to process') }
        if(moment(event.scheduled_datetime).isBefore(moment())){ return alert('This event has already started') }
        if(FINALIZED_STATUSES.includes(event.status)){ return alert('This event is not eligible for picks') }
        if(player_pick){
            return onPick(player_pick, event.event_title, event.scheduled_datetime)
            //await CompetitionStoreActions.removePlayerPick(player_pick.player_pick_id)
        } else {
            let pp = CompetitionHelpers.createPickFromMatchMarket(competition_match.competition_id, mm)
            let pick_title = CompetitionHelpers.getPickTitleForTeamEvent(pp, market, event)
            return onPick({ ...pp, pick_title }, event.event_title, event.scheduled_datetime)
        }
    }


    const renderWagerPicks = (data: { item:PlayerPickProps, index:number }) => {
        let locked = mode == 'view' && moment().isBefore(moment(event.scheduled_datetime)) ? true : false
        return (
            <View style={{ flexDirection:'row', marginTop:5 }}>
                <Text style={{ flex:1 }} size={12} color={Colors.highlights.highlight200}>{locked?'WAGER LOCKED':data.item.pick_title}</Text>
                <Text style={{ flex:1 }} size={12} color={Colors.highlights.highlight200} textAlign="right">{locked?'':`${data.item.stake.toFixed(2)} to win ${data.item.potential_winnings.toFixed(2)}`}</Text>
            </View>
        )
    }

    if(!event){ return <></> }
    return (
        <View style={{ padding:5 }}>
            <View style={{ flexDirection:'row' }}>
                <View nativeID="event_details" style={{ flex:1 }}>
                    <View />
                    <View nativeID="away_team" style={{ flex:1, flexDirection:'row', alignItems:'center', paddingRight:5 }}>
                        <View style={{ flex:1, marginRight:5 }}> 
                            <View style={{ flexDirection:'row', alignItems:'flex-start' }}>
                                {event?.away?.rank ?
                                <Text style={{ marginRight:4 }} size={12} color={Colors.brand.cyan} weight='bold'>{event.away.rank}</Text>
                                :<></>}
                                {event?.away?.image?.url?
                                <Image
                                    source={{ uri: event.away.image.url }}
                                    style={{ height:18, width:18, marginRight:5 }}
                                    resizeMode="cover"
                                />
                                :<></>}
                                <Text size={12} color={Colors.shades.black} weight='semibold'>{event.away?.market_name} {event.away?.name}</Text>
                            </View>                            
                            {event?.away_sub_title ?
                            <Text size={12} color={Colors.shades.black} weight='regular'>{event?.away_sub_title}</Text>
                            :<></>}
                        </View>
                        <Text size={12} color={Colors.shades.black} weight='bold'>{event.away_team_score}</Text>
                    </View>
                    <View nativeID="home_team" style={{ flex:1, flexDirection:'row', alignItems:'center', paddingRight:5 }}>
                        <View style={{ flex:1, marginRight:5 }}> 
                            <View style={{ flexDirection:'row', alignItems:'flex-start' }}>
                                {event?.home?.rank ?
                                <Text style={{ marginRight:4 }} size={12} color={Colors.brand.cyan} weight='bold'>{event.home.rank}</Text>
                                :<></>}
                                {event?.home?.image?.url?
                                <Image
                                    source={{ uri: event.home.image.url }}
                                    style={{ height:18, width:18, marginRight:5 }}
                                    resizeMode="cover"
                                />
                                :<></>}
                                <Text size={12} color={Colors.shades.black} weight='semibold'>{event.home?.market_name} {event.home?.name}</Text>
                            </View>                            
                            {event?.home_sub_title ?
                            <Text size={12} color={Colors.shades.black} weight='regular'>{event?.home_sub_title}</Text>
                            :<></>}
                        </View>
                        <Text size={12} color={Colors.shades.black} weight='bold'>{event.home_team_score}</Text>
                    </View>
                </View>
                <View nativeID="market_details" style={{ flex:1 }}>
                    <MarketsCard
                        player_picks={player_picks}
                        markets={markets}
                        mode={mode}
                        disabled={moment().isAfter(moment(event.scheduled_datetime)) || FINALIZED_STATUSES.includes(event.status) ? true : false}
                        locked={mode == 'view' && moment().isBefore(moment(event.scheduled_datetime)) ? true : false}
                        competition_match_markets={competition_match_markets}
                        onMarketSelect={(mm, player_pick) => handleMarketSelect(mm, player_pick)}
                    />
                    {loading ?
                    <View style={{ position:'absolute', top:0, right:0, left:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                        <ActivityIndicator
                            size='large'
                            color={Colors.brand.midnight}
                        />
                    </View>
                    :<></>}
                </View>
            </View>
            {wager_picks.length > 0 ?
            <View nativeID="wager_picks">
                <FlatList
                    data={wager_picks}
                    renderItem={renderWagerPicks}
                    keyExtractor={(item) => item.player_pick_id.toString()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default TeamEventCard