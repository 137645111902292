import React, { useEffect, useState } from 'react';
import StrategyForm from './Components/StrategyForm';
import { AuthenticateApi } from './api';
import { View, Image } from 'react-native';
import LoginForm from './Components/LoginForm';
import { Button, Text } from '../Components';
import Colors from '../constants/colors';
import type { AuthStrategyIdentifierProps, AuthStrategyProps, AuthenticatedProps } from './api/types';
import { APIOverrides } from '../ApiOverrides';
import type { CompanyProps } from '../types';


type AuthenticateWizardProps = {
    auth_strategy_id:string,
    width:number,
    company_id?:string,
    default_view?:'login'|'register'
    show_referral?:boolean,
    source:string,
    theme?:'full'|'mini'
    opt_in_url?:string,
    referral_code?:string,
    visible?:boolean,
    onAuthenticated: (auth_response:AuthenticatedProps) => void,
    onTryAuthenticate?: (attribute:string, result:'success'|'fail') => void
    onClose: () => void
}

const Authenticator = ({ visible, theme, show_referral, auth_strategy_id, default_view, company_id, source, referral_code, width, opt_in_url, onTryAuthenticate, onAuthenticated, onClose }:AuthenticateWizardProps) => {
    const [ loaded, setLoaded ] = useState(false);
    const [ company, setCompany ] = useState<CompanyProps>();
    const [ distinct_id, setDistinctID ] = useState<string>();
    const [ active_view, setActiveView ] = useState(default_view ?? 'register')
    const [ strategy, setStrategy ] = useState<{
        loading:boolean,
        auth_strategy?:AuthStrategyProps,
        auth_strategy_identifiers:AuthStrategyIdentifierProps[]
    }>({
        loading: false,
        auth_strategy:undefined,
        auth_strategy_identifiers:[]
    });
    
    useEffect(() => {
        getWidgetData();
    },[auth_strategy_id])

    useEffect(() => {
        if(!company_id){ return }
        getCompanyFromServer(company_id);
    },[company_id])

    useEffect(() => {
        if(!default_view){ return }
        setActiveView(default_view)
    },[default_view])

    const getCompanyFromServer = async(company_id:string) => {
        const company_resp = await AuthenticateApi.getCompanyById(company_id)
        setCompany(company_resp.company)
    }

    const getWidgetData = async() => {
        AuthenticateApi.setEnvironment();
        const distinct_id = await APIOverrides.getDistinctID()
        setDistinctID(distinct_id)

        const { auth_strategy, auth_strategy_identifiers } = await AuthenticateApi.getAuthStrategyById(auth_strategy_id);        
        setStrategy({
            ...strategy,
            auth_strategy,
            auth_strategy_identifiers,
            loading:false
        })
        setLoaded(true);
    }

    const handleAuthenticated = async(auth_response:AuthenticatedProps) => {
        return onAuthenticated(auth_response)
    }

    if(!loaded || !distinct_id){ return <></> }
    if(!visible){ return <></> }
    return (
        <View style={{ flex:1, width, minHeight:theme!='mini'?600:undefined }}>
            {company && theme != 'mini' ?
            <View style={{ padding:20, flexDirection:'row', alignItems:'center', borderTopRightRadius:22, borderTopLeftRadius:22, borderBottomWidth:1, borderColor:Colors.accents.accent200 }}>
                <View style={{ flex:1, marginRight:10 }}>
                    <Text size={28} weight='bold' color={Colors.brand.midnight}>{active_view == 'login' ? 'Log In!': 'Welcome!'}</Text>
                    <Text style={{ marginTop:5 }} color={Colors.brand.midnight} size={14} weight='semibold' textAlign='left'>{active_view == 'login' ? 'Continue to login with':'Complete the form below to participate with'} {company?.company_name ?? 'Bettoredge'}</Text>
                </View>
                <View>
                    <Image
                        source={{ uri: company?.company_image?.url }}
                        style={{ width: width * 0.15, height: width * 0.15, borderRadius:8 }}
                        resizeMode='cover'
                    />
                </View>         
            </View>
            :<></>}
            <StrategyForm
                visible={active_view == 'register' ? true : false}
                width={width}
                company={company}
                strategy={strategy}
                theme={theme}
                onLogin={() => setActiveView('login')}
                auth_strategy_id={auth_strategy_id}
                company_id={company_id}
                show_referral={show_referral}
                distinct_id={distinct_id}
                source={source}
                referral_code={referral_code ?? 'tipoff'}
                opt_in_url={opt_in_url}
                onAuthenticated={handleAuthenticated}
            />
            <LoginForm
                width={width}
                visible={ active_view == 'login' ? true : false }
                onAuthenticated={handleAuthenticated}
                onTryAuthenticate={(attribute, result) => onTryAuthenticate ? onTryAuthenticate(attribute, result) : console.log()}
                onRegister={() => setActiveView('register')}
            />
            {theme != 'mini' ?
            <View style={{ padding:10 }}>
                <Button
                    title='CLOSE'
                    title_color={Colors.utility.error}
                    padding={15}
                    borderWidth={0}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default Authenticator
