import React, { useState } from 'react';
import { View, Image } from 'react-native';
import type { AthleteProps, LeagueProps, OrderProps, PublicPlayerProps, TeamProps } from '../types';
import { view_styles } from '../constants/styles';
import { Button, Icons, Text } from '../Components';
import { SocialComponentApi, SocialOrderHelpers } from './api';
import Colors from '../constants/colors';
import moment from 'moment-mini';
import { AthleteImage } from '../Components/Jerseys';

type SocialOrderCardProps = {
    player?:PublicPlayerProps,
    show_player?:boolean,
    contest_title:string,
    contest_time:string,
    order:OrderProps,
    athlete?:AthleteProps,
    maxWidth?:number,
    team?:TeamProps,
    league?:LeagueProps,
    onCopyOrder?:(order:OrderProps) => void,
    onFadeOrder?:(order:OrderProps) => void
}
const SocialOrderCard = ({ player, show_player, maxWidth, league, team, athlete, order, contest_title, contest_time, onCopyOrder, onFadeOrder }:SocialOrderCardProps) => {
    const [ fade_loading, setFadeLoading ] = useState(false);
    const { result_label, color } = SocialOrderHelpers.getResultDetails(order)
    
    const handleFadeOrder = async() => {
        if(!onFadeOrder){ return }
        if(fade_loading){ return }
        setFadeLoading(true);
        let reversed_order = await SocialComponentApi.reverseOrder(order);
        if(!reversed_order){ return alert('Unable to fade this order at this time') }
        onFadeOrder({
            ...reversed_order, 
            buy_sell_ind:'buy',
            influencer_id: order.player_id,
            influence_type: 'fade', 
            selling_position_id:undefined, 
            open_amt:10,
            player_id:'', 
            order_type: 'limit',
            collar_pct: 0,
            h2h_id:undefined,
            order_id: '', 
            called_amt: 0, 
            potential_winnings: SocialOrderHelpers.calcPotentialWinnings(reversed_order.odds, 10) 
        })
        setFadeLoading(false);
    }
    
    return (
        <View style={{ ...view_styles.section, minWidth:175, maxWidth, borderWidth:1, borderColor:Colors.shades.shade600 }}>
            {show_player && player ?
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <Image
                    source={{ uri: player.profile_pic && player.profile_pic != '' ? player.profile_pic : 'https://res.cloudinary.com/hoabts6mc/image/upload/v1722453927/default_man_n96ofq.webp' }}
                    style={{ height:35, width:35, borderRadius:100 }}
                    resizeMode='cover'
                />
                <View style={{ flex:1, marginLeft:10, marginRight:10 }}>
                    <Text theme='header'>{player.first_name} {player.last_name}</Text>
                    <Text theme='body_2' style={{ marginTop:3 }}>@{player.username}</Text>
                </View>
                <Text size={14} color={color} weight='bold'>{result_label}</Text>
            </View>
            :<></>}
            <View style={{ ...view_styles.section_header }}>
                
                <View style={{ flex:1, marginRight:10 }}>
                    <Text theme='body_2'>{contest_title}</Text>
                    <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate} weight='regular'>{contest_time}</Text>
                </View>
                {league ?
                <Image
                    source={{ uri: league.league_image }}
                    style={{ height:30, width:30, borderRadius:4 }}
                    resizeMode='cover'
                />
                :
                <View style={{ height:30, width:30, borderRadius:4, justifyContent:'center', alignItems:'center', backgroundColor:SocialOrderHelpers.getIconColor(order) }}>
                    <Icons.ActivityIcon size={14} color={Colors.shades.white}/>
                </View>
                }
            </View>
            <View style={{ ...view_styles.section_body, padding:10 }}>
                <View style={{ ...view_styles.body_row }}>
                    {team ?
                    <Image
                        source={{ uri: team.image?.url }}
                        style={{ height:35, width:35 }}
                        resizeMode='cover'
                    />
                    :athlete ?
                    <AthleteImage
                        athlete={athlete}
                        size={16}
                    />
                    :<></>}
                    <View style={{ flex:1, marginLeft:10, marginRight:10 }}>
                        <Text theme='header_2'>{order.title}</Text>
                        <Text style={{ marginTop:3 }} theme='body_2'>Created on {moment(order.create_datetime).format('MM/DD hh:mm a')}</Text>
                    </View>
                    <View>
                        <Text theme='header'>{SocialOrderHelpers.getOddsLabel(order.odds)}</Text>
                        {!show_player ?
                        <Text style={{ marginTop:3 }} size={14} color={color} weight='bold'>{result_label}</Text>
                        :<></>}
                    </View>
                </View>
            </View>
            {order.resolution_status == 'inprogress' ?
            <View style={{ ...view_styles.body_row, padding:0 }}>
                {onCopyOrder ? 
                <Button
                    style={{ flex:1, borderRadius:0, borderBottomLeftRadius:8 }}
                    title='Copy'
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.midnight}
                    onPress={() => onCopyOrder({ 
                        ...order, 
                        buy_sell_ind:'buy',
                        influencer_id: order.player_id,
                        influence_type: 'copy', 
                        order_type:'limit',
                        collar_pct:0,
                        selling_position_id:undefined, 
                        open_amt:10,
                        player_id:'', 
                        h2h_id:undefined,
                        order_id: '', 
                        called_amt: 0, 
                        potential_winnings: SocialOrderHelpers.calcPotentialWinnings(order.odds, 10) 
                    })}
                />
                :<></>}
                {onFadeOrder ?
                <Button
                    style={{ flex:1, borderRadius:0, borderBottomRightRadius:8, opacity:fade_loading?0.5:1 }}
                    title='Fade'
                    loading={fade_loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.cyan}
                    onPress={() => handleFadeOrder()}
                />
                :<></>}
            </View>
            :<></>}
        </View>
    )
}

export default SocialOrderCard