import moment from "moment-mini";
import type { AthleteProps, EventOrderStatProps, MarketProps, MatchProps, TeamProps, TradeProps } from "../../../../types";

export { TournamentMarketHelpers }
const TournamentMarketHelpers = {
    visibleMarketTypes: (markets:MarketProps[]) => {
        let show_athletes =  false
        let show_teams =  false
        let show_matches = false
        let show_futures = false
        markets.map(m => {
            if(m.event_type == 'match'){ show_matches = true }
            if(m.event_type == 'tournament'){ show_futures = true }
            if(m.event_type == 'tournament' && m.level == 'athlete'){ show_athletes = true }
            if(m.event_type == 'tournament' && m.level == 'team'){ show_teams = true }
        })
        return { show_athletes, show_teams, show_matches, show_futures }
    },
    getMarketWrapWidth : (padding:number, width?:number, market_wrap?:boolean) => {
        const MIN_MARKET_WIDTH = 150
        if(!width || !market_wrap){ return undefined }

        let total_width = width - padding
        
        let total_cards_possible = Math.floor(total_width / MIN_MARKET_WIDTH)

        let new_width = total_width / total_cards_possible

        return new_width
    },
    sortAthletes : (event_order_stats:EventOrderStatProps[], latest_trades:TradeProps[], athletes:AthleteProps[], market?:MarketProps, search_value?:string) => {
        let filtered_athletes = [ ...athletes ]
        if(search_value){
            filtered_athletes = filtered_athletes.filter(a => `${a.first_name.toLowerCase()} ${a.last_name.toLowerCase()}`.includes(search_value.toLowerCase()))
        }
        return filtered_athletes.sort((a,b) => {
            let a_prob = 0
            let b_prob = 0
            let a_trade = latest_trades.find(os => os.market_id == market?.market_id && os.side == market?.trade_side && os.side_type == 'athlete' && os.side_id == a.athlete_id);
            if(a_trade){ a_prob = a_trade.probability }
            let b_trade = latest_trades.find(os => os.market_id == market?.market_id && os.side == market?.trade_side && os.side_type == 'athlete' && os.side_id == b.athlete_id);
            if(b_trade){ b_prob = b_trade.probability }
            let a_stats = event_order_stats.filter(os => os.market_id == market?.market_id && os.side_type == 'athlete' && os.side_id == a.athlete_id).reduce((a,b) => a + b.open_order_amount, 0);
            let b_stats = event_order_stats.filter(os => os.market_id == market?.market_id && os.side_type == 'athlete' && os.side_id == b.athlete_id).reduce((a,b) => a + b.open_order_amount, 0);
            
            if(a_stats > 0){ a_prob += a_stats }
            if(b_stats > 0){ b_prob += b_stats }
            return b_prob - a_prob
        })
    },
    sortTeams : (event_order_stats:EventOrderStatProps[], latest_trades:TradeProps[], teams:TeamProps[], market?:MarketProps, search_value?:string) => {
        let filtered_teams = [ ...teams ]
        if(search_value){
            filtered_teams = filtered_teams.filter(t => `${t.market_name.toLowerCase()} ${t.name.toLowerCase()}`.includes(search_value.toLowerCase()))
        }
        return filtered_teams.sort((a,b) => {
            let a_prob = 0
            let b_prob = 0
            let a_trade = latest_trades.find(os => os.market_id == market?.market_id && os.side == market?.trade_side && os.side_type == 'team' && os.side_id == a.team_id);
            if(a_trade){ a_prob = a_trade.probability }
            let b_trade = latest_trades.find(os => os.market_id == market?.market_id && os.side == market?.trade_side && os.side_type == 'team' && os.side_id == b.team_id);
            if(b_trade){ b_prob = b_trade.probability }
            let a_stats = event_order_stats.filter(os => os.market_id == market?.market_id && os.side_type == 'team' && os.side_id == a.team_id).reduce((a,b) => a + b.open_order_amount, 0);
            let b_stats = event_order_stats.filter(os => os.market_id == market?.market_id && os.side_type == 'team' && os.side_id == b.team_id).reduce((a,b) => a + b.open_order_amount, 0);
            if(a_stats > 0){ a_prob += a_stats }
            if(b_stats > 0){ b_prob += b_stats }
            return b_prob - a_prob
        })
    },
    sortMatches : (event_order_stats:EventOrderStatProps[], matches:MatchProps[], market?:MarketProps, search_value?:string, athletes?:AthleteProps[], teams?:TeamProps[]) => {
        let filtered_matches = [ ...matches ];
        if(search_value){
            filtered_matches = matches.filter(m => {
                let home_athlete = athletes?.find(a => a.athlete_id == m.participants[1]);
                let away_athlete = athletes?.find(a => a.athlete_id == m.participants[0]);
                if(`${home_athlete?.first_name.toLowerCase()} ${home_athlete?.last_name.toLowerCase()} ${away_athlete?.first_name.toLowerCase()} ${away_athlete?.last_name.toLowerCase()}`.includes(search_value.toLowerCase())){ return true }
                let home_team = teams?.find(a => a.team_id == m.participants[1]);
                let away_team = teams?.find(a => a.team_id == m.participants[0]);
                if(`${home_team?.market_name.toLowerCase()} ${home_team?.name.toLowerCase()} ${away_team?.market_name.toLowerCase()} ${away_team?.name.toLowerCase()}`.includes(search_value.toLowerCase())){ return true }
                return false
            })
        }
        return filtered_matches.sort((a,b) => {
            let a_prob = 0
            let b_prob = 0
            let a_stats = event_order_stats.filter(os => os.market_id == market?.market_id && os.event_type == 'match' && os.event_id == a.match_id).reduce((a,b) => a + b.open_order_amount, 0);
            let b_stats = event_order_stats.filter(os => os.market_id == market?.market_id && os.event_type == 'match' && os.event_id == b.match_id).reduce((a,b) => a + b.open_order_amount, 0);
            if(a_stats > 0){ a_prob += a_stats + 999999999 }
            if(b_stats > 0){ b_prob += b_stats + 999999999 }
            if(a_prob == 0 && b_prob == 0){
                b_prob = moment(a.scheduled_datetime).unix()
                a_prob = moment(b.scheduled_datetime).unix()
            }
            if(['closed','complete','postponed','cancelled','suspended'].includes(a.status)){ a_prob -= 999999999 }
            if(['closed','complete','postponed','cancelled','suspended'].includes(b.status)){ b_prob -= 999999999 }

            return b_prob - a_prob
        })
    }
}