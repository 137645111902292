import axios from 'axios';
import { APIOverrides } from "../../ApiOverrides";
import type { EventProps, TeamProps } from '../../types';

let EVENT_SVC_API = ''
//let AUTH_SVC_API = ''

export { EventComponentApi }

const EventComponentApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
        //MK_SVC_API = endpoints['MK_SVC_API'] as string;
        //AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getTeamById: async(team_id:string):Promise<undefined|TeamProps> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/teams/team/${team_id}`);
            return resp.data.team
        } catch (e) {
            console.log(e);
            return undefined
        }
    },
    getUpcomingScheduleByTeams: async(team_ids:string, offset:number):Promise<EventProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/teams/schedule/upcoming/${team_ids}?offset=${offset}`)
            return resp.data.events
        } catch (e) {
            return []
        }
    },
    getPastScheduleByTeams: async(team_ids:string, offset:number):Promise<EventProps[]> => {
        try {
            const resp = await axios.get(`${EVENT_SVC_API}/v1/teams/schedule/past/${team_ids}?offset=${offset}`)
            return resp.data.events
        } catch (e) {
            return []
        }
    }
}