import React, { useState } from 'react';
import { View } from 'react-native';
import Colors from '../../constants/colors';

type WeightProgressProps = {
    min_value:number,
    max_value:number,
    value: number,
    bar_height?:number
}

const WeightProgress = ({ value, bar_height }:WeightProgressProps) => {
    const [ width, setWidth ] = useState(0);
    let progress_width = value * width / 2
    let start_point = width/2
    if(progress_width < 0){
        start_point += progress_width
    }
    return (
        <View style={{ borderRadius:2, height: bar_height ? bar_height + 2: 6, backgroundColor:Colors.shades.shade600, padding:1, justifyContent:'center' }} onLayout={(ev) => {
            const { width } = ev.nativeEvent.layout;
            setWidth(width)
        }}>
            <View style={{ position:'absolute', top:1, bottom:1, left:start_point, width:Math.abs(progress_width), backgroundColor:Colors.brand.midnight, height:bar_height??4, borderRadius:4 }}/>
        </View>
    )
}

export default WeightProgress